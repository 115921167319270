import {
  GET_POLICY_AND_NOTICE_MANAGEMENT_DASHBOARD_SUCCESS,
  LIST_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
  LIST_POLICY_AND_NOTICE_MANAGEMENT_FAILED,
  GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_SUCCESS,
  GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_DATA_SUCCESS,
  GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_BUILDER_SUCCESS,
  CREATE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
  CREATE_POLICY_AND_NOTICE_MANAGEMENT_FAILED,
  GET_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
  GET_POLICY_AND_NOTICE_MANAGEMENT_FAILED,
  UPDATE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
  UPDATE_POLICY_AND_NOTICE_MANAGEMENT_FAILED,
  PUBLISH_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
  GET_POLICY_AND_NOTICE_MANAGEMENT_VERSION_SUCCESS,
  DELETE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
  DELETE_POLICY_AND_NOTICE_MANAGEMENT_FAILED,
  LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK_SUCCESS,
  LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK_FAILED,
  UPDATE_POLICY_AND_NOTICE_MANAGEMENT_TASK_SUCCESS,
  UPDATE_POLICY_AND_NOTICE_MANAGEMENT_TASK_FAILED,
  LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
  LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED,
  GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
  GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED,
  CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
  CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED,
  UPDATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
  UPDATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED,
  DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
  DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED,
} from "redux/constants/PrivacyKitPolicyAndNoticeManagement";

export const getPolicyAndNoticeManagementDashboardSuccess = (payload) => ({
  type: GET_POLICY_AND_NOTICE_MANAGEMENT_DASHBOARD_SUCCESS,
  payload,
});

export const listPolicyAndNoticeManagementSuccess = (payload) => {
  return {
    type: LIST_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
    payload,
  };
};

export const listPolicyAndNoticeManagementFailed = (error) => {
  return {
    type: LIST_POLICY_AND_NOTICE_MANAGEMENT_FAILED,
    payload: error,
  };
};

export const getPolicyAndNoticeManagementTemplateSuccess = (payload) => ({
  type: GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_SUCCESS,
  payload,
});

export const getPolicyAndNoticeManagementTemplateDataSuccess = (payload) => ({
  type: GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_DATA_SUCCESS,
  payload,
});

export const getPolicyAndNoticeManagementTemplateBuilderSuccess = (
  payload
) => ({
  type: GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_BUILDER_SUCCESS,
  payload,
});

export const createPolicyAndNoticeManagementSuccess = (payload) => {
  return {
    type: CREATE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
    payload,
  };
};

export const createPolicyAndNoticeManagementFailed = (error) => {
  return {
    type: CREATE_POLICY_AND_NOTICE_MANAGEMENT_FAILED,
    payload: error,
  };
};

export const getPolicyAndNoticeManagementSuccess = (payload) => {
  return {
    type: GET_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
    payload,
  };
};

export const getPolicyAndNoticeManagementFailed = (error) => {
  return {
    type: GET_POLICY_AND_NOTICE_MANAGEMENT_FAILED,
    payload: error,
  };
};

export const updatePolicyAndNoticeManagementSuccess = (payload) => {
  return {
    type: UPDATE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
    payload,
  };
};

export const updatePolicyAndNoticeManagementFailed = (error) => {
  return {
    type: UPDATE_POLICY_AND_NOTICE_MANAGEMENT_FAILED,
    payload: error,
  };
};

export const publishPolicyAndNoticeManagementSuccess = (error) => {
  return {
    type: PUBLISH_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
    payload: error,
  };
};

export const getPolicyAndNoticeManagementVersionSuccess = (policyId) => ({
  type: GET_POLICY_AND_NOTICE_MANAGEMENT_VERSION_SUCCESS,
  payload: policyId,
});

export const deletePolicyAndNoticeManagementSuccess = (payload) => {
  return {
    type: DELETE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
    payload,
  };
};

export const deletePolicyAndNoticeManagementFailed = (error) => {
  return {
    type: DELETE_POLICY_AND_NOTICE_MANAGEMENT_FAILED,
    payload: error,
  };
};

export const listPolicyAndNoticeManagementTaskSuccess = (payload) => {
  return {
    type: LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK_SUCCESS,
    payload,
  };
};

export const listPolicyAndNoticeManagementTaskFailed = (error) => {
  return {
    type: LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK_FAILED,
    payload: error,
  };
};

export const updatePolicyAndNoticeManagementTaskSuccess = (payload) => ({
  type: UPDATE_POLICY_AND_NOTICE_MANAGEMENT_TASK_SUCCESS,
  payload,
});

export const updatePolicyAndNoticeManagementTaskFailed = (payload) => ({
  type: UPDATE_POLICY_AND_NOTICE_MANAGEMENT_TASK_FAILED,
  payload,
});

export const listPolicyAndNoticeManagementAssessmentSuccess = (payload) => {
  return {
    type: LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
    payload,
  };
};

export const listPolicyAndNoticeManagementAssessmentFailed = (error) => {
  return {
    type: LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED,
    payload: error,
  };
};

export const createPolicyAndNoticeManagementAssessmentSuccess = (payload) => {
  return {
    type: CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
    payload,
  };
};

export const createPolicyAndNoticeManagementAssessmentFailed = (error) => {
  return {
    type: CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED,
    payload: error,
  };
};

export const getPolicyAndNoticeManagementAssessmentSuccess = (payload) => {
  return {
    type: GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
    payload,
  };
};

export const getPolicyAndNoticeManagementAssessmentFailed = (error) => {
  return {
    type: GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED,
    payload: error,
  };
};

export const updatePolicyAndNoticeManagementAssessmentSuccess = (payload) => ({
  type: UPDATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
  payload,
});

export const updatePolicyAndNoticeManagementAssessmentFailed = (payload) => ({
  type: UPDATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED,
  payload,
});

export const deletePolicyAndNoticeManagementAssessmentSuccess = (payload) => {
  return {
    type: DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
    payload,
  };
};

export const deletePolicyAndNoticeManagementAssessmentFailed = (error) => {
  return {
    type: DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED,
    payload: error,
  };
};
