import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED,
  CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED,
  UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE,
  UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED,
  LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE,
  UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED,
  UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED,
  LIST_DATA_MAPPING_ASSESSMENT_DELETED,
  UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED,
  DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED,
  LIST_DATA_MAPPING_RISK,
  CREATE_DATA_MAPPING_RISK,
  GET_DATA_MAPPING_RISK,
  UPDATE_DATA_MAPPING_RISK,
  DELETE_DATA_MAPPING_RISK,
  LIST_DATA_MAPPING_INVENTORY_ASSETS,
  CREATE_DATA_MAPPING_INVENTORY_ASSETS,
  GET_DATA_MAPPING_INVENTORY_ASSETS,
  UPDATE_DATA_MAPPING_INVENTORY_ASSETS,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS,
  LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED,
  LIST_DATA_MAPPING_INVENTORY_PROCESSING,
  CREATE_DATA_MAPPING_INVENTORY_PROCESSING,
  GET_DATA_MAPPING_INVENTORY_PROCESSING,
  UPDATE_DATA_MAPPING_INVENTORY_PROCESSING,
  DELETE_DATA_MAPPING_INVENTORY_PROCESSING,
  LIST_DATA_MAPPING_INVENTORY_ENTITY,
  CREATE_DATA_MAPPING_INVENTORY_ENTITY,
  GET_DATA_MAPPING_INVENTORY_ENTITY,
  UPDATE_DATA_MAPPING_INVENTORY_ENTITY,
  DELETE_DATA_MAPPING_INVENTORY_ENTITY,
  LIST_DATA_MAPPING_INVENTORY_VENDOR,
  CREATE_DATA_MAPPING_INVENTORY_VENDOR,
  GET_DATA_MAPPING_INVENTORY_VENDOR,
  UPDATE_DATA_MAPPING_INVENTORY_VENDOR,
  DELETE_DATA_MAPPING_INVENTORY_VENDOR,
  LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE,
  CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE,
  GET_DATA_MAPPING_SETTING_AUTOMATION_RULE,
  UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE,
  DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE,
  LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE,
  LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW,
  CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW,
  GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW,
  UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW,
  DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW,
  LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT,
  LIST_DATA_MAPPING_INVENTORY,
  GET_DATA_MAPPING_INVENTORY,
  CREATE_DATA_MAPPING_MANAGEMENT,
  UPDATE_DATA_MAPPING_INVENTORY,
  LIST_DATA_MAPPING_DASHBOARD,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT,
  ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT,
  ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED,
  ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED,
  LIST_DATA_MAPPING_INVENTORY_LOCATION,
  LIST_DATA_MAPPING_INVENTORY_CONTROL,
  LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM,
  LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL,
  ADD_DATA_MAPPING_INVENTORY_CONTROL,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED,
  LIST_DATA_MAPPING_DATA_WORKFLOW,
  CREATE_DATA_MAPPING_DATA_WORKFLOW,
  DELETE_DATA_MAPPING_DATA_WORKFLOW,
  DELETE_DATA_MAPPING_INVENTORY_CONTROL,
  LIST_DATA_MAPPING_INVENTORY_IT_ASSET,
  LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED,
  GET_DATA_MAPPING_FLOW,
  ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED,
  DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED,
} from "redux/constants/PrivacyKitDataMapping";
import Notification from "util/Notification";
import {
  listDataMappingAssessmentActivatedSuccess,
  listDataMappingAssessmentActivatedFailed,
  createDataMappingAssessmentActivatedSuccess,
  createDataMappingAssessmentActivatedFailed,
  updateDataMappingAssessmentActivatedToArchiveSuccess,
  updateDataMappingAssessmentActivatedToArchiveFailed,
  updateDataMappingAssessmentActivatedToDeletedSuccess,
  updateDataMappingAssessmentActivatedToDeletedFailed,
  listDataMappingAssessmentArchiveSuccess,
  listDataMappingAssessmentArchiveFailed,
  updateDataMappingAssessmentArchiveToActivatedSuccess,
  updateDataMappingAssessmentArchiveToActivatedFailed,
  updateDataMappingAssessmentArchiveToDeletedSuccess,
  updateDataMappingAssessmentArchiveToDeletedFailed,
  listDataMappingAssessmentDeletedSuccess,
  listDataMappingAssessmentDeletedFailed,
  updateDataMappingAssessmentDeletedToActivatedSuccess,
  updateDataMappingAssessmentDeletedToActivatedFailed,
  deleteDataMappingAssessmentDeletedToDeletedSuccess,
  deleteDataMappingAssessmentDeletedToDeletedFailed,
  listDataMappingRiskSuccess,
  listDataMappingRiskFailed,
  createDataMappingRiskSuccess,
  createDataMappingRiskFailed,
  getDataMappingRiskSuccess,
  getDataMappingRiskFailed,
  updateDataMappingRiskSuccess,
  updateDataMappingRiskFailed,
  deleteDataMappingRiskSuccess,
  deleteDataMappingRiskFailed,
  listDataMappingInventoryAssetsSuccess,
  listDataMappingInventoryAssetsFailed,
  createDataMappingInventoryAssetsSuccess,
  createDataMappingInventoryAssetsFailed,
  getDataMappingInventoryAssetsSuccess,
  getDataMappingInventoryAssetsFailed,
  updateDataMappingInventoryAssetsSuccess,
  updateDataMappingInventoryAssetsFailed,
  deleteDataMappingInventoryAssetsSuccess,
  deleteDataMappingInventoryAssetsFailed,
  listDataMappingInventoryProcessingDataInventoryRelatedSuccess,
  listDataMappingInventoryProcessingDataInventoryRelatedFailed,
  listDataMappingInventoryProcessingSuccess,
  listDataMappingInventoryProcessingFailed,
  createDataMappingInventoryProcessingSuccess,
  createDataMappingInventoryProcessingFailed,
  getDataMappingInventoryProcessingSuccess,
  getDataMappingInventoryProcessingFailed,
  updateDataMappingInventoryProcessingSuccess,
  updateDataMappingInventoryProcessingFailed,
  deleteDataMappingInventoryProcessingSuccess,
  deleteDataMappingInventoryProcessingFailed,
  listDataMappingInventoryEntitySuccess,
  listDataMappingInventoryEntityFailed,
  createDataMappingInventoryEntitySuccess,
  createDataMappingInventoryEntityFailed,
  getDataMappingInventoryEntitySuccess,
  getDataMappingInventoryEntityFailed,
  updateDataMappingInventoryEntitySuccess,
  updateDataMappingInventoryEntityFailed,
  deleteDataMappingInventoryEntitySuccess,
  deleteDataMappingInventoryEntityFailed,
  listDataMappingInventoryVendorSuccess,
  listDataMappingInventoryVendorFailed,
  createDataMappingInventoryVendorSuccess,
  createDataMappingInventoryVendorFailed,
  getDataMappingInventoryVendorSuccess,
  getDataMappingInventoryVendorFailed,
  updateDataMappingInventoryVendorSuccess,
  updateDataMappingInventoryVendorFailed,
  deleteDataMappingInventoryVendorSuccess,
  deleteDataMappingInventoryVendorFailed,
  listDataMappingSettingAutomationRuleSuccess,
  listDataMappingSettingAutomationRuleFailed,
  createDataMappingSettingAutomationRuleSuccess,
  createDataMappingSettingAutomationRuleFailed,
  getDataMappingSettingAutomationRuleSuccess,
  getDataMappingSettingAutomationRuleFailed,
  updateDataMappingSettingAutomationRuleSuccess,
  updateDataMappingSettingAutomationRuleFailed,
  deleteDataMappingSettingAutomationRuleSuccess,
  deleteDataMappingSettingAutomationRuleFailed,
  listDataMappingSettingAssessmentTemplateSuccess,
  listDataMappingSettingAssessmentTemplateFailed,
  listDataMappingSettingAssessmentWorkflowSuccess,
  listDataMappingSettingAssessmentWorkflowFailed,
  createDataMappingSettingAssessmentWorkflowSuccess,
  createDataMappingSettingAssessmentWorkflowFailed,
  getDataMappingSettingAssessmentWorkflowSuccess,
  getDataMappingSettingAssessmentWorkflowFailed,
  updateDataMappingSettingAssessmentWorkflowSuccess,
  updateDataMappingSettingAssessmentWorkflowFailed,
  deleteDataMappingSettingAssessmentWorkflowSuccess,
  deleteDataMappingSettingAssessmentWorkflowFailed,
  listDataMappingSettingInventoryManagementSuccess,
  listDataMappingSettingInventoryManagementFailed,
  listDataMappingInventorySuccess,
  listDataMappingInventoryFailed,
  getDataMappingInventorySuccess,
  getDataMappingInventoryFailed,
  createDataMappingManagementSuccess,
  createDataMappingManagementFailed,
  updateDataMappingInventorySuccess,
  updateDataMappingInventoryFailed,
  dataMappingDashboardFailed,
  dataMappingDashboardSuccess,
  listDataMappingInventoryAssetsAssessmentSuccess,
  listDataMappingInventoryAssetsAssessmentFailed,
  addDataMappingInventoryAssetsAssessmentSuccess,
  addDataMappingInventoryAssetsAssessmentFailed,
  deleteDataMappingInventoryAssetsAssessmentSuccess,
  deleteDataMappingInventoryAssetsAssessmentFailed,
  listDataMappingInventoryAssetsDataElementSuccess,
  listDataMappingInventoryAssetsDataElementFailed,
  addDataMappingInventoryAssetsDataElementSuccess,
  addDataMappingInventoryAssetsDataElementFailed,
  deleteDataMappingInventoryAssetsDataElementSuccess,
  deleteDataMappingInventoryAssetsDataElementFailed,
  listDataMappingInventoryAssetsDataLifeCircleSuccess,
  listDataMappingInventoryAssetsDataLifeCircleFailed,
  listDataMappingInventoryAssetsDataInventoryRelatedSuccess,
  listDataMappingInventoryAssetsDataInventoryRelatedFailed,
  addDataMappingInventoryAssetsDataInventoryRelatedSuccess,
  addDataMappingInventoryAssetsDataInventoryRelatedFailed,
  listDataMappingInventoryLocationSuccess,
  listDataMappingInventoryLocationFailed,
  listDataMappingInventoryControlSuccess,
  listDataMappingInventoryControlFailed,
  listDataMappingInventoryControlParamSuccess,
  listDataMappingInventoryControlParamFailed,
  listDataMappingInventoryLinkControlSuccess,
  listDataMappingInventoryLinkControlFailed,
  addDataMappingInventoryControlSuccess,
  addDataMappingInventoryControlFailed,
  deleteDataMappingInventoryAssetsInventoryRelatedSuccess,
  deleteDataMappingInventoryAssetsInventoryRelatedFailed,
  setLoadingDataMapping,
  listDataMappingDataWorkflowSuccess,
  listDataMappingDataWorkflowFailed,
  createDataMappingDataWorkflowSuccess,
  createDataMappingDataWorkflowFailed,
  deleteDataMappingDataWorkflowSuccess,
  deleteDataMappingDataWorkflowFailed,
  deleteDataMappingInventoryControlSuccess,
  deleteDataMappingInventoryControlFailed,
  listDataMappingInventoryITAssetSuccess,
  listDataMappingInventoryITAssetFailed,
  listDataMappingInventoryEntityDataInventoryRelatedSuccess,
  listDataMappingInventoryEntityDataInventoryRelatedFailed,
  getDataMappingFlowSuccess,
  getDataMappingFlowFailed,
  setLoadingCreateDataMapping,
  setLoadingDataElement,
  setLoadingAsset,
  setLoadingControl,
  addDataMappingInventoryEntityDataInventoryRelatedSuccess,
  addDataMappingInventoryEntityDataInventoryRelatedFailed,
  deleteDataMappingInventoryEntityDataInventoryRelatedFailed,
  deleteDataMappingInventoryEntityDataInventoryRelatedSuccess,
  setLoadingEntity,
  setLoadingProcessing,
  setLoadingWorkflow,
} from "redux/actions/PrivacyKitDataMapping";
import DataMappingService from "services/pdpakit/DataMappingService";

function* listDataMappingDashboard() {
  yield takeEvery(LIST_DATA_MAPPING_DASHBOARD, function* () {
    try {
      const response = yield call(DataMappingService.dataMappingDashboard);
      if (response.statusCode === 200) {
        yield put(dataMappingDashboardSuccess(response));
      } else {
        yield put(dataMappingDashboardFailed(response));
      }
    } catch (error) {
      yield put(dataMappingDashboardFailed(error));
    }
  });
}

function* listDataMappingAssessmentActivated() {
  yield takeEvery(
    LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listAssessmentActivated,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingAssessmentActivatedSuccess(response));
        } else {
          yield put(listDataMappingAssessmentActivatedFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingAssessmentActivatedFailed(error));
      }
    }
  );
}

function* createDataMappingAssessmentActivated() {
  yield takeEvery(
    CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.createAssessment,
          payload
        );
        if (response.statusCode === 200 && response.data) {
          yield put(createDataMappingAssessmentActivatedSuccess(response.data));
          onFinish(response);
        } else {
          yield put(createDataMappingAssessmentActivatedFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(createDataMappingAssessmentActivatedFailed(error));
        onFailed(error);
      }
    }
  );
}

function* updateDataMappingAssessmentActivatedToArchive() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.updateAssessmentActivatedToArchive,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            updateDataMappingAssessmentActivatedToArchiveSuccess(response.data)
          );
          onFinish(response);
        } else {
          yield put(
            updateDataMappingAssessmentActivatedToArchiveFailed(response)
          );
          onFailed(response);
        }
      } catch (error) {
        yield put(updateDataMappingAssessmentActivatedToArchiveFailed(error));
        onFailed(error);
      }
    }
  );
}

function* updateDataMappingAssessmentActivatedToDeleted() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.updateAssessmentActivatedToDeleted,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            updateDataMappingAssessmentActivatedToDeletedSuccess(response.data)
          );
          onFinish(response);
        } else {
          yield put(
            updateDataMappingAssessmentActivatedToDeletedFailed(response)
          );
          onFailed(response);
        }
      } catch (error) {
        yield put(updateDataMappingAssessmentActivatedToDeletedFailed(error));
        onFailed(error);
      }
    }
  );
}

function* listDataMappingAssessmentArchive() {
  yield takeEvery(
    LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listAssessmentArchive,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingAssessmentArchiveSuccess(response));
        } else {
          yield put(listDataMappingAssessmentArchiveFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingAssessmentArchiveFailed(error));
      }
    }
  );
}

function* updateDataMappingAssessmentArchiveToActivated() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.updateAssessmentArchiveToActivated,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            updateDataMappingAssessmentArchiveToActivatedSuccess(response.data)
          );
          onFinish(response);
        } else {
          yield put(
            updateDataMappingAssessmentArchiveToActivatedFailed(response)
          );
          onFailed(response);
        }
      } catch (error) {
        yield put(updateDataMappingAssessmentArchiveToActivatedFailed(error));
        onFailed(error);
      }
    }
  );
}

function* updateDataMappingAssessmentArchiveToDeleted() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.updateAssessmentArchiveToDeleted,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            updateDataMappingAssessmentArchiveToDeletedSuccess(response.data)
          );
          onFinish(response);
        } else {
          yield put(
            updateDataMappingAssessmentArchiveToDeletedFailed(response)
          );
          onFailed(response);
        }
      } catch (error) {
        yield put(updateDataMappingAssessmentArchiveToDeletedFailed(error));
        onFailed(error);
      }
    }
  );
}

function* listDataMappingAssessmentDeleted() {
  yield takeEvery(
    LIST_DATA_MAPPING_ASSESSMENT_DELETED,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listAssessmentDeleted,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingAssessmentDeletedSuccess(response));
        } else {
          yield put(listDataMappingAssessmentDeletedFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingAssessmentDeletedFailed(error));
      }
    }
  );
}

function* updateDataMappingAssessmentDeletedToActivate() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.updateAssessmentDeletedToActivated,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            updateDataMappingAssessmentDeletedToActivatedSuccess(response.data)
          );
          onFinish(response);
        } else {
          yield put(
            updateDataMappingAssessmentDeletedToActivatedFailed(response)
          );
          onFailed(response);
        }
      } catch (error) {
        yield put(updateDataMappingAssessmentDeletedToActivatedFailed(error));
        onFailed(error);
      }
    }
  );
}

function* deleteDataMappingAssessmentDeletedToDeleted() {
  yield takeEvery(
    DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.deleteAssessment,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            deleteDataMappingAssessmentDeletedToDeletedSuccess(
              payload.ObjectUUID
            )
          );
          onFinish(response);
        } else {
          yield put(
            deleteDataMappingAssessmentDeletedToDeletedFailed(response)
          );
          onFailed(response);
        }
      } catch (error) {
        yield put(deleteDataMappingAssessmentDeletedToDeletedFailed(error));
        onFailed(error);
      }
    }
  );
}

function* listDataMappingRisk() {
  yield takeEvery(LIST_DATA_MAPPING_RISK, function* ({ payload }) {
    try {
      const response = yield call(DataMappingService.listRisk, payload);
      if (response.statusCode === 200) {
        yield put(listDataMappingRiskSuccess(response));
      } else {
        yield put(listDataMappingRiskFailed(response));
      }
    } catch (error) {
      yield put(listDataMappingRiskFailed(error));
    }
  });
}

function* createDataMappingRisk() {
  yield takeEvery(
    CREATE_DATA_MAPPING_RISK,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(DataMappingService.createRisk, payload);
        if (response.statusCode === 200 && response.data) {
          yield put(createDataMappingRiskSuccess(response.data));
          onFinish(response);
        } else {
          yield put(createDataMappingRiskFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(createDataMappingRiskFailed(error));
        onFailed(error);
      }
    }
  );
}

function* getDataMappingRisk() {
  yield takeEvery(GET_DATA_MAPPING_RISK, function* ({ payload }) {
    try {
      const response = yield call(DataMappingService.getRisk, payload);
      if (response.statusCode === 200) {
        yield put(getDataMappingRiskSuccess(response.data));
      } else {
        yield put(getDataMappingRiskFailed(response));
      }
    } catch (error) {
      yield put(getDataMappingRiskFailed(error));
    }
  });
}

function* updateDataMappingRisk() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_RISK,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(DataMappingService.updateRisk, payload);
        if (response.statusCode === 200) {
          yield put(updateDataMappingRiskSuccess(response));
          onFinish(response.data);
        } else {
          yield put(updateDataMappingRiskFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(updateDataMappingRiskFailed(error));
        onFailed(error);
      }
    }
  );
}

function* deleteDataMappingRisk() {
  yield takeEvery(
    DELETE_DATA_MAPPING_RISK,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(DataMappingService.deleteRisk, payload);
        if (response.statusCode === 200) {
          yield put(deleteDataMappingRiskSuccess(payload.ObjectUUID));
          onFinish(response);
        } else {
          yield put(deleteDataMappingRiskFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(deleteDataMappingRiskFailed(error));
        onFailed(error);
      }
    }
  );
}

function* listDataMappingInventoryAssets() {
  yield takeEvery(LIST_DATA_MAPPING_INVENTORY_ASSETS, function* ({ payload }) {
    try {
      const response = yield call(
        DataMappingService.listInventoryAssets,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listDataMappingInventoryAssetsSuccess(response));
      } else {
        yield put(listDataMappingInventoryAssetsFailed(response));
      }
    } catch (error) {
      yield put(listDataMappingInventoryAssetsFailed(error));
    }
  });
}

function* listDataMappingInventoryAssetsAssessment() {
  yield takeEvery(
    LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listDataMappingInventoryAssessment,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingInventoryAssetsAssessmentSuccess(response));
        } else {
          yield put(listDataMappingInventoryAssetsAssessmentFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingInventoryAssetsAssessmentFailed(error));
      }
    }
  );
}

function* addDataMappingInventoryAssetsAssessment() {
  yield takeEvery(
    ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.addDataMappingInventoryAssessment,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            addDataMappingInventoryAssetsAssessmentSuccess(response.data)
          );
        } else {
          yield put(addDataMappingInventoryAssetsAssessmentFailed(response));
        }
      } catch (error) {
        yield put(addDataMappingInventoryAssetsAssessmentFailed(error));
      }
    }
  );
}

function* deleteDataMappingInventoryAssetsAssessment() {
  yield takeEvery(
    DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.deleteDataMappingInventoryAssessment,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            deleteDataMappingInventoryAssetsAssessmentSuccess(
              payload.ObjectUUID
            )
          );
          onFinish(response);
        } else {
          yield put(deleteDataMappingInventoryAssetsAssessmentFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(deleteDataMappingInventoryAssetsAssessmentFailed(error));
        onFailed(error);
      }
    }
  );
}

function* listDataMappingInventoryAssetsDataElement() {
  yield takeEvery(
    LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listDataMappingInventoryDataElement,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingInventoryAssetsDataElementSuccess(response));
        } else {
          yield put(listDataMappingInventoryAssetsDataElementFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingInventoryAssetsDataElementFailed(error));
      }
    }
  );
}

function* addDataMappingInventoryAssetsDataElement() {
  yield takeEvery(
    ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT,
    function* ({ inventory_type, ObjectUUID, payload, onFinish, onFailed }) {
      try {
        yield put(setLoadingDataElement(true));
        const response = yield call(
          DataMappingService.addDataMappingInventoryDataElement,
          inventory_type,
          ObjectUUID,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            addDataMappingInventoryAssetsDataElementSuccess(response.data)
          );
          Notification("success", "เพิ่มองค์ประกอบข้อมูลสำเร็จ");
          onFinish(response);
        } else {
          yield put(addDataMappingInventoryAssetsDataElementFailed(response));
          Notification("error", "เพิ่มองค์ประกอบข้อมูลไม่สำเร็จ");
          onFailed(response);
        }
        yield put(setLoadingDataElement(false));
      } catch (error) {
        yield put(addDataMappingInventoryAssetsDataElementFailed(error));
        yield put(setLoadingDataElement(false));
        Notification("error", "เพิ่มองค์ประกอบข้อมูลไม่สำเร็จ");
        onFailed(error);
      }
    }
  );
}

function* deleteDataMappingInventoryAssetsDataElement() {
  yield takeEvery(
    DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT,
    function* ({ inventory_type, ObjectUUID, dataElement_id }) {
      try {
        const response = yield call(
          DataMappingService.deleteDataMappingInventoryDataElement,
          inventory_type,
          ObjectUUID,
          dataElement_id
        );
        if (response.statusCode === 200) {
          yield put(
            deleteDataMappingInventoryAssetsDataElementSuccess(dataElement_id)
          );
          Notification("success", "ลบองค์ประกอบข้อมูลสำเร็จ");
        } else {
          yield put(
            deleteDataMappingInventoryAssetsDataElementFailed(response)
          );
          Notification("error", "");
        }
      } catch (error) {
        yield put(deleteDataMappingInventoryAssetsDataElementFailed(error));
        Notification("error", "");
      }
    }
  );
}

function* listDataMappingInventoryAssetsDataInventoryRelated() {
  yield takeEvery(
    LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listDataMappingInventoryDataInventoryRelated,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            listDataMappingInventoryAssetsDataInventoryRelatedSuccess(response)
          );
        } else {
          yield put(
            listDataMappingInventoryAssetsDataInventoryRelatedFailed(response)
          );
        }
      } catch (error) {
        yield put(
          listDataMappingInventoryAssetsDataInventoryRelatedFailed(error)
        );
      }
    }
  );
}

function* listDataMappingInventoryEntityDataInventoryRelated() {
  yield takeEvery(
    LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listDataMappingInventoryDataInventoryRelated,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            listDataMappingInventoryEntityDataInventoryRelatedSuccess(response)
          );
        } else {
          yield put(
            listDataMappingInventoryEntityDataInventoryRelatedFailed(response)
          );
        }
      } catch (error) {
        yield put(
          listDataMappingInventoryEntityDataInventoryRelatedFailed(error)
        );
      }
    }
  );
}

function* addDataMappingInventoryEntityDataInventoryRelated() {
  yield takeEvery(
    ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED,
    function* ({ inventory_type, ObjectUUID, payload }) {
      yield put(setLoadingEntity(true));
      try {
        const response = yield call(
          DataMappingService.addDataMappingInventoryDataInventoryRelated,
          inventory_type,
          ObjectUUID,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            addDataMappingInventoryEntityDataInventoryRelatedSuccess(
              response.data
            )
          );
          Notification("success", "เพิ่มหน่วยงานที่เกี่ยวข้องสำเร็จ");
        } else {
          yield put(
            addDataMappingInventoryEntityDataInventoryRelatedFailed(response)
          );
          Notification("error", "");
        }
        yield put(setLoadingEntity(false));
      } catch (error) {
        yield put(
          addDataMappingInventoryEntityDataInventoryRelatedFailed(error)
        );
        yield put(setLoadingEntity(false));
        Notification("error");
      }
    }
  );
}

function* deleteDataMappingInventoryEntityDataInventoryRelated() {
  yield takeEvery(
    DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED,
    function* ({ inventory_type, ObjectUUID, related_type, related_id }) {
      try {
        const response = yield call(
          DataMappingService.deleteDataMappingInventoryDataInventoryRelated,
          inventory_type,
          ObjectUUID,
          related_type,
          related_id
        );
        if (response.statusCode === 200) {
          yield put(
            deleteDataMappingInventoryEntityDataInventoryRelatedSuccess(
              related_id
            )
          );
          Notification("success", "ลบหน่วยงานที่เกี่ยวข้องสำเร็จ");
        } else {
          yield put(
            deleteDataMappingInventoryEntityDataInventoryRelatedFailed(response)
          );
          Notification("error", "");
        }
      } catch (error) {
        yield put(
          deleteDataMappingInventoryEntityDataInventoryRelatedFailed(error)
        );
        Notification("error", "");
      }
    }
  );
}

function* addDataMappingInventoryAssetsDataInventoryRelated() {
  yield takeEvery(
    ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED,
    function* ({ inventory_type, ObjectUUID, payload }) {
      yield put(setLoadingAsset(true));
      try {
        const response = yield call(
          DataMappingService.addDataMappingInventoryDataInventoryRelated,
          inventory_type,
          ObjectUUID,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            addDataMappingInventoryAssetsDataInventoryRelatedSuccess(
              response.data
            )
          );
          Notification("success", "เพิ่มประเภทข้อมูลที่เกี่ยวข้องสำเร็จ");
        } else {
          yield put(
            addDataMappingInventoryAssetsDataInventoryRelatedFailed(response)
          );
          Notification("error", "");
        }
        yield put(setLoadingAsset(false));
      } catch (error) {
        yield put(
          addDataMappingInventoryAssetsDataInventoryRelatedFailed(error)
        );
        yield put(setLoadingAsset(false));
        Notification("error");
      }
    }
  );
}

function* deleteDataMappingInventoryAssetsDataInventoryRelated() {
  yield takeEvery(
    DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED,
    function* ({ inventory_type, ObjectUUID, related_type, related_id }) {
      try {
        const response = yield call(
          DataMappingService.deleteDataMappingInventoryDataInventoryRelated,
          inventory_type,
          ObjectUUID,
          related_type,
          related_id
        );
        if (response.statusCode === 200) {
          yield put(
            deleteDataMappingInventoryAssetsInventoryRelatedSuccess(related_id)
          );
          Notification("success", "ลบประเภทข้อมูลที่เกี่ยวข้องสำเร็จ");
        } else {
          yield put(
            deleteDataMappingInventoryAssetsInventoryRelatedFailed(response)
          );
          Notification("error", "");
        }
      } catch (error) {
        yield put(
          deleteDataMappingInventoryAssetsInventoryRelatedFailed(error)
        );
        Notification("error", "");
      }
    }
  );
}

function* createDataMappingInventoryAssets() {
  yield takeEvery(
    CREATE_DATA_MAPPING_INVENTORY_ASSETS,
    function* ({ payload, onFinish, onFailed }) {
      yield put(setLoadingCreateDataMapping(true));
      try {
        const response = yield call(
          DataMappingService.createInventory,
          payload
        );
        if (response.statusCode === 200 && response.data) {
          yield put(createDataMappingInventoryAssetsSuccess(response.data));
          onFinish(response);
          Notification("success", "เพิ่มชุดข้อมูลสำเร็จ");
        } else {
          yield put(createDataMappingInventoryAssetsFailed(response));
          onFailed(response);
          Notification(
            "error"
            // "notification.internalServerError.create"
          );
        }
        yield put(setLoadingCreateDataMapping(false));
      } catch (error) {
        yield put(createDataMappingInventoryAssetsFailed(error));
        onFailed(error);
        yield put(setLoadingCreateDataMapping(false));
        Notification(
          "error"
          // "notification.internalServerError.create"
        );
        console.log(error);
      }
    }
  );
}

function* getDataMappingInventoryAssets() {
  yield takeEvery(GET_DATA_MAPPING_INVENTORY_ASSETS, function* ({ payload }) {
    yield put(setLoadingDataMapping(true));
    try {
      const response = yield call(DataMappingService.getInventory, payload);
      if (response.statusCode === 200) {
        yield put(getDataMappingInventoryAssetsSuccess(response.data));
      } else {
        yield put(getDataMappingInventoryAssetsFailed(response));
      }
      yield put(setLoadingDataMapping(false));
    } catch (error) {
      yield put(getDataMappingInventoryAssetsFailed(error));
      yield put(setLoadingDataMapping(false));
    }
  });
}

function* updateDataMappingInventoryAssets() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_INVENTORY_ASSETS,
    function* ({ payload, onFinish, onFailed }) {
      yield put(setLoadingCreateDataMapping(true));
      try {
        const response = yield call(
          DataMappingService.updateInventory,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateDataMappingInventoryAssetsSuccess(response.data));
          onFinish(response);
          Notification("success", "แก้ไขชุดข้อมูลสำเร็จ");
        } else {
          yield put(updateDataMappingInventoryAssetsFailed(response));
          onFailed(response);
          Notification(
            "error"
            // "notification.internalServerError.create"
          );
        }
        yield put(setLoadingCreateDataMapping(false));
      } catch (error) {
        yield put(updateDataMappingInventoryAssetsFailed(error));
        onFailed(error);
        Notification(
          "error"
          // "notification.internalServerError.create"
        );
        yield put(setLoadingCreateDataMapping(false));
      }
    }
  );
}

function* deleteDataMappingInventoryAssets() {
  yield takeEvery(
    DELETE_DATA_MAPPING_INVENTORY_ASSETS,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.deleteInventory,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            deleteDataMappingInventoryAssetsSuccess(payload.ObjectUUID)
          );
          onFinish(response);
          Notification("success", "ลบชุดข้อมูลสำเร็จ");
        } else {
          yield put(deleteDataMappingInventoryAssetsFailed(response));
          onFailed(response);
          Notification(
            "error"
            // "notification.internalServerError.create"
          );
        }
      } catch (error) {
        yield put(deleteDataMappingInventoryAssetsFailed(error));
        onFailed(error);
        Notification(
          "error"
          // "notification.internalServerError.create"
        );
        console.log(error);
      }
    }
  );
}

function* listDataMappingInventoryProcessingDataInventoryRelated() {
  yield takeEvery(
    LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listDataMappingInventoryDataInventoryRelated,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            listDataMappingInventoryProcessingDataInventoryRelatedSuccess(
              response
            )
          );
        } else {
          yield put(
            listDataMappingInventoryProcessingDataInventoryRelatedFailed(
              response
            )
          );
        }
      } catch (error) {
        yield put(
          listDataMappingInventoryProcessingDataInventoryRelatedFailed(error)
        );
      }
    }
  );
}

function* listDataMappingInventoryProcessing() {
  yield takeEvery(
    LIST_DATA_MAPPING_INVENTORY_PROCESSING,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listInventoryProcessing,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingInventoryProcessingSuccess(response));
        } else {
          yield put(listDataMappingInventoryProcessingFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingInventoryProcessingFailed(error));
      }
    }
  );
}

function* createDataMappingInventoryProcessing() {
  yield takeEvery(
    CREATE_DATA_MAPPING_INVENTORY_PROCESSING,
    function* ({ payload, onFinish, onFailed }) {
      yield put(setLoadingProcessing(true));
      try {
        const response = yield call(
          DataMappingService.createInventory,
          payload
        );
        if (response.statusCode === 200 && response.data) {
          yield put(createDataMappingInventoryProcessingSuccess(response.data));
          onFinish(response);
          Notification("success", "เพิ่มกิจกรรมการประมวลผลที่เกี่ยวข้องสำเร็จ");
        } else {
          yield put(createDataMappingInventoryProcessingFailed(response));
          onFailed(response);
          Notification("error", "");
        }
        yield put(setLoadingProcessing(false));
      } catch (error) {
        yield put(createDataMappingInventoryProcessingFailed(error));
        onFailed(error);
        yield put(setLoadingProcessing(false));
        Notification("error", "");
      }
    }
  );
}

function* getDataMappingInventoryProcessing() {
  yield takeEvery(
    GET_DATA_MAPPING_INVENTORY_PROCESSING,
    function* ({ payload }) {
      try {
        const response = yield call(DataMappingService.getInventory, payload);
        if (response.statusCode === 200) {
          yield put(getDataMappingInventoryProcessingSuccess(response.data));
        } else {
          yield put(getDataMappingInventoryProcessingFailed(response));
        }
      } catch (error) {
        yield put(getDataMappingInventoryProcessingFailed(error));
      }
    }
  );
}

function* updateDataMappingInventoryProcessing() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_INVENTORY_PROCESSING,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.updateInventory,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateDataMappingInventoryProcessingSuccess(response.data));
          onFinish(response.data);
        } else {
          yield put(updateDataMappingInventoryProcessingFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(updateDataMappingInventoryProcessingFailed(error));
        onFailed(error);
      }
    }
  );
}

function* deleteDataMappingInventoryProcessing() {
  yield takeEvery(
    DELETE_DATA_MAPPING_INVENTORY_PROCESSING,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.deleteInventory,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            deleteDataMappingInventoryProcessingSuccess(payload.ObjectUUID)
          );
          Notification("success", "ลบกิจกรรมการประมวลผลที่เกี่ยวข้องสำเร็จ");
        } else {
          yield put(deleteDataMappingInventoryProcessingFailed(response));
          Notification("error", "");
        }
      } catch (error) {
        yield put(deleteDataMappingInventoryProcessingFailed(error));
        Notification("error", "");
      }
    }
  );
}

function* listDataMappingInventoryEntity() {
  yield takeEvery(LIST_DATA_MAPPING_INVENTORY_ENTITY, function* ({ payload }) {
    try {
      const response = yield call(
        DataMappingService.listInventoryEntity,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listDataMappingInventoryEntitySuccess(response));
      } else {
        yield put(listDataMappingInventoryEntityFailed(response));
      }
    } catch (error) {
      yield put(listDataMappingInventoryEntityFailed(error));
    }
  });
}

function* createDataMappingInventoryEntity() {
  yield takeEvery(
    CREATE_DATA_MAPPING_INVENTORY_ENTITY,
    function* ({ payload, onFinish, onFailed }) {
      yield put(setLoadingCreateDataMapping(true));
      try {
        const response = yield call(
          DataMappingService.createInventory,
          payload
        );
        if (response.statusCode === 200 && response.data) {
          yield put(createDataMappingInventoryEntitySuccess(response.data));
          onFinish();
          Notification("success", "เพิ่มหน่วยงานผู้จัดเก็บข้อมูลสำเร็จ");
        } else {
          yield put(createDataMappingInventoryEntityFailed(response));
          onFailed();
          Notification("error", "");
        }
        yield put(setLoadingCreateDataMapping(false));
      } catch (error) {
        yield put(createDataMappingInventoryEntityFailed(error));
        onFailed();
        console.log(error);
        yield put(setLoadingCreateDataMapping(false));
        Notification("error", "");
      }
    }
  );
}

function* getDataMappingInventoryEntity() {
  yield takeEvery(GET_DATA_MAPPING_INVENTORY_ENTITY, function* ({ payload }) {
    yield put(setLoadingDataMapping(true));
    try {
      const response = yield call(DataMappingService.getInventory, payload);
      if (response.statusCode === 200) {
        yield put(getDataMappingInventoryEntitySuccess(response.data));
      } else {
        yield put(getDataMappingInventoryEntityFailed(response));
      }
      yield put(setLoadingDataMapping(false));
    } catch (error) {
      yield put(getDataMappingInventoryEntityFailed(error));
      yield put(setLoadingDataMapping(false));
    }
  });
}

function* updateDataMappingInventoryEntity() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_INVENTORY_ENTITY,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.updateInventory,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateDataMappingInventoryEntitySuccess(response.data));
          onFinish(response.data);
          Notification("success", "แก้ไขหน่วยงานผู้จัดเก็บข้อมูลสำเร็จ");
        } else {
          yield put(updateDataMappingInventoryEntityFailed(response));
          onFailed(response);
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถเพิ่มหรือแก้ไขข้อมูลได้"
          );
        }
      } catch (error) {
        console.log(error);
        yield put(updateDataMappingInventoryEntityFailed(error));
        onFailed(error);
        Notification(
          "error",
          "เกิดข้อผิดพลาด ไม่สามารถเพิ่มหรือแก้ไขข้อมูลได้"
        );
      }
    }
  );
}

function* deleteDataMappingInventoryEntity() {
  yield takeEvery(
    DELETE_DATA_MAPPING_INVENTORY_ENTITY,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.deleteInventory,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            deleteDataMappingInventoryEntitySuccess(payload.ObjectUUID)
          );
          onFinish(response);
          Notification("success", "ลบหน่วยงานผู้จัดเก็บข้อมูลสำเร็จ");
        } else {
          yield put(deleteDataMappingInventoryEntityFailed(response));
          onFailed(response);
          Notification("error", "");
        }
      } catch (error) {
        yield put(deleteDataMappingInventoryEntityFailed(error));
        onFailed(error);
        Notification("error", "");
      }
    }
  );
}

function* listDataMappingInventoryVendor() {
  yield takeEvery(LIST_DATA_MAPPING_INVENTORY_VENDOR, function* ({ payload }) {
    try {
      const response = yield call(
        DataMappingService.listInventoryVendor,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listDataMappingInventoryVendorSuccess(response));
      } else {
        yield put(listDataMappingInventoryVendorFailed(response));
      }
    } catch (error) {
      yield put(listDataMappingInventoryVendorFailed(error));
    }
  });
}

function* createDataMappingInventoryVendor() {
  yield takeEvery(
    CREATE_DATA_MAPPING_INVENTORY_VENDOR,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.createInventory,
          payload
        );
        if (response.statusCode === 200 && response.data) {
          yield put(createDataMappingInventoryVendorSuccess(response.data));
          onFinish(response);
        } else {
          yield put(createDataMappingInventoryVendorFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(createDataMappingInventoryVendorFailed(error));
        onFailed(error);
      }
    }
  );
}

function* getDataMappingInventoryVendor() {
  yield takeEvery(GET_DATA_MAPPING_INVENTORY_VENDOR, function* ({ payload }) {
    try {
      const response = yield call(DataMappingService.getInventory, payload);
      if (response.statusCode === 200) {
        yield put(getDataMappingInventoryVendorSuccess(response.data));
      } else {
        yield put(getDataMappingInventoryVendorFailed(response));
      }
    } catch (error) {
      yield put(getDataMappingInventoryVendorFailed(error));
    }
  });
}

function* updateDataMappingInventoryVendor() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_INVENTORY_VENDOR,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.updateInventory,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateDataMappingInventoryVendorSuccess(response.data));
          onFinish(response.data);
        } else {
          yield put(updateDataMappingInventoryVendorFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(updateDataMappingInventoryVendorFailed(error));
        onFailed(error);
      }
    }
  );
}

function* deleteDataMappingInventoryVendor() {
  yield takeEvery(
    DELETE_DATA_MAPPING_INVENTORY_VENDOR,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.deleteInventory,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            deleteDataMappingInventoryVendorSuccess(payload.ObjectUUID)
          );
          onFinish(response);
        } else {
          yield put(deleteDataMappingInventoryVendorFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(deleteDataMappingInventoryVendorFailed(error));
        onFailed(error);
      }
    }
  );
}

function* listDataMappingSettingAutomationRule() {
  yield takeEvery(
    LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listSettingAutomationRule,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingSettingAutomationRuleSuccess(response));
        } else {
          yield put(listDataMappingSettingAutomationRuleFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingSettingAutomationRuleFailed(error));
      }
    }
  );
}

function* createDataMappingSettingAutomationRule() {
  yield takeEvery(
    CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.createSettingAutomationRule,
          payload
        );
        if (response.statusCode === 200 && response.data) {
          yield put(
            createDataMappingSettingAutomationRuleSuccess(response.data)
          );
          onFinish(response);
        } else {
          yield put(createDataMappingSettingAutomationRuleFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(createDataMappingSettingAutomationRuleFailed(error));
        onFailed(error);
      }
    }
  );
}

function* getDataMappingSettingAutomationRule() {
  yield takeEvery(
    GET_DATA_MAPPING_SETTING_AUTOMATION_RULE,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.getSettingAutomationRule,
          payload
        );
        if (response.statusCode === 200) {
          yield put(getDataMappingSettingAutomationRuleSuccess(response));
        } else {
          yield put(getDataMappingSettingAutomationRuleFailed(response));
        }
      } catch (error) {
        yield put(getDataMappingSettingAutomationRuleFailed(error));
      }
    }
  );
}

function* updateDataMappingSettingAutomationRule() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.updateSettingAutomationRule,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            updateDataMappingSettingAutomationRuleSuccess(response.data)
          );
          onFinish(response.data);
        } else {
          yield put(updateDataMappingSettingAutomationRuleFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(updateDataMappingSettingAutomationRuleFailed(error));
        onFailed(error);
      }
    }
  );
}

function* deleteDataMappingSettingAutomationRule() {
  yield takeEvery(
    DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.deleteSettingAutomationRule,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            deleteDataMappingSettingAutomationRuleSuccess(payload.ObjectUUID)
          );
          onFinish(response);
        } else {
          yield put(deleteDataMappingSettingAutomationRuleFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(deleteDataMappingSettingAutomationRuleFailed(error));
        onFailed(error);
      }
    }
  );
}

function* listDataMappingSettingAssessmentTemplate() {
  yield takeEvery(
    LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listSettingAssessmentTemplate,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingSettingAssessmentTemplateSuccess(response));
        } else {
          yield put(listDataMappingSettingAssessmentTemplateFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingSettingAssessmentTemplateFailed(error));
      }
    }
  );
}

function* listDataMappingSettingAssessmentWorkflow() {
  yield takeEvery(
    LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listSettingAssessmentWorkflow,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingSettingAssessmentWorkflowSuccess(response));
        } else {
          yield put(listDataMappingSettingAssessmentWorkflowFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingSettingAssessmentWorkflowFailed(error));
      }
    }
  );
}

function* createDataMappingSettingAssessmentWorkflow() {
  yield takeEvery(
    CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.createSettingAssessmentWorkflow,
          payload
        );
        if (response.statusCode === 200 && response.data) {
          yield put(
            createDataMappingSettingAssessmentWorkflowSuccess(response.data)
          );
          onFinish(response);
        } else {
          yield put(createDataMappingSettingAssessmentWorkflowFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(createDataMappingSettingAssessmentWorkflowFailed(error));
        onFailed(error);
      }
    }
  );
}

function* getDataMappingSettingAssessmentWorkflow() {
  yield takeEvery(
    GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.getSettingAssessmentWorkflow,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            getDataMappingSettingAssessmentWorkflowSuccess(response.data)
          );
        } else {
          yield put(getDataMappingSettingAssessmentWorkflowFailed(response));
        }
      } catch (error) {
        yield put(getDataMappingSettingAssessmentWorkflowFailed(error));
      }
    }
  );
}

function* updateDataMappingSettingAssessmentWorkflow() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.updateSettingAssessmentWorkflow,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            updateDataMappingSettingAssessmentWorkflowSuccess(response)
          );
          onFinish(response.data);
        } else {
          yield put(updateDataMappingSettingAssessmentWorkflowFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(updateDataMappingSettingAssessmentWorkflowFailed(error));
        onFailed(error);
      }
    }
  );
}

function* deleteDataMappingSettingAssessmentWorkflow() {
  yield takeEvery(
    DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.deleteSettingAssessmentWorkflow,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            deleteDataMappingSettingAssessmentWorkflowSuccess(
              payload.ObjectUUID
            )
          );
          onFinish(response);
        } else {
          yield put(deleteDataMappingSettingAssessmentWorkflowFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(deleteDataMappingSettingAssessmentWorkflowFailed(error));
        onFailed(error);
      }
    }
  );
}

function* listDataMappingSettingInventoryManagement() {
  yield takeEvery(
    LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listSettingInventoryManagement,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingSettingInventoryManagementSuccess(response));
        } else {
          yield put(listDataMappingSettingInventoryManagementFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingSettingInventoryManagementFailed(error));
      }
    }
  );
}

function* createDataMappingManagement() {
  yield takeEvery(
    CREATE_DATA_MAPPING_MANAGEMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.createSettingInventoryManagement,
          payload
        );
        if (response.statusCode === 200 && response.data) {
          yield put(createDataMappingManagementSuccess(response.data));
          onFinish(response);
        } else {
          yield put(createDataMappingManagementFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(createDataMappingManagementFailed(error));
        onFailed(error);
        console.log(error);
      }
    }
  );
}

function* listDataMappingInventory() {
  yield takeEvery(LIST_DATA_MAPPING_INVENTORY, function* ({ payload }) {
    try {
      const response = yield call(
        DataMappingService.listDataMappingInventory,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listDataMappingInventorySuccess(response));
      } else {
        yield put(listDataMappingInventoryFailed(response));
      }
    } catch (error) {
      yield put(listDataMappingInventoryFailed(error));
    }
  });
}

function* getDataMappingInventory() {
  yield takeEvery(GET_DATA_MAPPING_INVENTORY, function* ({ payload }) {
    try {
      const response = yield call(
        DataMappingService.getDataMappingInventoryDetail,
        payload
      );
      if (response.statusCode === 200) {
        yield put(getDataMappingInventorySuccess(response.data));
      } else {
        yield put(getDataMappingInventoryFailed(response));
      }
    } catch (error) {
      yield put(getDataMappingInventoryFailed(error));
    }
  });
}

function* updateDataMappingInventory() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_INVENTORY,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          DataMappingService.updateDataMappingInventory,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateDataMappingInventorySuccess(response.data));
          onFinish(response.data);
        } else {
          yield put(updateDataMappingInventoryFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(updateDataMappingInventoryFailed(error));
        onFailed(error);
      }
    }
  );
}

function* listDataMappingInventoryAssetsDataLifeCycle() {
  yield takeEvery(
    LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listDataMappingInventoryDataLifeCycle,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            listDataMappingInventoryAssetsDataLifeCircleSuccess(response)
          );
        } else {
          yield put(
            listDataMappingInventoryAssetsDataLifeCircleFailed(response)
          );
        }
      } catch (error) {
        yield put(listDataMappingInventoryAssetsDataLifeCircleFailed(error));
      }
    }
  );
}

function* listDataMappingInventoryLocation() {
  yield takeEvery(
    LIST_DATA_MAPPING_INVENTORY_LOCATION,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listDataMappingInventoryLocation,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingInventoryLocationSuccess(response));
        } else {
          yield put(listDataMappingInventoryLocationFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingInventoryLocationFailed(error));
      }
    }
  );
}

function* listDataMappingInventoryControl() {
  yield takeEvery(LIST_DATA_MAPPING_INVENTORY_CONTROL, function* ({ payload }) {
    try {
      const response = yield call(
        DataMappingService.listDataMappingInventoryControl,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listDataMappingInventoryControlSuccess(response));
      } else {
        yield put(listDataMappingInventoryControlFailed(response));
      }
    } catch (error) {
      yield put(listDataMappingInventoryControlFailed(error));
    }
  });
}

function* listDataMappingInventoryControlParam() {
  yield takeEvery(
    LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM,
    function* ({ module }) {
      try {
        const response = yield call(
          DataMappingService.listDataMappingInventoryControlParam,
          module
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingInventoryControlParamSuccess(response));
        } else {
          yield put(listDataMappingInventoryControlParamFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingInventoryControlParamFailed(error));
      }
    }
  );
}

function* listDataMappingInventoryLinkControl() {
  yield takeEvery(
    LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL,
    function* ({ inventory_type, ObjectUUID }) {
      try {
        const response = yield call(
          DataMappingService.listDataMappingInventoryLinkControl,
          inventory_type,
          ObjectUUID
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingInventoryLinkControlSuccess(response));
        } else {
          yield put(listDataMappingInventoryLinkControlFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingInventoryLinkControlFailed(error));
      }
    }
  );
}

function* addDataMappingInventoryControl() {
  yield takeEvery(
    ADD_DATA_MAPPING_INVENTORY_CONTROL,
    function* ({ inventory_type, ObjectUUID, payload }) {
      yield put(setLoadingControl(true));
      try {
        const response = yield call(
          DataMappingService.addDataMappingInventoryControl,
          inventory_type,
          ObjectUUID,
          payload
        );
        if (response.statusCode === 200) {
          yield put(addDataMappingInventoryControlSuccess(response.data));
          Notification("success", "เพิ่มมาตรการควบคุมที่เกี่ยวข้องสำเร็จ");
        } else {
          yield put(addDataMappingInventoryControlFailed(response));
          Notification("error", "");
        }
        yield put(setLoadingControl(false));
      } catch (error) {
        console.log(error);
        yield put(addDataMappingInventoryControlFailed(error));
        yield put(setLoadingControl(false));
        Notification("error", "");
      }
    }
  );
}

function* deleteDataMappingInventoryControl() {
  yield takeEvery(
    DELETE_DATA_MAPPING_INVENTORY_CONTROL,
    function* ({ inventory_type, ObjectUUID, controls_id }) {
      try {
        const response = yield call(
          DataMappingService.deleteDataMappingInventoryControl,
          inventory_type,
          ObjectUUID,
          controls_id
        );
        if (response.statusCode === 200) {
          yield put(deleteDataMappingInventoryControlSuccess(controls_id));
          Notification("success", "ลบมาตรการควบคุมที่เกี่ยวข้องสำเร็จ");
        } else {
          yield put(deleteDataMappingInventoryControlFailed(response));
          Notification("error", "");
        }
      } catch (error) {
        console.log(error);
        yield put(deleteDataMappingInventoryControlFailed(error));
        Notification("error", "");
      }
    }
  );
}

function* listDataMappingDataWorkflow() {
  yield takeEvery(LIST_DATA_MAPPING_DATA_WORKFLOW, function* () {
    try {
      const response = yield call(DataMappingService.listInventoryDataWorkflow);
      if (response.statusCode === 200) {
        yield put(listDataMappingDataWorkflowSuccess(response));
      } else {
        yield put(listDataMappingDataWorkflowFailed(response));
      }
    } catch (error) {
      yield put(listDataMappingDataWorkflowFailed(error));
    }
  });
}

function* createDataMappingDataWorkflow() {
  yield takeEvery(
    CREATE_DATA_MAPPING_DATA_WORKFLOW,
    function* ({ payload, onFailed, onFinish }) {
      yield put(setLoadingWorkflow(true));
      try {
        const response = yield call(
          DataMappingService.createInventoryDataWorkflow,
          payload
        );
        if (response.statusCode === 200 && response.data) {
          yield put(createDataMappingDataWorkflowSuccess(response.data));
          onFinish(response);
          Notification("success", "เพิ่มกระแสข้อมูลสำเร็จ");
        } else {
          yield put(createDataMappingDataWorkflowFailed(response));
          onFailed(response);
          Notification("error", "");
        }
        yield put(setLoadingWorkflow(false));
      } catch (error) {
        yield put(createDataMappingDataWorkflowFailed(error));
        onFailed(error);
        console.log(error);
        yield put(setLoadingWorkflow(false));
        Notification("error", "");
      }
    }
  );
}

function* deleteDataMappingDataWorkflow() {
  yield takeEvery(
    DELETE_DATA_MAPPING_DATA_WORKFLOW,
    function* ({ payload, onFailed, onFinish }) {
      try {
        const response = yield call(
          DataMappingService.deleteInventoryDataWorkflow,
          payload
        );
        if (response.statusCode === 200 && response.data) {
          yield put(deleteDataMappingDataWorkflowSuccess(payload));
          onFinish(response);
          Notification("success", "ลบกระแสข้อมูลสำเร็จ");
        } else {
          yield put(deleteDataMappingDataWorkflowFailed(response));
          onFailed(response);
          Notification("error", "");
        }
      } catch (error) {
        yield put(deleteDataMappingDataWorkflowFailed(error));
        onFailed(error);
        console.log(error);
        Notification("error", "");
      }
    }
  );
}

function* listDataMappingInventoryITAsset() {
  yield takeEvery(
    LIST_DATA_MAPPING_INVENTORY_IT_ASSET,
    function* ({ payload }) {
      try {
        const response = yield call(
          DataMappingService.listDataMappingInventoryITAsset,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listDataMappingInventoryITAssetSuccess(response));
        } else {
          yield put(listDataMappingInventoryITAssetFailed(response));
        }
      } catch (error) {
        yield put(listDataMappingInventoryITAssetFailed(error));
      }
    }
  );
}

function* getDataMappingFlow() {
  yield takeEvery(GET_DATA_MAPPING_FLOW, function* ({ payload }) {
    yield put(setLoadingDataMapping(true));
    try {
      const response = yield call(
        DataMappingService.listDataMappingFlow,
        payload
      );
      if (response.statusCode === 200) {
        yield put(getDataMappingFlowSuccess(response.data));
      } else {
        yield put(getDataMappingFlowFailed(response));
      }
      yield put(setLoadingDataMapping(false));
    } catch (error) {
      yield put(getDataMappingFlowFailed(error));
      yield put(setLoadingDataMapping(false));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(listDataMappingDashboard),
    fork(listDataMappingAssessmentActivated),
    fork(createDataMappingAssessmentActivated),
    fork(updateDataMappingAssessmentActivatedToArchive),
    fork(updateDataMappingAssessmentActivatedToDeleted),
    fork(listDataMappingAssessmentArchive),
    fork(updateDataMappingAssessmentArchiveToActivated),
    fork(updateDataMappingAssessmentArchiveToDeleted),
    fork(listDataMappingAssessmentDeleted),
    fork(updateDataMappingAssessmentDeletedToActivate),
    fork(deleteDataMappingAssessmentDeletedToDeleted),
    fork(listDataMappingInventoryAssets),
    fork(createDataMappingInventoryAssets),
    fork(getDataMappingInventoryAssets),
    fork(updateDataMappingInventoryAssets),
    fork(deleteDataMappingInventoryAssets),
    fork(listDataMappingInventoryProcessingDataInventoryRelated),
    fork(listDataMappingInventoryProcessing),
    fork(createDataMappingInventoryProcessing),
    fork(getDataMappingInventoryProcessing),
    fork(updateDataMappingInventoryProcessing),
    fork(deleteDataMappingInventoryProcessing),
    fork(listDataMappingInventoryEntity),
    fork(createDataMappingInventoryEntity),
    fork(getDataMappingInventoryEntity),
    fork(updateDataMappingInventoryEntity),
    fork(deleteDataMappingInventoryEntity),
    fork(listDataMappingInventoryVendor),
    fork(createDataMappingInventoryVendor),
    fork(getDataMappingInventoryVendor),
    fork(updateDataMappingInventoryVendor),
    fork(deleteDataMappingInventoryVendor),
    fork(listDataMappingRisk),
    fork(createDataMappingRisk),
    fork(getDataMappingRisk),
    fork(updateDataMappingRisk),
    fork(deleteDataMappingRisk),
    fork(listDataMappingSettingAutomationRule),
    fork(createDataMappingSettingAutomationRule),
    fork(getDataMappingSettingAutomationRule),
    fork(updateDataMappingSettingAutomationRule),
    fork(deleteDataMappingSettingAutomationRule),
    fork(listDataMappingSettingAssessmentTemplate),
    fork(listDataMappingSettingAssessmentWorkflow),
    fork(createDataMappingSettingAssessmentWorkflow),
    fork(getDataMappingSettingAssessmentWorkflow),
    fork(updateDataMappingSettingAssessmentWorkflow),
    fork(deleteDataMappingSettingAssessmentWorkflow),
    fork(listDataMappingSettingInventoryManagement),
    fork(listDataMappingInventory),
    fork(getDataMappingInventory),
    fork(createDataMappingManagement),
    fork(updateDataMappingInventory),
    fork(listDataMappingInventoryAssetsAssessment),
    fork(addDataMappingInventoryAssetsAssessment),
    fork(deleteDataMappingInventoryAssetsAssessment),
    fork(listDataMappingInventoryAssetsDataElement),
    fork(addDataMappingInventoryAssetsDataElement),
    fork(deleteDataMappingInventoryAssetsDataElement),
    fork(listDataMappingInventoryAssetsDataLifeCycle),
    fork(listDataMappingInventoryAssetsDataInventoryRelated),
    fork(addDataMappingInventoryAssetsDataInventoryRelated),
    fork(listDataMappingInventoryLocation),
    fork(listDataMappingInventoryControl),
    fork(listDataMappingInventoryControlParam),
    fork(listDataMappingInventoryLinkControl),
    fork(addDataMappingInventoryControl),
    fork(deleteDataMappingInventoryAssetsDataInventoryRelated),
    fork(listDataMappingDataWorkflow),
    fork(createDataMappingDataWorkflow),
    fork(deleteDataMappingDataWorkflow),
    fork(deleteDataMappingInventoryControl),
    fork(listDataMappingInventoryITAsset),
    fork(listDataMappingInventoryEntityDataInventoryRelated),
    fork(getDataMappingFlow),
    fork(addDataMappingInventoryEntityDataInventoryRelated),
    fork(deleteDataMappingInventoryEntityDataInventoryRelated),
  ]);
}
