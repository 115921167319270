import { all, fork } from "redux-saga/effects";
import { getDataSubjectRightManagementDashboard } from "./Dashboard";
import {
  listDataSubjectRightManagementRequest,
  listDataSubjectRightManagementRequestNextPage,
  getDataSubjectRightManagementRequest,
  updateDataSubjectRightManagementRequestStage,
  listDataSubjectRightManagementRequestTask,
  manageDataSubjectRightManagementRequestTask,
  searchDataRequest,
} from "./Request";
import {
  listDataSubjectRightManagementWebForm,
  listDataSubjectRightManagementWebFormTemplate,
  createDataSubjectRightManagementWebForm,
  deleteDataSubjectRightManagementWebForm,
  getDataSubjectRightManagementWebForm,
  getDataSubjectRightManagementWebFormFields,
  updateDataSubjectRightManagementWebForm,
  publishDataSubjectRightManagementWebForm,
  listDataSubjectRightManagementWebFormVersion,
  getDataSubjectRightManagementWebFormVersion,
} from "./WebForm";
import {
  listDataSubjectRightManagementWorkflow,
  getDataSubjectRightManagementDefaultWorkflow,
  createDataSubjectRightManagementWorkflow,
  getDataSubjectRightManagementWorkflow,
  updateDataSubjectRightManagementWorkflow,
  publishDataSubjectRightManagementWorkflow,
  deleteDataSubjectRightManagementWorkflow,
  listDataSubjectRightManagementWorkflowTask,
  createDataSubjectRightManagementWorkflowTask,
  updateDataSubjectRightManagementWorkflowTask,
  deleteDataSubjectRightManagementWorkflowTask,
} from "./Workflow";
import {
  listDataSubjectRightManagementTask,
  listDataSubjectRightManagementTaskNextPage,
  getDataSubjectRightManagementTask,
  updateDataSubjectRightManagementTask,
  searchDataTask,
} from "./Task";

export default function* rootSaga() {
  yield all([
    fork(getDataSubjectRightManagementDashboard),
    fork(listDataSubjectRightManagementRequest),
    fork(listDataSubjectRightManagementRequestNextPage),
    fork(getDataSubjectRightManagementRequest),
    fork(updateDataSubjectRightManagementRequestStage),
    fork(listDataSubjectRightManagementWebForm),
    fork(listDataSubjectRightManagementWebFormTemplate),
    fork(createDataSubjectRightManagementWebForm),
    fork(deleteDataSubjectRightManagementWebForm),
    fork(getDataSubjectRightManagementWebForm),
    fork(getDataSubjectRightManagementWebFormFields),
    fork(updateDataSubjectRightManagementWebForm),
    fork(publishDataSubjectRightManagementWebForm),
    fork(listDataSubjectRightManagementWebFormVersion),
    fork(getDataSubjectRightManagementWebFormVersion),
    fork(listDataSubjectRightManagementWorkflow),
    fork(getDataSubjectRightManagementDefaultWorkflow),
    fork(createDataSubjectRightManagementWorkflow),
    fork(getDataSubjectRightManagementWorkflow),
    fork(updateDataSubjectRightManagementWorkflow),
    fork(publishDataSubjectRightManagementWorkflow),
    fork(listDataSubjectRightManagementRequestTask),
    fork(manageDataSubjectRightManagementRequestTask),
    fork(deleteDataSubjectRightManagementWorkflow),
    fork(listDataSubjectRightManagementWorkflowTask),
    fork(listDataSubjectRightManagementTask),
    fork(listDataSubjectRightManagementTaskNextPage),
    fork(getDataSubjectRightManagementTask),
    fork(updateDataSubjectRightManagementTask),
    fork(createDataSubjectRightManagementWorkflowTask),
    fork(updateDataSubjectRightManagementWorkflowTask),
    fork(deleteDataSubjectRightManagementWorkflowTask),
    fork(searchDataRequest),
    fork(searchDataTask),
  ]);
}
