export const LIST_DATA_MAPPING_DASHBOARD = "LIST_DATA_MAPPING_DASHBOARD";
export const LIST_DATA_MAPPING_DASHBOARD_SUCCESS =
  "LIST_DATA_MAPPING_DASHBOARD_SUCCESS";
export const LIST_DATA_MAPPING_DASHBOARD_FAILED =
  "LIST_DATA_MAPPING_DASHBOARD_FAILED";

export const LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED =
  "LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED";
export const LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED_SUCCESS =
  "LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED_SUCCESS";
export const LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED_FAILED =
  "LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED_FAILED";
export const CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED =
  "CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED";
export const CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_SUCCESS =
  "CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_SUCCESS";
export const CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_FAILED =
  "CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_FAILED";
export const UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE =
  "UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE";
export const UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE_SUCCESS =
  "UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE_SUCCESS";
export const UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE_FAILED =
  "UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE_FAILED";
export const UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED =
  "UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED";
export const UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED_SUCCESS =
  "UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED_SUCCESS";
export const UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED_FAILED =
  "UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED_FAILED";

export const LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE =
  "LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE";
export const LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE_SUCCESS =
  "LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE_SUCCESS";
export const LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE_FAILED =
  "LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE_FAILED";
export const UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED =
  "UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED";
export const UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED_SUCCESS =
  "UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED_SUCCESS";
export const UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED_FAILED =
  "UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED_FAILED";
export const UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED =
  "UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED";
export const UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED_SUCCESS =
  "UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED_SUCCESS";
export const UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED_FAILED =
  "UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED_FAILED";

export const LIST_DATA_MAPPING_ASSESSMENT_DELETED =
  "LIST_DATA_MAPPING_ASSESSMENT_DELETED";
export const LIST_DATA_MAPPING_ASSESSMENT_DELETED_SUCCESS =
  "LIST_DATA_MAPPING_ASSESSMENT_DELETED_SUCCESS";
export const LIST_DATA_MAPPING_ASSESSMENT_DELETED_FAILED =
  "LIST_DATA_MAPPING_ASSESSMENT_DELETED_FAILED";
export const UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED =
  "UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED";
export const UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED_SUCCESS =
  "UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED_SUCCESS";
export const UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED_FAILED =
  "UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED_FAILED";
export const DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED =
  "DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED";
export const DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED_SUCCESS =
  "DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED_SUCCESS";
export const DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED_FAILED =
  "DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED_FAILED";

export const LIST_DATA_MAPPING_RISK = "LIST_DATA_MAPPING_RISK";
export const LIST_DATA_MAPPING_RISK_SUCCESS = "LIST_DATA_MAPPING_RISK_SUCCESS";
export const LIST_DATA_MAPPING_RISK_FAILED = "LIST_DATA_MAPPING_RISK_FAILED";
export const CREATE_DATA_MAPPING_RISK = "CREATE_DATA_MAPPING_RISK";
export const CREATE_DATA_MAPPING_RISK_SUCCESS =
  "CREATE_DATA_MAPPING_RISK_SUCCESS";
export const CREATE_DATA_MAPPING_RISK_FAILED =
  "CREATE_DATA_MAPPING_RISK_FAILED";
export const GET_DATA_MAPPING_RISK = "GET_DATA_MAPPING_RISK";
export const GET_DATA_MAPPING_RISK_SUCCESS = "GET_DATA_MAPPING_RISK_SUCCESS";
export const GET_DATA_MAPPING_RISK_FAILED = "GET_DATA_MAPPING_RISK_FAILED";
export const UPDATE_DATA_MAPPING_RISK = "UPDATE_DATA_MAPPING_RISK";
export const UPDATE_DATA_MAPPING_RISK_SUCCESS =
  "UPDATE_DATA_MAPPING_RISK_SUCCESS";
export const UPDATE_DATA_MAPPING_RISK_FAILED =
  "UPDATE_DATA_MAPPING_RISK_FAILED";
export const DELETE_DATA_MAPPING_RISK = "DELETE_DATA_MAPPING_RISK";
export const DELETE_DATA_MAPPING_RISK_SUCCESS =
  "DELETE_DATA_MAPPING_RISK_SUCCESS";
export const DELETE_DATA_MAPPING_RISK_FAILED =
  "DELETE_DATA_MAPPING_RISK_FAILED";

export const LIST_DATA_MAPPING_INVENTORY_ASSETS =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS";
export const LIST_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_ASSETS_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_FAILED";
export const LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT";
export const LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_FAILED";
export const ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT =
  "ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT";
export const ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS =
  "ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS";
export const ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_FAILED =
  "ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_FAILED";
export const DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT =
  "DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT";
export const DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS =
  "DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS";
export const DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_FAILED =
  "DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_FAILED";
export const LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT";
export const LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_FAILED";
export const ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT =
  "ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT";
export const ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS =
  "ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS";
export const ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_FAILED =
  "ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_FAILED";
export const DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT =
  "DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT";
export const DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS =
  "DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS";
export const DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_FAILED =
  "DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_FAILED";
export const SET_DATA_MAPPING_INVENTORY_ASSETS =
  "SET_DATA_MAPPING_INVENTORY_ASSETS";
export const CREATE_DATA_MAPPING_INVENTORY_ASSETS =
  "CREATE_DATA_MAPPING_INVENTORY_ASSETS";
export const CREATE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS =
  "CREATE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS";
export const CREATE_DATA_MAPPING_INVENTORY_ASSETS_FAILED =
  "CREATE_DATA_MAPPING_INVENTORY_ASSETS_FAILED";
export const GET_DATA_MAPPING_INVENTORY_ASSETS =
  "GET_DATA_MAPPING_INVENTORY_ASSETS";
export const GET_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS =
  "GET_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS";
export const GET_DATA_MAPPING_INVENTORY_ASSETS_FAILED =
  "GET_DATA_MAPPING_INVENTORY_ASSETS_FAILED";
export const UPDATE_DATA_MAPPING_INVENTORY_ASSETS =
  "UPDATE_DATA_MAPPING_INVENTORY_ASSETS";
export const UPDATE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS =
  "UPDATE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS";
export const UPDATE_DATA_MAPPING_INVENTORY_ASSETS_FAILED =
  "UPDATE_DATA_MAPPING_INVENTORY_ASSETS_FAILED";
export const DELETE_DATA_MAPPING_INVENTORY_ASSETS =
  "DELETE_DATA_MAPPING_INVENTORY_ASSETS";
export const DELETE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS =
  "DELETE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS";
export const DELETE_DATA_MAPPING_INVENTORY_ASSETS_FAILED =
  "DELETE_DATA_MAPPING_INVENTORY_ASSETS_FAILED";

export const LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED =
  "LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED";
export const LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED_FAILED";
export const LIST_DATA_MAPPING_INVENTORY_PROCESSING =
  "LIST_DATA_MAPPING_INVENTORY_PROCESSING";
export const LIST_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_PROCESSING_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_PROCESSING_FAILED";
export const SET_DATA_MAPPING_INVENTORY_PROCESSING =
  "SET_DATA_MAPPING_INVENTORY_PROCESSING";
export const CREATE_DATA_MAPPING_INVENTORY_PROCESSING =
  "CREATE_DATA_MAPPING_INVENTORY_PROCESSING";
export const CREATE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS =
  "CREATE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS";
export const CREATE_DATA_MAPPING_INVENTORY_PROCESSING_FAILED =
  "CREATE_DATA_MAPPING_INVENTORY_PROCESSING_FAILED";
export const GET_DATA_MAPPING_INVENTORY_PROCESSING =
  "GET_DATA_MAPPING_INVENTORY_PROCESSING";
export const GET_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS =
  "GET_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS";
export const GET_DATA_MAPPING_INVENTORY_PROCESSING_FAILED =
  "GET_DATA_MAPPING_INVENTORY_PROCESSING_FAILED";
export const UPDATE_DATA_MAPPING_INVENTORY_PROCESSING =
  "UPDATE_DATA_MAPPING_INVENTORY_PROCESSING";
export const UPDATE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS =
  "UPDATE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS";
export const UPDATE_DATA_MAPPING_INVENTORY_PROCESSING_FAILED =
  "UPDATE_DATA_MAPPING_INVENTORY_PROCESSING_FAILED";
export const DELETE_DATA_MAPPING_INVENTORY_PROCESSING =
  "DELETE_DATA_MAPPING_INVENTORY_PROCESSING";
export const DELETE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS =
  "DELETE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS";
export const DELETE_DATA_MAPPING_INVENTORY_PROCESSING_FAILED =
  "DELETE_DATA_MAPPING_INVENTORY_PROCESSING_FAILED";

export const LIST_DATA_MAPPING_INVENTORY_ENTITY =
  "LIST_DATA_MAPPING_INVENTORY_ENTITY";
export const LIST_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_ENTITY_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_ENTITY_FAILED";
export const SET_DATA_MAPPING_INVENTORY_ENTITY =
  "SET_DATA_MAPPING_INVENTORY_ENTITY";
export const CREATE_DATA_MAPPING_INVENTORY_ENTITY =
  "CREATE_DATA_MAPPING_INVENTORY_ENTITY";
export const CREATE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS =
  "CREATE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS";
export const CREATE_DATA_MAPPING_INVENTORY_ENTITY_FAILED =
  "CREATE_DATA_MAPPING_INVENTORY_ENTITY_FAILED";
export const GET_DATA_MAPPING_INVENTORY_ENTITY =
  "GET_DATA_MAPPING_INVENTORY_ENTITY";
export const GET_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS =
  "GET_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS";
export const GET_DATA_MAPPING_INVENTORY_ENTITY_FAILED =
  "GET_DATA_MAPPING_INVENTORY_ENTITY_FAILED";
export const UPDATE_DATA_MAPPING_INVENTORY_ENTITY =
  "UPDATE_DATA_MAPPING_INVENTORY_ENTITY";
export const UPDATE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS =
  "UPDATE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS";
export const UPDATE_DATA_MAPPING_INVENTORY_ENTITY_FAILED =
  "UPDATE_DATA_MAPPING_INVENTORY_ENTITY_FAILED";
export const DELETE_DATA_MAPPING_INVENTORY_ENTITY =
  "DELETE_DATA_MAPPING_INVENTORY_ENTITY";
export const DELETE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS =
  "DELETE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS";
export const DELETE_DATA_MAPPING_INVENTORY_ENTITY_FAILED =
  "DELETE_DATA_MAPPING_INVENTORY_ENTITY_FAILED";

export const LIST_DATA_MAPPING_INVENTORY_VENDOR =
  "LIST_DATA_MAPPING_INVENTORY_VENDOR";
export const LIST_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_VENDOR_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_VENDOR_FAILED";
export const CREATE_DATA_MAPPING_INVENTORY_VENDOR =
  "CREATE_DATA_MAPPING_INVENTORY_VENDOR";
export const CREATE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS =
  "CREATE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS";
export const CREATE_DATA_MAPPING_INVENTORY_VENDOR_FAILED =
  "CREATE_DATA_MAPPING_INVENTORY_VENDOR_FAILED";
export const GET_DATA_MAPPING_INVENTORY_VENDOR =
  "GET_DATA_MAPPING_INVENTORY_VENDOR";
export const GET_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS =
  "GET_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS";
export const GET_DATA_MAPPING_INVENTORY_VENDOR_FAILED =
  "GET_DATA_MAPPING_INVENTORY_VENDOR_FAILED";
export const UPDATE_DATA_MAPPING_INVENTORY_VENDOR =
  "UPDATE_DATA_MAPPING_INVENTORY_VENDOR";
export const UPDATE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS =
  "UPDATE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS";
export const UPDATE_DATA_MAPPING_INVENTORY_VENDOR_FAILED =
  "UPDATE_DATA_MAPPING_INVENTORY_VENDOR_FAILED";
export const DELETE_DATA_MAPPING_INVENTORY_VENDOR =
  "DELETE_DATA_MAPPING_INVENTORY_VENDOR";
export const DELETE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS =
  "DELETE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS";
export const DELETE_DATA_MAPPING_INVENTORY_VENDOR_FAILED =
  "DELETE_DATA_MAPPING_INVENTORY_VENDOR_FAILED";

export const LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE =
  "LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE";
export const LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS =
  "LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS";
export const LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED =
  "LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED";
export const CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE =
  "CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE";
export const CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS =
  "CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS";
export const CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED =
  "CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED";
export const GET_DATA_MAPPING_SETTING_AUTOMATION_RULE =
  "GET_DATA_MAPPING_SETTING_AUTOMATION_RULE";
export const GET_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS =
  "GET_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS";
export const GET_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED =
  "GET_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED";
export const UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE =
  "UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE";
export const UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS =
  "UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS";
export const UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED =
  "UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED";
export const DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE =
  "DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE";
export const DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS =
  "DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS";
export const DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED =
  "DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED";

export const LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE =
  "LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE";
export const LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE_SUCCESS =
  "LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE_SUCCESS";
export const LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE_FAILED =
  "LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE_FAILED";

export const LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW =
  "LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW";
export const LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS =
  "LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS";
export const LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED =
  "LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED";
export const CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW =
  "CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW";
export const CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS =
  "CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS";
export const CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED =
  "CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED";
export const GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW =
  "GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW";
export const GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS =
  "GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS";
export const GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED =
  "GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED";
export const UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW =
  "UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW";
export const UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS =
  "UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS";
export const UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED =
  "UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED";
export const DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW =
  "DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW";
export const DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS =
  "DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS";
export const DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED =
  "DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED";

export const LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT =
  "LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT";
export const LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT_SUCCESS =
  "LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT_SUCCESS";
export const LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT_FAILED =
  "LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT_FAILED";

export const LIST_DATA_MAPPING_INVENTORY = "LIST_DATA_MAPPING_INVENTORY";
export const LIST_DATA_MAPPING_INVENTORY_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_FAILED";
export const GET_DATA_MAPPING_INVENTORY = "GET_DATA_MAPPING_INVENTORY";
export const GET_DATA_MAPPING_INVENTORY_SUCCESS =
  "GET_DATA_MAPPING_INVENTORY_SUCCESS";
export const GET_DATA_MAPPING_INVENTORY_FAILED =
  "GET_DATA_MAPPING_INVENTORY_FAILED";
export const UPDATE_DATA_MAPPING_INVENTORY = "UPDATE_DATA_MAPPING_INVENTORY";
export const UPDATE_DATA_MAPPING_INVENTORY_SUCCESS =
  "UPDATE_DATA_MAPPING_INVENTORY_SUCCESS";
export const UPDATE_DATA_MAPPING_INVENTORY_FAILED =
  "UPDATE_DATA_MAPPING_INVENTORY_FAILED";
export const LIST_DATA_MAPPING_MANAGEMENT = "LIST_DATA_MAPPING_MANAGEMENT";
export const LIST_DATA_MAPPING_MANAGEMENT_SUCCESS =
  "LIST_DATA_MAPPING_MANAGEMENT_SUCCESS";
export const LIST_DATA_MAPPING_MANAGEMENT_FAILED =
  "LIST_DATA_MAPPING_MANAGEMENT_FAILED";
export const CREATE_DATA_MAPPING_MANAGEMENT = "CREATE_DATA_MAPPING_MANAGEMENT";
export const CREATE_DATA_MAPPING_MANAGEMENT_SUCCESS =
  "CREATE_DATA_MAPPING_MANAGEMENT_SUCCESS";
export const CREATE_DATA_MAPPING_MANAGEMENT_FAILED =
  "CREATE_DATA_MAPPING_MANAGEMENT_FAILED";
export const DELETE_DATA_MAPPING_MANAGEMENT = "DELETE_DATA_MAPPING_MANAGEMENT";
export const DELETE_DATA_MAPPING_MANAGEMENT_SUCCESS =
  "DELETE_DATA_MAPPING_MANAGEMENT_SUCCESS";
export const DELETE_DATA_MAPPING_MANAGEMENT_FAILED =
  "DELETE_DATA_MAPPING_MANAGEMENT_FAILED";

export const LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE";
export const LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE_FAILED";

export const LIST_DATA_MAPPING_INVENTORY_LOCATION =
  "LIST_DATA_MAPPING_INVENTORY_LOCATION";
export const LIST_DATA_MAPPING_INVENTORY_LOCATION_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_LOCATION_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_LOCATION_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_LOCATION_FAILED";

export const LIST_DATA_MAPPING_INVENTORY_CONTROL =
  "LIST_DATA_MAPPING_INVENTORY_CONTROL";
export const LIST_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_CONTROL_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_CONTROL_FAILED";
export const LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM =
  "LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM";
export const LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM_FAILED";
export const LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL =
  "LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL";
export const LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL_FAILED";
export const ADD_DATA_MAPPING_INVENTORY_CONTROL =
  "ADD_DATA_MAPPING_INVENTORY_CONTROL";
export const ADD_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS =
  "ADD_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS";
export const ADD_DATA_MAPPING_INVENTORY_CONTROL_FAILED =
  "ADD_DATA_MAPPING_INVENTORY_CONTROL_FAILED";
export const DELETE_DATA_MAPPING_INVENTORY_CONTROL =
  "DELETE_DATA_MAPPING_INVENTORY_CONTROL";
export const DELETE_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS =
  "DELETE_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS";
export const DELETE_DATA_MAPPING_INVENTORY_CONTROL_FAILED =
  "DELETE_DATA_MAPPING_INVENTORY_CONTROL_FAILED";

export const LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED";
export const LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_FAILED";
export const ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED =
  "ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED";
export const ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS =
  "ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS";
export const ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_FAILED =
  "ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_FAILED";
export const DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED =
  "DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED";
export const DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS =
  "DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS";
export const DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_FAILED =
  "DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_FAILED";

export const LOADING_DATA_MAPPING = "LOADING_DATA_MAPPING";
export const LOADING_CREATE_DATA_MAPPING = "LOADING_CREATE_DATA_MAPPING";
export const LOADING_DATA_ELEMENT = "LOADING_DATA_ELEMENT";
export const LOADING_ASSET = "LOADING_ASSET";
export const LOADING_ENTITY = "LOADING_ENTITY";
export const LOADING_CONTROL = "LOADING_CONTROL";
export const LOADING_PROCESSING = "LOADING_PROCESSING";
export const LOADING_WORKFLOW = "LOADING_WORKFLOW";

export const LIST_DATA_MAPPING_DATA_WORKFLOW =
  "LIST_DATA_MAPPING_DATA_WORKFLOW";
export const LIST_DATA_MAPPING_DATA_WORKFLOW_SUCCESS =
  "LIST_DATA_MAPPING_DATA_WORKFLOW_SUCCESS";
export const LIST_DATA_MAPPING_DATA_WORKFLOW_FAILED =
  "LIST_DATA_MAPPING_DATA_WORKFLOW_FAILED";
export const CREATE_DATA_MAPPING_DATA_WORKFLOW =
  "CREATE_DATA_MAPPING_DATA_WORKFLOW";
export const CREATE_DATA_MAPPING_DATA_WORKFLOW_SUCCESS =
  "CREATE_DATA_MAPPING_DATA_WORKFLOW_SUCCESS";
export const CREATE_DATA_MAPPING_DATA_WORKFLOW_FAILED =
  "CREATE_DATA_MAPPING_DATA_WORKFLOW_FAILED";
export const DELETE_DATA_MAPPING_DATA_WORKFLOW =
  "DELETE_DATA_MAPPING_DATA_WORKFLOW";
export const DELETE_DATA_MAPPING_DATA_WORKFLOW_SUCCESS =
  "DELETE_DATA_MAPPING_DATA_WORKFLOW_SUCCESS";
export const DELETE_DATA_MAPPING_DATA_WORKFLOW_FAILED =
  "DELETE_DATA_MAPPING_DATA_WORKFLOW_FAILED";

export const RESET_DATA_MAPPING_ASSET = "RESET_DATA_MAPPING_ASSET";
export const RESET_DATA_MAPPING_ENTITY = "RESET_DATA_MAPPING_ENTITY";

export const LIST_DATA_MAPPING_INVENTORY_IT_ASSET =
  "LIST_DATA_MAPPING_INVENTORY_IT_ASSET ";
export const LIST_DATA_MAPPING_INVENTORY_IT_ASSET_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_IT_ASSET_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_IT_ASSET_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_IT_ASSET_FAILED";

export const LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED =
  "LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED";
export const LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS =
  "LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS";
export const LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_FAILED =
  "LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_FAILED";
export const ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED =
  "ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED";
export const ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS =
  "ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS";
export const ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_FAILED =
  "ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_FAILED";
export const DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED =
  "DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED";
export const DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS =
  "DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS";
export const DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_FAILED =
  "DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_FAILED";

export const GET_DATA_MAPPING_FLOW = "GET_DATA_MAPPING_FLOW";
export const GET_DATA_MAPPING_FLOW_SUCCESS = "GET_DATA_MAPPING_FLOW_SUCCESS";
export const GET_DATA_MAPPING_FLOW_FAILED = "GET_DATA_MAPPING_FLOW_FAILED";
