import fetch from "util/Api";
import { PRIVACYKIT_ASSESSMENT_AUTOMATION_BASE_URL as endpoint } from "config/EndpointConfig";

const AssessmentAutomationServices = {};

// #ASSESSMENT SERVICE
AssessmentAutomationServices.listAssessmentByType = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/type/${assessmentType}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.getAssessmentById = async ({
  assessmentType,
  assessmentId,
}) =>
  await fetch({
    url: `${endpoint}/assessment/${assessmentType}/${assessmentId}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.createAssessment = async (data) =>
  await fetch({
    url: `${endpoint}/assessment`,
    method: "post",
    data,
  });

AssessmentAutomationServices.updateAssessment = async ({
  assessmentType,
  assessmentId,
  data,
}) =>
  await fetch({
    url: `${endpoint}/assessment/${assessmentType}/${assessmentId}`,
    method: "put",
    data,
  });

AssessmentAutomationServices.publishAssessment = async (
  assessmentType,
  assessmentId
) =>
  await fetch({
    url: `${endpoint}/assessment/${assessmentType}/${assessmentId}/publish`,
    method: "post",
  });

AssessmentAutomationServices.deleteAssessment = async ({
  assessmentType,
  assessmentId,
}) =>
  await fetch({
    url: `${endpoint}/assessment/${assessmentType}/${assessmentId}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

//ASSESSMENT ACTIVE
AssessmentAutomationServices.listAssessmentActive = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/type/${assessmentType}?status=active`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.createAssessmentActive = async (data) =>
  await fetch({
    url: `${endpoint}/assessment`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.getAssessmentActiveByID = async (
  assessmentType,
  ObjectUUID
) =>
  await fetch({
    url: `${endpoint}/assessment/${assessmentType}/${ObjectUUID}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.updateAssessmentActive = async (
  data,
  ObjectUUID
) =>
  await fetch({
    url: `${endpoint}/assessment/${data.assessment_type}/${ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.archivedAssessmentActive = async ({
  assessment_type,
  ObjectUUID,
  data,
}) =>
  await fetch({
    url: `${endpoint}/assessment/${assessment_type}/${ObjectUUID}/status`,
    method: "put",
    data: { status: "achieve", ...data },
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.deleteAssessmentActive = async ({
  assessment_type,
  ObjectUUID,
  data,
}) =>
  await fetch({
    url: `${endpoint}/assessment/${assessment_type}/${ObjectUUID}/status`,
    method: "put",
    data: { status: "remove", ...data },
  })
    .then((response) => response)
    .catch((error) => error);

//ASSESSMENT ARCHIVED
AssessmentAutomationServices.listAssessmentArchived = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/type/${assessmentType}?status=achieve`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.activatedAssessmentArchived = async ({
  assessment_type,
  ObjectUUID,
  data,
}) =>
  await fetch({
    url: `${endpoint}/assessment/${assessment_type}/${ObjectUUID}/status`,
    method: "put",
    data: { status: "active", ...data },
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.deleteAssessmentArchived = async ({
  assessment_type,
  ObjectUUID,
  data,
}) =>
  await fetch({
    url: `${endpoint}/assessment/${assessment_type}/${ObjectUUID}/status`,
    method: "put",
    data: { status: "remove", ...data },
  })
    .then((response) => response)
    .catch((error) => error);

//ASSESSMENT DELETED
AssessmentAutomationServices.listAssessmentDeleted = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/type/${assessmentType}?status=remove`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.activatedAssessmentDeleted = async ({
  assessment_type,
  ObjectUUID,
  data,
}) =>
  await fetch({
    url: `${endpoint}/assessment/${assessment_type}/${ObjectUUID}/status`,
    method: "put",
    data: { status: "active", ...data },
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.deleteAssessmentDeleted = async ({
  assessment_type,
  ObjectUUID,
}) =>
  await fetch({
    url: `${endpoint}/assessment/${assessment_type}/${ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

//ASSESSMENT RISK
AssessmentAutomationServices.listAssessmentRisk = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/risk?assessment_type=${assessmentType}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.createAssessmentRisk = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/risk`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.updateAssessmentRisk = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/risk/${data.assessment_type}/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.deleteAssessmentRisk = async ({
  assessment_type,
  ObjectUUID,
}) =>
  await fetch({
    url: `${endpoint}/assessment/risk/${assessment_type}/${ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

//# TEMPLATE SERVICES
AssessmentAutomationServices.listTemplateType = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/template?default=yes&assessment_type=${assessmentType}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.listTemplateByType = async (template_type) =>
  await fetch({
    url: `${endpoint}/assessment/template?template_type=${template_type}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.listTemplate = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/template?assessment_type=${assessmentType}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.getTemplateData = async ({
  assessmentType,
  assessment_template_type,
  assessment_template_id,
}) =>
  await fetch({
    url: `${endpoint}/assessment/template/${assessmentType}/${assessment_template_type}/${assessment_template_id}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.createAssessmentTemplate = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/template`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.deleteAssessmentTemplate = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/template/${data.assessment_type}/${data.ObjectUUID}`,
    method: "delete",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.listAssessmentTemplateType = async (
  assessmentType
) =>
  await fetch({
    url: `${endpoint}/assessment/template?assessment_type_template=${assessmentType}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

/* ## ASSESSMENT PROCESS SERVICE*/
AssessmentAutomationServices.listAssessmentProcess = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/stage?assessment_type=${assessmentType}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.createAssessmentProcess = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/stage`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.getAssessmentProcess = async ({
  assessmentProcessId,
  assessmentType,
}) =>
  await fetch({
    url: `${endpoint}/assessment/stage/${assessmentType}/${assessmentProcessId}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.updateAssessmentProcess = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/stage/${data.assessment_type}/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.deleteAssessmentProcess = async ({
  assessment_type,
  ObjectUUID,
}) =>
  await fetch({
    url: `${endpoint}/assessment/stage/${assessment_type}/${ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

//ASSESSMENT RESULT
AssessmentAutomationServices.listAssessmentResult = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/result?assessment_type=${assessmentType}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.createAssessmentResult = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/result`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.updateAssessmentResult = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/result/${data.assessment_type}/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.deleteAssessmentResult = async ({
  assessment_type,
  ObjectUUID,
}) =>
  await fetch({
    url: `${endpoint}/assessment/result/${assessment_type}/${ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

//ASSESSMENT RULES
AssessmentAutomationServices.listAssessmentRules = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/rule?assessment_type=${assessmentType}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.createAssessmentRules = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/rule`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.updateAssessmentRules = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/rule/${data.assessment_type}/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.deleteAssessmentRules = async ({
  assessment_type,
  ObjectUUID,
}) =>
  await fetch({
    url: `${endpoint}/assessment/rule/${assessment_type}/${ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

//COMPLIANCE PLAN
AssessmentAutomationServices.listCompliancePlan = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/stage?assessment_type=${assessmentType}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.createCompliancePlan = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/stage`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.deleteCompliancePlan = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/stage/${data.assessment_type}/${data.ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.getDetailCompliancePlan = async (
  assessmentType,
  id
) =>
  await fetch({
    url: `${endpoint}/assessment/stage/${assessmentType}/${id}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.updateCompliancePlan = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/stage/${data.assessment_type}/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

//ASSESSMENT SENT
AssessmentAutomationServices.listAssessmentNotSent = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/type/${assessmentType}?status_sent=NOTSEND&limit=20`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.listAssessmentNotSentNextPage = async (
  assessmentType,
  next_page_token
) =>
  await fetch({
    url: `${endpoint}/assessment/type/${assessmentType}?status_sent=NOTSEND&next_page_token=${next_page_token}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.updateAssessmentNotSentToSent = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/${data.assessment_type}/${data.ObjectUUID}/respondents`,
    method: "post",
    data: { status: "SENTED", ...data },
  })
    .then((response) => response)
    .catch((error) => error);

// Assessment Report
AssessmentAutomationServices.listAssessmentSent = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/type/${assessmentType}?status_sent=SENTED&limit=20`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.listAssessmentSentNextPage = async (
  assessmentType,
  next_page_token
) =>
  await fetch({
    url: `${endpoint}/assessment/type/${assessmentType}?status_sent=SENTED&next_page_token=${next_page_token}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.getAssessment = async ({
  assessmentType,
  assessmentId,
}) =>
  await fetch({
    url: `${endpoint}/assessment/${assessmentType}/${assessmentId}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.getAssessmentReport = async ({
  assessmentType,
  assessmentId,
}) =>
  await fetch({
    url: `${endpoint}/assessment/${assessmentType}/${assessmentId}/report`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.listAssessmentSubmitReport = async ({
  assessmentType,
  assessmentId,
}) =>
  await fetch({
    url: `${endpoint}/assessment/send/${assessmentType}/${assessmentId}`,
    method: "get",
  });

AssessmentAutomationServices.getAssessmentSubmitReport = async ({
  assessmentType,
  assessmentId,
  assessmentSubmitId,
}) =>
  await fetch({
    url: `${endpoint}/assessment/submit/${assessmentType}/${assessmentId}/${assessmentSubmitId}`,
    method: "get",
  });

// Task
AssessmentAutomationServices.createTask = async (data) =>
  await fetch({
    url: `${endpoint}/assessment/task`,
    method: "post",
    data,
  });

AssessmentAutomationServices.listTask = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/task/${assessmentType}`,
    method: "get",
  });

AssessmentAutomationServices.listTask = async (assessmentType) =>
  await fetch({
    url: `${endpoint}/assessment/task/${assessmentType}/?limit=20`,
    method: "get",
  });
AssessmentAutomationServices.listTaskNextPage = async (
  assessmentType,
  next_page_token
) =>
  await fetch({
    url: `${endpoint}/assessment/task/${assessmentType}/?limit=6&next_page_token=${next_page_token}`,
    method: "get",
  });
AssessmentAutomationServices.listTaskInAssessment = async ({
  assessmentType,
  assessmentId,
}) =>
  await fetch({
    url: `${endpoint}/assessment/task/${assessmentType}/${assessmentId}`,
    method: "get",
  });

AssessmentAutomationServices.getTask = async ({
  assessmentType,
  assessmentId,
  taskId,
}) =>
  await fetch({
    url: `${endpoint}/assessment/task/${assessmentType}/${assessmentId}/${taskId}`,
    method: "get",
  });

AssessmentAutomationServices.updateTask = async ({
  ObjectUUID,
  assessment_id,
  assessment_type,
  ...data
}) =>
  await fetch({
    url: `${endpoint}/assessment/task/${assessment_type}/${assessment_id}/${ObjectUUID}`,
    method: "put",
    data,
  });

AssessmentAutomationServices.deleteTask = async ({
  assessmentType,
  assessmentId,
  taskId,
}) =>
  await fetch({
    url: `${endpoint}/assessment/task/${assessmentType}/${assessmentId}/${taskId}`,
    method: "delete",
  });

AssessmentAutomationServices.AddAssessmentControl = async (
  assessment_type,
  assessment_id,
  data
) =>
  await fetch({
    url: `${endpoint}/assessment/link/${assessment_type}/${assessment_id}`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.GetlistAssessmentControl = async (
  assessment_type,
  assessment_id,
  link_type
) =>
  await fetch({
    url: `${endpoint}/assessment/link/${assessment_type}/${assessment_id}/${link_type}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

AssessmentAutomationServices.deleteAssessmentControl = async (
  assesment_type,
  assessment_id,
  link_type,
  link_id
) =>
  await fetch({
    url: `${endpoint}/assessment/link/${assesment_type}/${assessment_id}/${link_type}/${link_id}`,
    method: "delete",
  });

export default AssessmentAutomationServices;
