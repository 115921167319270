const COLOUR_TAG = {
  critical: { color: "#DC3545", label: "ร้ายแรง" },
  high: { color: "#DC3545", label: "สูง" },
  medium: { color: "#FFC107", label: "ปานกลาง" },
  low: { color: "#28A745", label: "ต่ำ" },
  log: { color: "#28A745", label: "ต่ำ" },
};

export default class FoundModel {
  constructor(data) {
    this.id = data?.id || "";
    this.name = data?.name || "";
    this.port = data?.port || "";
    this.severity = data.severity;
    this.threat = data?.threat || "";
  }

  displayID = () => this.id;
  displayName = () => this.name;
  displayPort = () => this.port;
  displaySeverityColor = () =>
    COLOUR_TAG[this.threat.toLowerCase()]?.color || "#52C41A";
  displaySeverityLabel = () =>
    COLOUR_TAG[this.threat.toLowerCase()]?.label || "None";
  displayThreat = () => this.threat;
}
