import {
  SET_ALL_EVENTS,
  SET_FOLLOW_EVENTS,
  SET_PRIVATE_EVENTS,
  GET_EVENT_BY_ID,
  SET_EVENT_DETAIL,
  SET_ATTRIBUTES,
  GET_FEED,
  SET_FEED,
  FOLLOW_EVENT,
  UNFOLLOW_EVENT,
} from "redux/constants/CyberKitThreatIntelligence";

export const setAllEventList = (payload) => {
  return {
    type: SET_ALL_EVENTS,
    payload: payload,
  };
};

export const setFollowEventList = (payload) => {
  return {
    type: SET_FOLLOW_EVENTS,
    payload: payload,
  };
};

export const setPrivateEventList = (payload) => {
  return {
    type: SET_PRIVATE_EVENTS,
    payload: payload,
  };
};

export const getEventByID = (payload) => {
  return {
    type: GET_EVENT_BY_ID,
    payload: payload,
  };
};

export const setEventDetail = (payload) => {
  return {
    type: SET_EVENT_DETAIL,
    payload: payload,
  };
};

export const setAttributes = (payload) => {
  return {
    type: SET_ATTRIBUTES,
    payload: payload,
  };
};

export const getFeed = (payload) => {
  return {
    type: GET_FEED,
    payload: payload,
  };
};

export const setFeed = (payload) => {
  return {
    type: SET_FEED,
    payload: payload,
  };
};

export const followEvent = (payload) => {
  return {
    type: FOLLOW_EVENT,
    payload: payload,
  };
};

export const unfollowEvent = (payload) => {
  return {
    type: UNFOLLOW_EVENT,
    payload: payload,
  };
};
