export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE_SUCCESS =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE_SUCCESS";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE_FAILURE =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE_FAILURE";
export const TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK =
  "TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_FAILED =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_FAILED";
export const SEARCH_DATA_TASK = "SEARCH_DATA_TASK";
export const SEARCH_DATA_TASK_SUCCESS = "SEARCH_DATA_TASK_SUCCESS";
