import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { css } from "@emotion/css";

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer>
      <div className="gx-layout-footer-content">
        <div
          className={css`
            display: flex;
            align-items: center;
            align-content: center;

            a {
              margin-left: 5px;
              color: #545454;
            }
          `}
        >
          <img
            alt=""
            style={{ maxHeight: 20 }}
            src={`/assets/images/cc_mol.png`}
          />
          <Link to="/copyright">
            Copyright © 2022 Onefence All rights reserved.
          </Link>
        </div>
      </div>
    </Footer>
  );
};

export default AppFooter;
