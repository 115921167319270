export const LIST_INCIDENT_RESPONSE_EVENT_STAGE = "LIST_INCIDENT_RESPONSE_EVENT_STAGE";
export const LIST_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS = "LIST_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS";
export const LIST_INCIDENT_RESPONSE_EVENT_STAGE_FAILED = "LIST_INCIDENT_RESPONSE_EVENT_STAGE_FAILED";
export const GET_INCIDENT_RESPONSE_EVENT_STAGE = "GET_INCIDENT_RESPONSE_EVENT_STAGE";
export const GET_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS = "GET_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS";
export const GET_INCIDENT_RESPONSE_EVENT_STAGE_FAILED = "GET_INCIDENT_RESPONSE_EVENT_STAGE_FAILED";
export const CREATE_INCIDENT_RESPONSE_EVENT_STAGE = "CREATE_INCIDENT_RESPONSE_EVENT_STAGE";
export const CREATE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS = "CREATE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS";
export const CREATE_INCIDENT_RESPONSE_EVENT_STAGE_FAILED = "CREATE_INCIDENT_RESPONSE_EVENT_STAGE_FAILED";
export const UPDATE_INCIDENT_RESPONSE_EVENT_STAGE = "UPDATE_INCIDENT_RESPONSE_EVENT_STAGE";
export const UPDATE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS = "UPDATE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS";
export const UPDATE_INCIDENT_RESPONSE_EVENT_STAGE_FAILED = "UPDATE_INCIDENT_RESPONSE_EVENT_STAGE_FAILED";
export const DELETE_INCIDENT_RESPONSE_EVENT_STAGE = "DELETE_INCIDENT_RESPONSE_EVENT_STAGE";
export const DELETE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS = "DELETE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS";
export const DELETE_INCIDENT_RESPONSE_EVENT_STAGE_FAILED = "DELETE_INCIDENT_RESPONSE_EVENT_STAGE_FAILED";

export const LIST_INCIDENT_RESPONSE_EVENT_TYPE = "LIST_INCIDENT_RESPONSE_EVENT_TYPE";
export const LIST_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS = "LIST_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS";
export const LIST_INCIDENT_RESPONSE_EVENT_TYPE_FAILED = "LIST_INCIDENT_RESPONSE_EVENT_TYPE_FAILED";
export const CREATE_INCIDENT_RESPONSE_EVENT_TYPE = "CREATE_INCIDENT_RESPONSE_EVENT_TYPE";
export const CREATE_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS = "CREATE_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS";
export const CREATE_INCIDENT_RESPONSE_EVENT_TYPE_FAILED = "CREATE_INCIDENT_RESPONSE_EVENT_TYPE_FAILED";
export const DELETE_INCIDENT_RESPONSE_EVENT_TYPE = "DELETE_INCIDENT_RESPONSE_EVENT_TYPE";
export const DELETE_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS = "DELETE_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS";
export const DELETE_INCIDENT_RESPONSE_EVENT_TYPE_FAILED = "DELETE_INCIDENT_RESPONSE_EVENT_TYPE_FAILED";

export const LIST_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT = "LIST_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT";
export const LIST_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS = "LIST_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS";
export const LIST_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_FAILED = "LIST_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_FAILED";
export const CREATE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT = "CREATE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT";
export const CREATE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS = "CREATE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS";
export const CREATE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_FAILED = "CREATE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_FAILED";
export const DELETE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT = "DELETE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT";
export const DELETE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS = "DELETE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS";
export const DELETE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_FAILED = "DELETE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_FAILED";
