import dashboardReducer from "./dashboardReducer";
import dataElementReducer from "./dataElementReducer";
import customPreferenceReducer from "./customPreferenceReducer";
import processingPurposeReducers from "./processingPurposeReducer";
import collectionPointReducer from "./collectionPointReducer";
import consentManagementReducer from "./consentManagementReducer";

export default function rootReducers(state = {}, action) {
  return {
    dashboard: dashboardReducer(state.dashboard, action),
    dataElement: dataElementReducer(state.dataElement, action),
    customPreference: customPreferenceReducer(state.customPreference, action),
    processingPurpose: processingPurposeReducers(
      state.processingPurpose,
      action
    ),
    collectionPoint: collectionPointReducer(state.collectionPoint, action),
    consentManagement: consentManagementReducer(
      state.consentManagement,
      action
    ),
  };
}
