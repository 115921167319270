import {
  SIGNIN_SUCCESS,
  AUTH_SHOW_LOADING,
  AUTH_HIDE_LOADING,
  SIGNIN_FAILED,
  TOKEN_REFRESHED_SUCCESS,
  CHANGE_AUTH_PASSWORD,
  CHANGE_AUTH_PASSWORD_SUCCESS,
} from "../constants/Auth";

const initState = {
  AuthenticationResult: {},
  ...JSON.parse(localStorage.getItem("auth")),
  loading: false,
  okButtonProps: {
    loading: false,
  },
};

const Auth = (state = initState, action) => {
  switch (action.type) {
    case SIGNIN_SUCCESS: {
      return {
        ...action.payload,
        loading: false,
      };
    }
    case SIGNIN_FAILED: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case AUTH_SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case AUTH_HIDE_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case TOKEN_REFRESHED_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CHANGE_AUTH_PASSWORD: {
      return {
        ...state,
        okButtonProps: {
          loading: true,
        },
      };
    }
    case CHANGE_AUTH_PASSWORD_SUCCESS: {
      return {
        ...state,
        okButtonProps: {
          loading: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default Auth;
