import {
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD_SUCCESS,
  CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD_FILTER,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";
import dayjs from "dayjs";

const initialState = {
  loading: false,
  data: null,
  requests: null,
  filter: {
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  },
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    }
    case CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD_FILTER: {
      return {
        ...state,
        filter: action.filter,
      };
    }
    default: {
      return state;
    }
  }
};

export default DashboardReducer;
