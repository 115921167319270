import { all, fork } from "redux-saga/effects";
import {
  getDashboard,
  getNextPageDashboardReceipt,
  getNextPageDashboardProcessingPurpose,
} from "./Dashboard";
import {
  listConsentManagementDataElement,
  listConsentManagementDataElementType,
  getConsentManagementDataElement,
  setManageConsentManagementDataElement,
  deleteConsentManagementDataElement,
  SearchConsentManagementDataElement
} from "./DataElement";
import {
  listConsentManagementCustomPreference,
  getConsentManagementCustomPreference,
  setManageConsentManagementCustomPreference,
  deleteConsentManagementCustomPreference,
} from "./CustomPreference";
import {
  listConsentManagementProcessingPurpose,
  getConsentManagementProcessingPurpose,
  setManageConsentManagementProcessingPurpose,
  deleteConsentManagementProcessingPurpose,
} from "./ProcessingPurpose";
import {
  listConsentManagementCollectionPoint,
  getConsentManagementCollectionPoint,
  createConsentManagementCollectionPoint,
  updateConsentManagementCollectionPoint,
  publishConsentManagementCollectionPoint,
  changeConsentManagementCollectionPointStatus,
  listConsentManagementCollectionPointVersion,
  getConsentManagementCollectionPointVersion,
} from "./CollectionPoint";
import {
  listConsentManagement,
  listConsentManagementNextPage,
  getConsentManagement,
  onSearchConsentManagement,
} from "./ConsentManagement";

export default function* rootSaga() {
  yield all([
    fork(getDashboard),
    fork(getNextPageDashboardReceipt),
    fork(getNextPageDashboardProcessingPurpose),
    fork(listConsentManagementDataElement),
    fork(listConsentManagementDataElementType),
    fork(getConsentManagementDataElement),
    fork(setManageConsentManagementDataElement),
    fork(deleteConsentManagementDataElement),
    fork(listConsentManagementCustomPreference),
    fork(getConsentManagementCustomPreference),
    fork(setManageConsentManagementCustomPreference),
    fork(deleteConsentManagementCustomPreference),
    fork(listConsentManagementProcessingPurpose),
    fork(getConsentManagementProcessingPurpose),
    fork(setManageConsentManagementProcessingPurpose),
    fork(deleteConsentManagementProcessingPurpose),
    fork(listConsentManagementCollectionPoint),
    fork(getConsentManagementCollectionPoint),
    fork(createConsentManagementCollectionPoint),
    fork(publishConsentManagementCollectionPoint),
    fork(updateConsentManagementCollectionPoint),
    fork(changeConsentManagementCollectionPointStatus),
    fork(listConsentManagementCollectionPointVersion),
    fork(getConsentManagementCollectionPointVersion),
    fork(listConsentManagement),
    fork(listConsentManagementNextPage),
    fork(getConsentManagement),
    fork(onSearchConsentManagement),
    fork(SearchConsentManagementDataElement),
  ]);
}
