import fetch from "util/Api";
import { CYBER_KIT_BASE_URL as endpoint } from "config/EndpointConfig";

const LogManagementServices = {};

// DASHBOARD
LogManagementServices.getDashboardCountSource = async (payload) =>
  await fetch({
    url: `${endpoint}/dashboard/log_management/source`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

LogManagementServices.getDashboardTopEvent = async (payload) =>
  await fetch({
    url: `${endpoint}/dashboard/log_management/top_event`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

LogManagementServices.getDashboardSummaryEvent = async (payload) =>
  await fetch({
    url: `${endpoint}/dashboard/log_management/summary_event${payload.params}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

LogManagementServices.getDashboardRangeEvent = async (payload) =>
  await fetch({
    url: `${endpoint}/dashboard/log_management/range_event`,
    data: payload,
    method: "post",
  })
    .then((response) => response)
    .catch((error) => error);

LogManagementServices.getDashboardSummarySize = async (payload) =>
  await fetch({
    url: `${endpoint}/dashboard/log_management/summary_size`,
    data: payload,
    method: "post",
  })
    .then((response) => response)
    .catch((error) => error);

// SOURCE
LogManagementServices.getSourceList = async (payload) => {
  return await fetch({
    url: `${endpoint}/source/tenancy`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);
};

LogManagementServices.getSourcesByTenancy = async (payload) => {
  return await fetch({
    method: "get",
    url: `${endpoint}/source/tenancy${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);
};

LogManagementServices.getSetup = async (payload) => {
  return await fetch({
    method: "get",
    url: `${endpoint}/source/setup/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);
};

LogManagementServices.getCret = async (payload) => {
  return await fetch({
    method: "get",
    url: `${endpoint}/source/cert${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);
};

LogManagementServices.createSource = async (payload) => {
  let params = ``;
  if (payload.params !== undefined)
    payload.params.forEach(
      (value, index) =>
        (params = params.concat(
          `${index === 0 ? "?" : ""}`,
          `${value.key}=${value.value}`,
          `${parseInt(index) !== payload.length - 1 ? "&" : ""}`
        ))
    );

  return await fetch({
    method: "post",
    url: `${endpoint}/source${params}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      return error;
    });
};

LogManagementServices.updateSource = async (payload) => {
  let params = ``;
  if (payload.params !== undefined)
    payload.params.forEach(
      (value, index) =>
        (params = params.concat(
          `${index === 0 ? "?" : ""}`,
          `${value.key}=${value.value}`,
          `${parseInt(index) !== payload.length - 1 ? "&" : ""}`
        ))
    );

  return await fetch({
    method: "put",
    url: `${endpoint}/source/${payload.path.id}${params}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);
};

LogManagementServices.deleteSource = async (payload) =>
  await fetch({
    method: "delete",
    url: `${endpoint}/source/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

LogManagementServices.getSourceSIEMList = async (payload) => {
  // console.log(payload)
  return await fetch({
    method: "get",
    url: `${endpoint}/source/tenancy${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);
};

// AUDIT
LogManagementServices.getAdultList = async (payload) => {
  return await fetch({
    method: "get",
    url: `${endpoint}/audit_log${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);
};

// SEARCH
LogManagementServices.getSearchResults = async (payload) => {
  return await fetch({
    method: "post",
    url: `${endpoint}/search`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);
};

LogManagementServices.getSIEMFields = async (payload) => {
  return await fetch({
    method: "get",
    url: `${endpoint}/search/fields${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);
};

// EXPLORER
LogManagementServices.getLogList = async (payload) => {
  return await fetch({
    method: "get",
    url: `${endpoint}/log_information/explorer${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);
};

LogManagementServices.getFileLog = async (payload) => {
  return await fetch({
    method: "get",
    url: `${endpoint}/log_information/detail${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);
};

LogManagementServices.createArchive = async (payload) =>
  await fetch({
    method: "post",
    url: `${endpoint}/archive`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

LogManagementServices.deleteArchive = async (payload) =>
  await fetch({
    method: "delete",
    url: `${endpoint}/archive/${payload.path.id}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

LogManagementServices.getArchives = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/archive${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

LogManagementServices.getArchiveByID = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/archive/${payload.path.id}${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

export default LogManagementServices;
