import React from "react";

import dataWarehouseReducers from "./dataWarehouseReducers";
import relatedControlMeasuresReducers from "./relatedControlMeasuresReducers";

const initialState = {};

const IPrivacyKitDataMappingReducers = (state = initialState, action) => {
  return {
    dataWarehouse: dataWarehouseReducers(state.dataWarehouse, action),
    relatedControlMeasures: relatedControlMeasuresReducers(
      state.relatedControlMeasures,
      action
    ),
  };
};

export default IPrivacyKitDataMappingReducers;
