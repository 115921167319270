import DashboardService from "./DashboardService";
import RequestService from "./RequestService";
import WebFormService from "./WebFormService";
import WorkFlowService from "./WorkFlowService";
import TaskService from "./TaskService";

export default {
  ...DashboardService,
  ...RequestService,
  ...WebFormService,
  ...WorkFlowService,
  ...TaskService,
};
