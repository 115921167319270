import { changeFormatDatetime } from "util/DateUtils";

const COLOUR_TAG = {
  active: { color: "#04D182", label: "เปิดใช้งาน" },
  inactive: { color: "#C74343", label: "ปิดใช้งาน" },
};

const UNIT = {
  minutes: "นาที",
  minute: "นาที",
  hours: "ชั่วโมง",
  hour: "ชั่วโมง",
  day: "วัน",
  days: "วัน",
};

export default class DetectionModel {
  constructor(data) {
    this.id = data.id ? data.id : 0;
    this.title = data.title ? data.title : "";
    this.type = data.type ? data.type : "Standard";
    this.description = data.description ? data.description : "";
    this.tags = data.tags ? data.tags : [];
    this.components = data.components ? data.components : [];
    this.notify = data.notify ? data.notify : {};
    this.enabled = data.enabled;
    this.status = data?.status;
    this.create_at = data.create_at ? data.create_at : "";
    this.update_at = data.update_at ? data.update_at : "";
  }

  displayTrigger = () => {
    const triggerType = this.components[0]?.trigger?.every;
    console.log("unit", triggerType);
    return `ทุกๆ ${triggerType?.value} ${UNIT[triggerType?.unit]}`;
  };
  displaySeverityLevel = (severityLevel = []) => {
    const severityFound =
      this.components[0]?.trigger?.thresholds?.severity?.map((item) =>
        item?.type.toLowerCase()
      );
    const result = severityLevel?.filter((item) =>
      severityFound?.includes(item.value.toLowerCase())
    );
    return result[0]?.label || "ไม่พบ";
  };
  displaySource = (sources) => {
    const source = this.components[0]?.source || "";
    return (
      sources?.find((item) => item?.value === source)?.label || "อุปกรณ์ทั้งหมด"
    );
  };
  displayStatusColor = () =>
    COLOUR_TAG[this.status.toLowerCase()]?.color || "#04D182";
  displayStatusLabel = () =>
    COLOUR_TAG[this.status.toLowerCase()]?.label || "None";
  displayCreatedAt = () =>
    this.create_at !== "0001-01-01T00:00:00Z"
      ? changeFormatDatetime(this.create_at, "DD/MM/YYYY h:mm A")
      : "-";
  displayUpdatedAt = () =>
    this.update_at !== "0001-01-01T00:00:00Z"
      ? changeFormatDatetime(this.update_at, "DD/MM/YYYY h:mm A")
      : "-";
  notAllow = () => this.type.toLowerCase() === "standard";
}
