export const LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES =
  "LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES";
export const LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS =
  "LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS";
export const LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE =
  "LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE";

export const LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP =
  "LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP";
export const LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP_SUCCESS =
  "LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP_SUCCESS";
export const LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP_FAILURE =
  "LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP_FAILURE";

export const TOGGLE_MANAGE_DATA_MAPPING_RELATED_CONTROL_MEASURES =
  "TOGGLE_CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES";
export const MANAGE_DATA_MAPPING_RELATED_CONTROL_MEASURES =
  "MANAGE_DATA_MAPPING_RELATED_CONTROL_MEASURES";
export const CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES =
  "CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES";
export const CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS =
  "CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS";
export const CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE =
  "CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE";

export const UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES =
  "UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES";
export const UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS =
  "UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS";
export const UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE =
  "UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE";

export const TOGGLE_DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES =
  "TOGGLE_DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES";
export const DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES =
  "DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES";
export const DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS =
  "DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS";
export const DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE =
  "DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE";
