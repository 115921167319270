import {
  PrivacyKitPath,
  PrivacyKitCompliantManagementPath,
} from "../AppModuleConfig";

const PrivacyKitCompliantManagementNavigation = [
  // {
  //   key: "privacykit-compliant-management-dashboard",
  //   path: `${PrivacyKitPath}${PrivacyKitCompliantManagementPath}/dashboard`,
  //   title: "แดชบอร์ด",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
  {
    key: "privacykit-compliant-management-assessment",
    path: `${PrivacyKitPath}${PrivacyKitCompliantManagementPath}/compliance-privacykit/assessment`,
    title: "การกำหนดแบบประเมิน",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: "privacykit-compliant-management-report",
    path: `${PrivacyKitPath}${PrivacyKitCompliantManagementPath}/compliance-privacykit/report`,
    title: "ประมวลผลแบบประเมินและมอบหมายงาน",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  // {
  //   key: "privacykit-compliant-management-plan",
  //   path: `${PrivacyKitPath}${PrivacyKitCompliantManagementPath}/plan`,
  //   title: "แผนบริหารจัดการ",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
  {
    key: "privacykit-compliant-management-tak",
    path: `${PrivacyKitPath}${PrivacyKitCompliantManagementPath}/compliance-privacykit/task`,
    title: "การติดตามงานที่มอบหมาย",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  // {
  //   key: "privacykit-compliant-management-archive",
  //   path: `${PrivacyKitPath}${PrivacyKitCompliantManagementPath}/compliance-privacykit/archive`,
  //   title: "คลังแบบประเมิน",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
];

export default PrivacyKitCompliantManagementNavigation;
