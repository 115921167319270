import fetch from "util/Api";
import { CYBER_KIT_BASE_URL as endpoint } from "config/EndpointConfig";

const ThreatIntelligenceServices = {};

// EVENT
ThreatIntelligenceServices.getEventList = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/threat_intelligence_events${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

ThreatIntelligenceServices.getFollowEvent = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/threat_intelligence_events/follows${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

ThreatIntelligenceServices.getEventByID = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/threat_intelligence_events/${payload.path.id}${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

ThreatIntelligenceServices.getAttributesByEventID = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/threat_intelligence_events/attributes/${payload.path.id}${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

ThreatIntelligenceServices.searchEvent = async (payload) =>
  await fetch({
    method: "post",
    url: `${endpoint}/threat_intelligence_events/search${payload.params}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

ThreatIntelligenceServices.checkFollowEvent = async (payload) =>
  await fetch({
    method: "post",
    url: `${endpoint}/threat_intelligence_events/follows/check/${payload.path.id}${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

ThreatIntelligenceServices.followEvent = async (payload) =>
  await fetch({
    method: "post",
    url: `${endpoint}/threat_intelligence_events/follows`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

ThreatIntelligenceServices.unfollowEvent = async (payload) =>
  await fetch({
    method: "delete",
    url: `${endpoint}/threat_intelligence_events/follows/${payload.path.id}${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

// FEED
ThreatIntelligenceServices.getFeed = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/threat_intelligence_feed${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

ThreatIntelligenceServices.createFeed = async (payload) =>
  await fetch({
    method: "post",
    url: `${endpoint}/threat_intelligence_feed${payload.params}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

ThreatIntelligenceServices.updateFeed = async (payload) =>
  await fetch({
    method: "post",
    url: `${endpoint}/threat_intelligence_feed/${payload.path.id}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

ThreatIntelligenceServices.deleteFeed = async (payload) =>
  await fetch({
    method: "post",
    url: `${endpoint}/threat_intelligence_feed/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

// FEED
ThreatIntelligenceServices.getFeed = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/threat_intelligence_feed${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

ThreatIntelligenceServices.createFeed = async (payload) =>
  await fetch({
    method: "post",
    url: `${endpoint}/threat_intelligence_feed${payload.params}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

ThreatIntelligenceServices.updateFeed = async (payload) =>
  await fetch({
    method: "post",
    url: `${endpoint}/threat_intelligence_feed/${payload.path.id}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

ThreatIntelligenceServices.deleteFeed = async (payload) =>
  await fetch({
    method: "post",
    url: `${endpoint}/threat_intelligence_feed/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

export default ThreatIntelligenceServices;
