import {
  takeEvery,
  takeLatest,
  call,
  put,
  all,
  fork,
} from "redux-saga/effects";
import {
  LIST_DATA_CLASSIFICATION_AND_DISCOVERY,
  CHANGE_SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY,
} from "../constants/PrivacyKitDataClassificationAndDiscovery";
import {
  listDataClassificationAndDiscoverySuccess,
  listDataClassificationAndDiscoveryError,
  searchDataClassificationAndDiscoverySuccess,
  searchDataClassificationAndDiscoveryError,
} from "../actions/PrivactKitDataClassificationAndDiscovery";
import DataClassificationAndDiscoveryService from "services/pdpakit/DataClassificationAndDiscoveryService";

function* listDataClassificationAndDiscovery() {
  yield takeEvery(LIST_DATA_CLASSIFICATION_AND_DISCOVERY, function* () {
    try {
      const response = yield call(
        DataClassificationAndDiscoveryService.listData
      );
      yield put(listDataClassificationAndDiscoverySuccess(response.data));
    } catch (error) {
      yield put(listDataClassificationAndDiscoveryError(error));
    }
  });
}

function* searchDataClassificationAndDiscovery() {
  yield takeLatest(
    CHANGE_SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY,
    function* ({ search }) {
      try {
        if (search) {
          const response = yield call(
            DataClassificationAndDiscoveryService.searchData,
            search
          );
          yield put(searchDataClassificationAndDiscoverySuccess(response.data));
        } else {
          yield put(searchDataClassificationAndDiscoverySuccess(null));
        }
      } catch (error) {
        yield put(searchDataClassificationAndDiscoveryError(error));
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(listDataClassificationAndDiscovery),
    fork(searchDataClassificationAndDiscovery),
  ]);
}
