import fetch from "util/Api";
import { PRIVACYKIT_DATA_SUBJECT_RIGHT_MANAGEMENT_BASE_URL as endpoint } from "config/EndpointConfig";

const DataSubjectRightManagementService = {};

DataSubjectRightManagementService.listTask = async () =>
  await fetch({
    url: `${endpoint}/subtask?search&filter=all`,
    method: "get",
  });

DataSubjectRightManagementService.listTaskNextPage = async (nextPageToken) =>
  await fetch({
    url: `${endpoint}/subtask?search&filter=all&next_page_token=${nextPageToken}`,
    method: "get",
  });

DataSubjectRightManagementService.getTask = async (taskId, userKey) =>
  await fetch({
    url: `${endpoint}/subtask/${taskId}?user_key=${userKey}`,
    method: "get",
  });

DataSubjectRightManagementService.updateTask = async (
  taskId,
  request_id,
  user_key,
  status,
  data
) =>
  await fetch({
    url: `${endpoint}/subtask/${taskId}/${status}?request_id=${request_id}&user_key=${user_key}`,
    method: "post",
    data: { data: { ...data, "Subtask[ObjID]": undefined } },
  });

DataSubjectRightManagementService.SearchDataTask = async (search) =>
  await fetch({
    url: `${endpoint}/subtask/search?identifier=${search}`,
    method: "get",
  });
export default DataSubjectRightManagementService;
