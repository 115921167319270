export const displaySize = (size) => {
  const KB = 1024;
  const MB = KB * 1024;
  const GB = MB * 1024;
  const TB = GB * 1024;
  if (size < 1024) return `${size} Byte`;
  else if (size < 1024 * KB) return `${(size / KB).toFixed(2)} KB`;
  else if (size < 1024 * MB) return `${(size / MB).toFixed(2)} MB`;
  else if (size < 1024 * GB) return `${(size / GB).toFixed(2)} GB`;
  else if (size < 1024 * TB) return `${(size / TB).toFixed(2)} TB`;
  else return "Oversize";
};

export const SizeValue = (size) => {
  const KB = 1024;
  const MB = KB * 1024;
  const GB = MB * 1024;
  const TB = GB * 1024;
  // if (size < 1024) return size.toFixed(2);
  // else if (size < 1024 * KB) return (size / KB).toFixed(5) / (1024 * KB);
  // else if (size < 1024 * MB) return (size / MB).toFixed(5) / 1024;
  // else if (size < 1024 * GB) return (size / GB).toFixed(2);
  // else if (size < 1024 * TB) return (size / TB).toFixed(2);
  // else return (size / GB).toFixed(2);
  return size;
};

export const nFormatter = (num, digits) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: " k" },
    { value: 1e6, symbol: " M" },
    { value: 1e9, symbol: " G" },
    { value: 1e12, symbol: " T" },
    { value: 1e15, symbol: " P" },
    { value: 1e18, symbol: " E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const delay = async (ms) => new Promise((res) => setTimeout(res, ms));

export const groupByCategory = (data, key) => {
  return data.reduce((group, product) => {
    group[product[key]] = group[product[key]] ?? [];
    group[product[key]].push(product);
    return group;
  }, {});
};
