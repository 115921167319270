import DashboardServices from "./DashboardServices";
import DataElementService from "./DataElementService";
import CustomPreferenceService from "./CustomPreferenceService";
import ProcessingPurposeService from "./ProcessingPurposeService";
import CollectionPointService from "./CollectionPointService";
import ConsentManagementService from "./ConsentManagementService";

const UniversalConsentAndPreferenceService = {
  ...DashboardServices,
  ...DataElementService,
  ...CustomPreferenceService,
  ...ProcessingPurposeService,
  ...CollectionPointService,
  ...ConsentManagementService,
};

export default UniversalConsentAndPreferenceService;
