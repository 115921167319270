import { CyberKitPath } from "../AppModuleConfig";

const CyberKitComplianceManagementNavigation = (method) => [
  {
    key: `cyberkit-${method}-assets`,
    path: `${CyberKitPath}${method}/asset`,
    title: "รายการทรัพย์สิน",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `cyberkit-${method}-dashboard`,
    path: `${CyberKitPath}${method}/dashboard`,
    title: "แดชบอร์ด",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  // {
  //   key: `cyberkit-${method}-assessment`,
  //   path: `${CyberKitPath}${method}/assessment`,
  //   title: "แบบประเมินความเสี่ยง",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
];

export default CyberKitComplianceManagementNavigation;
