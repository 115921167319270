import moment from "moment";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import convertJsonParamsToString from "util/convertJsonParamsToString";
import {
  GET_ST_DASHBOARD,
  GET_DY_DASHBOARD,
  GET_SOURCE_LIST,
  GET_SOURCE_LIST_BY_TENANACY,
  CREATE_SOURCE,
  UPDATE_SOURCE,
  DELETE_SOURCE,
  GET_SOURCE_SIEM_LIST,
  CLEAR_SOURCE_SIEM_LIST,
  GET_LOG_SIEM_FIELDS,
  GET_LOG_LIST,
  CREATE_ARCHIVE,
  DELETE_ARCHIVE,
} from "redux/constants/CyberKitLogManagement";
import { setGlobalLoading } from "redux/actions/CyberKitTool";
import {
  setSTDashboard,
  setDYDashboard,
  setListSource,
  setListSIEMSource,
  setSIEMField,
  setDateFolders,
  setHourFolders,
  setExplorers,
} from "redux/actions/CyberKitLogManagement";
import LogManagementServices from "services/cyberkit/LogManagementServices";
import { CHART_DEFAULT_COLOR_SCHEME } from "config/ChartColorScheme";
import { CyberKitLogMangementSourceModel } from "models";
import { changeFormatDatetime } from "util/DateUtils";
import { displaySize, SizeValue } from "util/General";
import Notification from "util/Notification";

// DASHBOARD
export function* getSTDashboard() {
  yield takeEvery(GET_ST_DASHBOARD, function* ({ payload }) {
    yield put(setGlobalLoading(true));

    let paramsSource = `?response_type=lists`;
    let paramsSummaryEventMonth = `?type_event=month`;
    let paramsSummaryEventDay = `?type_event=day`;
    let paramsRangeSummarySizeMonth = `?type_size=month`;
    let paramsRangeSummarySizeDay = `?type_size=day`;

    const [
      sources,
      countSource,
      topEvent,
      summaryEventMonth,
      summaryEventDay,
      SummarySizeMonth,
      SummarySizeDay,
    ] = yield all([
      call(LogManagementServices.getSourcesByTenancy, { params: paramsSource }),
      call(LogManagementServices.getDashboardCountSource),
      call(LogManagementServices.getDashboardTopEvent),
      call(LogManagementServices.getDashboardSummaryEvent, {
        params: paramsSummaryEventMonth,
      }),
      call(LogManagementServices.getDashboardSummaryEvent, {
        params: paramsSummaryEventDay,
      }),
      call(LogManagementServices.getDashboardSummarySize, {
        type_size: "month",
        sources: payload.sources,
      }),
      call(LogManagementServices.getDashboardSummarySize, {
        type_size: "day",
        sources: payload.sources,
      }),
    ]);

    const sourceValueList = sources?.data || [];

    const initData = {
      sourceNumber: countSource?.data || 0,
      topEvent: topEvent?.data?.map((v) => {
        const sourceName = sourceValueList.find(
          (source) => source.value === v.source
        )?.label;
        let newObj = {};
        newObj["key"] = sourceName;
        newObj[sourceName] = v.sum;

        return newObj;
      }),
      flowOverview: {
        perMonth: summaryEventMonth?.data?.sum || 0,
        perDay: summaryEventDay?.data?.sum || 0,
      },
      flowSource: {
        perMonth: Object.keys(SummarySizeMonth?.data || {})
          .filter((item) => sourceValueList.map((v) => v.value).includes(item))
          .map((item, index) => {
            const summaryData = SummarySizeMonth?.data[item];
            const sourceName =
              sourceValueList.find((v) => v.value === summaryData.source)
                ?.label || `ID ${item.slice(0, 5)}... Not Found`;
            return {
              id: sourceName,
              sourceName: sourceName,
              label: `${sourceName} : ${displaySize(summaryData.sum_size)}`,
              value: SizeValue(summaryData.sum_size),
              color:
                `${CHART_DEFAULT_COLOR_SCHEME[index]}` === "undefined"
                  ? `#${Math.floor(Math.random() * 16777215).toString(16)}`
                  : `${CHART_DEFAULT_COLOR_SCHEME[index]}`,
            };
          }),

        perDay: Object.keys(SummarySizeDay?.data || {})
          .filter((item) => sourceValueList.map((v) => v.value).includes(item))
          .map((item, index) => {
            const summaryData = SummarySizeDay?.data[item];
            const sourceName =
              sourceValueList.find((v) => v.value === summaryData.source)
                ?.label || `ID ${item.slice(0, 5)}... Not Found`;
            return {
              id: sourceName,
              sourceName: sourceName,
              label: `${sourceName} : ${displaySize(summaryData.sum_size)}`,
              value: SizeValue(summaryData.sum_size),
              color:
                `${CHART_DEFAULT_COLOR_SCHEME[index]}` === "undefined"
                  ? `#${Math.floor(Math.random() * 16777215).toString(16)}`
                  : `${CHART_DEFAULT_COLOR_SCHEME[index]}`,
            };
          }),
      },
    };

    yield put(setSTDashboard(initData));
    yield put(setGlobalLoading(false));
    yield put(setListSIEMSource(sourceValueList));
  });
}

export function* getDYDashboard() {
  yield takeEvery(GET_DY_DASHBOARD, function* ({ payload }) {
    let paramsSource = `?response_type=lists`;
    let paramsRange = `?hour=${payload.hour}`;

    const [sources, rangeEvent] = yield all([
      call(LogManagementServices.getSourcesByTenancy, { params: paramsSource }),
      call(LogManagementServices.getDashboardRangeEvent, {
        hour: payload.hour,
        sources: payload.sources,
      }),
    ]);

    const sourceValueList = sources.data;

    const initData = {
      flowRange: Object.keys(rangeEvent?.data).map((eachSource, index) => {
        const sourceName = sourceValueList.find(
          (v) => v.value === eachSource
        )?.label;

        return {
          id: sourceName,
          color:
            `${CHART_DEFAULT_COLOR_SCHEME[index]}` === "undefined"
              ? `#${Math.floor(Math.random() * 16777215).toString(16)}`
              : `${CHART_DEFAULT_COLOR_SCHEME[index]}`,
          data: Object.keys(rangeEvent?.data[eachSource]).map((v) => {
            return {
              x: changeFormatDatetime(
                moment(rangeEvent.data[eachSource][v].timestamp).zone(
                  "Asia/Bangkok"
                ),
                "YYYY-MM-DD HH:mm:ss"
              ),
              y: rangeEvent.data[eachSource][v].event,
            };
          }),
        };
      }),
    };
    yield put(setDYDashboard(initData));
  });
}

// SOURCE
export function* getListSoruce() {
  yield takeEvery(GET_SOURCE_LIST, function* () {
    yield put(setGlobalLoading(false));
    const resp = yield call(LogManagementServices.getSourceList, {});

    const { status, data } = resp;

    if (status === "OK") {
      const mapData = data.map(
        (value) => new CyberKitLogMangementSourceModel(value)
      );
      yield put(setListSource(mapData));
    }
  });
}

export function* createSource() {
  yield takeEvery(CREATE_SOURCE, function* ({ payload }) {
    const state = yield select();
    const sources = state.cyberKitLogManagement.source.sourceList.map(
      (v) => v.name
    );

    if (sources.includes(payload.data.name)) {
      Notification("success", `ชื่อ ${payload.data.name} มีอยู่แล้ว`);
      return;
    }

    yield put(setGlobalLoading(true));
    const resp = yield call(LogManagementServices.createSource, payload);

    const { status, response } = resp;

    if (status === "OK") {
      setTimeout(function () {
        Notification(
          "success",
          `สร้างการแจ้งเตือน ${payload.data.name} สำเร็จ`
        );
        payload.next();
      }, 10000);
    } else {
      yield put(setGlobalLoading(false));
      Notification("error", response.data.error.message);
    }

    return;
  });
}

export function* updateSource() {
  yield takeEvery(UPDATE_SOURCE, function* ({ payload }) {
    yield put(setGlobalLoading(true));
    const resp = yield call(LogManagementServices.updateSource, payload);

    const { status } = resp;

    if (status === "OK") {
      setTimeout(function () {
        Notification("success", `อัพเดตข้อมูล ${payload.data.name} สำเร็จ`);
        payload.next();
      }, 10000);
    } else {
      yield put(setGlobalLoading(false));
      Notification("error", `เกิดข้อผิดพลาดบางอย่าง`);
    }
  });
}

export function* deleteSource() {
  yield takeEvery(DELETE_SOURCE, function* ({ payload }) {
    yield put(setGlobalLoading(true));
    const resp = yield call(LogManagementServices.deleteSource, payload);

    const { status } = resp;

    if (status === "OK") {
      setTimeout(function () {
        Notification("success", `ลบ ${payload.data.header} สำเร็จ`);
        payload.next();
      }, 10000);
    } else {
      yield put(setGlobalLoading(false));
      Notification(
        "error",
        `ไม่สามารถลบข้อมูลได้ เนื่องจากถูกใช้งานที่เงื่อนไขการตรวจจับ`
      );
    }
    return;
  });
}

export function* getSourceSIEMList() {
  yield takeEvery(GET_SOURCE_SIEM_LIST, function* ({ payload }) {
    let params = ``;
    const keyParamList = Object.keys(payload.params || {});
    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);

    const payloadService = {
      ...payload,
      params: params,
    };

    const { status, data } = yield call(
      LogManagementServices.getSourceSIEMList,
      payloadService
    );

    if (status === "OK") {
      yield put(setListSIEMSource(data));
    }
  });
}

export function* clearListSIEMSoruce() {
  yield takeEvery(CLEAR_SOURCE_SIEM_LIST, function* () {
    try {
      yield put(setListSIEMSource([]));
    } catch (err) {
      console.log(err);
    }
  });
}

// LOG MANAGEMENT - SEARCH
export function* getSourceListByTenancy() {
  yield takeEvery(GET_SOURCE_LIST_BY_TENANACY, function* ({ payload }) {
    let params = ``;
    const keyParamList = Object.keys(payload.params || {});
    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);

    const payloadService = {
      path: payload.path,
      params: params,
    };

    const { status, data } = yield call(
      LogManagementServices.getSourcesByTenancy,
      payloadService
    );

    if (status === "OK") {
      yield put(setListSIEMSource(data));
    }
  });
}

export function* getSIEMFields() {
  yield takeEvery(GET_LOG_SIEM_FIELDS, function* ({ payload }) {
    let params = ``;
    const keyParamList = Object.keys(payload.params || {});
    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);

    const payloadService = { params: params };

    const { status, data } = yield call(
      LogManagementServices.getSIEMFields,
      payloadService
    );

    if (status === "OK") yield put(setSIEMField(data));
  });
}

// EXPLORER
export function* getLogs() {
  yield takeEvery(GET_LOG_LIST, function* ({ payload }) {
    let params = ``;

    const keyParamList = Object.keys(payload.params || {});
    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);

    const payloadService = { params: params };

    yield put(setGlobalLoading(true));
    const { status, data } = yield call(
      LogManagementServices.getLogList,
      payloadService
    );
    yield put(setGlobalLoading(false));

    const level = payload.params.level;

    if (status === "OK") {
      if (level === "date") yield put(setDateFolders(data));
      else if (level === "hour") yield put(setHourFolders(data));
      else yield put(setExplorers(data));
    }
  });
}

export function* createArchive() {
  yield takeEvery(CREATE_ARCHIVE, function* ({ payload }) {
    const resp = yield call(LogManagementServices.createArchive, payload);

    const { status } = resp;

    if (status === "OK") {
      Notification("success", `จัดเก็บข้อมูล Log File สำเร็จ`);
      payload.next();
    } else {
      Notification("error", `เกิดข้อผิดพลาดบางอย่าง`);
    }
  });
}

export function* deleteArchive() {
  yield takeEvery(DELETE_ARCHIVE, function* ({ payload }) {
    const resp = yield call(LogManagementServices.deleteArchive, payload);

    const { status } = resp;

    if (status === "OK") {
      Notification("success", `ยกเลิกการจัดเก็บข้อมูล Log File สำเร็จ`);
      payload.next();
    } else {
      Notification("error", `เกิดข้อผิดพลาดบางอย่าง`);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getSTDashboard),
    fork(getDYDashboard),
    fork(getListSoruce),
    fork(getSourceListByTenancy),
    fork(createSource),
    fork(updateSource),
    fork(deleteSource),
    fork(getSourceSIEMList),
    fork(clearListSIEMSoruce),

    fork(getSIEMFields),

    fork(getLogs),
    fork(createArchive),
    fork(deleteArchive),
  ]);
}
