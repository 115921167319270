import {
  TOGGLE_MANAGE_DATA_MAPPING_DATA_WAREHOUSE_MODAL,
  LIST_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP_SUCCESS,
  MANAGE_DATA_MAPPING_DATA_WHERE_HOUSE,
  CREATE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  CREATE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED,
  UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED,
  TOGGLE_DELETE_DATA_MAPPING_DATA_WAREHOUSE_MODAL,
  DELETE_DATA_MAPPING_DATA_WHERE_HOUSE,
  DELETE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  DELETE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED,
} from "../../constants/IPrivacyKitDataMapping";
import { replaceObject } from "util/arrayUtils";

const initialState = {
  manageModal: {
    visible: false,
    data: null,
    loading: false,
    okButtonProps: {
      loading: false,
    },
  },
  deleteModal: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
  listDataWareHouse: null,
  listDataWareHouseGroup: null,
  allDataWareHouseGroup: null,
};

export default function dataWarehouseReducers(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MANAGE_DATA_MAPPING_DATA_WAREHOUSE_MODAL: {
      return {
        ...state,
        manageModal: {
          ...state.manageModal,
          data: action.payload ?? null,
          visible: !state.manageModal.visible,
        },
      };
    }
    case LIST_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS: {
      return {
        ...state,
        listDataWareHouse: action.payload,
      };
    }
    case LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP_SUCCESS: {
      return {
        ...state,
        listDataWareHouseGroup: action.payload.listDataWareHouseGroup,
        allDataWareHouseGroup: action.payload.allDataWareHouseGroup,
      };
    }
    case MANAGE_DATA_MAPPING_DATA_WHERE_HOUSE: {
      return {
        ...state,
        manageModal: {
          ...state.manageModal,
          okButtonProps: {
            ...state.manageModal.okButtonProps,
            loading: true,
          },
        },
      };
    }
    case CREATE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS: {
      return {
        ...state,
        listDataWareHouse: [action.payload, ...state.listDataWareHouse],
        manageModal: initialState.manageModal,
      };
    }
    case CREATE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED: {
      return {
        ...state,
        manageModal: {
          ...state.manageModal,
          okButtonProps: initialState.manageModal.okButtonProps,
        },
      };
    }
    case UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS: {
      return {
        ...state,
        listDataWareHouse: [
          ...replaceObject(
            state.listDataWareHouse,
            action.payload.ObjectUUID,
            action.payload
          ),
        ],
        manageModal: initialState.manageModal,
      };
    }
    case UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED: {
      return {
        ...state,
        manageModal: {
          ...state.manageModal,
          okButtonProps: initialState.manageModal.okButtonProps,
        },
      };
    }
    case TOGGLE_DELETE_DATA_MAPPING_DATA_WAREHOUSE_MODAL: {
      return {
        ...state,
        deleteModal: {
          ...initialState.deleteModal,
          data: action.payload ?? null,
          visible: !state.deleteModal.visible,
        },
      };
    }
    case DELETE_DATA_MAPPING_DATA_WHERE_HOUSE: {
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          okButtonProps: {
            ...state.deleteModal.okButtonProps,
            loading: true,
          },
        },
      };
    }
    case DELETE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS: {
      return {
        ...state,
        listDataWareHouse: [
          ...state.listDataWareHouse.filter(
            (item) => item.ObjectUUID !== state.deleteModal.data.ObjectUUID
          ),
        ],
        deleteModal: initialState.deleteModal,
      };
    }
    case DELETE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED: {
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          okButtonProps: initialState.deleteModal.okButtonProps,
        },
      };
    }
    default: {
      return state;
    }
  }
}
