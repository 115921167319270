import PrivacyKitLogo from "../assets/img/app-logo/privacykit-logo.png";
import CyberKitLogo from "../assets/img/app-logo/cyberkit-logo.png";

import PrivacyKitCookieConsentManagementLogo from "assets/img/module-in-privacykit-logo/cookit-consent-management-logo.svg";
import PrivacyKitDataSubjectRightManagementLogo from "assets/img/module-in-privacykit-logo/data-subject-right-management-logo.svg";
import PrivacyKitUniversalConsentAndPreferenceManagementLogo from "assets/img/module-in-privacykit-logo/user-consent-lifecycle-management-logo.svg";
import PrivacyKitUserConsentLifecycleManagementLogo from "assets/img/module-in-privacykit-logo/user-consent-lifecycle-management-logo.png";
import PrivacyKitDataBreachNotificationLogo from "assets/img/module-in-privacykit-logo/data-breach-notification-logo.png";
import PrivacyKitIncidentResponseManagementLogo from "assets/img/module-in-privacykit-logo/incident-response-management-logo.png";
import PrivacyKitPrivacyAssessmentAutomationLogo from "assets/img/module-in-privacykit-logo/privacy-assessment-automation-logo.png";
import PrivacyKitInternalAssessmentAutomationLogo from "assets/img/module-in-privacykit-logo/internal-assessment-automation-logo.png";
import PrivacyKitVendorAssessmentAutomationLogo from "assets/img/module-in-privacykit-logo/vendor-assessment-automation-logo.png";
import PrivacyKitDataClassificationAndDiscoveryLogo from "assets/img/module-in-privacykit-logo/data-classification-and-discovery-logo.png";
import PrivacyKitDataMappingLogo from "assets/img/module-in-privacykit-logo/data-mapping-logo.png";
import PrivacyKitComplianceManagementLogo from "assets/img/module-in-privacykit-logo/compliance-management-logo.png";
import PrivacyKitPolicyAndNoticeManagementLogo from "assets/img/module-in-privacykit-logo/policy-and-notice-management.svg";

import CyberKitLogManagementLogo from "assets/img/module-in-cyberkit-logo/log-management-logo.png";
import CyberKitSiemLogo from "assets/img/module-in-cyberkit-logo/siem-logo.png";
import CyberKitSoarLogo from "assets/img/module-in-cyberkit-logo/soar-logo.png";
import CyberKitItAssetInventoryLogo from "assets/img/module-in-cyberkit-logo/it-asset-inventory-logo.png";
import CyberKitItVulnerabilityAssessmentLogo from "assets/img/module-in-cyberkit-logo/vulnerability-assessment-logo.png";
// import CyberKitComplianceManagementLogo from "assets/img/module-in-cyberkit-logo/compliance-management.png";
import CyberKitCyberThreatIntelligenceLogo from "assets/img/module-in-cyberkit-logo/cyber-threat-intelligence.png";

export const PrivacyKitPath = "/apps/privacykit";
export const CyberKitPath = "/apps/cyberkit";
export const OrganizationsManagementPath = "/apps/management";
export const SettingUserPath = "/apps/setting";
export const AppConfig = [
  {
    title: "Privacy Kit",
    id: "privacy_kit",
    logo: PrivacyKitLogo,
    description: "ระบบบริหารจัดการด้านการคุ้มครองข้อมูลส่วนบุคคล",
    path: PrivacyKitPath,
    ability: "apps.privacykit",
  },
  {
    title: "Cyber Kit",
    id: "cyber_kit",
    logo: CyberKitLogo,
    description: "ระบบบริการจัดการความมั่นคงปลอดภัยไซเบอร์",
    path: CyberKitPath,
    ability: "apps.cyberkit",
  },
];

export const PrivacyKitCookieConsentManagementPath =
  "/cookie-consent-management";
export const PrivacyKitDataSubjectRightManagementPath =
  "/data-subject-right-management";
export const PrivacyKitUniversalConsentAndPreferenceManagementPath = `/universal-consent-and-preference-management`;
export const PrivacyKitDataBreachNotificationPath = "/data-breach-notification";
export const PrivacyKitIncidentResponseManagementPath = `/incident-response-management`;
export const PrivacyKitPrivacyAssessmentAutomationPath = `/privacy-assessment-automation`;
export const PrivacyKitInternalAssessmentAutomationPath = `/internal`;
export const PrivacyKitVendorAssessmentAutomationPath = `/vendor`;
export const PrivacyKitDataClassificationAndDiscoveryPath =
  "/data-classification-and-discovery";
export const PrivacyKitAssessmentAutomationPath = "/assessment-automation";
export const PrivacyKitDataMappingPath = "/data-mapping";
export const PrivacyKitCompliantManagementPath = "/compliance-management";
export const PrivacyKitPolicyAndNoticeManagementPath =
  "/policy-and-notice-management";

export const PrivacyKitConfig = [
  {
    title: "Cookie Consent Management",
    id: "cookie-consent-management",
    logo: PrivacyKitCookieConsentManagementLogo,
    description:
      "สร้างและบริหารจัดการการให้ความยินยอมคุกกี้หน้าเว็บไชต์ให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562",
    path: `${PrivacyKitPath}${PrivacyKitCookieConsentManagementPath}`,
    ability: "apps.privacykit.cookie_consent_management",
  },
  {
    title: "Data Subject Right Management: DSR ",
    id: "data-subject-right-management",
    logo: PrivacyKitDataSubjectRightManagementLogo,
    description:
      "สร้างช่องทางการใช้สิทธิของเจ้าของข้อมูลและการบริหารกระบวนการขอใช้สิทธิตามกฎหมาย",
    path: `${PrivacyKitPath}${PrivacyKitDataSubjectRightManagementPath}`,
    ability: "apps.privacykit.data_subject_right_management",
  },
  {
    title: "User Consent LifecycleManagement",
    id: "universal-consent-and-preference-management",
    logo: PrivacyKitUniversalConsentAndPreferenceManagementLogo,
    description:
      "บริหารจัดการวงจรการให้ความยินยอมของผู้ใช้ ในขั้นตอนต่าง ๆ ของเจ้าของข้อมูลแต่ละคน และการสร้างเอกสารแม่แบบ (Template /Document) สำหรับการให้ความยินยอมตามกฎหมาย",
    path: `${PrivacyKitPath}${PrivacyKitUniversalConsentAndPreferenceManagementPath}`,
    ability: "apps.privacykit.universal_consent_and_preference_management",
  },
  {
    title: "User Consent Lifecycle Management",
    id: "user-consent-lifecycle-management",
    logo: PrivacyKitUserConsentLifecycleManagementLogo,
    description:
      "บริหารจัดการความยินยอมในขั้นตอนต่าง ๆ ของเจ้าของข้อมูลแต่ละคนได้",
    path: `${PrivacyKitPath}/user-consent-lifecycle-management`,
    ability: "apps.privacykit.user_consent_lifecycle_management",
  },
  {
    title: "Data Breach Notification and Incident Response Management ",
    id: "data-breach-notification",
    logo: PrivacyKitDataBreachNotificationLogo,
    description:
      "บริหารจัดการการแจ้งเตือนการละเมิด และการรั่วไหลของข้อมูลไปใน อินเตอร์เน็ต หรือ Dark Web และการตอบสนองต่อเหตุการณ์ภัยคุกคามไซเบอร์ ให้เป็นไปตามขั้นตอนของกฎหมาย",
    path: `${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}`,
    ability: "apps.privacykit.data_breach_notification",
  },
  {
    title: "Incident Response Management",
    id: "incident-response-management",
    logo: PrivacyKitIncidentResponseManagementLogo,
    description:
      "สร้างกระบวนการตอบสนองหากมีการรั่วไหลของข้อมูลให้ขั้นตอนตามกฎหมายได้",
    path: `${PrivacyKitPath}${PrivacyKitIncidentResponseManagementPath}`,
    ability: "apps.privacykit.incident_response_management",
  },
  {
    title: "Privacy Assessment",
    id: "privacy-assessment-automation",
    logo: PrivacyKitPrivacyAssessmentAutomationLogo,
    description:
      "ประเมินความเสี่ยงด้านการคุ้มครองข้อมูลและข้อมูลส่วนบุคคลตามกฎหมาย",
    path: `${PrivacyKitPath}${PrivacyKitPrivacyAssessmentAutomationPath}`,
    ability: "apps.privacykit.privacy_assessment_automation",
  },
  // {
  //   title: "Internal Assessment Automation",
  //   id: "internal-assessment-automation",
  //   logo: PrivacyKitInternalAssessmentAutomationLogo,
  //   description:
  //     "ประเมินความเสี่ยงด้าน Data protection and privacy ได้ตามกฎหมาย",
  //   path: `${PrivacyKitPath}${PrivacyKitAssessmentAutomationPath}${PrivacyKitInternalAssessmentAutomationPath}`,
  //   ability: "apps.privacykit.internal_assessment_automation",
  // },
  {
    title: "PDPA Compliance Level",
    id: "compliance-management",
    logo: PrivacyKitComplianceManagementLogo,
    description:
      "ประเมินความพร้อมและประเมินระดับความสอดคล้องของการคุ้มครองข้อมูลส่วนบุคคลตามกฎหมาย",
    path: `${PrivacyKitPath}${PrivacyKitCompliantManagementPath}/compliance-privacykit`,
    ability: "apps.privacykit.compliance_management",
  },
  {
    title: "IT Vendor Risk Assessment",
    id: "vendor-assessment-automation",
    logo: PrivacyKitVendorAssessmentAutomationLogo,
    description:
      "ประเมินความเสี่ยงของผู้ขาย/ผู้ให้บริการด้านเทคโนโลยีสารสนเทศและการสื่อสาร",
    path: `${PrivacyKitPath}${PrivacyKitAssessmentAutomationPath}${PrivacyKitVendorAssessmentAutomationPath}`,
    ability: "apps.privacykit.vendor_assessment_automation",
  },
  {
    title: "Data Classification and Discovery",
    id: "data-classification-and-discovery",
    logo: PrivacyKitDataClassificationAndDiscoveryLogo,
    description: "จำแนกและค้นหาข้อมูลส่วนบุคคล ประเภทต่าง ๆ",
    path: `${PrivacyKitPath}${PrivacyKitDataClassificationAndDiscoveryPath}`,
    ability: "apps.privacykit.data_classification_and_discovery",
  },
  {
    title: "Data Mapping",
    id: "data-mapping",
    logo: PrivacyKitDataMappingLogo,
    description:
      "วิเคราะห์กระแสข้อมูลที่ไหลภายในองค์กร (Data Flow) และการทำแผนที่ข้อมูลส่วนบุคคล",
    path: `${PrivacyKitPath}${PrivacyKitDataMappingPath}`,
    ability: "apps.privacykit.data_mapping",
  },
  {
    title: "Policy and Notice Management",
    id: "policy-and-notice-management",
    logo: PrivacyKitPolicyAndNoticeManagementLogo,
    description:
      "การสร้างและบริหารจัดการนโยบายและประกาศที่เกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล",
    path: `${PrivacyKitPath}${PrivacyKitPolicyAndNoticeManagementPath}`,
    ability: "apps.privacykit.policy_and_notice_management",
  },
];

export const CyberKitLogManagementPath = `/log-management`;
export const CyberKitSiemPath = `/siem`;
export const CyberKitSoarPath = `/soar`;
export const CyberKitITAssetInventoryPath = `/it-asset-inventory`;
export const CyberKitVulnerabilityAssessmentPath = `/vulnerability-assessment`;
export const CyberKitComplianceManagementPath = `/compliance-management`;
export const CyberKitThreatIntelligencePath = `/cyber-threat-intelligence`;

export const CyberKitConfig = [
  {
    title: "Log Management",
    id: "log-management",
    logo: CyberKitLogManagementLogo,
    description:
      "จัดการข้อมูลติดต่อสื่อสารของระบบคอมพิวเตอร์พร้อมจัดเก็บ Log File",
    path: `${CyberKitPath}${CyberKitLogManagementPath}`,
    ability: "apps.cyberkit.log-management",
  },
  {
    title: "Security Information and Event Management: SIEM",
    id: "siem",
    logo: CyberKitSiemLogo,
    description:
      "ด้านการบริหารจัดการข้อมูลด้านความปลอดภัยและการจัดการเหตุการณ์",
    path: `${CyberKitPath}${CyberKitSiemPath}`,
    ability: "apps.cyberkit.siem",
  },
  {
    title: "Security Orchestration, Automation and Response: SOAR",
    id: "soar",
    logo: CyberKitSoarLogo,
    description:
      "ด้านการประสานการทำงานของระบบความปลอดภัย การทำงานแบบอัตโนมัติ เพิ่มความเร็วในการตอบสนองต่อเหตุการณ์ภัยไซเบอร์",
    path: `${CyberKitPath}${CyberKitSoarPath}`,
    ability: "apps.cyberkit.soar",
  },
  {
    title: "Cybersecurity Asset",
    id: "it-asset-inventory",
    logo: CyberKitItAssetInventoryLogo,
    description:
      "ด้านการจัดการทรัพย์สินเกี่ยวกับความมั่นคงปลอดภัยไซเบอร์ขององค์กร",
    path: `${CyberKitPath}${CyberKitITAssetInventoryPath}`,
    ability: "apps.cyberkit.it_asset_inventory",
  },
  {
    title: "Vulnerability Assessment",
    id: "vulnerability-assessment",
    logo: CyberKitItVulnerabilityAssessmentLogo,
    description: "ด้านการตรวจสอบช่องโหว่ของระบบ",
    path: `${CyberKitPath}${CyberKitVulnerabilityAssessmentPath}`,
    ability: "apps.cyberkit.vulnerability_assessment",
  },
  // {
  //   title: "Cybersecurity Compliance Management",
  //   id: "compliance-management",
  //   logo: CyberKitComplianceManagementLogo,
  //   description: "การจัดการการปฏิบัติตามข้อกำหนดด้านความปลอดภัยทางไซเบอร์",
  //   path: `${CyberKitPath}${CyberKitComplianceManagementPath}/compliance-cyberkit`,
  //   ability: "apps.cyberkit.compliance_management",
  // },
  {
    title: "Cyber Threat Intelligence",
    id: "cyber-threat-intelligence",
    logo: CyberKitCyberThreatIntelligenceLogo,
    description:
      "การดึงข้อมูลการข่าวด้านภัยคุกคามทางไซเบอร์ที่เกิดขึ้นใหม่เพื่อนำมาอัพเดทในฐานข้อมูลภัยคุกคามไซเบอร์",
    path: `${CyberKitPath}${CyberKitThreatIntelligencePath}`,
    ability: "apps.cyberkit.cyber_threat_intelligence",
  },
];
