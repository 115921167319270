import fetch from "util/Api";
import { PRIVACYKIT_DATA_CLASSIFICATION_AND_DISCOVERY_BASE_URL as endpoint } from "config/EndpointConfig";

const DataClassificationAndDiscoveryService = {};

DataClassificationAndDiscoveryService.listData = async () =>
  await fetch({
    url: `${endpoint}/discovery/find?search`,
    method: "get",
  });

DataClassificationAndDiscoveryService.searchData = async (search) =>
  await fetch({
    url: `${endpoint}/discovery/find?search=${search}`,
    method: "get",
  });

export default DataClassificationAndDiscoveryService;
