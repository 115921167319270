import {
  LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED_SUCCESS,
  CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_SUCCESS,
  UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE_SUCCESS,
  UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED_SUCCESS,
  LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE_SUCCESS,
  UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED_SUCCESS,
  UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED_SUCCESS,
  LIST_DATA_MAPPING_ASSESSMENT_DELETED_SUCCESS,
  UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED_SUCCESS,
  DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED_SUCCESS,
  LIST_DATA_MAPPING_RISK_SUCCESS,
  CREATE_DATA_MAPPING_RISK_SUCCESS,
  GET_DATA_MAPPING_RISK_SUCCESS,
  UPDATE_DATA_MAPPING_RISK_SUCCESS,
  DELETE_DATA_MAPPING_RISK_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
  CREATE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
  GET_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
  UPDATE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
  CREATE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
  GET_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
  UPDATE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
  CREATE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
  GET_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
  UPDATE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
  CREATE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
  GET_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
  UPDATE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
  LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
  CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
  GET_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
  UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
  DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
  LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE_SUCCESS,
  LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
  CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
  GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
  UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
  DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
  LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_SUCCESS,
  GET_DATA_MAPPING_INVENTORY_SUCCESS,
  CREATE_DATA_MAPPING_MANAGEMENT_SUCCESS,
  UPDATE_DATA_MAPPING_INVENTORY_SUCCESS,
  DELETE_DATA_MAPPING_MANAGEMENT_SUCCESS,
  SET_DATA_MAPPING_INVENTORY_ASSETS,
  LIST_DATA_MAPPING_DASHBOARD_SUCCESS,
  SET_DATA_MAPPING_INVENTORY_ENTITY,
  SET_DATA_MAPPING_INVENTORY_PROCESSING,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS,
  ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS,
  ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS,
  ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_LOCATION_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL_SUCCESS,
  ADD_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS,
  LOADING_DATA_MAPPING,
  LIST_DATA_MAPPING_DATA_WORKFLOW_SUCCESS,
  CREATE_DATA_MAPPING_DATA_WORKFLOW_SUCCESS,
  DELETE_DATA_MAPPING_DATA_WORKFLOW_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS,
  RESET_DATA_MAPPING_ASSET,
  RESET_DATA_MAPPING_ENTITY,
  LIST_DATA_MAPPING_INVENTORY_IT_ASSET_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS,
  GET_DATA_MAPPING_FLOW_SUCCESS,
  LOADING_CREATE_DATA_MAPPING,
  LOADING_DATA_ELEMENT,
  LOADING_ASSET,
  LOADING_CONTROL,
  LOADING_ENTITY,
  ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS,
  LOADING_PROCESSING,
  LOADING_WORKFLOW,
} from "redux/constants/PrivacyKitDataMapping";
import _ from "lodash";

const initState = {
  dataMappingFlow: null,
};

const PrivacyKitDataMapping = (state = initState, action) => {
  switch (action.type) {
    case LIST_DATA_MAPPING_DASHBOARD_SUCCESS: {
      return {
        ...state,
        dataMappingDashboard: action.payload,
      };
    }
    case LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED_SUCCESS: {
      return {
        ...state,
        listAssessmentActivated: action.payload,
      };
    }
    case CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_SUCCESS: {
      return {
        ...state,
        listAssessmentActivated: {
          data: [action.payload, ...state.listAssessmentActivated.data],
        },
      };
    }
    case UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE_SUCCESS: {
      const { data } = state.listAssessmentActivated;
      return {
        ...state,
        listAssessmentActivated: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
        listAssessmentArchive: state.listAssessmentArchive
          ? {
              ...state.listAssessmentArchive,
              data: [action.payload, ...state.listAssessmentArchive.data],
            }
          : undefined,
      };
    }
    case UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED_SUCCESS: {
      const { data } = state.listAssessmentActivated;
      return {
        ...state,
        listAssessmentActivated: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
        listAssessmentDeleted: state.listAssessmentDeleted
          ? {
              ...state.listAssessmentDeleted,
              data: [action.payload, ...state.listAssessmentDeleted.data],
            }
          : undefined,
      };
    }
    case LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE_SUCCESS: {
      return {
        ...state,
        listAssessmentArchive: action.payload,
      };
    }
    case UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED_SUCCESS: {
      const { data } = state.listAssessmentArchive;
      return {
        ...state,
        listAssessmentArchive: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
        listAssessmentActivated: state.listAssessmentActivated
          ? {
              ...state.listAssessmentActivated,
              data: [action.payload, ...state.listAssessmentActivated.data],
            }
          : undefined,
      };
    }
    case UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED_SUCCESS: {
      const { data } = state.listAssessmentArchive;
      return {
        ...state,
        listAssessmentArchive: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
        listAssessmentDeleted: state.listAssessmentDeleted
          ? {
              ...state.listAssessmentDeleted,
              data: [action.payload, ...state.listAssessmentDeleted.data],
            }
          : undefined,
      };
    }
    case LIST_DATA_MAPPING_ASSESSMENT_DELETED_SUCCESS: {
      return {
        ...state,
        listAssessmentDeleted: action.payload,
      };
    }
    case UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED_SUCCESS: {
      const { data } = state.listAssessmentDeleted;
      return {
        ...state,
        listAssessmentDeleted: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
        listAssessmentActivated: state.listAssessmentActivated
          ? {
              ...state.listAssessmentActivated,
              data: [action.payload, ...state.listAssessmentActivated.data],
            }
          : undefined,
      };
    }
    case DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED_SUCCESS: {
      return {
        ...state,
        listAssessmentDeleted: {
          data: [
            ..._.filter(
              state.listAssessmentDeleted.data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload
            ),
          ],
        },
      };
    }
    case LIST_DATA_MAPPING_RISK_SUCCESS: {
      return {
        ...state,
        riskData: undefined,
        listRisk: action.payload,
      };
    }
    case CREATE_DATA_MAPPING_RISK_SUCCESS: {
      return {
        ...state,
        listRisk: {
          data: [action.payload, ...state.listRisk.data],
        },
      };
    }
    case GET_DATA_MAPPING_RISK_SUCCESS: {
      return {
        ...state,
        riskData: action.payload,
      };
    }
    case UPDATE_DATA_MAPPING_RISK_SUCCESS: {
      const { data } = state.listRisk;
      return {
        ...state,
        listRisk: {
          data: [
            action.payload,
            ...state.listRisk.data,
            _.filter(
              data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
            ),
          ],
        },
        ...state,
        riskData: { ...action.payload, ...state.riskData },
      };
    }
    case DELETE_DATA_MAPPING_RISK_SUCCESS: {
      return {
        ...state,
        listRisk: {
          data: [
            ..._.filter(
              state.listRisk.data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload
            ),
          ],
        },
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS: {
      return {
        ...state,
        listInventory: action.payload,
      };
    }
    case RESET_DATA_MAPPING_ASSET: {
      return {
        ...state,
        inventoryAssets: undefined,
        listInventoryAssetDataElement: undefined,
        listInventoryAssetDataInventoryRelated: undefined,
        listInventoryControl: undefined,
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        listInventoryAssetAssessment: action.payload,
      };
    }
    case ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        listInventoryAssetAssessment: {
          data: [action.payload, ...state.listInventoryAssetAssessment.data],
        },
      };
    }
    case DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS: {
      const { data } = state.listInventoryAssetAssessment.data;
      return {
        ...state,
        listInventoryAssetAssessment: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS: {
      return {
        ...state,
        listInventoryAssetDataElement: action.payload,
      };
    }
    case ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS: {
      return {
        ...state,
        listInventoryAssetDataElement: {
          data: [
            ...action.payload,
            ...state.listInventoryAssetDataElement.data,
          ],
        },
      };
    }
    case DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS: {
      const data = state.listInventoryAssetDataElement.data;
      return {
        ...state,
        listInventoryAssetDataElement: {
          data: _.filter(
            data,
            ({ data_element_id }) => data_element_id !== action.payload
          ),
        },
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS: {
      return {
        ...state,
        listInventoryAssetDataInventoryRelated: action.payload,
      };
    }
    case ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS: {
      return {
        ...state,
        listInventoryAssetDataInventoryRelated: {
          data: [
            ...action.payload,
            ...state.listInventoryAssetDataInventoryRelated.data,
          ],
        },
      };
    }
    case DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS: {
      const data = state.listInventoryAssetDataInventoryRelated.data;
      return {
        ...state,
        listInventoryAssetDataInventoryRelated: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload
          ),
        },
      };
    }
    case CREATE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS: {
      return {
        ...state,
        listInventory: {
          data: [action.payload, ...state.listInventory.data],
        },
      };
    }
    case GET_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS: {
      return {
        ...state,
        inventoryAssets: action.payload,
      };
    }
    case SET_DATA_MAPPING_INVENTORY_ASSETS: {
      return {
        ...state,
        inventoryAssets: action.payload,
      };
    }
    case UPDATE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS: {
      const { data } = state.listInventory;
      const dataIndex = _.findIndex(
        data,
        ({ ObjectUUID }) => ObjectUUID === action.payload.ObjectUUID
      );
      data[dataIndex] = action.payload;
      return {
        ...state,
        listInventory: { ...state.listInventory, data },
        ...state,
        inventoryAssets: { ...action.payload, ...state.inventoryAssets },
      };
    }
    case DELETE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS: {
      return {
        ...state,
        listInventory: {
          data: [
            ..._.filter(
              state.listInventory.data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload
            ),
          ],
        },
      };
    }

    case LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED_SUCCESS: {
      return {
        ...state,
        listInventoryProcessingDataInventoryRelated: action.payload,
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS: {
      return {
        ...state,
        listInventoryProcessing: action.payload,
      };
    }
    case SET_DATA_MAPPING_INVENTORY_PROCESSING: {
      return {
        ...state,
        inventoryProcessing: action.payload,
      };
    }
    case CREATE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS: {
      return {
        ...state,
        listInventoryProcessing: {
          data: [action.payload, ...state.listInventoryProcessing.data],
        },
      };
    }
    case GET_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS: {
      return {
        ...state,
        inventoryProcessing: action.payload,
      };
    }
    case UPDATE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS: {
      const { data } = state.listInventoryProcessing;
      const dataIndex = _.findIndex(
        data,
        ({ ObjectUUID }) => ObjectUUID === action.payload.ObjectUUID
      );
      data[dataIndex] = action.payload;
      return {
        ...state,
        listInventoryProcessing: { ...state.listInventoryProcessing, data },
        ...state,
        inventoryProcessing: {
          ...action.payload,
          ...state.inventoryProcessing,
        },
      };
    }
    case DELETE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS: {
      return {
        ...state,
        listInventoryProcessing: {
          data: [
            ..._.filter(
              state.listInventoryProcessing.data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload
            ),
          ],
        },
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS: {
      return {
        ...state,
        listInventoryEntity: action.payload,
      };
    }
    case RESET_DATA_MAPPING_ENTITY: {
      return {
        ...state,
        inventoryEntity: undefined,
        listInventoryAssetDataInventoryRelated: undefined,
        listInventoryControl: undefined,
      };
    }
    case SET_DATA_MAPPING_INVENTORY_ENTITY: {
      return {
        ...state,
        inventoryEntity: action.payload,
      };
    }
    case CREATE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS: {
      return {
        ...state,
        listInventoryEntity: {
          data: [action.payload, ...state.listInventoryEntity.data],
        },
      };
    }
    case GET_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS: {
      return {
        ...state,
        inventoryEntity: action.payload,
      };
    }

    case UPDATE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS: {
      return {
        ...state,
        inventoryEntity: {
          ...state.listInventoryEntity,
          ...action.payload,
        },
      };
    }

    case DELETE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS: {
      return {
        ...state,
        listInventoryEntity: {
          data: [
            ..._.filter(
              state.listInventoryEntity.data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload
            ),
          ],
        },
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS: {
      return {
        ...state,
        listInventoryVendor: action.payload,
      };
    }
    case CREATE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS: {
      return {
        ...state,
        listInventoryVendor: {
          data: [action.payload, ...state.listInventoryVendor.data],
        },
      };
    }
    case GET_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS: {
      return {
        ...state,
        inventoryVendor: action.payload,
      };
    }
    case UPDATE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS: {
      const { data } = state.listInventoryVendor;
      return {
        ...state,
        listInventoryVendor: {
          data: [
            action.payload,
            ...state.listInventoryVendor.data,
            _.filter(
              data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
            ),
          ],
        },
        ...state,
        inventoryVendor: { ...action.payload, ...state.inventoryVendor },
      };
    }
    case DELETE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS: {
      return {
        ...state,
        listInventoryVendor: {
          data: [
            ..._.filter(
              state.listInventoryVendor.data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload
            ),
          ],
        },
      };
    }
    case LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS: {
      return {
        ...state,
        listSettingAutomationRule: action.payload,
      };
    }
    case CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS: {
      return {
        ...state,
        listSettingAutomationRule: {
          data: [action.payload, ...state.listSettingAutomationRule.data],
        },
      };
    }
    case GET_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS: {
      return {
        ...state,
        settingAutomationRule: action.payload,
      };
    }
    case UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS: {
      const { data } = state.listSettingAutomationRule;
      return {
        ...state,
        listSettingAutomationRule: {
          data: [
            action.payload,
            ...state.listSettingAutomationRule.data,
            _.filter(
              data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
            ),
          ],
        },
        ...state,
        settingAutomationRule: {
          ...action.payload,
          ...state.settingAutomationRule,
        },
      };
    }
    case DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS: {
      return {
        ...state,
        listSettingAutomationRule: {
          data: [
            ..._.filter(
              state.listSettingAutomationRule.data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload
            ),
          ],
        },
      };
    }
    case LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        listSettingAssessmentTemplate: action.payload,
      };
    }
    case LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS: {
      return {
        ...state,
        listSettingAssessmentWorkflow: action.payload,
      };
    }
    case CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS: {
      return {
        ...state,
        listSettingAssessmentWorkflow: {
          data: [action.payload, ...state.listSettingAssessmentWorkflow.data],
        },
      };
    }
    case GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS: {
      return {
        ...state,
        settingAssessmentWorkflow: action.payload,
      };
    }
    case UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS: {
      const { data } = state.listSettingAssessmentWorkflow;
      return {
        ...state,
        listSettingAssessmentWorkflow: {
          data: [
            action.payload,
            ...state.listSettingAssessmentWorkflow.data,
            _.filter(
              data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
            ),
          ],
        },
        ...state,
        settingAssessmentWorkflow: {
          ...action.payload,
          ...state.settingAssessmentWorkflow,
        },
      };
    }
    case DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS: {
      return {
        ...state,
        listSettingAssessmentWorkflow: {
          data: [
            ..._.filter(
              state.listSettingAssessmentWorkflow.data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload
            ),
          ],
        },
      };
    }
    case LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        listManagement: action.payload,
      };
    }
    case CREATE_DATA_MAPPING_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        listManagement: {
          data: [action.payload, ...state.listManagement.data],
        },
      };
    }
    case DELETE_DATA_MAPPING_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        listManagement: {
          data: [
            ..._.filter(
              state.listManagement.data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload
            ),
          ],
        },
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_SUCCESS: {
      return {
        ...state,
        listInventory: action.payload,
      };
    }
    case GET_DATA_MAPPING_INVENTORY_SUCCESS: {
      return {
        ...state,
        dataMappingInventory: action.payload,
      };
    }
    case UPDATE_DATA_MAPPING_INVENTORY_SUCCESS: {
      const updateListInventory = [...state.listInventory.data];
      const updatedIndex = _.findIndex(updateListInventory, {
        ObjectUUID: action.payload.ObjectUUID,
      });
      updateListInventory[updatedIndex] = action.payload;
      return {
        ...state,
        dataMappingInventory: action.payload,
        listInventory: {
          ...state.listInventory,
          data: updateListInventory,
        },
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE_SUCCESS: {
      return {
        ...state,
        listInventoryAssetDataLifeCycle: action.payload,
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_LOCATION_SUCCESS: {
      return {
        ...state,
        listInventoryLocation: action.payload,
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS: {
      return {
        ...state,
        listControl: action.payload,
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM_SUCCESS: {
      return {
        ...state,
        listControlParam: action.payload,
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL_SUCCESS: {
      return {
        ...state,
        listInventoryControl: action.payload,
      };
    }
    case ADD_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS: {
      return {
        ...state,
        listInventoryControl: {
          data: [...action.payload, ...state.listInventoryControl.data],
        },
      };
    }
    case DELETE_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS: {
      const data = state.listInventoryControl.data;
      return {
        ...state,
        listInventoryControl: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload
          ),
        },
      };
    }
    case LOADING_DATA_MAPPING: {
      return {
        ...state,
        isLoadingDataMapping: action.payload,
      };
    }
    case LOADING_CREATE_DATA_MAPPING: {
      return {
        ...state,
        isLoadingCreateDataMapping: action.payload,
      };
    }
    case LOADING_DATA_ELEMENT: {
      return {
        ...state,
        isLoadingDataElement: action.payload,
      };
    }
    case LOADING_ASSET: {
      return {
        ...state,
        isLoadingAsset: action.payload,
      };
    }
    case LOADING_ENTITY: {
      return {
        ...state,
        isLoadingEntity: action.payload,
      };
    }
    case LOADING_CONTROL: {
      return {
        ...state,
        isLoadingControl: action.payload,
      };
    }
    case LOADING_PROCESSING: {
      return {
        ...state,
        isLoadingProcessing: action.payload,
      };
    }
    case LOADING_WORKFLOW: {
      return {
        ...state,
        isLoadingWorkflow: action.payload,
      };
    }
    case LIST_DATA_MAPPING_DATA_WORKFLOW_SUCCESS: {
      return {
        ...state,
        listInventoryDataWorkflow: action.payload,
      };
    }
    case CREATE_DATA_MAPPING_DATA_WORKFLOW_SUCCESS: {
      return {
        ...state,
        listInventoryDataWorkflow: {
          data: [action.payload, ...state.listInventoryDataWorkflow.data],
        },
      };
    }
    case DELETE_DATA_MAPPING_DATA_WORKFLOW_SUCCESS: {
      const data = state.listInventoryDataWorkflow.data;
      return {
        ...state,
        listInventoryDataWorkflow: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload
          ),
        },
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_IT_ASSET_SUCCESS: {
      return {
        ...state,
        listITAsset: action.payload,
      };
    }
    case LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS: {
      return {
        ...state,
        listInventoryEntityDataInventoryRelated: action.payload,
      };
    }
    case ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS: {
      return {
        ...state,
        listInventoryEntityDataInventoryRelated: {
          data: [
            ...action.payload,
            ...state.listInventoryEntityDataInventoryRelated.data,
          ],
        },
      };
    }
    case DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS: {
      const data = state.listInventoryEntityDataInventoryRelated.data;
      return {
        ...state,
        listInventoryEntityDataInventoryRelated: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload
          ),
        },
      };
    }
    case GET_DATA_MAPPING_FLOW_SUCCESS: {
      return {
        ...state,
        dataMappingFlow: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default PrivacyKitDataMapping;
