import { takeEvery, takeLatest, put, call } from "redux-saga/effects";
import {
  LIST_CONSENT_MANAGEMENT,
  GET_CONSENT_MANAGEMENT,
  LIST_CONSENT_MANAGEMENT_NEXT_PAGE,
  ON_SEARCH_CONSENT_MANAGEMENT,
} from "../../constants/PrivacyKitConsentManagement";
import {
  listConsentManagementSuccess,
  getConsentManagementSuccess,
  listConsentManagementNextPageSuccess,
  listConsentManagementNextPageError,
  onSearchConsentManagementSuccess,
} from "../../actions/PrivacyKitConsentManagement";
import UniversalConsentAndPreferenceService from "services/pdpakit/UniversalConsentAndPreferenceService";

export function* listConsentManagement() {
  yield takeEvery(LIST_CONSENT_MANAGEMENT, function* () {
    try {
      const { body } = yield call(
        UniversalConsentAndPreferenceService.listConsent
      );

      if (body.next_page_token) {
        yield put({
          type: LIST_CONSENT_MANAGEMENT_NEXT_PAGE,
          nextPageToken: body.next_page_token,
        });
      }

      if (body) {
        yield put(
          listConsentManagementSuccess({
            listConsent: body.Items,
            nextPageToken: body.next_page_token,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  });
}

export function* listConsentManagementNextPage() {
  yield takeEvery(
    LIST_CONSENT_MANAGEMENT_NEXT_PAGE,
    function* ({ nextPageToken }) {
      try {
        const { body } = yield call(
          UniversalConsentAndPreferenceService.listConsentNextPage,
          nextPageToken
        );

        if (body) {
          yield put(
            listConsentManagementNextPageSuccess({
              listConsent: body.Items,
              nextPageToken: body.next_page_token,
            })
          );
        }
      } catch (error) {
        yield put(listConsentManagementNextPageError());
      }
    }
  );
}

export function* getConsentManagement() {
  yield takeEvery(
    GET_CONSENT_MANAGEMENT,
    function* ({ consentId, collectionId }) {
      try {
        const { Item } = yield call(
          UniversalConsentAndPreferenceService.getConsent,
          { consentId, collectionId }
        );
        yield put(
          getConsentManagementSuccess({
            consentData: Item,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );
}

export function* onSearchConsentManagement() {
  yield takeLatest(ON_SEARCH_CONSENT_MANAGEMENT, function* ({ search }) {
    try {
      const { data, next_page_token } = yield call(
        UniversalConsentAndPreferenceService.searchConsent,
        search
      );
      yield put(
        onSearchConsentManagementSuccess({
          listConsent: data,
          nextPageToken: next_page_token,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });
}
