import { takeEvery, put, call, select, takeLatest } from "redux-saga/effects";
import {
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
  SEARCH_DATA_TASK,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";
import {
  listDataSubjectRightManagementTaskSuccess,
  listDataSubjectRightManagementTaskNextPageSuccess,
  listDataSubjectRightManagementTaskNextPageFailure,
  toggleDataSubjectRightManagementTask,
  updateDataSubjectRightManagementTaskSuccess,
  updateDataSubjectRightManagementTaskFailed,
  searchDataTaskSuccess,
} from "../../actions/PrivacyKitDataSubjectRightManagement";
import DataSubjectRightManagementService from "services/pdpakit/DataSubjectRightManagementService";
import { selectDataSubjectRightManagementManageTask } from "../../selectors/PrivacyKitDataSubjectRightManagement";
import Notification from "util/Notification";
import _ from "lodash";
import { selectListTenantUsers } from "../../selectors/UserManagement";

export function* listDataSubjectRightManagementTask() {
  yield takeEvery(LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK, function* () {
    try {
      const response = yield call(DataSubjectRightManagementService.listTask);
      yield put(
        listDataSubjectRightManagementTaskSuccess({
          listTasks: response.body.items,
          nextPageToken: response.body.next_page_token,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });
}

export function* listDataSubjectRightManagementTaskNextPage() {
  yield takeEvery(
    LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE,
    function* ({ nextPageToken }) {
      try {
        const response = yield call(
          DataSubjectRightManagementService.listTaskNextPage,
          nextPageToken
        );
        yield put(
          listDataSubjectRightManagementTaskNextPageSuccess({
            listTasks: response.body.items,
            nextPageToken: response.body.next_page_token,
          })
        );
      } catch (error) {
        console.log(error);
        yield put(listDataSubjectRightManagementTaskNextPageFailure());
      }
    }
  );
}

export function* getDataSubjectRightManagementTask() {
  yield takeEvery(
    GET_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
    function* ({ payload }) {
      try {
        yield put(toggleDataSubjectRightManagementTask(payload));
      } catch (error) {
        console.log(error);
      }
    }
  );
}

export function* updateDataSubjectRightManagementTask() {
  yield takeEvery(
    UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
    function* ({ values }) {
      try {
        const listUsers = yield select(selectListTenantUsers);
        const { data } = yield select(
          selectDataSubjectRightManagementManageTask
        );
        const payload = {
          ...data,
          ...values,
          approver:
            _.find(listUsers, { ukey: values.assignee }) ?? values.approver,
        };
        const response = yield call(
          DataSubjectRightManagementService.updateTask,
          data.ObjectUUID,
          data.request_id,
          data.created_by,
          values.status,
          payload
        );
        yield put(updateDataSubjectRightManagementTaskSuccess(response.body));
        Notification("success", "แก้ไขงานเรียนร้อยแล้ว");
      } catch (error) {
        yield put(updateDataSubjectRightManagementTaskFailed());
        if (error.message) {
          Notification(
            "error",
            `ไม่สามารถบันทึกได้เนื่องจากงานนี้ไม่ได้อยู่ในความรับผิดชอบของท่าน`
          );
        } else {
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถบันทึกได้เนื่องจากงานนี้ไม่ได้อยู่ในความรับผิดชอบของท่าน"
          );
        }
      }
    }
  );
}

export function* searchDataTask() {
  yield takeLatest(SEARCH_DATA_TASK, function* ({ search }) {
    try {
      const { data, next_page_token } = yield call(
        DataSubjectRightManagementService.SearchDataTask,
        search
      );
      yield put(
        searchDataTaskSuccess({
          listTasks: data,
          nextPageToken: next_page_token,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });
}
