import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  LIST_PRIVACY_ASSESSMENT_AUTOMATION,
  GET_PRIVACY_ASSESSMENT_AUTOMATION,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION,
} from "../../constants/PrivacyKitPrivacyAssessmentAutomation";
import {
  listPrivacyAssessmentAutomationSuccess,
  listPrivacyAssessmentAutomationFailure,
  getPrivacyAssessmentAutomationSuccess,
  getPrivacyAssessmentAutomationFailure,
  createPrivacyAssessmentAutomationSuccess,
  createPrivacyAssessmentAutomationFailure,
  updatePrivacyAssessmentAutomationSuccess,
  updatePrivacyAssessmentAutomationFailure,
  publishPrivacyAssessmentAutomationSuccess,
  publishPrivacyAssessmentAutomationFailure,
  deletePrivacyAssessmentAutomationSuccess,
  deletePrivacyAssessmentAutomationFailure,
} from "../../actions/PrivacyKitPrivacyAssessmentAutomation";
import PrivacyAssessmentAutomationService from "services/pdpakit/PrivacyAssessmentAutomationService";
import { selectPrivacyKitListDataMappingInventory } from "../../selectors/PrivacyKitDataMapping";
import {
  selectPrivacyKitPrivacyAssessmentAutomationData,
  selectPrivacyKitPrivacyAssessmentAutomationDeleteModal,
} from "../../selectors/PrivacyKitPrivacyAssessmentAutomation";
import _ from "lodash";
import Notification from "util/Notification";

export function* listPrivacyAssessmentAutomation() {
  yield takeEvery(LIST_PRIVACY_ASSESSMENT_AUTOMATION, function* () {
    try {
      const list = yield call(
        PrivacyAssessmentAutomationService.listAssessmentAutomation
      );
      yield put(
        listPrivacyAssessmentAutomationSuccess(list.data, list.next_page_token)
      );
    } catch (error) {
      yield put(listPrivacyAssessmentAutomationFailure(error));
    }
  });
}

export function* getPrivacyAssessmentAutomation() {
  yield takeEvery(GET_PRIVACY_ASSESSMENT_AUTOMATION, function* (action) {
    try {
      const response = yield call(
        PrivacyAssessmentAutomationService.getAssessmentAutomation,
        action.assessmentId
      );
      yield put(getPrivacyAssessmentAutomationSuccess(response.data));
    } catch (error) {
      yield put(getPrivacyAssessmentAutomationFailure(error));
    }
  });
}

export function* createPrivacyAssessmentAutomation() {
  yield takeEvery(CREATE_PRIVACY_ASSESSMENT_AUTOMATION, function* (action) {
    const dataMappingInventory = yield select(
      selectPrivacyKitListDataMappingInventory
    );
    try {
      const inventoryId = action.data.assessment_risk.inventory;
      const inventory = _.find(dataMappingInventory.data, {
        ObjectUUID: inventoryId,
      });
      const response = yield call(
        PrivacyAssessmentAutomationService.createAssessmentAutomation,
        {
          ...action.data,
          name: inventory.name,
          assessment_type: "privacy",
          assessment_template_type: "privacy",
          assessment_template_id: "privacy",
          approver_user: [],
          responder_user: [],
          dataMappingInventory: inventory,
        }
      );
      yield put(createPrivacyAssessmentAutomationSuccess(response.data));
      Notification("success", "สร้างแบบประเมินสำเร็จ");
    } catch (error) {
      if (error?.errorCode === "CreateNotAllowed") {
        Notification(
          "error",
          "เกิดข้อผิดพลาด ไม่สามารถสร้างแบบประเมินซ้ำกันได้"
        );
      }
      Notification("error", "เกิดข้อผิดพลาด ไม่สามารถสร้างแบบประเมินได้");
      yield put(createPrivacyAssessmentAutomationFailure(error));
    }
  });
}

export function* updatePrivacyAssessmentAutomation() {
  yield takeEvery(UPDATE_PRIVACY_ASSESSMENT_AUTOMATION, function* () {
    try {
      const data = yield select(
        selectPrivacyKitPrivacyAssessmentAutomationData
      );
      const response = yield call(
        PrivacyAssessmentAutomationService.updateAssessmentAutomation,
        data.ObjectUUID,
        data
      );
      yield put(updatePrivacyAssessmentAutomationSuccess(response.data));
      Notification("success", "แก้ไขแบบประเมินสำเร็จ");
    } catch (error) {
      Notification("error", "เกิดข้อผิดพลาด ไม่สามารถแก้ไขแบบประเมินได้");
      yield put(updatePrivacyAssessmentAutomationFailure(error));
    }
  });
}

export function* publishPrivacyAssessmentAutomation() {
  yield takeEvery(PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION, function* () {
    try {
      const data = yield select(
        selectPrivacyKitPrivacyAssessmentAutomationData
      );
      yield call(
        PrivacyAssessmentAutomationService.updateAssessmentAutomation,
        data.ObjectUUID,
        data
      );
      const response = yield call(
        PrivacyAssessmentAutomationService.publishAssessmentAutomation,
        data.ObjectUUID
      );
      yield put(publishPrivacyAssessmentAutomationSuccess(response.data));
      Notification("success", "เผยแพร่แบบประเมินสำเร็จ");
    } catch (error) {
      Notification("error", "เกิดข้อผิดพลาด ไม่สามารถเผยแพร่แบบประเมินได้");
      yield put(publishPrivacyAssessmentAutomationFailure(error));
    }
  });
}

export function* deletePrivacyAssessmentAutomation() {
  yield takeEvery(DELETE_PRIVACY_ASSESSMENT_AUTOMATION, function* () {
    try {
      const { data } = yield select(
        selectPrivacyKitPrivacyAssessmentAutomationDeleteModal
      );
      const assessmentId = data.ObjectUUID;
      yield call(
        PrivacyAssessmentAutomationService.deleteAssessmentAutomation,
        assessmentId
      );
      yield put(deletePrivacyAssessmentAutomationSuccess(assessmentId));
      Notification("success", "ลบแบบประเมินสำเร็จ");
    } catch (error) {
      Notification("error", "เกิดข้อผิดพลาด ไม่สามารถลบแบบประเมินได้");
      yield put(deletePrivacyAssessmentAutomationFailure(error));
    }
  });
}
