import {
  LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  TOGGLE_CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_MODAL,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  TOGGLE_PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  TOGGLE_DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  CLEAR_PRIVACY_ASSESSMENT_AUTOMATION_RISK
} from "../../constants/PrivacyKitPrivacyAssessmentAutomation";

export const listPrivacyAssessmentAutomationRisk = (assessmentId) => ({
  type: LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  assessmentId,
});

export const listPrivacyAssessmentAutomationRiskSuccess = (list) => ({
  type: LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  list,
});

export const listPrivacyAssessmentAutomationRiskFailure = (error) => ({
  type: LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  error,
});

export const toggleCreatePrivacyAssessmentAutomationRiskModal = () => ({
  type: TOGGLE_CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_MODAL,
});

export const createPrivacyAssessmentAutomationRisk = (data) => ({
  type: CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  data,
});

export const createPrivacyAssessmentAutomationRiskSuccess = (data) => ({
  type: CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  data,
});

export const createPrivacyAssessmentAutomationRiskFailure = (error) => ({
  type: CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  error,
});

export const getPrivacyAssessmentAutomationRisk = (riskId) => ({
  type: GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  riskId,
});

export const getPrivacyAssessmentAutomationRiskSuccess = (data) => ({
  type: GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  data,
});

export const getPrivacyAssessmentAutomationRiskFailure = (error) => ({
  type: GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  error,
});

export const updatePrivacyAssessmentAutomationRisk = (data) => ({
  type: UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  data,
});

export const updatePrivacyAssessmentAutomationRiskSuccess = (data) => ({
  type: UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  data,
});

export const updatePrivacyAssessmentAutomationRiskFailure = (error) => ({
  type: UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  error,
});

export const togglePublishPrivacyAssessmentAutomationRisk = () => ({
  type: TOGGLE_PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
});

export const publishPrivacyAssessmentAutomationRisk = (data) => ({
  type: PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  data,
});

export const publishPrivacyAssessmentAutomationRiskSuccess = (data) => ({
  type: PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  data,
});

export const publishPrivacyAssessmentAutomationRiskFailure = (error) => ({
  type: PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  error,
});

export const toggleDeletePrivacyAssessmentAutomationRisk = (data) => ({
  type: TOGGLE_DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  data,
});

export const deletePrivacyAssessmentAutomationRisk = (riskId) => ({
  type: DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  riskId,
});

export const deletePrivacyAssessmentAutomationRiskSuccess = (riskId) => ({
  type: DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  riskId,
});

export const deletePrivacyAssessmentAutomationRiskFailure = (error) => ({
  type: DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  error,
});

export const clearPrivacyAssessmentAutomationRisk = () => ({
  type: CLEAR_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
});
