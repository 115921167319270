export const selectPrivacyKitPrivacyAssessmentAutomation = (state) =>
  state.privacyKitPrivacyAssessmentAutomation.assessmentAutomation;

export const selectPrivacyKitPrivacyAssessmentAutomationData = (state) =>
  state.privacyKitPrivacyAssessmentAutomation.assessmentAutomation.data;

export const selectPrivacyKitPrivacyAssessmentAutomationDeleteModal = (state) =>
  state.privacyKitPrivacyAssessmentAutomation.assessmentAutomation.deleteModal;

export const selectPrivacyKitPrivacyRiskAssessmentAutomation = (state) =>
  state.privacyKitPrivacyAssessmentAutomation.riskAssessment;

export const selectPrivacyKitPrivacyRiskDataAssessmentAutomation = (state) =>
  state.privacyKitPrivacyAssessmentAutomation.riskAssessment.data;

export const selectPrivacyKitPrivacyDataMappingAssessmentAutomation = (state) =>
  state.privacyKitPrivacyAssessmentAutomation.dataMapping;

export const selectPrivacyKitDashboardPrivacyAssessmentAutomation = (state) =>
  state.privacyKitPrivacyAssessmentAutomation.dashboard;
