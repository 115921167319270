import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import {
  GET_TASK_MANAGEMENT_DETAIL,
  GET_JOBS,
  GET_COMMENTS,
  UPDATE_TASK_STATUS,
  GET_RESPONSE_PLAN_LIST,
  GET_RESPONSE_PLAN_BY_ID,
  GET_WORKFLOW_MODULE,
  ON_COMMENTS,
  GET_ACTIVITY,
  CREATE_WORKFLOW,
  UPDATE_WORKFLOW,
} from "redux/constants/CyberKitSoar";
import {
  setResponsePlan,
  setTaskManagementDetail,
  setJobs,
  setLoadingJob,
  setComments,
  setNextPageTokenComment,
  setCommentLoading,
  setActivity,
  setActivityLoading,
  setNextPageTokenActivity,
  setModuleResponsePlan,
  setResponsePlanDetail,
  setNode,
  setEdge,
} from "redux/actions/CyberKitSoar";
import { setGlobalLoading } from "redux/actions/CyberKitTool";
import {
  CyberKitSoarResponsePlanModel,
  CyberKitSoarTaskManagementModel,
} from "models";
import SoarServices from "services/cyberkit/SoarServices";

import convertJsonParamsToString from "util/convertJsonParamsToString";

// TASK MANAGEMENT
export function* getTaskMangementDetail() {
  yield takeEvery(GET_TASK_MANAGEMENT_DETAIL, function* ({ payload }) {
    const { status, data } = yield call(SoarServices.getRequestByID, payload);
    if (status === "OK") {
      yield put(
        setTaskManagementDetail(
          new CyberKitSoarTaskManagementModel({ ...data, id: data._id })
        )
      );
    }
  });
}

export function* getJobs() {
  yield takeEvery(GET_JOBS, function* ({ payload }) {
    yield put(setLoadingJob(true));
    const { status, data } = yield call(
      SoarServices.getJobByRequestID,
      payload
    );

    if (status === "OK") {
      yield put(setJobs(data));
      yield put(setLoadingJob(false));
    } else yield put(setLoadingJob(false));
  });
}

export function* getComments() {
  yield takeEvery(GET_COMMENTS, function* ({ payload }) {
    let params = ``;
    if (typeof payload.params !== "string") {
      const keyParamList = Object.keys(payload.params || {});
      if (keyParamList.length > 0)
        params = convertJsonParamsToString(payload.params);
    } else {
      params = payload.params;
    }

    const payloadService = {
      params: params,
    };

    yield put(setCommentLoading(true));
    const { status, data, link, meta } = yield call(
      SoarServices.getComments,
      payloadService
    );
    yield put(setCommentLoading(false));

    if (status === "OK") {
      const state = yield select();
      const oldComment =
        state.cyberKitSoar.taskManagement.taskInfo.comment.all.items;
      yield put(setComments([...data.reverse(), ...oldComment]));
      yield put(
        setNextPageTokenComment({
          next: link.next,
          totalPage: meta.totalPage,
          currentPage: meta.currentPage,
        })
      );
    }
  });
}

export function* onComment() {
  yield takeEvery(ON_COMMENTS, function* ({ payload }) {
    yield put(setCommentLoading(true));
    const { status } = yield call(SoarServices.createComment, payload);
    yield put(setCommentLoading(false));

    if (status === "OK") {
      const state = yield select();
      const oldComment =
        state.cyberKitSoar.taskManagement.taskInfo.comment.all.items;
      yield put(setComments([...oldComment, payload.data]));
    }
  });
}

export function* getActivity() {
  yield takeEvery(GET_ACTIVITY, function* ({ payload }) {
    let params = ``;
    const keyParamList = Object.keys(payload.params || {});
    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);

    const payloadService = {
      params: params,
    };

    yield put(setActivityLoading(true));
    const { status, data, link, meta } = yield call(
      SoarServices.getActivity,
      payloadService
    );
    yield put(setActivityLoading(false));

    if (status === "OK") {
      const state = yield select();
      const oldActivity =
        state.cyberKitSoar.taskManagement.taskInfo.activity.items;
      yield put(setActivity([...oldActivity, ...data]));
      yield put(
        setNextPageTokenActivity({
          next: link.next,
          totalPage: meta.totalPage,
          currentPage: meta.currentPage,
        })
      );
    }
  });
}

// TASK-ASSIGNMENT
export function* updateStatusTaskAssignment() {
  yield takeEvery(UPDATE_TASK_STATUS, function* ({ payload }) {
    yield put(setGlobalLoading(true));
    const { status } = yield call(
      SoarServices.updateStatusTaskAssignment,
      payload
    );

    if (status === "OK") {
      payload.next();
      yield put(setGlobalLoading(false));
    } else yield put(setGlobalLoading(false));
  });
}

// RESPONSE-PLANNING
export function* getResponsePlanList() {
  yield takeEvery(GET_RESPONSE_PLAN_LIST, function* ({ payload }) {
    let params = ``;
    const keyParamList = Object.keys(payload.params || {});
    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);

    const payloadService = {
      params: params,
    };

    const resp = yield call(SoarServices.getWorkFlowList, payloadService);

    const { status, data } = resp;

    if (status === "OK") {
      yield put(
        setResponsePlan(
          data.map(
            (v) => new CyberKitSoarResponsePlanModel({ ...v, id: v._id })
          )
        )
      );
    }
  });
}

export function* getResponsePlanningByID() {
  yield takeEvery(GET_RESPONSE_PLAN_BY_ID, function* ({ payload }) {
    const resp = yield call(SoarServices.getWorkFlowByID, payload);

    const { status, data } = resp;

    if (status === "OK") {
      let nds = [];
      let eds = [];
      data.stages.forEach((value, index) => {
        nds.push({
          id: index.toString(),
          type: "CustomNode",
          data: {
            ...value,
            ndID: index.toString(),
            firstIndex: index === 0 ? true : false,
          },
          position: { x: 0, y: 0 },
        });
      });

      nds.push({
        id: nds.length.toString(),
        type: "EmptyNode",
        data: { ndID: nds.length.toString() },
        position: { x: 0, y: 0 },
      });

      nds.forEach((value, index) => {
        if (index !== 0) {
          const sourceID = nds[index - 1].id.toString();
          const targetID = nds[index].id.toString();
          eds.push({
            id: `${sourceID}-${targetID}`,
            source: sourceID,
            target: targetID,
            type: "smoothstep",
            animated: true,
          });
        }
      });

      yield put(setEdge(eds));
      yield put(setNode(nds));
      yield put(setResponsePlanDetail(data));
    }
  });
}

export function* getWorkflowModules() {
  yield takeEvery(GET_WORKFLOW_MODULE, function* () {
    const resp = yield call(SoarServices.getModuleList);

    const { status, data } = resp;

    if (status === "OK") {
      yield put(setModuleResponsePlan(data));
    }
  });
}

export function* createWorkflow() {
  yield takeEvery(CREATE_WORKFLOW, function* ({ payload }) {
    yield put(setGlobalLoading(true));
    const state = yield select();
    const nds = state.cyberKitSoar.responsePlanning.workFlow.nodes;

    let stages = nds
      .filter((nd) => nd.type === "CustomNode")
      .map((nd) => {
        let ndData = nd.data;
        ndData.rules = ndData.rules.map((item) => {
          delete item.formType;
          return item;
        });
        return {
          name: ndData.name,
          type: ndData.type,
          status: ndData.status,
          rules: ndData.rules,
        };
      });
    payload.data.stages = stages;

    const { status } = yield call(SoarServices.createWorkFlow, payload);

    yield put(setGlobalLoading(false));
    if (status === "OK") payload.next();
  });
}

export function* updateWorkflow() {
  yield takeEvery(UPDATE_WORKFLOW, function* ({ payload }) {
    yield put(setGlobalLoading(true));
    const state = yield select();
    const detailWorkflow = state.cyberKitSoar.responsePlanning.workFlow.detail;
    const nds = state.cyberKitSoar.responsePlanning.workFlow.nodes;
    let stages = nds
      .filter((nd) => nd.type === "CustomNode")
      .map((nd) => {
        let ndData = nd.data;
        ndData.rules = ndData.rules.map((item) => {
          delete item.formType;
          return item;
        });
        return {
          name: ndData.name,
          type: ndData.type,
          status: ndData.status,
          rules: ndData.rules,
        };
      });

    payload.data.object_type = detailWorkflow.object_type;
    // payload.data.organization = detailWorkflow.organization;
    payload.data.module = detailWorkflow.module;
    payload.data.version = detailWorkflow.version;
    payload.data.status = detailWorkflow.status;
    payload.data.stages = stages;

    payload.path = { id: detailWorkflow._id };
    const { status } = yield call(SoarServices.updateWorkFlow, payload);
    yield put(setGlobalLoading(false));
    if (status === "OK") payload.next();
  });
}

export default function* rootSaga() {
  yield all([
    fork(getTaskMangementDetail),
    fork(getJobs),
    fork(getComments),
    fork(onComment),
    fork(getActivity),
    fork(updateStatusTaskAssignment),
    fork(getResponsePlanList),
    fork(getResponsePlanningByID),
    fork(getWorkflowModules),
    fork(createWorkflow),
    fork(updateWorkflow),
  ]);
}
