import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import UserManagementService from "services/UserManagementService";
import {
  LIST_USER_MANAGEMENT_ORGANIZATION,
  LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL,
  LIST_USER_MANAGEMENT_USER,
  LIST_USER_MANAGEMENT_USER_DETAIL,
  LIST_USER_MANAGEMENT_ROLES,
  LIST_USER_MANAGEMENT_ROLES_DETAIL,
  LIST_USER_MANAGEMENT_ROLES_PERMISSIONS,
  LIST_USER_MANAGEMENT_USER_GROUP,
  ADD_USER_MANAGEMENT_USER,
  LIST_USER_MANAGEMENT_USER_IN_GROUP,
  LIST_USER_MANAGEMENT_ROLE_IN_GROUP,
  LIST_TENANT_USER_MANAGEMENT_USER,
} from "../constants/UserManagement";
import {
  listUserManagementOrganizationSuccess,
  listUserManagementOrganizationFailed,
  listUserManagementOrganizationDetailSuccess,
  listUserManagementOrganizationDetailFailed,
  listUserManagementUserSuccess,
  listUserManagementUserFailed,
  addUserManagementUserSuccess,
  addUserManagementUserFailed,
  listUserManagementUserDetailSuccess,
  listUserManagementUserDetailFailed,
  listUserManagementRolesSuccess,
  listUserManagementRolesFailed,
  listUserManagementRolesDetailSuccess,
  listUserManagementRolesDetailFailed,
  listUserManagementRolesPermissionsSuccess,
  listUserManagementRolesPermissionsFailed,
  listUserManagementUserGroupSuccess,
  listUserManagementUserGroupFailed,
  listUserManagementUserInGroupSuccess,
  listUserManagementUserInGroupFailed,
  listUserManagementRoleInGroupSuccess,
  listUserManagementRoleInGroupFailed,
  listTenantUserManagementUserSuccess,
  listTenantUserManagementUserFailed,
} from "../actions/UserManagement";

function* listUserManagementOrganization() {
  yield takeEvery(
    LIST_USER_MANAGEMENT_ORGANIZATION,
    function* ({ payload, onFinish = () => {}, onFailed = () => {} }) {
      try {
        const response = yield call(
          UserManagementService.listOrganization,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listUserManagementOrganizationSuccess(response));
        } else {
          yield put(listUserManagementOrganizationFailed(response));
        }
        onFinish();
      } catch (error) {
        yield put(listUserManagementOrganizationFailed(error));
        onFailed(error);
      }
    }
  );
}

function* listUserManagementOrganizationDetail() {
  yield takeEvery(
    LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL,
    function* ({ payload, onFinish = () => {}, onFailed = () => {} }) {
      try {
        const response = yield call(
          UserManagementService.listOrganizationDetail,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listUserManagementOrganizationDetailSuccess(response));
        } else {
          yield put(listUserManagementOrganizationDetailFailed(response));
        }
        onFinish();
      } catch (error) {
        yield put(listUserManagementOrganizationDetailFailed(error));
        onFailed(error);
      }
    }
  );
}

function* listUserManagementUser() {
  yield takeEvery(
    LIST_USER_MANAGEMENT_USER,
    function* ({ payload, onFinish = () => {}, onFailed = () => {} }) {
      try {
        const response = yield call(UserManagementService.listUser, payload);
        if (response.statusCode === 200) {
          yield put(listUserManagementUserSuccess(response));
        } else {
          yield put(listUserManagementUserFailed(response));
        }
        onFinish();
      } catch (error) {
        yield put(listUserManagementUserFailed(error));
        onFailed(error);
      }
    }
  );
}

function* listUserManagementUserDetail() {
  yield takeEvery(
    LIST_USER_MANAGEMENT_USER_DETAIL,
    function* ({ payload, onFinish = () => {}, onFailed = () => {} }) {
      try {
        const response = yield call(
          UserManagementService.listUserDetail,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listUserManagementUserDetailSuccess(response));
        } else {
          yield put(listUserManagementUserDetailFailed(response));
        }
        onFinish();
      } catch (error) {
        yield put(listUserManagementUserDetailFailed(error));
        onFailed(error);
      }
    }
  );
}

function* addUserManagementUser() {
  yield takeEvery(ADD_USER_MANAGEMENT_USER, function* ({ payload }) {
    try {
      const response = yield call(UserManagementService.addUser, payload);
      if (response.statusCode === 200) {
        yield put(addUserManagementUserSuccess(response));
      } else {
        yield put(addUserManagementUserFailed(response));
      }
    } catch (error) {
      yield put(addUserManagementUserFailed(error));
    }
  });
}

export function* listUserManagementRoles() {
  yield takeEvery(
    LIST_USER_MANAGEMENT_ROLES,
    function* ({ payload, onFinish = () => {}, onFailed = () => {} }) {
      try {
        const response = yield call(UserManagementService.listRoles, payload);
        if (response.statusCode === 200) {
          yield put(listUserManagementRolesSuccess(response));
        } else {
          yield put(listUserManagementRolesFailed(response));
        }
        onFinish();
      } catch (error) {
        onFailed(error);
        yield put(listUserManagementRolesFailed(error));
      }
    }
  );
}

export function* listUserManagementRolesDetail() {
  yield takeEvery(
    LIST_USER_MANAGEMENT_ROLES_DETAIL,
    function* ({ payload, onFinish = () => {}, onFailed = () => {} }) {
      try {
        const response = yield call(
          UserManagementService.rolePermissionsDetail,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listUserManagementRolesDetailSuccess(response));
        } else {
          yield put(listUserManagementRolesDetailFailed(response));
        }
        onFinish();
      } catch (error) {
        onFailed(error);
        yield put(listUserManagementRolesDetailFailed(error));
      }
    }
  );
}

export function* listUserManagementRolesPermissions() {
  yield takeEvery(
    LIST_USER_MANAGEMENT_ROLES_PERMISSIONS,
    function* ({ payload, onFinish = () => {}, onFailed = () => {} }) {
      try {
        const response = yield call(
          UserManagementService.rolePermissions,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listUserManagementRolesPermissionsSuccess(response));
        } else {
          yield put(listUserManagementRolesPermissionsFailed(response));
        }
        onFinish();
      } catch (error) {
        onFailed(error);
        yield put(listUserManagementRolesPermissionsFailed(error));
      }
    }
  );
}

export function* listUserManagementUserGroup() {
  yield takeEvery(
    LIST_USER_MANAGEMENT_USER_GROUP,
    function* ({ payload, onFinish = () => {}, onFailed = () => {} }) {
      try {
        const response = yield call(
          UserManagementService.listUserGroup,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listUserManagementUserGroupSuccess(response));
        } else {
          yield put(listUserManagementUserGroupFailed(response));
        }
        onFinish();
      } catch (error) {
        onFailed(error);
        yield put(listUserManagementUserGroupFailed(error));
      }
    }
  );
}

export function* listUserManagementUserInGroup() {
  yield takeEvery(
    LIST_USER_MANAGEMENT_USER_IN_GROUP,
    function* ({ payload, onFinish = () => {}, onFailed = () => {} }) {
      const { groupuuid } = payload;
      try {
        const response = yield call(
          UserManagementService.listUserInGroup,
          groupuuid
        );
        if (response.statusCode === 200) {
          yield put(listUserManagementUserInGroupSuccess(response));
        } else {
          yield put(listUserManagementUserInGroupFailed(response));
        }
        onFinish();
      } catch (error) {
        onFailed(error);
        yield put(listUserManagementUserInGroupFailed(error));
      }
    }
  );
}

export function* listUserManagementRoleInGroup() {
  yield takeEvery(
    LIST_USER_MANAGEMENT_ROLE_IN_GROUP,
    function* ({ payload, onFinish = () => {}, onFailed = () => {} }) {
      const { groupuuid } = payload;
      try {
        const response = yield call(
          UserManagementService.listRoleInGroup,
          groupuuid
        );
        if (response.statusCode === 200) {
          yield put(listUserManagementRoleInGroupSuccess(response));
        } else {
          yield put(listUserManagementRoleInGroupFailed(response));
        }
        onFinish();
      } catch (error) {
        onFailed(error);
        yield put(listUserManagementRoleInGroupFailed(error));
      }
    }
  );
}

export function* listTenantUserManagement() {
  yield takeEvery(LIST_TENANT_USER_MANAGEMENT_USER, function* () {
    try {
      const { master_org } = yield call(
        UserManagementService.getOrganizationTenant
      );
      const response = yield call(
        UserManagementService.listTenantUser,
        master_org?.UUID
      );
      yield put(listTenantUserManagementUserSuccess(response));
    } catch (error) {
      yield put(listTenantUserManagementUserFailed(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(listUserManagementOrganization),
    fork(listUserManagementOrganizationDetail),
    fork(listUserManagementUser),
    fork(addUserManagementUser),
    fork(listUserManagementUserDetail),
    fork(listUserManagementRoles),
    fork(listUserManagementRolesDetail),
    fork(listUserManagementRolesPermissions),
    fork(listUserManagementUserGroup),
    fork(listUserManagementUserInGroup),
    fork(listUserManagementRoleInGroup),
    fork(listTenantUserManagement),
  ]);
}
