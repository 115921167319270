import {
  GET_CONSENT_MANAGEMENT_DASHBOARD,
  GET_CONSENT_MANAGEMENT_DASHBOARD_SUCCESS,
  CHANGE_CONSENT_MANAGEMENT_DASHBOARD_FILTER,
  GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT,
  GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT_SUCCESS,
  GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT_FAILURE,
  GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE,
  GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE_SUCCESS,
} from "../../constants/PrivacyKitConsentManagement";

export const getConsentManagementDashboard = (startDate, endDate) => ({
  type: GET_CONSENT_MANAGEMENT_DASHBOARD,
  startDate,
  endDate,
});

export const getConsentManagementDashboardSuccess = (data) => ({
  type: GET_CONSENT_MANAGEMENT_DASHBOARD_SUCCESS,
  data,
});

export const changeConsentManagementDashboardFilter = (filter) => ({
  type: CHANGE_CONSENT_MANAGEMENT_DASHBOARD_FILTER,
  filter,
});

export const getNextPageConsentManagementDashboardReceipt = (
  nextPageToken
) => ({
  type: GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT,
  nextPageToken,
});

export const getNextPageConsentManagementDashboardReceiptSuccess = (
  receipt
) => ({
  type: GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT_SUCCESS,
  receipt,
});

export const getNextPageConsentManagementDashboardReceiptFailure = () => ({
  type: GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT_FAILURE,
});

export const getNextConsentManagementDashboardProcessingPurpose = (
  currentPage
) => ({
  type: GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE,
  currentPage,
});

export const getNextConsentManagementDashboardProcessingPurposeSuccess = (
  payload
) => ({
  type: GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE_SUCCESS,
  payload,
});
