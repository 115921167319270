import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  LIST_INCIDENT_RESPONSE_EVENT_STAGE,
  CREATE_INCIDENT_RESPONSE_EVENT_STAGE,
  DELETE_INCIDENT_RESPONSE_EVENT_STAGE,
  LIST_INCIDENT_RESPONSE_EVENT_TYPE,
  CREATE_INCIDENT_RESPONSE_EVENT_TYPE,
  DELETE_INCIDENT_RESPONSE_EVENT_TYPE,
  LIST_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT,
  CREATE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT,
  DELETE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT,
  UPDATE_INCIDENT_RESPONSE_EVENT_STAGE,
  GET_INCIDENT_RESPONSE_EVENT_STAGE,
} from "../constants/PrivacyKitIncidentResponseManagement";
import {
  listIncidentResponseEventStageSuccess,
  createIncidentResponseEventStageSuccess,
  deleteIncidentResponseEventStageSuccess,
  listIncidentResponseEventTypeSuccess,
  createIncidentResponseEventTypeSuccess,
  deleteIncidentResponseEventTypeSuccess,
  listIncidentResponseAttributeSuccess,
  createIncidentResponseAttributeSuccess,
  deleteIncidentResponseAttributeSuccess,
  updateIncidentResponseEventStageSuccess,
  getIncidentResponseEventStageSuccess,
} from "../actions/PrivacyKitIncidentResponseManagement";
import IncidentResponseManagementServices from "services/pdpakit/IncidentResponseManagementServices";

function* listIncidentResponseManagementEventStage() {
  yield takeEvery(LIST_INCIDENT_RESPONSE_EVENT_STAGE, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.listIncidentResponseEventStage,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listIncidentResponseEventStageSuccess(response));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

function* getIncidentResponseManagementEventStage() {
  yield takeEvery(GET_INCIDENT_RESPONSE_EVENT_STAGE, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.getIncidentResponseEventStageById,
        payload
      );
      if (response.statusCode === 200) {
        yield put(getIncidentResponseEventStageSuccess(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

function* createIncidentResponseManagementEventStage() {
  yield takeEvery(
    CREATE_INCIDENT_RESPONSE_EVENT_STAGE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.createIncidentResponseEventStage,
          payload
        );

        if (response.statusCode === 200 && response.data) {
          yield put(createIncidentResponseEventStageSuccess(response.data));
          onFinish();
        } else {
          onFailed(response);
        }
      } catch (error) {
        console.log(error);
        onFailed(error);
      }
    }
  );
}

function* updateIncidentResponseManagementEventStage() {
  yield takeEvery(
    UPDATE_INCIDENT_RESPONSE_EVENT_STAGE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.updateIncidentResponseEventStage,
          payload
        );
        const { statusCode = "" } = response;
        if (statusCode === 200) {
          yield put(updateIncidentResponseEventStageSuccess(response.data));
          onFinish(response.data);
        } else {
          onFailed(response);
        }
      } catch (err) {
          console.log(err)
        onFailed(err);
      }
    }
  );
}

function* deleteIncidentResponseManagementEventStage() {
  yield takeEvery(
    DELETE_INCIDENT_RESPONSE_EVENT_STAGE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.deleteIncidentResponseEventStage,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteIncidentResponseEventStageSuccess(payload));
          onFinish();
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* listIncidentResponseManagementEventType() {
  yield takeEvery(LIST_INCIDENT_RESPONSE_EVENT_TYPE, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.listIncidentResponseEventType,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listIncidentResponseEventTypeSuccess(response));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

function* createIncidentResponseManagementEventType() {
  yield takeEvery(
    CREATE_INCIDENT_RESPONSE_EVENT_TYPE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.createIncidentResponseEventType,
          payload
        );

        if (response.statusCode === 200 && response.data) {
          yield put(createIncidentResponseEventTypeSuccess(response.data));
          onFinish();
        } else {
          onFailed(response);
        }
      } catch (error) {
        console.log(error);
        onFailed(error);
      }
    }
  );
}

function* deleteIncidentResponseManagementEventType() {
  yield takeEvery(
    DELETE_INCIDENT_RESPONSE_EVENT_TYPE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.deleteIncidentResponseEventType,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteIncidentResponseEventTypeSuccess(payload));
          onFinish();
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* listIncidentResponseManagementAttribute() {
  yield takeEvery(
    LIST_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT,
    function* ({ payload }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.listIncidentResponseAttribute,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listIncidentResponseAttributeSuccess(response));
        }
      } catch (err) {
        console.log(err);
      }
    }
  );
}

function* createIncidentResponseManagementAttribute() {
  yield takeEvery(
    CREATE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.createIncidentResponseAttribute,
          payload
        );

        if (response.statusCode === 200 && response.data) {
          yield put(createIncidentResponseAttributeSuccess(response.data));
          onFinish();
        } else {
          onFailed(response);
        }
      } catch (error) {
        console.log(error);
        onFailed(error);
      }
    }
  );
}

function* deleteIncidentResponseManagementAttribute() {
  yield takeEvery(
    DELETE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.deleteIncidentResponseAttribute,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteIncidentResponseAttributeSuccess(payload));
          onFinish();
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(listIncidentResponseManagementEventStage),
    fork(getIncidentResponseManagementEventStage),
    fork(createIncidentResponseManagementEventStage),
    fork(updateIncidentResponseManagementEventStage),
    fork(deleteIncidentResponseManagementEventStage),
    fork(listIncidentResponseManagementEventType),
    fork(createIncidentResponseManagementEventType),
    fork(deleteIncidentResponseManagementEventType),
    fork(listIncidentResponseManagementAttribute),
    fork(createIncidentResponseManagementAttribute),
    fork(deleteIncidentResponseManagementAttribute),
  ]);
}
