import { env } from "./EnvironmentConfig";

// config api endpoints
export const ASSESSMENT_AUTOMATION_BASE_URL =
  env.API_ENDPOINT_ASSESSMENT_AUTOMATION;
export const AUTH_BASE_URL = env.API_ENDPOINT_BASE_URL;
export const CENTRAL_BASE_URL = env.API_ENDPOINT_CENTRAL_URL;
export const PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT_BASE_URL =
  env.API_ENDPOINT_PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT;
export const PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT_SAM_BASE_URL =
  env.API_ENDPOINT_PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT_SAM;
export const PRIVACYKIT_DATA_SUBJECT_RIGHT_MANAGEMENT_BASE_URL =
  env.API_ENDPOINT_PRIVACYKIT_DATA_SUBJECT_RIGHT_MANAGEMENT;
export const PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL =
  env.API_ENDPOINT_PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE;
export const PRIVACYKIT_INCIDENT_RESPONSE_MANAGEMENT_BASE_URL = 
  env.API_ENDPOINT_PRIVACYKIT_INCIDENT_RESPONSE_MANAGEMENT; 
export const PRIVACYKIT_ASSESSMENT_AUTOMATION_BASE_URL =
  env.API_ENDPOINT_PRIVACYKIT_ASSESSMENT_AUTOMATION;
export const PRIVACYKIT_DATA_CLASSIFICATION_AND_DISCOVERY_BASE_URL =
  env.API_ENDPOINT_PRIVACYKIT_DATA_CLASSIFICATION_AND_DISCOVERY;

export const PRIVACYKIT_DATA_MAPPING_BASE_URL =
  env.API_ENDPOINT_PRIVACYKIT_DATA_MAPPING;
export const PRIVACYKIT_POLICY_AND_NOTICE_MANAGEMENT_BASE_URL =
  env.API_ENDPOINT_PRIVACYKIT_POLICY_AND_NOTICE_MANAGEMENT;

export const CYBER_KIT_BASE_URL = env.API_ENDPOINT_CYBER_KIT;
export const WORK_FLOW_BASE_URL = env.API_ENDPOINT_CORE_WORKFLOW;
