import { PrivacyKitPath, PrivacyKitDataMappingPath } from "../AppModuleConfig";

const PrivacyKitPolicyAndNoticeManagement = [
  {
    key: `privacykit-data-mapping-inventory-data-warehouse`,
    path: `${PrivacyKitPath}${PrivacyKitDataMappingPath}/data-warehouse`,
    title: "คลังข้อมูล",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  // {
  //   key: `privacykit-data-mapping-inventory-related-control-measures`,
  //   path: `${PrivacyKitPath}${PrivacyKitDataMappingPath}/related-control-measures`,
  //   title: "มาตรการควบคุมที่เกี่ยวข้อง",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
  {
    key: `privacykit-data-mapping-inventory`,
    path: `${PrivacyKitPath}${PrivacyKitDataMappingPath}/inventory`,
    title: "ชุดข้อมูล",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `privacykit-data-mapping-inventory-legal-entity`,
    path: `${PrivacyKitPath}${PrivacyKitDataMappingPath}/legal-entity`,
    title: "จัดการข้อมูลหน่วยงาน",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `privacykit-data-mapping-inventory-data-stream`,
    path: `${PrivacyKitPath}${PrivacyKitDataMappingPath}/data-stream`,
    title: "กระแสข้อมูล",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  // {
  //   key: `privacykit-data-mapping-dashboard`,
  //   path: `${PrivacyKitPath}${PrivacyKitDataMappingPath}/dashboard`,
  //   title: "แดชบอร์ด",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
];

export default PrivacyKitPolicyAndNoticeManagement;
