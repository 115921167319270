import {
  LIST_CONSENT_MANAGEMENT_DATE_ELEMENT,
  LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS,
  LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_TYPE,
  LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_TYPE_SUCCESS,
  GET_CONSENT_MANAGEMENT_DATE_ELEMENT,
  GET_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS,
  SET_MANAGE_CONSENT_MANAGEMENT_DATE_ELEMENT,
  CREATE_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS,
  UPDATE_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS,
  CREATE_OR_UPDATE_CONSENT_MANAGEMENT_DATE_ELEMENT_ERROR,
  TOGGLE_MANAGE_CONSENT_MANAGEMENT_DATA_ELEMENT,
  TOGGLE_DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT,
  DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT,
  DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT_SUCCESS,
  DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT_ERROR,
  SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT,
  SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT_SUCCESS,
} from "../../constants/PrivacyKitConsentManagement";

export const listConsentManagementDataElement = () => ({
  type: LIST_CONSENT_MANAGEMENT_DATE_ELEMENT,
});

export const listConsentManagementDataElementSuccess = (data) => ({
  type: LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS,
  data,
});

export const listConsentManagementDataElementType = () => ({
  type: LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_TYPE,
});

export const listConsentManagementDataElementTypeSuccess = (data) => ({
  type: LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_TYPE_SUCCESS,
  data,
});

export const getConsentManagementDataElement = (dataElementId) => ({
  type: GET_CONSENT_MANAGEMENT_DATE_ELEMENT,
  dataElementId,
});

export const getConsentManagementDataElementSuccess = (data) => ({
  type: GET_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS,
  data,
});

export const setManageConsentManagementDataElement = (values) => ({
  type: SET_MANAGE_CONSENT_MANAGEMENT_DATE_ELEMENT,
  values,
});

export const createConsentManagementDataElementSuccess = (data) => ({
  type: CREATE_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS,
  data,
});

export const updateConsentManagementDataElementSuccess = (
  dataElementId,
  data
) => ({
  type: UPDATE_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS,
  dataElementId,
  data,
});

export const createOrUpdateConsentManagementDataElementError = () => ({
  type: CREATE_OR_UPDATE_CONSENT_MANAGEMENT_DATE_ELEMENT_ERROR,
});

export const toggleConsentManagementDataElement = (dataElementId) => ({
  type: TOGGLE_MANAGE_CONSENT_MANAGEMENT_DATA_ELEMENT,
  dataElementId,
});

export const toggleDeleteConsentManagementDataElement = (payload) => ({
  type: TOGGLE_DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT,
  payload,
});

export const deleteConsentManagementDataElement = (dataElementId) => ({
  type: DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT,
  dataElementId,
});

export const deleteConsentManagementDataElementSuccess = (dataElementId) => ({
  type: DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT_SUCCESS,
  dataElementId,
});

export const deleteConsentManagementDataElementError = (payload) => ({
  type: DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT_ERROR,
  payload,
});

export const SearchConsentManagementDataElement = (search) => ({
  type: SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT,
  search,
});

export const SearchConsentManagementDataElementSuccess = (payload) => ({
  type: SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT_SUCCESS,
  payload,
});
