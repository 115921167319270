import fetch from "util/Api";
import { PRIVACYKIT_INCIDENT_RESPONSE_MANAGEMENT_BASE_URL as endpoint } from "config/EndpointConfig";
import { PRIVACYKIT_DATA_SUBJECT_RIGHT_MANAGEMENT_BASE_URL as endpoint_webform } from "config/EndpointConfig";
import { PRIVACYKIT_ASSESSMENT_AUTOMATION_BASE_URL as endpoint_assessment } from "config/EndpointConfig";

const IncidentResponseManagementServices = {};

// INCIDENR RESPONSE
IncidentResponseManagementServices.listIncidentResponseEventStage = async () =>
  await fetch({
    url: `${endpoint}/incident/stage`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.getIncidentResponseEventStageById = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/stage/${data.ObjectUUID}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.createIncidentResponseEventStage = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/stage`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.updateIncidentResponseEventStage = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/stage/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.deleteIncidentResponseEventStage = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/stage/${data.ObjectUUID}`,
    method: "delete",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.listIncidentResponseEventType = async () =>
  await fetch({
    url: `${endpoint}/incident/group`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.getIncidentResponseEventTypeById = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/group/${data.ObjectUUID}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.createIncidentResponseEventType = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/group`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.updateIncidentResponseEventType = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/group/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.deleteIncidentResponseEventType = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/group/${data.ObjectUUID}`,
    method: "delete",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.listIncidentResponseAttribute = async () =>
  await fetch({
    url: `${endpoint}/incident/attrbute`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.getIncidentResponseAttributeById = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/attrbute/${data.ObjectUUID}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.createIncidentResponseAttribute = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/attrbute`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.updateIncidentResponseAttribute = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/attrbute/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.deleteIncidentResponseAttribute = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/attrbute/${data.ObjectUUID}`,
    method: "delete",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

// NOTIFICATION
IncidentResponseManagementServices.listIncidentResponseNotification =
  async () =>
    await fetch({
      url: `${endpoint}/incident/notification`,
      method: "get",
    })
      .then((response) => response)
      .catch((error) => error);

IncidentResponseManagementServices.getIncidentResponseNotification = async (
  ObjectUUID
) =>
  await fetch({
    url: `${endpoint}/incident/notification/${ObjectUUID}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.createIncidentResponseNotification = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/notification`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.updateDetailIncidentResponseNotification =
  async (data) =>
    await fetch({
      url: `${endpoint}/incident/notification/${data.ObjectUUID}`,
      method: "put",
      data,
    })
      .then((response) => response)
      .catch((error) => error);

IncidentResponseManagementServices.updateStatusIncidentResponseNotification =
  async (data) =>
    await fetch({
      url: `${endpoint}/incident/notification/${data.ObjectUUID}/status`,
      method: "put",
      data,
    })
      .then((response) => response)
      .catch((error) => error);

IncidentResponseManagementServices.deleteIncidentResponseNotification = async (
  ObjectUUID
) =>
  await fetch({
    url: `${endpoint}/incident/notification/${ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

// RESPONE PLAN
IncidentResponseManagementServices.listResponsePlan = async () =>
  await fetch({
    url: `${endpoint}/incident/stage`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.getResponsePlan = async (ObjectUUID) =>
  await fetch({
    url: `${endpoint}/incident/stage/${ObjectUUID}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.createResponsePlan = async (data) =>
  await fetch({
    url: `${endpoint}/incident/stage`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.updateResponsePlan = async (data) =>
  await fetch({
    url: `${endpoint}/incident/stage/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.publishResponsePlan = async (data) =>
  await fetch({
    url: `${endpoint}/incident/stage/${data.ObjectUUID}/publish`,
    method: "post",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.deleteResponsePlan = async (data) =>
  await fetch({
    url: `${endpoint}/incident/stage/${data.ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.listTaskInResponsePlan = async (stageId) =>
  await fetch({
    url: `${endpoint}/incident/stage/${stageId}/task`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.createTaskInResponsePlan = async ({
  data,
  stageId,
}) =>
  await fetch({
    url: `${endpoint}/incident/stage/${stageId}/task`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.getDetailTaskInResponsePlan = async ({
  stageId,
  ObjectUUID,
}) =>
  await fetch({
    url: `${endpoint}/incident/stage/${stageId}/task/${ObjectUUID}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.updateTaskInResponsePlan = async ({
  stageId,
  data,
}) =>
  await fetch({
    url: `${endpoint}/incident/stage/${stageId}/task/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.deleteTaskInResponsePlan = async ({
  stageId,
  data,
}) =>
  await fetch({
    url: `${endpoint}/incident/stage/${stageId}/task/${data.ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

// WEBFORM
IncidentResponseManagementServices.listWebform = async () =>
  await fetch({
    url: `${endpoint}/incident/webform`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.getWebform = async (ObjectUUID) =>
  await fetch({
    url: `${endpoint}/incident/webform/${ObjectUUID}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.createWebform = async (data) =>
  await fetch({
    url: `${endpoint}/incident/webform`,
    method: "post",
    data,
  });

IncidentResponseManagementServices.updateWebform = async (data) =>
  await fetch({
    url: `${endpoint}/incident/webform/${data.ObjectUUID}`,
    method: "put",
    data,
  });

IncidentResponseManagementServices.publishWebform = async (data) =>
  await fetch({
    url: `${endpoint}/incident/webform/${data.ObjectUUID}/publish`,
    method: "put",
  });

IncidentResponseManagementServices.deleteWebform = async (data) =>
  await fetch({
    url: `${endpoint}/incident/webform/${data.ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.listWebformVersion = async (webformId) =>
  await fetch({
    url: `${endpoint}/incident/webform/${webformId}/list_version`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.getWebformVersion = async ({
  webformId,
  versionId,
}) =>
  await fetch({
    url: `${endpoint}/incident/webform/${webformId}/${versionId}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.getWebFormFields = async (type) =>
  await fetch({
    url: `${endpoint_webform}/webform/field_lists/${type}`,
  });

// EVENT REGISTER
IncidentResponseManagementServices.listIncidentResponseCase = async () =>
  await fetch({
    url: `${endpoint}/incident/case`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.createIncidentResponseCase = async (data) =>
  await fetch({
    url: `${endpoint}/incident/case`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.getDetailIncidentResponseCase = async (
  ObjectUUID
) =>
  await fetch({
    url: `${endpoint}/incident/case/${ObjectUUID}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.updateDetailIncidentResponseCase = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/case/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.publishDetailIncidentResponseCase = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/case/${data.ObjectUUID}/publish`,
    method: "post",
  });

IncidentResponseManagementServices.updateIncidentResponseCaseStage = async (
  data
) =>
  await fetch({
    url: `${endpoint}/incident/case/${data.ObjectUUID}`,
    method: "put",
    data,
  });

IncidentResponseManagementServices.deleteDetailIncidentResponseCase = async (
  caseId
) =>
  await fetch({
    url: `${endpoint}/incident/case/${caseId}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.listTaskInCase = async (caseId) =>
  await fetch({
    url: `${endpoint}/incident/case/${caseId}/task`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.createTaskInCase = async ({
  data,
  caseId,
}) =>
  await fetch({
    url: `${endpoint}/incident/case/${caseId}/task`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.getDetailTaskInCase = async ({
  caseId,
  ObjectUUID,
}) =>
  await fetch({
    url: `${endpoint}/incident/case/${caseId}/task/${ObjectUUID}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.updateTaskInCase = async ({
  caseId,
  data,
}) =>
  await fetch({
    url: `${endpoint}/incident/case/${caseId}/task/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.deleteTaskInCase = async ({
  caseId,
  data,
}) =>
  await fetch({
    url: `${endpoint}/incident/case/${caseId}/task/${data.ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.listComment = async (caseId) =>
  await fetch({
    url: `${endpoint}/incident/case/${caseId}/comment`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.createComment = async ({ data, caseId }) =>
  await fetch({
    url: `${endpoint}/incident/case/${caseId}/comment`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.listHistory = async (caseId) =>
  await fetch({
    url: `${endpoint}/incident/case/${caseId}/log`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

// REPORT
IncidentResponseManagementServices.listCaseHigh = async () =>
  await fetch({
    url: `${endpoint}/incident/case?risk_level=HIGH`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.sentCaseHigh = async (data) =>
  await fetch({
    url: `${endpoint}/incident/case/${data.ObjectUUID}/send_mail`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

IncidentResponseManagementServices.getTemplateData = async ({
  template_type,
  template_id,
}) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/template/incident-privacykit/${template_type}/${template_id}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

// TASK
IncidentResponseManagementServices.listTaskAll = async () =>
  await fetch({
    url: `${endpoint}/incident/case/task`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

export default IncidentResponseManagementServices;
