import { saveToLS, getFromLS } from "util/LocalStorage";
import {
  // DASHBOARD
  SET_ST_DASHBOARD,
  SET_DY_DASHBOARD,

  // SOURCE
  SET_SOURCE_LIST,
  SET_SOURCE_SIEM_LIST,

  // AUDIT
  SET_LOG_AUDIT_LOG,
  SET_LOG_SEARCH_LIST,
  SET_LOG_SIEM_FIELDS,

  // EXPLORER
  SET_DATE_FOLDER,
  SET_HOUR_FOLDER,
  SET_EXPLORER,
  SET_ARCHIVES,
} from "redux/constants/CyberKitLogManagement";

const CYBERKIT = "CYBERKIT";
const CYBERKIT_LOG_MANAGEMENT = "CYBERKIT_LOG_MANAGEMENT";

const initState = {
  dashboard: {
    st: {
      sourceNumber: 0,
      topEvent: [],
      flowOverview: {
        perMonth: 0,
        perDay: 0,
      },
      flowSource: {
        perMonth: [],
        perDay: [],
      },
    },
    dy: {
      flowRange: [],
    },
  },
  source: {
    sourceList: [],
    sourceSiemList: [],
  },
  audit: {
    dataList: [],
  },
  search: {
    result: [],
    siemFields: [],
  },
  explorer: {
    dateFolder: [],
    hoursFolder: [],
    explorers: [],
    archives: [],
  },
};

const CyberKit = (
  state = getFromLS(CYBERKIT, CYBERKIT_LOG_MANAGEMENT)
    ? getFromLS(CYBERKIT, CYBERKIT_LOG_MANAGEMENT)
    : initState,
  action
) => {
  const { type, payload } = action;
  let changed = state;

  switch (type) {
    // DASHBOARD
    case SET_ST_DASHBOARD: {
      changed = {
        ...state,
        dashboard: {
          ...state.dashboard,
          st: payload,
        },
      };
      break;
    }

    case SET_DY_DASHBOARD: {
      changed = {
        ...state,
        dashboard: {
          ...state.dashboard,
          dy: payload,
        },
      };
      break;
    }

    // SOURCE
    case SET_SOURCE_LIST: {
      changed = {
        ...state,
        source: {
          ...state.source,
          sourceList: payload,
        },
      };
      break;
    }

    case SET_SOURCE_SIEM_LIST: {
      changed = {
        ...state,
        source: {
          ...state.source,
          sourceSiemList: payload,
        },
      };
      break;
    }

    // AUDIT
    case SET_LOG_AUDIT_LOG: {
      changed = {
        ...state,
        audit: {
          ...state.audit,
          dataList: payload,
        },
      };
      break;
    }

    // SEARCH
    case SET_LOG_SEARCH_LIST: {
      changed = {
        ...state,
        search: {
          ...state.search,
          result: payload,
        },
      };
      break;
    }

    case SET_LOG_SIEM_FIELDS: {
      changed = {
        ...state,
        search: {
          ...state.search,
          siemFields: payload,
        },
      };
      break;
    }

    // EXPLORER
    case SET_DATE_FOLDER: {
      changed = {
        ...state,
        explorer: {
          ...state.explorer,
          dateFolder: payload,
        },
      };
      break;
    }

    case SET_HOUR_FOLDER: {
      changed = {
        ...state,
        explorer: {
          ...state.explorer,
          hoursFolder: payload,
        },
      };
      break;
    }

    case SET_EXPLORER: {
      changed = {
        ...state,
        explorer: {
          ...state.explorer,
          explorers: payload,
        },
      };
      break;
    }

    case SET_ARCHIVES: {
      changed = {
        ...state,
        explorer: {
          ...state.explorer,
          archives: payload,
        },
      };
      break;
    }

    default:
      break;
  }

  if (localStorage.getItem("auth") !== null)
    saveToLS(CYBERKIT, CYBERKIT_LOG_MANAGEMENT, changed);
  return changed;
};

export default CyberKit;
