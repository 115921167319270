import {
  GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY_SUCCESS,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY_FAILURE,
  CLEAR_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY,
} from "../../constants/PrivacyKitPrivacyAssessmentAutomation";

const initialState = {
  listInventory: null,
  loading: false,
};

const DataMappingReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY: {
      return {
        ...state,
        loading: true,
        listInventory: null,
      };
    }
    case GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        listInventory: action.data,
      };
    }
    case GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case CLEAR_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default DataMappingReducers;
