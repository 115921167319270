import _ from "lodash";
import {
  LIST_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
  CREATE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
  GET_INCIDENT_RESPONSE_NOTIFICATION,
  GET_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_NOTIFICATION_STATUS_SUCCESS,
  DELETE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
  LIST_INCIDENT_RESPONSE_PLAN_SUCCESS,
  GET_INCIDENT_RESPONSE_PLAN_SUCCESS,
  CREATE_INCIDENT_RESPONSE_PLAN_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_PLAN_SUCCESS,
  PUBLISH_INCIDENT_RESPONSE_PLAN_SUCCESS,
  DELETE_INCIDENT_RESPONSE_PLAN_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_PLAN_STAGE_SUCCESS,
  TOGGLE_INCIDENT_RESPONSE_PLAN_STAGE_NEXT_BUTTON,
  TOGGLE_INCIDENT_RESPONSE_PLAN_STAGE_BACK_BUTTON,
  LIST_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
  CREATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
  GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN,
  GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
  DELETE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
  LIST_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
  GET_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
  CREATE_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
  PUBLISH_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
  DELETE_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
  LIST_INCIDENT_RESPONSE_WEBFORM_VERSION_SUCCESS,
  GET_INCIDENT_RESPONSE_WEBFORM_VERSION_SUCCESS,
  GET_INCIDENT_RESPONSE_WEBFORM_WEBFORM_FIELDS_SUCCESS,
  LIST_INCIDENT_RESPONSE_CASE_SUCCESS,
  CREATE_INCIDENT_RESPONSE_CASE_SUCCESS,
  GET_INCIDENT_RESPONSE_CASE_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_CASE_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_CASE_ERROR,
  UPDATE_INCIDENT_RESPONSE_CASE_STAGE_SUCCESS,
  DELETE_INCIDENT_RESPONSE_CASE_SUCCESS,
  LIST_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
  CREATE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
  GET_INCIDENT_RESPONSE_TASK_IN_CASE,
  GET_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
  DELETE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
  LIST_INCIDENT_RESPONSE_CASE_COMMENT_SUCCESS,
  CREATE_INCIDENT_RESPONSE_CASE_COMMENT_SUCCESS,
  LIST_INCIDENT_RESPONSE_CASE_HISTORY_SUCCESS,
  LIST_INCIDENT_RESPONSE_CASE_HIGH_SUCCESS,
  SENT_INCIDENT_RESPONSE_CASE_HIGH_SUCCESS,
  GET_INCIDENT_RESPONSE_CASE_HIGH_TEMPLATE_SUCCESS,
  LIST_INCIDENT_RESPONSE_TASK_ALL_SUCCESS,
  CLEAR_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_DATA,
  GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE_SUCCESS,
  RESET_INCIDENT_RESPONSE_WEBFORM_DATA,
} from "redux/constants/PrivacyKitDataBreachNotification";
import { replaceObject } from "util/arrayUtils";

const initialState = {
  webformFields: {
    fields: null,
    key_fields: null,
  },
  nextStageButtonProps: {
    loading: false,
    disabled: false,
  },
  backStageButtonProps: {
    loading: false,
    disabled: false,
  },
};

const PrivacyKitDataBreachNotification = (state = initialState, action) => {
  switch (action.type) {
    case LIST_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        incidentResponseNotification: undefined,
        listIncidentResponseNotification: action.payload,
      };
    }
    case CREATE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        listIncidentResponseNotification: {
          ...state.listIncidentResponseNotification,
          data: [
            action.payload,
            ...state.listIncidentResponseNotification.data,
          ],
        },
      };
    }
    case GET_INCIDENT_RESPONSE_NOTIFICATION: {
      return {
        ...state,
        incidentResponseNotification: undefined,
      };
    }
    case GET_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        incidentResponseNotification: action.payload,
      };
    }
    case UPDATE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS: {
      const editData = state.listIncidentResponseNotification.data;
      const dataIndex = _.findIndex(
        editData,
        ({ ObjectUUID }) => ObjectUUID === action.payload.ObjectUUID
      );
      editData[dataIndex] = action.payload;
      return {
        ...state,
        listIncidentResponseNotification: {
          ...state.listIncidentResponseNotification,
          data: [...editData],
        },
      };
    }
    case UPDATE_INCIDENT_RESPONSE_NOTIFICATION_STATUS_SUCCESS: {
      const editData = state.listIncidentResponseNotification.data;
      const dataIndex = _.findIndex(
        editData,
        ({ ObjectUUID }) => ObjectUUID === action.payload.ObjectUUID
      );
      editData[dataIndex] = action.payload;
      return {
        ...state,
        listIncidentResponseNotification: {
          ...state.listIncidentResponseNotification,
          data: [...editData],
        },
      };
    }
    case DELETE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS: {
      const { data } = state.listIncidentResponseNotification;
      return {
        ...state,
        listIncidentResponseNotification: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload
          ),
        },
      };
    }
    case LIST_INCIDENT_RESPONSE_PLAN_SUCCESS: {
      return {
        ...state,
        listResponsePlan: action.payload,
      };
    }
    case GET_INCIDENT_RESPONSE_PLAN_SUCCESS: {
      return {
        ...state,
        responsePlan: action.payload,
      };
    }
    case CREATE_INCIDENT_RESPONSE_PLAN_SUCCESS: {
      return {
        ...state,
        listResponsePlan: {
          data: [action.payload, ...state.listResponsePlan.data],
        },
      };
    }
    case UPDATE_INCIDENT_RESPONSE_PLAN_SUCCESS: {
      const { data } = state.listResponsePlan;
      const dataIndex = _.findIndex(
        data,
        ({ ObjectUUID }) => ObjectUUID === action.payload.ObjectUUID
      );
      data[dataIndex] = action.payload;
      return {
        ...state,
        listResponsePlan: { ...state.listResponsePlan, data },
      };
    }
    case PUBLISH_INCIDENT_RESPONSE_PLAN_SUCCESS: {
      return {
        ...state,
        listResponsePlan: {
          ...state.listResponsePlan,
          data: [
            ...replaceObject(
              state.listResponsePlan.data,
              action.payload.ObjectUUID,
              action.payload
            ),
          ],
        },
      };
    }
    case DELETE_INCIDENT_RESPONSE_PLAN_SUCCESS: {
      const { data } = state.listResponsePlan;
      return {
        ...state,
        listResponsePlan: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    case UPDATE_INCIDENT_RESPONSE_PLAN_STAGE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        nextStageButtonProps: initialState.nextStageButtonProps,
        backStageButtonProps: initialState.backStageButtonProps,
      };
    }
    case TOGGLE_INCIDENT_RESPONSE_PLAN_STAGE_NEXT_BUTTON: {
      return {
        ...state,
        nextStageButtonProps: {
          ...state.nextStageButtonProps,
          loading: true,
        },
        backStageButtonProps: {
          ...state.backStageButtonProps,
          disabled: true,
        },
      };
    }
    case TOGGLE_INCIDENT_RESPONSE_PLAN_STAGE_BACK_BUTTON: {
      return {
        ...state,
        nextStageButtonProps: {
          ...state.nextStageButtonProps,
          disabled: true,
        },
        backStageButtonProps: {
          ...state.backStageButtonProps,
          loading: true,
        },
      };
    }
    case LIST_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS: {
      return {
        ...state,
        taskInResponsePlan: undefined,
        listTaskInResponsePlan: action.payload,
      };
    }
    case CREATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS: {
      return {
        ...state,
        listTaskInResponsePlan: {
          data: [action.payload, ...state.listTaskInResponsePlan.data],
        },
      };
    }
    case GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN: {
      return {
        ...state,
        taskInResponsePlan: undefined,
        // taskInResponsePlan: action.payload,
      };
    }
    case GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS: {
      return {
        ...state,
        taskInResponsePlan: action.payload,
      };
    }
    case UPDATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS: {
      const { data } = state.listTaskInResponsePlan;
      const dataIndex = _.findIndex(
        data,
        ({ ObjectUUID }) => ObjectUUID === action.payload.ObjectUUID
      );
      data[dataIndex] = action.payload;
      return {
        ...state,
        listTaskInResponsePlan: {
          ...state.listTaskInResponsePlan,
          data,
        },
      };
    }
    case DELETE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS: {
      const { data } = state.listTaskInResponsePlan;
      return {
        ...state,
        listTaskInResponsePlan: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    case CLEAR_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_DATA: {
      return {
        ...state,
        responsePlan: undefined,
        listTaskInResponsePlan: undefined,
      };
    }
    case LIST_INCIDENT_RESPONSE_WEBFORM_SUCCESS: {
      return {
        ...state,
        listWebform: action.payload,
      };
    }
    case GET_INCIDENT_RESPONSE_WEBFORM_SUCCESS: {
      return {
        ...state,
        webform: action.payload,
      };
    }
    case CREATE_INCIDENT_RESPONSE_WEBFORM_SUCCESS: {
      return {
        ...state,
        listWebform: state.listWebform
          ? {
              ...state.listWebform,
              data: [action.payload, ...state.listWebform.data],
            }
          : undefined,
      };
    }
    case UPDATE_INCIDENT_RESPONSE_WEBFORM_SUCCESS: {
      return {
        ...state,
        listWebform: state.listWebform
          ? {
              ...state.listWebform,
              data: replaceObject(
                state.listWebform.data,
                action.payload.ObjectUUID,
                action.payload
              ),
            }
          : undefined,
      };
    }
    case PUBLISH_INCIDENT_RESPONSE_WEBFORM_SUCCESS: {
      return {
        ...state,
        listWebform: state.listWebform
          ? {
              ...state.listWebform,
              data: replaceObject(
                state.listWebform.data,
                action.payload.webformData.ObjectUUID,
                action.payload.webformData
              ),
            }
          : undefined,
        listWebformVersion: state.listWebformVersion
          ? {
              ...state.listWebformVersion,
              data: [
                action.payload.versionData,
                ...state.listWebformVersion.data,
              ],
            }
          : undefined,
      };
    }
    case DELETE_INCIDENT_RESPONSE_WEBFORM_SUCCESS: {
      const { data } = state.listWebform;
      return {
        ...state,
        listWebform: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    case LIST_INCIDENT_RESPONSE_WEBFORM_VERSION_SUCCESS: {
      return {
        ...state,
        listWebformVersion: action.payload,
      };
    }
    case GET_INCIDENT_RESPONSE_WEBFORM_VERSION_SUCCESS: {
      return {
        ...state,
        webformVersion: action.payload,
      };
    }
    case GET_INCIDENT_RESPONSE_WEBFORM_WEBFORM_FIELDS_SUCCESS: {
      return {
        ...state,
        webformFields: action.payload,
      };
    }
    case LIST_INCIDENT_RESPONSE_CASE_SUCCESS: {
      return {
        ...state,
        incidentResponseCase: undefined,
        listIncidentResponseCase: action.payload,
      };
    }
    case CREATE_INCIDENT_RESPONSE_CASE_SUCCESS: {
      return {
        ...state,
        listIncidentResponseCase: {
          data: [action.payload, ...state.listIncidentResponseCase.data],
        },
      };
    }
    case GET_INCIDENT_RESPONSE_CASE_SUCCESS: {
      return {
        ...state,
        incidentResponseCase: action.payload,
      };
    }
    case UPDATE_INCIDENT_RESPONSE_CASE_SUCCESS: {
      // console.log(action.payload);
      return {
        ...state,
        listIncidentResponseCaseHigh: { ...action.payload },
        nextStageButtonProps: initialState.nextStageButtonProps,
        backStageButtonProps: initialState.backStageButtonProps,
        listIncidentResponseCaseHigh: state?.listIncidentResponseCaseHigh
          ? {
              ...state.listIncidentResponseCaseHigh,
              data: [
                ...replaceObject(
                  state.listIncidentResponseCaseHigh.data,
                  action.payload.ObjectUUID,
                  action.payload
                ),
              ],
            }
          : undefined,
      };
    }
    case UPDATE_INCIDENT_RESPONSE_CASE_ERROR: {
      return {
        ...state,
        nextStageButtonProps: initialState.nextStageButtonProps,
        backStageButtonProps: initialState.backStageButtonProps,
      };
    }

    case UPDATE_INCIDENT_RESPONSE_CASE_STAGE_SUCCESS: {
      // console.log(action.payload);
      return {
        ...state,
        ...action.payload,
        nextStageButtonProps: initialState.nextStageButtonProps,
        backStageButtonProps: initialState.backStageButtonProps,
      };
    }

    case DELETE_INCIDENT_RESPONSE_CASE_SUCCESS: {
      const { data } = state.listIncidentResponseCase;
      return {
        ...state,
        listIncidentResponseCase: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload
          ),
        },
      };
    }
    case LIST_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS: {
      return {
        ...state,
        taskInCase: undefined,
        listIncidentResponseTask: action.payload,
      };
    }
    case CREATE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS: {
      return {
        ...state,
        listIncidentResponseTask: {
          data: [action.payload, ...state.listIncidentResponseTask.data],
        },
      };
    }
    case GET_INCIDENT_RESPONSE_TASK_IN_CASE: {
      return {
        ...state,
        taskInCase: undefined,
      };
    }
    case GET_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS: {
      return {
        ...state,
        taskInCase: action.payload,
      };
    }
    case UPDATE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS: {
      return {
        ...state,
        listIncidentResponseTask: {
          ...state.listIncidentResponseTask,
          data: [
            ...replaceObject(
              state.listIncidentResponseTask.data,
              action.payload.ObjectUUID,
              action.payload
            ),
          ],
        },
      };
    }
    case DELETE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS: {
      const { data } = state.listIncidentResponseTask;
      return {
        ...state,
        listIncidentResponseTask: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    case LIST_INCIDENT_RESPONSE_CASE_COMMENT_SUCCESS: {
      return {
        ...state,
        listIncidentResponseCaseComment: action.payload,
      };
    }
    case CREATE_INCIDENT_RESPONSE_CASE_COMMENT_SUCCESS: {
      return {
        ...state,
        listIncidentResponseCaseComment: {
          data: [action.payload, ...state.listIncidentResponseCaseComment.data],
        },
      };
    }
    case LIST_INCIDENT_RESPONSE_CASE_HISTORY_SUCCESS: {
      return {
        ...state,
        listIncidentResponseCaseHistory: action.payload,
      };
    }
    case LIST_INCIDENT_RESPONSE_CASE_HIGH_SUCCESS: {
      return {
        ...state,
        listIncidentResponseCaseHigh: action.payload,
      };
    }
    case SENT_INCIDENT_RESPONSE_CASE_HIGH_SUCCESS: {
      return {
        ...state,
        listIncidentResponseCaseHigh: {
          data: [
            ...replaceObject(
              state.listIncidentResponseCaseHigh.data,
              action.payload.ObjectUUID,
              action.payload
            ),
          ],
        },
      };
    }
    case GET_INCIDENT_RESPONSE_CASE_HIGH_TEMPLATE_SUCCESS: {
      return {
        ...state,
        getTemplateReport: action.payload,
      };
    }
    case LIST_INCIDENT_RESPONSE_TASK_ALL_SUCCESS: {
      return {
        ...state,
        listIncidentResponseTask: action.payload,
      };
    }
    case GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE_SUCCESS: {
      return {
        ...state,
        webformTemplate: action.payload,
      };
    }
    case RESET_INCIDENT_RESPONSE_WEBFORM_DATA: {
      return {
        ...state,
        webform: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export default PrivacyKitDataBreachNotification;
