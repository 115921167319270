import {
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DEFAULT_WORKFLOW,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_DELETE,
  CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGE_ORDER,
  CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGES,
  TOGGLE_CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_FAILED,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR,
  TOGGLE_PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  // PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED,
  TOGGLE_DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";
import _ from "lodash";
import { replaceObject } from "util/arrayUtils";

const initialState = {
  loading: false,
  stageOrder: null,
  listWorkflow: null,
  workflowData: null,
  state: null,
  okButtonProps: {
    loading: false,
  },
  workflowCreate: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
  workflowDelete: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
  listTaskInWorkflow: null,
  modalPublish: {
    visible: false,
    okButtonProps: {
      loading: false,
    },
  },
  deleteTask: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
  taskModal: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
};

const WorkflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DEFAULT_WORKFLOW: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW: {
      return {
        ...state,
        state: initialState.state,
        loading: true,
      };
    }
    case GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS: {
      return {
        ...state,
        stageOrder: 0,
        workflowData: action.payload,
        loading: false,
      };
    }
    case TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_DELETE: {
      return {
        ...state,
        workflowDelete: {
          ...initialState.workflowDelete,
          visible: !state.workflowDelete.visible,
          data: action.payload ?? null,
        },
      };
    }
    case CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGE_ORDER: {
      return {
        ...state,
        stageOrder: action.stageOrder,
      };
    }
    case CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGES: {
      return {
        ...state,
        workflowData: {
          ...state.workflowData,
          stages: action.stages,
        },
      };
    }
    case TOGGLE_CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW: {
      return {
        ...state,
        workflowCreate: {
          ...initialState.workflowCreate,
          visible: !state.workflowCreate.visible,
          data: action?.payload ?? null,
        },
      };
    }
    case CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW: {
      return {
        ...state,
        workflowCreate: {
          ...state.workflowCreate,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS: {
      return {
        ...state,
        state: action.type,
        listWorkflow: state.listWorkflow
          ? [action.payload, ...state.listWorkflow]
          : null,
        workflowCreate: initialState.workflowCreate,
      };
    }
    case CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_FAILED: {
      return {
        ...state,
        workflowCreate: {
          ...state.workflowCreate,
          okButtonProps: initialState.workflowCreate.okButtonProps,
        },
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW: {
      return {
        ...state,
        okButtonProps: {
          loading: true,
        },
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS: {
      return {
        ...state,
        listWorkflow: state.listWorkflow
          ? replaceObject(
              state.listWorkflow,
              action.payload.ObjectUUID,
              action.payload
            )
          : null,
        modalPublish: initialState.modalPublish,
        okButtonProps: initialState.okButtonProps,
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR: {
      return {
        ...state,
        okButtonProps: {
          loading: false,
        },
      };
    }
    case TOGGLE_PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW: {
      return {
        ...state,
        modalPublish: {
          ...initialState.modalPublish,
          visible: !state.modalPublish.visible,
        },
      };
    }
    case PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW: {
      return {
        ...state,
        modalPublish: {
          ...state.modalPublish,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR: {
      return {
        ...state,
        modalPublish: initialState.modalPublish,
      };
    }
    case DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW: {
      return {
        ...state,
        workflowDelete: {
          ...state.workflowDelete,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS: {
      return {
        ...state,
        workflowDelete: initialState.workflowDelete,
        listWorkflow: _.filter(
          state.listWorkflow,
          ({ ObjectUUID }) => ObjectUUID !== action.workflowId
        ),
      };
    }
    case DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR: {
      return {
        ...state,
        workflowDelete: {
          ...state.workflowDelete,
          okButtonProps: {
            loading: false,
          },
        },
      };
    }
    case TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW: {
      return {
        ...state,
        taskModal: {
          ...state.taskModal,
          data: action?.data ?? null,
          visible: !state.taskModal.visible,
        },
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS: {
      return {
        ...state,
        listTaskInWorkflow: action.listTaskInWorkflow,
      };
    }
    case CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW ||
      UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW: {
      return {
        ...state,
        taskModal: {
          ...state.taskModal,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS: {
      return {
        ...state,
        listTaskInWorkflow: [action.payload, ...state.listTaskInWorkflow],
        taskModal: initialState.taskModal,
      };
    }
    case CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED: {
      return {
        ...state,
        taskModal: {
          ...state.taskModal,
          okButtonProps: {
            loading: false,
          },
        },
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW: {
      return {
        ...state,
        taskModal: {
          ...state.taskModal,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS: {
      return {
        ...state,
        listTaskInWorkflow: state.listTaskInWorkflow
          ? replaceObject(
              state.listTaskInWorkflow,
              action.payload.ObjectUUID,
              action.payload
            )
          : initialState.listTaskInWorkflow,
        taskModal: initialState.taskModal,
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED: {
      return {
        ...state,
        taskModal: {
          ...state.taskModal,
          okButtonProps: {
            loading: false,
          },
        },
      };
    }
    case TOGGLE_DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW: {
      return {
        ...state,
        deleteTask: {
          ...state.deleteTask,
          visible: !state.deleteTask.visible,
          data: action.payload ?? null,
        },
      };
    }
    case DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW: {
      return {
        ...state,
        deleteTask: {
          ...state.deleteTask,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS: {
      return {
        ...state,
        listTaskInWorkflow: _.filter(
          state.listTaskInWorkflow,
          ({ ObjectUUID }) => ObjectUUID !== action.taskId
        ),
        deleteTask: initialState.deleteTask,
      };
    }
    case DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED: {
      return {
        ...state,
        deleteTask: {
          ...state.deleteTask,
          okButtonProps: {
            loading: false,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default WorkflowReducer;
