// PAGINATE
export const SET_LOADING = "CYBERKIT/SET_LOADING";
export const GET_PAGINATE = "CYBERKIT/PAGINATE/GET";
export const SET_PAGINATE = "CYBERKIT/PAGINATE/SET";

export const SET_DATA = "CYBERKIT/PAGINATE/SET_DATA";
export const SET_METADATA = "CYBERKIT/PAGINATE/SET_METADATA";
export const SET_METADATA_TOTAL_PAGE = "CYBERKIT/PAGINATE/SET_TOTAL_PAGE";
export const SET_METADATA_CURRENT_PAGE =
  "CYBERKIT/PAGINATE/SET_METADATA_CURRENT_PAGE";
export const SET_METADATA_PAGE_SIZE =
  "CYBERKIT/PAGINATE/SET_METADATA_PAGE_SIZE";
export const CLEAR_METADATA = "CYBERKIT/PAGINATE/CLEAR_METADATA";

// COMMON
export const GET_AGENT_LIST = "GET_AGENT_LIST";
export const SET_GLOBAL_LOADING = "SET_GLOBAL_LOADING";
