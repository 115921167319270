export const LIST_CONSENT_MANAGEMENT_DATE_ELEMENT =
  "LIST_CONSENT_MANAGEMENT_DATE_ELEMENT";
export const LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS =
  "LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS";
export const LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_TYPE =
  "LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_TYPE";
export const LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_TYPE_SUCCESS =
  "LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_TYPE_SUCCESS";
export const GET_CONSENT_MANAGEMENT_DATE_ELEMENT =
  "GET_CONSENT_MANAGEMENT_DATE_ELEMENT";
export const GET_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS =
  "GET_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS";
export const SET_MANAGE_CONSENT_MANAGEMENT_DATE_ELEMENT =
  "SET_MANAGE_CONSENT_MANAGEMENT_DATE_ELEMENT";
export const CREATE_CONSENT_MANAGEMENT_DATE_ELEMENT =
  "CREATE_CONSENT_MANAGEMENT_DATE_ELEMENT";
export const CREATE_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS =
  "CREATE_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS";
export const UPDATE_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS =
  "UPDATE_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS";
export const CREATE_OR_UPDATE_CONSENT_MANAGEMENT_DATE_ELEMENT_ERROR =
  "CREATE_OR_UPDATE_CONSENT_MANAGEMENT_DATE_ELEMENT_ERROR";
export const TOGGLE_MANAGE_CONSENT_MANAGEMENT_DATA_ELEMENT =
  "TOGGLE_MANAGE_CONSENT_MANAGEMENT_DATA_ELEMENT";
export const TOGGLE_DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT =
  "TOGGLE_DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT";
export const DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT =
  "DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT";
export const DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT_SUCCESS =
  "DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT_SUCCESS";
export const DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT_ERROR =
  "DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT_ERROR";

export const SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT =
  "SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT";
export const SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT_SUCCESS =
  "SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT_SUCCESS";
