import {
  LIST_CONSENT_MANAGEMENT_COLLECTION_POINT,
  LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  GET_CONSENT_MANAGEMENT_COLLECTION_POINT,
  GET_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  TOGGLE_CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT,
  CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT,
  CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT_ERROR,
  UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT,
  UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT,
  PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT_ERROR,
  TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_SETTING,
  SET_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION,
  ADD_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_SECTION,
  UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_SECTION,
  UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_TEXT,
  UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_STYLE,
  TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION,
  TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
  TOGGLE_MASK_IDENTIFY_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
  TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS,
  CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS,
  CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_SUCCESS,
  CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_ERROR,
  TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_PREVIEW,
  TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_GET_SCRIPT,
  LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_SUCCESS,
  GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION,
  GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_SUCCESS,
  TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION,
} from "../../constants/PrivacyKitConsentManagement";
import { v4 as uuid } from "uuid";
import { replaceObject } from "util/arrayUtils";

const initialState = {
  loading: false,
  collectionPointId: null,
  collectionPointData: {
    form_fields: {},
  },
  listCollectionPoint: null,
  collectionPointVersion: null,
  listCollectionPointVersion: null,
  maskIdentify: {
    visible: false,
    data: null,
  },
  collectionPointSetting: {
    visible: false,
    sectionId: null,
    settingType: "DEFAULT",
    data: null,
  },
  createModalProps: {
    title: "สร้างฟอร์มขอความยินยอม",
    width: 800,
    visible: false,
  },
  deleteSection: {
    visible: false,
    data: null,
  },
  deleteSectionComponent: {
    visible: false,
    data: null,
  },
  collectionPointStatus: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
  collectionPointPreview: {
    visible: false,
    data: null,
  },
  collectionPointGetScript: {
    visible: false,
    data: null,
  },
  okButtonProps: {
    loading: false,
  },
  listLabel: [
    {
      element_type: "data-label",
      name: "ป้ายกำกับ",
      id: uuid(),
      text: "<p>ป้ายกำกับ</p>",
      widget: "text-editor",
      identifier: false,
    },
  ],
  previewVersion: {
    visible: false,
    data: null,
  },
};

const addFormSection = (formFields) => {
  const id = uuid();
  formFields.form[0].section.push({
    component: [],
    ObjectUUID: id,
    section_name: id,
    id,
  });
  return { ...formFields };
};

const CollectionPointReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CONSENT_MANAGEMENT_COLLECTION_POINT: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS: {
      return {
        ...state,
        listCollectionPoint: action.data,
        loading: false,
      };
    }
    case GET_CONSENT_MANAGEMENT_COLLECTION_POINT: {
      return {
        ...state,
        collectionPointId: action.collectionPointId,
        collectionPointData: state.collectionPointData,
        loading: true,
      };
    }
    case GET_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS: {
      return {
        ...state,
        collectionPointData: action.data,
        collectionPointSetting: {
          ...state.collectionPointSetting,
          sectionId: action.data?.form_fields?.form[0]?.section[0]?.ObjectUUID,
        },
        loading: false,
      };
    }
    case TOGGLE_CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT: {
      return {
        ...state,
        createModalProps: {
          ...state.createModalProps,
          visible: !state.createModalProps.visible,
        },
      };
    }
    case CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT: {
      return {
        ...state,
        createModalProps: {
          ...state.createModalProps,
          visible: true,
        },
        okButtonProps: {
          loading: true,
        },
      };
    }
    case CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS: {
      return {
        ...state,
        createModalProps: {
          ...state.createModalProps,
          visible: false,
        },
        okButtonProps: {
          loading: false,
        },
        listCollectionPoint: state.listCollectionPoint
          ? [action.data, ...state.listCollectionPoint]
          : null,
      };
    }
    case CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT_ERROR: {
      return {
        ...state,
        createModalProps: initialState.createModalProps,
      };
    }
    case UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT: {
      return {
        ...state,
        okButtonProps: {
          loading: true,
        },
      };
    }
    case UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS: {
      return {
        ...state,
        listCollectionPoint: state.listCollectionPoint
          ? [
              ...replaceObject(
                state.listCollectionPoint,
                state.collectionPointId,
                action.data
              ),
            ]
          : null,
        okButtonProps: {
          loading: false,
        },
      };
    }
    case PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT: {
      return {
        ...state,
        okButtonProps: {
          loading: true,
        },
      };
    }
    case PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS: {
      return {
        ...state,
        listCollectionPoint: state.listCollectionPoint
          ? [
              ...replaceObject(
                state.listCollectionPoint,
                state.collectionPointId,
                action.data
              ),
            ]
          : null,
        listCollectionPointVersion: state.listCollectionPointVersion
          ? [action.data, ...state.listCollectionPointVersion]
          : null,
        okButtonProps: {
          loading: false,
        },
      };
    }
    case PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT_ERROR: {
      return {
        ...state,
        okButtonProps: initialState.okButtonProps,
      };
    }
    case TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_SETTING: {
      return {
        ...state,
        collectionPointSetting: {
          ...state.collectionPointSetting,
          visible: !state.collectionPointSetting.visible,
          settingType: action.settingType ?? "DEFAULT",
          data: action.data ?? null,
        },
      };
    }
    case SET_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION: {
      return {
        ...state,
        collectionPointSetting: {
          ...state.collectionPointSetting,
          sectionId: action.sectionId,
        },
      };
    }
    case ADD_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_SECTION: {
      return {
        ...state,
        collectionPointData: {
          ...state.collectionPointData,
          form_fields: addFormSection(state.collectionPointData.form_fields),
        },
      };
    }
    case UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_SECTION: {
      return {
        ...state,
        collectionPointData: {
          ...state.collectionPointData,
          form_fields: {
            ...state.collectionPointData.form_fields,
            form: action.data,
          },
        },
      };
    }
    case UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_TEXT: {
      return {
        ...state,
        collectionPointData: {
          ...state.collectionPointData,
          form_text: {
            ...state.collectionPointData.form_text,
            th: action.formText,
          },
        },
      };
    }
    case UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_STYLE: {
      return {
        ...state,
        collectionPointData: {
          ...state.collectionPointData,
          form_style: {
            ...state.collectionPointData.form_style,
            ...action.formStyle,
          },
        },
      };
    }
    case TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION: {
      return {
        ...state,
        deleteSection: {
          visible: !state.deleteSection.visible,
          data: action.payload ?? null,
        },
      };
    }
    case TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT: {
      return {
        ...state,
        deleteSectionComponent: {
          visible: !state.deleteSectionComponent.visible,
          data: action.payload ?? null,
        },
      };
    }
    case TOGGLE_MASK_IDENTIFY_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT: {
      return {
        ...state,
        maskIdentify: {
          visible: !state.maskIdentify.visible,
          data: action.payload ?? null,
        },
      };
    }
    case TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS: {
      return {
        ...state,
        collectionPointStatus: {
          ...state.collectionPointStatus,
          visible: !state.collectionPointStatus.visible,
          data: action.payload ?? null,
        },
      };
    }
    case CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS: {
      return {
        ...state,
        collectionPointStatus: {
          ...state.collectionPointStatus,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_SUCCESS: {
      return {
        ...state,
        collectionPointStatus: initialState.collectionPointStatus,
        listCollectionPoint: [
          ...replaceObject(
            state.listCollectionPoint,
            action.payload.ObjectUUID,
            action.payload
          ),
        ],
      };
    }
    case CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_ERROR: {
      return {
        ...state,
        collectionPointStatus: {
          ...state.collectionPointStatus,
          okButtonProps: {
            loading: false,
          },
        },
      };
    }
    case TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_PREVIEW: {
      return {
        ...state,
        collectionPointPreview: {
          visible: !state.collectionPointPreview.visible,
          data: action.payload ?? null,
        },
      };
    }
    case TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_GET_SCRIPT: {
      return {
        ...state,
        collectionPointGetScript: {
          visible: !state.collectionPointGetScript.visible,
          data: action.payload ?? null,
        },
      };
    }
    case LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_SUCCESS: {
      return {
        ...state,
        listCollectionPointVersion: action.payload,
      };
    }
    case TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION: {
      return {
        ...state,
        previewVersion: {
          visible: !state.previewVersion.visible,
          data: action.payload ?? null,
        },
      };
    }
    case GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION: {
      return {
        ...state,
        collectionPointVersion: initialState.collectionPointVersion,
      };
    }
    case GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_SUCCESS: {
      return {
        ...state,
        collectionPointVersion: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default CollectionPointReducer;
