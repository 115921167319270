// DASHBOARD
export const GET_ST_DASHBOARD = "GET_STATIC_DASHBOARD";
export const GET_DY_DASHBOARD = "GET_DY_DASHBOARD";
export const SET_ST_DASHBOARD = "SET_ST_DASHBOARD";
export const SET_DY_DASHBOARD = "SET_DY_DASHBOARD";

// LOG MANAGEMENT - SOURCE
export const GET_SOURCE_LIST = "GET_SOURCE_LIST";
export const SET_SOURCE_LIST = "SET_SOURCE_LIST";
export const GET_SOURCE_LIST_BY_TENANACY = "GET_SOURCE_LIST_BY_TENANACY";
export const CREATE_SOURCE = "CREATE_SOURCE";
export const UPDATE_SOURCE = "UPDATE_SOURCE";
export const DELETE_SOURCE = "DELETE_SOURCE";

export const GET_SOURCE_SIEM_LIST = "GET_SOURCE_SIEM_LIST";
export const SET_SOURCE_SIEM_LIST = "SET_SOURCE_SIEM_LIST";
export const CLEAR_SOURCE_SIEM_LIST = "CLEAR_SOURCE_SIEM_LIST";

// LOG MANAGEMENT - AUDIT
export const GET_LOG_AUDIT_LOG = "CYBER_KIT/LOG_MANAGEMENT/GET_AUDIT_LOG";
export const SET_LOG_AUDIT_LOG = "CYBER_KIT/LOG_MANAGEMENT/SET_AUDIT_LOG";

// LOG MANAGEMENT - SEARCH
export const SET_LOG_SEARCH_LIST = "CYBER_KIT/LOG_MANAGEMENT/SET_SEARCH_LIST";

export const GET_LOG_SIEM_FIELDS = "CYBER_KIT/LOG_MANAGEMENT/GET_SIEM_FIELD";

export const SET_LOG_SIEM_FIELDS = "CYBER_KIT/LOG_MANAGEMENT/SET_SIEM_FIELD";

// EXPLORER
export const GET_LOG_LIST = "CYBER_KIT/LOG_MANAGEMENT/GET_LOG_LIST";
export const SET_EXPLORER = "CYBER_KIT/LOG_MANAGEMENT/SET_EXPLORER";
export const SET_DATE_FOLDER = "CYBER_KIT/LOG_MANAGEMENT/SET_DATE_FOLDER";
export const SET_HOUR_FOLDER = "CYBER_KIT/LOG_MANAGEMENT/SET_HOUR_FOLDER";

export const SET_ARCHIVES = "CYBER_KIT/LOG_MANAGEMENT/SET_ARCHIVES";
export const CREATE_ARCHIVE = "CREATE_ARCHIVE";
export const UPDATE_ARCHIVE = "UPDATE_ARCHIVE";
export const DELETE_ARCHIVE = "DELETE_ARCHIVE";
