import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  CREATE_AGENT,
  UPDATE_AGENT,
  DELETE_AGENT,
  GET_SCANNER_DASHBOARD,
  GET_SCANNER_LIST,
  CREATE_SCANNER,
  UPDATE_SCANNER,
  DELETE_SCANNER,
  GET_SCANNER_TASK,
  GET_SCANNER_FOUND,
  GET_SCANNER_DETAIL,
} from "redux/constants/CyberKitVulnerabilityAssessment";
import {
  setAgentList,
  setScannerDashboard,
  setScannerList,
  setScannerTask,
  setScannerFound,
  setScannerDetail,
} from "redux/actions/CyberKitVulnerabilityAssessment";
import convertJsonParamsToString from "util/convertJsonParamsToString";
import VulnerabilityAssessmentServices from "services/cyberkit/VulnerabilityAssessmentServices";
import { CyberKitVulnerabilityAssessmentDetailModel } from "models";
import Notification from "util/Notification";

// Agent
export function* createAgent() {
  yield takeEvery(CREATE_AGENT, function* ({ payload }) {
    const resp = yield call(
      VulnerabilityAssessmentServices.createAgent,
      payload
    );

    const { status, data } = resp;

    if (status === "OK") {
      const state = yield select();
      let oldAgentList = state.cyberKitVulnerabilityAssessment.agent.dataList;
      oldAgentList.pop();
      oldAgentList.unshift(data);
      setAgentList(oldAgentList);
      payload.next(data.id);
    } else Notification("error", "ไม่สามารถสร้าง Agent ได้");
  });
}

export function* updateAgent() {
  yield takeEvery(UPDATE_AGENT, function* ({ payload }) {
    const resp = yield call(
      VulnerabilityAssessmentServices.updateAgent,
      payload
    );

    const { status } = resp;

    if (status === "OK") payload.next();
    else Notification("error", "ไม่สามารถอัพเดต Agent ได้");
  });
}

export function* deleteAgent() {
  yield takeEvery(DELETE_AGENT, function* ({ payload }) {
    const resp = yield call(
      VulnerabilityAssessmentServices.deleteAgent,
      payload
    );

    const { status } = resp;

    if (status === "OK") payload.next();
    else Notification("error", "ไม่สามารถลบ Agent ได้");
  });
}

// Scanner
export function* getScannerDashboard() {
  yield takeEvery(GET_SCANNER_DASHBOARD, function* ({ payload }) {
    let params = ``;
    const keyParamList = Object.keys(payload.params || {});

    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);

    const payloadService = {
      params: params,
    };

    const resp = yield call(
      VulnerabilityAssessmentServices.getScannerDashboard,
      payloadService
    );

    const { status, data } = resp;

    if (status === "OK") yield put(setScannerDashboard(data));
  });
}

export function* getScannerList() {
  yield takeEvery(GET_SCANNER_LIST, function* ({ payload }) {
    let params = ``;
    const keyParamList = Object.keys(payload.params || {});

    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);

    const payloadService = {
      params: params,
    };

    const resp = yield call(
      VulnerabilityAssessmentServices.getScannerList,
      payloadService
    );

    const { status, data } = resp;

    if (status === "OK") yield put(setScannerList(data));
  });
}

export function* getScannerTask() {
  yield takeEvery(GET_SCANNER_TASK, function* ({ payload }) {
    let params = ``;
    const keyParamList = Object.keys(payload.params || {});

    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);

    const payloadService = {
      path: payload.path,
      params: params,
    };

    const resp = yield call(
      VulnerabilityAssessmentServices.getScannerTasks,
      payloadService
    );

    const { status, data } = resp;

    if (status === "OK") yield put(setScannerTask(data));
  });
}

export function* getScannerFound() {
  yield takeEvery(GET_SCANNER_FOUND, function* ({ payload }) {
    let params = ``;
    const keyParamList = Object.keys(payload.params || {});

    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);

    const payloadService = {
      path: payload.path,
      params: params,
    };

    const resp = yield call(
      VulnerabilityAssessmentServices.getScannerFound,
      payloadService
    );

    const { status, data } = resp;

    if (status === "OK") yield put(setScannerFound(resp));
  });
}

export function* createScanner() {
  yield takeEvery(CREATE_SCANNER, function* ({ payload }) {
    const resp = yield call(
      VulnerabilityAssessmentServices.createScanner,
      payload
    );

    const { status } = resp;

    if (status === "OK") payload.next();
  });
}

export function* updateScanner() {
  yield takeEvery(UPDATE_SCANNER, function* ({ payload }) {
    const resp = yield call(
      VulnerabilityAssessmentServices.updateScanner,
      payload
    );

    const { status } = resp;

    if (status === "OK") payload.next();
  });
}

export function* deleteScanner() {
  yield takeEvery(DELETE_SCANNER, function* ({ payload }) {
    const resp = yield call(
      VulnerabilityAssessmentServices.deleteScanner,
      payload
    );

    const { status } = resp;

    if (status === "OK") return;
  });
}

export function* getScannerDetail() {
  yield takeEvery(GET_SCANNER_DETAIL, function* ({ payload }) {
    const resp = yield call(
      VulnerabilityAssessmentServices.getScannerDetail,
      payload
    );

    const { status, data } = resp;

    if (status === "OK")
      yield put(
        setScannerDetail(new CyberKitVulnerabilityAssessmentDetailModel(data))
      );
    else
      yield put(
        setScannerDetail(new CyberKitVulnerabilityAssessmentDetailModel({}))
      );
  });
}

export default function* rootSaga() {
  yield all([
    fork(createAgent),
    fork(updateAgent),
    fork(deleteAgent),
    fork(getScannerDashboard),
    fork(getScannerList),
    fork(createScanner),
    fork(updateScanner),
    fork(deleteScanner),
    fork(getScannerTask),
    fork(getScannerFound),
    fork(getScannerDetail),
  ]);
}
