const MOL_ENDPOINT = "https://api-prod.cyberthai.co";

const mol = {
  API_ENDPOINT_ASSESSMENT_AUTOMATION: `${MOL_ENDPOINT}/assessment`,
  API_ENDPOINT_BASE_URL: `${MOL_ENDPOINT}/user`,
  API_ENDPOINT_CENTRAL_URL: `${MOL_ENDPOINT}/central`,
  API_ENDPOINT_PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT: `${MOL_ENDPOINT}/cookie`,
  API_ENDPOINT_PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT_SAM: `${MOL_ENDPOINT}/cookie`,
  API_ENDPOINT_PRIVACYKIT_DATA_SUBJECT_RIGHT_MANAGEMENT: `${MOL_ENDPOINT}/dsar`,
  API_ENDPOINT_PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE: `${MOL_ENDPOINT}/consent`,
  API_ENDPOINT_PRIVACYKIT_INCIDENT_RESPONSE_MANAGEMENT: `${MOL_ENDPOINT}/incident`,
  API_ENDPOINT_PRIVACYKIT_ASSESSMENT_AUTOMATION: `${MOL_ENDPOINT}/assessment`,
  API_ENDPOINT_PRIVACYKIT_DATA_CLASSIFICATION_AND_DISCOVERY: `${MOL_ENDPOINT}/dataclass`,
  API_ENDPOINT_PRIVACYKIT_DATA_MAPPING: `${MOL_ENDPOINT}/datamap`,
  API_ENDPOINT_PRIVACYKIT_POLICY_AND_NOTICE_MANAGEMENT: `${MOL_ENDPOINT}/policy`,
  API_ENDPOINT_CYBER_KIT: "https://api.service.cyberthai.co/api",
  API_ENDPOINT_CORE_WORKFLOW:
    "https://6ho5mei6f1.execute-api.ap-southeast-1.amazonaws.com/alpha",
  H_CAPTCHA_SITE_KEY: "b888f1af-5023-45f0-b2e2-e49489f389c1",
};

const dev = {
  API_ENDPOINT_ASSESSMENT_AUTOMATION: `${MOL_ENDPOINT}/assessment`,
  API_ENDPOINT_BASE_URL: `${MOL_ENDPOINT}/user`,
  API_ENDPOINT_CENTRAL_URL: `${MOL_ENDPOINT}/central`,
  API_ENDPOINT_PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT: `${MOL_ENDPOINT}/cookie`,
  API_ENDPOINT_PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT_SAM: `${MOL_ENDPOINT}/cookie`,
  API_ENDPOINT_PRIVACYKIT_DATA_SUBJECT_RIGHT_MANAGEMENT: `${MOL_ENDPOINT}/dsar`,
  API_ENDPOINT_PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE: `${MOL_ENDPOINT}/consent`,
  API_ENDPOINT_PRIVACYKIT_INCIDENT_RESPONSE_MANAGEMENT: `${MOL_ENDPOINT}/incident`,
  API_ENDPOINT_PRIVACYKIT_ASSESSMENT_AUTOMATION: `${MOL_ENDPOINT}/assessment`,
  API_ENDPOINT_PRIVACYKIT_DATA_CLASSIFICATION_AND_DISCOVERY: `${MOL_ENDPOINT}/dataclass`,
  API_ENDPOINT_PRIVACYKIT_DATA_MAPPING: `${MOL_ENDPOINT}/datamap`,
  API_ENDPOINT_PRIVACYKIT_POLICY_AND_NOTICE_MANAGEMENT: `${MOL_ENDPOINT}/policy`,
  API_ENDPOINT_CYBER_KIT: "https://api.beta.cyber.onefence.co/",
  API_ENDPOINT_CORE_WORKFLOW:
    "https://ca9foawqd2.execute-api.ap-southeast-1.amazonaws.com/prod",
  H_CAPTCHA_SITE_KEY: "10000000-ffff-ffff-ffff-000000000001",
};

const prod = {
  API_ENDPOINT_ASSESSMENT_AUTOMATION:
    "https://234wrzpol3.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_BASE_URL:
    "https://0cidya7gwd.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_CENTRAL_URL:
    "https://qxro8z0064.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT: "https://api.onefence.co",
  API_ENDPOINT_PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT_SAM:
    "https://0dfadq3z73.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_DATA_SUBJECT_RIGHT_MANAGEMENT:
    "https://o32coe1m18.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE:
    "https://2xpk26ciy2.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_INCIDENT_RESPONSE_MANAGEMENT:
    "https://a6u87rihs3.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_ASSESSMENT_AUTOMATION:
    "https://234wrzpol3.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_DATA_CLASSIFICATION_AND_DISCOVERY:
    "https://akl99y8so6.execute-api.ap-southeast-1.amazonaws.com/v1/",
  API_ENDPOINT_PRIVACYKIT_DATA_MAPPING:
    "https://959xned6n7.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_POLICY_AND_NOTICE_MANAGEMENT:
    "https://c367qel55j.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_CYBER_KIT: "https://api.beta.cyber.onefence.co/",
  API_ENDPOINT_CORE_WORKFLOW:
    "https://ca9foawqd2.execute-api.ap-southeast-1.amazonaws.com/prod",
  H_CAPTCHA_SITE_KEY: "b888f1af-5023-45f0-b2e2-e49489f389c1",
};

const test = {
  API_ENDPOINT_ASSESSMENT_AUTOMATION:
    "https://234wrzpol3.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_BASE_URL:
    "https://0cidya7gwd.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_CENTRAL_URL:
    "https://qxro8z0064.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT: "https://api.onefence.co",
  API_ENDPOINT_PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT_SAM:
    "https://0dfadq3z73.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_DATA_SUBJECT_RIGHT_MANAGEMENT:
    "https://o32coe1m18.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE:
    "https://2xpk26ciy2.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_INCIDENT_RESPONSE_MANAGEMENT:
    "https://a6u87rihs3.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_ASSESSMENT_AUTOMATION:
    "https://234wrzpol3.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_DATA_CLASSIFICATION_AND_DISCOVERY:
    "https://akl99y8so6.execute-api.ap-southeast-1.amazonaws.com/v1/",
  API_ENDPOINT_PRIVACYKIT_DATA_MAPPING:
    "https://959xned6n7.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_PRIVACYKIT_POLICY_AND_NOTICE_MANAGEMENT:
    "https://c367qel55j.execute-api.ap-southeast-1.amazonaws.com/v1",
  API_ENDPOINT_CYBER_KIT: "https://api.beta.cyber.onefence.co/",
  API_ENDPOINT_CORE_WORKFLOW:
    "https://ca9foawqd2.execute-api.ap-southeast-1.amazonaws.com/prod",
  H_CAPTCHA_SITE_KEY: "10000000-ffff-ffff-ffff-000000000001",
};

const getEnv = () => {
  switch (process.env.REACT_APP_SERV) {
    case "mol":
      return mol;
    case "development":
      return mol;
    case "production":
      return mol;
    case "test":
      return mol;
    default:
      return mol;
  }
};

export const env = getEnv();
