import {
  CREATE_EVENT,
  SET_EVENT_LIST,
  SET_EVENT_IN_EVENT,
  CREATE_DETECTION,
  UPDATE_DETECTION,
  SET_DETECTION_LIST,
  SET_AUDIT,
  GET_NOTIFICATION,
  SET_NOTIFICATION,
  CREATE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
} from "redux/constants/CyberKitSiem";

// EVENT
export const setEventList = (payload) => {
  return {
    type: SET_EVENT_LIST,
    payload: payload,
  };
};

export const setEventInEvent = (payload) => {
  return {
    type: SET_EVENT_IN_EVENT,
    payload: payload,
  };
};

export const createEvent = (payload) => {
  return {
    type: CREATE_EVENT,
    payload: payload,
  };
};

// DETECTION
export const setDetectionList = (payload) => {
  return {
    type: SET_DETECTION_LIST,
    payload: payload,
  };
};

export const createDetection = (payload) => {
  return {
    type: CREATE_DETECTION,
    payload: payload,
  };
};

export const updateDetection = (payload) => {
  return {
    type: UPDATE_DETECTION,
    payload: payload,
  };
};

// AUDIT
export const setAudit = (payload) => {
  return {
    type: SET_AUDIT,
    payload: payload,
  };
};

// NOTIFICATION
export const getNotification = (payload) => {
  return {
    type: GET_NOTIFICATION,
    payload: payload,
  };
};

export const setNotification = (payload) => {
  return {
    type: SET_NOTIFICATION,
    payload: payload,
  };
};

export const createNotification = (payload) => {
  return {
    type: CREATE_NOTIFICATION,
    payload: payload,
  };
};

export const updateNotification = (payload) => {
  return {
    type: UPDATE_NOTIFICATION,
    payload: payload,
  };
};

export const deleteNotification = (payload) => {
  return {
    type: DELETE_NOTIFICATION,
    payload: payload,
  };
};
