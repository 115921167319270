export default class archiveModel {

    constructor(data) {
        this.is = data.is;
        this.note = data.note? data.note: "";
        this.day = data.day? data.day: 0;
        this.create_by = data.create_by? data.create_by: "";
        this.create_at = data.create_at? data.create_at: "";
    }

    isArchive = () => this.is
    displayCreatedAt = () => this.create_at
}
