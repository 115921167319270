import React from "react";
import { Avatar, Popover } from "antd";
import { removeGlobalLS } from "util/LocalStorage";
import { useSelector } from "react-redux";
import { css } from "@emotion/css";
import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Ability } from "util/Ability/createContextualCan";

const UserInfo = () => {
  const userInfo = useSelector(({ auth }) => auth?.userInfo);
  const history = useHistory();
  const signOut = () => {
    removeGlobalLS("CYBERKIT");
    localStorage.removeItem("auth");
    sessionStorage.removeItem("domainKey");
    window.location.reload();
  };
  const { UserAttributes } = userInfo

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => history.push("/apps/setting")}>บัญชีของฉัน</li>
      <Ability I="access" a="apps.user_management">
        <li onClick={() => history.push("/apps/management")}>
          การจัดการองค์กร
        </li>
      </Ability>
      <li onClick={signOut}>ออกจากระบบ</li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <div
        className={css`
          display: flex;
          align-content: center;
          align-items: center;
          cursor: pointer;

          .ant-avatar {
            margin-right: 10px;
          }

          .anticon {
            margin-left: 10px;
          }

          color: #003366;
        `}
      >
        {UserAttributes.filter((data) => data.Name === "custom:profile_image")
          .length > 0 ? (
          <Avatar
            src={
              UserAttributes.filter(
                (data) => data.Name === "custom:profile_image"
              )[0].Value
            }
            className="gx-avatar gx-pointer gx-bg-white"
            alt=""
          />
        ) : (
          <Avatar
            src="/assets/images/default_profile.svg"
            className="gx-avatar gx-pointer gx-bg-white"
            alt=""
          />
        )}
        <DownOutlined />
      </div>
    </Popover>
  );
};

export default UserInfo;
