import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  SET_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
} from "../../constants/PrivacyKitConsentManagement";

import {
  listConsentManagementCustomPreferenceSuccess,
  getConsentManagementCustomPreferenceSuccess,
  createConsentManagementCustomPreferenceSuccess,
  updateConsentManagementCustomPreferenceSuccess,
  deleteConsentManagementCustomPreferenceSuccess,
  deleteConsentManagementCustomPreferenceError,
} from "../../actions/PrivacyKitConsentManagement";
import UniversalConsentAndPreferenceService from "services/pdpakit/UniversalConsentAndPreferenceService";
import Notification from "util/Notification";
import { selectDeleteCustomPreference } from "../../selectors/PrivacyKitConsentManagement";

export function* listConsentManagementCustomPreference() {
  yield takeEvery(LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE, function* () {
    try {
      const response = yield call(
        UniversalConsentAndPreferenceService.listCustomPreference
      );
      yield put(listConsentManagementCustomPreferenceSuccess(response.Items));
    } catch (error) {
      console.log(error);
    }
  });
}

export function* getConsentManagementCustomPreference() {
  yield takeEvery(
    GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
    function* ({ customPreferenceId }) {
      try {
        const response = yield call(
          UniversalConsentAndPreferenceService.getCustomPreference,
          customPreferenceId
        );
        let data = { ...response.Item };
        data.form_fields.options = data.form_fields.options.map(
          (option) => option.value
        );
        yield put(getConsentManagementCustomPreferenceSuccess(data));
      } catch (error) {
        console.log(error);
      }
    }
  );
}

const initialCustomPreference = {
  data_element_type: "multichoice",
  default_language: "th",
  translate: [],
  organizations: {},
  setting: [{ key: "ConfigureDataElementModalMarkAsPII", value: true }],
  form_fields: {
    key: "checkbox-group",
    widget: "checkbox-group",
    buttonGroup: true,
    forwardRef: true,
  },
};

export function* setManageConsentManagementCustomPreference() {
  yield takeEvery(
    SET_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
    function* ({ customPreferenceId, data, values }) {
      try {
        const { name, form_fields } = values;
        let payload = {
          ...initialCustomPreference,
          ...data,
          ObjectType: undefined,
          name,
          form_fields: {
            ...initialCustomPreference?.form_fields,
            ...data?.form_fields,
            label: name,
            options: form_fields.options.map((option) => ({
              label: option,
              value: option,
            })),
          },
        };

        if (customPreferenceId) {
          const response = yield call(
            UniversalConsentAndPreferenceService.updateCustomPreference,
            {
              customPreferenceId,
              data: payload,
            }
          );
          yield put(
            updateConsentManagementCustomPreferenceSuccess({
              customPreferenceId,
              data: response,
            })
          );
          Notification("success", "แก้ไขความยินยอมเรียบร้อยแล้ว");
        }
        if (!customPreferenceId) {
          const response = yield call(
            UniversalConsentAndPreferenceService.createCustomPreference,
            payload
          );
          yield put(createConsentManagementCustomPreferenceSuccess(response));
          Notification("success", "สร้างความยินยอมเรียบร้อยแล้ว");
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

export function* deleteConsentManagementCustomPreference() {
  yield takeEvery(DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE, function* () {
    try {
      const { data } = yield select(selectDeleteCustomPreference);
      yield call(
        UniversalConsentAndPreferenceService.deleteCustomPreference,
        data.ObjectUUID
      );
      yield put(
        deleteConsentManagementCustomPreferenceSuccess(data.ObjectUUID)
      );
      Notification("success", "ลบความยินยอมเรียบร้อยแล้ว");
    } catch (error) {
      yield put(deleteConsentManagementCustomPreferenceError());
      Notification("error", "เกิดข้อผิดพลาด ไม่สามารถลบความยินยอมได้");
    }
  });
}
