import {
  LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  TOGGLE_CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_MODAL,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  TOGGLE_PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  TOGGLE_DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE,
  CLEAR_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
} from "../../constants/PrivacyKitPrivacyAssessmentAutomation";
import { replaceObject } from "../../../util/arrayUtils";

const initialState = {
  list: null,
  data: null,
  loading: false,
  okButtonProps: {
    loading: false,
  },
  modalCreate: {
    visible: false,
    okButtonProps: {
      loading: false,
    },
  },
  modalPublish: {
    visible: false,
    okButtonProps: {
      loading: false,
    },
  },
  modalDelete: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
};

const RiskAssessment = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS: {
      return {
        ...state,
        list: action.list,
      };
    }
    case TOGGLE_CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_MODAL: {
      return {
        ...state,
        modalCreate: {
          ...initialState.modalCreate,
          visible: !state.modalCreate.visible,
        },
      };
    }
    case CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK: {
      return {
        ...state,
        modalCreate: {
          ...state.modalCreate,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS: {
      return {
        ...state,
        modalCreate: initialState.modalCreate,
        list: [action.data, ...state.list],
      };
    }
    case CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE: {
      return {
        ...state,
        modalCreate: {
          ...state.modalCreate,
          okButtonProps: {
            loading: false,
          },
        },
      };
    }
    case GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK: {
      return {
        ...state,
        okButtonProps: {
          loading: true,
        },
      };
    }
    case UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS: {
      return {
        ...state,
        okButtonProps: initialState.okButtonProps,
      };
    }
    case UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE: {
      return {
        ...state,
        okButtonProps: initialState,
      };
    }
    case TOGGLE_PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK: {
      return {
        ...state,
        modalPublish: {
          ...initialState.modalPublish,
          visible: !state.modalPublish.visible,
        },
      };
    }
    case PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK: {
      return {
        ...state,
        okButtonProps: {
          loading: true,
        },
      };
    }
    case PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS: {
      return {
        ...state,
        okButtonProps: initialState.okButtonProps,
        list: state.list
          ? [...replaceObject(state.list, action.data.ObjectUUID, action.data)]
          : null,
      };
    }
    case PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE: {
      return {
        ...state,
        okButtonProps: initialState,
      };
    }
    case TOGGLE_DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK: {
      return {
        ...state,
        modalDelete: {
          ...initialState.modalDelete,
          visible: !state.modalDelete.visible,
          data: action.data ?? null,
        },
      };
    }
    case DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK: {
      return {
        ...state,
        modalDelete: {
          ...state.modalDelete,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS: {
      return {
        ...state,
        modalDelete: initialState.modalDelete,
        list: state.list.filter((item) => item.ObjectUUID !== action.riskId),
      };
    }
    case DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE: {
      return {
        ...state,
        modalDelete: {
          ...state.modalDelete,
          okButtonProps: {
            loading: false,
          },
        },
      };
    }
    case CLEAR_PRIVACY_ASSESSMENT_AUTOMATION_RISK: {
      return {
        ...state,
        data: null,
      };
    }
    default:
      return state;
  }
};

export default RiskAssessment;
