import {
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_CREATE,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_TEMPLATE_SUCCESS,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR,
  TOGGLE_DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELDS_SUCCESS,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SETTING,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELD_DELETE,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM_TEXT,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM_STYLE,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR,
  TOGGLE_SELECT_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_WORKFLOW,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_WORKFLOW,
  TOGGLE_PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_SUCCESS,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_ERROR,
  TOGGLE_PREVIEW_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_SUCCESS,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SCRIPT,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_PREVIEW,
  RESET_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_DATA,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";
import _ from "lodash";
import { replaceObject } from "util/arrayUtils";

const initialState = {
  loading: false,
  listWebForm: null,
  webformTemplates: null,
  webformData: {
    form_fields: null,
  },
  modalDeleteField: {
    visible: false,
    name: null,
    data: null,
  },
  version: {
    loading: true,
    listVersion: null,
    visible: false,
    versionId: null,
    data: null,
  },
  getScript: {
    visible: false,
    data: null,
  },
  preview: {
    visible: false,
    data: null,
  },
  okButtonProps: {
    loading: false,
  },
  webformFields: {
    fields: null,
    key_fields: null,
  },
  settingWebForm: {
    visible: false,
    type: "DEFAULT",
    data: null,
  },
  createWebForm: {
    visible: false,
    okButtonProps: {
      loading: false,
    },
  },
  deleteWebForm: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
  publishWebform: {
    visible: false,
    okButtonProps: {
      loading: false,
    },
  },
  selectWorkflow: {
    visible: false,
  },
};

const WebFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_CREATE: {
      return {
        ...state,
        createWebForm: {
          ...initialState.createWebForm,
          visible: !state.createWebForm.visible,
        },
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_TEMPLATE_SUCCESS: {
      return {
        ...state,
        webformTemplates: action.payload,
      };
    }
    case CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM: {
      return {
        ...state,
        createWebForm: {
          ...state.createWebForm,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS: {
      return {
        ...state,
        createWebForm: initialState.createWebForm,
        listWebForm: state.listWebForm
          ? [action.payload, ...state.listWebForm]
          : null,
      };
    }
    case CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR: {
      return {
        ...state,
        createWebForm: {
          ...state.createWebForm,
          okButtonProps: initialState.createWebForm.okButtonProps,
        },
      };
    }
    case TOGGLE_DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM: {
      return {
        ...state,
        deleteWebForm: {
          ...initialState.deleteWebForm,
          data: action.payload ?? null,
          visible: !state.deleteWebForm.visible,
        },
      };
    }
    case DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM: {
      return {
        ...state,
        deleteWebForm: {
          ...state.deleteWebForm,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS: {
      return {
        ...state,
        deleteWebForm: initialState.deleteWebForm,
        listWebForm: _.filter(
          state.listWebForm,
          ({ ObjectUUID }) => ObjectUUID !== action.webFormId
        ),
      };
    }
    case DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR: {
      return {
        ...state,
        deleteWebForm: {
          ...state.deleteWebForm,
          okButtonProps: initialState.deleteWebForm.okButtonProps,
        },
      };
    }
    case GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM: {
      return {
        ...state,
        version: initialState.version,
        loading: true,
      };
    }
    case GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS: {
      return {
        ...state,
        loading: false,
        webformData: action.payload,
      };
    }
    case GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELDS_SUCCESS: {
      return {
        ...state,
        webformFields: action.payload,
      };
    }
    case TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SETTING: {
      return {
        ...state,
        settingWebForm: {
          visible: !state.settingWebForm.visible,
          type: action.settingType ?? initialState.settingWebForm.type,
          data: action.data ?? initialState.settingWebForm.data,
        },
      };
    }
    case TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELD_DELETE: {
      return {
        ...state,
        modalDeleteField: {
          visible: !state.modalDeleteField.visible,
          name: action?.payload?.label ?? null,
          data: action.payload ?? null,
        },
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM: {
      return {
        ...state,
        webformData: {
          ...state.webformData,
          form_fields: {
            ...state.webformData.form_fields,
            form: action.form,
          },
        },
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM_TEXT: {
      return {
        ...state,
        webformData: {
          ...state.webformData,
          form_text: action.formText,
        },
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM_STYLE: {
      return {
        ...state,
        webformData: {
          ...state.webformData,
          form_style: {
            ...state.webformData.form_style,
            ...action.formStyle,
          },
        },
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM: {
      return {
        ...state,
        okButtonProps: {
          ...state.okButtonProps,
          loading: true,
        },
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS: {
      return {
        ...state,
        okButtonProps: initialState.okButtonProps,
        publishWebform: initialState.publishWebform,
        listWebForm:
          state.listWebForm ??
          replaceObject(
            state.listWebForm,
            action.payload.ObjectUUID,
            action.payload
          ),
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR: {
      return {
        ...state,
        okButtonProps: initialState.okButtonProps,
      };
    }
    case TOGGLE_SELECT_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_WORKFLOW: {
      return {
        ...state,
        selectWorkflow: {
          visible: !state.selectWorkflow.visible,
        },
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_WORKFLOW: {
      return {
        ...state,
        selectWorkflow: initialState.selectWorkflow,
        webformData: {
          ...state.webformData,
          default: {
            ...state.default,
            workflow: action.workflowId,
          },
        },
      };
    }
    case TOGGLE_PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM: {
      return {
        ...state,
        publishWebform: {
          ...initialState.publishWebform,
          visible: !state.publishWebform.visible,
        },
      };
    }
    case PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM: {
      return {
        ...state,
        publishWebform: {
          ...state.publishWebform,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR: {
      return {
        ...state,
        publishWebform: initialState.publishWebform,
      };
    }
    case TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SCRIPT: {
      return {
        ...state,
        getScript: {
          ...state.getScript,
          visible: !state.getScript.visible,
          data: action.payload ?? null,
        },
      };
    }
    case TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_PREVIEW: {
      return {
        ...state,
        preview: {
          ...state.preview,
          visible: !state.preview.visible,
          data: action.payload ?? null,
        },
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_SUCCESS: {
      return {
        ...state,
        version: {
          ...state.version,
          listVersion: action.listVersion,
        },
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_ERROR: {
      return {
        ...state,
        version: {
          ...initialState.version,
          listVersion: [],
        },
      };
    }
    case TOGGLE_PREVIEW_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION: {
      return {
        ...state,
        version: {
          ...state.version,
          versionId: action.versionId ?? null,
          visible: !state.version.visible,
        },
      };
    }
    case GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION: {
      return {
        ...state,
        version: {
          ...state.version,
          loading: true,
          data: null,
        },
      };
    }
    case GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_SUCCESS: {
      return {
        ...state,
        version: {
          ...state.version,
          loading: false,
          data: action.data,
        },
      };
    }
    case RESET_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_DATA: {
      return {
        ...state,
        webformData: initialState.webformData,
        version: initialState.version,
      };
    }
    default: {
      return state;
    }
  }
};

export default WebFormReducer;
