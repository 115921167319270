export const SELECT_ASSESSMENT_TYPE = "SELECT_ASSESSMENT_TYPE";

//ASSESSMENT ACTIVE
export const RESET_ASSESSMENT_AUTOMATION_DATA =
  "RESET_ASSESSMENT_AUTOMATION_DATA";
export const LIST_ASSESSMENT_AUTOMATION = "LIST_ASSESSMENT_AUTOMATION";
export const SET_ASSESSMENT_AUTOMATION_DATA = "SET_ASSESSMENT_AUTOMATION_DATA";
export const UPDATED_ASSESSMENT_AUTOMATION_DATA =
  "UPDATED_ASSESSMENT_AUTOMATION_DATA";
export const CREATED_ASSESSMENT_AUTOMATION = "CREATED_ASSESSMENT_AUTOMATION";
export const CREATED_ASSESSMENT_AUTOMATION_SUCCESS =
  "CREATED_ASSESSMENT_AUTOMATION_SUCCESS";
export const GET_ASSESSMENT_AUTOMATION_DATA = "GET_ASSESSMENT_AUTOMATION_DATA";
export const GET_ASSESSMENT_AUTOMATION_DATA_SUCCESS =
  "GET_ASSESSMENT_AUTOMATION_DATA_SUCCESS";
export const UPDATE_ASSESSMENT_AUTOMATION = "UPDATE_ASSESSMENT_AUTOMATION";
export const UPDATE_ASSESSMENT_AUTOMATION_SUCCESS =
  "UPDATE_ASSESSMENT_AUTOMATION_SUCCESS";
export const PUBLISH_ASSESSMENT_AUTOMATION = "PUBLISH_ASSESSMENT_AUTOMATION";
export const PUBLISH_ASSESSMENT_AUTOMATION_SUCCESS =
  "PUBLISH_ASSESSMENT_AUTOMATION_SUCCESS";
export const DELETE_ASSESSMENT_AUTOMATION = "DELETE_ASSESSMENT_AUTOMATION";
export const DELETE_ASSESSMENT_AUTOMATION_SUCCESS =
  "DELETE_ASSESSMENT_AUTOMATION_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_FAILED =
  "LIST_ASSESSMENT_AUTOMATION_FAILED";
export const LIST_ASSESSMENT_AUTOMATION_ACTIVE =
  "LIST_ASSESSMENT_AUTOMATION_ACTIVE";
export const LIST_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_ACTIVE_FAILED =
  "LIST_ASSESSMENT_AUTOMATION_ACTIVE_FAILED";
export const GET_ASSESSMENT_AUTOMATION_ACTIVE =
  "GET_ASSESSMENT_AUTOMATION_ACTIVE";
export const GET_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS =
  "GET_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS";
export const GET_ASSESSMENT_AUTOMATION_ACTIVE_FAILED =
  "GET_ASSESSMENT_AUTOMATION_ACTIVE_FAILED";
export const CREATE_ASSESSMENT_AUTOMATION_ACTIVE =
  "CREATE_ASSESSMENT_AUTOMATION_ACTIVE";
export const CREATE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS =
  "CREATE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS";
export const CREATE_ASSESSMENT_AUTOMATION_ACTIVE_FAILED =
  "CREATE_ASSESSMENT_AUTOMATION_ACTIVE_FAILED";
export const ARCHIVED_ASSESSMENT_AUTOMATION_ACTIVE =
  "ARCHIVED_ASSESSMENT_AUTOMATION_ACTIVE";
export const ARCHIVED_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS =
  "ARCHIVED_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS";
export const ARCHIVED_ASSESSMENT_AUTOMATION_ACTIVE_FAILED =
  "ARCHIVED_ASSESSMENT_AUTOMATION_ACTIVE_FAILED";
export const UPDATE_ASSESSMENT_AUTOMATION_ACTIVE =
  "UPDATE_ASSESSMENT_AUTOMATION_ACTIVE";
export const UPDATE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS =
  "UPDATE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS";
export const UPDATE_ASSESSMENT_AUTOMATION_ACTIVE_FAILED =
  "UPDATE_ASSESSMENT_AUTOMATION_ACTIVE_FAILED";
export const DELETE_ASSESSMENT_AUTOMATION_ACTIVE =
  "DELETE_ASSESSMENT_AUTOMATION_ACTIVE";
export const DELETE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS =
  "DELETE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS";
export const DELETE_ASSESSMENT_AUTOMATION_ACTIVE_FAILED =
  "DELETE_ASSESSMENT_AUTOMATION_ACTIVE_FAILED";

//ASSESSMENT ARCHIVED
export const LIST_ASSESSMENT_AUTOMATION_ARCHIVED =
  "LIST_ASSESSMENT_AUTOMATION_ARCHIVED";
export const LIST_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_ARCHIVED_FAILED =
  "LIST_ASSESSMENT_AUTOMATION_ARCHIVED_FAILED";
export const ACTIVATED_ASSESSMENT_AUTOMATION_ARCHIVED =
  "ACTIVATED_ASSESSMENT_AUTOMATION_ARCHIVED";
export const ACTIVATED_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS =
  "ACTIVATED_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS";
export const ACTIVATED_ASSESSMENT_AUTOMATION_ARCHIVED_FAILED =
  "ACTIVATED_ASSESSMENT_AUTOMATION_ARCHIVED_FAILED";
export const DELETE_ASSESSMENT_AUTOMATION_ARCHIVED =
  "DELETE_ASSESSMENT_AUTOMATION_ARCHIVED";
export const DELETE_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS =
  "DELETE_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS";
export const DELETE_ASSESSMENT_AUTOMATION_ARCHIVED_FAILED =
  "DELETE_ASSESSMENT_AUTOMATION_ARCHIVED_FAILED";

//ASSESSMENT DELETED
export const LIST_ASSESSMENT_AUTOMATION_DELETED =
  "LIST_ASSESSMENT_AUTOMATION_DELETED";
export const LIST_ASSESSMENT_AUTOMATION_DELETED_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_DELETED_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_DELETED_FAILED =
  "LIST_ASSESSMENT_AUTOMATION_DELETED_FAILED";
export const ACTIVATED_ASSESSMENT_AUTOMATION_DELETED =
  "ACTIVATED_ASSESSMENT_AUTOMATION_DELETED";
export const ACTIVATED_ASSESSMENT_AUTOMATION_DELETED_SUCCESS =
  "ACTIVATED_ASSESSMENT_AUTOMATION_DELETED_SUCCESS";
export const ACTIVATED_ASSESSMENT_AUTOMATION_DELETED_FAILED =
  "ACTIVATED_ASSESSMENT_AUTOMATION_DELETED_FAILED";
export const DELETE_ASSESSMENT_AUTOMATION_DELETED =
  "DELETE_ASSESSMENT_AUTOMATION_DELETED";
export const DELETE_ASSESSMENT_AUTOMATION_DELETED_SUCCESS =
  "DELETE_ASSESSMENT_AUTOMATION_DELETED_SUCCESS";
export const DELETE_ASSESSMENT_AUTOMATION_DELETED_FAILED =
  "DELETE_ASSESSMENT_AUTOMATION_DELETED_FAILED";

//ASSESSMENT RISK
export const LIST_ASSESSMENT_AUTOMATION_RISK =
  "LIST_ASSESSMENT_AUTOMATION_RISK";
export const LIST_ASSESSMENT_AUTOMATION_RISK_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_RISK_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_RISK_FAILED =
  "LIST_ASSESSMENT_AUTOMATION_RISK_FAILED";
export const CREATE_ASSESSMENT_AUTOMATION_RISK =
  "CREATE_ASSESSMENT_AUTOMATION_RISK";
export const CREATE_ASSESSMENT_AUTOMATION_RISK_SUCCESS =
  "CREATE_ASSESSMENT_AUTOMATION_RISK_SUCCESS";
export const CREATE_ASSESSMENT_AUTOMATION_RISK_FAILED =
  "CREATE_ASSESSMENT_AUTOMATION_RISK_FAILED";
export const UPDATE_ASSESSMENT_AUTOMATION_RISK =
  "UPDATE_ASSESSMENT_AUTOMATION_RISK";
export const UPDATE_ASSESSMENT_AUTOMATION_RISK_SUCCESS =
  "UPDATE_ASSESSMENT_AUTOMATION_RISK_SUCCESS";
export const UPDATE_ASSESSMENT_AUTOMATION_RISK_FAILED =
  "UPDATE_ASSESSMENT_AUTOMATION_RISK_FAILED";
export const DELETE_ASSESSMENT_AUTOMATION_RISK =
  "DELETE_ASSESSMENT_AUTOMATION_RISK";
export const DELETE_ASSESSMENT_AUTOMATION_RISK_SUCCESS =
  "DELETE_ASSESSMENT_AUTOMATION_RISK_SUCCESS";
export const DELETE_ASSESSMENT_AUTOMATION_RISK_FAILED =
  "DELETE_ASSESSMENT_AUTOMATION_RISK_FAILED";

export const LIST_ASSESSMENT_AUTOMATION_TEMPLATE =
  "LIST_ASSESSMENT_AUTOMATION_TEMPLATE";
export const LIST_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_TEMPLATE_FAILED =
  "LIST_ASSESSMENT_AUTOMATION_TEMPLATE_FAILED";
export const GET_ASSESSMENT_AUTOMATION_TEMPLATE_BY_TYPE =
  "GET_ASSESSMENT_AUTOMATION_TEMPLATE_BY_TYPE";
export const GET_ASSESSMENT_AUTOMATION_TEMPLATE_BY_TYPE_SUCCESS =
  "GET_ASSESSMENT_AUTOMATION_TEMPLATE_BY_TYPE_SUCCESS";
export const GET_ASSESSMENT_AUTOMATION_TEMPLATE_TYPE =
  "GET_ASSESSMENT_AUTOMATION_TEMPLATE_TYPE";
export const GET_ASSESSMENT_AUTOMATION_TEMPLATE_TYPE_SUCCESS =
  "GET_ASSESSMENT_AUTOMATION_TEMPLATE_TYPE_SUCCESS";
export const CREATE_ASSESSMENT_AUTOMATION_TEMPLATE =
  "CREATE_ASSESSMENT_AUTOMATION_TEMPLATE";
export const CREATE_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS =
  "CREATE_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS";
export const CREATE_ASSESSMENT_AUTOMATION_TEMPLATE_FAILED =
  "CREATE_ASSESSMENT_AUTOMATION_TEMPLATE_FAILED";
export const DELETE_ASSESSMENT_AUTOMATION_TEMPLATE =
  "DELETE_ASSESSMENT_AUTOMATION_TEMPLATE";
export const DELETE_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS =
  "DELETE_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS";
export const DELETE_ASSESSMENT_AUTOMATION_TEMPLATE_FAILED =
  "DELETE_ASSESSMENT_AUTOMATION_TEMPLATE_FAILED";

export const CLEAR_ASSESSMENT_AUTOMATION_PROCESS =
  "CLEAR_ASSESSMENT_AUTOMATION_PROCESS";
export const LIST_ASSESSMENT_AUTOMATION_PROCESS =
  "LIST_ASSESSMENT_AUTOMATION_PROCESS";
export const LIST_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_PROCESS_FAILED =
  "LIST_ASSESSMENT_AUTOMATION_PROCESS_FAILED";
export const CREATE_ASSESSMENT_AUTOMATION_PROCESS =
  "CREATE_ASSESSMENT_AUTOMATION_PROCESS";
export const CREATE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS =
  "CREATE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS";
export const CREATE_ASSESSMENT_AUTOMATION_PROCESS_FAILED =
  "CREATE_ASSESSMENT_AUTOMATION_PROCESS_FAILED";
export const GET_ASSESSMENT_AUTOMATION_PROCESS =
  "GET_ASSESSMENT_AUTOMATION_PROCESS";
export const GET_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS =
  "GET_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS";
export const GET_ASSESSMENT_AUTOMATION_PROCESS_FAILED =
  "GET_ASSESSMENT_AUTOMATION_PROCESS_FAILED";
export const UPDATE_ASSESSMENT_AUTOMATION_PROCESS =
  "UPDATE_ASSESSMENT_AUTOMATION_PROCESS";
export const UPDATE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS =
  "UPDATE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS";
export const UPDATE_ASSESSMENT_AUTOMATION_PROCESS_FAILED =
  "UPDATE_ASSESSMENT_AUTOMATION_PROCESS_FAILED";
export const DELETE_ASSESSMENT_AUTOMATION_PROCESS =
  "DELETE_ASSESSMENT_AUTOMATION_PROCESS";
export const DELETE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS =
  "DELETE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS";
export const DELETE_ASSESSMENT_AUTOMATION_PROCESS_FAILED =
  "DELETE_ASSESSMENT_AUTOMATION_PROCESS_FAILED";

//ASSESSMENT RESULT
export const LIST_ASSESSMENT_AUTOMATION_RESULT =
  "LIST_ASSESSMENT_AUTOMATION_RESULT";
export const LIST_ASSESSMENT_AUTOMATION_RESULT_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_RESULT_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_RESULT_FAILED =
  "LIST_ASSESSMENT_AUTOMATION_RESULT_FAILED";
export const CREATE_ASSESSMENT_AUTOMATION_RESULT =
  "CREATE_ASSESSMENT_AUTOMATION_RESULT";
export const CREATE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS =
  "CREATE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS";
export const CREATE_ASSESSMENT_AUTOMATION_RESULT_FAILED =
  "CREATE_ASSESSMENT_AUTOMATION_RESULT_FAILED";
export const UPDATE_ASSESSMENT_AUTOMATION_RESULT =
  "UPDATE_ASSESSMENT_AUTOMATION_RESULT";
export const UPDATE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS =
  "UPDATE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS";
export const UPDATE_ASSESSMENT_AUTOMATION_RESULT_FAILED =
  "UPDATE_ASSESSMENT_AUTOMATION_RESULT_FAILED";
export const DELETE_ASSESSMENT_AUTOMATION_RESULT =
  "DELETE_ASSESSMENT_AUTOMATION_RESULT";
export const DELETE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS =
  "DELETE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS";
export const DELETE_ASSESSMENT_AUTOMATION_RESULT_FAILED =
  "DELETE_ASSESSMENT_AUTOMATION_RESULT_FAILED";

//ASSESSMENT RULES
export const LIST_ASSESSMENT_AUTOMATION_RULES =
  "LIST_ASSESSMENT_AUTOMATION_RULES";
export const LIST_ASSESSMENT_AUTOMATION_RULES_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_RULES_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_RULES_FAILED =
  "LIST_ASSESSMENT_AUTOMATION_RULES_FAILED";
export const CREATE_ASSESSMENT_AUTOMATION_RULES =
  "CREATE_ASSESSMENT_AUTOMATION_RULES";
export const CREATE_ASSESSMENT_AUTOMATION_RULES_SUCCESS =
  "CREATE_ASSESSMENT_AUTOMATION_RULES_SUCCESS";
export const CREATE_ASSESSMENT_AUTOMATION_RULES_FAILED =
  "CREATE_ASSESSMENT_AUTOMATION_RULES_FAILED";
export const UPDATE_ASSESSMENT_AUTOMATION_RULES =
  "UPDATE_ASSESSMENT_AUTOMATION_RULES";
export const UPDATE_ASSESSMENT_AUTOMATION_RULES_SUCCESS =
  "UPDATE_ASSESSMENT_AUTOMATION_RULES_SUCCESS";
export const UPDATE_ASSESSMENT_AUTOMATION_RULES_FAILED =
  "UPDATE_ASSESSMENT_AUTOMATION_RULES_FAILED";
export const DELETE_ASSESSMENT_AUTOMATION_RULES =
  "DELETE_ASSESSMENT_AUTOMATION_RULES";
export const DELETE_ASSESSMENT_AUTOMATION_RULES_SUCCESS =
  "DELETE_ASSESSMENT_AUTOMATION_RULES_SUCCESS";
export const DELETE_ASSESSMENT_AUTOMATION_RULES_FAILED =
  "DELETE_ASSESSMENT_AUTOMATION_RULES_FAILED";

//ASSESSMENT SENT
export const LIST_ASSESSMENT_AUTOMATION_NOT_SENT =
  "LIST_ASSESSMENT_AUTOMATION_NOT_SENT";
export const LIST_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED =
  "LIST_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED";
export const CREATE_ASSESSMENT_AUTOMATION_NOT_SENT =
  "CREATE_ASSESSMENT_AUTOMATION_NOT_SENT";
export const CREATE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS =
  "CREATE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS";
export const CREATE_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED =
  "CREATE_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED";
export const UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT =
  "UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT";
export const UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS =
  "UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS";
export const UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED =
  "UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED";
export const DELETE_ASSESSMENT_AUTOMATION_NOT_SENT =
  "DELETE_ASSESSMENT_AUTOMATION_NOT_SENT";
export const DELETE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS =
  "DELETE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS";
export const DELETE_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED =
  "DELETE_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED";

export const UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT =
  "UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT";
export const UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT_SUCCESS =
  "UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT_SUCCESS";
export const UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT_FAILED =
  "UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT_FAILED";

export const LIST_ASSESSMENT_AUTOMATION_SENT =
  "LIST_ASSESSMENT_AUTOMATION_SENT";
export const LIST_ASSESSMENT_AUTOMATION_SENT_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_SENT_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_SENT_FAILED =
  "LIST_ASSESSMENT_AUTOMATION_SENT_FAILED";
export const LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE =
  "LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE";
export const LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE_ERROR =
  "LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE_ERROR";
export const LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE =
  "LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE";
export const LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE_ERROR =
  "LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE_ERROR";

export const GET_ASSESSMENT_AUTOMATION_REPORT =
  "GET_ASSESSMENT_AUTOMATION_REPORT";
export const GET_ASSESSMENT_AUTOMATION_REPORT_SUCCESS =
  "GET_ASSESSMENT_AUTOMATION_REPORT_SUCCESS";

export const GET_ASSESSMENT_AUTOMATION_REPORT_DATA =
  "GET_ASSESSMENT_AUTOMATION_REPORT_DATA";
export const GET_ASSESSMENT_AUTOMATION_REPORT_DATA_SUCCESS =
  "GET_ASSESSMENT_AUTOMATION_REPORT_DATA_SUCCESS";

export const LIST_ASSESSMENT_AUTOMATION_SUBMIT_REPORT =
  "LIST_ASSESSMENT_AUTOMATION_SUBMIT_REPORT";
export const LIST_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_SUCCESS";
export const GET_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_DATA =
  "GET_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_DATA";
export const GET_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_DATA_SUCCESS =
  "GET_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_DATA_SUCCESS";

export const LIST_ASSESSMENT_AUTOMATION_TASK =
  "LIST_ASSESSMENT_AUTOMATION_TASK";
export const LIST_ASSESSMENT_AUTOMATION_TASK_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_TASK_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE =
  "LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE";
export const LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE_SUCCESS";
export const LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE_ERROR =
  "LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE_ERROR";
export const LIST_ASSESSMENT_AUTOMATION_TASK_IN_ASSESSMENT =
  "LIST_ASSESSMENT_AUTOMATION_TASK_IN_ASSESSMENT";
export const LIST_ASSESSMENT_AUTOMATION_TASK_IN_ASSESSMENT_SUCCESS =
  "LIST_ASSESSMENT_AUTOMATION_TASK_IN_ASSESSMENT_SUCCESS";
export const CREATE_ASSESSMENT_AUTOMATION_TASK =
  "CREATE_ASSESSMENT_AUTOMATION_TASK";
export const CREATE_ASSESSMENT_AUTOMATION_TASK_SUCCESS =
  "CREATE_ASSESSMENT_AUTOMATION_TASK_SUCCESS";
export const UPDATE_ASSESSMENT_AUTOMATION_TASK =
  "UPDATE_ASSESSMENT_AUTOMATION_TASK";
export const UPDATE_ASSESSMENT_AUTOMATION_TASK_SUCCESS =
  "UPDATE_ASSESSMENT_AUTOMATION_TASK_SUCCESS";
export const DELETE_ASSESSMENT_AUTOMATION_TASK =
  "DELETE_ASSESSMENT_AUTOMATION_TASK";
export const DELETE_ASSESSMENT_AUTOMATION_TASK_SUCCESS =
  "DELETE_ASSESSMENT_AUTOMATION_TASK_SUCCESS";

export const CHANGE_ASSESSMENT_AUTOMATION_FORM_ASSESSMENT_FIELDS =
  "CHANGE_ASSESSMENT_AUTOMATION_FORM_ASSESSMENT_FIELDS";

export const UPDATE_ASSESSMENT_AUTOMATION_DATA_INVENTORY =
  "UPDATE_ASSESSMENT_AUTOMATION_DATA_INVENTORY";
export const GET_ASSESSMENT_AUTOMATION_DATA_INVENTORY =
  "GET_ASSESSMENT_AUTOMATION_DATA_INVENTORY";
export const GET_ASSESSMENT_AUTOMATION_DATA_INVENTORY_SUCCESS =
  "UPDATE_ASSESSMENT_AUTOMATION_DATA_INVENTORY";
export const GET_ASSESSMENT_AUTOMATION_DATA_INVENTORY_ERROR =
  "GET_ASSESSMENT_AUTOMATION_DATA_INVENTORY_ERROR";

export const ADD_ASSESSMENT_CONTROL = "ADD_ASSESSMENT_CONTROL";
export const ADD_ASSESSMENT_CONTROL_SUCCESS = "ADD_ASSESSMENT_CONTROL_SUCCESS";
export const ADD_ASSESSMENT_CONTROL_FAILED = "ADD_ASSESSMENT_CONTROL_FAILED";

export const GET_ASSESSMENT_CONTROL = "GET_ASSESSMENT_CONTROL";
export const GET_ASSESSMENT_CONTROL_SUCCESS = "GET_ASSESSMENT_CONTROL_SUCCESS";
export const GET_ASSESSMENT_CONTROL_FAILED = "GET_ASSESSMENT_CONTROL_FAILED";

export const DELETE_ASSESSMENT_CONTROL = "DELETE_ASSESSMENT_CONTROL";
export const DELETE_ASSESSMENT_CONTROL_SUCCESS =
  "DELETE_ASSESSMENT_CONTROL_SUCCESS";
export const DELETE_ASSESSMENT_CONTROL_FAILED =
  "DELETE_ASSESSMENT_CONTROL_FAILED";
