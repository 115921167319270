import { Redirect, Route } from "react-router-dom";
import { ENTRY_ROUTE } from "config/AppConfig";
import PropsTypes from "prop-types";

function PrivateRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      exact
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ENTRY_ROUTE,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  isAuthenticated: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.bool]),
};

export default PrivateRoute;
