import {
  PrivacyKitPath,
  PrivacyKitIncidentResponseManagementPath,
} from "../AppModuleConfig";

const PrivacyKitIncidentResponseManagement = [
  {
    key: "privacykit-incident-response-management-template",
    path: `${PrivacyKitPath}${PrivacyKitIncidentResponseManagementPath}/template`,
    title: "แม่แบบ",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: "privacykit-incident-response-management-setting",
    path: `${PrivacyKitPath}${PrivacyKitIncidentResponseManagementPath}/setting`,
    title: "การตั้งค่า",
    breadcrumb: false,
    icon: null,
    submenu: [
      {
        key: "privacykit-incident-response-management-setting-event-stage",
        path: `${PrivacyKitPath}${PrivacyKitIncidentResponseManagementPath}/setting/event-stage`,
        title: "ขั้นตอนเหตุการณ์",
        breadcrumb: false,
        icon: null,
        submenu: [],
      },
      {
        key: "privacykit-incident-response-management-setting-event-type",
        path: `${PrivacyKitPath}${PrivacyKitIncidentResponseManagementPath}/setting/event-type`,
        title: "ประเภทเหตุการณ์",
        breadcrumb: false,
        icon: null,
        submenu: [],
      },
      {
        key: "privacykit-incident-response-management-setting-webform",
        path: `${PrivacyKitPath}${PrivacyKitIncidentResponseManagementPath}/setting/webform`,
        title: "เว็บฟอร์ม",
        breadcrumb: false,
        icon: null,
        submenu: [],
      },
      {
        key: "privacykit-incident-response-management-setting-attribute-management",
        path: `${PrivacyKitPath}${PrivacyKitIncidentResponseManagementPath}/setting/attribute-management`,
        title: "ตัวจัดการแอทตทริบิวต์",
        breadcrumb: false,
        icon: null,
        submenu: [],
      },
    ],
  },
];

export default PrivacyKitIncidentResponseManagement;
