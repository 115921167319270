import {
  PrivacyKitPath,
  PrivacyKitPrivacyAssessmentAutomationPath,
} from "../AppModuleConfig";

const PrivacyKitPrivacyAssessmentAutomation = [
  // {
  //   key: `privacykit-${assessmentType}-assessment-automation-dashboard`,
  //   path: `${PrivacyKitPath}${PrivacyKitAssessmentAutomationPath}${assessmentType}/dashboard`,
  //   title: "แดชบอร์ด",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
  {
    key: `privacykit-privacy-assessment-automation-assessment`,
    path: `${PrivacyKitPath}${PrivacyKitPrivacyAssessmentAutomationPath}/assessment`,
    title: "แบบการประเมิน",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `privacykit-privacy-assessment-automation-dashboard`,
    path: `${PrivacyKitPath}${PrivacyKitPrivacyAssessmentAutomationPath}/dashboard`,
    title: "แดชบอร์ด",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  // {
  //   key: `privacykit-${assessmentType}-assessment-automation-submission-list`,
  //   path: `${PrivacyKitPath}${PrivacyKitAssessmentAutomationPath}${assessmentType}/submission-list`,
  //   title: "รายการส่งแบบประเมิน",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
  // {
  //   key: `privacykit-${assessmentType}-assessment-automation-task`,
  //   path: `${PrivacyKitPath}${PrivacyKitAssessmentAutomationPath}${assessmentType}/task`,
  //   title: "งาน",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
];

export default PrivacyKitPrivacyAssessmentAutomation;
