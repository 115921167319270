export const selectDataSubjectRightManagementWebForm = (state) =>
  state.privacykitDataSubjectRightManagement.webform;

export const selectDataSubjectRightManagementWebFormData = (state) =>
  state.privacykitDataSubjectRightManagement.webform.webformData;

export const selectDataSubjectRightManagementWebFormFields = (state) =>
  state.privacykitDataSubjectRightManagement.webform.webformFields;

export const selectDataSubjectRightManagementWebFormCreate = (state) =>
  state.privacykitDataSubjectRightManagement.webform.createWebForm;

export const selectDataSubjectRightManagementWebFormDelete = (state) =>
  state.privacykitDataSubjectRightManagement.webform.deleteWebForm;

export const selectDataSubjectRightManagementWebFormSelectWorkflow = (state) =>
  state.privacykitDataSubjectRightManagement.webform.selectWorkflow;

export const selectDataSubjectRightManagementWebFormDeleteField = (state) =>
  state.privacykitDataSubjectRightManagement.webform.modalDeleteField;

export const selectDataSubjectRightManagementWebFormSelectPublishWebform = (
  state
) => state.privacykitDataSubjectRightManagement.webform.publishWebform;

export const selectDataSubjectRightManagementWebFormVersion = (state) =>
  state.privacykitDataSubjectRightManagement.webform.version;

export const selectDataSubjectRightManagementWebFormGetScript = (state) =>
  state.privacykitDataSubjectRightManagement.webform.getScript;

export const selectDataSubjectRightManagementWebFormPreview = (state) =>
  state.privacykitDataSubjectRightManagement.webform.preview;
