import { saveToLS, getFromLS } from "util/LocalStorage";
import {
  SET_ALL_EVENTS,
  SET_FOLLOW_EVENTS,
  SET_PRIVATE_EVENTS,
  SET_FEED,
  SET_EVENT_DETAIL,
  SET_ATTRIBUTES,
} from "redux/constants/CyberKitThreatIntelligence";

const CYBERKIT = "CYBERKIT";
const CYBERKIT_THREAT_INTELLIGENCE = "CYBERKIT_THREAT_INTELLIGENCE";

const initState = {
  event: {
    all: {
      dataList: [],
    },
    follow: {
      dataList: [],
      feed: [],
      private: {
        dataList: [],
      },
    },
    eventDetail: {
      data: {},
      urlList: [],
      attributes: [],
    },
  },
};

const CyberKit = (
  state = getFromLS(CYBERKIT, CYBERKIT_THREAT_INTELLIGENCE)
    ? getFromLS(CYBERKIT, CYBERKIT_THREAT_INTELLIGENCE)
    : initState,
  action
) => {
  const { type, payload } = action;
  let changed = state;

  switch (type) {
    case SET_ALL_EVENTS:
      changed = {
        ...state,
        event: {
          ...state.event,
          all: {
            ...state.event.all,
            dataList: payload,
          },
        },
      };
      break;

    case SET_FOLLOW_EVENTS:
      changed = {
        ...state,
        event: {
          ...state.event,
          follow: {
            ...state.event.follow,
            dataList: payload,
          },
        },
      };
      break;

    case SET_PRIVATE_EVENTS:
      changed = {
        ...state,
        event: {
          ...state.event,
          follow: {
            ...state.event.follow,
            private: {
              ...state.event.follow.private,
              dataList: payload,
            },
          },
        },
      };
      break;

    case SET_FEED:
      changed = {
        ...state,
        event: {
          ...state.event,
          follow: {
            ...state.event.follow,
            feed: payload,
          },
        },
      };
      break;

    case SET_EVENT_DETAIL:
      changed = {
        ...state,
        event: {
          ...state.event,
          eventDetail: {
            ...state.event.eventDetail,
            data: payload.data,
          },
        },
      };
      break;

    case SET_ATTRIBUTES:
      changed = {
        ...state,
        event: {
          ...state.event,
          eventDetail: {
            ...state.event.eventDetail,
            attributes: payload,
          },
        },
      };
      break;

    default:
      break;
  }

  if (localStorage.getItem("auth") !== null)
    saveToLS(CYBERKIT, CYBERKIT_THREAT_INTELLIGENCE, changed);
  return changed;
};

export default CyberKit;
