import {
  GET_POLICY_AND_NOTICE_MANAGEMENT_DASHBOARD_SUCCESS,
  LIST_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
  LIST_POLICY_AND_NOTICE_MANAGEMENT_FAILED,
  SET_POLICY_AND_NOTICE_MANAGEMENT_INIT_CREATE,
  GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_SUCCESS,
  GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_DATA_SUCCESS,
  GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_BUILDER_SUCCESS,
  CREATE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
  GET_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
  UPDATE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
  PUBLISH_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
  GET_POLICY_AND_NOTICE_MANAGEMENT_VERSION_SUCCESS,
  DELETE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS,
  LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK_SUCCESS,
  LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
  GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
  CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
  DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS,
  RESET_POLICY_AND_NOTICE_MANAGEMENT_DATA,
} from "redux/constants/PrivacyKitPolicyAndNoticeManagement";
import _ from "lodash";

const initState = {};

const updateListPolicy = (state, action) => {
  if (state.listPolicy) {
    let updateListPolicy = [...state.listPolicy.Items];
    const updateIndex = _.findIndex(updateListPolicy, {
      ObjectUUID: action.payload.ObjectUUID,
    });
    updateListPolicy[updateIndex] = { ...action.payload };
    return {
      ...state.listPolicy,
      Items: updateListPolicy,
    };
  }
  return undefined;
};

const PrivacyKitPolicyAndNoticeManagement = (state = initState, action) => {
  switch (action.type) {
    case GET_POLICY_AND_NOTICE_MANAGEMENT_DASHBOARD_SUCCESS: {
      return {
        ...state,
        dashboard: action.payload,
      };
    }
    case LIST_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        policyData: undefined,
        listPolicy: action.payload,
      };
    }
    case LIST_POLICY_AND_NOTICE_MANAGEMENT_FAILED: {
      return state;
    }
    case SET_POLICY_AND_NOTICE_MANAGEMENT_INIT_CREATE: {
      return {
        ...state,
        policyData: action.payload,
      };
    }
    case GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        templatePolicy: action.payload,
      };
    }
    case GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_DATA_SUCCESS: {
      return {
        ...state,
        policyData: {
          ...state.policyData,
          builder: {
            ...state.policyData.builder,
            ...action.payload,
          },
        },
      };
    }
    case GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_BUILDER_SUCCESS: {
      return {
        ...state,
        policyData: {
          ...state.policyData,
          builder: {
            ...state.policyData.builder,
            form_sections: [...action.payload],
          },
        },
      };
    }
    case CREATE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS: {
      if (!state.listPolicy) {
        return state;
      }
      return {
        ...state,
        listPolicy: {
          Items: [action.payload, ...state.listPolicy.Items],
        },
      };
    }
    case GET_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        policyData: action.payload,
      };
    }
    case UPDATE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS: {
      const listPolicy = updateListPolicy(state, action);
      return {
        ...state,
        listPolicy,
        policyData: { ...state.policyData, ...action.payload },
      };
    }
    case PUBLISH_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS: {
      const listPolicy = updateListPolicy(state, action);
      return {
        ...state,
        listPolicy,
        policyData: { ...state.policyData, ...action.payload },
        listPolicyVersion: state.listPolicyVersion
          ? {
              ...state.listPolicyVersion,
              Items: [action.payload, ...state.listPolicyVersion.Items],
            }
          : undefined,
      };
    }
    case GET_POLICY_AND_NOTICE_MANAGEMENT_VERSION_SUCCESS: {
      return {
        ...state,
        listPolicyVersion: action.payload,
      };
    }
    case DELETE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        listPolicy: {
          ...state.listPolicy,
          Items: [
            ..._.filter(
              state.listPolicy.Items,
              ({ ObjectUUID }) => ObjectUUID !== action.payload
            ),
          ],
        },
      };
    }
    case LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK_SUCCESS: {
      return {
        ...state,
        listTask: action.payload,
      };
    }
    case LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        listAssessment: action.payload,
      };
    }
    case GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        assessmentData: action.payload,
      };
    }
    case CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS: {
      if (!state.listAssessment) {
        return state;
      }
      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          Item: [action.payload, ...state.listAssessment.Item],
        },
      };
    }
    case DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          Item: [
            ..._.filter(
              state.listAssessment.Item,
              ({ ObjectUUID }) => ObjectUUID !== action.payload
            ),
          ],
        },
      };
    }
    case RESET_POLICY_AND_NOTICE_MANAGEMENT_DATA: {
      return {
        ...state,
        policyData: undefined,
        listPolicyVersion: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export default PrivacyKitPolicyAndNoticeManagement;
