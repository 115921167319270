import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { message } from "antd";
import {
  LIST_INCIDENT_RESPONSE_NOTIFICATION,
  CREATE_INCIDENT_RESPONSE_NOTIFICATION,
  GET_INCIDENT_RESPONSE_NOTIFICATION,
  UPDATE_INCIDENT_RESPONSE_NOTIFICATION,
  UPDATE_INCIDENT_RESPONSE_NOTIFICATION_STATUS,
  DELETE_INCIDENT_RESPONSE_NOTIFICATION,
  LIST_INCIDENT_RESPONSE_PLAN,
  GET_INCIDENT_RESPONSE_PLAN,
  CREATE_INCIDENT_RESPONSE_PLAN,
  UPDATE_INCIDENT_RESPONSE_PLAN,
  PUBLISH_INCIDENT_RESPONSE_PLAN,
  DELETE_INCIDENT_RESPONSE_PLAN,
  UPDATE_INCIDENT_RESPONSE_PLAN_STAGE,
  LIST_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN,
  CREATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN,
  GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN,
  UPDATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN,
  DELETE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN,
  LIST_INCIDENT_RESPONSE_WEBFORM,
  GET_INCIDENT_RESPONSE_WEBFORM,
  CREATE_INCIDENT_RESPONSE_WEBFORM,
  UPDATE_INCIDENT_RESPONSE_WEBFORM,
  PUBLISH_INCIDENT_RESPONSE_WEBFORM,
  DELETE_INCIDENT_RESPONSE_WEBFORM,
  LIST_INCIDENT_RESPONSE_WEBFORM_VERSION,
  GET_INCIDENT_RESPONSE_WEBFORM_VERSION,
  GET_INCIDENT_RESPONSE_WEBFORM_WEBFORM_FIELDS,
  LIST_INCIDENT_RESPONSE_CASE,
  CREATE_INCIDENT_RESPONSE_CASE,
  GET_INCIDENT_RESPONSE_CASE,
  UPDATE_INCIDENT_RESPONSE_CASE,
  DELETE_INCIDENT_RESPONSE_CASE,
  LIST_INCIDENT_RESPONSE_TASK_IN_CASE,
  CREATE_INCIDENT_RESPONSE_TASK_IN_CASE,
  GET_INCIDENT_RESPONSE_TASK_IN_CASE,
  UPDATE_INCIDENT_RESPONSE_TASK_IN_CASE,
  DELETE_INCIDENT_RESPONSE_TASK_IN_CASE,
  LIST_INCIDENT_RESPONSE_CASE_COMMENT,
  CREATE_INCIDENT_RESPONSE_CASE_COMMENT,
  LIST_INCIDENT_RESPONSE_CASE_HISTORY,
  LIST_INCIDENT_RESPONSE_CASE_HIGH,
  SENT_INCIDENT_RESPONSE_CASE_HIGH,
  GET_INCIDENT_RESPONSE_CASE_HIGH_TEMPLATE,
  LIST_INCIDENT_RESPONSE_TASK_ALL,
  GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE,
  UPDATE_INCIDENT_RESPONSE_CASE_STAGE,
  PUBLISH_INCIDENT_RESPONSE_CASE,
} from "redux/constants/PrivacyKitDataBreachNotification";
import {
  listIncidentResponseNotificationSuccess,
  createIncidentResponseNotificationSuccess,
  getIncidentResponseNotificationSuccess,
  updateIncidentResponseNotificationSuccess,
  updateIncidentResponseNotificationStatusSuccess,
  deleteIncidentResponseNotificationSuccess,
  listIncidentResponsePlanSuccess,
  getIncidentResponsePlanSuccess,
  createIncidentResponsePlanSuccess,
  updateIncidentResponsePlanSuccess,
  publishIncidentResponsePlanSuccess,
  deleteIncidentResponsePlanSuccess,
  updateIncidentResponsePlanStageSuccess,
  listTaskInResponsePlanSuccess,
  createTaskInResponsePlanSuccess,
  getTaskInResponsePlanSuccess,
  updateTaskInResponsePlanSuccess,
  deleteTaskInResponsePlanSuccess,
  listIncidentResponseWebformSuccess,
  getIncidentResponseWebformSuccess,
  createIncidentResponseWebformSuccess,
  updateIncidentResponseWebformSuccess,
  publishIncidentResponseWebformSuccess,
  deleteIncidentResponseWebformSuccess,
  listIncidentResponseWebformVersionSuccess,
  getIncidentResponseWebformVersionSuccess,
  getIncidentResponseWebformFieldsSuccess,
  listIncidentResponseCaseSuccess,
  createIncidentResponseCaseSuccess,
  getIncidentResponseCaseSuccess,
  updateIncidentResponseCaseSuccess,
  updateIncidentResponseCaseError,
  deleteIncidentResponseCaseSuccess,
  listTaskInCaseSuccess,
  createTaskInCaseSuccess,
  getTaskInCaseSuccess,
  updateTaskInCaseSuccess,
  deleteTaskInCaseSuccess,
  listIncidentResponseCaseCommentSuccess,
  createIncidentResponseCaseCommentSuccess,
  listIncidentResponseCaseHistorySuccess,
  listIncidentResponseCaseHighSuccess,
  sentIncidentResponseCaseHighSuccess,
  getIncidentResponseCaseHighTemplateSuccess,
  listIncidentResponseTaskAllSuccess,
  getIncidentResponseWebformTemplateSuccess,
  getIncidentResponseWebformTemplateError,
  toggleIncidentResponsePlanStageNextButton,
  toggleIncidentResponsePlanStageBackButton,
  publishIncidentResponseCaseSuccess,
} from "redux/actions/PrivacyKitDataBreachNotification";
import IncidentResponseManagementServices from "services/pdpakit/IncidentResponseManagementServices";
import Notification from "util/Notification";
import AssessmentAutomationServices from "../../services/pdpakit/AssessmentAutomationServices";
import { selectListTenantUsers } from "../selectors/UserManagement";
import { getListUserDataByUKey } from "util/UserManagementUtils";
import {
  selectDataBreachNotificationIncidentResponseCase,
  selectDataBreachNotificationIncidentResponseCaseStageId,
} from "../selectors/PrivacyKitDataBreachNotification";
import _ from "lodash";
import { v4 as uuid } from "uuid";

// NOTIFICATION
function* listIncidentResponseNotification() {
  yield takeEvery(LIST_INCIDENT_RESPONSE_NOTIFICATION, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.listIncidentResponseNotification,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listIncidentResponseNotificationSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* getIncidentResponseNotification() {
  yield takeEvery(GET_INCIDENT_RESPONSE_NOTIFICATION, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.getIncidentResponseNotification,
        payload
      );
      if (response.statusCode === 200) {
        yield put(
          getIncidentResponseNotificationSuccess({
            ...response.data,
            receiver: response.data?.receiver?.map(({ ukey }) => ukey),
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* createIncidentResponseNotification() {
  yield takeEvery(
    CREATE_INCIDENT_RESPONSE_NOTIFICATION,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const listUser = yield select(selectListTenantUsers);
        payload.receiver = getListUserDataByUKey(listUser, payload.receiver);

        const response = yield call(
          IncidentResponseManagementServices.createIncidentResponseNotification,
          payload
        );
        if (response.statusCode === 200) {
          yield put(createIncidentResponseNotificationSuccess(response.data));
          onFinish(response);
          Notification("success", "สร้างการแจ้งเตือนเรียบร้อยแล้ว");
        } else {
          onFailed(response);
          Notification(
            "success",
            "เกิดข้อผิดพลาด ไม่สามารถสร้างการแจ้งเตือนได้"
          );
        }
      } catch (error) {
        onFailed(error);
        Notification("success", "เกิดข้อผิดพลาด ไม่สามารถสร้างการแจ้งเตือนได้");
      }
    }
  );
}

function* updateIncidentResponseNotification() {
  yield takeEvery(
    UPDATE_INCIDENT_RESPONSE_NOTIFICATION,
    function* ({ payload, onFinish, onFailed }) {
      const listUser = yield select(selectListTenantUsers);
      payload.receiver = getListUserDataByUKey(listUser, payload.receiver);

      try {
        const response = yield call(
          IncidentResponseManagementServices.updateDetailIncidentResponseNotification,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateIncidentResponseNotificationSuccess(response.data));
          onFinish(response.data);
          Notification("success", "บันทึกเหตุการณ์เรียบร้อยแล้ว");
        } else {
          onFailed(response);
          Notification("error", "เกิดข้อผิดพลาด ไม่สามารถบันทึกเหตุการณ์ได้");
        }
      } catch (error) {
        onFailed(error);
        Notification("error", "เกิดข้อผิดพลาด ไม่สามารถบันทึกเหตุการณ์ได้");
      }
    }
  );
}

function* updateIncidentResponseNotificationStatus() {
  yield takeEvery(
    UPDATE_INCIDENT_RESPONSE_NOTIFICATION_STATUS,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.updateStatusIncidentResponseNotification,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            updateIncidentResponseNotificationStatusSuccess(response.data)
          );
          onFinish(response.data);
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* deleteIncidentResponseNotification() {
  yield takeEvery(
    DELETE_INCIDENT_RESPONSE_NOTIFICATION,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.deleteIncidentResponseNotification,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteIncidentResponseNotificationSuccess(payload));
          onFinish();
          Notification("success", "ลบการแจ้งเตือนเรียบร้อยแล้ว");
        } else {
          onFailed(response);
          Notification("error", "เกิดข้อผิดพลาด ไม่สามารถลบการแจ้งเตือนได้");
        }
      } catch (error) {
        onFailed(error);
        Notification("error", "เกิดข้อผิดพลาด ไม่สามารถลบการแจ้งเตือนได้");
      }
    }
  );
}

// RESPONSE PLAN
function* listIncidentResponseManagementPlan() {
  yield takeEvery(LIST_INCIDENT_RESPONSE_PLAN, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.listResponsePlan,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listIncidentResponsePlanSuccess(response));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

function* getIncidentResponseManagementPlan() {
  yield takeEvery(GET_INCIDENT_RESPONSE_PLAN, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.getResponsePlan,
        payload
      );
      if (response.statusCode === 200) {
        yield put(getIncidentResponsePlanSuccess(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

function* createIncidentResponseManagementPlan() {
  yield takeEvery(
    CREATE_INCIDENT_RESPONSE_PLAN,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.createResponsePlan,
          payload
        );

        if (response.statusCode === 200 && response.data) {
          yield put(createIncidentResponsePlanSuccess(response.data));
          onFinish();
        } else {
          onFailed(response);
        }
      } catch (error) {
        console.log(error);
        onFailed(error);
      }
    }
  );
}

function* updateIncidentResponseManagementPlan() {
  yield takeEvery(
    UPDATE_INCIDENT_RESPONSE_PLAN,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.updateResponsePlan,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateIncidentResponsePlanSuccess(response.data));
          onFinish(response.data);
          Notification("success", "บันทึกแผนการตอบสนองเรียบร้อยแล้ว");
        } else {
          onFailed(response);
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถบันทึกแผนการตอบสนองได้"
          );
        }
      } catch (err) {
        onFailed(err);
        Notification("error", "เกิดข้อผิดพลาด ไม่สามารถบันทึกแผนการตอบสนองได้");
      }
    }
  );
}

function* publishIncidentResponseManagementPlan() {
  yield takeEvery(
    PUBLISH_INCIDENT_RESPONSE_PLAN,
    function* ({ payload, onFinish, onFailed }) {
      try {
        yield call(
          IncidentResponseManagementServices.updateResponsePlan,
          payload
        );
        const response = yield call(
          IncidentResponseManagementServices.publishResponsePlan,
          payload
        );
        if (response.statusCode === 200) {
          yield put(publishIncidentResponsePlanSuccess(response.data));
          onFinish(response.data);
          Notification("success", "เผยแพร่แผนการตอบสนองเรียบร้อยแล้ว");
        } else {
          onFailed(response);
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถเผยแพร่แผนการตอบสนองได้"
          );
        }
      } catch (err) {
        onFailed(err);
        Notification(
          "error",
          "เกิดข้อผิดพลาด ไม่สามารถเผยแพร่แผนการตอบสนองได้"
        );
      }
    }
  );
}

function* deleteIncidentResponseManagementPlan() {
  yield takeEvery(
    DELETE_INCIDENT_RESPONSE_PLAN,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.deleteResponsePlan,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteIncidentResponsePlanSuccess(payload));
          onFinish();
          Notification("success", "ลบแผนการตอบสนองเรียบร้อยแล้ว");
        } else {
          onFailed(response);
          Notification("error", "เกิดข้อผิดพลาด ไม่สามารถลบแผนการตอบสนองได้");
        }
      } catch (error) {
        onFailed(error);
        Notification("error", "เกิดข้อผิดพลาด ไม่สามารถลบแผนการตอบสนองได้");
      }
    }
  );
}

function* updateIncidentResponseManagementPlanStage() {
  yield takeEvery(
    UPDATE_INCIDENT_RESPONSE_PLAN_STAGE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.updateIncidentResponseEventStage,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateIncidentResponsePlanStageSuccess(response.data));
          onFinish(response.data);
        } else {
          onFailed(response);
        }
      } catch (err) {
        console.log(err);
        onFailed(err);
      }
    }
  );
}

function* listIncidentResponseTaskInResponsePlan() {
  yield takeEvery(
    LIST_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN,
    function* ({ payload }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.listTaskInResponsePlan,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listTaskInResponsePlanSuccess(response));
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* createIncidentResponseTaskInResponsePlan() {
  yield takeEvery(
    CREATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.createTaskInResponsePlan,
          payload
        );
        if (response.statusCode === 200) {
          yield put(createTaskInResponsePlanSuccess(response.data));
          onFinish(response);
        } else {
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถสร้างงานชื่อซ้ำกันได้"
          );
          onFailed(response);
        }
      } catch (error) {
        Notification("error", "เกิดข้อผิดพลาด ไม่สามารถสร้างงานชื่อซ้ำกันได้");
        onFailed(error);
      }
    }
  );
}

function* getIncidentResponseTaskInResponsePlan() {
  yield takeEvery(
    GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN,
    function* ({ payload }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.getDetailTaskInResponsePlan,
          payload
        );
        if (response.statusCode === 200) {
          yield put(getTaskInResponsePlanSuccess(response.data));
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* updateIncidentResponseTaskInResponsePlan() {
  yield takeEvery(
    UPDATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.updateTaskInResponsePlan,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateTaskInResponsePlanSuccess(response.data));
          onFinish(response.data);
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* deleteIncidentResponseTaskInResponsePlan() {
  yield takeEvery(
    DELETE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.deleteTaskInResponsePlan,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteTaskInResponsePlanSuccess(payload.data));
          onFinish(response);
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

// INCIDENT WEBFORM
function* listIncidentResponseManagementWebform() {
  yield takeEvery(LIST_INCIDENT_RESPONSE_WEBFORM, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.listWebform,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listIncidentResponseWebformSuccess(response));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

function* getIncidentResponseManagementWebform() {
  yield takeEvery(GET_INCIDENT_RESPONSE_WEBFORM, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.getWebform,
        payload
      );
      if (response.statusCode === 200) {
        yield put(getIncidentResponseWebformSuccess(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

function* createIncidentResponseManagementWebform() {
  yield takeEvery(
    CREATE_INCIDENT_RESPONSE_WEBFORM,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.createWebform,
          payload
        );
        yield put(createIncidentResponseWebformSuccess(response.data));
        onFinish();
        Notification("success", "สร้างเว็บฟอร์มเรียบร้อย");
      } catch (error) {
        onFailed(error);
        Notification("error", "ไม่สามารถเว็บฟอร์มชื่อซ้ำกันได้");
      }
    }
  );
}

function* updateIncidentResponseManagementWebform() {
  yield takeEvery(
    UPDATE_INCIDENT_RESPONSE_WEBFORM,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.updateWebform,
          payload
        );
        yield put(updateIncidentResponseWebformSuccess(response.data));
        Notification("success", "แก้ไขเว็บฟอร์มเรียบร้อย");
        onFinish(response.data);
      } catch (err) {
        console.log(err);
        onFailed(err);
        Notification("error", "ไม่สามารถแก้ไขเว็บฟอร์มได้");
      }
    }
  );
}

function* publishIncidentResponseManagementWebform() {
  yield takeEvery(
    PUBLISH_INCIDENT_RESPONSE_WEBFORM,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const webformData = yield call(
          IncidentResponseManagementServices.updateWebform,
          payload
        );
        const versionData = yield call(
          IncidentResponseManagementServices.publishWebform,
          payload
        );
        yield put(
          publishIncidentResponseWebformSuccess({
            webformData: webformData.data,
            versionData: versionData.data,
          })
        );
        onFinish(versionData.data);
        Notification("success", "เผยแพร่เว็บฟอร์มเรียบร้อย");
      } catch (err) {
        Notification("error", "ไม่สามารถเผยแพร่เว็บฟอร์มได้");
        console.log(err);
        onFailed(err);
      }
    }
  );
}

function* deleteIncidentResponseManagementWebform() {
  yield takeEvery(
    DELETE_INCIDENT_RESPONSE_WEBFORM,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.deleteWebform,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteIncidentResponseWebformSuccess(payload));
          onFinish();
          Notification("success", "ลบรายการเว็บฟอร์มเรียบร้อย");
        } else {
          onFailed(response);
          Notification("error", "เกิดข้อผิดพลาด ไม่สามารถลบรายการเว็บฟอร์มได้");
        }
      } catch (error) {
        onFailed(error);
        Notification("error", "เกิดข้อผิดพลาด ไม่สามารถลบรายการเว็บฟอร์มได้");
      }
    }
  );
}

function* listIncidentResponseWebformVersion() {
  yield takeEvery(
    LIST_INCIDENT_RESPONSE_WEBFORM_VERSION,
    function* ({ payload }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.listWebformVersion,
          payload
        );
        if (response.statusCode === 200) {
          yield put(listIncidentResponseWebformVersionSuccess(response));
        }
      } catch (err) {
        console.log(err);
      }
    }
  );
}

function* getIncidentResponseWebformVersion() {
  yield takeEvery(
    GET_INCIDENT_RESPONSE_WEBFORM_VERSION,
    function* ({ payload }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.getWebformVersion,
          payload
        );
        if (response.statusCode === 200) {
          yield put(getIncidentResponseWebformVersionSuccess(response.data));
        }
      } catch (err) {
        console.log(err);
      }
    }
  );
}

const dynamicWidget = {
  label: "ช่องกรอกรายละเอียด(แบบรายตัวเลือก)",
  widget: "input-dynamic",
  element_type: {},
  question: "ช่องกรอกรายละเอียด(แบบรายตัวเลือก)",
  visibility: [],
  initialValue: [""],
  value: [""],
  key: uuid(),
};

const uploadWidget = {
  label: "อัปโหลด",
  widget: "upload",
  element_type: {},
  visibility: [],
  initialValue: [],
  value: [],
  key: uuid(),
};

export function* getIncidentResponseWebformFields() {
  yield takeEvery(GET_INCIDENT_RESPONSE_WEBFORM_WEBFORM_FIELDS, function* () {
    try {
      let { body } = yield call(
        IncidentResponseManagementServices.getWebFormFields,
        "incident"
      );
      body.item.fields.push(dynamicWidget);
      body.item.fields.push(uploadWidget);
      yield put(getIncidentResponseWebformFieldsSuccess(body.item));
    } catch (error) {
      console.error(error);
    }
  });
}

// EVENT REGISTER
function* listIncidentResponseCase() {
  yield takeEvery(LIST_INCIDENT_RESPONSE_CASE, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.listIncidentResponseCase,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listIncidentResponseCaseSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* createIncidentResponseCase() {
  yield takeEvery(
    CREATE_INCIDENT_RESPONSE_CASE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.createIncidentResponseCase,
          payload
        );
        if (response.statusCode === 200 && response.data) {
          yield put(createIncidentResponseCaseSuccess(response.data));
          onFinish(response);
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* getIncidentResponseCase() {
  yield takeEvery(GET_INCIDENT_RESPONSE_CASE, function* ({ payload }) {
    try {
      const { data, statusCode } = yield call(
        IncidentResponseManagementServices.getDetailIncidentResponseCase,
        payload
      );
      const stageId = data?.stageId ?? data?.stages[0].id;
      if (statusCode === 200) {
        yield put(getIncidentResponseCaseSuccess({ ...data, stageId }));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* updateIncidentResponseCase() {
  yield takeEvery(
    UPDATE_INCIDENT_RESPONSE_CASE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        let incidentResponseCase = yield select(
          selectDataBreachNotificationIncidentResponseCase
        );
        incidentResponseCase.form_fields.form_sorkorsor.template[0].form[0] =
          payload.formSorKorSor;
        // incidentResponseCase.form_style.banner_logo =
        //   payload.formSorKorSor.logo;
        // incidentResponseCase.form_style.favicon = payload.formSorKorSor.logo;

        const response = yield call(
          IncidentResponseManagementServices.updateDetailIncidentResponseCase,
          {
            ...incidentResponseCase,
            ...payload,
          }
        );
        if (response.statusCode === 200) {
          yield put(updateIncidentResponseCaseSuccess(response.data));
          onFinish(response.data);
          Notification("success", "บันทึกเหตุการณ์เรียบร้อยแล้ว");
        } else {
          onFailed(response);
          Notification("error", "เกิดข้อผิดพลาด ไม่สามารถบันทึกเหตุการณ์ได้");
        }
      } catch (error) {
        onFailed(error);
        Notification("error", "เกิดข้อผิดพลาด ไม่สามารถบันทึกเหตุการณ์ได้");
      }
    }
  );
}

function* deleteIncidentResponseCase() {
  yield takeEvery(
    DELETE_INCIDENT_RESPONSE_CASE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.deleteDetailIncidentResponseCase,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteIncidentResponseCaseSuccess(payload));
          onFinish();
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* listIncidentResponseTaskInCase() {
  yield takeEvery(LIST_INCIDENT_RESPONSE_TASK_IN_CASE, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.listTaskInCase,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listTaskInCaseSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* createIncidentResponseTaskInCase() {
  yield takeEvery(
    CREATE_INCIDENT_RESPONSE_TASK_IN_CASE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.createTaskInCase,
          payload
        );
        if (response.statusCode === 200) {
          yield put(createTaskInCaseSuccess(response.data));
          onFinish(response);
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* getIncidentResponseTaskInCase() {
  yield takeEvery(GET_INCIDENT_RESPONSE_TASK_IN_CASE, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.getDetailTaskInCase,
        payload
      );
      if (response.statusCode === 200) {
        yield put(getTaskInCaseSuccess(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* updateIncidentResponseTaskInCase() {
  yield takeEvery(
    UPDATE_INCIDENT_RESPONSE_TASK_IN_CASE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.updateTaskInCase,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateTaskInCaseSuccess(response.data));
          onFinish(response.data);
          Notification("success", "แก้ไขข้อมูลสำเร็จ");
        } else {
          Notification(
            "error",
            "ไม่สามารถบันทึกได้เนื่องจากงานนี้ไม่ได้อยู่ในความรับผิดชอบของท่าน"
          );
          onFailed(response.data);
        }
      } catch (error) {
        onFailed(error);
        Notification("error", "เกิดข้อผิดพลาด ไม่สามารถบันทึกงานได้");
      }
    }
  );
}

function* deleteIncidentResponseTaskInCase() {
  yield takeEvery(
    DELETE_INCIDENT_RESPONSE_TASK_IN_CASE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.deleteTaskInCase,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteTaskInCaseSuccess(payload.data));
          onFinish(response);
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* listIncidentResponseCaseComment() {
  yield takeEvery(LIST_INCIDENT_RESPONSE_CASE_COMMENT, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.listComment,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listIncidentResponseCaseCommentSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* createIncidentResponseCaseComment() {
  yield takeEvery(
    CREATE_INCIDENT_RESPONSE_CASE_COMMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.createComment,
          payload
        );
        if (response.statusCode === 200) {
          yield put(createIncidentResponseCaseCommentSuccess(response.data));
          onFinish(response);
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* listIncidentResponseCaseHistory() {
  yield takeEvery(LIST_INCIDENT_RESPONSE_CASE_HISTORY, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.listHistory,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listIncidentResponseCaseHistorySuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* listIncidentResponseCaseHigh() {
  yield takeEvery(LIST_INCIDENT_RESPONSE_CASE_HIGH, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.listCaseHigh,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listIncidentResponseCaseHighSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* sentIncidentResponseCaseHigh() {
  yield takeEvery(
    SENT_INCIDENT_RESPONSE_CASE_HIGH,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.sentCaseHigh,
          payload
        );
        if (response.statusCode === 200) {
          yield put(sentIncidentResponseCaseHighSuccess(response.data));
          onFinish(response);
          Notification("success", "ส่งรายงาน สคส.เรียบร้อยแล้ว");
        } else {
          onFailed(response);
          Notification("error", "เกิดข้อผิดพลาด ไม่สามารถส่งรายงาน สคส.ได้");
        }
      } catch (error) {
        console.log(error);
        onFailed(error);
        Notification("error", "เกิดข้อผิดพลาด ไม่สามารถส่งรายงาน สคส.ได้");
      }
    }
  );
}

function* getIncidentResponseCaseHighTemplate() {
  yield takeEvery(
    GET_INCIDENT_RESPONSE_CASE_HIGH_TEMPLATE,
    function* ({ payload }) {
      try {
        const response = yield call(
          IncidentResponseManagementServices.getTemplateData,
          payload
        );
        if (response.statusCode === 200) {
          yield put(getIncidentResponseCaseHighTemplateSuccess(response.item));
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* listIncidentResponseTaskAll() {
  yield takeEvery(LIST_INCIDENT_RESPONSE_TASK_ALL, function* ({ payload }) {
    try {
      const response = yield call(
        IncidentResponseManagementServices.listTaskAll,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listIncidentResponseTaskAllSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* getIncidentResponseTemplate() {
  yield takeEvery(GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE, function* () {
    try {
      const { Item } = yield call(
        AssessmentAutomationServices.getTemplateData,
        {
          assessmentType: "incident-privacykit",
          assessment_template_type: "incident_response_template",
          assessment_template_id: "incident-report-data-breaches",
        }
      );
      yield put(
        getIncidentResponseWebformTemplateSuccess(
          Item.template[0].form[0].section[0]
        )
      );
    } catch (error) {
      yield put(getIncidentResponseWebformTemplateError());
    }
  });
}

function* updateIncidentResponseStageId() {
  yield takeEvery(UPDATE_INCIDENT_RESPONSE_CASE_STAGE, function* ({ stageId }) {
    const hide = message.loading("กำลังดำเนินการ...", 0);
    // console.log("sagas", stageId);
    try {
      const incidentResponseCase = yield select(
        selectDataBreachNotificationIncidentResponseCase
      );
      const { stages } = incidentResponseCase;
      const prevStageId = yield select(
        selectDataBreachNotificationIncidentResponseCaseStageId
      );
      const prevStageIndex = _.findIndex(stages, { id: prevStageId });
      const nextStageIndex = _.findIndex(stages, { id: stageId });

      if (nextStageIndex > prevStageIndex) {
        yield put(toggleIncidentResponsePlanStageNextButton());
      } else {
        yield put(toggleIncidentResponsePlanStageBackButton());
      }

      const { data } = yield call(
        IncidentResponseManagementServices.updateIncidentResponseCaseStage,
        {
          ...incidentResponseCase,
          "risk-level-answer-system":
            incidentResponseCase["risk-level-answer-system"] ?? "",
          stageId,
        }
      );
      hide();
      yield put(updateIncidentResponseCaseSuccess({ ...data, stageId }));
      Notification("success", "เปลี่ยนขั้นตอนการทำงานเรียบร้อยแล้ว");
    } catch (error) {
      hide();
      yield put(updateIncidentResponseCaseError());
      Notification(
        "error",
        "กรุณาตรวจสอบงานให้เรียบร้อย ก่อนดำเนินการขั้นตอนถัดไป"
      );
    }
  });
}

function* publishIncidentResponseCase() {
  yield takeEvery(
    PUBLISH_INCIDENT_RESPONSE_CASE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        let incidentResponseCase = yield select(
          selectDataBreachNotificationIncidentResponseCase
        );
        incidentResponseCase.form_fields.form_sorkorsor.template[0].form[0] =
          payload.formSorKorSor;
        const response = yield call(
          IncidentResponseManagementServices.updateDetailIncidentResponseCase,
          {
            ...incidentResponseCase,
            ...payload,
          }
        );
        yield call(
          IncidentResponseManagementServices.publishDetailIncidentResponseCase,
          {
            ...payload,
          }
        );
        if (response.statusCode === 200) {
          yield put(updateIncidentResponseCaseSuccess(response.data));
          onFinish(response.data);
          yield put(publishIncidentResponseCaseSuccess(response.data));
          Notification("success", "เผยแพร่เหตุการณ์เรียบร้อยแล้ว");
        } else {
          onFailed(response);
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถเผยแพร่เหตุการณ์เรียบร้อยแล้ว"
          );
        }
      } catch (error) {
        onFailed(error);
        Notification(
          "error",
          "เกิดข้อผิดพลาด ไม่เผยแพร่เหตุการณ์เรียบร้อยแล้ว"
        );
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(listIncidentResponseNotification),
    fork(getIncidentResponseNotification),
    fork(createIncidentResponseNotification),
    fork(updateIncidentResponseNotification),
    fork(updateIncidentResponseNotificationStatus),
    fork(deleteIncidentResponseNotification),
    fork(listIncidentResponseManagementPlan),
    fork(getIncidentResponseManagementPlan),
    fork(createIncidentResponseManagementPlan),
    fork(updateIncidentResponseManagementPlan),
    fork(publishIncidentResponseManagementPlan),
    fork(deleteIncidentResponseManagementPlan),
    fork(updateIncidentResponseManagementPlanStage),
    fork(listIncidentResponseTaskInResponsePlan),
    fork(getIncidentResponseTaskInResponsePlan),
    fork(createIncidentResponseTaskInResponsePlan),
    fork(updateIncidentResponseTaskInResponsePlan),
    fork(deleteIncidentResponseTaskInResponsePlan),
    fork(listIncidentResponseManagementWebform),
    fork(getIncidentResponseManagementWebform),
    fork(createIncidentResponseManagementWebform),
    fork(updateIncidentResponseManagementWebform),
    fork(publishIncidentResponseManagementWebform),
    fork(deleteIncidentResponseManagementWebform),
    fork(getIncidentResponseWebformFields),
    fork(listIncidentResponseWebformVersion),
    fork(getIncidentResponseWebformVersion),
    fork(listIncidentResponseCase),
    fork(createIncidentResponseCase),
    fork(getIncidentResponseCase),
    fork(updateIncidentResponseCase),
    fork(deleteIncidentResponseCase),
    fork(listIncidentResponseTaskInCase),
    fork(createIncidentResponseTaskInCase),
    fork(getIncidentResponseTaskInCase),
    fork(updateIncidentResponseTaskInCase),
    fork(deleteIncidentResponseTaskInCase),
    fork(listIncidentResponseCaseComment),
    fork(createIncidentResponseCaseComment),
    fork(listIncidentResponseCaseHistory),
    fork(listIncidentResponseCaseHigh),
    fork(sentIncidentResponseCaseHigh),
    fork(getIncidentResponseCaseHighTemplate),
    fork(listIncidentResponseTaskAll),
    fork(getIncidentResponseTemplate),
    fork(updateIncidentResponseStageId),
    fork(publishIncidentResponseCase),
  ]);
}
