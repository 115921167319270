import { CyberKitPath } from "../AppModuleConfig";

const CyberKitLogManagementNavigation = (assessmentType) => [
  {
    key: `cyberkit-${assessmentType}-source`,
    path: `${CyberKitPath}${assessmentType}/source`,
    title: "รายการอุปกรณ์",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `cyberkit-${assessmentType}-audit`,
    path: `${CyberKitPath}${assessmentType}/audit`,
    title: "ประวัติการใช้งาน",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `cyberkit-${assessmentType}-search`,
    path: `${CyberKitPath}${assessmentType}/search`,
    title: "ค้นหา",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `cyberkit-${assessmentType}-explorers`,
    path: `${CyberKitPath}${assessmentType}/explorers`,
    title: "จัดการข้อมูล Log File",
    breadcrumb: false,
    icon: null,
    submenu: [
      {
        key: `cyberkit-${assessmentType}-explorer-explorer`,
        path: `${CyberKitPath}${assessmentType}/explorer/explorer`,
        title: "การจัดเก็บ Log File",
        breadcrumb: false,
        icon: null,
        submenu: [],
      },
      {
        key: `cyberkit-${assessmentType}-explorer-archive`,
        path: `${CyberKitPath}${assessmentType}/explorer/archive`,
        title: "รายละเอียดการจัดเก็บ",
        breadcrumb: false,
        icon: null,
        submenu: [],
      },
    ],
  },
  {
    key: `cyberkit-${assessmentType}-dashboard`,
    path: `${CyberKitPath}${assessmentType}/dashboard`,
    title: "แดชบอร์ด",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
];

export default CyberKitLogManagementNavigation;
