export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS";
export const TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_CREATE =
  "TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_CREATE";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_TEMPLATE =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_TEMPLATE";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_TEMPLATE_SUCCESS =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_TEMPLATE_SUCCESS";
export const CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM =
  "CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM";
export const CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS =
  "CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS";
export const CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR =
  "CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR";
export const TOGGLE_DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM =
  "TOGGLE_DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM";
export const DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM =
  "DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM";
export const DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS =
  "DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS";
export const DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR =
  "DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELDS =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELDS";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELDS_SUCCESS =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELDS_SUCCESS";
export const TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SETTING =
  "TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SETTING";
export const TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELD_DELETE =
  "TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELD_DELETE";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM_TEXT =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM_TEXT";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM_STYLE =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM_STYLE";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR";

export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_SUCCESS =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_SUCCESS";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_ERROR =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_ERROR";

export const TOGGLE_PREVIEW_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION =
  "TOGGLE_PREVIEW_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_SUCCESS =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_SUCCESS";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_ERROR =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_ERROR";

export const TOGGLE_SELECT_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_WORKFLOW =
  "TOGGLE_SELECT_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_WORKFLOW";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_WORKFLOW =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_WORKFLOW";

export const TOGGLE_PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM =
  "TOGGLE_PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM";
export const PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM =
  "PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM";
export const PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR =
  "PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR";

export const TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SCRIPT =
  "TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SCRIPT";
export const TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_PREVIEW =
  "TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_PREVIEW";

export const RESET_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_DATA =
  "RESET_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_DATA";
