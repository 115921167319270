import fetch from "util/Api";
import { AUTH_BASE_URL } from "../config/EndpointConfig";

const UserManagementService = {};

UserManagementService.listOrganization = async (PaginationToken) =>
  await fetch({
    url: `${AUTH_BASE_URL}/organizations/lists`,
    method: "post",
    data: {
      PaginationToken: PaginationToken || null,
    },
  })
    .then((response) => response)
    .catch((error) => error.response.data);
UserManagementService.listOrganizationDetail = async (organization_uuid) =>
  await fetch({
    method: "get",
    url: `${AUTH_BASE_URL}/organizations/detail/${organization_uuid}`,
    data: { ...organization_uuid },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.addOrganizations = async (data) =>
  await fetch({
    method: "put",
    url: `${AUTH_BASE_URL}/organizations/add`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);

UserManagementService.editOrganizations = async (data) =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/update`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);

UserManagementService.deleteOrganizations = async (data) =>
  await fetch({
    method: "delete",
    url: ` ${AUTH_BASE_URL}/organizations/delete`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);

UserManagementService.listUser = async (PaginationToken) =>
  await fetch({
    url: `${AUTH_BASE_URL}/organizations/user/lists`,
    method: "post",
    data: {
      PaginationToken: PaginationToken || null,
    },
  })
    .then((response) => response)
    .catch((error) => error.response.data);
UserManagementService.listUserDetail = async (data) =>
  await fetch({
    url: `${AUTH_BASE_URL}/organizations/user/detail`,
    method: "post",
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.updateUserDetail = async (data) =>
  await fetch({
    method: "put",
    url: `${AUTH_BASE_URL}/organizations/user/detail`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.removeUser = async (email) =>
  await fetch({
    method: "delete",
    url: `${AUTH_BASE_URL}/organizations/user/detail`,
    data: { email },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.addUser = async (data) =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/user/add`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.listRoles = async (PaginationToken) =>
  await fetch({
    url: `${AUTH_BASE_URL}/organizations/role/lists`,
    method: "post",
    data: {
      PaginationToken: PaginationToken || null,
    },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.deleteRolePermissions = async (data) =>
  await fetch({
    method: "delete",
    url: `${AUTH_BASE_URL}/organizations/role/delete`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.rolePermissionsDetail = async (data) =>
  await fetch({
    method: "get",
    url: `${AUTH_BASE_URL}/organizations/role/get/${data}`,
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.updateRolePermissions = async (role_uuid, data) =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/role/update/${role_uuid}`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.AddRolePermissions = async (data) =>
  await fetch({
    method: "put",
    url: `${AUTH_BASE_URL}/organizations/role/add`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.rolePermissions = async (data) =>
  await fetch({
    method: "get",
    url: `${AUTH_BASE_URL}/organizations/role/listspermissions`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.addRoleInGroup = async (data) =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/role/add_roles_group`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.addRoleUser = async (data) =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/role/add_role_user`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.removeRoleUser = async (data) =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/role/remove_role_user`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.removeGroupUser = async (data) =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/user/removefromgroup`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.listUserGroup = async (PaginationToken) =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/group/lists`,
    data: {
      PaginationToken: PaginationToken || "",
    },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.addGroupUser = async (data) =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/user/addtogroup`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.addUserGroup = async (data) =>
  await fetch({
    method: "put",
    url: `${AUTH_BASE_URL}/organizations/group/add`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.deleteUserGroup = async (data) =>
  await fetch({
    method: "delete",
    url: `${AUTH_BASE_URL}/organizations/group/delete`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.listUserInGroup = async (groupuuid) =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/user/lists?groupuuid=${groupuuid}`,
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.deleteUserInGroup = async (data) =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/user/removefromgroup`,
    data,
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.addUserToGroup = async (data) =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/user/addtogroup`,
    data,
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.listRoleInGroup = async (groupuuid) =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/role/group`,
    data: { groupuuid },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);

UserManagementService.DeleteRoleInGroup = async (data) =>
  await fetch({
    method: "delete",
    url: `${AUTH_BASE_URL}/organizations/role/group/delete_role`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);
UserManagementService.updateUserGroup = async (data) =>
  await fetch({
    method: "post",
    url: ` ${AUTH_BASE_URL}/organizations/group/update`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err.response.data);

UserManagementService.getOrganizationTenant = async () =>
  await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/organizations/lists`,
    data: {
      PaginationToken: null,
    },
  });

UserManagementService.listTenantUser = async (organization_uuid) =>
  await fetch({
    url: `${AUTH_BASE_URL}/organizations/user/lists?organization_uuid=${organization_uuid}`,
    method: "post",
    data: {
      PaginationToken: null,
    },
  });

export default UserManagementService;
