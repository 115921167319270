import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD,
  LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN,
  GET_COOKIE_CONSENT_MANAGEMENT_DETAIL,
  SCAN_COOKIES_CONSENT_MANAGEMENT_DOMAIN,
  UPDATE_COOKIE_CONSENT_MANAGEMENT_COOKIE_DATA,
  UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN,
  CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN,
  DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN,
  UPDATE_COOKIES_CONSENT_MANAGEMENT_CATEGORY,
} from "../constants/PrivacyKitCookieConsentManagement";
import {
  listCookieConsentManagementDomainSuccess,
  listCookieConsentManagementDomainFailed,
  getCookieConsentManagementDashboardSuccess,
  getCookieConsentManagementDashboardFailed,
  getCookieConsentManagementDetailSuccess,
  getCookieConsentManagementDetailFailed,
  scanCookieConsentManagementDomainSuccess,
  updateCookieConsentManagementDomainSuccess,
  updateCookieConsentManagementDomainFailed,
  createCookieConsentManagementDomainSuccess,
  createCookieConsentManagementDomainFailed,
  deleteCookieConsentManagementDomainSuccess,
  deleteCookieConsentManagementDomainFailed,
  updateCookieConsentManagementCategorySuccess,
} from "../actions/PrivacyKitCookieConsentManagement";
import CookieConsentManagementService from "services/pdpakit/CookieConsentManagementService";
import Notification from "util/Notification";
import { message } from "antd";
import _ from "lodash";

const AcceptedType = [
  { key: "cookie_accepted_all", label: "กดยอมรับคุกกี้ทั้งหมด" },
  { key: "cookie_accepted_customised", label: "กดยอมรับเฉพาะคุกกี้ที่เลือก" },
];

const CookieCategory = [
  {
    key: "cookie_category_necessary",
    category: "necessary",
    label: "คุกกี้ที่มีความจำเป็น",
    description:
      "คุกกี้ประเภทที่มีความจำเป็นต้องใช้เพื่อให้การให้บริการหรือการทำงานของหน้าเว็บไซต์สามารถดำเนินการไปได้ (ฐานประโยชน์โดยชอบธรรม 24(5))",
  },
  {
    key: "cookie_category_functional",
    category: "functional",
    label: "คุกกี้เพื่อการทำงานของเว็บไซต์",
    description:
      "คุกกี้ที่ทำให้เว็บไซต์สามารถจดจำผู้เข้ามาใช้บริการหรือเยี่ยมชมเว็บไซต์และค่าที่ผู้ใช้บริการเลือก โดยข้อมูลดังกล่าวจะถูกนำไปใช้ในการจัดเนื้อหาบนหน้าเว็บไซต์ให้เหมาะสมกับผู้เข้าชมเว็บไซต์แต่ละรายได้ (ฐานความยินยอมมาตรา 24)",
  },
  {
    key: "cookie_category_analytics",
    category: "analytics",
    label: "คุกกี้เพื่อประสิทธิภาพ",
    description:
      "คุกกี้ที่ช่วยให้บริษัท ทราบถึงการปฏิสัมพันธ์ของผู้ใช้งานในการใช้บริการเว็บไซต์ของบริษัท รวมถึงหน้าเพจหรือพื้นที่ใดของเว็บไซต์ที่ได้รับความนิยม ตลอดจนการวิเคราะห์ข้อมูลด้านอื่น ๆ บริษัทยังใช้ข้อมูลนี้เพื่อการปรับปรุงการทำงานของเว็บไซต์ และเพื่อเข้าใจพฤติกรรมของผู้ใช้งานมากขึ้น (ฐานความยินยอมมาตรา 24)",
  },
  {
    key: "cookie_category_marketing",
    category: "marketing",
    label: "คุกกี้เพื่อการตลาด",
    description:
      "คุกกี้ที่จะทำการติดตามกิจกรรมต่าง ๆ ที่ผู้บริโภคทำบนช่องทางออนไลน์ และช่วยในการส่งเสริมการขายโดยการปล่อยโฆษณาต่าง ๆ ไปยังผู้บริโภคได้อย่างสอดคล้องยังผู้บริโภคได้อย่างสอดคล้องกับพฤติกรรมของผู้บริโภคแต่ละรายให้มากยิ่งขึ้น (ฐานความยินยอมมาตรา 24)",
  },
  {
    key: "cookie_category_unclassified",
    category: "unclassified",
    label: "คุกกี้ที่ยังไม่จำแนกประเภท",
    description: "คุกกี้ที่ไม่สามารถระบุประเภทได้",
  },
];

const CookieThirdParty = [
  {
    key: "first_party",
    label: "บุคคลที่หนึ่ง",
    value: true,
  },
  {
    key: "third_party",
    label: "บุคคลที่สาม",
    value: false,
  },
];

function* listCookieConsentManagementDomain() {
  yield takeEvery(LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN, function* () {
    try {
      const response = yield call(CookieConsentManagementService.listDomains);
      if (response.statusCode === 200) {
        yield put(listCookieConsentManagementDomainSuccess(response));
      } else {
        yield put(listCookieConsentManagementDomainFailed(response));
      }
    } catch (error) {
      console.log(error);
      yield put(listCookieConsentManagementDomainFailed(error));
    }
  });
}

const findCookieAcceptChart = (keys, query_result) => {
  return keys.map(({ key, label, description }) => ({
    id: key,
    label,
    value: query_result[key] || 0,
    description,
  }));
};

function* getCookieConsentManagementDashboard() {
  yield takeEvery(
    GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD,
    function* ({ payload, filter }) {
      try {
        const [dashboard, report] = yield all([
          call(
            CookieConsentManagementService.getCookieConsentDashboard,
            payload,
            filter
          ),
          call(
            CookieConsentManagementService.getCookieConsentReport,
            payload,
            filter
          ),
        ]);
        const { query_result } = dashboard.body;
        const acceptQueryType = findCookieAcceptChart(
          AcceptedType,
          query_result
        );
        const acceptQueryCategory = findCookieAcceptChart(
          CookieCategory,
          query_result
        );

        yield put(
          getCookieConsentManagementDashboardSuccess({
            ...dashboard,
            domain: payload,
            acceptQueryType,
            acceptQueryCategory,
            acceptReport: report.Items,
          })
        );
      } catch (error) {
        yield put(getCookieConsentManagementDashboardFailed(error));
      }
    }
  );
}

const getCookieCategory = ({ category, text }) => {
  const updateText = { ...text };
  Object.entries(updateText).forEach(([key, data]) => {
    const unclassified = data.unclassified_category
      ? {
          ...data.unclassified_category,
          cetegory_name: "unclassified",
          background: "#ff0000",
        }
      : undefined;

    updateText[key] = [...data.category, unclassified];
  });

  const updateCategory = category.map((data) => {
    const category = {};
    category["th"] = _.find(updateText["th"], {
      cetegory_name: data.cetegory_name,
    });
    category["en"] = _.find(updateText["en"], {
      cetegory_name: data.cetegory_name,
    });
    return { ...data, background: data.background.slice(0, 7), category };
  });

  return updateCategory || [];
};

const getCookieScanResults = (data) => {
  const category = getCookieCategory(data);

  const { cookies } = data;

  const cookieCategory = category.map((categoryData) => {
    const { cetegory_name, category, background } = categoryData;
    return {
      id: cetegory_name,
      label: {
        th: category["th"].cetegory_label,
        en: category["en"].cetegory_label,
      },
      description: {
        th: category["th"].desscirption,
        en: category["en"].desscirption,
      },
      color: background.slice(0, 7),
      value:
        cookies.filter(({ Category }) => Category === cetegory_name).length ||
        0,
    };
  });

  const cookieThirdParty = CookieThirdParty.map(({ key, label, value }) => {
    return {
      id: key,
      label,
      value:
        cookies.filter(({ FirstParty }) => FirstParty === value).length || 0,
    };
  });

  const cookieMoreYear = category.map((categoryData) => {
    const { cetegory_name, category, background } = categoryData;
    return {
      id: cetegory_name,
      label: {
        th: category["th"].cetegory_label,
        en: category["en"].cetegory_label,
      },
      color: background.slice(0, 7),
      value:
        cookies
          .filter(({ Category }) => Category === category)
          .filter(({ Expiry }) => Expiry.split(" ").includes("year")).length ||
        0,
    };
  });

  const cookieClassified = [
    {
      key: "classified_cookie",
      label: "คุกกี้ที่จำแนกประเภท",
      value: cookies.filter(({ Category }) => Category !== null).length || 0,
    },
    {
      key: "unclassified_cookie",
      label: "คุกกี้ที่ไม่จำแนกประเภท",
      value: cookies.filter(({ Category }) => Category === null).length || 0,
    },
  ];

  return {
    category,
    cookieCategory,
    cookieThirdParty,
    cookieMoreYear,
    cookieClassified,
  };
};

function* getCookieConsentManagementDetail() {
  yield takeEvery(
    GET_COOKIE_CONSENT_MANAGEMENT_DETAIL,
    function* ({ payload }) {
      try {
        const response = yield call(
          CookieConsentManagementService.getDomain,
          payload
        );
        if (response.statusCode === 200) {
          const resultScan = getCookieScanResults(response.body.data);
          yield put(
            getCookieConsentManagementDetailSuccess({
              ...response.body,
              ...resultScan,
              statusCode: 200,
            })
          );
        } else {
          yield put(getCookieConsentManagementDetailFailed(response));
        }
      } catch (err) {
        console.log(err);
      }
    }
  );
}

function* scanCookieConsentManagementDomain() {
  yield takeEvery(
    SCAN_COOKIES_CONSENT_MANAGEMENT_DOMAIN,
    function* ({ payload }) {
      const hide = message.loading("Action in progress...");
      try {
        const response = yield call(
          CookieConsentManagementService.scanCookieConsentManagementDomain,
          payload
        );
        if (response.statusCode === 200) {
          yield put(scanCookieConsentManagementDomainSuccess(response.body));
          Notification(
            "success",
            "notification.cookieConsentManagement.scan.success"
          );
        } else {
          Notification("error", "notification.internalServerError");
        }
        hide();
      } catch (error) {
        hide();
      }
    }
  );
}

function* updateCookieConsentManagementDomain() {
  yield takeEvery(
    UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          CookieConsentManagementService.updateDomain,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            updateCookieConsentManagementDomainSuccess({
              ...response.body,
              statusCode: 200,
            })
          );
          Notification("success", "อัพเดตข้อมูลเว็บไซต์ของคุณสำเร็จ");
          onFinish();
        } else {
          onFailed();
          yield put(updateCookieConsentManagementDomainFailed(response));
          Notification("error", "notification.internalServerError");
        }
      } catch (err) {
        onFailed(err);
      }
    }
  );
}

function* updateCookieConsentManagementCookieData() {
  yield takeEvery(
    UPDATE_COOKIE_CONSENT_MANAGEMENT_COOKIE_DATA,
    function* ({ payload, onFinish }) {
      try {
        const response = yield call(
          CookieConsentManagementService.updateCookie,
          payload
        );
        if (response.statusCode === 200) {
          Notification(
            "success",
            "notification.cookieConsentManagement.update.success"
          );
          const resultScan = getCookieScanResults(response.body);
          yield put(
            updateCookieConsentManagementCategorySuccess({
              data: response.body,
              ...resultScan,
            })
          );
          onFinish(response);
        } else {
          Notification("error", "notification.internalServerError");
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* createCookieConsentManagementDomain() {
  yield takeEvery(
    CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          CookieConsentManagementService.addDomain,
          payload
        );

        if (response.statusCode === 200 && !response.errorCode) {
          yield call(
            CookieConsentManagementService.scanCookieConsentManagementDomain,
            response.body.key
          );
          yield put(createCookieConsentManagementDomainSuccess(response.body));
          Notification("success", "สร้างเว็บไซต์นี้สำเร็จ");
          onFinish(response);
        } else if (response.errorCode === "domain_not_valid") {
          onFailed(response);
          Notification("error", "กรุณากรอกโดเมนให้ถูกต้อง");
        } else if (response.errorCode === "DomainLimit") {
          Notification("error", "คุณสามารถเพิ่มเว็บไซต์ได้สูงสุด 3 เว็บไซต์");
        } else {
          yield put(createCookieConsentManagementDomainFailed(response));
          onFailed(response);
          Notification("error", "notification.internalServerError");
        }
      } catch (error) {
        console.log(error);
        yield put(createCookieConsentManagementDomainFailed(error));
        onFailed(error);
        Notification("error", "notification.internalServerError");
      }
    }
  );
}

function* deleteCookieConsentManagementDomain() {
  yield takeEvery(
    DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          CookieConsentManagementService.deleteDomain,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteCookieConsentManagementDomainSuccess(payload.key));
          Notification("success", "ลบเว็บไซต์ของคุณสำเร็จ");
          onFinish();
        } else {
          Notification("error", "notification.internalServerError");
        }
      } catch (error) {
        yield put(deleteCookieConsentManagementDomainFailed(error));
        onFailed(error);
      }
    }
  );
}

function* updateCookieConsentManagementCategory() {
  yield takeEvery(
    UPDATE_COOKIES_CONSENT_MANAGEMENT_CATEGORY,
    function* ({ payload, onFinish }) {
      try {
        const response = yield call(
          CookieConsentManagementService.updateCookieCategory,
          payload
        );
        if (response.statusCode === 200) {
          const resultScan = getCookieScanResults(response.body);
          yield put(
            updateCookieConsentManagementCategorySuccess({
              data: response.body,
              ...resultScan,
            })
          );
          Notification(
            "success",
            "notification.cookieConsentManagement.update.success"
          );
          onFinish();
        } else {
          Notification("error", "notification.internalServerError");
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(listCookieConsentManagementDomain),
    fork(getCookieConsentManagementDashboard),
    fork(getCookieConsentManagementDetail),
    fork(scanCookieConsentManagementDomain),
    fork(updateCookieConsentManagementCookieData),
    fork(updateCookieConsentManagementDomain),
    fork(createCookieConsentManagementDomain),
    fork(deleteCookieConsentManagementDomain),
    fork(updateCookieConsentManagementCategory),
  ]);
}
