import {
  // TASK MANAGEMENT
  SET_TASK_MANAGEMENT_LIST,
  GET_TASK_MANAGEMENT_DETAIL,
  SET_TASK_MANAGEMENT_DETAIL,
  GET_JOBS,
  SET_JOBS,
  SET_JOB_LOADING,
  GET_COMMENTS,
  SET_COMMENTS,
  SET_COMMENT_LOADING,
  SET_NEXT_PAGE_TOKEN_COMMENTS,
  ON_COMMENTS,
  GET_ACTIVITY,
  SET_ACTIVITY,
  SET_ACTIVITY_LOADING,
  SET_NEXT_PAGE_TOKEN_ACTIVITY,

  // TASK ASSIGNMENT
  SET_TASK_ASSIGNMENT_LIST,
  UPDATE_TASK_STATUS,

  // RESPONSE PLANNING
  GET_RESPONSE_PLAN_LIST,
  GET_RESPONSE_PLAN_BY_ID,
  SET_RESPONSE_PLAN,
  SET_RESPONSE_PLAN_DETAIL,
  SET_EDGE,
  SET_NODE,
  GET_WORKFLOW_MODULE,
  SET_WORKFLOW_MODULE,
  CREATE_WORKFLOW,
  UPDATE_WORKFLOW,
} from "redux/constants/CyberKitSoar";

// TASK MANAGEMENT
export const setTaskManagementList = (payload) => {
  return {
    type: SET_TASK_MANAGEMENT_LIST,
    payload: payload,
  };
};

export const getTaskManagementDetail = (payload) => {
  return {
    type: GET_TASK_MANAGEMENT_DETAIL,
    payload: payload,
  };
};

export const setTaskManagementDetail = (payload) => {
  return {
    type: SET_TASK_MANAGEMENT_DETAIL,
    payload: payload,
  };
};

export const getJobs = (payload) => {
  return {
    type: GET_JOBS,
    payload: payload,
  };
};

export const setJobs = (payload) => {
  return {
    type: SET_JOBS,
    payload: payload,
  };
};

export const setLoadingJob = (payload) => {
  return {
    type: SET_JOB_LOADING,
    payload: payload,
  };
};

export const getComments = (payload) => {
  return {
    type: GET_COMMENTS,
    payload: payload,
  };
};

export const setComments = (payload) => {
  return {
    type: SET_COMMENTS,
    payload: payload,
  };
};

export const onComment = (payload) => {
  return {
    type: ON_COMMENTS,
    payload: payload,
  };
};

export const setCommentLoading = (payload) => {
  return {
    type: SET_COMMENT_LOADING,
    payload: payload,
  };
};

export const setNextPageTokenComment = (payload) => {
  return {
    type: SET_NEXT_PAGE_TOKEN_COMMENTS,
    payload: payload,
  };
};

export const getActivity = (payload) => {
  return {
    type: GET_ACTIVITY,
    payload: payload,
  };
};

export const setActivity = (payload) => {
  return {
    type: SET_ACTIVITY,
    payload: payload,
  };
};

export const setActivityLoading = (payload) => {
  return {
    type: SET_ACTIVITY_LOADING,
    payload: payload,
  };
};

export const setNextPageTokenActivity = (payload) => {
  return {
    type: SET_NEXT_PAGE_TOKEN_ACTIVITY,
    payload: payload,
  };
};

// TASK ASSIGNMENT
export const setTaskAssignment = (payload) => {
  return {
    type: SET_TASK_ASSIGNMENT_LIST,
    payload: payload,
  };
};

export const updateTaskStatus = (payload) => {
  return {
    type: UPDATE_TASK_STATUS,
    payload: payload,
  };
};

// RESPONSE PLANNING
export const getResponsePlanList = (payload) => {
  return {
    type: GET_RESPONSE_PLAN_LIST,
    payload: payload,
  };
};

export const getResponsePlanById = (payload) => {
  return {
    type: GET_RESPONSE_PLAN_BY_ID,
    payload: payload,
  };
};

export const setResponsePlan = (payload) => {
  return {
    type: SET_RESPONSE_PLAN,
    payload: payload,
  };
};

export const setResponsePlanDetail = (payload) => {
  return {
    type: SET_RESPONSE_PLAN_DETAIL,
    payload: payload,
  };
};

export const setNode = (payload) => {
  return {
    type: SET_NODE,
    payload: payload,
  };
};

export const setEdge = (payload) => {
  return {
    type: SET_EDGE,
    payload: payload,
  };
};

export const createWorkFlow = (payload) => {
  return {
    type: CREATE_WORKFLOW,
    payload: payload,
  };
};

export const updateWorkFlow = (payload) => {
  return {
    type: UPDATE_WORKFLOW,
    payload: payload,
  };
};

export const getModuleResponsePlan = (payload) => {
  return {
    type: GET_WORKFLOW_MODULE,
    payload: payload,
  };
};

export const setModuleResponsePlan = (payload) => {
  return {
    type: SET_WORKFLOW_MODULE,
    payload: payload,
  };
};
