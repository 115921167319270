import {
  PrivacyKitPath,
  PrivacyKitDataSubjectRightManagementPath,
} from "../AppModuleConfig";

const PrivacyKitDataSubjectRightManagement = [
  {
    key: `data-subject-right-management-right-process`,
    path: `${PrivacyKitPath}${PrivacyKitDataSubjectRightManagementPath}/right-process`,
    title: "กระบวนการขอใช้สิทธิ",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `data-subject-right-management-webform`,
    path: `${PrivacyKitPath}${PrivacyKitDataSubjectRightManagementPath}/webform`,
    title: "สร้างเว็บฟอร์ม",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `data-subject-right-management-request`,
    path: `${PrivacyKitPath}${PrivacyKitDataSubjectRightManagementPath}/request`,
    title: "คำขอ",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `data-subject-right-management-task`,
    path: `${PrivacyKitPath}${PrivacyKitDataSubjectRightManagementPath}/task`,
    title: "งานทั้งหมด",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `data-subject-right-management-dashboard`,
    path: `${PrivacyKitPath}${PrivacyKitDataSubjectRightManagementPath}/dashboard`,
    title: "แดชบอร์ด",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  // {
  //   key: `data-subject-right-management-setting`,
  //   path: `${PrivacyKitPath}${PrivacyKitDataSubjectRightManagementPath}/setting`,
  //   title: "ตั้งค่าเริ่มใช้",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [
  //     {
  //       key: `data-subject-right-management-setting`,
  //       path: `${PrivacyKitPath}${PrivacyKitDataSubjectRightManagementPath}/setting/webform`,
  //       title: "เว็บฟอร์ม",
  //       breadcrumb: false,
  //       icon: null,
  //       submenu: [],
  //     },
  //     {
  //       key: `data-subject-right-management-setting`,
  //       path: `${PrivacyKitPath}${PrivacyKitDataSubjectRightManagementPath}/setting/workflow`,
  //       title: "ขั้นตอนการทำงาน",
  //       breadcrumb: false,
  //       icon: null,
  //       submenu: [],
  //     },
  //   ],
  // },
];

export default PrivacyKitDataSubjectRightManagement;
