import {
  LIST_USER_MANAGEMENT_USER,
  LIST_USER_MANAGEMENT_ORGANIZATION_SUCCESS,
  LIST_USER_MANAGEMENT_ORGANIZATION_FAILED,
  LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL_SUCCESS,
  LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL_FAILED,
  LIST_USER_MANAGEMENT_USER_SUCCESS,
  LIST_USER_MANAGEMENT_USER_FAILED,
  LIST_USER_MANAGEMENT_USER_DETAIL_SUCCESS,
  LIST_USER_MANAGEMENT_USER_DETAIL_FAILED,
  LIST_USER_MANAGEMENT_ROLES_SUCCESS,
  LIST_USER_MANAGEMENT_ROLES_FAILED,
  LIST_USER_MANAGEMENT_ROLES_DETAIL_SUCCESS,
  LIST_USER_MANAGEMENT_ROLES_DETAIL_FAILED,
  LIST_USER_MANAGEMENT_ROLES_PERMISSIONS_SUCCESS,
  LIST_USER_MANAGEMENT_ROLES_PERMISSIONS_FAILED,
  ADD_USER_MANAGEMENT_USER_SUCCESS,
  ADD_USER_MANAGEMENT_USER_FAILED,
  LIST_USER_MANAGEMENT_USER_GROUP_SUCCESS,
  LIST_USER_MANAGEMENT_USER_GROUP_FAILED,
  LIST_USER_MANAGEMENT_USER_IN_GROUP_SUCCESS,
  LIST_USER_MANAGEMENT_USER_IN_GROUP_FAILED,
  LIST_USER_MANAGEMENT_ROLE_IN_GROUP_SUCCESS,
  LIST_USER_MANAGEMENT_ROLE_IN_GROUP_FAILED,
  LIST_TENANT_USER_MANAGEMENT_USER_SUCCESS,
  LIST_TENANT_USER_MANAGEMENT_USER_FAILED,
} from "../constants/UserManagement";

export const listUserManagementUsers = () => ({
  type: LIST_USER_MANAGEMENT_USER,
});

export const listUserManagementOrganizationSuccess = (payload) => ({
  type: LIST_USER_MANAGEMENT_ORGANIZATION_SUCCESS,
  payload,
});

export const listUserManagementOrganizationFailed = (payload) => ({
  type: LIST_USER_MANAGEMENT_ORGANIZATION_FAILED,
  payload,
});
export const listUserManagementOrganizationDetailSuccess = (payload) => ({
  type: LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL_SUCCESS,
  payload,
});

export const listUserManagementOrganizationDetailFailed = (payload) => ({
  type: LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL_FAILED,
  payload,
});

export const listUserManagementUserSuccess = (payload) => ({
  type: LIST_USER_MANAGEMENT_USER_SUCCESS,
  payload,
});

export const listUserManagementUserFailed = (payload) => ({
  type: LIST_USER_MANAGEMENT_USER_FAILED,
  payload,
});
export const listUserManagementUserDetailSuccess = (payload) => ({
  type: LIST_USER_MANAGEMENT_USER_DETAIL_SUCCESS,
  payload,
});

export const listUserManagementUserDetailFailed = (payload) => ({
  type: LIST_USER_MANAGEMENT_USER_DETAIL_FAILED,
  payload,
});
export const listUserManagementRolesSuccess = (payload) => ({
  type: LIST_USER_MANAGEMENT_ROLES_SUCCESS,
  payload,
});

export const listUserManagementRolesFailed = (payload) => ({
  type: LIST_USER_MANAGEMENT_ROLES_FAILED,
  payload,
});
export const listUserManagementRolesDetailSuccess = (payload) => ({
  type: LIST_USER_MANAGEMENT_ROLES_DETAIL_SUCCESS,
  payload,
});

export const listUserManagementRolesDetailFailed = (payload) => ({
  type: LIST_USER_MANAGEMENT_ROLES_DETAIL_FAILED,
  payload,
});
export const listUserManagementRolesPermissionsSuccess = (payload) => ({
  type: LIST_USER_MANAGEMENT_ROLES_PERMISSIONS_SUCCESS,
  payload,
});

export const listUserManagementRolesPermissionsFailed = (payload) => ({
  type: LIST_USER_MANAGEMENT_ROLES_PERMISSIONS_FAILED,
  payload,
});
export const listUserManagementUserGroupSuccess = (payload) => ({
  type: LIST_USER_MANAGEMENT_USER_GROUP_SUCCESS,
  payload,
});

export const listUserManagementUserGroupFailed = (payload) => ({
  type: LIST_USER_MANAGEMENT_USER_GROUP_FAILED,
  payload,
});
export const listUserManagementUserInGroupSuccess = (payload) => ({
  type: LIST_USER_MANAGEMENT_USER_IN_GROUP_SUCCESS,
  payload,
});

export const listUserManagementUserInGroupFailed = (payload) => ({
  type: LIST_USER_MANAGEMENT_USER_IN_GROUP_FAILED,
  payload,
});
export const listUserManagementRoleInGroupSuccess = (payload) => ({
  type: LIST_USER_MANAGEMENT_ROLE_IN_GROUP_SUCCESS,
  payload,
});

export const listUserManagementRoleInGroupFailed = (payload) => ({
  type: LIST_USER_MANAGEMENT_ROLE_IN_GROUP_FAILED,
  payload,
});

export const addUserManagementUserSuccess = (payload) => ({
  type: ADD_USER_MANAGEMENT_USER_SUCCESS,
  payload,
});
export const addUserManagementUserFailed = (payload) => ({
  type: ADD_USER_MANAGEMENT_USER_FAILED,
  payload,
});

export const listTenantUserManagementUserSuccess = (payload) => ({
  type: LIST_TENANT_USER_MANAGEMENT_USER_SUCCESS,
  payload,
});

export const listTenantUserManagementUserFailed = () => ({
  type: LIST_TENANT_USER_MANAGEMENT_USER_FAILED,
});
