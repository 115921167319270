import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import {
  GET_TEMPLATE_DEVICE_LIST,
  GET_ASSESSMENT_BY_TYPE,
  CREATE_ASSET,
  UPDATE_ASSET,
  DELETE_ASSET,
  GET_OPTION,
  CREATE_OPTION,
  UPDATE_OPTION,
  DELETE_OPTION,
} from "redux/constants/CyberKitAssetInventory";
import ITAssetInventoryServices from "services/cyberkit/AssetInventoryService";
import {
  getAssetOption,
  setTemplateDeviceList,
  setAssetOption,
  setAssessment,
} from "redux/actions/CyberKitAssetInventory";
import { setGlobalLoading } from "redux/actions/CyberKitTool";

// ASSET
export function* getTemplateDeviceList() {
  yield takeEvery(GET_TEMPLATE_DEVICE_LIST, function* ({ payload }) {
    const resp = yield call(
      ITAssetInventoryServices.getListTemplateDevice,
      payload
    );

    const { statusCode, data } = resp;

    if (statusCode === 200) {
      yield put(setTemplateDeviceList(data));
    }
  });
}

export function* createAsset() {
  yield takeEvery(CREATE_ASSET, function* ({ payload }) {
    yield put(setGlobalLoading(true));
    const resp = yield call(ITAssetInventoryServices.createNewAsset, payload);

    const { statusCode } = resp;

    yield put(setGlobalLoading(false));
    if (statusCode === 200) {
      payload.next();
    }
  });
}

export function* updateAsset() {
  yield takeEvery(UPDATE_ASSET, function* ({ payload }) {
    yield put(setGlobalLoading(true));
    const resp = yield call(ITAssetInventoryServices.updateAsset, payload);

    const { statusCode } = resp;

    yield put(setGlobalLoading(false));
    if (statusCode === 200) {
      payload.next();
    }
  });
}

export function* deleteAsset() {
  yield takeEvery(DELETE_ASSET, function* ({ payload }) {
    yield put(setGlobalLoading(true));
    const resp = yield call(ITAssetInventoryServices.deleteAsset, payload);

    const { statusCode } = resp;

    if (statusCode === 200) {
      payload.next();
      yield put(setGlobalLoading(false));
    }
  });
}

export function* getAssetOptionSage() {
  yield takeEvery(GET_OPTION, function* ({ payload }) {
    const resp = yield call(
      ITAssetInventoryServices.getInventoryManagement,
      payload
    );

    const { statusCode, data } = resp;

    if (statusCode === 200) {
      yield put(
        setAssetOption({
          key: payload.attrbute_type,
          data: data,
        })
      );
    }
  });
}

export function* createAssetOption() {
  yield takeEvery(CREATE_OPTION, function* ({ payload }) {
    const resp = yield call(
      ITAssetInventoryServices.createInventoryManagement,
      payload
    );

    const { statusCode } = resp;

    if (statusCode === 200) {
      yield put(
        getAssetOption({
          attrbute_type: payload.attrbute_type,
          path: {
            attrbute_type: payload.attrbute_type,
          },
        })
      );
    }
  });
}

export function* updateAssetOption() {
  yield takeEvery(UPDATE_OPTION, function* ({ payload }) {
    const resp = yield call(
      ITAssetInventoryServices.updateInventoryManagement,
      payload
    );

    const { statusCode } = resp;

    if (statusCode === 200) {
      yield put(
        getAssetOption({
          attrbute_type: payload.attrbute_type,
          path: {
            attrbute_type: payload.attrbute_type,
          },
        })
      );
    }
  });
}

export function* deleteAssetOption() {
  yield takeEvery(DELETE_OPTION, function* ({ payload }) {
    const resp = yield call(
      ITAssetInventoryServices.deleteInventoryManagement,
      payload
    );

    const { statusCode } = resp;

    if (statusCode === 200) {
      yield put(
        getAssetOption({
          attrbute_type: payload.attrbute_type,
          path: {
            attrbute_type: payload.attrbute_type,
          },
        })
      );
    }
  });
}

// ASSESSMENT
export function* getAssessmentByType() {
  yield takeEvery(GET_ASSESSMENT_BY_TYPE, function* ({ payload }) {
    const resp = yield call(
      ITAssetInventoryServices.getListAssessment,
      payload
    );

    const { statusCode, data } = resp;

    if (statusCode === 200) {
      yield put(setAssessment(data));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getTemplateDeviceList),
    fork(createAsset),
    fork(updateAsset),
    fork(deleteAsset),
    fork(getAssetOptionSage),
    fork(createAssetOption),
    fork(updateAssetOption),
    fork(deleteAssetOption),
    fork(getAssessmentByType),
  ]);
}
