import { changeFormatDatetime } from "util/DateUtils";

const COLOUR_TAG = {
  true: { color: "#04D182", label: "เปิดการแจ้งเตือน" },
  false: { color: "#C74343", label: "ปิดการแจ้งเตือน" }
};

export default class NotificationModel {
  constructor(data) {
    this.id = data.id ? data.id : "0";
    this.index = data?.index;
    this.name = data.name ? data.name : "";
    this.tenancy = data.tenancy ? data.tenancy : "";
    this.provider = data.provider ? data.provider : "";
    this.provider_type = data.provider_type ? data.provider_type : "";
    this.configuration = data.configuration ? data.configuration : "";
    this.enable = data.enable ? data.enable : "";
    this.created_at = data.created_at ? data.created_at : "";
    this.updated_at = data.updated_at ? data.updated_at : "";
  }

  displayStatusColor = () => COLOUR_TAG[this.enable]?.color || "#C74343";
  displayStatusLabel = () => COLOUR_TAG[this.enable]?.label || "ปิดการแจ้งเตือน";
  displayCreatedAt = () =>
    this.created_at !== "0001-01-01T00:00:00Z"
      ? changeFormatDatetime(this.created_at, "DD/MM/YYYY h:mm A")
      : "-";
  displayUpdatedAt = () =>
    this.updated_at !== "0001-01-01T00:00:00Z"
      ? changeFormatDatetime(this.updated_at, "DD/MM/YYYY h:mm A")
      : "-";
}
