import { CyberKitPath } from "../AppModuleConfig";

const CyberKitSoarNavigation = (assessmentType) => [
  {
    key: `cyberkit-${assessmentType}-responsePlanning`,
    path: `${CyberKitPath}${assessmentType}/response-planning`,
    title: "แผนการดำเนินงาน",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `cyberkit-${assessmentType}-tashMangement`,
    path: `${CyberKitPath}${assessmentType}/task-manage`,
    title: "จัดการใบงาน",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `cyberkit-${assessmentType}-taskAssign`,
    path: `${CyberKitPath}${assessmentType}/task-assign`,
    title: "งานที่ได้รับมอบหมาย",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `cyberkit-${assessmentType}-dashboard`,
    path: `${CyberKitPath}${assessmentType}/dashboard`,
    title: "แดชบอร์ด",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
];

export default CyberKitSoarNavigation;
