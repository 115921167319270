import _ from "lodash";
import { v4 as uuid } from "uuid";

export const replaceObject = (items, key, data, identifyKey = "ObjectUUID") => {
  const destinationIndex = _.findIndex(
    items,
    (item) => item[identifyKey] === key
  );
  if (destinationIndex < 0) {
    return items;
  }
  items.splice(destinationIndex, 1, data);
  return items;
};

export const cloneObjectId = (items, sourceId, destinationId) => {
  if (!items) {
    return [];
  }

  return items.map((item) => {
    item[destinationId] = item[sourceId] ?? uuid();
    return item;
  });
};

export const removeObject = (items, key, identifyKey = "ObjectUUID") => {
  return _.filter(items, (item) => item[identifyKey] !== key);
};
