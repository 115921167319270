import { saveToLS, getFromLS } from "util/LocalStorage";
import {
  // ASSET
  SET_ASSETS,
  SET_TEMPLATE_DEVICE_LIST,
  SET_OPTION,

  // ASSESSMENT
  SET_ASSESSMENT,
} from "redux/constants/CyberKitAssetInventory";

const CYBERKIT = "CYBERKIT";
const CYBERKIT_IT_ASSET_INVENTORY = "CYBERKIT_IT_ASSET_INVENTORY";

const initState = {
  asset: {
    dataList: [],
    templateDevice: [],
    options: {},
  },
  assessment: {
    dataList: [],
  },
};

const CyberKitAssetInventory = (
  state = getFromLS(CYBERKIT, CYBERKIT_IT_ASSET_INVENTORY)
    ? getFromLS(CYBERKIT, CYBERKIT_IT_ASSET_INVENTORY)
    : initState,
  action
) => {
  const { type, payload } = action;
  let changed = state;

  switch (type) {
    case SET_ASSETS:
      changed = {
        ...state,
        asset: {
          ...state.asset,
          dataList: payload,
        },
      };
      break;

    case SET_TEMPLATE_DEVICE_LIST:
      changed = {
        ...state,
        asset: {
          ...state.asset,
          templateDevice: payload,
        },
      };
      break;

    case SET_OPTION:
      changed["asset"]["options"][payload.key] = payload.data;
      break;

    case SET_ASSESSMENT:
      changed = {
        ...state,
        assessment: {
          ...state.assessment,
          dataList: payload,
        },
      };
      break;

    default:
      break;
  }

  if (localStorage.getItem("auth") !== null)
    saveToLS(CYBERKIT, CYBERKIT_IT_ASSET_INVENTORY, changed);
  return changed;
};

export default CyberKitAssetInventory;
