import fetch from "util/Api";
import { PRIVACYKIT_DATA_SUBJECT_RIGHT_MANAGEMENT_BASE_URL as endpoint } from "config/EndpointConfig";

const DataSubjectRightManagementService = {};

DataSubjectRightManagementService.listWebForm = async () =>
  await fetch({
    url: `${endpoint}/webform?search`,
    method: "get",
  });

DataSubjectRightManagementService.listWebFormTemplate = async (language) =>
  await fetch({
    url: `${endpoint}/webform/template/${language}`,
    method: "get",
  });

DataSubjectRightManagementService.createWebForm = async (data) =>
  await fetch({
    url: `${endpoint}/webform`,
    method: "post",
    data: { data },
  });

DataSubjectRightManagementService.getWebForm = async (webformId, userKey) =>
  await fetch({
    url: `${endpoint}/webform/${webformId}?user_key=${userKey}`,
    method: "get",
  });

DataSubjectRightManagementService.getWebFormFields = async (language) =>
  await fetch({
    url: `${endpoint}/webform/field_lists/${language}`,
  });

DataSubjectRightManagementService.updateWebForm = async (
  webFormId,
  userKey,
  data
) =>
  await fetch({
    url: `${endpoint}/webform/${webFormId}?userKey=${userKey}`,
    method: "put",
    data: { data },
  });

DataSubjectRightManagementService.publishWebForm = async (webFormId, userKey) =>
  await fetch({
    url: `${endpoint}/webform/${webFormId}/publish?userKey=${userKey}`,
    method: "post",
  });

DataSubjectRightManagementService.deleteWebForm = async (webformId, userKey) =>
  await fetch({
    url: `${endpoint}/webform/${webformId}?user_key=${userKey}`,
    method: "delete",
  });

DataSubjectRightManagementService.listWebformVersion = async (
  webformId,
  userKey
) =>
  await fetch({
    url: `${endpoint}/webform/${webformId}/version?user_key=${userKey}`,
    method: "get",
  });

DataSubjectRightManagementService.getWebformVersion = async (
  webformId,
  webformVersionId,
  userKey
) =>
  await fetch({
    url: `${endpoint}/webform/${webformId}/version/${webformVersionId}?user_key=${userKey}`,
    method: "get",
  });

export default DataSubjectRightManagementService;
