export const TOGGLE_MANAGE_DATA_MAPPING_DATA_WAREHOUSE_MODAL =
  "TOGGLE_MANAGE_DATA_MAPPING_DATA_WAREHOUSE_MODAL";

export const LIST_DATA_MAPPING_DATA_WHERE_HOUSE =
  "LIST_DATA_MAPPING_DATA_WHERE_HOUSE";
export const LIST_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS =
  "LIST_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS";
export const LIST_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED =
  "LIST_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED";

export const MANAGE_DATA_MAPPING_DATA_WHERE_HOUSE =
  "MANAGE_DATA_MAPPING_DATA_WHERE_HOUSE";

export const CREATE_DATA_MAPPING_DATA_WHERE_HOUSE =
  "CREATE_DATA_MAPPING_DATA_WHERE_HOUSE";
export const CREATE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS =
  "CREATE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS";
export const CREATE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED =
  "CREATE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED";

export const GET_DATA_MAPPING_DATA_WHERE_HOUSE =
  "GET_DATA_MAPPING_DATA_WHERE_HOUSE";
export const GET_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS =
  "GET_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS";
export const GET_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED =
  "GET_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED";

export const UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE =
  "UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE";
export const UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS =
  "UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS";
export const UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED =
  "UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED";

export const TOGGLE_DELETE_DATA_MAPPING_DATA_WAREHOUSE_MODAL =
  "TOGGLE_DELETE_DATA_MAPPING_DATA_WAREHOUSE_MODAL";
export const DELETE_DATA_MAPPING_DATA_WHERE_HOUSE =
  "DELETE_DATA_MAPPING_DATA_WHERE_HOUSE";
export const DELETE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS =
  "DELETE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS";
export const DELETE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED =
  "DELETE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED";

export const LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP =
  "LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP";
export const LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP_SUCCESS =
  "LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP_SUCCESS";
export const LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP_FAILED =
  "LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP_FAILED";
