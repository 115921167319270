import { takeEvery, call, select, put } from "redux-saga/effects";
import {
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DEFAULT_WORKFLOW,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";
import {
  listDataSubjectRightManagementWorkflowSuccess,
  getDataSubjectRightManagementWorkflowSuccess,
  createDataSubjectRightManagementWorkflowSuccess,
  createDataSubjectRightManagementWorkflowFailed,
  updateDataSubjectRightManagementWorkflowSuccess,
  updateDataSubjectRightManagementWorkflowError,
  // publishDataSubjectRightManagementWorkflowSuccess,
  publishDataSubjectRightManagementWorkflowError,
  deleteDataSubjectRightManagementWorkflowSuccess,
  deleteDataSubjectRightManagementWorkflowError,
  listDataSubjectRightManagementTaskInWorkflowSuccess,
  createDataSubjectRightManagementTaskInWorkflowSuccess,
  createDataSubjectRightManagementTaskInWorkflowFailed,
  updateDataSubjectRightManagementTaskInWorkflowSuccess,
  updateDataSubjectRightManagementTaskInWorkflowFailed,
  deleteDataSubjectRightManagementTaskInWorkflowSuccess,
  deleteDataSubjectRightManagementTaskInWorkflowFailed,
} from "../../actions/PrivacyKitDataSubjectRightManagement";
import DataSubjectRightManagementService from "services/pdpakit/DataSubjectRightManagementService";
import {
  selectDataSubjectRightManagementWorkflow,
  selectDataSubjectRightManagementWorkflowData,
  selectDataSubjectRightManagementWorkflowDelete,
  selectDataSubjectRightManagementWorkflowDeleteTask,
  selectDataSubjectRightManagementWorkflowTaskModal,
} from "../../selectors/PrivacyKitDataSubjectRightManagement/Workflow";
import { selectListTenantUsers } from "../../selectors/UserManagement";
import Notification from "util/Notification";
import { v4 as uuid } from "uuid";
import _ from "lodash";

export function* listDataSubjectRightManagementWorkflow() {
  yield takeEvery(LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW, function* () {
    try {
      const response = yield call(
        DataSubjectRightManagementService.listWorkflow
      );
      yield put(
        listDataSubjectRightManagementWorkflowSuccess({
          listWorkflow: response.body.items,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });
}

export function* getDataSubjectRightManagementDefaultWorkflow() {
  yield takeEvery(
    GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DEFAULT_WORKFLOW,
    function* () {
      try {
        const response = yield call(
          DataSubjectRightManagementService.getWorkflowDefault
        );
        yield put(getDataSubjectRightManagementWorkflowSuccess(response.body));
      } catch (error) {
        console.log(error);
      }
    }
  );
}

export function* createDataSubjectRightManagementWorkflow() {
  yield takeEvery(
    CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
    function* ({ values }) {
      try {
        const { workflowData } = yield select(
          selectDataSubjectRightManagementWorkflow
        );
        const data = {
          ...workflowData,
          ...values,
          stages: workflowData.stages.map((stage) => ({
            ...stage,
            stage_id: uuid(),
          })),
        };
        const response = yield call(
          DataSubjectRightManagementService.createWorkflow,
          data
        );
        yield put(
          createDataSubjectRightManagementWorkflowSuccess(response.body)
        );
        Notification("success", "สร้างขั้นตอนการทำงานเสร็จเรียบร้อย");
      } catch (error) {
        if (error?.error === "workflow name already exist") {
          Notification("error", "ชื่อขั้นตอนการทำงานนี้มีอยู่แล้ว");
        } else {
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถสร้างขั้นตอนการทำงานได้"
          );
        }
        yield put(createDataSubjectRightManagementWorkflowFailed());
      }
    }
  );
}

export function* getDataSubjectRightManagementWorkflow() {
  yield takeEvery(
    GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
    function* ({ workflowId }) {
      try {
        const response = yield call(
          DataSubjectRightManagementService.getWorkflow,
          workflowId
        );
        yield put(getDataSubjectRightManagementWorkflowSuccess(response.body));
      } catch (error) {
        console.log(error);
      }
    }
  );
}

export function* updateDataSubjectRightManagementWorkflow() {
  yield takeEvery(
    UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
    function* ({ values }) {
      try {
        const { workflowData } = yield select(
          selectDataSubjectRightManagementWorkflow
        );
        const payload = {
          ...workflowData,
          ...values,
          stages: workflowData.stages.map((stage, index) => ({
            ...stage,
            order: index + 1,
          })),
        };
        const { body } = yield call(
          DataSubjectRightManagementService.updateWorkflow,
          workflowData.ObjectUUID,
          workflowData.created_by,
          payload
        );
        Notification("success", "แก้ไขขั้นตอนการทำงานเสร็จเรียบร้อย");
        yield put(updateDataSubjectRightManagementWorkflowSuccess(body));
      } catch (error) {
        console.error(error);
        Notification("error", "ไม่สามารถแก้ไขขั้นตอนการทำงานได้");
        yield put(updateDataSubjectRightManagementWorkflowError());
      }
    }
  );
}

export function* publishDataSubjectRightManagementWorkflow() {
  yield takeEvery(
    PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
    function* ({ values }) {
      try {
        const { workflowData } = yield select(
          selectDataSubjectRightManagementWorkflow
        );
        const payload = {
          ...workflowData,
          ...values,
          stages: workflowData.stages.map((stage, index) => ({
            ...stage,
            order: index + 1,
          })),
        };
        yield call(
          DataSubjectRightManagementService.updateWorkflow,
          workflowData.ObjectUUID,
          workflowData.created_by,
          payload
        );
        const { body } = yield call(
          DataSubjectRightManagementService.publishWorkflow,
          workflowData.ObjectUUID,
          workflowData.created_by
        );
        yield put(updateDataSubjectRightManagementWorkflowSuccess(body));
        Notification("success", "เผยแผร่ขั้นตอนการทำงานเสร็จเรียบร้อย");
      } catch (error) {
        console.error(error);
        Notification("error", "ไม่สามารถเผยแผร่ขั้นตอนการทำงานได้");
        yield put(publishDataSubjectRightManagementWorkflowError());
      }
    }
  );
}

export function* deleteDataSubjectRightManagementWorkflow() {
  yield takeEvery(DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW, function* () {
    try {
      const { data } = yield select(
        selectDataSubjectRightManagementWorkflowDelete
      );
      yield call(
        DataSubjectRightManagementService.deleteWorkflow,
        data.ObjectUUID
      );
      yield put(
        deleteDataSubjectRightManagementWorkflowSuccess(data.ObjectUUID)
      );
      Notification("success", "ลบขั้นตอนการทำงานเรียบร้อย");
    } catch (error) {
      yield put(deleteDataSubjectRightManagementWorkflowError());
      if (error?.errorCode === "Status still publish") {
        Notification(
          "error",
          "เกิดข้อผิดพลาด เนื่องจากกระบวนการขอใช้สิทธิอาจถูกใช้งาน"
        );
      } else {
        Notification("error", "เกิดข้อผิดพลาด ไม่สามารถลบขั้นตอนการทำงานได้");
      }
    }
  });
}

export function* listDataSubjectRightManagementWorkflowTask() {
  yield takeEvery(
    LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
    function* ({ workflowId }) {
      try {
        const { body } = yield call(
          DataSubjectRightManagementService.listTaskInWorkflow,
          workflowId
        );
        yield put(listDataSubjectRightManagementTaskInWorkflowSuccess(body));
      } catch (error) {
        console.log(error);
      }
    }
  );
}

const initialTask = {
  subtask_required: false,
  comment_required: false,
  resolution_required: false,
  deadline: 3,
  reminders: [],
  object_class: "workflow/subtask",
  resolution: null,
  comments: [],
};

export function* createDataSubjectRightManagementWorkflowTask() {
  yield takeEvery(
    CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
    function* ({ values }) {
      try {
        const listUser = yield select(selectListTenantUsers);
        const { stageOrder, workflowData } = yield select(
          selectDataSubjectRightManagementWorkflow
        );
        const payload = {
          ...initialTask,
          ...values,
          stage_title: workflowData.stages[stageOrder].title,
          stage_order: `${stageOrder}`,
          stage_id: workflowData.stages[stageOrder].stage_id,
          ObjectUUID: uuid(),
          approver: _.find(listUser, { ukey: values.assignee }),
        };
        const { body } = yield call(
          DataSubjectRightManagementService.createTaskInWorkflow,
          workflowData.ObjectUUID,
          payload
        );
        yield put(createDataSubjectRightManagementTaskInWorkflowSuccess(body));
        Notification("success", "สร้างงานเรียบร้อยแล้ว");
      } catch (error) {
        console.error(error);
        Notification("error", "เกิดข้อผิดพลาดไม่สามารถสร้างงานได้");
        yield put(createDataSubjectRightManagementTaskInWorkflowFailed());
      }
    }
  );
}

export function* updateDataSubjectRightManagementWorkflowTask() {
  yield takeEvery(
    UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
    function* ({ values }) {
      try {
        const listUser = yield select(selectListTenantUsers);
        const workflowData = yield select(
          selectDataSubjectRightManagementWorkflowData
        );
        const { data } = yield select(
          selectDataSubjectRightManagementWorkflowTaskModal
        );
        const payload = {
          ...data,
          ...values,
          "Subtask[ObjID]": undefined,
          approver: _.find(listUser, { ukey: values.assignee }),
        };
        const { body } = yield call(
          DataSubjectRightManagementService.updateTaskInWorkflow,
          data.ObjectUUID,
          workflowData.ObjectUUID,
          payload
        );
        yield put(updateDataSubjectRightManagementTaskInWorkflowSuccess(body));
        Notification("success", "แก้ไขงานเรียบร้อยแล้ว");
      } catch (error) {
        console.error(error);
        Notification("error", "เกิดข้อผิดพลาดไม่สามารถแก้ไขงานได้");
        yield put(updateDataSubjectRightManagementTaskInWorkflowFailed());
      }
    }
  );
}

export function* deleteDataSubjectRightManagementWorkflowTask() {
  yield takeEvery(
    DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
    function* () {
      try {
        const { data } = yield select(
          selectDataSubjectRightManagementWorkflowDeleteTask
        );
        const workflowData = yield select(
          selectDataSubjectRightManagementWorkflowData
        );
        yield call(
          DataSubjectRightManagementService.deleteTaskInWorkflow,
          data.ObjectUUID,
          workflowData.ObjectUUID,
          workflowData.created_by
        );
        yield put(
          deleteDataSubjectRightManagementTaskInWorkflowSuccess(data.ObjectUUID)
        );
      } catch (error) {
        console.error(error);
        Notification("error", "เกิดข้อผิดพลาดไม่สามารถลบงานได้");
        yield put(deleteDataSubjectRightManagementTaskInWorkflowFailed());
      }
    }
  );
}
