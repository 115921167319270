import { combineReducers } from "redux";
import Settings from "./Settings";
import Common from "./Common";
import Auth from "./Auth";
import UserManagement from "./UserManagement";

import PrivacyKitCookieConsentManagement from "./PrivacyKitCookieConsentManagement";
import PrivacyKitDataBreachNotification from "./PrivacyKitDataBreachNotification";
import PrivacyKitDataSubjectRightManagement from "./PrivacyKitDataSubjectRightManagement";
import PrivacyKitConsentManagement from "./PrivacyKitConsentManagement";
import PrivacyKitIncidentResponseManagement from "./PrivacyKitIncidentResponseManagement";
import PrivacyKitPrivacyAssessmentAutomation from "./PrivacyKitPrivacyAssessmentAutomation";
import PrivacyKitAssessmentAutomation from "./PrivacyKitAssessmentAutomation";
import PrivacyKitDataClassificationAndDiscovery from "./PrivacyKitDataClassificationAndDiscovery";
import PrivacyKitDataMapping from "./PrivacyKitDataMapping";
import PrivacyKitCompliantManagement from "./PrivacyKitCompliantManagement";
import PrivacyKitPolicyAndNoticeManagement from "./PrivacyKitPolicyAndNoticeManagement";

import CyberKitTool from "./CyberKitTool";
import CyberKitLogManagement from "./CyberKitLogManagement";
import CyberKitSiem from "./CyberKitSiem";
import CyberKitSoar from "./CyberKitSoar";
import CyberKitVulnerabilityAssessment from "./CyberKitVulnerabilityAssessment";
import CyberKitAssetInventory from "./CyberKitAssetInventory";
import CyberKitThreatIntelligence from "./CyberKitThreatIntelligence";

import IPrivacyKitDataMappingReducers from "./IPrivacyKitDataMapping";

import { connectRouter } from "connected-react-router";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    auth: Auth,
    userManagement: UserManagement,
    privacykitCookieConsentManagement: PrivacyKitCookieConsentManagement,
    privacyKitDataBreachNotification: PrivacyKitDataBreachNotification,
    privacykitDataSubjectRightManagement: PrivacyKitDataSubjectRightManagement,
    privacyKitConsentManagement: PrivacyKitConsentManagement,
    privacyKitIncidentResponseManagement: PrivacyKitIncidentResponseManagement,
    privacyKitPrivacyAssessmentAutomation:
      PrivacyKitPrivacyAssessmentAutomation,
    privacykitAssessmentAutomation: PrivacyKitAssessmentAutomation,
    privacyKitDataClassificationAndDiscovery:
      PrivacyKitDataClassificationAndDiscovery,
    privacykitDataMapping: PrivacyKitDataMapping,
    privacykitCompliantManagement: PrivacyKitCompliantManagement,
    privacyKitPolicyAndNoticeManagement: PrivacyKitPolicyAndNoticeManagement,
    cyberkitTool: CyberKitTool,
    cyberKitLogManagement: CyberKitLogManagement,
    cyberKitSiem: CyberKitSiem,
    cyberKitSoar: CyberKitSoar,
    cyberKitVulnerabilityAssessment: CyberKitVulnerabilityAssessment,
    cyberKitAssetInventory: CyberKitAssetInventory,
    cyberKitThreatIntelligence: CyberKitThreatIntelligence,
    iPrivacyKitDataMapping: IPrivacyKitDataMappingReducers,
  });

export default createRootReducer;
