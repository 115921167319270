import {
  LIST_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
  CREATE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
  GET_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_NOTIFICATION_STATUS_SUCCESS,
  DELETE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
  LIST_INCIDENT_RESPONSE_PLAN_SUCCESS,
  GET_INCIDENT_RESPONSE_PLAN_SUCCESS,
  CREATE_INCIDENT_RESPONSE_PLAN_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_PLAN_SUCCESS,
  PUBLISH_INCIDENT_RESPONSE_PLAN_SUCCESS,
  DELETE_INCIDENT_RESPONSE_PLAN_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_PLAN_STAGE,
  UPDATE_INCIDENT_RESPONSE_PLAN_STAGE_SUCCESS,
  TOGGLE_INCIDENT_RESPONSE_PLAN_STAGE_BACK_BUTTON,
  TOGGLE_INCIDENT_RESPONSE_PLAN_STAGE_NEXT_BUTTON,
  LIST_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
  CREATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
  GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
  DELETE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
  LIST_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
  GET_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
  CREATE_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
  PUBLISH_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
  DELETE_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
  LIST_INCIDENT_RESPONSE_WEBFORM_VERSION_SUCCESS,
  GET_INCIDENT_RESPONSE_WEBFORM_VERSION_SUCCESS,
  GET_INCIDENT_RESPONSE_WEBFORM_WEBFORM_FIELDS,
  GET_INCIDENT_RESPONSE_WEBFORM_WEBFORM_FIELDS_SUCCESS,
  LIST_INCIDENT_RESPONSE_CASE_SUCCESS,
  CREATE_INCIDENT_RESPONSE_CASE_SUCCESS,
  GET_INCIDENT_RESPONSE_CASE_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_CASE_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_CASE_ERROR,
  UPDATE_INCIDENT_RESPONSE_CASE_STAGE_SUCCESS,
  DELETE_INCIDENT_RESPONSE_CASE_SUCCESS,
  LIST_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
  CREATE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
  GET_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
  DELETE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
  LIST_INCIDENT_RESPONSE_CASE_COMMENT_SUCCESS,
  CREATE_INCIDENT_RESPONSE_CASE_COMMENT_SUCCESS,
  LIST_INCIDENT_RESPONSE_CASE_HISTORY_SUCCESS,
  LIST_INCIDENT_RESPONSE_CASE_HIGH_SUCCESS,
  SENT_INCIDENT_RESPONSE_CASE_HIGH_SUCCESS,
  GET_INCIDENT_RESPONSE_CASE_HIGH_TEMPLATE_SUCCESS,
  LIST_INCIDENT_RESPONSE_TASK_ALL_SUCCESS,
  GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE_SUCCESS,
  GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE_ERROR,
  PUBLISH_INCIDENT_RESPONSE_CASE_SUCCESS,
} from "redux/constants/PrivacyKitDataBreachNotification";

export const listIncidentResponseNotificationSuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const createIncidentResponseNotificationSuccess = (payload) => {
  return {
    type: CREATE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const getIncidentResponseNotificationSuccess = (payload) => {
  return {
    type: GET_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const updateIncidentResponseNotificationSuccess = (payload) => {
  return {
    type: UPDATE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const updateIncidentResponseNotificationStatusSuccess = (payload) => {
  return {
    type: UPDATE_INCIDENT_RESPONSE_NOTIFICATION_STATUS_SUCCESS,
    payload,
  };
};

export const deleteIncidentResponseNotificationSuccess = (payload) => {
  return {
    type: DELETE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const listIncidentResponsePlanSuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_PLAN_SUCCESS,
    payload,
  };
};

export const getIncidentResponsePlanSuccess = (payload) => {
  return {
    type: GET_INCIDENT_RESPONSE_PLAN_SUCCESS,
    payload,
  };
};

export const createIncidentResponsePlanSuccess = (payload) => {
  return {
    type: CREATE_INCIDENT_RESPONSE_PLAN_SUCCESS,
    payload,
  };
};

export const updateIncidentResponsePlanSuccess = (payload) => {
  return {
    type: UPDATE_INCIDENT_RESPONSE_PLAN_SUCCESS,
    payload,
  };
};

export const publishIncidentResponsePlanSuccess = (payload) => {
  return {
    type: PUBLISH_INCIDENT_RESPONSE_PLAN_SUCCESS,
    payload,
  };
};

export const deleteIncidentResponsePlanSuccess = (payload) => {
  return {
    type: DELETE_INCIDENT_RESPONSE_PLAN_SUCCESS,
    payload,
  };
};

export const updateIncidentResponsePlanStage = (payload) => ({
  type: UPDATE_INCIDENT_RESPONSE_PLAN_STAGE,
  payload,
});

export const updateIncidentResponsePlanStageSuccess = (payload) => ({
  type: UPDATE_INCIDENT_RESPONSE_PLAN_STAGE_SUCCESS,
  payload,
});

export const toggleIncidentResponsePlanStageNextButton = (payload) => ({
  type: TOGGLE_INCIDENT_RESPONSE_PLAN_STAGE_NEXT_BUTTON,
  payload,
});

export const listTaskInResponsePlanSuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
    payload,
  };
};

export const createTaskInResponsePlanSuccess = (payload) => {
  return {
    type: CREATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
    payload,
  };
};

export const getTaskInResponsePlanSuccess = (payload) => {
  return {
    type: GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
    payload,
  };
};

export const updateTaskInResponsePlanSuccess = (payload) => {
  return {
    type: UPDATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
    payload,
  };
};

export const deleteTaskInResponsePlanSuccess = (payload) => {
  return {
    type: DELETE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS,
    payload,
  };
};

export const toggleIncidentResponsePlanStageBackButton = (payload) => ({
  type: TOGGLE_INCIDENT_RESPONSE_PLAN_STAGE_BACK_BUTTON,
  payload,
});

export const listIncidentResponseWebformSuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
    payload,
  };
};

export const getIncidentResponseWebformSuccess = (payload) => {
  return {
    type: GET_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
    payload,
  };
};

export const createIncidentResponseWebformSuccess = (payload) => {
  return {
    type: CREATE_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
    payload,
  };
};

export const updateIncidentResponseWebformSuccess = (payload) => {
  return {
    type: UPDATE_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
    payload,
  };
};

export const publishIncidentResponseWebformSuccess = (payload) => {
  return {
    type: PUBLISH_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
    payload,
  };
};

export const deleteIncidentResponseWebformSuccess = (payload) => {
  return {
    type: DELETE_INCIDENT_RESPONSE_WEBFORM_SUCCESS,
    payload,
  };
};

export const listIncidentResponseWebformVersionSuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_WEBFORM_VERSION_SUCCESS,
    payload,
  };
};

export const getIncidentResponseWebformVersionSuccess = (payload) => {
  return {
    type: GET_INCIDENT_RESPONSE_WEBFORM_VERSION_SUCCESS,
    payload,
  };
};

export const getIncidentResponseWebformFields = () => ({
  type: GET_INCIDENT_RESPONSE_WEBFORM_WEBFORM_FIELDS,
});

export const getIncidentResponseWebformFieldsSuccess = (payload) => ({
  type: GET_INCIDENT_RESPONSE_WEBFORM_WEBFORM_FIELDS_SUCCESS,
  payload,
});

export const listIncidentResponseCaseSuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_CASE_SUCCESS,
    payload,
  };
};

export const createIncidentResponseCaseSuccess = (payload) => {
  return {
    type: CREATE_INCIDENT_RESPONSE_CASE_SUCCESS,
    payload,
  };
};

export const getIncidentResponseCaseSuccess = (payload) => {
  return {
    type: GET_INCIDENT_RESPONSE_CASE_SUCCESS,
    payload,
  };
};

export const updateIncidentResponseCaseSuccess = (payload) => {
  return {
    type: UPDATE_INCIDENT_RESPONSE_CASE_SUCCESS,
    payload,
  };
};

export const updateIncidentResponseCaseError = (payload) => {
  return {
    type: UPDATE_INCIDENT_RESPONSE_CASE_ERROR,
    payload,
  };
};

export const updateIncidentResponseCaseStageSuccess = (payload) => {
  return {
    type: UPDATE_INCIDENT_RESPONSE_CASE_STAGE_SUCCESS,
    payload,
  };
};

export const deleteIncidentResponseCaseSuccess = (payload) => {
  return {
    type: DELETE_INCIDENT_RESPONSE_CASE_SUCCESS,
    payload,
  };
};

export const listTaskInCaseSuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
    payload,
  };
};

export const createTaskInCaseSuccess = (payload) => {
  return {
    type: CREATE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
    payload,
  };
};

export const getTaskInCaseSuccess = (payload) => {
  return {
    type: GET_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
    payload,
  };
};

export const updateTaskInCaseSuccess = (payload) => {
  return {
    type: UPDATE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
    payload,
  };
};

export const deleteTaskInCaseSuccess = (payload) => {
  return {
    type: DELETE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS,
    payload,
  };
};

export const listIncidentResponseCaseCommentSuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_CASE_COMMENT_SUCCESS,
    payload,
  };
};

export const createIncidentResponseCaseCommentSuccess = (payload) => {
  return {
    type: CREATE_INCIDENT_RESPONSE_CASE_COMMENT_SUCCESS,
    payload,
  };
};

export const listIncidentResponseCaseHistorySuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_CASE_HISTORY_SUCCESS,
    payload,
  };
};

export const listIncidentResponseCaseHighSuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_CASE_HIGH_SUCCESS,
    payload,
  };
};

export const sentIncidentResponseCaseHighSuccess = (payload) => {
  return {
    type: SENT_INCIDENT_RESPONSE_CASE_HIGH_SUCCESS,
    payload,
  };
};

export const getIncidentResponseCaseHighTemplateSuccess = (payload) => {
  return {
    type: GET_INCIDENT_RESPONSE_CASE_HIGH_TEMPLATE_SUCCESS,
    payload,
  };
};

export const listIncidentResponseTaskAllSuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_TASK_ALL_SUCCESS,
    payload,
  };
};

export const getIncidentResponseWebformTemplateSuccess = (payload) => ({
  type: GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE_SUCCESS,
  payload,
});

export const getIncidentResponseWebformTemplateError = (payload) => ({
  type: GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE_ERROR,
  payload,
});

export const publishIncidentResponseCaseSuccess = (payload) => ({
  type: PUBLISH_INCIDENT_RESPONSE_CASE_SUCCESS,
  payload,
});
