import {
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE_SUCCESS,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE_FAILURE,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_FAILED,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS,
  SEARCH_DATA_TASK,
  SEARCH_DATA_TASK_SUCCESS,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";

export const listDataSubjectRightManagementTask = () => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
});

export const listDataSubjectRightManagementTaskSuccess = (payload) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS,
  payload,
});

export const listDataSubjectRightManagementTaskNextPage = (nextPageToken) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE,
  nextPageToken,
});

export const listDataSubjectRightManagementTaskNextPageSuccess = (payload) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE_SUCCESS,
  payload,
});

export const listDataSubjectRightManagementTaskNextPageFailure = () => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE_FAILURE,
});

export const toggleDataSubjectRightManagementTask = (payload) => ({
  type: TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
  payload,
});

export const getDataSubjectRightManagementTask = (payload) => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
  payload,
});

export const getDataSubjectRightManagementTaskSuccess = (payload) => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS,
  payload,
});

export const updateDataSubjectRightManagementTask = (values) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
  values,
});

export const updateDataSubjectRightManagementTaskSuccess = (payload) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS,
  payload,
});

export const updateDataSubjectRightManagementTaskFailed = (payload) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_FAILED,
  payload,
});

export const searchDataTask = (search) => ({
  type: SEARCH_DATA_TASK,
  search,
});

export const searchDataTaskSuccess = (payload) => ({
  type: SEARCH_DATA_TASK_SUCCESS,
  payload,
});
