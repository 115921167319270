// TASK MANAGEMENT
export const SET_TASK_MANAGEMENT_LIST = "SET_TASK_MANAGEMENT_LIST";
export const GET_TASK_MANAGEMENT_DETAIL = "GET_TASK_MANAGEMENT_DETAIL";
export const SET_TASK_MANAGEMENT_DETAIL = "SET_TASK_MANAGEMENT_DETAIL";
export const GET_JOBS = "GET_JOBS";
export const SET_JOBS = "SET_JOBS";
export const SET_JOB_LOADING = "SET_JOB_LOADING";
export const GET_COMMENTS = "GET_COMMENTS";
export const SET_COMMENTS = "SET_COMMENTS";
export const ON_COMMENTS = "ON_COMMENTS";
export const SET_COMMENT_LOADING = "SET_COMMENT_LOADING";
export const SET_NEXT_PAGE_TOKEN_COMMENTS = "SET_NEXT_PAGE_TOKEN_COMMENTS";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const SET_ACTIVITY = "SET_ACTIVITY";
export const SET_ACTIVITY_LOADING = "SET_ACTIVITY_LOADING";
export const SET_NEXT_PAGE_TOKEN_ACTIVITY = "SET_NEXT_PAGE_TOKEN_ACTIVITY";

// TASK ASSIGNMENT
export const SET_TASK_ASSIGNMENT_LIST = "SET_TASK_ASSIGNMENT_LIST";
export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";

// RESPONSE PLANNING
export const GET_RESPONSE_PLAN_LIST = "GET_RESPONSE_PLAN_LIST";
export const GET_RESPONSE_PLAN_BY_ID = "GET_RESPONSE_PLAN_BY_ID";
export const SET_RESPONSE_PLAN = "SET_RESPONSE_PLAN";
export const SET_RESPONSE_PLAN_DETAIL = "SET_RESPONSE_PLAN_DETAIL";
export const SET_EDGE = "SET_EDGE";
export const SET_NODE = "SET_NODE";
export const CREATE_WORKFLOW = "CREATE_WORKFLOW";
export const UPDATE_WORKFLOW = "UPDATE_WORKFLOW";
export const GET_WORKFLOW_MODULE = "GET_WORKFLOW_MODULE";
export const SET_WORKFLOW_MODULE = "SET_WORKFLOW_MODULE";
