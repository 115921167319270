import fetch from "util/Api";
import { PRIVACYKIT_DATA_SUBJECT_RIGHT_MANAGEMENT_BASE_URL as endpoint } from "config/EndpointConfig";

const DataSubjectRightManagementService = {};

DataSubjectRightManagementService.getDashboard = async ({
  startDate,
  endDate,
}) =>
  await fetch({
    url: `${endpoint}/request/dashboard?type=yes&start_date=${startDate}&end_date=${endDate}`,
    method: "get",
  });

DataSubjectRightManagementService.getDashboardRequest = async ({
  startDate,
  endDate,
}) =>
  await fetch({
    url: `${endpoint}/request?search&filter=all&start_date=${startDate}&end_date=${endDate}`,
    method: "get",
  });

export default DataSubjectRightManagementService;
