import {
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_CREATE,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_TEMPLATE,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_TEMPLATE_SUCCESS,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR,
  TOGGLE_DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELDS,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELDS_SUCCESS,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SETTING,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELD_DELETE,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM_TEXT,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM_STYLE,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  TOGGLE_SELECT_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_WORKFLOW,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_WORKFLOW,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR,
  TOGGLE_PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_SUCCESS,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_ERROR,
  TOGGLE_PREVIEW_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_SUCCESS,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_ERROR,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SCRIPT,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_PREVIEW,
  RESET_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_DATA,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";

export const listDataSubjectRightManagementWebForm = () => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
});

export const listDataSubjectRightManagementWebFormSuccess = (payload) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  payload,
});

export const toggleDataSubjectRightManagementWebFormCreate = () => ({
  type: TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_CREATE,
});

export const listDataSubjectRightManagementWebFormTemplate = () => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_TEMPLATE,
});

export const listDataSubjectRightManagementWebFormTemplateSuccess = (
  payload
) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_TEMPLATE_SUCCESS,
  payload,
});

export const createDataSubjectRightManagementWebForm = (values) => ({
  type: CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  values,
});

export const createDataSubjectRightManagementWebFormSuccess = (payload) => ({
  type: CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  payload,
});

export const createDataSubjectRightManagementWebFormError = (payload) => ({
  type: CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR,
  payload,
});

export const toggleDataSubjectRightManagementWebFormDelete = (payload) => ({
  type: TOGGLE_DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  payload,
});

export const deleteDataSubjectRightManagementWebForm = (payload) => ({
  type: DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  payload,
});

export const deleteDataSubjectRightManagementWebFormSuccess = (webFormId) => ({
  type: DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  webFormId,
});

export const deleteDataSubjectRightManagementWebFormError = () => ({
  type: DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR,
});

export const getDataSubjectRightManagementWebForm = (webformId, userKey) => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  webformId,
  userKey,
});

export const getDataSubjectRightManagementWebFormSuccess = (payload) => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  payload,
});

export const getDataSubjectRightManagementWebFormFields = () => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELDS,
});

export const getDataSubjectRightManagementWebFormFieldsSuccess = (payload) => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELDS_SUCCESS,
  payload,
});

export const toggleDataSubjectRightManagementWebFormSetting = (type, data) => ({
  type: TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SETTING,
  settingType: type,
  data,
});

export const toggleDataSubjectRightManagementWebFormFieldDelete = (
  payload
) => ({
  type: TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELD_DELETE,
  payload,
});

export const updateDataSubjectRightManagementWebFormForm = (form) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM,
  form,
});

export const updateDataSubjectRightManagementWebFormFormText = (formText) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM_TEXT,
  formText,
});

export const updateDataSubjectRightManagementWebFormFormStyle = (
  formStyle
) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FORM_STYLE,
  formStyle,
});

export const updateDataSubjectRightManagementWebForm = (
  webFormId,
  userKey,
  values
) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  webFormId,
  userKey,
  values,
});

export const updateDataSubjectRightManagementWebFormSuccess = (payload) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SUCCESS,
  payload,
});

export const updateDataSubjectRightManagementWebFormError = () => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR,
});

export const toggleSelectDataSubjectRightManagementWebFormWorkflow = () => ({
  type: TOGGLE_SELECT_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_WORKFLOW,
});

export const updateDataSubjectRightManagementWebFormWorkflow = (
  workflowId
) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_WORKFLOW,
  workflowId,
});

export const togglePublishDataSubjectRightManagementWebForm = () => ({
  type: TOGGLE_PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
});

export const publishDataSubjectRightManagementWebForm = (values) => ({
  type: PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  values,
});

export const publishDataSubjectRightManagementWebFormError = (values) => ({
  type: PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_ERROR,
  values,
});

export const toggleDataSubjectRightManagementWebFormScript = (payload) => ({
  type: TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_SCRIPT,
  payload,
});

export const listDataSubjectRightManagementWebFormVersion = () => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION,
});

export const listDataSubjectRightManagementWebFormVersionSuccess = (
  listVersion
) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_SUCCESS,
  listVersion,
});

export const listDataSubjectRightManagementWebFormVersionError = () => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_ERROR,
});

export const togglePreviewDataSubjectRightManagementWebFormVersion = (
  versionId
) => ({
  type: TOGGLE_PREVIEW_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION,
  versionId,
});

export const getDataSubjectRightManagementWebFormVersion = (versionId) => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION,
  versionId,
});

export const getDataSubjectRightManagementWebFormVersionSuccess = (data) => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_SUCCESS,
  data,
});

export const getDataSubjectRightManagementWebFormVersionError = () => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION_ERROR,
});

export const toggleDataSubjectRightManagementWebFormPreview = (payload) => ({
  type: TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_PREVIEW,
  payload,
});

export const resetDataSubjectRightManagementWebFormData = () => ({
  type: RESET_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_DATA,
});
