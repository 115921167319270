import { fork, all } from "redux-saga/effects";
import {
  listPrivacyAssessmentAutomation,
  getPrivacyAssessmentAutomation,
  createPrivacyAssessmentAutomation,
  updatePrivacyAssessmentAutomation,
  publishPrivacyAssessmentAutomation,
  deletePrivacyAssessmentAutomation,
} from "./assessmentAutomation";
import {
  listPrivacyAssessmentAutomationRisk,
  getPrivacyAssessmentAutomationRisk,
  createPrivacyAssessmentAutomationRisk,
  updatePrivacyAssessmentAutomationRisk,
  publishPrivacyAssessmentAutomationRisk,
  deletePrivacyAssessmentAutomationRisk,
} from "./riskAssessmentAutomation";
import { getPrivacyAssessmentAutomationDataMappingInventory } from "./dataMappingAssessmentAutomation";
import { getPrivacyAssessmentAutomationDashboard } from "./dashboardAssessmentAutomation";

export default function* rootSaga() {
  yield all([
    fork(listPrivacyAssessmentAutomation),
    fork(getPrivacyAssessmentAutomation),
    fork(createPrivacyAssessmentAutomation),
    fork(updatePrivacyAssessmentAutomation),
    fork(publishPrivacyAssessmentAutomation),
    fork(deletePrivacyAssessmentAutomation),
    fork(listPrivacyAssessmentAutomationRisk),
    fork(getPrivacyAssessmentAutomationRisk),
    fork(createPrivacyAssessmentAutomationRisk),
    fork(updatePrivacyAssessmentAutomationRisk),
    fork(publishPrivacyAssessmentAutomationRisk),
    fork(deletePrivacyAssessmentAutomationRisk),
    fork(getPrivacyAssessmentAutomationDataMappingInventory),
    fork(getPrivacyAssessmentAutomationDashboard),
  ]);
}
