import { notification } from "antd";
import { loadLocaleData } from "../lang";

const Notification = (type, description) => {
  const { messages } = loadLocaleData();

  notification[type]({
    message: messages[`notification.${type}`] || type,
    placement: "bottomLeft",
    description: messages[description] || description,
  });
};

export default Notification;

export const ErrorFormNotification = (error) => {
  if (error.errorFields) {
    Notification("error", error.errorFields[0].errors[0]);
  } else {
    Notification("error", "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งในภายหลัง");
  }
};
