import {
  LIST_ASSESSMENT_AUTOMATION_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_FAILED,
  CREATED_ASSESSMENT_AUTOMATION_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_DATA_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_SUCCESS,
  PUBLISH_ASSESSMENT_AUTOMATION_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_TEMPLATE_FAILED,
  GET_ASSESSMENT_AUTOMATION_TEMPLATE_TYPE_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_TEMPLATE_BY_TYPE_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
  ARCHIVED_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS,
  ACTIVATED_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_DELETED_SUCCESS,
  ACTIVATED_ASSESSMENT_AUTOMATION_DELETED_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_DELETED_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_RISK_FAILED,
  LIST_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_PROCESS_FAILED,
  UPDATE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_RESULT_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_RESULT_FAILED,
  LIST_ASSESSMENT_AUTOMATION_RULES_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_RULES_FAILED,
  CREATE_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_RULES_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_RULES_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_RULES_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED,
  CREATE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED,
  UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED,
  DELETE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED,
  UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT_FAILED,
  LIST_ASSESSMENT_AUTOMATION_SENT_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_SENT_FAILED,
  LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE,
  LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE_ERROR,
  GET_ASSESSMENT_AUTOMATION_REPORT_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_REPORT_DATA_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_DATA_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_TASK_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_TASK_IN_ASSESSMENT_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_TASK_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_TASK_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_TASK_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_DATA_INVENTORY,
  GET_ASSESSMENT_AUTOMATION_DATA_INVENTORY_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_DATA_INVENTORY_ERROR,
  ADD_ASSESSMENT_CONTROL_SUCCESS,
  ADD_ASSESSMENT_CONTROL_FAILED,
  GET_ASSESSMENT_CONTROL_SUCCESS,
  GET_ASSESSMENT_CONTROL_FAILED,
  DELETE_ASSESSMENT_CONTROL_SUCCESS,
  DELETE_ASSESSMENT_CONTROL_FAILED,
  LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE,
  LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE_ERROR,
  LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE,
  LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE_ERROR,
  LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE_SUCCESS,
} from "../constants/PrivacyKitAssessmentAutomation";

export const listAssessmentAutomationSuccess = (payload) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_SUCCESS,
    payload,
  };
};

export const listAssessmentAutomationFailed = (payload) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_FAILED,
    payload,
  };
};

export const createAssessmentAutomationSuccess = (payload) => {
  return {
    type: CREATED_ASSESSMENT_AUTOMATION_SUCCESS,
    payload,
  };
};

export const getAssessmentAutomationDataSuccess = (payload) => {
  return {
    type: GET_ASSESSMENT_AUTOMATION_DATA_SUCCESS,
    payload,
  };
};

export const updateAssessmentAutomationSuccess = (payload) => {
  return {
    type: UPDATE_ASSESSMENT_AUTOMATION_SUCCESS,
    payload,
  };
};

export const publishAssessmentAutomationSuccess = (payload) => {
  return {
    type: PUBLISH_ASSESSMENT_AUTOMATION_SUCCESS,
    payload,
  };
};

export const deleteAssessmentAutomationSuccess = (payload) => {
  return {
    type: DELETE_ASSESSMENT_AUTOMATION_SUCCESS,
    payload,
  };
};

//ASSESSMENT ACTIVE
export const listAssessmentAutomationActiveSuccess = (payload) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
    payload,
  };
};

export const createAssessmentAutomationActiveSuccess = (payload) => {
  return {
    type: CREATE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
    payload,
  };
};

export const getAssessmentAutomationActiveByIDSuccess = (payload) => {
  return {
    type: GET_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
    payload,
  };
};

export const updateAssessmentAutomationActiveSuccess = (payload) => {
  return {
    type: UPDATE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
    payload,
  };
};

export const archivedAssessmentAutomationActiveSuccess = (payload) => {
  return {
    type: ARCHIVED_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
    payload,
  };
};

export const deleteAssessmentAutomationActiveSuccess = (payload) => {
  return {
    type: DELETE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
    payload,
  };
};

//ASSESSMENT ARCHIVED
export const listAssessmentAutomationArchivedSuccess = (payload) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS,
    payload,
  };
};

export const activatedAssessmentAutomationArchivedSuccess = (payload) => {
  return {
    type: ACTIVATED_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS,
    payload,
  };
};

export const deleteAssessmentAutomationArchivedSuccess = (payload) => {
  return {
    type: DELETE_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS,
    payload,
  };
};

//ASSESSMENT DELETED
export const listAssessmentAutomationDeletedSuccess = (payload) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_DELETED_SUCCESS,
    payload,
  };
};

export const activatedAssessmentAutomationDeletedSuccess = (payload) => {
  return {
    type: ACTIVATED_ASSESSMENT_AUTOMATION_DELETED_SUCCESS,
    payload,
  };
};

export const deleteAssessmentAutomationDeletedSuccess = (payload) => {
  return {
    type: DELETE_ASSESSMENT_AUTOMATION_DELETED_SUCCESS,
    payload,
  };
};

//ASSESSMENT RISK
export const listAssessmentAutomationRiskSuccess = (payload) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
    payload,
  };
};

export const listAssessmentAutomationRiskFailed = (error) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_RISK_FAILED,
    payload: error,
  };
};

export const createAssessmentAutomationRiskSuccess = (payload) => {
  return {
    type: CREATE_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
    payload,
  };
};

export const updateAssessmentAutomationRiskSuccess = (payload) => {
  return {
    type: UPDATE_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
    payload,
  };
};

export const deleteAssessmentAutomationRiskSuccess = (payload) => {
  return {
    type: DELETE_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
    payload,
  };
};

export const listAssessmentAutomationTemplateSuccess = (error) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS,
    payload: error,
  };
};

export const listAssessmentAutomationTemplateFailed = (error) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_TEMPLATE_FAILED,
    payload: error,
  };
};

export const getAssessmentAutomationTemplateTypeSuccess = (payload) => ({
  type: GET_ASSESSMENT_AUTOMATION_TEMPLATE_TYPE_SUCCESS,
  payload,
});

export const getAssessmentAutomationTemplateByTypeSuccess = (templateType) => ({
  type: GET_ASSESSMENT_AUTOMATION_TEMPLATE_BY_TYPE_SUCCESS,
  payload: templateType,
});

export const deleteAssessmentAutomationTemplateSuccess = (payload) => {
  return {
    type: DELETE_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS,
    payload,
  };
};

export const listAssessmentAutomationProcessSuccess = (payload) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
    payload,
  };
};

export const createAssessmentAutomationProcessSuccess = (payload) => {
  return {
    type: CREATE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
    payload,
  };
};

export const getAssessmentAutomationProcessSuccess = (payload) => {
  return {
    type: GET_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
    payload,
  };
};

export const getAssessmentAutomationProcessFailed = (error) => {
  return {
    type: GET_ASSESSMENT_AUTOMATION_PROCESS_FAILED,
    payload: error,
  };
};

export const updateAssessmentAutomationProcessSuccess = (payload) => {
  return {
    type: UPDATE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
    payload,
  };
};

export const deleteAssessmentAutomationProcessSuccess = (payload) => {
  return {
    type: DELETE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
    payload,
  };
};

//ASSESSMENT RESULT
export const listAssessmentAutomationResultSuccess = (payload) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_RESULT_SUCCESS,
    payload,
  };
};

export const listAssessmentAutomationResultFailed = (error) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_RESULT_FAILED,
    payload: error,
  };
};

export const createAssessmentAutomationResultSuccess = (payload) => {
  return {
    type: CREATE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS,
    payload,
  };
};

export const updateAssessmentAutomationResultSuccess = (payload) => {
  return {
    type: UPDATE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS,
    payload,
  };
};

export const deleteAssessmentAutomationResultSuccess = (payload) => {
  return {
    type: DELETE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS,
    payload,
  };
};

//ASSESSMENT RULES
export const listAssessmentAutomationRulesSuccess = (payload) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_RULES_SUCCESS,
    payload,
  };
};

export const listAssessmentAutomationRulesFailed = (error) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_RULES_FAILED,
    payload: error,
  };
};

export const createAssessmentAutomationRulesSuccess = (payload) => {
  return {
    type: CREATE_ASSESSMENT_AUTOMATION_RULES_SUCCESS,
    payload,
  };
};

export const updateAssessmentAutomationRulesSuccess = (payload) => {
  return {
    type: UPDATE_ASSESSMENT_AUTOMATION_RULES_SUCCESS,
    payload,
  };
};

export const deleteAssessmentAutomationRulesSuccess = (payload) => {
  return {
    type: DELETE_ASSESSMENT_AUTOMATION_RULES_SUCCESS,
    payload,
  };
};

//ASSESSMENT SENT
export const listAssessmentAutomationNotSentSuccess = (payload) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS,
    payload,
  };
};

export const listAssessmentAutomationNotSentNextPage = (payload) => ({
  type: LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE,
  payload,
});

export const listAssessmentAutomationNotSentNextPageSuccess = (payload) => ({
  type: LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE_SUCCESS,
  payload,
});

export const listAssessmentAutomationNotSentNextPageError = () => ({
  type: LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE_ERROR,
});

export const listAssessmentAutomationNotSentFailed = (error) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED,
    payload: error,
  };
};

export const createAssessmentAutomationNotSentSuccess = (payload) => {
  return {
    type: CREATE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS,
    payload,
  };
};

export const createAssessmentAutomationNotSentFailed = (error) => {
  return {
    type: CREATE_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED,
    payload: error,
  };
};

export const updateAssessmentAutomationNotSentSuccess = (payload) => {
  return {
    type: UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS,
    payload,
  };
};

export const updateAssessmentAutomationNotSentFailed = (error) => {
  return {
    type: UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED,
    payload: error,
  };
};

export const deleteAssessmentAutomationNotSentSuccess = (payload) => {
  return {
    type: DELETE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS,
    payload,
  };
};

export const deleteAssessmentAutomationNotSentFailed = (error) => {
  return {
    type: DELETE_ASSESSMENT_AUTOMATION_NOT_SENT_FAILED,
    payload: error,
  };
};

export const updateAssessmentAutomationNotSentToSentSuccess = (payload) => {
  return {
    type: UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT_SUCCESS,
    payload,
  };
};

export const updateAssessmentAutomationNotSentToSentFailed = (error) => {
  return {
    type: UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT_FAILED,
    payload: error,
  };
};

export const listAssessmentAutomationSentSuccess = (payload) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_SENT_SUCCESS,
    payload,
  };
};
export const listAssessmentAutomationSentNextPage = (payload) => ({
  type: LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE,
  payload,
});
export const listAssessmentAutomationSentNextPageSuccess = (payload) => ({
  type: LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE_SUCCESS,
  payload,
});

export const listAssessmentAutomationSentNextPageError = () => ({
  type: LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE_ERROR,
});
export const listAssessmentAutomationSentFailed = (error) => {
  return {
    type: LIST_ASSESSMENT_AUTOMATION_SENT_FAILED,
    payload: error,
  };
};

export const getAssessmentAutomationReportSuccess = (payload) => ({
  type: GET_ASSESSMENT_AUTOMATION_REPORT_SUCCESS,
  payload,
});

export const getAssessmentAutomationReportDataSuccess = (payload) => ({
  type: GET_ASSESSMENT_AUTOMATION_REPORT_DATA_SUCCESS,
  payload,
});

export const listAssessmentAutomationSubmitReportSuccess = (payload) => ({
  type: LIST_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_SUCCESS,
  payload,
});

export const getAssessmentAutomationSubmitReportDataSuccess = (payload) => ({
  type: GET_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_DATA_SUCCESS,
  payload,
});

export const listAssessmentAutomationTaskSuccess = (payload) => ({
  type: LIST_ASSESSMENT_AUTOMATION_TASK_SUCCESS,
  payload,
});
export const listAssessmentAutomationTaskNextPage = (payload) => ({
  type: LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE,
  payload,
});
export const listAssessmentAutomationTaskNextPageSuccess = (payload) => ({
  type: LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE_SUCCESS,
  payload,
});
export const listAssessmentAutomationTaskNextPageError = () => ({
  type: LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE_ERROR,
});
export const listAssessmentAutomationInAssessmentSuccess = (payload) => ({
  type: LIST_ASSESSMENT_AUTOMATION_TASK_IN_ASSESSMENT_SUCCESS,
  payload,
});

export const createAssessmentAutomationTaskSuccess = (payload) => ({
  type: CREATE_ASSESSMENT_AUTOMATION_TASK_SUCCESS,
  payload,
});

export const updateAssessmentAutomationTaskSuccess = (payload) => ({
  type: UPDATE_ASSESSMENT_AUTOMATION_TASK_SUCCESS,
  payload,
});

export const deleteAssessmentAutomationTaskSuccess = (payload) => ({
  type: DELETE_ASSESSMENT_AUTOMATION_TASK_SUCCESS,
  payload,
});

export const updateAssessmentDataInventory = (payload) => ({
  type: UPDATE_ASSESSMENT_AUTOMATION_DATA_INVENTORY,
  payload,
});

export const getAssessmentDataInventorySuccess = (payload) => ({
  type: GET_ASSESSMENT_AUTOMATION_DATA_INVENTORY_SUCCESS,
  payload,
});

export const getAssessmentDataInventoryError = (payload) => ({
  type: GET_ASSESSMENT_AUTOMATION_DATA_INVENTORY_ERROR,
  payload,
});
export const addAssessmentControlSuccess = (payload) => {
  return {
    type: ADD_ASSESSMENT_CONTROL_SUCCESS,
    payload,
  };
};

export const addAssessmentControlFailed = (error) => {
  return {
    type: ADD_ASSESSMENT_CONTROL_FAILED,
    payload: error,
  };
};

export const getAssessmentControlSuccess = (payload) => ({
  type: GET_ASSESSMENT_CONTROL_SUCCESS,
  payload,
});

export const getAssessmentControlError = (payload) => ({
  type: GET_ASSESSMENT_CONTROL_FAILED,
  payload,
});

export const deleteAssessmentControlSucces = (payload) => {
  return {
    type: DELETE_ASSESSMENT_CONTROL_SUCCESS,
    payload,
  };
};
export const deleteAssessmentControlFailed = (error) => {
  return {
    type: DELETE_ASSESSMENT_CONTROL_FAILED,
    payload: error,
  };
};
