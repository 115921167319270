import { changeFormatDatetime } from "util/DateUtils";

export default class archiveModel {
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.create_at = data?.create_at;
    this.expired = data?.expired;
    this.type = data?.type;
  }

  displayCreateAt = () => {
    if (this.create_at !== "0001-01-01T00:00:00Z")
      return changeFormatDatetime(this.create_at, "DD/MM/YYYY h:mm A");
    return "-";
  };

  displayExpired = () => {
    if (this.expired !== "0001-01-01T00:00:00Z")
      return changeFormatDatetime(this.expired, "DD/MM/YYYY h:mm A");
    return "-";
  };
}
