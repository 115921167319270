import React, { Component } from "react";
import { Button, Card, Result } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import IntlMessage from "components/util-components/IntlMessage";
import { css } from "@emotion/css";

const CardStyled = styled(Card)`
  .ant-card-body {
    height: 75vh;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
  }

  button {
    text-transform: capitalize;
  }
`;

class ErrorBoundaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  refresh() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <CardStyled>
          <Result
            status="error"
            title={<IntlMessage id="component.error-boundaries.title" />}
            subTitle={<IntlMessage id="component.error-boundaries.sub-title" />}
            extra={[
              <Button onClick={this.refresh} key="refresh" type="primary">
                <ReloadOutlined
                  className={css`
                    margin-right: 10px;
                  `}
                />
                <IntlMessage id="component.error-boundaries.refresh" />
              </Button>,
            ]}
          />
        </CardStyled>
      );
    }

    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundaries;
