import {
  SET_LOADING,
  SET_METADATA,
  SET_METADATA_TOTAL_PAGE,
  SET_METADATA_CURRENT_PAGE,
  SET_METADATA_PAGE_SIZE,
  CLEAR_METADATA,
  SET_GLOBAL_LOADING,
} from "redux/constants/CyberKitTool";

const initState = {
  globalLoading: false,
  paginate: {
    loading: false,
    metadata: {
      totalPage: 1,
      currentPage: 1,
      pageSize: 10,
    },
  },
  tenancy: "fnpzulapvrcieuqwttkuydtt",
};

const CyberKit = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING: {
      return {
        ...state,
        paginate: {
          ...state.paginate,
          loading: payload,
        },
      };
    }

    case SET_METADATA: {
      return {
        ...state,
        paginate: {
          metadata: payload,
        },
      };
    }

    case SET_METADATA_TOTAL_PAGE: {
      return {
        ...state,
        paginate: {
          ...state.paginate,
          metadata: {
            ...state.paginate.metadata,
            totalPage: payload,
          },
        },
      };
    }

    case SET_METADATA_CURRENT_PAGE: {
      return {
        ...state,
        paginate: {
          ...state.paginate,
          metadata: {
            ...state.paginate.metadata,
            currentPage: payload,
          },
        },
      };
    }

    case SET_METADATA_PAGE_SIZE: {
      return {
        ...state,
        paginate: {
          ...state.paginate,
          metadata: {
            ...state.paginate.metadata,
            currentPage: 1,
            pageSize: payload,
          },
        },
      };
    }

    case CLEAR_METADATA: {
      return {
        ...state,
        paginate: {
          metadata: {
            totalPage: 1,
            currentPage: 1,
            pageSize: 10,
          },
        },
      };
    }

    case SET_GLOBAL_LOADING: {
      return {
        ...state,
        globalLoading: payload,
      };
    }

    default:
      return state;
  }
};

export default CyberKit;
