// NOTIFICATION
export const LIST_INCIDENT_RESPONSE_NOTIFICATION =
  "LIST_INCIDENT_RESPONSE_NOTIFICATION";
export const LIST_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS =
  "LIST_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS";
export const LIST_INCIDENT_RESPONSE_NOTIFICATION_FAILED =
  "LIST_INCIDENT_RESPONSE_NOTIFICATION_FAILED";
export const GET_INCIDENT_RESPONSE_NOTIFICATION =
  "GET_INCIDENT_RESPONSE_NOTIFICATION";
export const GET_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS =
  "GET_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS";
export const GET_INCIDENT_RESPONSE_NOTIFICATION_FAILED =
  "GET_INCIDENT_RESPONSE_NOTIFICATION_FAILED";
export const CREATE_INCIDENT_RESPONSE_NOTIFICATION =
  "CREATE_INCIDENT_RESPONSE_NOTIFICATION";
export const CREATE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS =
  "CREATE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS";
export const CREATE_INCIDENT_RESPONSE_NOTIFICATION_FAILED =
  "CREATE_INCIDENT_RESPONSE_NOTIFICATION_FAILED";
export const UPDATE_INCIDENT_RESPONSE_NOTIFICATION =
  "UPDATE_INCIDENT_RESPONSE_NOTIFICATION";
export const UPDATE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS =
  "UPDATE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS";
export const UPDATE_INCIDENT_RESPONSE_NOTIFICATION_FAILED =
  "UPDATE_INCIDENT_RESPONSE_NOTIFICATION_FAILED";
export const UPDATE_INCIDENT_RESPONSE_NOTIFICATION_STATUS =
  "UPDATE_INCIDENT_RESPONSE_NOTIFICATION_STATUS";
export const UPDATE_INCIDENT_RESPONSE_NOTIFICATION_STATUS_SUCCESS =
  "UPDATE_INCIDENT_RESPONSE_NOTIFICATION_STATUS_SUCCESS";
export const UPDATE_INCIDENT_RESPONSE_NOTIFICATION_STATUS_FAILED =
  "UPDATE_INCIDENT_RESPONSE_NOTIFICATION_STATUS_FAILED";
export const DELETE_INCIDENT_RESPONSE_NOTIFICATION =
  "DELETE_INCIDENT_RESPONSE_NOTIFICATION";
export const DELETE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS =
  "DELETE_INCIDENT_RESPONSE_NOTIFICATION_SUCCESS";
export const DELETE_INCIDENT_RESPONSE_NOTIFICATION_FAILED =
  "DELETE_INCIDENT_RESPONSE_NOTIFICATION_FAILED";

// RESPONSE PLAN
export const LIST_INCIDENT_RESPONSE_PLAN = "LIST_INCIDENT_RESPONSE_PLAN";
export const LIST_INCIDENT_RESPONSE_PLAN_SUCCESS =
  "LIST_INCIDENT_RESPONSE_PLAN_SUCCESS";
export const LIST_INCIDENT_RESPONSE_PLAN_FAILED =
  "LIST_INCIDENT_RESPONSE_PLAN_FAILED";
export const GET_INCIDENT_RESPONSE_PLAN = "GET_INCIDENT_RESPONSE_PLAN";
export const GET_INCIDENT_RESPONSE_PLAN_SUCCESS =
  "GET_INCIDENT_RESPONSE_PLAN_SUCCESS";
export const GET_INCIDENT_RESPONSE_PLAN_FAILED =
  "GET_INCIDENT_RESPONSE_PLAN_FAILED";
export const CREATE_INCIDENT_RESPONSE_PLAN = "CREATE_INCIDENT_RESPONSE_PLAN";
export const CREATE_INCIDENT_RESPONSE_PLAN_SUCCESS =
  "CREATE_INCIDENT_RESPONSE_PLAN_SUCCESS";
export const CREATE_INCIDENT_RESPONSE_PLAN_FAILED =
  "CREATE_INCIDENT_RESPONSE_PLAN_FAILED";
export const UPDATE_INCIDENT_RESPONSE_PLAN = "UPDATE_INCIDENT_RESPONSE_PLAN";
export const UPDATE_INCIDENT_RESPONSE_PLAN_SUCCESS =
  "UPDATE_INCIDENT_RESPONSE_PLAN_SUCCESS";
export const UPDATE_INCIDENT_RESPONSE_PLAN_FAILED =
  "UPDATE_INCIDENT_RESPONSE_PLAN_FAILED";
export const PUBLISH_INCIDENT_RESPONSE_PLAN = "PUBLISH_INCIDENT_RESPONSE_PLAN";
export const PUBLISH_INCIDENT_RESPONSE_PLAN_SUCCESS =
  "PUBLISH_INCIDENT_RESPONSE_PLAN_SUCCESS";
export const PUBLISH_INCIDENT_RESPONSE_PLAN_FAILED =
  "PUBLISH_INCIDENT_RESPONSE_PLAN_FAILED";
export const DELETE_INCIDENT_RESPONSE_PLAN = "DELETE_INCIDENT_RESPONSE_PLAN";
export const DELETE_INCIDENT_RESPONSE_PLAN_SUCCESS =
  "DELETE_INCIDENT_RESPONSE_PLAN_SUCCESS";
export const DELETE_INCIDENT_RESPONSE_PLAN_FAILED =
  "DELETE_INCIDENT_RESPONSE_PLAN_FAILED";

export const UPDATE_INCIDENT_RESPONSE_PLAN_STAGE =
  "UPDATE_INCIDENT_RESPONSE_PLAN_STAGE";
export const UPDATE_INCIDENT_RESPONSE_PLAN_STAGE_SUCCESS =
  "UPDATE_INCIDENT_RESPONSE_PLAN_STAGE_SUCCESS";
export const UPDATE_INCIDENT_RESPONSE_PLAN_STAGE_FAILED =
  "UPDATE_INCIDENT_RESPONSE_PLAN_STAGE_FAILED";
export const TOGGLE_INCIDENT_RESPONSE_PLAN_STAGE_NEXT_BUTTON =
  "TOGGLE_INCIDENT_RESPONSE_PLAN_STAGE_NEXT_BUTTON";
export const TOGGLE_INCIDENT_RESPONSE_PLAN_STAGE_BACK_BUTTON =
  "TOGGLE_INCIDENT_RESPONSE_PLAN_STAGE_BACK_BUTTON";

export const LIST_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN =
  "LIST_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN";
export const LIST_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS =
  "LIST_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS";
export const LIST_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_FAILED =
  "LIST_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_FAILED";
export const CREATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN =
  "CREATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN";
export const CREATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS =
  "CREATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS";
export const CREATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_FAILED =
  "CREATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_FAILED";
export const GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN =
  "GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN";
export const GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS =
  "GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS";
export const GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_FAILED =
  "GET_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_FAILED";
export const UPDATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN =
  "UPDATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN";
export const UPDATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS =
  "UPDATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS";
export const UPDATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_FAILED =
  "UPDATE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_FAILED";
export const DELETE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN =
  "DELETE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN";
export const DELETE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS =
  "DELETE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_SUCCESS";
export const DELETE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_FAILED =
  "DELETE_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_FAILED";
export const CLEAR_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_DATA =
  "CLEAR_INCIDENT_RESPONSE_TASK_IN_RESPONSE_PLAN_DATA";

// WEBFORM
export const LIST_INCIDENT_RESPONSE_WEBFORM = "LIST_INCIDENT_RESPONSE_WEBFORM";
export const LIST_INCIDENT_RESPONSE_WEBFORM_SUCCESS =
  "LIST_INCIDENT_RESPONSE_WEBFORM_SUCCESS";
export const LIST_INCIDENT_RESPONSE_WEBFORM_FAILED =
  "LIST_INCIDENT_RESPONSE_WEBFORM_FAILED";
export const GET_INCIDENT_RESPONSE_WEBFORM = "GET_INCIDENT_RESPONSE_WEBFORM";
export const GET_INCIDENT_RESPONSE_WEBFORM_SUCCESS =
  "GET_INCIDENT_RESPONSE_WEBFORM_SUCCESS";
export const GET_INCIDENT_RESPONSE_WEBFORM_FAILED =
  "GET_INCIDENT_RESPONSE_WEBFORM_FAILED";
export const CREATE_INCIDENT_RESPONSE_WEBFORM =
  "CREATE_INCIDENT_RESPONSE_WEBFORM";
export const CREATE_INCIDENT_RESPONSE_WEBFORM_SUCCESS =
  "CREATE_INCIDENT_RESPONSE_WEBFORM_SUCCESS";
export const CREATE_INCIDENT_RESPONSE_WEBFORM_FAILED =
  "CREATE_INCIDENT_RESPONSE_WEBFORM_FAILED";
export const UPDATE_INCIDENT_RESPONSE_WEBFORM =
  "UPDATE_INCIDENT_RESPONSE_WEBFORM";
export const UPDATE_INCIDENT_RESPONSE_WEBFORM_SUCCESS =
  "UPDATE_INCIDENT_RESPONSE_WEBFORM_SUCCESS";
export const UPDATE_INCIDENT_RESPONSE_WEBFORM_FAILED =
  "UPDATE_INCIDENT_RESPONSE_WEBFORM_FAILED";
export const PUBLISH_INCIDENT_RESPONSE_WEBFORM =
  "PUBLISH_INCIDENT_RESPONSE_WEBFORM";
export const PUBLISH_INCIDENT_RESPONSE_WEBFORM_SUCCESS =
  "PUBLISH_INCIDENT_RESPONSE_WEBFORM_SUCCESS";
export const PUBLISH_INCIDENT_RESPONSE_WEBFORM_FAILED =
  "PUBLISH_INCIDENT_RESPONSE_WEBFORM_FAILED";
export const DELETE_INCIDENT_RESPONSE_WEBFORM =
  "DELETE_INCIDENT_RESPONSE_WEBFORM";
export const DELETE_INCIDENT_RESPONSE_WEBFORM_SUCCESS =
  "DELETE_INCIDENT_RESPONSE_WEBFORM_SUCCESS";
export const DELETE_INCIDENT_RESPONSE_WEBFORM_FAILED =
  "DELETE_INCIDENT_RESPONSE_WEBFORM_FAILED";
export const LIST_INCIDENT_RESPONSE_WEBFORM_VERSION =
  "LIST_INCIDENT_RESPONSE_WEBFORM_VERSION";
export const LIST_INCIDENT_RESPONSE_WEBFORM_VERSION_SUCCESS =
  "LIST_INCIDENT_RESPONSE_WEBFORM_VERSION_SUCCESS";
export const LIST_INCIDENT_RESPONSE_WEBFORM_VERSION_FAILED =
  "LIST_INCIDENT_RESPONSE_WEBFORM_VERSION_FAILED";
export const GET_INCIDENT_RESPONSE_WEBFORM_VERSION =
  "GET_INCIDENT_RESPONSE_WEBFORM_VERSION";
export const GET_INCIDENT_RESPONSE_WEBFORM_VERSION_SUCCESS =
  "GET_INCIDENT_RESPONSE_WEBFORM_VERSION_SUCCESS";
export const GET_INCIDENT_RESPONSE_WEBFORM_VERSION_FAILED =
  "GET_INCIDENT_RESPONSE_WEBFORM_VERSION_FAILED";

export const GET_INCIDENT_RESPONSE_WEBFORM_WEBFORM_FIELDS =
  "GET_INCIDENT_RESPONSE_WEBFORM_WEBFORM_FIELDS";
export const GET_INCIDENT_RESPONSE_WEBFORM_WEBFORM_FIELDS_SUCCESS =
  "GET_INCIDENT_RESPONSE_WEBFORM_WEBFORM_FIELDS_SUCCESS";

// EVENT REGISTER
export const LIST_INCIDENT_RESPONSE_CASE = "LIST_INCIDENT_RESPONSE_CASE";
export const LIST_INCIDENT_RESPONSE_CASE_SUCCESS =
  "LIST_INCIDENT_RESPONSE_CASE_SUCCESS";
export const LIST_INCIDENT_RESPONSE_CASE_FAILED =
  "LIST_INCIDENT_RESPONSE_CASE_FAILED";
export const GET_INCIDENT_RESPONSE_CASE = "GET_INCIDENT_RESPONSE_CASE";
export const GET_INCIDENT_RESPONSE_CASE_SUCCESS =
  "GET_INCIDENT_RESPONSE_CASE_SUCCESS";
export const GET_INCIDENT_RESPONSE_CASE_FAILED =
  "GET_INCIDENT_RESPONSE_CASE_FAILED";
export const CREATE_INCIDENT_RESPONSE_CASE = "CREATE_INCIDENT_RESPONSE_CASE";
export const CREATE_INCIDENT_RESPONSE_CASE_SUCCESS =
  "CREATE_INCIDENT_RESPONSE_CASE_SUCCESS";
export const CREATE_INCIDENT_RESPONSE_CASE_FAILED =
  "CREATE_INCIDENT_RESPONSE_CASE_FAILED";
export const UPDATE_INCIDENT_RESPONSE_CASE = "UPDATE_INCIDENT_RESPONSE_CASE";
export const UPDATE_INCIDENT_RESPONSE_CASE_SUCCESS =
  "UPDATE_INCIDENT_RESPONSE_CASE_SUCCESS";
export const PUBLISH_INCIDENT_RESPONSE_CASE = "PUBLISH_INCIDENT_RESPONSE_CASE";
export const PUBLISH_INCIDENT_RESPONSE_CASE_SUCCESS =
  "PUBLISH_INCIDENT_RESPONSE_CASE_SUCCESS";
export const UPDATE_INCIDENT_RESPONSE_CASE_ERROR =
  "UPDATE_INCIDENT_RESPONSE_CASE_ERROR";
export const UPDATE_INCIDENT_RESPONSE_CASE_FAILED =
  "UPDATE_INCIDENT_RESPONSE_CASE_FAILED";
  
export const UPDATE_INCIDENT_RESPONSE_CASE_STAGE =
  "UPDATE_INCIDENT_RESPONSE_CASE_STAGE";
  export const UPDATE_INCIDENT_RESPONSE_CASE_STAGE_SUCCESS =
  "UPDATE_INCIDENT_RESPONSE_CASE_STAGE_SUCCESS";
  export const UPDATE_INCIDENT_RESPONSE_CASE_STAGE_FAILED =
  "UPDATE_INCIDENT_RESPONSE_CASE_STAGE_FAILED";

export const DELETE_INCIDENT_RESPONSE_CASE = "DELETE_INCIDENT_RESPONSE_CASE";
export const DELETE_INCIDENT_RESPONSE_CASE_SUCCESS =
  "DELETE_INCIDENT_RESPONSE_CASE_SUCCESS";
export const DELETE_INCIDENT_RESPONSE_CASE_FAILED =
  "DELETE_INCIDENT_RESPONSE_CASE_FAILED";

export const LIST_INCIDENT_RESPONSE_TASK_IN_CASE =
  "LIST_INCIDENT_RESPONSE_TASK_IN_CASE";
export const LIST_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS =
  "LIST_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS";
export const LIST_INCIDENT_RESPONSE_TASK_IN_CASE_FAILED =
  "LIST_INCIDENT_RESPONSE_TASK_IN_CASE_FAILED";
export const CREATE_INCIDENT_RESPONSE_TASK_IN_CASE =
  "CREATE_INCIDENT_RESPONSE_TASK_IN_CASE";
export const CREATE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS =
  "CREATE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS";
export const CREATE_INCIDENT_RESPONSE_TASK_IN_CASE_FAILED =
  "CREATE_INCIDENT_RESPONSE_TASK_IN_CASE_FAILED";
export const GET_INCIDENT_RESPONSE_TASK_IN_CASE =
  "GET_INCIDENT_RESPONSE_TASK_IN_CASE";
export const GET_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS =
  "GET_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS";
export const GET_INCIDENT_RESPONSE_TASK_IN_CASE_FAILED =
  "GET_INCIDENT_RESPONSE_TASK_IN_CASE_FAILED";
export const UPDATE_INCIDENT_RESPONSE_TASK_IN_CASE =
  "UPDATE_INCIDENT_RESPONSE_TASK_IN_CASE";
export const UPDATE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS =
  "UPDATE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS";
export const UPDATE_INCIDENT_RESPONSE_TASK_IN_CASE_FAILED =
  "UPDATE_INCIDENT_RESPONSE_TASK_IN_CASE_FAILED";
export const DELETE_INCIDENT_RESPONSE_TASK_IN_CASE =
  "DELETE_INCIDENT_RESPONSE_TASK_IN_CASE";
export const DELETE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS =
  "DELETE_INCIDENT_RESPONSE_TASK_IN_CASE_SUCCESS";
export const DELETE_INCIDENT_RESPONSE_TASK_IN_CASE_FAILED =
  "DELETE_INCIDENT_RESPONSE_TASK_IN_CASE_FAILED";

export const LIST_INCIDENT_RESPONSE_CASE_COMMENT =
  "LIST_INCIDENT_RESPONSE_CASE_COMMENT";
export const LIST_INCIDENT_RESPONSE_CASE_COMMENT_SUCCESS =
  "LIST_INCIDENT_RESPONSE_CASE_COMMENT_SUCCESS";
export const LIST_INCIDENT_RESPONSE_CASE_COMMENT_FAILED =
  "LIST_INCIDENT_RESPONSE_CASE_COMMENT_FAILED";
export const CREATE_INCIDENT_RESPONSE_CASE_COMMENT =
  "CREATE_INCIDENT_RESPONSE_CASE_COMMENT";
export const CREATE_INCIDENT_RESPONSE_CASE_COMMENT_SUCCESS =
  "CREATE_INCIDENT_RESPONSE_CASE_COMMENT_SUCCESS";
export const CREATE_INCIDENT_RESPONSE_CASE_COMMENT_FAILED =
  "CREATE_INCIDENT_RESPONSE_CASE_COMMENT_FAILED";

export const LIST_INCIDENT_RESPONSE_CASE_HISTORY =
  "LIST_INCIDENT_RESPONSE_CASE_HISTORY";
export const LIST_INCIDENT_RESPONSE_CASE_HISTORY_SUCCESS =
  "LIST_INCIDENT_RESPONSE_CASE_HISTORY_SUCCESS";
export const LIST_INCIDENT_RESPONSE_CASE_HISTORY_FAILED =
  "LIST_INCIDENT_RESPONSE_CASE_HISTORY_FAILED";
// REPORT
export const LIST_INCIDENT_RESPONSE_CASE_HIGH =
  "LIST_INCIDENT_RESPONSE_CASE_HIGH";
export const LIST_INCIDENT_RESPONSE_CASE_HIGH_SUCCESS =
  "LIST_INCIDENT_RESPONSE_CASE_HIGH_SUCCESS";
export const LIST_INCIDENT_RESPONSE_CASE_HIGH_FAILED =
  "LIST_INCIDENT_RESPONSE_CASE_HIGH_FAILED";
export const SENT_INCIDENT_RESPONSE_CASE_HIGH =
  "SENT_INCIDENT_RESPONSE_CASE_HIGH";
export const SENT_INCIDENT_RESPONSE_CASE_HIGH_SUCCESS =
  "SENT_INCIDENT_RESPONSE_CASE_HIGH_SUCCESS";
export const SENT_INCIDENT_RESPONSE_CASE_HIGH_FAILED =
  "SENT_INCIDENT_RESPONSE_CASE_HIGH_FAILED";
export const GET_INCIDENT_RESPONSE_CASE_HIGH_TEMPLATE =
  "GET_INCIDENT_RESPONSE_CASE_HIGH_TEMPLATE";
export const GET_INCIDENT_RESPONSE_CASE_HIGH_TEMPLATE_SUCCESS =
  "GET_INCIDENT_RESPONSE_CASE_HIGH_TEMPLATE_SUCCESS";
export const GET_INCIDENT_RESPONSE_CASE_HIGH_TEMPLATE_FAILED =
  "GET_INCIDENT_RESPONSE_CASE_HIGH_TEMPLATE_FAILED";

// TASK
export const LIST_INCIDENT_RESPONSE_TASK_ALL =
  "LIST_INCIDENT_RESPONSE_TASK_ALL";
export const LIST_INCIDENT_RESPONSE_TASK_ALL_SUCCESS =
  "LIST_INCIDENT_RESPONSE_TASK_ALL_SUCCESS";
export const LIST_INCIDENT_RESPONSE_TASK_ALL_FAILED =
  "LIST_INCIDENT_RESPONSE_TASK_ALL_FAILED";

export const GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE =
  "GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE";
export const GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE_SUCCESS =
  "GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE_SUCCESS";
export const GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE_ERROR =
  "GET_INCIDENT_RESPONSE_WEBFORM_TEMPLATE_ERROR";

export const RESET_INCIDENT_RESPONSE_WEBFORM_DATA =
  "RESET_INCIDENT_RESPONSE_WEBFORM_DATA";
