export const LIST_PRIVACY_ASSESSMENT_AUTOMATION =
  "LIST_PRIVACY_ASSESSMENT_AUTOMATION";
export const LIST_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS =
  "LIST_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS";
export const LIST_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE =
  "LIST_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE";

export const TOGGLE_CREATE_PRIVACY_ASSESSMENT_AUTOMATION_MODAL =
  "TOGGLE_CREATE_PRIVACY_ASSESSMENT_AUTOMATION_MODAL";
export const CREATE_PRIVACY_ASSESSMENT_AUTOMATION =
  "CREATE_PRIVACY_ASSESSMENT_AUTOMATION";
export const CREATE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS =
  "CREATE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS";
export const CREATE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE =
  "CREATE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE";

export const UPDATE_PRIVACY_ASSESSMENT_AUTOMATION =
  "UPDATE_PRIVACY_ASSESSMENT_AUTOMATION";
export const UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS =
  "UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS";
export const UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE =
  "UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE";

export const TOGGLE_PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION =
  "TOGGLE_PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION";
export const PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION =
  "PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION";
export const PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS =
  "PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS";
export const PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE =
  "PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE";

export const TOGGLE_DELETE_PRIVACY_ASSESSMENT_AUTOMATION =
  "TOGGLE_DELETE_PRIVACY_ASSESSMENT_AUTOMATION";
export const DELETE_PRIVACY_ASSESSMENT_AUTOMATION =
  "DELETE_PRIVACY_ASSESSMENT_AUTOMATION";
export const DELETE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS =
  "DELETE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS";
export const DELETE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE =
  "DELETE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE";

export const GET_PRIVACY_ASSESSMENT_AUTOMATION =
  "GET_PRIVACY_ASSESSMENT_AUTOMATION";
export const GET_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS =
  "GET_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS";
export const GET_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE =
  "GET_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE";

export const CLEAR_PRIVACY_ASSESSMENT_AUTOMATION =
  "CLEAR_PRIVACY_ASSESSMENT_AUTOMATION";

export const LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK =
  "LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK";
export const LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS =
  "LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS";
export const LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE =
  "LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE";

export const TOGGLE_CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_MODAL =
  "TOGGLE_CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_MODAL";
export const CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK =
  "CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK";
export const CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS =
  "CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS";
export const CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE =
  "CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE";

export const UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK =
  "UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK";
export const UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS =
  "UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS";
export const UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE =
  "UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE";

export const TOGGLE_PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK =
  "TOGGLE_PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK";
export const PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK =
  "PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK";
export const PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS =
  "PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS";
export const PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE =
  "PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE";

export const TOGGLE_DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK =
  "TOGGLE_DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK";
export const DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK =
  "DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK";
export const DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS =
  "DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS";
export const DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE =
  "DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE";

export const GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK =
  "GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK";
export const GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS =
  "GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK_SUCCESS";
export const GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE =
  "GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK_FAILURE";

export const CLEAR_PRIVACY_ASSESSMENT_AUTOMATION_RISK =
  "CLEAR_PRIVACY_ASSESSMENT_AUTOMATION_RISK";

export const GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY =
  "GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY";
export const GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY_SUCCESS =
  "GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY_SUCCESS";
export const GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY_FAILURE =
  "GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY_FAILURE";
export const CLEAR_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY =
  "CLEAR_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY";

export const GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD =
  "GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD";
export const GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD_SUCCESS =
  "GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD_SUCCESS";
export const GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD_FAILURE =
  "GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD_FAILURE";
