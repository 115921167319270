import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
} from "../../constants/PrivacyKitPrivacyAssessmentAutomation";
import {
  listPrivacyAssessmentAutomationRiskSuccess,
  listPrivacyAssessmentAutomationRiskFailure,
  createPrivacyAssessmentAutomationRiskSuccess,
  createPrivacyAssessmentAutomationRiskFailure,
  updatePrivacyAssessmentAutomationRiskSuccess,
  updatePrivacyAssessmentAutomationRiskFailure,
  publishPrivacyAssessmentAutomationRiskSuccess,
  publishPrivacyAssessmentAutomationRiskFailure,
  deletePrivacyAssessmentAutomationRiskSuccess,
  deletePrivacyAssessmentAutomationRiskFailure,
  getPrivacyAssessmentAutomationRiskSuccess,
  getPrivacyAssessmentAutomationRiskFailure,
} from "../../actions/PrivacyKitPrivacyAssessmentAutomation";
import PrivacyAssessmentAutomationService from "services/pdpakit/PrivacyAssessmentAutomationService";
import {
  selectPrivacyKitPrivacyAssessmentAutomationData,
  selectPrivacyKitPrivacyRiskDataAssessmentAutomation,
} from "../../selectors/PrivacyKitPrivacyAssessmentAutomation";
import Notification from "util/Notification";

export function* listPrivacyAssessmentAutomationRisk() {
  yield takeEvery(
    LIST_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
    function* ({ assessmentId }) {
      try {
        const list = yield call(
          PrivacyAssessmentAutomationService.listAssessmentAutomationRisk,
          assessmentId
        );
        yield put(listPrivacyAssessmentAutomationRiskSuccess(list.data));
      } catch (error) {
        yield put(listPrivacyAssessmentAutomationRiskFailure(error));
      }
    }
  );
}

export function* getPrivacyAssessmentAutomationRisk() {
  yield takeEvery(
    GET_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
    function* ({ riskId }) {
      try {
        const response = yield call(
          PrivacyAssessmentAutomationService.getAssessmentAutomationRisk,
          riskId
        );
        yield put(
          getPrivacyAssessmentAutomationRiskSuccess({
            ...response.data,
          })
        );
      } catch (error) {
        yield put(getPrivacyAssessmentAutomationRiskFailure(error));
      }
    }
  );
}

export function* createPrivacyAssessmentAutomationRisk() {
  yield takeEvery(
    CREATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
    function* ({ data }) {
      try {
        const assessmentData = yield select(
          selectPrivacyKitPrivacyAssessmentAutomationData
        );
        const response = yield call(
          PrivacyAssessmentAutomationService.createAssessmentAutomationRisk,
          {
            ...data,
            description: "ไม่มีคำอธิบาย",
            assessment_type: "privacy",
            assessment_id: assessmentData.ObjectUUID,
            dataMappingInventory: assessmentData.dataMappingInventory,
            assessment_object: {
              ...assessmentData,
              dataMappingInventory: undefined,
            },
          }
        );
        yield put(createPrivacyAssessmentAutomationRiskSuccess(response.data));
        Notification("success", "สร้างความเสี่ยงสำเร็จ");
      } catch (error) {
        Notification("error", "เกิดข้อผิดพลาด สร้างความเสี่ยงไม่สำเร็จ");
        yield put(createPrivacyAssessmentAutomationRiskFailure(error));
      }
    }
  );
}

export function* updatePrivacyAssessmentAutomationRisk() {
  yield takeEvery(
    UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
    function* ({ data }) {
      try {
        const riskData = yield select(
          selectPrivacyKitPrivacyRiskDataAssessmentAutomation
        );
        const { beforeRisk, afterRisk, assessment_report_risk } = data;
        const response = yield call(
          PrivacyAssessmentAutomationService.updateAssessmentAutomationRisk,
          riskData.ObjectUUID,
          { ...riskData, beforeRisk, afterRisk, assessment_report_risk }
        );
        yield put(updatePrivacyAssessmentAutomationRiskSuccess(response.data));
        Notification("success", "แก้ไขความเสี่ยงสำเร็จ");
      } catch (error) {
        Notification("error", "เกิดข้อผิดพลาด แก้ไขความเสี่ยงไม่สำเร็จ");
        yield put(updatePrivacyAssessmentAutomationRiskFailure(error));
      }
    }
  );
}

export function* publishPrivacyAssessmentAutomationRisk() {
  yield takeEvery(
    PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
    function* ({ data }) {
      try {
        const riskData = yield select(
          selectPrivacyKitPrivacyRiskDataAssessmentAutomation
        );
        yield call(
          PrivacyAssessmentAutomationService.updateAssessmentAutomationRisk,
          riskData.ObjectUUID,
          {
            ...riskData,
            beforeRisk: data?.beforeRisk || riskData.beforeRisk,
            afterRisk: data?.afterRisk || riskData.afterRisk,
            assessment_report_risk:
              data?.assessment_report_risk || riskData.assessment_report_risk,
          }
        );
        const response = yield call(
          PrivacyAssessmentAutomationService.publishAssessmentAutomationRisk,
          riskData.ObjectUUID
        );
        yield put(publishPrivacyAssessmentAutomationRiskSuccess(response.data));
        Notification("success", "แก้ไขความเสี่ยงสำเร็จ");
      } catch (error) {
        console.log(error);
        Notification("error", "เกิดข้อผิดพลาด แก้ไขความเสี่ยงไม่สำเร็จ");
        yield put(publishPrivacyAssessmentAutomationRiskFailure(error));
      }
    }
  );
}

export function* deletePrivacyAssessmentAutomationRisk() {
  yield takeEvery(
    DELETE_PRIVACY_ASSESSMENT_AUTOMATION_RISK,
    function* ({ riskId }) {
      try {
        yield call(
          PrivacyAssessmentAutomationService.deleteAssessmentAutomationRisk,
          riskId
        );
        yield put(deletePrivacyAssessmentAutomationRiskSuccess(riskId));
        Notification("success", "ลบความเสี่ยงสำเร็จ");
      } catch (error) {
        Notification("error", "เกิดข้อผิดพลาด ลบความเสี่ยงไม่สำเร็จ");
        yield put(deletePrivacyAssessmentAutomationRiskFailure(error));
      }
    }
  );
}
