import fetch from "util/Api";
import { PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL } from "config/EndpointConfig";

const DataElementService = {};

DataElementService.listDataElement = async () =>
  await fetch({
    url: `${PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL}/data_elements`,
    method: "get",
  });

DataElementService.listDataElementType = async () =>
  await fetch({
    url: `${PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL}/data_elements/types`,
    method: "get",
  });

DataElementService.getDataElement = async (dataElementId) =>
  await fetch({
    url: `${PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL}/data_elements/${dataElementId}`,
    method: "get",
  });

DataElementService.createDataElement = async (data) =>
  await fetch({
    url: `${PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL}/data_elements`,
    method: "post",
    data,
  });

DataElementService.updateDataElement = async (dataElementId, data) =>
  await fetch({
    url: `${PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL}/data_elements/${dataElementId}`,
    method: "put",
    data,
  });

DataElementService.deleteDataElement = async (dataElementId) =>
  await fetch({
    url: `${PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL}/data_elements/${dataElementId}`,
    method: "delete",
  });

DataElementService.searchDataElement = async (search) => {
  // let inputEncoded = base64_encode(search);
  // let inputEncoded = btoa(search);
  // let inputEncoded = toBinary(search);
  // let inputEncoded = btoa(encodeURIComponent(search));
  // let inputEncoded = decodeURIComponent(atob(search))
  let inputEncoded = btoa(unescape(encodeURIComponent(search)));
  // console.log(inputEncoded);
  await fetch({
    url: `${PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL}/data_elements/search?identifier=${inputEncoded}`,
    method: "get",
  });
};

export default DataElementService;
