import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  SET_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
} from "../../constants/PrivacyKitConsentManagement";
import {
  listConsentManagementProcessingPurposeSuccess,
  getConsentManagementProcessingPurposeSuccess,
  createConsentManagementProcessingPurposeSuccess,
  updateConsentManagementProcessingPurposeSuccess,
  deleteConsentManagementProcessingPurposeSuccess,
  deleteConsentManagementProcessingPurposeError,
} from "../../actions/PrivacyKitConsentManagement";
import UniversalConsentAndPreferenceService from "services/pdpakit/UniversalConsentAndPreferenceService";
import {
  selectListCustomPreference,
  selectManageProcessingPurpose,
  selectDeleteProcessingPurpose,
} from "../../selectors/PrivacyKitConsentManagement";
import _ from "lodash";
import Notification from "util/Notification";

export function* listConsentManagementProcessingPurpose() {
  yield takeEvery(LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE, function* () {
    try {
      const response = yield call(
        UniversalConsentAndPreferenceService.listProcessingPurpose
      );
      yield put(listConsentManagementProcessingPurposeSuccess(response.Items));
    } catch (error) {
      console.log();
    }
  });
}

export function* getConsentManagementProcessingPurpose() {
  yield takeEvery(
    GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
    function* ({ processingPurposeId }) {
      try {
        const { Item } = yield call(
          UniversalConsentAndPreferenceService.getProcessingPurpose,
          processingPurposeId
        );
        let data = { ...Item };
        data.custom_preferences = data?.custom_preferences
          ?.filter((data) => data)
          .map((customPreference) => customPreference?.ObjectUUID ?? null);
        yield put(getConsentManagementProcessingPurposeSuccess(data));
      } catch (error) {
        console.log(error);
      }
    }
  );
}

export function* setManageConsentManagementProcessingPurpose() {
  yield takeEvery(
    SET_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
    function* ({ values }) {
      try {
        const listCustomPreference = yield select(selectListCustomPreference);
        const { processingPurposeId, data } = yield select(
          selectManageProcessingPurpose
        );
        const payload = {
          default_language: "th",
          expire: {
            length_number: 1,
            unit_types: "days",
          },
          ...data,
          ...values,
          ObjectType: undefined,
          custom_preferences: values?.custom_preferences.map(
            (customPreferenceId) =>
              _.find(listCustomPreference, { ObjectUUID: customPreferenceId })
          ),
        };

        if (processingPurposeId) {
          const data = yield call(
            UniversalConsentAndPreferenceService.updateProcessingPurpose,
            processingPurposeId,
            payload
          );
          yield put(
            updateConsentManagementProcessingPurposeSuccess(
              processingPurposeId,
              data
            )
          );
          Notification("success", "แก้ไขวัตถุประสงค์การประมวลผลเรียบร้อยแล้ว");
        }

        if (!processingPurposeId) {
          const data = yield call(
            UniversalConsentAndPreferenceService.createProcessingPurpose,
            payload
          );
          yield put(createConsentManagementProcessingPurposeSuccess(data));
          Notification("success", "สร้างวัตถุประสงค์การประมวลผลเรียบร้อยแล้ว");
        }
      } catch (error) {
        if (error.errorCode === "CreateNotAllowed") {
          Notification(
            "error",
            "เกิดข้อผิดพลาด เนื่องจากชื่อวัตถุประสงค์การประมวลผลมีการใช้งานแล้ว"
          );
        } else {
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถสร้างหรือแก้ไขวัตถุประสงค์การประมวลผล"
          );
        }
      }
    }
  );
}

export function* deleteConsentManagementProcessingPurpose() {
  yield takeEvery(DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE, function* () {
    try {
      const { data } = yield select(selectDeleteProcessingPurpose);
      yield call(
        UniversalConsentAndPreferenceService.deleteProcessingPurpose,
        data.ObjectUUID
      );
      yield put(
        deleteConsentManagementProcessingPurposeSuccess(data.ObjectUUID)
      );
      Notification("success", "ลบวัตถุประสงค์การประมวลผลเรียบร้อยแล้ว");
    } catch (error) {
      console.log(error);
      yield put(deleteConsentManagementProcessingPurposeError());
      Notification(
        "error",
        "เกิดข้อผิดพลาด ไม่สามารถวัตถุประสงค์การประมวลผลได้"
      );
    }
  });
}
