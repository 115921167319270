import {
  PrivacyKitPath,
  PrivacyKitCookieConsentManagementPath,
  PrivacyKitDataSubjectRightManagementPath,
  PrivacyKitUniversalConsentAndPreferenceManagementPath,
  PrivacyKitIncidentResponseManagementPath,
  PrivacyKitDataBreachNotificationPath,
  PrivacyKitAssessmentAutomationPath,
  PrivacyKitPrivacyAssessmentAutomationPath,
  PrivacyKitInternalAssessmentAutomationPath,
  PrivacyKitVendorAssessmentAutomationPath,
  PrivacyKitDataClassificationAndDiscoveryPath,
  PrivacyKitDataMappingPath,
  PrivacyKitCompliantManagementPath,
  PrivacyKitPolicyAndNoticeManagementPath,
  CyberKitPath,
  CyberKitLogManagementPath,
  CyberKitSiemPath,
  CyberKitSoarPath,
  CyberKitComplianceManagementPath,
  CyberKitVulnerabilityAssessmentPath,
  CyberKitITAssetInventoryPath,
  OrganizationsManagementPath,
  SettingUserPath,
} from "../AppModuleConfig";

import PrivacyKitCookieConsentManagement from "./PrivacyKitCookieConsentManagement";
import PrivacyKitDataSubjectRightManagement from "./PrivacyKitDataSubjectRightManagement";
import PrivacyKitUniversalConsentAndPreferenceManagement from "./PrivacyKitUniversalConsentAndPreferenceManagement";
import PrivacyKitIncidentResponseManagement from "./PrivacyKitIncidentResponseManagement";
import PrivacyKitDataBreachNotification from "./PrivacyKitDataBreachNotification";
import PrivacyKitPrivacyAssessmentAutomation from "./PrivacyKitPrivacyAssessmentAutomation";
import PrivacyKitAssessment from "./PrivacyKitAssessment";
import PrivacyKitDataClassificationAndDiscovery from "./PrivacyKitDataClassificationAndDiscovery";
import PrivacyKitDataMapping from "./PrivacyKitDataMapping";
import PrivacyKitCompliantManagement from "./PrivacyKitCompliantManagement";
import PrivacyKitPolicyAndNoticeManagement from "./PrivacyKitPolicyAndNoticeManagement";

import CyberKitLogManagementNavigation from "./CyberKitLogManagement";
import CyberKitSiemNavigation from "./CyberKitSiem";
import CyberKitSoarNavigation from "./CyberKitSoar";
import CyberKitComplianceManagementNavigation from "./CyberKitComplianceManagement";
import CyberKitVulnerabilityAssessment from "./CyberKitVulnerabilityAssessment";
import CyberKitItAssetInventoryNavigation from "./CyberKitItAssetInventory";
import UserManagement from "./UserManagement";
import SettingUser from "./SettingUser";

const NavigationConfig = (path, data) => {
  if (
    path.includes(`${PrivacyKitPath}${PrivacyKitCookieConsentManagementPath}`)
  ) {
    let navigation = PrivacyKitCookieConsentManagement;
    if (data.length > 0) {
      navigation = navigation.map((navigation) => ({
        ...navigation,
        disabled: false,
      }));
    }
    return navigation;
  }
  if (
    path.includes(
      `${PrivacyKitPath}${PrivacyKitDataSubjectRightManagementPath}`
    )
  ) {
    return PrivacyKitDataSubjectRightManagement;
  }
  if (
    path.includes(
      `${PrivacyKitPath}${PrivacyKitUniversalConsentAndPreferenceManagementPath}`
    )
  ) {
    return PrivacyKitUniversalConsentAndPreferenceManagement;
  }
  if (
    path.includes(
      `${PrivacyKitPath}${PrivacyKitIncidentResponseManagementPath}`
    )
  ) {
    return PrivacyKitIncidentResponseManagement;
  }
  if (
    path.includes(`${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}`)
  ) {
    return PrivacyKitDataBreachNotification;
  }
  if (
    path.includes(
      `${PrivacyKitPath}${PrivacyKitPrivacyAssessmentAutomationPath}`
    )
  ) {
    return PrivacyKitPrivacyAssessmentAutomation;
  }
  if (
    path.includes(
      `${PrivacyKitPath}${PrivacyKitAssessmentAutomationPath}${PrivacyKitInternalAssessmentAutomationPath}`
    )
  ) {
    return PrivacyKitAssessment(PrivacyKitInternalAssessmentAutomationPath);
  }
  if (
    path.includes(
      `${PrivacyKitPath}${PrivacyKitAssessmentAutomationPath}${PrivacyKitVendorAssessmentAutomationPath}`
    )
  ) {
    return PrivacyKitAssessment(PrivacyKitVendorAssessmentAutomationPath);
  }
  if (
    path.includes(
      `${PrivacyKitPath}${PrivacyKitDataClassificationAndDiscoveryPath}`
    )
  ) {
    return PrivacyKitDataClassificationAndDiscovery;
  }
  if (path.includes(`${PrivacyKitPath}${PrivacyKitCompliantManagementPath}`)) {
    return PrivacyKitCompliantManagement;
  }
  if (path.includes(`${OrganizationsManagementPath}`)) {
    return UserManagement;
  }
  if (path.includes(`${SettingUserPath}`)) {
    return SettingUser;
  }
  if (path.includes(`${PrivacyKitPath}${PrivacyKitDataMappingPath}`)) {
    return PrivacyKitDataMapping;
  }
  if (
    path.includes(`${PrivacyKitPath}${PrivacyKitPolicyAndNoticeManagementPath}`)
  ) {
    return PrivacyKitPolicyAndNoticeManagement;
  }
  if (path.includes(`${CyberKitPath}${CyberKitLogManagementPath}`))
    return CyberKitLogManagementNavigation(CyberKitLogManagementPath);
  if (path.includes(`${CyberKitPath}${CyberKitSiemPath}`))
    return CyberKitSiemNavigation(CyberKitSiemPath);
  if (path.includes(`${CyberKitPath}${CyberKitSoarPath}`))
    return CyberKitSoarNavigation(CyberKitSoarPath);
  if (path.includes(`${CyberKitPath}${CyberKitVulnerabilityAssessmentPath}`))
    return CyberKitVulnerabilityAssessment(CyberKitVulnerabilityAssessmentPath);
  if (path.includes(`${CyberKitPath}${CyberKitComplianceManagementPath}`))
    return CyberKitComplianceManagementNavigation;
  if (path.includes(`${CyberKitPath}${CyberKitITAssetInventoryPath}`))
    return CyberKitItAssetInventoryNavigation(CyberKitITAssetInventoryPath);
  return [];
};

export default NavigationConfig;
