export const LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE =
  "LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE";
export const LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS =
  "LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS";
export const TOGGLE_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE =
  "TOGGLE_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE";
export const SET_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE =
  "SET_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE";
export const GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE =
  "GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE";
export const GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS =
  "GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS";
export const CREATE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS =
  "CREATE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS";
export const UPDATE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS =
  "UPDATE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS";
export const TOGGLE_DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE =
  "TOGGLE_DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE";
export const DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE =
  "DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE";
export const DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS =
  "DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS";
export const DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_ERROR =
  "DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_ERROR";
