export const selectDataSubjectRightManagementRequest = (state) =>
  state.privacykitDataSubjectRightManagement.request;

export const selectDataSubjectRightManagementRequestData = (state) =>
  state.privacykitDataSubjectRightManagement.request.requestData;

export const selectDataSubjectRightManagementRequestTask = (state) =>
  state.privacykitDataSubjectRightManagement.request.task;

export const selectDataSubjectRightManagementRequestTaskModal = (state) =>
  state.privacykitDataSubjectRightManagement.request.taskModal;
