export const CHANGE_SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY =
  "CHANGE_SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY";
export const SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY =
  "SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY";
export const SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY_SUCCESS =
  "SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY_SUCCESS";
export const SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY_ERROR =
  "SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY_ERROR";

export const LIST_DATA_CLASSIFICATION_AND_DISCOVERY =
  "LIST_DATA_CLASSIFICATION_AND_DISCOVERY";
export const LIST_DATA_CLASSIFICATION_AND_DISCOVERY_SUCCESS =
  "LIST_DATA_CLASSIFICATION_AND_DISCOVERY_SUCCESS";
export const LIST_DATA_CLASSIFICATION_AND_DISCOVERY_ERROR =
  "LIST_DATA_CLASSIFICATION_AND_DISCOVERY_ERROR";
