const convertJsonParamsToString = (payload) => {
    let params = ``
    const keyParamList = Object.keys((payload || {}));
    if (keyParamList.length > 0) {
        keyParamList.forEach((value, index) => {
            params = params.concat(
                `${index === 0 ? "?" : ""}`, `${value}=${payload[value]}`, 
                `${parseInt(index) !== keyParamList.length - 1? '&' : ''}`
            )
        })
    }

    return params
}

export default convertJsonParamsToString;