import {
  GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_SUCCESS,
  GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_FAILED,
  CHANGE_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_FILTER,
  LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
  LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED,
  CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
  CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED,
  SCAN_COOKIES_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
  GET_COOKIE_CONSENT_MANAGEMENT_DETAIL_SUCCESS,
  GET_COOKIE_CONSENT_MANAGEMENT_DETAIL_FAILED,
  UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
  UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED,
  DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
  DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED,
  UPDATE_COOKIES_CONSENT_MANAGEMENT_CATEGORY_SUCCESS,
} from "../constants/PrivacyKitCookieConsentManagement";

export const getCookieConsentManagementDashboardSuccess = (payload) => {
  return {
    type: GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_SUCCESS,
    payload,
  };
};

export const getCookieConsentManagementDashboardFailed = (payload) => {
  return {
    type: GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_FAILED,
    payload,
  };
};

export const changeCookieConsentManagementDashboardFilter = (
  startDate,
  endDate
) => {
  return {
    type: CHANGE_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_FILTER,
    payload: {
      startDate,
      endDate,
    },
  };
};

export const listCookieConsentManagementDomainSuccess = (payload) => {
  return {
    type: LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
    payload,
  };
};

export const listCookieConsentManagementDomainFailed = (payload) => {
  return {
    type: LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED,
    payload,
  };
};

export const createCookieConsentManagementDomainSuccess = (payload) => {
  return {
    type: CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
    payload,
  };
};

export const createCookieConsentManagementDomainFailed = (payload) => {
  return {
    type: CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED,
    payload,
  };
};

export const scanCookieConsentManagementDomainSuccess = (payload) => ({
  type: SCAN_COOKIES_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
  payload,
});

export const getCookieConsentManagementDetailSuccess = (payload) => {
  return {
    type: GET_COOKIE_CONSENT_MANAGEMENT_DETAIL_SUCCESS,
    payload,
  };
};

export const getCookieConsentManagementDetailFailed = (payload) => {
  return {
    type: GET_COOKIE_CONSENT_MANAGEMENT_DETAIL_FAILED,
    payload,
  };
};

export const updateCookieConsentManagementDomainSuccess = (payload) => {
  return {
    type: UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
    payload,
  };
};

export const updateCookieConsentManagementDomainFailed = (payload) => {
  return {
    type: UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED,
    payload,
  };
};

export const deleteCookieConsentManagementDomainSuccess = (payload) => ({
  type: DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
  payload,
});

export const deleteCookieConsentManagementDomainFailed = (payload) => ({
  type: DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED,
  payload,
});

export const updateCookieConsentManagementCategorySuccess = (payload) => ({
  type: UPDATE_COOKIES_CONSENT_MANAGEMENT_CATEGORY_SUCCESS,
  payload,
});
