import { takeEvery, takeLatest, put, call, select } from "redux-saga/effects";
import {
  LIST_CONSENT_MANAGEMENT_DATE_ELEMENT,
  LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_TYPE,
  GET_CONSENT_MANAGEMENT_DATE_ELEMENT,
  SET_MANAGE_CONSENT_MANAGEMENT_DATE_ELEMENT,
  DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT,
  SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT,
} from "../../constants/PrivacyKitConsentManagement";
import {
  listConsentManagementDataElementSuccess,
  listConsentManagementDataElementTypeSuccess,
  getConsentManagementDataElementSuccess,
  createConsentManagementDataElementSuccess,
  updateConsentManagementDataElementSuccess,
  createOrUpdateConsentManagementDataElementError,
  deleteConsentManagementDataElementSuccess,
  deleteConsentManagementDataElementError,
  SearchConsentManagementDataElementSuccess,
} from "../../actions/PrivacyKitConsentManagement";
import {
  selectListDataElementType,
  selectManageDataElement,
} from "../../selectors/PrivacyKitConsentManagement";
import UniversalConsentAndPreferenceService from "services/pdpakit/UniversalConsentAndPreferenceService";
import _ from "lodash";
import Notification from "util/Notification";

export function* listConsentManagementDataElement() {
  yield takeEvery(LIST_CONSENT_MANAGEMENT_DATE_ELEMENT, function* () {
    try {
      const { Items } = yield call(
        UniversalConsentAndPreferenceService.listDataElement
      );
      yield put(listConsentManagementDataElementSuccess(Items));
    } catch (error) {
      console.log(error);
    }
  });
}

export function* listConsentManagementDataElementType() {
  yield takeEvery(LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_TYPE, function* () {
    try {
      const { Items } = yield call(
        UniversalConsentAndPreferenceService.listDataElementType
      );
      yield put(listConsentManagementDataElementTypeSuccess(Items));
    } catch (error) {
      console.log(error);
    }
  });
}

export function* getConsentManagementDataElement() {
  yield takeEvery(
    GET_CONSENT_MANAGEMENT_DATE_ELEMENT,
    function* ({ dataElementId }) {
      try {
        const { Item } = yield call(
          UniversalConsentAndPreferenceService.getDataElement,
          dataElementId
        );

        const setting = {};
        Object.entries(Item.setting).forEach(([, { key, value }]) => {
          setting[key] = value;
        });
        const options = Item.form_fields.options;

        let data = { ...Item, setting, options };
        yield put(getConsentManagementDataElementSuccess(data));
      } catch (error) {
        console.log(error);
      }
    }
  );
}

export function* setManageConsentManagementDataElement() {
  yield takeEvery(
    SET_MANAGE_CONSENT_MANAGEMENT_DATE_ELEMENT,
    function* ({ values }) {
      try {
        const { dataElementId, data } = yield select(selectManageDataElement);
        const listDataElementType = yield select(selectListDataElementType);

        const dataElement = _.find(listDataElementType, {
          key: values.data_element_type,
        });
        const form_fields = _.find(dataElement.display_as, {
          key: values.form_fields,
        });

        const payload = {
          ...data,
          ...values,
          options: undefined,
          ObjectType: undefined,
          form_fields: {
            ...form_fields,
            options: values.options,
          },
          setting: Object.entries(values.setting).map(([key, value]) => {
            return { key, value };
          }),
        };

        if (dataElementId) {
          const data = yield call(
            UniversalConsentAndPreferenceService.updateDataElement,
            dataElementId,
            payload
          );
          yield put(
            updateConsentManagementDataElementSuccess(dataElementId, data)
          );
          Notification("success", "แก้ไขข้อมูลที่จัดเก็บเรียบร้อยแล้ว");
        }

        if (!dataElementId) {
          const data = yield call(
            UniversalConsentAndPreferenceService.createDataElement,
            payload
          );
          yield put(createConsentManagementDataElementSuccess(data));
          Notification("success", "สร้างข้อมูลที่จัดเก็บเรียบร้อยแล้ว");
        }
      } catch (error) {
        yield put(createOrUpdateConsentManagementDataElementError());
        if (error.errorCode === "CreateNotAllowed") {
          Notification(
            "error",
            "เกิดข้อผิดพลาด เนื่องจากชื่อข้อมูลที่จัดเก็บมีการใช้งานแล้ว"
          )
        } else if (error.errorCode === "EditNotAllowed") {
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถแก้ไขข้อมูลพื้นฐานที่ระบบสร้างให้ได้"
          )
        } else  {
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถสร้างหรือแก้ไขข้อมูลที่จัดเก็บได้"
          );
        }
      }
    }
  );
}

export function* deleteConsentManagementDataElement() {
  yield takeEvery(
    DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT,
    function* ({ dataElementId }) {
      try {
        yield call(
          UniversalConsentAndPreferenceService.deleteDataElement,
          dataElementId
        );
        yield put(deleteConsentManagementDataElementSuccess(dataElementId));
        Notification("success", "ลบข้อมูลที่จัดเก็บเรียบร้อยแล้ว");
      } catch (error) {
        console.log(error);
        yield put(deleteConsentManagementDataElementError());
        if (error.errorCode === "DeleteNotAllowed") {
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารลบข้อมูลพื้นฐานของระบบได้"
          )
        } else  {
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถลบข้อมูลที่จัดเก็บได้"
          );
        }
      }
    }
  );
}

export function* SearchConsentManagementDataElement() {
  yield takeLatest(
    SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT,
    function* ({ search }) {
      try {
        const data = yield call(
          UniversalConsentAndPreferenceService.searchDataElement,
          search
        );
        yield put(
          SearchConsentManagementDataElementSuccess({
            listDataElemen: data,
            // nextPageToken: next_page_token,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );
}
