import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import {
  CREATE_EVENT,
  CREATE_DETECTION,
  UPDATE_DETECTION,
  GET_NOTIFICATION,
  CREATE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from "redux/constants/CyberKitSiem";
import { setGlobalLoading } from "redux/actions/CyberKitTool";
import { setNotification } from "redux/actions/CyberKitSiem";
import SiemServices from "services/cyberkit/SiemServices";

import Notification from "util/Notification";
import convertJsonParamsToString from "util/convertJsonParamsToString";

// EVENT
export function* createEvent() {
  yield takeEvery(CREATE_EVENT, function* ({ payload }) {
    const createPayload = {
      data: payload.data,
    };

    yield put(setGlobalLoading(true));
    const { status } = yield call(SiemServices.createEvent, createPayload);

    const UpdateEventPayload = {
      path: {
        id: payload.id,
      },
      data: {
        description: payload.data.description,
        workflow_name: payload.data.workflow_name,
        start_date: payload.data.due_date.start,
        end_date: payload.data.due_date.day,
        tags: payload.data.tags,
        status: "done",
      },
    };

    if (status === "OK") {
      const { status } = yield call(
        SiemServices.updateEventStatus,
        UpdateEventPayload
      );

      if (status === "OK") {
        yield put(setGlobalLoading(false));
        payload.next();
      } else {
        yield put(setGlobalLoading(false));
      }
    } else {
      yield put(setGlobalLoading(false));
    }
  });
}

// DETECTION
export function* createDetection() {
  yield takeEvery(CREATE_DETECTION, function* ({ payload }) {
    const resp = yield call(SiemServices.createSecurityRule, payload);

    const { status } = resp;

    if (status === "OK") {
      payload.next();
    }
  });
}

export function* updateDetection() {
  yield takeEvery(UPDATE_DETECTION, function* ({ payload }) {
    const resp = yield call(SiemServices.updateSecurityRule, payload);

    const { status } = resp;

    if (status === "OK") {
      payload.next();
    }
  });
}

// NOTIFICATION
export function* getNotificationList() {
  yield takeEvery(GET_NOTIFICATION, function* ({ payload }) {
    let params = ``;
    const keyParamList = Object.keys(payload.params || {});
    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);
    const payloadService = { params: params };

    const { status, data } = yield call(
      SiemServices.getNotificationList,
      payloadService
    );

    if (status === "OK") yield put(setNotification(data));
  });
}

export function* createNoti() {
  yield takeEvery(CREATE_NOTIFICATION, function* ({ payload }) {
    const resp = yield call(SiemServices.createNotification, payload);

    const { status } = resp;

    if (status === "OK") {
      Notification("success", `สร้างการแจ้งเตือนสำเร็จ`);
      payload.next();
    } else Notification("error", `เกิดข้อผิดพลาดบางอย่าง`);
  });
}

export function* updateNoti() {
  yield takeEvery(UPDATE_NOTIFICATION, function* ({ payload }) {
    const resp = yield call(SiemServices.updateNotification, payload);

    const { status } = resp;

    if (status === "OK") {
      Notification("success", `อัพเดตการแจ้งเตือนสำเร็จ`);
      payload.next();
    } else Notification("error", `เกิดข้อผิดพลาดบางอย่าง`);
  });
}

export default function* rootSaga() {
  yield all([
    fork(getNotificationList),
    fork(createEvent),
    fork(createDetection),
    fork(updateDetection),
    fork(createNoti),
    fork(updateNoti),
  ]);
}
