export const LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE =
  "LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE";
export const LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS =
  "LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS";
export const GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE =
  "GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE";
export const GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS =
  "GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS";
export const SET_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE =
  "SET_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE";
export const CREATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE =
  "CREATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE";
export const CREATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS =
  "CREATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS";
export const UPDATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE =
  "UPDATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE";
export const UPDATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS =
  "UPDATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS";
export const TOGGLE_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE =
  "TOGGLE_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE";
export const TOGGLE_DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE =
  "TOGGLE_DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE";
export const DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE =
  "DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE";
export const DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS =
  "DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS";
export const DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_ERROR =
  "DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_ERROR";
