import { takeEvery, put, call } from "redux-saga/effects";
import { GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY } from "../../constants/PrivacyKitPrivacyAssessmentAutomation";
import {
  getPrivacyAssessmentAutomationDataMappingInventorySuccess,
  getPrivacyAssessmentAutomationDataMappingInventoryFailure,
} from "../../actions/PrivacyKitPrivacyAssessmentAutomation";
import PrivacyAssessmentAutomationService from "services/pdpakit/PrivacyAssessmentAutomationService";

export function* getPrivacyAssessmentAutomationDataMappingInventory() {
  yield takeEvery(
    GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY,
    function* ({ inventoryId }) {
      try {
        const response = yield call(
          PrivacyAssessmentAutomationService.getDataMappingInventoryPrivacyAssessmentAutomation,
          inventoryId
        );
        yield put(
          getPrivacyAssessmentAutomationDataMappingInventorySuccess(
            response.data
          )
        );
      } catch (error) {
        yield put(
          getPrivacyAssessmentAutomationDataMappingInventoryFailure(error)
        );
      }
    }
  );
}
