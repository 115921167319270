import {
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD_SUCCESS,
  CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD_FILTER,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";

export const getDataSubjectRightManagementDashboard = (filter) => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD,
  filter,
});

export const getDataSubjectRightManagementDashboardSuccess = (payload) => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD_SUCCESS,
  payload,
});

export const changeDataSubjectRightManagementDashboardFilter = (
  startDate,
  endDate
) => ({
  type: CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD_FILTER,
  filter: {
    startDate,
    endDate,
  },
});
