import { changeFormatDatetime } from "util/DateUtils";
import moment from "moment";

const COLOUR_TAG = {
  Running: { color: "#FFC542", label: "กำลังสแกน" },
  Wait: { color: "#FFC542", label: "รอ" },
  Done: { color: "#04D182", label: "เสร็จสิ้น" },
};

export default class TaskModel {
  constructor(data) {
    this.id = data.id ? data.id : "";
    this.name = data.name ? data.name : "";
    this.start = data.start ? data.start : "";
    this.end = data.end ? data.end : "";
    this.host = data.host ? data.host : "";
    this.status = data.status ? data.status : "";
  }

  displayID = () => this.id;
  displayName = () => this.name;
  displayStart = () => {
    if (this.start !== "0001-01-01T00:00:00Z")
      return changeFormatDatetime(this.start, "DD/MM/YYYY h:mm A");
    return "-";
  };
  displayEnd = () => {
    if (this.status.toLowerCase() !== "done") return "-";
    else {
      if (this.end !== "0001-01-01T00:00:00Z")
        return changeFormatDatetime(this.end, "DD/MM/YYYY h:mm A");
      return "-";
    }
  };
  displayHost = () => this.host;
  displayPeriod = () => {
    let time = "-";
    if (
      this.start !== "0001-01-01T00:00:00Z" ||
      this.end !== "0001-01-01T00:00:00Z"
    ) {
      time = moment(this.end).from(this.start);
      time = time.replace("in", "");
      time = time.replace("ago", "");
      time = time.replace("minutes", "นาที");
      time = time.replace("hours", "ชั่วโมง");
      time = time.replace("days", "วัน");
      time = time.replace("a few seconds", "ไม่กี่วินาทีที่แล้ว");
      time = time.replace("seconds", "วินาที");
    }
    return time;
  };
  displayStatusColor = () => COLOUR_TAG[this.status]?.color || "#000000";
  displayStatusLabel = () => COLOUR_TAG[this.status]?.label || "None";
}
