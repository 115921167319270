// Dashboard
export const GET_POLICY_AND_NOTICE_MANAGEMENT_DASHBOARD =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_DASHBOARD";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_DASHBOARD_SUCCESS =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_DASHBOARD_SUCCESS";

// Policy
export const LIST_POLICY_AND_NOTICE_MANAGEMENT =
  "LIST_POLICY_AND_NOTICE_MANAGEMENT";
export const LIST_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS =
  "LIST_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS";
export const LIST_POLICY_AND_NOTICE_MANAGEMENT_FAILED =
  "LIST_POLICY_AND_NOTICE_MANAGEMENT_FAILED";
export const SET_POLICY_AND_NOTICE_MANAGEMENT_INIT_CREATE =
  "SET_POLICY_AND_NOTICE_MANAGEMENT_INIT_CREATE";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_SUCCESS =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_SUCCESS";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_FAILED =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_FAILED";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_DATA =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_DATA";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_DATA_SUCCESS =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_DATA_SUCCESS";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_DATA_FAILED =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_DATA_FAILED";
export const CREATE_POLICY_AND_NOTICE_MANAGEMENT =
  "CREATE_POLICY_AND_NOTICE_MANAGEMENT";
export const CREATE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS =
  "CREATE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS";
export const CREATE_POLICY_AND_NOTICE_MANAGEMENT_FAILED =
  "CREATE_POLICY_AND_NOTICE_MANAGEMENT_FAILED";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_BUILDER =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_BUILDER";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_BUILDER_SUCCESS =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_BUILDER_SUCCESS";
export const GET_POLICY_AND_NOTICE_MANAGEMENT =
  "GET_POLICY_AND_NOTICE_MANAGEMENT";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_FAILED =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_FAILED";
export const UPDATE_POLICY_AND_NOTICE_MANAGEMENT =
  "UPDATE_POLICY_AND_NOTICE_MANAGEMENT";
export const UPDATE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS =
  "UPDATE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS";
export const UPDATE_POLICY_AND_NOTICE_MANAGEMENT_FAILED =
  "UPDATE_POLICY_AND_NOTICE_MANAGEMENT_FAILED";
export const PUBLISH_POLICY_AND_NOTICE_MANAGEMENT =
  "PUBLISH_POLICY_AND_NOTICE_MANAGEMENT";
export const PUBLISH_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS =
  "PUBLISH_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_VERSION =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_VERSION";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_VERSION_SUCCESS =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_VERSION_SUCCESS";
export const DELETE_POLICY_AND_NOTICE_MANAGEMENT =
  "DELETE_POLICY_AND_NOTICE_MANAGEMENT";
export const DELETE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS =
  "DELETE_POLICY_AND_NOTICE_MANAGEMENT_SUCCESS";
export const DELETE_POLICY_AND_NOTICE_MANAGEMENT_FAILED =
  "DELETE_POLICY_AND_NOTICE_MANAGEMENT_FAILED";

// Task
export const LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK =
  "LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK";
export const LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK_SUCCESS =
  "LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK_SUCCESS";
export const LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK_FAILED =
  "LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK_FAILED";
export const UPDATE_POLICY_AND_NOTICE_MANAGEMENT_TASK =
  "UPDATE_POLICY_AND_NOTICE_MANAGEMENT_TASK";
export const UPDATE_POLICY_AND_NOTICE_MANAGEMENT_TASK_SUCCESS =
  "UPDATE_POLICY_AND_NOTICE_MANAGEMENT_TASK_SUCCESS";
export const UPDATE_POLICY_AND_NOTICE_MANAGEMENT_TASK_FAILED =
  "UPDATE_POLICY_AND_NOTICE_MANAGEMENT_TASK_FAILED";

// Assessment
export const LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT =
  "LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT";
export const LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS =
  "LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS";
export const LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED =
  "LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS";
export const GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED =
  "GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED";
export const CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT =
  "CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT";
export const CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS =
  "CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS";
export const CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED =
  "CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED";
export const UPDATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT =
  "UPDATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT";
export const UPDATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS =
  "UPDATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS";
export const UPDATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED =
  "UPDATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED";
export const DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT =
  "DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT";
export const DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS =
  "DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_SUCCESS";
export const DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED =
  "DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT_FAILED";

export const RESET_POLICY_AND_NOTICE_MANAGEMENT_DATA = "RESET_POLICY_AND_NOTICE_MANAGEMENT_DATA"
