import {
  // ASSET
  SET_ASSETS,
  GET_TEMPLATE_DEVICE_LIST,
  SET_TEMPLATE_DEVICE_LIST,
  CREATE_ASSET,
  UPDATE_ASSET,
  DELETE_ASSET,
  GET_OPTION,
  SET_OPTION,
  CREATE_OPTION,
  UPDATE_OPTION,
  DELETE_OPTION,

  // ASSESSMENT
  SET_ASSESSMENT,
  GET_ASSESSMENT_BY_TYPE,
} from "redux/constants/CyberKitAssetInventory";

// ASSET
export const setAssetList = (payload) => {
  return {
    type: SET_ASSETS,
    payload: payload,
  };
};

export const getTemplateDeviceList = (payload) => {
  return {
    type: GET_TEMPLATE_DEVICE_LIST,
    payload: payload,
  };
};

export const setTemplateDeviceList = (payload) => {
  return {
    type: SET_TEMPLATE_DEVICE_LIST,
    payload: payload,
  };
};

export const createAsset = (payload) => {
  return {
    type: CREATE_ASSET,
    payload: payload,
  };
};

export const updateAsset = (payload) => {
  return {
    type: UPDATE_ASSET,
    payload: payload,
  };
};

export const deleteAsset = (payload) => {
  return {
    type: DELETE_ASSET,
    payload: payload,
  };
};

export const getAssetOption = (payload) => {
  return {
    type: GET_OPTION,
    payload: payload,
  };
};

export const setAssetOption = (payload) => {
  return {
    type: SET_OPTION,
    payload: payload,
  };
};

export const createAssetOption = (payload) => {
  return {
    type: CREATE_OPTION,
    payload: payload,
  };
};

export const updateAssetOption = (payload) => {
  return {
    type: UPDATE_OPTION,
    payload: payload,
  };
};

export const deleteAssetOption = (payload) => {
  return {
    type: DELETE_OPTION,
    payload: payload,
  };
};

// ASSESSMENT
export const getAssessmentByType = (payload) => {
  return {
    type: GET_ASSESSMENT_BY_TYPE,
    payload: payload,
  };
};

export const setAssessment = (payload) => {
  return {
    type: SET_ASSESSMENT,
    payload: payload,
  };
};
