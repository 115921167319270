import {
  LIST_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
  CREATE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
  DELETE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
  LIST_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS,
  CREATE_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS,
  DELETE_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS,
  LIST_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS,
  CREATE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS,
  DELETE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
  GET_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
} from "../constants/PrivacyKitIncidentResponseManagement";
import _ from "lodash";

const initState = {};

const PrivacyKitIncidentResponseManagement = (state = initState, action) => {
  switch (action.type) {
    case LIST_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS: {
      return {
        ...state,
        listEventStage: action.payload,
      };
    }
    case GET_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS: {
      return {
        ...state,
        eventStage: action.payload,
      };
    }
    case CREATE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS: {
      return {
        ...state,
        listEventStage: {
          data: [action.payload, ...state.listEventStage.data],
        },
      };
    }
    case UPDATE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS: {
      const { data } = state.listEventStage;
      const dataIndex = _.findIndex(
        data,
        ({ ObjectUUID }) => ObjectUUID === action.payload.ObjectUUID
      );
      data[dataIndex] = action.payload;
      return {
        ...state,
        listEventStage: { ...state.listEventStage, data },
      };
    }
    case DELETE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS: {
      const { data } = state.listEventStage;
      return {
        ...state,
        listEventStage: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    case LIST_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS: {
      return {
        ...state,
        listEventType: action.payload,
      };
    }
    case CREATE_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS: {
      return {
        ...state,
        listEventType: {
          data: [action.payload, ...state.listEventType.data],
        },
      };
    }
    case DELETE_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS: {
      const { data } = state.listEventType;
      return {
        ...state,
        listEventType: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    case LIST_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        listAttribute: action.payload,
      };
    }
    case CREATE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        listAttribute: {
          data: [action.payload, ...state.listAttribute.data],
        },
      };
    }
    case DELETE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS: {
      const { data } = state.listAttribute;
      return {
        ...state,
        listAttribute: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default PrivacyKitIncidentResponseManagement;
