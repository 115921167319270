import { CyberKitPath } from "../AppModuleConfig";

const CyberKitVulnerabilityAssessment = (assessmentType) => [
  {
    key: `cyberkit-${assessmentType}-agent`,
    path: `${CyberKitPath}${assessmentType}/agent`,
    title: "สร้างเครื่องสแกน",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `cyberkit-${assessmentType}-scanner`,
    path: `${CyberKitPath}${assessmentType}/scanner`,
    title: "การสแกน",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `cyberkit-${assessmentType}-dashboard`,
    path: `${CyberKitPath}${assessmentType}/dashboard`,
    title: "แดชบอร์ด",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
];

export default CyberKitVulnerabilityAssessment;
