import {
  LIST_PRIVACY_ASSESSMENT_AUTOMATION,
  LIST_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  LIST_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  TOGGLE_CREATE_PRIVACY_ASSESSMENT_AUTOMATION_MODAL,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  GET_PRIVACY_ASSESSMENT_AUTOMATION,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  TOGGLE_PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  TOGGLE_DELETE_PRIVACY_ASSESSMENT_AUTOMATION,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  CLEAR_PRIVACY_ASSESSMENT_AUTOMATION,
} from "../../constants/PrivacyKitPrivacyAssessmentAutomation";

export const listPrivacyAssessmentAutomation = () => ({
  type: LIST_PRIVACY_ASSESSMENT_AUTOMATION,
});

export const listPrivacyAssessmentAutomationSuccess = (
  listAssessment,
  nextPageToken
) => ({
  type: LIST_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  listAssessment,
  nextPageToken,
});

export const listPrivacyAssessmentAutomationFailure = (error) => ({
  type: LIST_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  error,
});

export const toggleCreatePrivacyAssessmentAutomationModal = () => ({
  type: TOGGLE_CREATE_PRIVACY_ASSESSMENT_AUTOMATION_MODAL,
});

export const createPrivacyAssessmentAutomation = (data) => ({
  type: CREATE_PRIVACY_ASSESSMENT_AUTOMATION,
  data,
});

export const createPrivacyAssessmentAutomationSuccess = (data) => ({
  type: CREATE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  data,
});

export const createPrivacyAssessmentAutomationFailure = (error) => ({
  type: CREATE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  error,
});

export const getPrivacyAssessmentAutomation = (assessmentId) => ({
  type: GET_PRIVACY_ASSESSMENT_AUTOMATION,
  assessmentId,
});

export const getPrivacyAssessmentAutomationSuccess = (data) => ({
  type: GET_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  data,
});

export const getPrivacyAssessmentAutomationFailure = (error) => ({
  type: GET_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  error,
});

export const updatePrivacyAssessmentAutomation = () => ({
  type: UPDATE_PRIVACY_ASSESSMENT_AUTOMATION,
});

export const updatePrivacyAssessmentAutomationSuccess = (data) => ({
  type: UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  data,
});

export const updatePrivacyAssessmentAutomationFailure = (error) => ({
  type: UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  error,
});

export const togglePublishPrivacyAssessmentAutomation = () => ({
  type: TOGGLE_PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION,
});

export const publishPrivacyAssessmentAutomation = () => ({
  type: PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION,
});

export const publishPrivacyAssessmentAutomationSuccess = (data) => ({
  type: PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  data,
});

export const publishPrivacyAssessmentAutomationFailure = (error) => ({
  type: PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  error,
});

export const toggleDeletePrivacyAssessmentAutomation = (data) => ({
  type: TOGGLE_DELETE_PRIVACY_ASSESSMENT_AUTOMATION,
  data,
});

export const deletePrivacyAssessmentAutomation = () => ({
  type: DELETE_PRIVACY_ASSESSMENT_AUTOMATION,
});

export const deletePrivacyAssessmentAutomationSuccess = (assessmentId) => ({
  type: DELETE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  assessmentId,
});

export const deletePrivacyAssessmentAutomationFailure = (error) => ({
  type: DELETE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  error,
});

export const clearPrivacyAssessmentAutomation = () => ({
  type: CLEAR_PRIVACY_ASSESSMENT_AUTOMATION,
});
