import {
  INIT_COMPLIANT_ASSESSMENT,
  CHANGE_COMPLIANT_ASSESSMENT_BUILDER,
  LIST_COMPLIANT_ASSESSMENT_SUCCESS,
  GET_COMPLIANT_ASSESSMENT_SUCCESS,
  DELETE_COMPLIANT_ASSESSMENT_SUCCESS,
  LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE_SUCCESS,
  GET_COMPLIANT_ASSESSMENT_TEMPLATE_DATA_SUCCESS,
  LIST_COMPLIANCE_PLAN_SUCCESS,
  CREATE_COMPLIANCE_PLAN_SUCCESS,
  DELETE_COMPLIANCE_PLAN_SUCCESS,
  GET_DETAIL_COMPLIANCE_PLAN_SUCCESS,
  UPDATE_COMPLIANCE_PLAN_SUCCESS,
} from "../constants/PrivacyKitCompliantManagement";
import _ from "lodash";
import { SELECT_ASSESSMENT_TYPE } from "redux/constants/PrivacyKitAssessmentAutomation";

const initState = {};

const PrivacyKitCompliantManagement = (state = initState, action) => {
  switch (action.type) {
    case SELECT_ASSESSMENT_TYPE: {
      return {
        assessmentType: action.payload,
      };
    }
    case INIT_COMPLIANT_ASSESSMENT: {
      return {
        ...state,
        assessment: {
          ...action.payload,
          sections: [],
          contents: [],
          multiChoices: [],
          compliantBuilders: [],
        },
      };
    }
    case CHANGE_COMPLIANT_ASSESSMENT_BUILDER: {
      return {
        ...state,
        assessment: {
          ...state.assessment,
          ...action.payload,
        },
      };
    }
    case LIST_COMPLIANT_ASSESSMENT_SUCCESS: {
      return {
        listAssessment: action.payload,
      };
    }
    case GET_COMPLIANT_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        assessment: action.payload,
      };
    }
    case DELETE_COMPLIANT_ASSESSMENT_SUCCESS: {
      const { listAssessment } = state;
      listAssessment["data"] = _.filter(
        listAssessment.data,
        ({ ObjectUUID }) => ObjectUUID !== action.payload
      );
      return {
        ...state,
      };
    }
    case LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE_SUCCESS: {
      return {
        listTemplateType: action.payload,
      };
    }
    case GET_COMPLIANT_ASSESSMENT_TEMPLATE_DATA_SUCCESS: {
      return {
        ...state,
        assessmentTemplateData: action.payload,
      };
    }
    case LIST_COMPLIANCE_PLAN_SUCCESS: {
      return {
        ...state,
        assessmentActive: undefined,
        listCompliancePlan: action.payload,
      };
    }
    case CREATE_COMPLIANCE_PLAN_SUCCESS: {
      return {
        ...state,
        listCompliancePlan: {
          data: [action.payload, ...state.listCompliancePlan.data],
        },
      };
    }
    case DELETE_COMPLIANCE_PLAN_SUCCESS: {
      const { data } = state.listCompliancePlan;
      return {
        ...state,
        listCompliancePlan: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    case GET_DETAIL_COMPLIANCE_PLAN_SUCCESS: {
      return {
        ...state,
        assessmentActive: undefined,
        compliancePlanData: action.payload,
      };
    }
    case UPDATE_COMPLIANCE_PLAN_SUCCESS: {
      const { data } = state.listCompliancePlan;
      const dataIndex = _.findIndex(
        data,
        ({ ObjectUUID }) => ObjectUUID === action.payload.ObjectUUID
      );
      data[dataIndex] = action.payload;
      return {
        ...state,
        listCompliancePlan: { ...state.listCompliancePlan, data },
      };
    }
    default: {
      return state;
    }
  }
};

export default PrivacyKitCompliantManagement;
