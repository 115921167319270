import {
  LIST_USER_MANAGEMENT_ORGANIZATION_SUCCESS,
  LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL_SUCCESS,
  LIST_USER_MANAGEMENT_ROLES_SUCCESS,
  LIST_USER_MANAGEMENT_ROLES_DETAIL_SUCCESS,
  LIST_USER_MANAGEMENT_ROLES_PERMISSIONS_SUCCESS,
  LIST_USER_MANAGEMENT_USER_SUCCESS,
  LIST_USER_MANAGEMENT_USER_DETAIL_SUCCESS,
  LIST_USER_MANAGEMENT_USER_GROUP_SUCCESS,
  LIST_USER_MANAGEMENT_USER_IN_GROUP_SUCCESS,
  LIST_USER_MANAGEMENT_ROLE_IN_GROUP_SUCCESS,
  LIST_USER_MANAGEMENT_USER_GROUP_DETAIL_SUCCESS,
  LIST_TENANT_USER_MANAGEMENT_USER_SUCCESS,
} from "../constants/UserManagement";

const initState = {};

const UserManagement = (state = initState, action) => {
  switch (action.type) {
    case LIST_USER_MANAGEMENT_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        listOrganization: action.payload,
      };
    }
    case LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL_SUCCESS: {
      return {
        ...state,
        organizationDetail: action.payload,
      };
    }
    case LIST_USER_MANAGEMENT_USER_SUCCESS: {
      return {
        ...state,
        listUser: action.payload,
      };
    }
    case LIST_USER_MANAGEMENT_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        userDetail: action.payload,
      };
    }
    case LIST_USER_MANAGEMENT_ROLES_SUCCESS: {
      return {
        ...state,
        listRoles: action.payload,
      };
    }
    case LIST_USER_MANAGEMENT_ROLES_DETAIL_SUCCESS: {
      return {
        ...state,
        roleDetail: action.payload,
      };
    }
    case LIST_USER_MANAGEMENT_ROLES_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        listRolesPermissions: action.payload,
      };
    }
    case LIST_USER_MANAGEMENT_USER_GROUP_SUCCESS: {
      return {
        ...state,
        listUserGroup: action.payload,
      };
    }
    case LIST_USER_MANAGEMENT_USER_GROUP_DETAIL_SUCCESS: {
      return {
        ...state,
        GroupDetail: action.payload,
      };
    }

    case LIST_USER_MANAGEMENT_USER_IN_GROUP_SUCCESS: {
      return {
        ...state,
        listUserInGroup: action.payload,
      };
    }
    case LIST_USER_MANAGEMENT_ROLE_IN_GROUP_SUCCESS: {
      return {
        ...state,
        listRoleInGroup: action.payload,
      };
    }
    case LIST_TENANT_USER_MANAGEMENT_USER_SUCCESS: {
      return {
        ...state,
        listUser: action.payload,
        listTenantUser: action.payload.users,
      };
    }
    default: {
      return state;
    }
  }
};

export default UserManagement;
