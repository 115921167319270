import {
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_FAILED,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DEFAULT_WORKFLOW,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_DELETE,
  CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGE_ORDER,
  CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGES,
  TOGGLE_CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_FAILED,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR,
  TOGGLE_PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED,
  TOGGLE_DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";

export const listDataSubjectRightManagementWorkflow = () => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
});

export const listDataSubjectRightManagementWorkflowSuccess = (payload) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  payload,
});

export const listDataSubjectRightManagementWorkflowFailed = (payload) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_FAILED,
  payload,
});

export const getDataSubjectRightManagementDefaultWorkflow = () => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DEFAULT_WORKFLOW,
});

export const getDataSubjectRightManagementWorkflow = (workflowId) => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  workflowId,
});

export const getDataSubjectRightManagementWorkflowSuccess = (payload) => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  payload,
});

export const toggleDataSubjectRightManagementWorkflowDelete = (payload) => ({
  type: TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_DELETE,
  payload,
});

export const changeDataSubjectRightManagementWorkflowStageOrder = (
  stageOrder
) => ({
  type: CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGE_ORDER,
  stageOrder,
});

export const changeDataSubjectRightManagementWorkflowStages = (stages) => ({
  type: CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGES,
  stages,
});

export const toggleCreateDataSubjectRightManagementWorkflow = (payload) => ({
  type: TOGGLE_CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  payload,
});

export const createDataSubjectRightManagementWorkflow = (values) => ({
  type: CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  values,
});

export const createDataSubjectRightManagementWorkflowSuccess = (payload) => ({
  type: CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  payload,
});

export const createDataSubjectRightManagementWorkflowFailed = (payload) => ({
  type: CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_FAILED,
  payload,
});

export const updateDataSubjectRightManagementWorkflow = (values) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  values,
});

export const updateDataSubjectRightManagementWorkflowSuccess = (payload) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  payload,
});

export const updateDataSubjectRightManagementWorkflowError = (payload) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR,
  payload,
});

export const togglePublishDataSubjectRightManagementWorkflow = () => ({
  type: TOGGLE_PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
});

export const publishDataSubjectRightManagementWorkflow = (values) => ({
  type: PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
  values,
});

export const publishDataSubjectRightManagementWorkflowSuccess = (payload) => ({
  type: PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  payload,
});

export const publishDataSubjectRightManagementWorkflowError = (payload) => ({
  type: PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR,
  payload,
});

export const deleteDataSubjectRightManagementWorkflow = () => ({
  type: DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW,
});

export const deleteDataSubjectRightManagementWorkflowSuccess = (
  workflowId
) => ({
  type: DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS,
  workflowId,
});

export const deleteDataSubjectRightManagementWorkflowError = () => ({
  type: DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR,
});

export const listDataSubjectRightManagementTaskInWorkflow = (workflowId) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  workflowId,
});

export const listDataSubjectRightManagementTaskInWorkflowSuccess = (
  listTaskInWorkflow
) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS,
  listTaskInWorkflow,
});

export const listDataSubjectRightManagementTaskInWorkflowFailed = (
  payload
) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED,
  payload,
});

export const toggleDataSubjectRightManagementTaskInWorkflow = (data) => ({
  type: TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  data,
});

export const createDataSubjectRightManagementTaskInWorkflow = (values) => ({
  type: CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  values,
});

export const createDataSubjectRightManagementTaskInWorkflowSuccess = (
  payload
) => ({
  type: CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS,
  payload,
});

export const createDataSubjectRightManagementTaskInWorkflowFailed = () => ({
  type: CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED,
});

export const updateDataSubjectRightManagementTaskInWorkflow = (values) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  values,
});

export const updateDataSubjectRightManagementTaskInWorkflowSuccess = (
  payload
) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS,
  payload,
});

export const updateDataSubjectRightManagementTaskInWorkflowFailed = () => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED,
});

export const toggleDeleteDataSubjectRightManagementTaskInWorkflow = (
  payload
) => ({
  type: TOGGLE_DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
  payload,
});

export const deleteDataSubjectRightManagementTaskInWorkflow = () => ({
  type: DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW,
});

export const deleteDataSubjectRightManagementTaskInWorkflowSuccess = (
  taskId
) => ({
  type: DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS,
  taskId,
});

export const deleteDataSubjectRightManagementTaskInWorkflowFailed = () => ({
  type: DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED,
});
