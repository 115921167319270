import {
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_SUCCESS,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE_SUCCESS,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE_FAILURE,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_FAILED,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_NEXT_BUTTON,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_BACK_BUTTON,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS,
  MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS,
  MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_ERROR,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_MODAL,
  SEARCH_DATA_REQUEST,
  SEARCH_DATA_REQUEST_SUCCESS,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";

export const listDataSubjectRightManagementRequest = () => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST,
});

export const listDataSubjectRightManagementRequestSuccess = (payload) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_SUCCESS,
  payload,
});

export const listDataSubjectRightManagementRequestNextPage = (
  nextPageToken
) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE,
  nextPageToken,
});

export const listDataSubjectRightManagementRequestNextPageSuccess = (
  payload
) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE_SUCCESS,
  payload,
});

export const listDataSubjectRightManagementRequestNextPageFailure = () => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE_FAILURE,
});

export const getDataSubjectRightManagementRequest = (requestId, userKey) => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST,
  requestId,
  userKey,
});

export const getDataSubjectRightManagementRequestSuccess = (
  stage,
  payload
) => ({
  type: GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_SUCCESS,
  stage,
  payload,
});

export const updateDataSubjectRightManagementRequestStage = (stageOrder) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE,
  stageOrder,
});

export const updateDataSubjectRightManagementRequestSuccess = (payload) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_SUCCESS,
  payload,
});

export const updateDataSubjectRightManagementRequestFailed = (payload) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_FAILED,
  payload,
});

export const toggleDataSubjectRightManagementRequestNextButton = (payload) => ({
  type: TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_NEXT_BUTTON,
  payload,
});

export const toggleDataSubjectRightManagementRequestBackButton = (payload) => ({
  type: TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_BACK_BUTTON,
  payload,
});

export const listDataSubjectRightManagementRequestTask = (
  requestId,
  userKey
) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK,
  requestId,
  userKey,
});

export const listDataSubjectRightManagementRequestTaskSuccess = (payload) => ({
  type: LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS,
  payload,
});

export const manageDataSubjectRightManagementRequestTask = (payload) => ({
  type: MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK,
  payload,
});

export const createDataSubjectRightManagementRequestTaskSuccess = (
  payload
) => ({
  type: CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS,
  payload,
});

export const updateDataSubjectRightManagementRequestTaskSuccess = (
  payload
) => ({
  type: UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS,
  payload,
});

export const manageDataSubjectRightManagementRequestTaskError = () => ({
  type: MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_ERROR,
});

export const toggleDataSubjectRightManagementRequestTaskModal = (payload) => ({
  type: TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_MODAL,
  payload,
});

export const searchDataRequest = (search) => ({
  type: SEARCH_DATA_REQUEST,
  search,
});

export const searchDataRequestSuccess = (payload) => ({
  type: SEARCH_DATA_REQUEST_SUCCESS,
  payload,
});
