import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP,
  MANAGE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
} from "../../constants/IPrivacyKitDataMapping";
import {
  listDataMappingRelatedControlMeasuresSuccess,
  listDataMappingRelatedControlMeasuresFailure,
  listDataMappingRelatedControlMeasuresGroupSuccess,
  listDataMappingRelatedControlMeasuresGroupFailure,
  createDataMappingRelatedControlMeasuresSuccess,
  createDataMappingRelatedControlMeasuresFailure,
  updateDataMappingRelatedControlMeasuresSuccess,
  updateDataMappingRelatedControlMeasuresFailure,
  deleteDataMappingRelatedControlMeasuresSuccess,
  deleteDataMappingRelatedControlMeasuresFailure,
} from "../../actions/IPrivacyKitDataMapping";
import {
  selectIPrivacyKitDataMappingRelatedControlMeasuresManageModal,
  selectIPrivacyKitDataMappingRelatedControlMeasuresDeleteModal,
} from "../../selectors/IPrivacyKitDataMapping";
import DataMappingServices from "services/pdpakit/IDataMappingServices";
import Notification from "util/Notification";

export function* listDataMappingRelatedControlMeasuresSaga() {
  yield takeEvery(LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES, function* () {
    try {
      const response = yield call(
        DataMappingServices.listRelatedControlMeasures
      );
      yield put(listDataMappingRelatedControlMeasuresSuccess(response.data));
    } catch (error) {
      yield put(listDataMappingRelatedControlMeasuresFailure(error));
    }
  });
}

export function* listDataMappingRelatedControlMeasuresGroupSaga() {
  yield takeEvery(
    LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP,
    function* () {
      try {
        const response = yield call(
          DataMappingServices.listRelatedControlMeasuresGroup
        );

        const listAllControl = [];
        response.data.forEach((item) => {
          listAllControl.push(item);
          item?.childs?.forEach((child) => {
            listAllControl.push(child);
          });
        });

        yield put(
          listDataMappingRelatedControlMeasuresGroupSuccess({
            listAllControl,
            listGroup: response.data,
          })
        );
      } catch (error) {
        yield put(listDataMappingRelatedControlMeasuresGroupFailure(error));
      }
    }
  );
}

export function* manageDataMappingRelatedControlMeasuresSaga() {
  yield takeEvery(
    MANAGE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
    function* ({ values }) {
      const { data } = yield select(
        selectIPrivacyKitDataMappingRelatedControlMeasuresManageModal
      );
      if (data) {
        yield put({
          type: UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
          data: { ...data, ...values },
        });
      } else {
        yield put({
          type: CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
          values,
        });
      }
    }
  );
}

export function* createDataMappingRelatedControlMeasuresSaga() {
  yield takeEvery(
    CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
    function* ({ values }) {
      try {
        const response = yield call(
          DataMappingServices.createRelatedControlMeasures,
          values
        );
        yield put(
          createDataMappingRelatedControlMeasuresSuccess(response.data)
        );
        Notification("success", "สร้างมาตรการควบคุมที่เกี่ยวข้องสำเร็จ");
      } catch (error) {
        yield put(createDataMappingRelatedControlMeasuresFailure(error));
        Notification("error", "สร้างมาตรการควบคุมที่เกี่ยวข้องไม่สำเร็จ");
      }
    }
  );
}

export function* updateDataMappingRelatedControlMeasuresSaga() {
  yield takeEvery(
    UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
    function* ({ data }) {
      try {
        const response = yield call(
          DataMappingServices.updateRelatedControlMeasures,
          data
        );
        yield put(
          updateDataMappingRelatedControlMeasuresSuccess(response.data)
        );
        Notification("success", "แก้ไขมาตรการควบคุมที่เกี่ยวข้องสำเร็จ");
      } catch (error) {
        yield put(updateDataMappingRelatedControlMeasuresFailure(error));
        Notification("error", "แก้ไขมาตรการควบคุมที่เกี่ยวข้องไม่สำเร็จ");
      }
    }
  );
}

export function* deleteDataMappingRelatedControlMeasuresSaga() {
  yield takeEvery(DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES, function* () {
    try {
      const { data } = yield select(
        selectIPrivacyKitDataMappingRelatedControlMeasuresDeleteModal
      );
      yield call(DataMappingServices.deleteRelatedControlMeasures, data);
      yield put(deleteDataMappingRelatedControlMeasuresSuccess());
      Notification("success", "ลบมาตรการควบคุมที่เกี่ยวข้องสำเร็จ");
    } catch (error) {
      yield put(deleteDataMappingRelatedControlMeasuresFailure(error));
      Notification("error", "ลบมาตรการควบคุมที่เกี่ยวข้องไม่สำเร็จ");
    }
  });
}
