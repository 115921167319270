export const GET_CONSENT_MANAGEMENT_DASHBOARD =
  "GET_CONSENT_MANAGEMENT_DASHBOARD";
export const GET_CONSENT_MANAGEMENT_DASHBOARD_SUCCESS =
  "GET_CONSENT_MANAGEMENT_DASHBOARD_SUCCESS";
export const CHANGE_CONSENT_MANAGEMENT_DASHBOARD_FILTER =
  "CHANGE_CONSENT_MANAGEMENT_DASHBOARD_FILTER";
export const GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT =
  "GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT";
export const GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT_SUCCESS =
  "GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT_SUCCESS";
export const GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT_FAILURE =
  "GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT_FAILURE";

export const GET_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE =
  "GET_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE";
export const GET_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE_SUCCESS =
  "GET_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE_SUCCESS";
export const GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE =
  "GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE";
export const GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE_SUCCESS =
  "GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE_SUCCESS";
