export const selectDataElement = (state) =>
  state.privacyKitConsentManagement.dataElement;

export const selectListDataElement = (state) =>
  state.privacyKitConsentManagement.dataElement.listDataElement;

export const selectListDataElementType = (state) =>
  state.privacyKitConsentManagement.dataElement.listDateElementType;

export const selectManageDataElement = (state) =>
  state.privacyKitConsentManagement.dataElement.manageDataElement;

export const selectDeleteDataElement = (state) =>
  state.privacyKitConsentManagement.dataElement.deleteDataElement;
