import {
  LIST_CONSENT_MANAGEMENT_DATE_ELEMENT,
  LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS,
  LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_TYPE_SUCCESS,
  GET_CONSENT_MANAGEMENT_DATE_ELEMENT,
  GET_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS,
  SET_MANAGE_CONSENT_MANAGEMENT_DATE_ELEMENT,
  CREATE_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS,
  UPDATE_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS,
  CREATE_OR_UPDATE_CONSENT_MANAGEMENT_DATE_ELEMENT_ERROR,
  TOGGLE_MANAGE_CONSENT_MANAGEMENT_DATA_ELEMENT,
  TOGGLE_DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT,
  DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT,
  DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT_SUCCESS,
  DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT_ERROR,
  SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT,
  SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT_SUCCESS,
} from "../../constants/PrivacyKitConsentManagement";
import { replaceObject } from "util/arrayUtils";
import _ from "lodash";

const initialState = {
  loading: false,
  listSearchDataElement: null,
  listDataElement: null,
  listDateElementType: null,
  manageDataElement: {
    visible: false,
    loading: false,
    dataElementId: null,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
  deleteDataElement: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
};

const DataElementReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CONSENT_MANAGEMENT_DATE_ELEMENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        listDataElement: action.data,
      };
    }
    case LIST_CONSENT_MANAGEMENT_DATE_ELEMENT_TYPE_SUCCESS: {
      return {
        ...state,
        listDateElementType: action.data,
      };
    }
    case GET_CONSENT_MANAGEMENT_DATE_ELEMENT: {
      return {
        ...state,
        manageDataElement: {
          ...state.manageDataElement,
          loading: true,
        },
      };
    }
    case GET_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS: {
      return {
        ...state,
        manageDataElement: {
          ...state.manageDataElement,
          loading: false,
          data: action.data,
        },
      };
    }
    case SET_MANAGE_CONSENT_MANAGEMENT_DATE_ELEMENT: {
      return {
        ...state,
        manageDataElement: {
          ...state.manageDataElement,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case CREATE_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS: {
      return {
        ...state,
        manageDataElement: {
          ...initialState.manageDataElement,
        },
        listDataElement: [action.data, ...state.listDataElement],
      };
    }
    case UPDATE_CONSENT_MANAGEMENT_DATE_ELEMENT_SUCCESS: {
      return {
        ...state,
        manageDataElement: {
          ...initialState.manageDataElement,
        },
        listDataElement: [
          ...replaceObject(
            state.listDataElement,
            action.dataElementId,
            action.data
          ),
        ],
      };
    }
    case CREATE_OR_UPDATE_CONSENT_MANAGEMENT_DATE_ELEMENT_ERROR: {
      return {
        ...state,
        manageDataElement: {
          ...state.manageDataElement,
          okButtonProps: {
            loading: false,
          },
        },
      };
    }
    case TOGGLE_MANAGE_CONSENT_MANAGEMENT_DATA_ELEMENT: {
      return {
        ...state,
        manageDataElement: {
          ...state.manageDataElement,
          loading: false,
          data: null,
          visible: !state.manageDataElement.visible,
          dataElementId: action.dataElementId ?? null,
        },
      };
    }
    case TOGGLE_DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT: {
      return {
        ...state,
        deleteDataElement: {
          ...initialState.deleteDataElement,
          visible: !state.deleteDataElement.visible,
          data: action.payload ?? null,
        },
      };
    }
    case DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT: {
      return {
        ...state,
        deleteDataElement: {
          ...state.deleteDataElement,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT_SUCCESS: {
      return {
        ...state,
        deleteDataElement: initialState.deleteDataElement,
        listDataElement: _.filter(
          state.listDataElement,
          ({ ObjectUUID }) => ObjectUUID !== action.dataElementId
        ),
      };
    }
    case DELETE_CONSENT_MANAGEMENT_DATA_ELEMENT_ERROR: {
      return {
        ...state,
        deleteDataElement: {
          ...state.deleteDataElement,
          okButtonProps: {
            loading: false,
          },
        },
      };
    }
    case SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT: {
      return {
        ...state,
        loading: true,
        search: action.search,
      };
    }
    case SEARCH_CONSENT_MANAGEMENT_DATA_ELEMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        listSearchDataElement: action.payload?.listDataElemen ?? null,
        // nextPageToken: action.payload?.nextPageToken ?? null,
      };
    }

    default: {
      return state;
    }
  }
};

export default DataElementReducer;
