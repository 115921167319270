import {
  LIST_COMPLIANT_ASSESSMENT_ERROR,
  LIST_COMPLIANT_ASSESSMENT_SUCCESS,
  GET_COMPLIANT_ASSESSMENT_SUCCESS,
  GET_COMPLIANT_ASSESSMENT_ERROR,
  LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE_SUCCESS,
  LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE_FAILED,
  GET_COMPLIANT_ASSESSMENT_TEMPLATE_DATA_SUCCESS,
  GET_COMPLIANT_ASSESSMENT_TEMPLATE_DATA_FAILED,
  LIST_COMPLIANCE_PLAN_SUCCESS,
  LIST_COMPLIANCE_PLAN_FAILED,
  CREATE_COMPLIANCE_PLAN_SUCCESS,
  CREATE_COMPLIANCE_PLAN_FAILED,
  DELETE_COMPLIANCE_PLAN_FAILED,
  DELETE_COMPLIANCE_PLAN_SUCCESS,
  GET_DETAIL_COMPLIANCE_PLAN_FAILED,
  GET_DETAIL_COMPLIANCE_PLAN_SUCCESS,
  UPDATE_COMPLIANCE_PLAN_SUCCESS,
  UPDATE_COMPLIANCE_PLAN_FAILED,
} from "../constants/PrivacyKitCompliantManagement";

export const listCompliantAssessmentSuccess = (payload) => {
  return {
    type: LIST_COMPLIANT_ASSESSMENT_SUCCESS,
    payload,
  };
};

export const listCompliantAssessmentError = (error) => {
  return {
    type: LIST_COMPLIANT_ASSESSMENT_ERROR,
    error,
  };
};

export const getCompliantAssessmentSuccess = (payload) => {
  return {
    type: GET_COMPLIANT_ASSESSMENT_SUCCESS,
    payload,
  };
};

export const getCompliantAssessmentError = (payload) => {
  return {
    type: GET_COMPLIANT_ASSESSMENT_ERROR,
    payload,
  };
};

export const listCompliantAssessmentTemplateTypeSuccess = (payload) => {
  return {
    type: LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE_SUCCESS,
    payload,
  };
};

export const listCompliantAssessmentTemplateTypeFailed = (error) => {
  return {
    type: LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE_FAILED,
    error,
  };
};

export const getCompliantAssessmentTemplateDataSuccess = (payload) => {
  return {
    type: GET_COMPLIANT_ASSESSMENT_TEMPLATE_DATA_SUCCESS,
    payload,
  };
};

export const getCompliantAssessmentTemplateDataFailed = (error) => {
  return {
    type: GET_COMPLIANT_ASSESSMENT_TEMPLATE_DATA_FAILED,
    error,
  };
};

export const listCompliantPlanSuccess = (payload) => {
  return {
    type: LIST_COMPLIANCE_PLAN_SUCCESS,
    payload,
  };
};

export const listCompliantPlanFailed = (error) => {
  return {
    type: LIST_COMPLIANCE_PLAN_FAILED,
    error,
  };
};

export const createCompliantPlanSuccess = (payload) => {
  return {
    type: CREATE_COMPLIANCE_PLAN_SUCCESS,
    payload,
  };
};

export const createCompliantPlanFailed = (error) => {
  return {
    type: CREATE_COMPLIANCE_PLAN_FAILED,
    error,
  };
};

export const deleteCompliantPlanSuccess = (payload) => {
  return {
    type: DELETE_COMPLIANCE_PLAN_SUCCESS,
    payload,
  };
};

export const deleteCompliantPlanFailed = (error) => {
  return {
    type: DELETE_COMPLIANCE_PLAN_FAILED,
    error,
  };
};

export const getDetailCompliantPlanSuccess = (payload) => {
  return {
    type: GET_DETAIL_COMPLIANCE_PLAN_SUCCESS,
    payload,
  };
};

export const getDetailCompliantPlanFailed = (error) => {
  return {
    type: GET_DETAIL_COMPLIANCE_PLAN_FAILED,
    error,
  };
};

export const updateCompliantPlanSuccess = (payload) => {
  return {
    type: UPDATE_COMPLIANCE_PLAN_SUCCESS,
    payload,
  };
};

export const updateCompliantPlanFailed = (error) => {
  return {
    type: UPDATE_COMPLIANCE_PLAN_FAILED,
    error,
  };
};
