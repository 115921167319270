import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import NavigationConfig from "config/NavigationConfig";
import NavigationSelectedKey from "util/NavigationSelectedKey";
import PropsTypes from "prop-types";

const HorizontalNav = ({ mode }) => {
  const pathname = useSelector(({ common }) => common.pathname);
  const { domain } = useSelector(
    ({ privacykitCookieConsentManagement }) => privacykitCookieConsentManagement
  );

  const Navigation = NavigationConfig(pathname, domain?.body || []);

  return (
    <Menu
      defaultOpenKeys={[pathname]}
      selectedKeys={NavigationSelectedKey(pathname)}
      mode={mode}
      theme={mode === "vertical" ? "dark" : "light"}
    >
      {Navigation.map((navigation) => {
        if (navigation.submenu.length > 0) {
          return (
            <Menu.SubMenu key={navigation.path} title={navigation.title}>
              <>
                {navigation.submenu.map((menu) => (
                  <Menu.Item key={menu.path} disabled={menu.disabled}>
                    <Link
                      to={`${menu.path}${menu.urlParam ? menu.urlParam : ""}`}
                    >
                      {menu.title}
                    </Link>
                  </Menu.Item>
                ))}
              </>
            </Menu.SubMenu>
          );
        } else {
          return (
            <Menu.Item key={navigation.path} disabled={navigation.disabled}>
              <Link
                to={`${navigation.path}${
                  navigation.urlParam ? navigation.urlParam : ""
                }`}
              >
                {navigation.title}
              </Link>
            </Menu.Item>
          );
        }
      })}
    </Menu>
  );
};

HorizontalNav.propTypes = {
  mode: PropsTypes.string.isRequired,
};

HorizontalNav.defaultProps = {
  mode: "horizontal",
};

export default HorizontalNav;
