// ASSET
export const SET_ASSETS = "SET_ASSETS";
export const GET_TEMPLATE_DEVICE_LIST = "GET_TEMPLATE_DEVICE_LIST";
export const SET_TEMPLATE_DEVICE_LIST = "SET_TEMPLATE_DEVICE_LIST";
export const CREATE_ASSET = "CREATE_ASSET";
export const UPDATE_ASSET = "UPDATE_ASSET";
export const DELETE_ASSET = "DELETE_ASSET";

export const GET_OPTION = "GET_OPTION";
export const SET_OPTION = "SET_OPTION";
export const CREATE_OPTION = "CREATE_OPTION";
export const UPDATE_OPTION = "UPDATE_OPTION";
export const DELETE_OPTION = "DELETE_OPTION";

// ASSESSMENT
export const GET_ASSESSMENT = "GET_ASSESSMENT";
export const SET_ASSESSMENT = "SET_ASSESSMENT";
export const CREATE_ASSESSMENT = "CREATE_ASSESSMENT";
export const GET_ASSESSMENT_BY_TYPE = "GET_ASSESSMENT_BY_TYPE";
