import { takeEvery, put, call } from "redux-saga/effects";
import { GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD } from "../../constants/PrivacyKitPrivacyAssessmentAutomation";
import {
  getPrivacyAssessmentAutomationDashboardSuccess,
  getPrivacyAssessmentAutomationDashboardFailure,
} from "../../actions/PrivacyKitPrivacyAssessmentAutomation";
import PrivacyAssessmentAutomationService from "services/pdpakit/PrivacyAssessmentAutomationService";

const riskChart = [
  {
    key: "high",
    label: "ความเสี่ยงสูง",
    color: "#FF0000",
  },
  {
    key: "medium",
    label: "ความเสี่ยงปานกลาง",
    color: "#FD7E14",
  },
  {
    key: "low",
    label: "ความเสี่ยงต่ำ",
    color: "#FDDA00",
  },
  {
    key: "none",
    label: "ยังไม่ประเมิน",
    color: "#52C41A",
  },
];

export function* getPrivacyAssessmentAutomationDashboard() {
  yield takeEvery(GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD, function* () {
    try {
      const response = yield call(
        PrivacyAssessmentAutomationService.getDashboard
      );

      const afterRisk = riskChart.map(({ key, ...rest }) => ({
        ...rest,
        key,
        value: response.body.afterRisk[key],
      }));

      const beforeRisk = riskChart.map(({ key, ...rest }) => ({
        ...rest,
        key,
        value: response.body.beforeRisk[key],
      }));
      const dataset_entry_risk = response.body.dataset_entry_risk.map(
        (value) => {
          return {
            name: value.inventory_asset.name,
            ObjectUUID: value.inventory_asset.ObjectUUID,
            count_processing: value.inventory_asset.count_processing,
            informant: value.inventory_asset.informant,
            risk: value.risk,
          };
        }
      );
      yield put(
        getPrivacyAssessmentAutomationDashboardSuccess({
          afterRisk,
          beforeRisk,
          dataset_entry_risk,
          inventoryAsset: response.body.inventory_asset,
        })
      );
    } catch (error) {
      yield put(getPrivacyAssessmentAutomationDashboardFailure(error));
    }
  });
}
