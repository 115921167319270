import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { PrivacyKitPath, CyberKitPath } from "config/AppModuleConfig";

const BrandLogo = () => {
  const { location } = useSelector(({ router }) => router);

  const LogoTarget = () => {
    if ([PrivacyKitPath, CyberKitPath].includes(location.pathname)) {
      return "/";
    }
    if (location.pathname.includes(PrivacyKitPath)) {
      return PrivacyKitPath;
    }
    if (location.pathname.includes(CyberKitPath)) {
      return CyberKitPath;
    }
    return "/";
  };

  return (
    <>
      <Link
        to={LogoTarget()}
        className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
      >
        <img alt="" src={`/assets/images/logo.png`} />
      </Link>
      <Link
        to={LogoTarget()}
        className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
      >
        <img
          alt=""
          style={{ maxHeight: 80 }}
          src={`/assets/images/logo.png`}
        />
      </Link>
    </>
  );
};

export default BrandLogo;
