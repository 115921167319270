import { changeFormatDatetime } from "util/DateUtils";
import parser from "cron-parser";
import moment from "moment";

const COLOUR_TAG = {
  Running: { color: "#FFC542", label: "กำลังสแกน" },
  Done: { color: "#04D182", label: "เสร็จสิ้น" },
};

export default class ScannerModel {
  constructor(data) {
    this._id = data._id ? data._id : "";
    this.organization = data.organization ? data.organization : "";
    this.name = data.name ? data.name : "";
    this.description = data.description ? data.description : "";
    this.identify = data.identify ? data.identify : "";
    this.limit = data?.limit;
    this.template = data.template ? data.template : "";
    this.action = data.action ? data.action : "";
    this.schedule = data.schedule ? data.schedule : "";
    this.target = data.target ? data.target : "";
    this.state = data.state ? data.state : "";
    this.state_last_time = data.state_last_time ? data.state_last_time : "";
    this.status = data.status ? data.status : "";
    this.create_by = data.create_by ? data.create_by : "";
    this.create_at = data.create_at ? data.create_at : "";
    this.update_by = data.update_by ? data.update_by : "";
    this.update_at = data.update_at ? data.update_at : "";
  }

  displayID = () => this._id;
  displayName = () => this.name;
  displayLimit = () => this.limit || 0;
  displayTypeSchedule = () => {
    if (this.schedule?.type === "OnDemand") return "สแกนครั้งเดียว";
    else return "กำหนดเวลาการสแกน";
  };
  displayNextTime = () => {
    if (
      this.schedule.type === "OnDemand" ||
      this.schedule.cronjob === "oneTime"
    )
      return changeFormatDatetime(this.state_last_time, "DD/MM/YYYY h:mm A");
    else {
      let interval = parser.parseExpression(this?.schedule.cronjob);
      return changeFormatDatetime(
        interval.next().toISOString(),
        "DD/MM/YYYY h:mm A"
      );
    }
  };
  displayUpdatedAt = () =>
    changeFormatDatetime(this.update_at, "DD/MM/YYYY h:mm A");
  displayStatusColor = () => COLOUR_TAG[this.state]?.color || "#04D182";
  displayStatusLabel = () => COLOUR_TAG[this.state]?.label || "ข้อผิดพลาด";
}
