import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SIGNIN, CHANGE_AUTH_PASSWORD } from "../constants/Auth";
import {
  signinSuccess,
  signinFailed,
  onShowLoading,
  changePasswordSuccess,
} from "../actions/Auth";
import AuthServices from "services/AuthServices";
import Notification from "util/Notification";
import dayjs from "dayjs";

function* signinWithEmailPassword() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    try {
      yield put(onShowLoading());
      const response = yield call(AuthServices.signin, payload);

      localStorage.setItem(
        "auth",
        JSON.stringify({ ...response, timestamp: dayjs().format() })
      );
      yield put(signinSuccess(response));
    } catch (error) {
      Notification("error", "กรุณากรอกอีเมลหรือรหัสผ่านให้ถูกต้อง");
      yield put(signinFailed(error));
    }
  });
}

function* changePassword() {
  yield takeEvery(CHANGE_AUTH_PASSWORD, function* ({ payload }) {
    try {
      const response = yield call(AuthServices.changePassword, payload);
      yield put(changePasswordSuccess(response));
      Notification("success", "เปลี่ยนรหัสผ่านสำเร็จ");
    } catch (error) {
      Notification("error", "กรุณากรอกรหัสผ่านให้ถูกต้อง");
      yield put(changePasswordSuccess());
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signinWithEmailPassword), fork(changePassword)]);
}
