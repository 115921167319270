import { takeEvery, call, put, select, takeLatest } from "redux-saga/effects";
import {
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK,
  MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK,
  SEARCH_DATA_REQUEST,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";
import {
  listDataSubjectRightManagementRequestSuccess,
  listDataSubjectRightManagementRequestNextPageSuccess,
  listDataSubjectRightManagementRequestNextPageFailure,
  getDataSubjectRightManagementRequestSuccess,
  updateDataSubjectRightManagementRequestSuccess,
  updateDataSubjectRightManagementRequestFailed,
  toggleDataSubjectRightManagementRequestNextButton,
  toggleDataSubjectRightManagementRequestBackButton,
  listDataSubjectRightManagementRequestTaskSuccess,
  createDataSubjectRightManagementRequestTaskSuccess,
  updateDataSubjectRightManagementRequestTaskSuccess,
  manageDataSubjectRightManagementRequestTaskError,
  searchDataRequestSuccess,
} from "../../actions/PrivacyKitDataSubjectRightManagement";
import DataSubjectRightManagementService from "services/pdpakit/DataSubjectRightManagementService";
import {
  selectDataSubjectRightManagementRequest,
  selectDataSubjectRightManagementRequestTaskModal,
} from "../../selectors/PrivacyKitDataSubjectRightManagement";
import Notification from "util/Notification";
import { selectListTenantUsers } from "../../selectors/UserManagement";
import _ from "lodash";

export function* listDataSubjectRightManagementRequest() {
  yield takeEvery(LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST, function* () {
    try {
      const { body } = yield call(
        DataSubjectRightManagementService.listRequest
      );
      const { items, next_page_token } = body;
      yield put(
        listDataSubjectRightManagementRequestSuccess({
          listRequest: items,
          nextPageToken: next_page_token,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });
}

export function* searchDataRequest() {
  yield takeLatest(SEARCH_DATA_REQUEST, function* ({ search }) {
    try {
      const { data, next_page_token } = yield call(
        DataSubjectRightManagementService.searchRequest,
        search
      );

      yield put(
        searchDataRequestSuccess({
          listRequest: data,
          nextPageToken: next_page_token,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });
}

export function* listDataSubjectRightManagementRequestNextPage() {
  yield takeEvery(
    LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE,
    function* ({ nextPageToken }) {
      try {
        const response = yield call(
          DataSubjectRightManagementService.listRequestNextPage,
          nextPageToken
        );
        yield put(
          listDataSubjectRightManagementRequestNextPageSuccess({
            items: response.items,
            nextPageToken: response.next_page_token,
          })
        );
      } catch (error) {
        yield put(listDataSubjectRightManagementRequestNextPageFailure());
      }
    }
  );
}

export function* getDataSubjectRightManagementRequest() {
  yield takeEvery(
    GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST,
    function* ({ requestId, userKey }) {
      try {
        const { body } = yield call(
          DataSubjectRightManagementService.getRequest,
          requestId,
          userKey
        );
        const { stages, stage_order } = body;
        const stage = {
          ...stages[stage_order - 1],
          stage_order: stage_order - 1,
        };

        yield put(
          getDataSubjectRightManagementRequestSuccess(stage, {
            requestId,
            userKey,
            requestData: body,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );
}

export function* updateDataSubjectRightManagementRequestStage() {
  yield takeEvery(
    UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE,
    function* ({ stageOrder }) {
      try {
        const { requestId, userKey, requestData, stage } = yield select(
          selectDataSubjectRightManagementRequest
        );
        if (stageOrder > stage.stage_order) {
          yield put(toggleDataSubjectRightManagementRequestNextButton());
        } else {
          yield put(toggleDataSubjectRightManagementRequestBackButton());
        }
        const data = {
          ...requestData,
          stage: requestData.stages[stageOrder].title,
          stage_order: stageOrder + 1,
        };
        const { body } = yield call(
          DataSubjectRightManagementService.updateRequest,
          requestId,
          userKey,
          data
        );

        const { stages, stage_order } = body;
        const newStage = {
          ...stages[stage_order - 1],
          stage_order: stage_order - 1,
        };

        yield put(
          updateDataSubjectRightManagementRequestSuccess({
            stage: newStage,
            requestData: body,
          })
        );
        Notification("success", "อัพเดตสถานะคำขอเรียบร้อยแล้ว");
      } catch (error) {
        Notification("error", "ไม่สามารถไปขั้นตอนต่อไป ก่อนที่งานจะสำเร็จ");
        yield put(updateDataSubjectRightManagementRequestFailed());
      }
    }
  );
}

export function* listDataSubjectRightManagementRequestTask() {
  yield takeEvery(
    LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK,
    function* ({ requestId, userKey }) {
      try {
        const { body } = yield call(
          DataSubjectRightManagementService.listRequestTask,
          requestId,
          userKey
        );
        const { items, next_page_token } = body;
        yield put(
          listDataSubjectRightManagementRequestTaskSuccess({
            listTask: items,
            nextPageToken: next_page_token,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );
}

const initialTask = {
  status: "reopen",
  resolution: null,
  comments: [],
  object_class: "request/subtask",
  detail: "",
  subtask_required: true,
  comment_required: false,
  resolution_required: false,
};

export function* manageDataSubjectRightManagementRequestTask() {
  yield takeEvery(
    MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK,
    function* ({ payload }) {
      try {
        const listUser = yield select(selectListTenantUsers);
        const { stage, requestId, userKey, requestData } = yield select(
          selectDataSubjectRightManagementRequest
        );
        const taskModal = yield select(
          selectDataSubjectRightManagementRequestTaskModal
        );

        const payloadData = {
          ...initialTask,
          ...taskModal.data,
          ...payload,
          stage_order: `${stage.stage_order}`,
          stage_title: stage.title,
          request_stage: stage.title,
          stage_id: stage.stage_id,
          request_id: requestId,
          created_by: requestData.created_by,
          approver: _.find(listUser, { ukey: payload.assignee }),
        };

        if (!taskModal.data) {
          const response = yield call(
            DataSubjectRightManagementService.createRequestTask,
            requestId,
            userKey,
            payloadData
          );
          yield put(
            createDataSubjectRightManagementRequestTaskSuccess(response.body)
          );
          Notification("success", "สร้างงานเรียบร้อย");
        }

        if (taskModal.data) {
          const response = yield call(
            DataSubjectRightManagementService.updateTask,
            taskModal.data.ObjectUUID,
            requestId,
            userKey,
            payloadData.status,
            payloadData
          );
          yield put(
            updateDataSubjectRightManagementRequestTaskSuccess(response.body)
          );
          Notification("success", "แก้ไขงานเรียบร้อย");
        }
      } catch (error) {
        console.log(error);
        yield put(manageDataSubjectRightManagementRequestTaskError());
        if (error.statusCode === 400) {
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถบันทึกได้เนื่องจากงานนี้ไม่ได้อยู่ในความรับผิดชอบของท่าน"
          );
        } else {
          Notification("error", "เกิดข้อผิดพลาด ไม่สามารถแก้ไขงาน");
        }
      }
    }
  );
}
