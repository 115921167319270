import fetch from "util/Api";
import {
  PRIVACYKIT_DATA_MAPPING_BASE_URL as data_mapping_endpoint,
  PRIVACYKIT_ASSESSMENT_AUTOMATION_BASE_URL as assessment_endpoint,
} from "config/EndpointConfig";

const ITAssetInventoryServices = {};

// Asset
ITAssetInventoryServices.getAssets = async (payload) =>
  await fetch({
    method: "get",
    url: `${data_mapping_endpoint}/inventory${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

ITAssetInventoryServices.getInventoryManagement = async (payload) =>
  await fetch({
    method: "get",
    url: `${data_mapping_endpoint}/inventory/management/${payload.path.attrbute_type}`,
  })
    .then((response) => response)
    .catch((error) => error);

ITAssetInventoryServices.createInventoryManagement = async (payload) =>
  await fetch({
    method: "post",
    url: `${data_mapping_endpoint}/inventory/management`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

ITAssetInventoryServices.updateInventoryManagement = async (payload) =>
  await fetch({
    method: "put",
    url: `${data_mapping_endpoint}/inventory/management/${payload.path.attrbute_type}/${payload.path.attrbute_id}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

ITAssetInventoryServices.deleteInventoryManagement = async (payload) =>
  await fetch({
    method: "delete",
    url: `${data_mapping_endpoint}/inventory/management/${payload.path.attrbute_type}/${payload.path.attrbute_id}`,
  })
    .then((response) => response)
    .catch((error) => error);

ITAssetInventoryServices.getAssesByInventoryType = async (payload) =>
  await fetch({
    method: "get",
    url: `${data_mapping_endpoint}/inventory${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

ITAssetInventoryServices.getAssetDetailByID = async (payload) =>
  await fetch({
    method: "get",
    url: `${data_mapping_endpoint}/inventory/${payload.path.inventory_type}/${payload.path.object_id}`,
  })
    .then((response) => response)
    .catch((error) => error);

ITAssetInventoryServices.createNewAsset = async (payload) =>
  await fetch({
    method: "post",
    url: `${data_mapping_endpoint}/inventory`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

ITAssetInventoryServices.updateAsset = async (payload) =>
  await fetch({
    method: "put",
    url: `${data_mapping_endpoint}/inventory/${payload.path.inventoryType}/${payload.path.objectID}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

ITAssetInventoryServices.deleteAsset = async (payload) =>
  await fetch({
    method: "delete",
    url: `${data_mapping_endpoint}/inventory/${payload.path.inventoryType}/${payload.path.objectID}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

ITAssetInventoryServices.getListTemplateDevice = async (payload) =>
  await fetch({
    method: "get",
    url: `${data_mapping_endpoint}/inventory/lists/type`,
  })
    .then((response) => response)
    .catch((error) => error);

ITAssetInventoryServices.getListAssessment = async (payload) =>
  await fetch({
    method: "get",
    url: `${assessment_endpoint}/assessment/type/${payload.path.assessmentType}`,
  })
    .then((response) => response)
    .catch((error) => error);

ITAssetInventoryServices.createAssessment = async (payload) =>
  await fetch({
    method: "post",
    url: `${assessment_endpoint}/assessment`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

export default ITAssetInventoryServices;
