export const LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN =
  "LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN";
export const LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS =
  "LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS";
export const LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED =
  "LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED";
export const CHANGE_COOKIE_CONSENT_MANAGEMENT_DOMAIN =
  "CHANGE_COOKIE_CONSENT_MANAGEMENT_DOMAIN";
export const GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD =
  "GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD";
export const GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_SUCCESS =
  "GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_SUCCESS";
export const GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_FAILED =
  "GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_FAILED";
export const CHANGE_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_FILTER =
  "CHANGE_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_FILTER";
export const GET_COOKIE_CONSENT_MANAGEMENT_DETAIL =
  "GET_COOKIE_CONSENT_MANAGEMENT_DETAIL";
export const GET_COOKIE_CONSENT_MANAGEMENT_DETAIL_SUCCESS =
  "GET_COOKIE_CONSENT_MANAGEMENT_DETAIL_SUCCESS";
export const GET_COOKIE_CONSENT_MANAGEMENT_DETAIL_FAILED =
  "GET_COOKIE_CONSENT_MANAGEMENT_DETAIL_FAILED";

export const CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN =
  "CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN";
export const CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS =
  "CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS";
export const CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED =
  "CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED";

export const UPDATE_COOKIE_CONSENT_MANAGEMENT_COOKIE_DATA =
  "UPDATE_COOKIE_CONSENT_MANAGEMENT_COOKIE_DATA";
export const UPDATE_COOKIE_CONSENT_MANAGEMENT_COOKIE_DATA_SUCCESS =
  "UPDATE_COOKIE_CONSENT_MANAGEMENT_COOKIE_DATA_SUCCESS";
export const UPDATE_COOKIE_CONSENT_MANAGEMENT_COOKIE_DATA_FAILED =
  "UPDATE_COOKIE_CONSENT_MANAGEMENT_COOKIE_DATA_FAILED";

export const UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN =
  "UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN";
export const UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS =
  "UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS";
export const UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED =
  "UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED";

export const DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN =
  "DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN";
export const DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS =
  "DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS";
export const DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED =
  "DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_FAILED";

export const SCAN_COOKIES_CONSENT_MANAGEMENT_DOMAIN =
  "SCAN_COOKIES_CONSENT_MANAGEMENT_DOMAIN";
export const SCAN_COOKIES_CONSENT_MANAGEMENT_DOMAIN_SUCCESS =
  "SCAN_COOKIES_CONSENT_MANAGEMENT_DOMAIN_SUCCESS";

export const UPDATE_COOKIES_CONSENT_MANAGEMENT_CATEGORY =
  "UPDATE_COOKIES_CONSENT_MANAGEMENT_CATEGORY";
export const UPDATE_COOKIES_CONSENT_MANAGEMENT_CATEGORY_SUCCESS =
  "UPDATE_COOKIES_CONSENT_MANAGEMENT_CATEGORY_SUCCESS";
