import {
  SIGNIN_SUCCESS,
  SIGNIN_FAILED,
  AUTH_SHOW_LOADING,
  AUTH_HIDE_LOADING,
  CHANGE_AUTH_PASSWORD,
  CHANGE_AUTH_PASSWORD_SUCCESS,
} from "../constants/Auth";

export const signinSuccess = (payload) => {
  return {
    type: SIGNIN_SUCCESS,
    payload,
  };
};

export const signinFailed = (payload) => {
  return {
    type: SIGNIN_FAILED,
    payload,
  };
};

export const onShowLoading = () => ({ type: AUTH_SHOW_LOADING });
export const onHideLoading = () => ({ type: AUTH_HIDE_LOADING });

export const changePassword = (payload) => {
  return {
    type: CHANGE_AUTH_PASSWORD,
    payload,
  };
};

export const changePasswordSuccess = () => {
  return {
    type: CHANGE_AUTH_PASSWORD_SUCCESS,
  };
};
