import {
  PrivacyKitPath,
  PrivacyKitPolicyAndNoticeManagementPath,
} from "../AppModuleConfig";

const PrivacyKitPolicyAndNoticeManagement = [
  {
    key: `policy-and-notice-management-policy`,
    path: `${PrivacyKitPath}${PrivacyKitPolicyAndNoticeManagementPath}/policy`,
    title: "สร้างนโยบาย",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `policy-and-notice-management-dashboard`,
    path: `${PrivacyKitPath}${PrivacyKitPolicyAndNoticeManagementPath}/dashboard`,
    title: "แดชบอร์ด",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  // {
  //   key: `policy-and-notice-management-task`,
  //   path: `${PrivacyKitPath}${PrivacyKitPolicyAndNoticeManagementPath}/task`,
  //   title: "งาน",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
  // {
  //   key: `policy-and-notice-management-assessment`,
  //   path: `${PrivacyKitPath}${PrivacyKitPolicyAndNoticeManagementPath}/assessment`,
  //   title: "การประเมิน",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
];

export default PrivacyKitPolicyAndNoticeManagement;
