import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  LIST_CONSENT_MANAGEMENT_COLLECTION_POINT,
  GET_CONSENT_MANAGEMENT_COLLECTION_POINT,
  CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT,
  UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT,
  PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT,
  CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS,
  LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION,
  GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION,
} from "../../constants/PrivacyKitConsentManagement";
import UniversalConsentAndPreferenceService from "services/pdpakit/UniversalConsentAndPreferenceService";
import {
  listConsentManagementCollectionPointSuccess,
  getConsentManagementCollectionPointSuccess,
  createConsentManagementCollectionPointSuccess,
  createConsentManagementCollectionPointError,
  updateConsentManagementCollectionPointSuccess,
  publishConsentManagementCollectionPointSuccess,
  publishConsentManagementCollectionPointError,
  changeConsentManagementCollectionPointStatusSuccess,
  changeConsentManagementCollectionPointStatusError,
  listConsentManagementCollectionPointVersionSuccess,
  listConsentManagementCollectionPointVersionError,
  getConsentManagementCollectionPointVersionSuccess,
  getConsentManagementCollectionPointVersionError,
} from "../../actions/PrivacyKitConsentManagement";
import {
  selectCollectionPoint,
  selectCollectionPointStatus,
} from "../../selectors/PrivacyKitConsentManagement";
import Notification from "util/Notification";
import { v4 as uuid } from "uuid";

const initialCollectionPointSectionId = uuid();
const initialCollectionPoint = {
  form_text: {
    th: [
      {
        name: "Welcome Text",
        text: {
          __html:
            "<p>ยินดีต้อนรับ! กรุณากรอกแบบฟอร์มนี้เพื่อส่งคำขอของคุณแล้วเราจะตอบกลับอย่างเร็วที่สุด</p>",
          position: "center",
          widget: "edittext",
          label: "Welcome Text",
        },
      },
      {
        name: "Footer Text",
        text: {
          __html: "<p></p>",
          position: "center",
          widget: "edittext",
          label: "Footer Text",
        },
      },
    ],
  },
  form_fields: {
    form: [
      {
        name: "page",
        section: [
          {
            component: [],
            ObjectUUID: initialCollectionPointSectionId,
            section_name: initialCollectionPointSectionId,
            id: initialCollectionPointSectionId,
          },
        ],
      },
    ],
  },
  organizations: {},
  default: {},
  collection_points_type: "hostwebform",
  rules: [],
  responsible_user: [],
  setting: {},
  default_language: "th",
  form_style: {},
};

export function* listConsentManagementCollectionPoint() {
  yield takeEvery(LIST_CONSENT_MANAGEMENT_COLLECTION_POINT, function* () {
    try {
      const { Items } = yield call(
        UniversalConsentAndPreferenceService.listCollectionPoint
      );
      yield put(listConsentManagementCollectionPointSuccess(Items));
    } catch (error) {
      console.log(error);
    }
  });
}

export function* getConsentManagementCollectionPoint() {
  yield takeEvery(
    GET_CONSENT_MANAGEMENT_COLLECTION_POINT,
    function* ({ collectionPointId }) {
      try {
        let { Item } = yield call(
          UniversalConsentAndPreferenceService.getCollectionPoint,
          collectionPointId
        );
        if (!Item.form_text.th) {
          Item.form_text.th = initialCollectionPoint.form_text.th;
        }
        yield put(getConsentManagementCollectionPointSuccess(Item));
      } catch (error) {
        console.log(error);
      }
    }
  );
}

export function* createConsentManagementCollectionPoint() {
  yield takeEvery(
    CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT,
    function* ({ values }) {
      try {
        const response = yield call(
          UniversalConsentAndPreferenceService.createCollectionPoint,
          { ...initialCollectionPoint, ...values }
        );
        yield put(createConsentManagementCollectionPointSuccess(response));
        Notification("success", "สร้างฟอร์มขอความยินยอมเรียบร้อยแล้ว");
      } catch (error) {
        console.error(error);
        yield put(createConsentManagementCollectionPointError());
        Notification(
          "error",
          "เกิดข้อผิดพลาด ไม่สามารถสร้างฟอร์มขอความยินยอมได้ กรุณาลองใหม่อีกครั้งในภายหลัง"
        );
      }
    }
  );
}

export function* updateConsentManagementCollectionPoint() {
  yield takeEvery(
    UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT,
    function* ({ values }) {
      try {
        const { collectionPointId, collectionPointData } = yield select(
          selectCollectionPoint
        );

        const data = {
          ...collectionPointData,
          ...values,
          ObjectType: undefined,
        };

        const Item = yield call(
          UniversalConsentAndPreferenceService.updateCollectionPoint,
          collectionPointId,
          data
        );
        yield put(updateConsentManagementCollectionPointSuccess(Item));
        Notification("success", "บันทึกฟอร์มขอความยินยอมเรียบร้อยแล้ว");
      } catch (error) {
        console.log(error);
        Notification(
          "error",
          "เกิดข้อผิดพลาด ไม่สามารถบันทึกฟอร์มขอความยินยอมได้ กรุณาลองใหม่อีกครั้งในภายหลัง"
        );
      }
    }
  );
}

export function* publishConsentManagementCollectionPoint() {
  yield takeEvery(
    PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT,
    function* ({ values }) {
      try {
        const { collectionPointId, collectionPointData } = yield select(
          selectCollectionPoint
        );

        const data = {
          ...collectionPointData,
          ...values,
          ObjectType: undefined,
        };

        yield call(
          UniversalConsentAndPreferenceService.updateCollectionPoint,
          collectionPointId,
          data
        );
        const response = yield call(
          UniversalConsentAndPreferenceService.publishCollectionPoint,
          collectionPointId
        );
        yield put(publishConsentManagementCollectionPointSuccess(response));
        Notification("success", "เผยแพร่ฟอร์มขอความยินยอมเรียบร้อยแล้ว");
      } catch (error) {
        yield put(publishConsentManagementCollectionPointError());
        if (error?.errorCode === "PublishNotAllowed") {
          Notification("error", "กรุณาใส่อีเมลในฟอร์มก่อนเผยแผร่");
        } else {
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถเผยแพร่ฟอร์มขอความยินยอมได้ กรุณาลองใหม่อีกครั้งในภายหลัง"
          );
        }
      }
    }
  );
}

export function* changeConsentManagementCollectionPointStatus() {
  yield takeEvery(
    CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS,
    function* () {
      try {
        const { data } = yield select(selectCollectionPointStatus);
        const { ObjectUUID, status } = data;
        const newStatus = status === "Disable" ? "Enable" : "Disable";
        yield call(
          UniversalConsentAndPreferenceService.updateCollectionPointStatus,
          ObjectUUID,
          newStatus
        );
        yield put(
          changeConsentManagementCollectionPointStatusSuccess({
            ...data,
            status: newStatus,
          })
        );
      } catch (error) {
        yield put(changeConsentManagementCollectionPointStatusError());
      }
    }
  );
}

export function* listConsentManagementCollectionPointVersion() {
  yield takeEvery(
    LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION,
    function* () {
      try {
        const { collectionPointId } = yield select(selectCollectionPoint);
        const response = yield call(
          UniversalConsentAndPreferenceService.listCollectionPointVersion,
          collectionPointId
        );
        yield put(
          listConsentManagementCollectionPointVersionSuccess(response.Items)
        );
      } catch (error) {
        yield put(listConsentManagementCollectionPointVersionError());
      }
    }
  );
}

export function* getConsentManagementCollectionPointVersion() {
  yield takeEvery(
    GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION,
    function* ({ versionId }) {
      try {
        const { collectionPointId } = yield select(selectCollectionPoint);
        const response = yield call(
          UniversalConsentAndPreferenceService.getCollectionPointVersion,
          collectionPointId,
          versionId
        );
        yield put(getConsentManagementCollectionPointVersionSuccess(response));
      } catch (error) {
        yield put(getConsentManagementCollectionPointVersionError());
      }
    }
  );
}
