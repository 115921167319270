export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_FAILED =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_FAILED";

export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DEFAULT_WORKFLOW =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DEFAULT_WORKFLOW";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_FAILED =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_FAILED";

export const TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_DELETE =
  "TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_DELETE";

export const CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGE_ORDER =
  "CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGE_ORDER";
export const CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGES =
  "CHANGE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGES";

export const TOGGLE_CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW =
  "TOGGLE_CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW";
export const CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW =
  "CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW";
export const CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS =
  "CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS";
export const CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_FAILED =
  "CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_FAILED";

export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGE =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_STAGE";

export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR";

export const TOGGLE_PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW =
  "TOGGLE_PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW";
export const PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW =
  "PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW";
export const PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS =
  "PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS";
export const PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR =
  "PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR";

export const DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW =
  "DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW";
export const DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS =
  "DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_SUCCESS";
export const DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR =
  "DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WORKFLOW_ERROR";

export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED";

export const TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW =
  "TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW";
export const CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW =
  "CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW";
export const CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS =
  "CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS";
export const CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED =
  "CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED";

export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED";

export const TOGGLE_DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW =
  "TOGGLE_DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW";
export const DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW =
  "DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW";
export const DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS =
  "DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_SUCCESS";
export const DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED =
  "DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_IN_WORKFLOW_FAILED";
