import {
  LIST_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
  CREATE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
  DELETE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
  LIST_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS,
  CREATE_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS,
  DELETE_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS,
  LIST_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS,
  CREATE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS,
  DELETE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS,
  UPDATE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
  GET_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
} from "../constants/PrivacyKitIncidentResponseManagement";

export const listIncidentResponseEventStageSuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
    payload,
  };
};

export const createIncidentResponseEventStageSuccess = (payload) => {
  return {
    type: CREATE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
    payload,
  };
};

export const getIncidentResponseEventStageSuccess = (payload) => {
  return {
    type: GET_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
    payload,
  };
};

export const updateIncidentResponseEventStageSuccess = (payload) => {
  return {
    type: UPDATE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
    payload,
  };
};

export const deleteIncidentResponseEventStageSuccess = (payload) => {
  return {
    type: DELETE_INCIDENT_RESPONSE_EVENT_STAGE_SUCCESS,
    payload,
  };
};

export const listIncidentResponseEventTypeSuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS,
    payload,
  };
};

export const createIncidentResponseEventTypeSuccess = (payload) => {
  return {
    type: CREATE_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS,
    payload,
  };
};

export const deleteIncidentResponseEventTypeSuccess = (payload) => {
  return {
    type: DELETE_INCIDENT_RESPONSE_EVENT_TYPE_SUCCESS,
    payload,
  };
};

export const listIncidentResponseAttributeSuccess = (payload) => {
  return {
    type: LIST_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS,
    payload,
  };
};

export const createIncidentResponseAttributeSuccess = (payload) => {
  return {
    type: CREATE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS,
    payload,
  };
};

export const deleteIncidentResponseAttributeSuccess = (payload) => {
  return {
    type: DELETE_INCIDENT_RESPONSE_ATTRIBUTE_MANAGEMENT_SUCCESS,
    payload,
  };
};