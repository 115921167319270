import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_PAGINATE } from "redux/constants/CyberKitTool";

import { setLoading, setTotalPage } from "redux/actions/CyberKitTool";
import {
  setAuditList,
  setSearchResult,
  setExplorers as logManagementSetExplorers,
  setArchives,
} from "redux/actions/CyberKitLogManagement";
import {
  setEventList as siemSetEventList,
  setEventInEvent as siemSetEventInEvent,
  setDetectionList as siemSetDetectionList,
  setAudit as siemSetAudit,
  setNotification as siemSetNotification,
} from "redux/actions/CyberKitSiem";
import {
  setTaskManagementList as soarSetTaskMangement,
  setTaskAssignment as soarSetTaskAssignment,
  setResponsePlan as soarSetResponsePlan,
} from "redux/actions/CyberKitSoar";
import {
  setAgentList as vaSetAgent,
  setScannerList,
  setScannerTask,
  setScannerFound,
} from "redux/actions/CyberKitVulnerabilityAssessment";
import {
  setAssetList as ITAssetSetAssetList,
  setAssessment as ITAssetSetAssessmentList,
} from "redux/actions/CyberKitAssetInventory";
import {
  setAllEventList as threatIntelligenceSetAllEventList,
  setFollowEventList as threatIntelligenceSetFollowEventList,
  setPrivateEventList as threatIntelligenceSetPrivateEventList,
  setAttributes as threatIntelligenceSetAttributes,
} from "redux/actions/CyberKitThreatIntelligence";

import convertJsonParamsToString from "util/convertJsonParamsToString";

import LogManagementServices from "services/cyberkit/LogManagementServices";
import SiemServices from "services/cyberkit/SiemServices";
import SoarServices from "services/cyberkit/SoarServices";
import VulnerabilityAssessmentServices from "services/cyberkit/VulnerabilityAssessmentServices";
import AssetInventoryService from "services/cyberkit/AssetInventoryService";
import ThreatIntelligenceServices from "services/cyberkit/ThreatIntelligenceServices";
import AuditServices from "services/cyberkit/common/AuditLogService";
import {
  CyberKitLogMangementExplorerModel,
  CyberKitLogMangementArchiveModel,
  CyberKitSiemEventModel,
  CyberKitSiemDetectionModel,
  CyberKitSiemNotificationModel,
  CyberKitSoarTaskManagementModel,
  CyberKitSoarResponsePlanModel,
  CyberKitVulnerabilityAssessmentScannerModel,
  CyberKitVulnerabilityAssessmentTaskModel,
  CyberKitVulnerabilityAssessmentFoundModel,
} from "models";

// PAGINATE
export function* getPgn() {
  yield takeEvery(GET_PAGINATE, function* ({ payload }) {
    yield put(setLoading(true));

    let resp = null;
    let newModel = [];
    let params = ``;

    const keyParamList = Object.keys(payload.options.params || {});

    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.options.params);

    const payloadService = {
      path: payload.options.path,
      params: params,
      data: payload.options.data,
    };

    switch (payload.type) {
      // LOG MANAGEMENT
      case "logManagementAudit":
        resp = yield call(AuditServices.getAdultLog, payloadService);
        break;
      case "logManagementSearch":
        resp = yield call(
          LogManagementServices.getSearchResults,
          payloadService
        );
        break;
      case "logManagementExplorer":
        resp = yield call(LogManagementServices.getLogList, payloadService);
        break;
      case "logManagementArchive":
        resp = yield call(LogManagementServices.getArchives, payloadService);
        break;

      // SIEM
      case "siemEvent":
        resp = yield call(SiemServices.getEventList, payloadService);
        break;
      case "siemEventInEvent":
        resp = yield call(SiemServices.getEventData, payloadService);
        break;
      case "siemDetection":
        resp = yield call(SiemServices.getDetectionList, payloadService);
        break;
      case "siemAudit":
        resp = yield call(AuditServices.getAdultLog, payloadService);
        break;
      case "siemNotification":
        resp = yield call(SiemServices.getNotificationList, payloadService);
        break;

      // SOAR
      case "soarTaskManagement":
        resp = yield call(SoarServices.getRequestList, payloadService);
        break;
      case "soarTaskAssignment":
        resp = yield call(SoarServices.getTaskAssignment, payloadService);
        break;
      case "soarResponsePlanning":
        resp = yield call(SoarServices.getWorkFlowList, payloadService);
        break;

      // VULNERABILITY ASSESSMENT
      case "agentList":
        resp = yield call(
          VulnerabilityAssessmentServices.getAgentList,
          payloadService
        );
        break;

      case "scannerList":
        resp = yield call(
          VulnerabilityAssessmentServices.getScannerList,
          payloadService
        );
        break;
      case "scannerTasks":
        resp = yield call(
          VulnerabilityAssessmentServices.getScannerTasks,
          payloadService
        );
        break;
      case "scannerFound":
        resp = yield call(
          VulnerabilityAssessmentServices.getScannerFound,
          payloadService
        );
        break;

      // IT ASSET INVENTORY
      case "ITAssetInventoryAssetList":
        resp = yield call(AssetInventoryService.getAssets, payloadService);
        break;

      case "ITAssetInventoryAssessmentList":
        resp = yield call(
          AssetInventoryService.getAssesByInventoryType,
          payloadService
        );
        break;

      // THREAT INTELLIGENCE
      case "threatIntelligenceAllEventList":
        resp = yield call(
          ThreatIntelligenceServices.getEventList,
          payloadService
        );
        break;

      case "threatIntelligenceSearchAllEventList":
        resp = yield call(
          ThreatIntelligenceServices.searchEvent,
          payloadService
        );
        break;

      case "threatIntelligenceFollowEventList":
        resp = yield call(
          ThreatIntelligenceServices.getFollowEvent,
          payloadService
        );
        break;

      case "threatIntelligencePrivateEventList":
        resp = yield call(
          ThreatIntelligenceServices.getEventList,
          payloadService
        );
        break;

      case "threatIntelligenceSearchPrivateEventList":
        resp = yield call(
          ThreatIntelligenceServices.searchEvent,
          payloadService
        );
        break;

      case "threatIntelligenceAttributes":
        resp = yield call(
          ThreatIntelligenceServices.getAttributesByEventID,
          payloadService
        );
        break;

      default:
        break;
    }

    const { status, statusCode, message, data, meta } = resp;

    if (status === "OK" || (statusCode === 200 && message === "success")) {
      if (meta !== undefined) yield put(setTotalPage(meta.total_page));

      switch (payload.type) {
        // LOG MANAGEMENT
        case "logManagementAudit":
          yield put(setAuditList(data));
          break;
        case "logManagementSearch":
          newModel = data.map((v) => {
            return {
              ...v,
              ...v._source,
            };
          });
          yield put(setSearchResult(newModel));
          break;
        case "logManagementExplorer":
          newModel = data.map((v) => new CyberKitLogMangementExplorerModel(v));
          yield put(logManagementSetExplorers(newModel));
          break;
        case "logManagementArchive":
          newModel = data.map((v) => new CyberKitLogMangementArchiveModel(v));
          yield put(setArchives(newModel));
          break;

        // SIEM
        case "siemEvent":
          newModel = data.map((v) => new CyberKitSiemEventModel(v));
          yield put(siemSetEventList(newModel));
          break;
        case "siemEventInEvent":
          yield put(siemSetEventInEvent(data));
          break;
        case "siemDetection":
          newModel = data.map((v) => new CyberKitSiemDetectionModel(v));
          yield put(siemSetDetectionList(newModel));
          break;
        case "siemAudit":
          yield put(siemSetAudit(data));
          break;
        case "siemNotification":
          newModel = data.map(
            (v, index) =>
              new CyberKitSiemNotificationModel({ ...v, index: index + 1 })
          );
          yield put(siemSetNotification(newModel));
          break;

        // SOAR
        case "soarTaskManagement":
          newModel = data.map(
            (v) => new CyberKitSoarTaskManagementModel({ ...v, id: v._id })
          );
          yield put(soarSetTaskMangement(newModel));
          break;
        case "soarTaskAssignment":
          yield put(soarSetTaskAssignment(data));
          break;
        case "soarResponsePlanning":
          newModel = data.map(
            (v) => new CyberKitSoarResponsePlanModel({ ...v, id: v._id })
          );
          yield put(soarSetResponsePlan(newModel));
          break;

        // VULNERABILITY ASSESSMENT
        case "agentList":
          yield put(vaSetAgent(data));
          break;

        case "scannerList":
          newModel = data.map(
            (v) => new CyberKitVulnerabilityAssessmentScannerModel(v)
          );
          yield put(setScannerList(newModel));
          break;
        case "scannerTasks":
          newModel = data.map(
            (v) => new CyberKitVulnerabilityAssessmentTaskModel(v)
          );
          yield put(setScannerTask(newModel));
          break;
        case "scannerFound":
          newModel.data = (data ?? []).map(
            (v) => new CyberKitVulnerabilityAssessmentFoundModel(v)
          );
          yield put(setScannerFound(newModel));
          break;

        // IT ASSET INVENTORY
        case "ITAssetInventoryAssetList":
          yield put(ITAssetSetAssetList(data));
          break;

        case "ITAssetInventoryAssessmentList":
          yield put(ITAssetSetAssessmentList(data));
          break;

        // THREAT INTELLIGENCE
        case "threatIntelligenceAllEventList":
          yield put(threatIntelligenceSetAllEventList(data || []));
          break;

        case "threatIntelligenceSearchAllEventList":
          yield put(threatIntelligenceSetAllEventList(data));
          break;

        case "threatIntelligenceFollowEventList":
          yield put(threatIntelligenceSetFollowEventList(data || []));
          break;

        case "threatIntelligencePrivateEventList":
          yield put(threatIntelligenceSetPrivateEventList(data || []));
          break;

        case "threatIntelligenceSearchPrivateEventList":
          yield put(threatIntelligenceSetPrivateEventList(data || []));
          break;

        case "threatIntelligenceAttributes":
          yield put(threatIntelligenceSetAttributes(data));
          break;

        default:
          break;
      }
    }

    yield put(setLoading(false));
  });
}

export default function* rootSaga() {
  yield all([fork(getPgn)]);
}
