import {
  // DASHBOARD
  GET_ST_DASHBOARD,
  GET_DY_DASHBOARD,
  SET_ST_DASHBOARD,
  SET_DY_DASHBOARD,

  // SOURCE
  GET_SOURCE_LIST,
  SET_SOURCE_LIST,
  GET_SOURCE_LIST_BY_TENANACY,
  CREATE_SOURCE,
  UPDATE_SOURCE,
  DELETE_SOURCE,
  GET_SOURCE_SIEM_LIST,
  SET_SOURCE_SIEM_LIST,
  CLEAR_SOURCE_SIEM_LIST,

  // AUDIT
  GET_LOG_AUDIT_LOG,
  SET_LOG_AUDIT_LOG,

  // SEARCH
  SET_LOG_SEARCH_LIST,
  GET_LOG_SIEM_FIELDS,
  SET_LOG_SIEM_FIELDS,

  // EXPLORER
  GET_LOG_LIST,
  SET_EXPLORER,
  SET_DATE_FOLDER,
  SET_HOUR_FOLDER,
  SET_ARCHIVES,
  CREATE_ARCHIVE,
  UPDATE_ARCHIVE,
  DELETE_ARCHIVE,
} from "redux/constants/CyberKitLogManagement";

// DASHBOARD
export const getSTDashboard = (payload) => {
  return {
    type: GET_ST_DASHBOARD,
    payload: payload,
  };
};

export const getDYDashboard = (payload) => {
  return {
    type: GET_DY_DASHBOARD,
    payload: payload,
  };
};

export const setSTDashboard = (data) => {
  return {
    type: SET_ST_DASHBOARD,
    payload: data,
  };
};

export const setDYDashboard = (data) => {
  return {
    type: SET_DY_DASHBOARD,
    payload: data,
  };
};

// SOURCE
export const getListSource = () => {
  return {
    type: GET_SOURCE_LIST,
  };
};

export const setListSource = (data) => {
  return {
    type: SET_SOURCE_LIST,
    payload: data,
  };
};

export const getSourcesByTenancy = (payload) => {
  return {
    type: GET_SOURCE_LIST_BY_TENANACY,
    payload: payload,
  };
};

export const createSource = (data) => {
  return {
    type: CREATE_SOURCE,
    payload: data,
  };
};

export const updateSource = (data) => {
  return {
    type: UPDATE_SOURCE,
    payload: data,
  };
};

export const deleteSource = (data) => {
  return {
    type: DELETE_SOURCE,
    payload: data,
  };
};

export const getSourceByTenancy = (payload) => {
  return {
    type: GET_SOURCE_SIEM_LIST,
    payload: payload,
  };
};

export const getSourceByTenancySIEM = (payload) => {
  return {
    type: GET_SOURCE_SIEM_LIST,
    payload: payload,
  };
};

export const getSourceByID = (payload) => {
  return {
    type: GET_SOURCE_SIEM_LIST,
    payload: payload,
  };
};

export const getSourceSIEMList = (data) => {
  return {
    type: GET_SOURCE_SIEM_LIST,
    payload: data,
  };
};

export const setListSIEMSource = (data) => {
  return {
    type: SET_SOURCE_SIEM_LIST,
    payload: data,
  };
};

export const clearListSIEMSource = () => {
  return {
    type: CLEAR_SOURCE_SIEM_LIST,
  };
};

// AUDIT
export const getAuditList = () => {
  return {
    type: GET_LOG_AUDIT_LOG,
  };
};

export const setAuditList = (payload) => {
  return {
    type: SET_LOG_AUDIT_LOG,
    payload: payload,
  };
};

// SEARCH
export const setSearchResult = (payload) => {
  return {
    type: SET_LOG_SEARCH_LIST,
    payload: payload,
  };
};

export const getSIEMField = (payload) => {
  return {
    type: GET_LOG_SIEM_FIELDS,
    payload: payload,
  };
};

export const setSIEMField = (payload) => {
  return {
    type: SET_LOG_SIEM_FIELDS,
    payload: payload,
  };
};

// EXPLORER
export const getLogList = (payload) => {
  return {
    type: GET_LOG_LIST,
    payload: payload,
  };
};

export const setExplorers = (payload) => {
  return {
    type: SET_EXPLORER,
    payload: payload,
  };
};

export const setDateFolders = (payload) => {
  return {
    type: SET_DATE_FOLDER,
    payload: payload,
  };
};

export const setHourFolders = (payload) => {
  return {
    type: SET_HOUR_FOLDER,
    payload: payload,
  };
};

export const setArchives = (payload) => {
  return {
    type: SET_ARCHIVES,
    payload: payload,
  };
};

export const createArchive = (payload) => {
  return {
    type: CREATE_ARCHIVE,
    payload: payload,
  };
};

export const updateArchvice = (payload) => {
  return {
    type: UPDATE_ARCHIVE,
    payload: payload,
  };
};

export const deleteArchvive = (payload) => {
  return {
    type: DELETE_ARCHIVE,
    payload: payload,
  };
};
