import fetch from "util/Api";
import { PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL as endpoint } from "config/EndpointConfig";

const ConsentManagementService = {};

ConsentManagementService.listConsent = async () =>
  await fetch({
    url: `${endpoint}/receipt/paginate`,
    method: "get",
  });

ConsentManagementService.listConsentNextPage = async (nextPageToken) =>
  await fetch({
    url: `${endpoint}/receipt/paginate?next_page_token=${nextPageToken}`,
    method: "get",
  });

ConsentManagementService.getConsent = async ({ consentId, collectionId }) =>
  await fetch({
    url: `${endpoint}/receipt/${consentId}?collection_id=${collectionId}`,
    method: "get",
  });

ConsentManagementService.searchConsent = async (search) =>
  await fetch({
    url: `${endpoint}/search?identifier=${search}`,
    method: "get",
  });

export default ConsentManagementService;
