import {
  CHANGE_SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY,
  SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY,
  SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY_SUCCESS,
  SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY_ERROR,
  LIST_DATA_CLASSIFICATION_AND_DISCOVERY,
  LIST_DATA_CLASSIFICATION_AND_DISCOVERY_SUCCESS,
  LIST_DATA_CLASSIFICATION_AND_DISCOVERY_ERROR,
} from "../constants/PrivacyKitDataClassificationAndDiscovery";

export const changeSearchDataClassificationAndDiscovery = (search) => ({
  type: CHANGE_SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY,
  search,
});

export const searchDataClassificationAndDiscovery = (search) => ({
  type: SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY,
  search,
});

export const searchDataClassificationAndDiscoverySuccess = (
  listSearchDataClassificationAndDiscovery
) => ({
  type: SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY_SUCCESS,
  listSearchDataClassificationAndDiscovery,
});

export const searchDataClassificationAndDiscoveryError = (error) => ({
  type: SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY_ERROR,
  error,
});

export const listDataClassificationAndDiscovery = (nextPageToken) => ({
  type: LIST_DATA_CLASSIFICATION_AND_DISCOVERY,
  nextPageToken,
});

export const listDataClassificationAndDiscoverySuccess = (
  payload,
  nextPageToken
) => ({
  type: LIST_DATA_CLASSIFICATION_AND_DISCOVERY_SUCCESS,
  payload,
  nextPageToken,
});

export const listDataClassificationAndDiscoveryError = (error) => ({
  type: LIST_DATA_CLASSIFICATION_AND_DISCOVERY_ERROR,
  error,
});
