import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_POLICY_AND_NOTICE_MANAGEMENT_DASHBOARD,
  LIST_POLICY_AND_NOTICE_MANAGEMENT,
  GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE,
  GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_DATA,
  GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_BUILDER,
  CREATE_POLICY_AND_NOTICE_MANAGEMENT,
  GET_POLICY_AND_NOTICE_MANAGEMENT,
  UPDATE_POLICY_AND_NOTICE_MANAGEMENT,
  PUBLISH_POLICY_AND_NOTICE_MANAGEMENT,
  GET_POLICY_AND_NOTICE_MANAGEMENT_VERSION,
  DELETE_POLICY_AND_NOTICE_MANAGEMENT,
  LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK,
  UPDATE_POLICY_AND_NOTICE_MANAGEMENT_TASK,
  LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT,
  GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT,
  CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT,
  UPDATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT,
  DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT,
} from "redux/constants/PrivacyKitPolicyAndNoticeManagement";
import {
  getPolicyAndNoticeManagementDashboardSuccess,
  listPolicyAndNoticeManagementSuccess,
  listPolicyAndNoticeManagementFailed,
  getPolicyAndNoticeManagementTemplateSuccess,
  getPolicyAndNoticeManagementTemplateDataSuccess,
  getPolicyAndNoticeManagementTemplateBuilderSuccess,
  createPolicyAndNoticeManagementSuccess,
  createPolicyAndNoticeManagementFailed,
  getPolicyAndNoticeManagementSuccess,
  getPolicyAndNoticeManagementFailed,
  updatePolicyAndNoticeManagementSuccess,
  updatePolicyAndNoticeManagementFailed,
  publishPolicyAndNoticeManagementSuccess,
  getPolicyAndNoticeManagementVersionSuccess,
  deletePolicyAndNoticeManagementSuccess,
  deletePolicyAndNoticeManagementFailed,
  listPolicyAndNoticeManagementTaskSuccess,
  listPolicyAndNoticeManagementTaskFailed,
  updatePolicyAndNoticeManagementTaskSuccess,
  updatePolicyAndNoticeManagementTaskFailed,
  listPolicyAndNoticeManagementAssessmentSuccess,
  listPolicyAndNoticeManagementAssessmentFailed,
  getPolicyAndNoticeManagementAssessmentSuccess,
  getPolicyAndNoticeManagementAssessmentFailed,
  createPolicyAndNoticeManagementAssessmentSuccess,
  createPolicyAndNoticeManagementAssessmentFailed,
  updatePolicyAndNoticeManagementAssessmentSuccess,
  updatePolicyAndNoticeManagementAssessmentFailed,
  deletePolicyAndNoticeManagementAssessmentSuccess,
  deletePolicyAndNoticeManagementAssessmentFailed,
} from "redux/actions/PrivacyKitPolicyAndNoticeManagement";
import PolicyAndNoticeManagementService from "services/pdpakit/PolicyAndNoticeManagementService";
import Notification from "util/Notification";

function* getPolicyAndNoticeManagementDashboard() {
  yield takeEvery(GET_POLICY_AND_NOTICE_MANAGEMENT_DASHBOARD, function* () {
    try {
      const response = yield call(
        PolicyAndNoticeManagementService.getDashboard
      );
      if (response.statusCode === 200) {
        yield put(getPolicyAndNoticeManagementDashboardSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* listPolicyAndNoticeManagement() {
  yield takeEvery(LIST_POLICY_AND_NOTICE_MANAGEMENT, function* () {
    try {
      const response = yield call(PolicyAndNoticeManagementService.listPolicy);
      if (response.statusCode === 200) {
        yield put(listPolicyAndNoticeManagementSuccess(response));
      } else {
        yield put(listPolicyAndNoticeManagementFailed(response));
      }
    } catch (error) {
      yield put(listPolicyAndNoticeManagementFailed(error));
    }
  });
}

function* getPolicyManagementAndNoticeManagementTemplate() {
  yield takeEvery(GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE, function* () {
    try {
      const response = yield call(
        PolicyAndNoticeManagementService.getPolicyTemplate
      );
      if (response.statusCode === 200) {
        yield put(getPolicyAndNoticeManagementTemplateSuccess(response.Items));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* getPolicyManagementAndNoticeManagementTemplateData() {
  yield takeEvery(
    GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_DATA,
    function* ({ payload }) {
      try {
        const response = yield call(
          PolicyAndNoticeManagementService.getPolicyTemplateData,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            getPolicyAndNoticeManagementTemplateDataSuccess(response.Item)
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* getPolicyManagementAndNoticeManagementTemplateBuilder() {
  yield takeEvery(
    GET_POLICY_AND_NOTICE_MANAGEMENT_TEMPLATE_BUILDER,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          PolicyAndNoticeManagementService.getPolicyTemplateBuilder,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            getPolicyAndNoticeManagementTemplateBuilderSuccess(response.Items)
          );
          onFinish();
        } else {
          onFailed();
        }
      } catch (error) {
        console.log(error);
        onFinish(error);
      }
    }
  );
}

function* createPolicyAndNoticeManagement() {
  yield takeEvery(
    CREATE_POLICY_AND_NOTICE_MANAGEMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          PolicyAndNoticeManagementService.createPolicy,
          payload
        );

        if (response.statusCode === 200) {
          yield put(createPolicyAndNoticeManagementSuccess(response.Item));
          onFinish(response);
          Notification("success", "สร้างนโยบายของคุณสำเร็จแล้ว");
        } else {
          yield put(createPolicyAndNoticeManagementFailed(response));
          onFailed(response);
          Notification("error", "ชื่อนโยบายนี้ถูกใช้งานแล้ว");
        }
      } catch (error) {
        yield put(createPolicyAndNoticeManagementFailed(error));
        onFailed(error);
      }
    }
  );
}

function* getPolicyAndNoticeManagement() {
  yield takeEvery(GET_POLICY_AND_NOTICE_MANAGEMENT, function* ({ payload }) {
    try {
      const response = yield call(
        PolicyAndNoticeManagementService.getPolicyData,
        payload
      );
      // const versions = yield call(
      //   PolicyAndNoticeManagementService.getPolicyVersion,
      //   payload
      // );
      if (response.statusCode === 200) {
        yield put(
          getPolicyAndNoticeManagementSuccess({
            ...response.Item,
            statusCode: 200,
          })
        );
      } else {
        yield put(getPolicyAndNoticeManagementFailed(response));
      }
    } catch (error) {
      yield put(getPolicyAndNoticeManagementFailed(error));
    }
  });
}

function* updatePolicyAndNoticeManagement() {
  yield takeEvery(
    UPDATE_POLICY_AND_NOTICE_MANAGEMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          PolicyAndNoticeManagementService.updatePolicy,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updatePolicyAndNoticeManagementSuccess(response.Item));
          onFinish(response.data);
          Notification("success", "อัปเดตนโยบายของคุณสำเร็จแล้ว");
        } else {
          yield put(updatePolicyAndNoticeManagementFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(updatePolicyAndNoticeManagementFailed(error));
        onFailed(error);
      }
    }
  );
}

function* publishPolicyAndNoticeManagement() {
  yield takeEvery(
    PUBLISH_POLICY_AND_NOTICE_MANAGEMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        yield call(PolicyAndNoticeManagementService.updatePolicy, payload);
        const response = yield call(
          PolicyAndNoticeManagementService.publishPolicy,
          payload
        );
        if (response.statusCode === 200) {
          yield put(publishPolicyAndNoticeManagementSuccess(response.Item));
          onFinish();
          Notification("success", "เผยแพร่นโยบายของคุณสำเร็จแล้ว");
        } else {
          onFailed();
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* getPolicyAndNoticeManagementVersion() {
  yield takeEvery(
    GET_POLICY_AND_NOTICE_MANAGEMENT_VERSION,
    function* ({ payload }) {
      try {
        const response = yield call(
          PolicyAndNoticeManagementService.getPolicyVersion,
          payload
        );
        if (response.statusCode === 200) {
          yield put(getPolicyAndNoticeManagementVersionSuccess(response));
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* deletePolicyAndNoticeManagement() {
  yield takeEvery(
    DELETE_POLICY_AND_NOTICE_MANAGEMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          PolicyAndNoticeManagementService.deletePolicy,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deletePolicyAndNoticeManagementSuccess(payload.ObjectUUID));
          onFinish(response);
          Notification("success", "ลบนโยบายของคุณสำเร็จแล้ว");
        } else {
          yield put(deletePolicyAndNoticeManagementFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(deletePolicyAndNoticeManagementFailed(error));
        onFailed(error);
      }
    }
  );
}

function* listPolicyAndNoticeManagementTask() {
  yield takeEvery(LIST_POLICY_AND_NOTICE_MANAGEMENT_TASK, function* () {
    try {
      const response = yield call(PolicyAndNoticeManagementService.listTask);
      if (response.statusCode === 200) {
        yield put(listPolicyAndNoticeManagementTaskSuccess(response));
      } else {
        yield put(listPolicyAndNoticeManagementTaskFailed(response));
      }
    } catch (error) {
      yield put(listPolicyAndNoticeManagementTaskFailed(error));
    }
  });
}

function* updatePolicyAndNoticeManagementTask() {
  yield takeEvery(
    UPDATE_POLICY_AND_NOTICE_MANAGEMENT_TASK,
    function* ({ payload, onFinish }) {
      try {
        const response = yield call(
          PolicyAndNoticeManagementService.updateTask,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updatePolicyAndNoticeManagementTaskSuccess(response.Item));
          onFinish();
        }
      } catch (error) {
        yield put(updatePolicyAndNoticeManagementTaskFailed(error));
      }
    }
  );
}

function* listPolicyAndNoticeManagementAssessment() {
  yield takeEvery(LIST_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT, function* () {
    try {
      const response = yield call(
        PolicyAndNoticeManagementService.listAccessment
      );
      if (response.statusCode === 200) {
        yield put(listPolicyAndNoticeManagementAssessmentSuccess(response));
      } else {
        yield put(listPolicyAndNoticeManagementAssessmentFailed(response));
      }
    } catch (error) {
      yield put(listPolicyAndNoticeManagementAssessmentFailed(error));
    }
  });
}

function* getPolicyAndNoticeManagementAssessment() {
  yield takeEvery(
    GET_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT,
    function* ({ payload }) {
      try {
        const response = yield call(
          PolicyAndNoticeManagementService.getAccessment,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            getPolicyAndNoticeManagementAssessmentSuccess({
              ...response.Item,
              statusCode: 200,
            })
          );
        } else {
          yield put(getPolicyAndNoticeManagementAssessmentFailed(response));
        }
      } catch (error) {
        yield put(getPolicyAndNoticeManagementAssessmentFailed(error));
      }
    }
  );
}

function* createPolicyAndNoticeManagementAssessment() {
  yield takeEvery(
    CREATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT,
    function* ({ payload, onFinish }) {
      try {
        const response = yield call(
          PolicyAndNoticeManagementService.createAccessment,
          payload
        );
        console.log(response);
        if (response.statusCode === 200) {
          yield put(
            createPolicyAndNoticeManagementAssessmentSuccess(response.Item)
          );
          onFinish();
        }
      } catch (error) {
        console.log(error);
        yield put(createPolicyAndNoticeManagementAssessmentFailed(error));
      }
    }
  );
}

function* updatePolicyAndNoticeManagementAssessment() {
  yield takeEvery(
    UPDATE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT,
    function* ({ payload, onFinish }) {
      try {
        const response = yield call(
          PolicyAndNoticeManagementService.updateAssessment,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            updatePolicyAndNoticeManagementAssessmentSuccess(response.Item)
          );
          onFinish();
        }
      } catch (error) {
        yield put(updatePolicyAndNoticeManagementAssessmentFailed(error));
      }
    }
  );
}

function* deletePolicyAndNoticeManagementAssessment() {
  yield takeEvery(
    DELETE_POLICY_AND_NOTICE_MANAGEMENT_ASSESSMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          PolicyAndNoticeManagementService.deleteAssessment,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            deletePolicyAndNoticeManagementAssessmentSuccess(payload.ObjectUUID)
          );
          onFinish(response);
        } else {
          yield put(deletePolicyAndNoticeManagementAssessmentFailed(response));
          onFailed(response);
        }
      } catch (error) {
        yield put(deletePolicyAndNoticeManagementAssessmentFailed(error));
        onFailed(error);
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(getPolicyAndNoticeManagementDashboard),
    fork(listPolicyAndNoticeManagement),
    fork(getPolicyManagementAndNoticeManagementTemplate),
    fork(getPolicyManagementAndNoticeManagementTemplateData),
    fork(getPolicyManagementAndNoticeManagementTemplateBuilder),
    fork(createPolicyAndNoticeManagement),
    fork(getPolicyAndNoticeManagement),
    fork(updatePolicyAndNoticeManagement),
    fork(publishPolicyAndNoticeManagement),
    fork(getPolicyAndNoticeManagementVersion),
    fork(deletePolicyAndNoticeManagement),
    fork(listPolicyAndNoticeManagementTask),
    fork(updatePolicyAndNoticeManagementTask),
    fork(listPolicyAndNoticeManagementAssessment),
    fork(getPolicyAndNoticeManagementAssessment),
    fork(createPolicyAndNoticeManagementAssessment),
    fork(updatePolicyAndNoticeManagementAssessment),
    fork(deletePolicyAndNoticeManagementAssessment),
  ]);
}
