import fetch from "util/Api";
import { CYBER_KIT_BASE_URL as endpoint } from "config/EndpointConfig";

const AuditServices = {};

AuditServices.getAdultLog = async (payload) => {
  return await fetch({
    method: "get",
    url: `${endpoint}/audit_log${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);
};

export default AuditServices;
