export const selectCustomPreference = (state) =>
  state.privacyKitConsentManagement.customPreference;

export const selectListCustomPreference = (state) =>
  state.privacyKitConsentManagement.customPreference.listCustomPreference;

export const selectManageCustomPreference = (state) =>
  state.privacyKitConsentManagement.customPreference.manageCustomPreference;

export const selectDeleteCustomPreference = (state) =>
  state.privacyKitConsentManagement.customPreference.deleteCustomPreference;
