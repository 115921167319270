import {
  // Agent
  CREATE_AGENT,
  UPDATE_AGENT,
  DELETE_AGENT,
  SET_AGENT,

  // Scanner
  GET_SCANNER_DASHBOARD,
  SET_SCANNER_DASHBOARD,
  GET_SCANNER_LIST,
  CREATE_SCANNER,
  UPDATE_SCANNER,
  DELETE_SCANNER,
  GET_SCANNER_TASK,
  GET_SCANNER_FOUND,
  GET_SCANNER_DETAIL,
  SET_SCANNER_LIST,
  SET_SCANNER_TASK,
  SET_SCANNER_FOUND,
  SET_SCANNER_DETAIL,
} from "redux/constants/CyberKitVulnerabilityAssessment";

// Agent
export const setAgentList = (payload) => {
  return {
    type: SET_AGENT,
    payload: payload,
  };
};

export const createAgent = (payload) => {
  return {
    type: CREATE_AGENT,
    payload: payload,
  };
};

export const updateAgent = (payload) => {
  return {
    type: UPDATE_AGENT,
    payload: payload,
  };
};

export const deleteAgent = (payload) => {
  return {
    type: DELETE_AGENT,
    payload: payload,
  };
};

// Scanner
export const getScannerDashboard = (payload) => {
  return {
    type: GET_SCANNER_DASHBOARD,
    payload: payload,
  };
};

export const setScannerDashboard = (payload) => {
  return {
    type: SET_SCANNER_DASHBOARD,
    payload: payload,
  };
};

export const getScannerList = (payload) => {
  return {
    type: GET_SCANNER_LIST,
    payload: payload,
  };
};

export const setScannerList = (payload) => {
  return {
    type: SET_SCANNER_LIST,
    payload: payload,
  };
};

export const setScannerTask = (payload) => {
  return {
    type: SET_SCANNER_TASK,
    payload: payload,
  };
};

export const getScannerFound = (payload) => {
  return {
    type: GET_SCANNER_FOUND,
    payload: payload,
  };
};

export const setScannerFound = (payload) => {
  return {
    type: SET_SCANNER_FOUND,
    payload: payload,
  };
};

export const getScannerTasks = (payload) => {
  return {
    type: GET_SCANNER_TASK,
    payload: payload,
  };
};

export const getScannerDetail = (payload) => {
  return {
    type: GET_SCANNER_DETAIL,
    payload: payload,
  };
};

export const setScannerDetail = (payload) => {
  return {
    type: SET_SCANNER_DETAIL,
    payload: payload,
  };
};

export const createScanner = (payload) => {
  return {
    type: CREATE_SCANNER,
    payload: payload,
  };
};

export const updateScanner = (payload) => {
  return {
    type: UPDATE_SCANNER,
    payload: payload,
  };
};

export const deleteScanner = (payload) => {
  return {
    type: DELETE_SCANNER,
    payload: payload,
  };
};
