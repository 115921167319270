import { changeFormatDatetime } from "util/DateUtils";
import moment from "moment";
import { CyberKitLogMangementExplorerArchiveModel } from "models";

export default class logModel {
  constructor(data) {
    this._id = data._id ? data._id : "";
    this.filename = data.filename ? data.filename : "";
    this.path = data.path ? data.path : "";
    this.collector = data.collector ? data.collector : "";
    this.compression = data.compression ? data.compression : "";
    this.tenancy = data.tenancy ? data.tenancy : "";
    this.timestamp = data.timestamp ? data.timestamp : "";
    this.collected_timestemp = data.collected_timestemp
      ? data.collected_timestemp
      : "";
    this.size = data.size ? data.size : 0;
    this.hashing = data.hashing ? data.hashing : {};
    this.facility = data.facility ? data.facility : "";
    this.severity = data.severity ? data.severity : "";
    this.source = data.source ? data.source : "";
    this.tags = data.tags ? data.tags : null;
    this.archive = data.archive
      ? new CyberKitLogMangementExplorerArchiveModel(data.archive)
      : new CyberKitLogMangementExplorerArchiveModel({});
  }

  displayID = () => this._id;
  displayFilename = () => this.filename;
  displayPath = () => this.path;
  displaySize = () => {
    const KB = 1024;
    const MB = KB * 1024;
    const GB = MB * 1024;
    const TB = GB * 1024;
    if (this.size < 1024) return `${this.size} Byte`;
    else if (this.size < 1024 * KB) return `${(this.size / KB).toFixed(4)} KB`;
    else if (this.size < 1024 * MB) return `${(this.size / MB).toFixed(4)} MB`;
    else if (this.size < 1024 * GB) return `${(this.size / GB).toFixed(4)} GB`;
    else if (this.size < 1024 * TB) return `${(this.size / TB).toFixed(4)} TB`;
    else return "Oversize";
  };
  displayArchiveDate = () => {
    if (this.archive?.is)
      return changeFormatDatetime(this.archive.create_at, "DD/MM/YYYY h:mm A");
    return "-";
  };
  displayArchiveTo = () => {
    if (this.archive?.is)
      return changeFormatDatetime(
        moment(this.archive.create_at).add(this.archive.day, "day"),
        "DD/MM/YYYY h:mm A"
      );
    return "-";
  };
}
