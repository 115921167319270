export const selectDataSubjectRightManagementWorkflow = (state) =>
  state.privacykitDataSubjectRightManagement.workflow;

export const selectDataSubjectRightManagementWorkflowData = (state) =>
  state.privacykitDataSubjectRightManagement.workflow.workflowData;

export const selectDataSubjectRightManagementWorkflowCreate = (state) =>
  state.privacykitDataSubjectRightManagement.workflow.workflowCreate;

export const selectDataSubjectRightManagementWorkflowDelete = (state) =>
  state.privacykitDataSubjectRightManagement.workflow.workflowDelete;

export const selectListDataSubjectRightManagementWorkflowTask = (state) =>
  state.privacykitDataSubjectRightManagement.workflow.listTaskInWorkflow;

export const selectDataSubjectRightManagementWorkflowTaskModal = (state) =>
  state.privacykitDataSubjectRightManagement.workflow.taskModal;

export const selectDataSubjectRightManagementWorkflowDeleteTask = (state) =>
  state.privacykitDataSubjectRightManagement.workflow.deleteTask;

export const selectDataSubjectRightManagementWorkflowModalPublish = (state) =>
  state.privacykitDataSubjectRightManagement.workflow.modalPublish;
