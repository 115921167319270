import fetch from "util/Api";
import { PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL as endpoint } from "config/EndpointConfig";

const DashboardServices = {};

DashboardServices.getConsentDashboard = async (startDate, endDate) =>
  await fetch({
    url: `${endpoint}/dashboard?start_date=${startDate}&end_date=${endDate}`,
    method: "get",
  });

DashboardServices.getConsentReceiptDashboard = async (
  nextPageToken = null,
  startDate,
  endDate
) =>
  await fetch({
    url: `${endpoint}/receipt/paginate?start_date=${startDate}&end_date=${endDate}${
      nextPageToken ? `&next_page_token=${nextPageToken}` : ""
    }`,
    method: "get",
  });

DashboardServices.getConsentProcessingPurposeDashboard = async (
  page,
  startDate,
  endDate
) =>
  await fetch({
    url: `${endpoint}/dashboard_purpose?page=${page}&start_date=${startDate}&end_date=${endDate}`,
    method: "get",
  });

export default DashboardServices;
