const COLOUR_TAG = {
  HIGH: { color: "#C74343", label: "สุง" },
  Medium: { color: "#C74343", label: "ปานกลาง" },
  LOW: { color: "#FFC542", label: "ต่ำ" },
  INFO: { color: "#17BCFF", label: "ไม่มีผลกระทบ" },
  MIXED: { color: "#17BCFF", label: "MIXED" },
};

export default class DetailModel {
  constructor(data) {
    this.threat = data?.threat || "";
    this.name = data?.name || "";
    this.solution = data?.solution || {};
    this.severity = data?.severity;
    this.description = data?.description || "";
    this.port = data?.port || "";
    this.host = data?.host || "";
    this.nvt = data?.nvt || [];
  }

  getSeveritylevel = () => {
    let level = "";
    let severity = parseFloat(this.severity);

    if (severity === 0) level = "INFO";
    else if (severity > 0 && severity <= 2.6) level = "LOW";
    else if (severity > 2.6) level = "HIGH";
    else level = "MIXED";

    return level;
  };

  displayThreat = () => this.threat;
  displayName = () => this.name;
  displayContentSolution = () => this.solution.content || "";
  displayTypeSolution = () => this.solution.type || "";
  displayDescription = () => this.description;
  displayPort = () => this.port;
  displayHost = () => this.host;
  displayNVT = () => this.nvt;
  displaySeverityColor = () =>
    COLOUR_TAG[this.getSeveritylevel()]?.color || "#795F9E";
  displaySeverityLabel = () =>
    COLOUR_TAG[this.getSeveritylevel()]?.label || "None";
}
