import fetch from "util/Api";
import {
  PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT_BASE_URL as endpoint,
  PRIVACYKIT_COOKIE_CONSENT_MANAGEMENT_SAM_BASE_URL as endpointSam,
} from "config/EndpointConfig";

const CookieConsentManagementService = {};

CookieConsentManagementService.getCookieConsentDashboard = async (
  domainKey,
  { startDate, endDate }
) =>
  await fetch({
    method: "GET",
    url: `${endpoint}/dashboard/accept/${domainKey}?start_date=${startDate}&end_date=${endDate}&mol=true`,
  });

CookieConsentManagementService.getCookieConsentReport = async (
  domainKey,
  { startDate, endDate }
) =>
  await fetch({
    method: "get",
    url: `${endpointSam}/cookie/transection/${domainKey}?start_date=${startDate}&end_date=${endDate}&mol=true`,
  });

CookieConsentManagementService.listDomains = async () =>
  await fetch({
    url: `${endpoint}/domain`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

CookieConsentManagementService.getDomain = async (key) =>
  await fetch({ method: "get", url: `${endpoint}/domain/${key}` })
    .then((resp) => resp)
    .catch((err) => err);

CookieConsentManagementService.updateDomain = async (domain) =>
  await fetch({
    method: "put",
    url: `${endpoint}/domain/${domain.key}`,
    data: { data: domain },
  })
    .then((resp) => resp)
    .catch((err) => err);

CookieConsentManagementService.addDomain = async (data) =>
  await fetch({
    method: "post",
    url: `${endpoint}/domain`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);

CookieConsentManagementService.deleteDomain = async (data) =>
  await fetch({
    method: "delete",
    url: `${endpoint}/domain/${data.key}`,
  })
    .then((resp) => resp)
    .catch((err) => err);

CookieConsentManagementService.scanCookieConsentManagementDomain = async (
  key
) => {
  return await fetch({
    method: "post",
    url: `${endpoint}/cookie/${key}`,
  })
    .then((resp) => resp)
    .catch((err) => err);
};

CookieConsentManagementService.updateCookieCategory = async ({
  domainKey,
  data,
}) =>
  await fetch({
    method: "post",
    url: `${endpoint}/cookie/category/${domainKey}`,
    data: { data },
  })
    .then((response) => response)
    .catch((error) => error);

CookieConsentManagementService.updateCookie = async ({ domainKey, data }) =>
  await fetch({
    method: "put",
    url: `${endpoint}/cookie/${domainKey}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);

export default CookieConsentManagementService;
