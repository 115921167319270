import {
    CyberKitPath,
} from "../AppModuleConfig";
  
const CyberKitSiemNavigation = (assessmentType) => [
  {
    key: `cyberkit-${assessmentType}-notify`,
    path: `${CyberKitPath}${assessmentType}/notify`,
    title: "ตั้งค่าการแจ้งเตือน",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `cyberkit-${assessmentType}-detection`,
    path: `${CyberKitPath}${assessmentType}/detection`,
    title: "เงื่อนไขการตรวจจับ",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `cyberkit-${assessmentType}-audit`,
    path: `${CyberKitPath}${assessmentType}/audit`,
    title: "ประวัติการใช้งานระบบ",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `cyberkit-${assessmentType}-event`,
    path: `${CyberKitPath}${assessmentType}/event`,
    title: "จัดการเหตุการณ์",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
];

export default CyberKitSiemNavigation;
  