import fetch from "util/Api";
import {
  // CYBER_KIT_BASE_URL as cyberkitEndpoint,
  WORK_FLOW_BASE_URL as workflowEndpoint,
} from "config/EndpointConfig";

const SoarServices = {};

// Dashboard
SoarServices.getSummary = async (payload) =>
  await fetch({
    method: "get",
    url: `${workflowEndpoint}/request/summary${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

// Task-Management
SoarServices.getRequestList = async (payload) =>
  await fetch({
    method: "get",
    url: `${workflowEndpoint}/request${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.getRequestByID = async (payload) =>
  await fetch({
    method: "get",
    url: `${workflowEndpoint}/request/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.deleteRequest = async (payload) =>
  await fetch({
    method: "delete",
    url: `${workflowEndpoint}/request/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.getJobByRequestID = async (payload) =>
  await fetch({
    method: "get",
    url: `${workflowEndpoint}/job/request/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.getComments = async (payload) =>
  await fetch({
    method: "get",
    url: `${workflowEndpoint}/comment${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.getActivity = async (payload) =>
  await fetch({
    method: "get",
    url: `${workflowEndpoint}/activity${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.createComment = async (payload) =>
  await fetch({
    method: "post",
    url: `${workflowEndpoint}/comment`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

// Task-Assign
SoarServices.getJobStatus = async (payload) =>
  await fetch({
    method: "get",
    url: `${workflowEndpoint}/job/status/${payload.path.request_id}${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.getTaskAssignment = async (payload) =>
  await fetch({
    method: "get",
    url: `${workflowEndpoint}/task/assing`,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.updateStatusTaskAssignment = async (payload) =>
  await fetch({
    method: "put",
    url: `${workflowEndpoint}/job/hook/${payload.path.request_id}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.getJobRequestDetailByRequestID = async (payload) =>
  await fetch({
    method: "get",
    url: `${workflowEndpoint}/job/request/${payload.path.request_id}${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.getJobResponseDetailByRequestID = async (payload) =>
  await fetch({
    method: "get",
    url: `${workflowEndpoint}/job/response/${payload.path.request_id}${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

// Response-planning
SoarServices.getWorkFlowList = async (payload) =>
  await fetch({
    method: "get",
    url: `${workflowEndpoint}/workflow${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.getWorkFlowByID = async (payload) =>
  await fetch({
    method: "get",
    url: `${workflowEndpoint}/workflow/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.getModuleList = async () =>
  await fetch({
    method: "get",
    url: `${workflowEndpoint}/items`,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.createWorkFlow = async (payload) =>
  await fetch({
    method: "post",
    url: `${workflowEndpoint}/workflow`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.updateWorkFlow = async (payload) =>
  await fetch({
    method: "put",
    url: `${workflowEndpoint}/workflow/${payload.path.id}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

SoarServices.deleteWorkFlow = async (payload) =>
  await fetch({
    method: "delete",
    url: `${workflowEndpoint}/workflow/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

export default SoarServices;
