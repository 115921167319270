import {
  LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS,
  LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP_SUCCESS,
  TOGGLE_MANAGE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  MANAGE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS,
  CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE,
  UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS,
  UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE,
  TOGGLE_DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS,
  DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE,
} from "../../constants/IPrivacyKitDataMapping";
import { replaceObject } from "util/arrayUtils";

const initialState = {
  list: null,
  listGroup: null,
  listAllGroup: null,
  manageModal: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
  deleteModal: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
};

export default function relatedControlMeasuresReducers(
  state = initialState,
  action
) {
  switch (action.type) {
    case LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    case LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP_SUCCESS: {
      return {
        ...state,
        listGroup: action.payload.listGroup,
        listAllControl: action.payload.listAllControl,
      };
    }
    case TOGGLE_MANAGE_DATA_MAPPING_RELATED_CONTROL_MEASURES: {
      return {
        ...state,
        manageModal: {
          ...initialState.manageModal,
          visible: !state.manageModal.visible,
          data: action?.data ?? null,
        },
      };
    }
    case MANAGE_DATA_MAPPING_RELATED_CONTROL_MEASURES: {
      return {
        ...state,
        manageModal: {
          ...state.manageModal,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS: {
      return {
        ...state,
        manageModal: initialState.manageModal,
        list: [action.payload, ...state.list],
      };
    }
    case CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE: {
      return {
        ...state,
        manageModal: {
          ...state.manageModal,
          okButtonProps: {
            loading: false,
          },
        },
      };
    }
    case UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS: {
      return {
        ...state,
        manageModal: initialState.manageModal,
        list: replaceObject(
          state.list,
          action.payload.ObjectUUID,
          action.payload
        ),
      };
    }
    case UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE: {
      return {
        ...state,
        manageModal: {
          ...state.manageModal,
          okButtonProps: {
            loading: false,
          },
        },
      };
    }
    case TOGGLE_DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES: {
      return {
        ...state,
        deleteModal: {
          ...initialState.deleteModal,
          visible: !state.deleteModal.visible,
          data: action?.data ?? null,
        },
      };
    }
    case DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES: {
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS: {
      return {
        ...state,
        deleteModal: initialState.deleteModal,
        list: state.list.filter(
          (item) => item.ObjectUUID !== state.deleteModal.data.ObjectUUID
        ),
      };
    }
    case DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE: {
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          okButtonProps: {
            loading: false,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
}
