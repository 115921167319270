import fetch from "util/Api";
import { AUTH_BASE_URL } from "config/EndpointConfig";

const AuthServices = {};

AuthServices.signin = async (data) =>
  await fetch({
    url: `${AUTH_BASE_URL}/signin`,
    method: "post",
    data,
  });
// .then((response) => response)
// .catch((error) => error);

AuthServices.refreshToken = async (refreshToken) =>
  await fetch({
    url: `${AUTH_BASE_URL}/userrefreshtoken`,
    method: "post",
    data: { data: { refresh_token: refreshToken } },
  })
    .then((response) => response)
    .catch((error) => error);

AuthServices.changeProfile = async (data) => {
  return await fetch({
    method: "post",
    url: `${AUTH_BASE_URL}/changeprofile`,
    data,
  })
    .then((resp) => resp)
    .catch((err) => err);
};

AuthServices.changePassword = async ({
  AccessToken,
  PreviousPassword,
  ProposedPassword,
  email,
}) =>
  await fetch({
    url: `${AUTH_BASE_URL}/changepasswd`,
    method: "post",
    data: {
      email,
      AccessToken,
      PreviousPassword,
      ProposedPassword,
    },
  });

export default AuthServices;
