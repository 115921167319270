import {
  LIST_CONSENT_MANAGEMENT,
  LIST_CONSENT_MANAGEMENT_SUCCESS,
  LIST_CONSENT_MANAGEMENT_NEXT_PAGE_SUCCESS,
  GET_CONSENT_MANAGEMENT,
  GET_CONSENT_MANAGEMENT_SUCCESS,
  SELECT_CONSENT_MANAGEMENT_FORM,
  TOGGLE_UNIVERSAL_CONSENT_MANAGEMENT_HISTORY,
  ON_SEARCH_CONSENT_MANAGEMENT,
  ON_SEARCH_CONSENT_MANAGEMENT_SUCCESS,
} from "../../constants/PrivacyKitConsentManagement";

const initialState = {
  loading: false,
  search: null,
  selectFormKey: null,
  consentData: null,
  listConsent: null,
  listSearchConsent: null,
  nextPageToken: null,
  consentHistory: {
    visible: false,
    data: null,
  },
};

const ConsentManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CONSENT_MANAGEMENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_CONSENT_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case LIST_CONSENT_MANAGEMENT_NEXT_PAGE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        listConsent: [...state.listConsent, ...action.payload.listConsent],
      };
    }
    case GET_CONSENT_MANAGEMENT: {
      return {
        ...state,
        consentData: null,
        loading: true,
      };
    }

    case GET_CONSENT_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case SELECT_CONSENT_MANAGEMENT_FORM: {
      return {
        ...state,
        selectFormKey: action.key,
      };
    }
    case TOGGLE_UNIVERSAL_CONSENT_MANAGEMENT_HISTORY: {
      return {
        ...state,
        consentHistory: {
          visible: !state.consentHistory.visible,
          data: action?.payload ?? null,
        },
      };
    }
    case ON_SEARCH_CONSENT_MANAGEMENT: {
      return {
        ...state,
        loading: true,
        search: action.search,
      };
    }
    case ON_SEARCH_CONSENT_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        listSearchConsent: action.payload?.listConsent ?? null,
        nextPageToken: action.payload?.nextPageToken ?? null,
      };
    }
    default: {
      return state;
    }
  }
};

export default ConsentManagementReducer;
