import {
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_SUCCESS,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE_SUCCESS,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE_FAILURE,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_FAILED,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_NEXT_BUTTON,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_BACK_BUTTON,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS,
  MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_MODAL,
  MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_ERROR,
  SEARCH_DATA_REQUEST,
  SEARCH_DATA_REQUEST_SUCCESS,
  SEARCH_DATA_REQUEST_ERROR,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";
import { replaceObject } from "util/arrayUtils";

const initialState = {
  loading: false,
  stage: null,
  listRequest: null,
  nextPageToken: null,
  requestId: null,
  userKey: null,
  taskModal: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
  nextStageButtonProps: {
    loading: false,
    disabled: false,
  },
  backStageButtonProps: {
    loading: false,
    disabled: false,
  },
  task: {
    loading: false,
    listTask: null,
    nextPageToken: null,
  },
};

const RequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE_SUCCESS: {
      return {
        ...state,
        listRequest: [...state.listRequest, ...action.payload.items],
        nextPageToken: action.payload?.nextPageToken ?? null,
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE_FAILURE: {
      return {
        ...state,
        nextPageToken: null,
      };
    }
    case GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST: {
      return {
        ...state,
        requestId: action.requestId,
        userKey: action.userKey,
        loading: true,
      };
    }
    case GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        stage: action.stage,
        loading: false,
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        nextStageButtonProps: initialState.nextStageButtonProps,
        backStageButtonProps: initialState.backStageButtonProps,
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_FAILED: {
      return {
        ...state,
        nextStageButtonProps: initialState.nextStageButtonProps,
        backStageButtonProps: initialState.backStageButtonProps,
      };
    }
    case TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_NEXT_BUTTON: {
      return {
        ...state,
        nextStageButtonProps: {
          ...state.nextStageButtonProps,
          loading: true,
        },
        backStageButtonProps: {
          ...state.backStageButtonProps,
          disabled: true,
        },
      };
    }
    case TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_BACK_BUTTON: {
      return {
        ...state,
        nextStageButtonProps: {
          ...state.nextStageButtonProps,
          disabled: true,
        },
        backStageButtonProps: {
          ...state.backStageButtonProps,
          loading: true,
        },
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK: {
      return {
        ...state,
        task: {
          ...state.task,
          loading: true,
        },
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS: {
      return {
        ...state,
        task: {
          ...state.task,
          ...action.payload,
          loading: false,
        },
      };
    }
    case MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK: {
      return {
        ...state,
        taskModal: {
          ...state.taskModal,
          okButtonProps: {
            ...state.taskModal.okButtonProps,
            loading: true,
          },
        },
      };
    }
    case CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS: {
      return {
        ...state,
        taskModal: initialState.taskModal,
        task: {
          ...state.task,
          listTask: state.task.listTask && [
            action.payload,
            ...state.task.listTask,
          ],
        },
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS: {
      return {
        ...state,
        taskModal: initialState.taskModal,
        task: {
          ...state.task,
          listTask:
            state.task.listTask &&
            replaceObject(
              state.task.listTask,
              action.payload.ObjectUUID,
              action.payload
            ),
        },
      };
    }
    case TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_MODAL: {
      return {
        ...state,
        taskModal: {
          ...initialState.taskModal,
          data: action.payload ?? null,
          visible: !state.taskModal.visible,
        },
      };
    }
    case MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_ERROR: {
      return {
        ...state,
        taskModal: initialState.taskModal,
      };
    }
    case SEARCH_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
        search: action.search,
      };
    }
    case SEARCH_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        listsearchRequest: action.payload?.listRequest ?? null,
        nextPageToken: action.payload?.nextPageToken ?? null,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default RequestReducer;
