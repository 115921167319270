import {
  GET_CONSENT_MANAGEMENT_DASHBOARD,
  GET_CONSENT_MANAGEMENT_DASHBOARD_SUCCESS,
  CHANGE_CONSENT_MANAGEMENT_DASHBOARD_FILTER,
  GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT_SUCCESS,
  GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT_FAILURE,
  GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE,
  GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE_SUCCESS,
} from "../../constants/PrivacyKitConsentManagement";
import dayjs from "dayjs";

const initialState = {
  loading: false,
  filter: {
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  },
  receipt: {
    listReceipt: null,
    nextPageToken: null,
  },
  processingPurpose: {
    currentPage: null,
    totalPage: null,
    prevPage: null,
    dashboard: [],
  },
};

const DashboardReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONSENT_MANAGEMENT_DASHBOARD: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CONSENT_MANAGEMENT_DASHBOARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...action.data,
      };
    }
    case CHANGE_CONSENT_MANAGEMENT_DASHBOARD_FILTER: {
      return {
        ...state,
        filter: action.filter,
        receipt: initialState.receipt,
      };
    }
    case GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT_SUCCESS: {
      return {
        ...state,
        receipt: action.receipt,
      };
    }
    case GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT_FAILURE: {
      return {
        ...state,
        receipt: {
          ...state.receipt,
          nextPageToken: null,
        },
      };
    }
    case GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE: {
      return {
        ...state,
        processingPurpose: {
          ...state.processingPurpose,
          currentPage: action.currentPage,
        },
      };
    }
    case GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE_SUCCESS: {
      return {
        ...state,
        processingPurpose: {
          ...state.processingPurpose,
          dashboard: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default DashboardReducers;
