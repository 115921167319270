import fetch from "util/Api";
import { PRIVACYKIT_DATA_MAPPING_BASE_URL as endpoint } from "config/EndpointConfig";

const DataWarehouseServices = {};

DataWarehouseServices.listDataWareHouse = async () =>
  await fetch({
    url: `${endpoint}/inventory/data_elements`,
    method: "GET",
  });

DataWarehouseServices.createDataWareHouse = async (data) =>
  await fetch({
    url: `${endpoint}/inventory/data_elements`,
    method: "POST",
    data,
  });

DataWarehouseServices.getDataWareHouse = async (dataWarehouseId) =>
  await fetch({
    url: `${endpoint}/inventory/data_elements/${dataWarehouseId}`,
    method: "GET",
  });

DataWarehouseServices.updateDataWareHouse = async (data) =>
  await fetch({
    url: `${endpoint}/inventory/data_elements/${data.ObjectUUID}`,
    method: "PUT",
    data,
  });

DataWarehouseServices.deleteDataWareHouse = async (dataWarehouseId) =>
  await fetch({
    url: `${endpoint}/inventory/data_elements/${dataWarehouseId}`,
    method: "DELETE",
  });

DataWarehouseServices.listDataWareHouseGroup = async () =>
  await fetch({
    url: `${endpoint}/inventory/data_elements/group?group_name=all`,
    method: "GET",
  });

export default DataWarehouseServices;
