export const selectAssessmentAutomation = (state) =>
  state.privacykitAssessmentAutomation

export const selectTypeAssessmentAutomation = (state) =>
  state.privacykitAssessmentAutomation.assessmentType;

export const selectAssessmentAutomationId = (state) =>
  state.privacykitAssessmentAutomation.assessmentId;

export const selectAssessmentAutomationDataRisk = (state) =>
  state.privacykitAssessmentAutomation.assessmentData.assessment_risk;
