import {
  CHANGE_COOKIE_CONSENT_MANAGEMENT_DOMAIN,
  GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD,
  GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_SUCCESS,
  CHANGE_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_FILTER,
  LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
  GET_COOKIE_CONSENT_MANAGEMENT_DETAIL_SUCCESS,
  CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
  SCAN_COOKIES_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
  UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
  DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS,
  UPDATE_COOKIES_CONSENT_MANAGEMENT_CATEGORY_SUCCESS,
} from "../constants/PrivacyKitCookieConsentManagement";
import _ from "lodash";
import dayjs from "dayjs";

const initState = {
  dashboard: {
    loading: false,
    filter: {
      startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
    },
  },
};

const PrivacyKitCookieConsentManagement = (state = initState, action) => {
  switch (action.type) {
    case LIST_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS: {
      return {
        ...state,
        domain: action.payload,
      };
    }
    case CHANGE_COOKIE_CONSENT_MANAGEMENT_DOMAIN: {
      return {
        ...state,
        dashboard: initState.dashboard,
      };
    }
    case CHANGE_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_FILTER: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          filter: action.payload,
        },
      };
    }
    case GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: true,
        },
      };
    }
    case GET_COOKIE_CONSENT_MANAGEMENT_DASHBOARD_SUCCESS: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          ...action.payload,
          loading: false,
        },
      };
    }
    case GET_COOKIE_CONSENT_MANAGEMENT_DETAIL_SUCCESS: {
      return {
        ...state,
        domainDetail: action.payload,
      };
    }
    case CREATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS: {
      return {
        ...state,
        domain: {
          ...state.domain,
          body: [action.payload, ...state.domain.body],
        },
      };
    }
    case SCAN_COOKIES_CONSENT_MANAGEMENT_DOMAIN_SUCCESS: {
      const updatedDomain = [...state.domain.body];
      const updateIndex = _.findIndex(updatedDomain, {
        key: action.payload.domain_key,
      });
      updatedDomain[updateIndex] = {
        ...updatedDomain[updateIndex],
        ...action.payload,
      };
      return {
        ...state,
        domain: {
          ...state.domain,
          body: updatedDomain,
        },
      };
    }
    case UPDATE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS: {
      const updatedDomain = [...state.domain.body];
      const updateIndex = _.findIndex(updatedDomain, {
        key: action.payload.key,
      });
      updatedDomain[updateIndex] = action.payload;
      return {
        ...state,
        domain: {
          ...state.domain,
          body: updatedDomain,
        },
      };
    }
    case DELETE_COOKIE_CONSENT_MANAGEMENT_DOMAIN_SUCCESS: {
      return {
        ...state,
        domain: {
          ...state.domain,
          body: _.filter(
            state.domain.body,
            ({ key }) => key !== action.payload
          ),
        },
      };
    }
    case UPDATE_COOKIES_CONSENT_MANAGEMENT_CATEGORY_SUCCESS: {
      return {
        ...state,
        domainDetail: {
          ...state.domainDetail,
          ...action.payload,
          // data: {
          //   ...state.domainDetail.data,
          //   ...action.payload.data,
          // },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default PrivacyKitCookieConsentManagement;
