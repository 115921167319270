import {
  LIST_CONSENT_MANAGEMENT,
  LIST_CONSENT_MANAGEMENT_SUCCESS,
  LIST_CONSENT_MANAGEMENT_NEXT_PAGE,
  LIST_CONSENT_MANAGEMENT_NEXT_PAGE_SUCCESS,
  LIST_CONSENT_MANAGEMENT_NEXT_PAGE_ERROR,
  GET_CONSENT_MANAGEMENT,
  GET_CONSENT_MANAGEMENT_SUCCESS,
  SELECT_CONSENT_MANAGEMENT_FORM,
  TOGGLE_UNIVERSAL_CONSENT_MANAGEMENT_HISTORY,
  ON_SEARCH_CONSENT_MANAGEMENT,
  ON_SEARCH_CONSENT_MANAGEMENT_SUCCESS,
} from "../../constants/PrivacyKitConsentManagement";

export const listConsentManagement = () => ({
  type: LIST_CONSENT_MANAGEMENT,
});

export const listConsentManagementSuccess = (payload) => ({
  type: LIST_CONSENT_MANAGEMENT_SUCCESS,
  payload,
});

export const listConsentManagementNextPage = (nextPageToken) => ({
  type: LIST_CONSENT_MANAGEMENT_NEXT_PAGE,
  nextPageToken,
});

export const listConsentManagementNextPageSuccess = (payload) => ({
  type: LIST_CONSENT_MANAGEMENT_NEXT_PAGE_SUCCESS,
  payload,
});

export const listConsentManagementNextPageError = () => ({
  type: LIST_CONSENT_MANAGEMENT_NEXT_PAGE_ERROR,
});

export const getConsentManagement = ({ consentId, collectionId }) => ({
  type: GET_CONSENT_MANAGEMENT,
  consentId,
  collectionId,
});

export const getConsentManagementSuccess = (payload) => ({
  type: GET_CONSENT_MANAGEMENT_SUCCESS,
  payload,
});

export const onSelectConsentManagementForm = (key) => ({
  type: SELECT_CONSENT_MANAGEMENT_FORM,
  key,
});

export const toggleConsentManagementHistory = (payload) => ({
  type: TOGGLE_UNIVERSAL_CONSENT_MANAGEMENT_HISTORY,
  payload,
});

export const onSearchConsentManagement = (search) => ({
  type: ON_SEARCH_CONSENT_MANAGEMENT,
  search,
});

export const onSearchConsentManagementSuccess = (payload) => ({
  type: ON_SEARCH_CONSENT_MANAGEMENT_SUCCESS,
  payload,
});
