import {
  GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD_SUCCESS,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD_FAILURE,
} from "../../constants/PrivacyKitPrivacyAssessmentAutomation";

const initialState = {
  loading: false,
  afterRisk: null,
  beforeRisk: null,
  inventoryAsset: null,
};

const DashboardReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD:
      return {
        ...state,
        loading: true,
      };
    case GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default DashboardReducers;
