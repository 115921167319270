import { takeEvery, call, put, all } from "redux-saga/effects";
import { GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD } from "../../constants/PrivacyKitDataSubjectRightManagementNew";
import { getDataSubjectRightManagementDashboardSuccess } from "../../actions/PrivacyKitDataSubjectRightManagement";
import DataSubjectRightManagementService from "services/pdpakit/DataSubjectRightManagementService";

export function* getDataSubjectRightManagementDashboard() {
  yield takeEvery(
    GET_DATA_SUBJECT_RIGHT_MANAGEMENT_DASHBOARD,
    function* ({ filter }) {
      try {
        const [dashboard, request] = yield all([
          call(DataSubjectRightManagementService.getDashboard, filter),
          call(DataSubjectRightManagementService.getDashboardRequest, filter),
        ]);
        yield put(
          getDataSubjectRightManagementDashboardSuccess({
            data: dashboard.body,
            requests: request.body.items,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );
}
