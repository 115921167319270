export const INIT_COMPLIANT_ASSESSMENT = "INIT_COMPLIANT_ASSESSMENT";
export const CHANGE_COMPLIANT_ASSESSMENT_BUILDER =
  "CHANGE_COMPLIANT_ASSESSMENT_BUILDER";
export const LIST_COMPLIANT_ASSESSMENT = "LIST_COMPLIANT_ASSESSMENT";
export const LIST_COMPLIANT_ASSESSMENT_SUCCESS =
  "LIST_COMPLIANT_ASSESSMENT_SUCCESS";
export const LIST_COMPLIANT_ASSESSMENT_ERROR =
  "LIST_COMPLIANT_ASSESSMENT_ERROR";
export const GET_COMPLIANT_ASSESSMENT = "GET_COMPLIANT_ASSESSMENT";
export const GET_COMPLIANT_ASSESSMENT_SUCCESS =
  "GET_COMPLIANT_ASSESSMENT_SUCCESS";
export const GET_COMPLIANT_ASSESSMENT_ERROR = "GET_COMPLIANT_ASSESSMENT_ERROR";
export const DELETE_COMPLIANT_ASSESSMENT_SUCCESS =
  "DELETE_COMPLIANT_ASSESSMENT_SUCCESS";
export const LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE = "LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE";
export const LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE_SUCCESS = "LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE_SUCCESS";
export const LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE_FAILED = "LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE_FAILED";
export const GET_COMPLIANT_ASSESSMENT_TEMPLATE_DATA = "GET_COMPLIANT_ASSESSMENT_TEMPLATE_DATA";
export const GET_COMPLIANT_ASSESSMENT_TEMPLATE_DATA_SUCCESS = "GET_COMPLIANT_ASSESSMENT_TEMPLATE_DATA_SUCCESS";
export const GET_COMPLIANT_ASSESSMENT_TEMPLATE_DATA_FAILED = "GET_COMPLIANT_ASSESSMENT_TEMPLATE_DATA_FAILED";



//COMPLIANCE PLAN
export const LIST_COMPLIANCE_PLAN = "LIST_COMPLIANCE_PLAN";
export const LIST_COMPLIANCE_PLAN_SUCCESS = "LIST_COMPLIANCE_PLAN_SUCCESS";
export const LIST_COMPLIANCE_PLAN_FAILED = "LIST_COMPLIANCE_PLAN_FAILED";
export const CREATE_COMPLIANCE_PLAN = "CREATE_COMPLIANCE_PLAN";
export const CREATE_COMPLIANCE_PLAN_SUCCESS = "CREATE_COMPLIANCE_PLAN_SUCCESS";
export const CREATE_COMPLIANCE_PLAN_FAILED = "CREATE_COMPLIANCE_PLAN_FAILED";
export const DELETE_COMPLIANCE_PLAN = "DELETE_COMPLIANCE_PLAN";
export const DELETE_COMPLIANCE_PLAN_SUCCESS = "DELETE_COMPLIANCE_PLAN_SUCCESS";
export const DELETE_COMPLIANCE_PLAN_FAILED = "DELETE_COMPLIANCE_PLAN_FAILED";
export const GET_DETAIL_COMPLIANCE_PLAN = "GET_DETAIL_COMPLIANCE_PLAN";
export const GET_DETAIL_COMPLIANCE_PLAN_SUCCESS =
  "GET_DETAIL_COMPLIANCE_PLAN_SUCCESS";
export const GET_DETAIL_COMPLIANCE_PLAN_FAILED = "GET_DETAIL_COMPLIANCE_PLAN_FAILED";
export const UPDATE_COMPLIANCE_PLAN = "UPDATE_COMPLIANCE_PLAN";
export const UPDATE_COMPLIANCE_PLAN_SUCCESS =
  "UPDATE_COMPLIANCE_PLAN_SUCCESS";
export const UPDATE_COMPLIANCE_PLAN_FAILED = "UPDATE_COMPLIANCE_PLAN_FAILED";
