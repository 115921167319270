import {
  LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  SET_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  CREATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  CREATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  UPDATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  UPDATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  TOGGLE_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  TOGGLE_DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_ERROR,
} from "../../constants/PrivacyKitConsentManagement";

export const listConsentManagementProcessingPurpose = () => ({
  type: LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
});

export const listConsentManagementProcessingPurposeSuccess = (
  listProcessingPurpose
) => ({
  type: LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  listProcessingPurpose,
});

export const getConsentManagementProcessingPurpose = (processingPurposeId) => ({
  type: GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  processingPurposeId,
});

export const getConsentManagementProcessingPurposeSuccess = (data) => ({
  type: GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  data,
});

export const setManageConsentManagementProcessingPurpose = (values) => ({
  type: SET_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  values,
});

export const createConsentManagementProcessingPurpose = (data) => ({
  type: CREATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  data,
});

export const createConsentManagementProcessingPurposeSuccess = (data) => ({
  type: CREATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  data,
});

export const updateConsentManagementProcessingPurpose = (
  processingPurposeId,
  data
) => ({
  type: UPDATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  processingPurposeId,
  data,
});

export const updateConsentManagementProcessingPurposeSuccess = (
  processingPurposeId,
  data
) => ({
  type: UPDATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  processingPurposeId,
  data,
});

export const toggleManageConsentManagementProcessingPurpose = (
  processingPurposeId
) => ({
  type: TOGGLE_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  processingPurposeId,
});

export const toggleDeleteConsentManagementProcessingPurpose = (payload) => ({
  type: TOGGLE_DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  payload,
});

export const deleteConsentManagementProcessingPurpose = () => ({
  type: DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
});

export const deleteConsentManagementProcessingPurposeSuccess = (
  processingPurposeId
) => ({
  type: DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  processingPurposeId,
});

export const deleteConsentManagementProcessingPurposeError = () => ({
  type: DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_ERROR,
});
