export const selectCollectionPoint = (state) =>
  state.privacyKitConsentManagement.collectionPoint;

export const selectCollectionPointData = (state) =>
  state.privacyKitConsentManagement.collectionPoint.collectionPointData;

export const selectCollectionPointDataFormFields = (state) =>
  state.privacyKitConsentManagement.collectionPoint.collectionPointData
    .form_fields;

export const selectCollectionPointDataFormText = (state) =>
  state.privacyKitConsentManagement.collectionPoint.collectionPointData
    ?.form_text?.th;

export const selectCollectionPointDataMaskIdentify = (state) =>
  state.privacyKitConsentManagement.collectionPoint.maskIdentify;

export const selectCollectionPointDataDeleteSection = (state) =>
  state.privacyKitConsentManagement.collectionPoint.deleteSection;

export const selectCollectionPointDataDeleteSectionComponent = (state) =>
  state.privacyKitConsentManagement.collectionPoint.deleteSectionComponent;

export const selectCollectionPointDataFormStyle = (state) =>
  state.privacyKitConsentManagement.collectionPoint.collectionPointData
    ?.form_style;

export const selectCollectionPointSetting = (state) =>
  state.privacyKitConsentManagement.collectionPoint.collectionPointSetting;

export const selectCollectionPointStatus = (state) =>
  state.privacyKitConsentManagement.collectionPoint.collectionPointStatus;

export const selectCollectionPointPreview = (state) =>
  state.privacyKitConsentManagement.collectionPoint.collectionPointPreview;

export const selectCollectionPointGetScript = (state) =>
  state.privacyKitConsentManagement.collectionPoint.collectionPointGetScript;

export const selectListCollectionPointLabel = (state) =>
  state.privacyKitConsentManagement.collectionPoint.listLabel;

export const selectCollectionPointPreviewVersion = (state) =>
  state.privacyKitConsentManagement.collectionPoint.previewVersion;
