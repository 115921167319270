import { SettingUserPath } from "../AppModuleConfig";

const SettingUser = [
  {
    key: `setting_user_profile`,
    path: `${SettingUserPath}/profile`,
    title: "ตั้งค่าบัญชี",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `setting_user_security`,
    path: `${SettingUserPath}/security`,
    title: "ความปลอดภัย",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
];

export default SettingUser;
