export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_SUCCESS =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_SUCCESS";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE_SUCCESS =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE_SUCCESS";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE_FAILURE =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_NEXT_PAGE_FAILURE";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST";
export const GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_SUCCESS =
  "GET_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_SUCCESS";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_SUCCESS =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_SUCCESS";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_FAILED =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_FAILED";
export const TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_BACK_BUTTON =
  "TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_BACK_BUTTON";
export const TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_NEXT_BUTTON =
  "TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_STAGE_NEXT_BUTTON";

export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK";
export const LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS =
  "LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS";
export const MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK =
  "MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK";
export const CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS =
  "CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS";
export const UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS =
  "UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_SUCCESS";
export const MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_ERROR =
  "MANAGE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_ERROR";

export const TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_MODAL =
  "TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_REQUEST_TASK_MODAL";

export const SEARCH_DATA_REQUEST = "SEARCH_DATA_REQUEST";
export const SEARCH_DATA_REQUEST_SUCCESS = "SEARCH_DATA_REQUEST_SUCCESS";
