const NavigationSelectedKey = (pathname) => {
  let selectedKey = pathname.split("/");
  let selectedSubKey = pathname.split("/");
  let selectedKeyOrganization = pathname.split("/");
  selectedKey.length = 5;
  selectedSubKey.length = 6;
  selectedKeyOrganization.length = 4;
  return [
    selectedKey.join("/"),
    selectedSubKey.join("/"),
    pathname,
    selectedKeyOrganization.join("/"),
  ];
};

export default NavigationSelectedKey;
