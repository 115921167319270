import { saveToLS, getFromLS } from "util/LocalStorage";
import {
  SET_EVENT_LIST,
  SET_EVENT_IN_EVENT,
  SET_DETECTION_LIST,
  SET_AUDIT,
  SET_NOTIFICATION,
} from "redux/constants/CyberKitSiem";

const CYBERKIT = "CYBERKIT";
const CYBERKIT_SIEM = "CYBERKIT_SIEM";

const initState = {
  event: {
    dataList: [],
    eventInEvent: [],
  },
  detection: {
    dataList: [],
  },
  audit: {
    dataList: [],
  },
  notification: {
    dataList: [],
  },
};

const CyberKitSiem = (
  state = getFromLS(CYBERKIT, CYBERKIT_SIEM)
    ? getFromLS(CYBERKIT, CYBERKIT_SIEM)
    : initState,
  action
) => {
  const { type, payload } = action;
  let changed = state;

  switch (type) {
    // EVENT
    case SET_EVENT_LIST: {
      changed = {
        ...state,
        event: {
          ...state.event,
          dataList: payload,
        },
      };
      break;
    }

    case SET_EVENT_IN_EVENT: {
      changed = {
        ...state,
        event: {
          ...state.event,
          eventInEvent: payload,
        },
      };
      break;
    }

    // DETECTION
    case SET_DETECTION_LIST: {
      changed = {
        ...state,
        detection: {
          dataList: payload,
        },
      };
      break;
    }

    // AUDIT
    case SET_AUDIT: {
      changed = {
        ...state,
        audit: {
          dataList: payload,
        },
      };
      break;
    }

    // NOTIFICATION
    case SET_NOTIFICATION: {
      changed = {
        ...state,
        notification: {
          ...state.notification,
          dataList: payload,
        },
      };
      break;
    }

    default:
      break;
  }
  if (localStorage.getItem("auth") !== null)
    saveToLS(CYBERKIT, CYBERKIT_SIEM, changed);
  return changed;
};

export default CyberKitSiem;
