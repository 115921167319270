import {
  PrivacyKitPath,
  PrivacyKitDataBreachNotificationPath,
} from "../AppModuleConfig";

const PrivacyKitDataBreachNotification = [
  // {
  //   key: `privacykit-data-breach-notification-dashboard`,
  //   path: `${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}/dashboard`,
  //   title: "แดชบอร์ด",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
  {
    key: `privacykit-data-breach-notification-notification`,
    path: `${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}/notification`,
    title: "การแจ้งเตือน",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `privacykit-data-breach-notification-response-plan`,
    path: `${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}/response-plan`,
    title: "แผนการตอบสนอง",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `privacykit-data-breach-notification-webform`,
    path: `${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}/webform`,
    title: "รายการเว็บฟอร์ม",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `privacykit-data-breach-notification-event-register`,
    path: `${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}/event-register`,
    title: "ทะเบียนเหตุการณ์",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `privacykit-data-breach-notification-report`,
    path: `${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}/report`,
    title: "รายงาน สคส.",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `privacykit-data-breach-notification-task`,
    path: `${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}/task`,
    title: "การติดตามงานที่มอบหมาย",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
];

export default PrivacyKitDataBreachNotification;
