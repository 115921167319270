import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_TEMPLATE,
  CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELDS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION,
  GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";
import {
  listDataSubjectRightManagementWebFormSuccess,
  listDataSubjectRightManagementWebFormTemplateSuccess,
  createDataSubjectRightManagementWebFormSuccess,
  createDataSubjectRightManagementWebFormError,
  deleteDataSubjectRightManagementWebFormSuccess,
  deleteDataSubjectRightManagementWebFormError,
  getDataSubjectRightManagementWebFormSuccess,
  getDataSubjectRightManagementWebFormFieldsSuccess,
  updateDataSubjectRightManagementWebFormSuccess,
  updateDataSubjectRightManagementWebFormError,
  publishDataSubjectRightManagementWebFormError,
  listDataSubjectRightManagementWebFormVersionSuccess,
  listDataSubjectRightManagementWebFormVersionError,
  getDataSubjectRightManagementWebFormVersionSuccess,
  getDataSubjectRightManagementWebFormVersionError,
} from "../../actions/PrivacyKitDataSubjectRightManagement";
import {
  selectDataSubjectRightManagementWebForm,
  selectDataSubjectRightManagementWebFormData,
} from "../../selectors/PrivacyKitDataSubjectRightManagement";
import DataSubjectRightManagementService from "services/pdpakit/DataSubjectRightManagementService";
import { DEFAULT_LOGO } from "constants/ImagesSrc";
import Notification from "util/Notification";

export function* listDataSubjectRightManagementWebForm() {
  yield takeEvery(LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM, function* () {
    try {
      const response = yield call(
        DataSubjectRightManagementService.listWebForm
      );
      yield put(
        listDataSubjectRightManagementWebFormSuccess({
          listWebForm: response.body.items,
          nextPageToken: response.body.next_page_token,
        })
      );
    } catch (error) {
      console.error(error);
    }
  });
}

export function* listDataSubjectRightManagementWebFormTemplate() {
  yield takeEvery(
    LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_TEMPLATE,
    function* () {
      try {
        const response = yield call(
          DataSubjectRightManagementService.listWebFormTemplate,
          "th"
        );
        yield put(
          listDataSubjectRightManagementWebFormTemplateSuccess(
            response.body.item.template
          )
        );
      } catch (error) {
        console.error(error);
      }
    }
  );
}

const initWebForm = {
  name: "",
  lang: "th",
  form_fields: {
    form: [],
    visibility: [],
  },
  default: {
    approver: {},
  },
  version: 0,
  status: "Draft",
  form_text: [
    {
      name: "Welcome Text",
      text: {
        __html:
          "<p>ยินดีต้อนรับ! กรุณากรอกแบบฟอร์มนี้เพื่อส่งคำขอของคุณแล้วเราจะตอบกลับอย่างเร็วที่สุด</p>",
        position: "center",
        widget: "edittext",
        label: "Welcome Text",
      },
    },
    {
      name: "Footer Text",
      text: {
        __html: "<p></p>",
        position: "center",
        widget: "edittext",
        label: "Footer Text",
      },
    },
  ],
  form_style: {
    favicon: DEFAULT_LOGO,
    page_title: "",
    banner_logo: DEFAULT_LOGO,
    banner_logo_alt:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
};

export function* createDataSubjectRightManagementWebForm() {
  yield takeEvery(
    CREATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
    function* ({ values }) {
      try {
        const { webformTemplates } = yield select(
          selectDataSubjectRightManagementWebForm
        );
        const data = {
          ...initWebForm,
          ...values,
          approver: undefined,
          form_fields: {
            ...initWebForm.form_fields,
            form: webformTemplates[0].form,
          },
          default: {
            ...initWebForm.default,
            approver: {
              email: null,
            },
          },
          form_style: {
            ...initWebForm.form_style,
            page_title: values.name,
          },
        };
        const response = yield call(
          DataSubjectRightManagementService.createWebForm,
          data
        );
        yield put(
          createDataSubjectRightManagementWebFormSuccess(response.body)
        );
        Notification("success", "สร้างเว็บฟอร์มเรียบร้อย");
      } catch (error) {
        console.error(error);
        yield put(createDataSubjectRightManagementWebFormError());
        Notification("error", "ไม่สามารถสร้างเว็บฟอร์มได้");
      }
    }
  );
}

export function* deleteDataSubjectRightManagementWebForm() {
  yield takeEvery(
    DELETE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
    function* ({ payload }) {
      try {
        const { ObjectUUID, created_by } = payload;
        yield call(
          DataSubjectRightManagementService.deleteWebForm,
          ObjectUUID,
          created_by
        );
        yield put(deleteDataSubjectRightManagementWebFormSuccess(ObjectUUID));
        Notification("success", "ลบเว็บฟอร์มเรียบร้อย");
      } catch (error) {
        console.error(error);
        Notification("error", "เกิดข้อผิดพลาดไม่สามารถลบเว็บฟอร์มนี้ได้");
        yield put(deleteDataSubjectRightManagementWebFormError());
      }
    }
  );
}

export function* getDataSubjectRightManagementWebForm() {
  yield takeEvery(
    GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
    function* ({ webformId, userKey }) {
      try {
        const response = yield call(
          DataSubjectRightManagementService.getWebForm,
          webformId,
          userKey
        );
        yield put(getDataSubjectRightManagementWebFormSuccess(response.body));
      } catch (error) {
        console.error(error);
      }
    }
  );
}

export function* getDataSubjectRightManagementWebFormFields() {
  yield takeEvery(
    GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_FIELDS,
    function* () {
      try {
        const { body } = yield call(
          DataSubjectRightManagementService.getWebFormFields,
          "th"
        );
        yield put(getDataSubjectRightManagementWebFormFieldsSuccess(body.item));
      } catch (error) {
        console.error(error);
      }
    }
  );
}

export function* updateDataSubjectRightManagementWebForm() {
  yield takeEvery(
    UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
    function* ({ webFormId, userKey, values }) {
      try {
        const webformData = yield select(
          selectDataSubjectRightManagementWebFormData
        );
        const { body } = yield call(
          DataSubjectRightManagementService.updateWebForm,
          webFormId,
          userKey,
          {
            ...webformData,
            ...values,
            default: {
              ...webformData.default,
            },
          }
        );
        yield put(updateDataSubjectRightManagementWebFormSuccess(body));
        Notification("success", "แก้ไขเว็บฟอร์มเรียบร้อย");
      } catch (error) {
        console.error(error);
        Notification("error", "ไม่สามารถแก้ไขเว็บฟอร์มได้");
        yield put(updateDataSubjectRightManagementWebFormError());
      }
    }
  );
}

export function* publishDataSubjectRightManagementWebForm() {
  yield takeEvery(
    PUBLISH_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM,
    function* ({ values }) {
      try {
        const webformData = yield select(
          selectDataSubjectRightManagementWebFormData
        );
        yield call(
          DataSubjectRightManagementService.updateWebForm,
          webformData.ObjectUUID,
          webformData.created_by,
          {
            ...webformData,
            ...values,
          }
        );
        const { body } = yield call(
          DataSubjectRightManagementService.publishWebForm,
          webformData.ObjectUUID,
          webformData.created_by
        );
        yield put(updateDataSubjectRightManagementWebFormSuccess(body));
        Notification("success", "เผยแพร่เว็บฟอร์มเรียบร้อย");
      } catch (error) {
        console.error(error);
        Notification("error", "ไม่สามารถเผยแพร่เว็บฟอร์มได้");
        yield put(publishDataSubjectRightManagementWebFormError());
      }
    }
  );
}

export function* listDataSubjectRightManagementWebFormVersion() {
  yield takeEvery(
    LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION,
    function* () {
      try {
        const { ObjectUUID, created_by } = yield select(
          selectDataSubjectRightManagementWebFormData
        );
        const response = yield call(
          DataSubjectRightManagementService.listWebformVersion,
          ObjectUUID,
          created_by
        );
        yield put(
          listDataSubjectRightManagementWebFormVersionSuccess(response.body)
        );
      } catch (error) {
        yield put(listDataSubjectRightManagementWebFormVersionError());
      }
    }
  );
}

export function* getDataSubjectRightManagementWebFormVersion() {
  yield takeEvery(
    GET_DATA_SUBJECT_RIGHT_MANAGEMENT_WEBFORM_VERSION,
    function* ({ versionId }) {
      try {
        const { ObjectUUID, created_by } = yield select(
          selectDataSubjectRightManagementWebFormData
        );
        const response = yield call(
          DataSubjectRightManagementService.getWebformVersion,
          ObjectUUID,
          versionId,
          created_by
        );
        yield put(
          getDataSubjectRightManagementWebFormVersionSuccess(response.body)
        );
      } catch (error) {
        yield put(getDataSubjectRightManagementWebFormVersionError());
      }
    }
  );
}
