import enLang from "./entries/en-US";
import thLang from "./entries/th-TH";

export const loadLocaleData = (locale) => {
  switch (locale) {
    case "en": {
      return enLang;
    }
    default:
      return thLang;
  }
};

const AppLocale = {
  en: enLang,
  th: thLang,
};

export default AppLocale;
