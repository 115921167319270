import { all, takeEvery, put, fork, call, select } from "redux-saga/effects";
import {
  LIST_ASSESSMENT_AUTOMATION,
  CREATED_ASSESSMENT_AUTOMATION,
  UPDATE_ASSESSMENT_AUTOMATION,
  PUBLISH_ASSESSMENT_AUTOMATION,
  DELETE_ASSESSMENT_AUTOMATION,
  LIST_ASSESSMENT_AUTOMATION_ACTIVE,
  CREATE_ASSESSMENT_AUTOMATION_ACTIVE,
  UPDATE_ASSESSMENT_AUTOMATION_ACTIVE,
  GET_ASSESSMENT_AUTOMATION_DATA,
  ARCHIVED_ASSESSMENT_AUTOMATION_ACTIVE,
  DELETE_ASSESSMENT_AUTOMATION_ACTIVE,
  LIST_ASSESSMENT_AUTOMATION_ARCHIVED,
  ACTIVATED_ASSESSMENT_AUTOMATION_ARCHIVED,
  DELETE_ASSESSMENT_AUTOMATION_ARCHIVED,
  LIST_ASSESSMENT_AUTOMATION_DELETED,
  ACTIVATED_ASSESSMENT_AUTOMATION_DELETED,
  DELETE_ASSESSMENT_AUTOMATION_DELETED,
  LIST_ASSESSMENT_AUTOMATION_RISK,
  LIST_ASSESSMENT_AUTOMATION_TEMPLATE,
  GET_ASSESSMENT_AUTOMATION_TEMPLATE_TYPE,
  GET_ASSESSMENT_AUTOMATION_TEMPLATE_BY_TYPE,
  DELETE_ASSESSMENT_AUTOMATION_TEMPLATE,
  LIST_ASSESSMENT_AUTOMATION_PROCESS,
  CREATE_ASSESSMENT_AUTOMATION_PROCESS,
  GET_ASSESSMENT_AUTOMATION_PROCESS,
  DELETE_ASSESSMENT_AUTOMATION_PROCESS,
  UPDATE_ASSESSMENT_AUTOMATION_PROCESS,
  LIST_ASSESSMENT_AUTOMATION_RESULT,
  LIST_ASSESSMENT_AUTOMATION_RULES,
  CREATE_ASSESSMENT_AUTOMATION_RISK,
  UPDATE_ASSESSMENT_AUTOMATION_RISK,
  DELETE_ASSESSMENT_AUTOMATION_RISK,
  CREATE_ASSESSMENT_AUTOMATION_RESULT,
  UPDATE_ASSESSMENT_AUTOMATION_RESULT,
  DELETE_ASSESSMENT_AUTOMATION_RESULT,
  CREATE_ASSESSMENT_AUTOMATION_RULES,
  UPDATE_ASSESSMENT_AUTOMATION_RULES,
  DELETE_ASSESSMENT_AUTOMATION_RULES,
  GET_ASSESSMENT_AUTOMATION_ACTIVE,
  LIST_ASSESSMENT_AUTOMATION_NOT_SENT,
  CREATE_ASSESSMENT_AUTOMATION_NOT_SENT,
  UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT,
  DELETE_ASSESSMENT_AUTOMATION_NOT_SENT,
  UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT,
  LIST_ASSESSMENT_AUTOMATION_SENT,
  GET_ASSESSMENT_AUTOMATION_REPORT,
  GET_ASSESSMENT_AUTOMATION_REPORT_DATA,
  LIST_ASSESSMENT_AUTOMATION_SUBMIT_REPORT,
  GET_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_DATA,
  LIST_ASSESSMENT_AUTOMATION_TASK,
  LIST_ASSESSMENT_AUTOMATION_TASK_IN_ASSESSMENT,
  CREATE_ASSESSMENT_AUTOMATION_TASK,
  UPDATE_ASSESSMENT_AUTOMATION_TASK,
  DELETE_ASSESSMENT_AUTOMATION_TASK,
  GET_ASSESSMENT_AUTOMATION_DATA_INVENTORY,
  ADD_ASSESSMENT_CONTROL,
  GET_ASSESSMENT_CONTROL,
  DELETE_ASSESSMENT_CONTROL,
  LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE,
  LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE,
  LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE,
} from "../constants/PrivacyKitAssessmentAutomation";
import {
  listAssessmentAutomationSuccess,
  listAssessmentAutomationFailed,
  createAssessmentAutomationSuccess,
  updateAssessmentAutomationSuccess,
  publishAssessmentAutomationSuccess,
  deleteAssessmentAutomationSuccess,
  getAssessmentAutomationDataSuccess,
  listAssessmentAutomationActiveSuccess,
  createAssessmentAutomationActiveSuccess,
  updateAssessmentAutomationActiveSuccess,
  archivedAssessmentAutomationActiveSuccess,
  deleteAssessmentAutomationActiveSuccess,
  listAssessmentAutomationArchivedSuccess,
  activatedAssessmentAutomationArchivedSuccess,
  deleteAssessmentAutomationArchivedSuccess,
  listAssessmentAutomationDeletedSuccess,
  activatedAssessmentAutomationDeletedSuccess,
  deleteAssessmentAutomationDeletedSuccess,
  listAssessmentAutomationRiskSuccess,
  listAssessmentAutomationTemplateSuccess,
  listAssessmentAutomationTemplateFailed,
  getAssessmentAutomationTemplateTypeSuccess,
  getAssessmentAutomationTemplateByTypeSuccess,
  deleteAssessmentAutomationTemplateSuccess,
  listAssessmentAutomationProcessSuccess,
  createAssessmentAutomationProcessSuccess,
  deleteAssessmentAutomationProcessSuccess,
  getAssessmentAutomationProcessSuccess,
  getAssessmentAutomationProcessFailed,
  updateAssessmentAutomationProcessSuccess,
  listAssessmentAutomationResultSuccess,
  listAssessmentAutomationRulesSuccess,
  createAssessmentAutomationRiskSuccess,
  updateAssessmentAutomationRiskSuccess,
  deleteAssessmentAutomationRiskSuccess,
  createAssessmentAutomationResultSuccess,
  updateAssessmentAutomationResultSuccess,
  deleteAssessmentAutomationResultSuccess,
  createAssessmentAutomationRulesSuccess,
  updateAssessmentAutomationRulesSuccess,
  deleteAssessmentAutomationRulesSuccess,
  getAssessmentAutomationActiveByIDSuccess,
  listAssessmentAutomationNotSentSuccess,
  listAssessmentAutomationNotSentFailed,
  createAssessmentAutomationNotSentSuccess,
  createAssessmentAutomationNotSentFailed,
  updateAssessmentAutomationNotSentSuccess,
  updateAssessmentAutomationNotSentFailed,
  deleteAssessmentAutomationNotSentSuccess,
  deleteAssessmentAutomationNotSentFailed,
  updateAssessmentAutomationNotSentToSentSuccess,
  updateAssessmentAutomationNotSentToSentFailed,
  listAssessmentAutomationSentSuccess,
  listAssessmentAutomationSentFailed,
  getAssessmentAutomationReportSuccess,
  getAssessmentAutomationReportDataSuccess,
  listAssessmentAutomationSubmitReportSuccess,
  getAssessmentAutomationSubmitReportDataSuccess,
  listAssessmentAutomationTaskSuccess,
  listAssessmentAutomationInAssessmentSuccess,
  createAssessmentAutomationTaskSuccess,
  updateAssessmentAutomationTaskSuccess,
  deleteAssessmentAutomationTaskSuccess,
  getAssessmentDataInventorySuccess,
  getAssessmentDataInventoryError,
  addAssessmentControlFailed,
  addAssessmentControlSuccess,
  getAssessmentControlSuccess,
  deleteAssessmentControlSucces,
  listAssessmentAutomationSentNextPageSuccess,
  listAssessmentAutomationNotSentNextPageSuccess,
  listAssessmentAutomationTaskNextPageSuccess,
} from "../actions/PrivacyKitAssessmentAutomation";
import AssessmentAutomationServices from "services/pdpakit/AssessmentAutomationServices";
import DataMappingService from "services/pdpakit/DataMappingService";
import _ from "lodash";
import Notification from "util/Notification";
import {
  selectAssessmentAutomation,
  selectAssessmentAutomationDataRisk,
  selectTypeAssessmentAutomation,
} from "../selectors/PrivacyKitAssessmentAutomation";

function* listAssessmentAutomation() {
  yield takeEvery(LIST_ASSESSMENT_AUTOMATION, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.listAssessmentByType,
        payload
      );

      if (response.statusCode === 200) {
        yield put(listAssessmentAutomationSuccess(response));
      } else {
        yield put(listAssessmentAutomationFailed(response));
      }
    } catch (error) {
      yield put(listAssessmentAutomationFailed(error));
    }
  });
}

function* createAssessmentAutomation() {
  yield takeEvery(
    CREATED_ASSESSMENT_AUTOMATION,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const assessmentType = yield select(selectTypeAssessmentAutomation);
        const template =
          assessmentType === "vendor"
            ? yield call(AssessmentAutomationServices.getTemplateData, {
                assessmentType: "it-vendor-cyberkit",
                assessment_template_type: "it_vendor_template",
                assessment_template_id: "ability_manage_risks",
              })
            : payload.form;
        const response = yield call(
          AssessmentAutomationServices.createAssessment,
          { ...payload, form: template?.Item?.form ?? undefined }
        );

        if (response.statusCode === 200) {
          yield put(createAssessmentAutomationSuccess(response.data));
          Notification("success", "สร้างแบบประเมินเรียบร้อยแล้ว");
          onFinish(response);
        } else if (response.errorCode === "CreateNotAllowed") {
          Notification("error", "เกิดข้อผิดพลาด แบบประเมินนี้ถูกสร้างแล้ว");
          onFailed();
        } else {
          Notification("error", "เกิดข้อผิดพลาด ไม่สามารถสร้างแบบประเมินได้");
          onFailed();
        }
      } catch (error) {
        Notification("error", "เกิดข้อผิดพลาด ไม่สามารถสร้างแบบประเมินได้");
        onFailed();
      }
    }
  );
}

function* updateAssessmentAutomation() {
  yield takeEvery(
    UPDATE_ASSESSMENT_AUTOMATION,
    function* ({ payload, onFinish }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.updateAssessment,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            updateAssessmentAutomationSuccess({
              ...payload,
              data: response.data,
            })
          );
          Notification(
            "success",
            "notification.assessmentAutomation.update.success"
          );
          onFinish();
        } else {
          Notification("error", "notification.internalServerError");
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* publishAssessmentAutomation() {
  yield takeEvery(
    PUBLISH_ASSESSMENT_AUTOMATION,
    function* ({ payload, onFinish, onFailed }) {
      const { assessmentId, assessmentType } = yield select(
        selectAssessmentAutomation
      );
      try {
        const response = yield call(
          AssessmentAutomationServices.updateAssessment,
          payload
        );
        const Pubresponse = yield call(
          AssessmentAutomationServices.publishAssessment,
          assessmentType,
          payload.assessmentId
        );
        if (assessmentType === "vendor") {
          yield put({
            type: GET_ASSESSMENT_CONTROL,
            assessment_type: assessmentType,
            link_type: "control",
            assessment_id: assessmentId,
          });
        }

        yield put(publishAssessmentAutomationSuccess(Pubresponse.data));
        Notification("success", "เผยแพร่แบบประเมินของคุณสำเร็จแล้ว");
        onFinish();
      } catch (error) {
        onFailed();
        console.log(error);
      }
    }
  );
}

function* deleteAssessmentAutomation() {
  yield takeEvery(
    DELETE_ASSESSMENT_AUTOMATION,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const assessmentType = yield select(selectTypeAssessmentAutomation);
        const response = yield call(
          AssessmentAutomationServices.deleteAssessment,
          { ...payload, assessmentType }
        );
        if (response.statusCode === 200) {
          yield put(deleteAssessmentAutomationSuccess(payload.assessmentId));
          Notification(
            "success",
            "notification.assessmentAutomation.delete.success"
          );
          onFinish();
        } else {
          if (onFailed) {
            onFailed();
          }
          Notification("error", "notification.internalServerError");
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* getAssessmentAutomationData() {
  yield takeEvery(GET_ASSESSMENT_AUTOMATION_DATA, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.getAssessmentById,
        payload
      );

      if (response.statusCode === 200) {
        yield put(getAssessmentAutomationDataSuccess(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

//ASSESSMENT ACTIVE
function* listAssessmentAutomationActive() {
  yield takeEvery(LIST_ASSESSMENT_AUTOMATION_ACTIVE, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.listAssessmentActive,
        payload
      );

      if (response.statusCode === 200) {
        yield put(listAssessmentAutomationActiveSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* createAssessmentAutomationActive() {
  yield takeEvery(
    CREATE_ASSESSMENT_AUTOMATION_ACTIVE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.createAssessmentActive,
          payload
        );

        if (response.statusCode === 200 && response.data) {
          yield put(createAssessmentAutomationActiveSuccess(response.data));
          Notification(
            "success",
            "notification.assessmentAutomation.update.success"
          );
          onFinish();
        } else {
          onFailed(response);
          Notification("error", "notification.internalServerError");
        }
      } catch (error) {
        console.log(error);
        onFailed(error);
      }
    }
  );
}

function* getAssessmentAutomationActiveByID() {
  yield takeEvery(
    GET_ASSESSMENT_AUTOMATION_ACTIVE,
    function* ({ payload, assessmentId }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.getAssessmentActiveByID,
          payload,
          assessmentId
        );

        if (response.statusCode === 200) {
          yield put(getAssessmentAutomationActiveByIDSuccess(response));
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* updateAssessmentAutomationActive() {
  yield takeEvery(
    UPDATE_ASSESSMENT_AUTOMATION_ACTIVE,
    function* ({ payload, onFinish, onFailed, ObjectUUID }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.updateAssessmentActive,
          payload,
          ObjectUUID
        );
        if (response.statusCode === 200) {
          yield put(updateAssessmentAutomationActiveSuccess(response.data));
          Notification(
            "success",
            "notification.assessmentAutomation.update.success"
          );
          onFinish();
        } else {
          onFailed();
          Notification("error", "notification.internalServerError");
        }
      } catch (err) {
        console.log(err);
        onFailed(err);
      }
    }
  );
}

function* archivedAssessmentAutomationActive() {
  yield takeEvery(
    ARCHIVED_ASSESSMENT_AUTOMATION_ACTIVE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.archivedAssessmentActive,
          payload
        );
        if (response.statusCode === 200) {
          yield put(archivedAssessmentAutomationActiveSuccess(response.data));
          onFinish();
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* deleteAssessmentAutomationActive() {
  yield takeEvery(
    DELETE_ASSESSMENT_AUTOMATION_ACTIVE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.deleteAssessmentActive,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteAssessmentAutomationActiveSuccess(response.data));
          Notification(
            "success",
            "notification.assessmentAutomation.delete.success"
          );
          onFinish();
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

//ASSESSMENT ARCHIVED
function* listAssessmentAutomationArchived() {
  yield takeEvery(LIST_ASSESSMENT_AUTOMATION_ARCHIVED, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.listAssessmentArchived,
        payload
      );

      if (response.statusCode === 200) {
        yield put(listAssessmentAutomationArchivedSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* activatedAssessmentAutomationArchived() {
  yield takeEvery(
    ACTIVATED_ASSESSMENT_AUTOMATION_ARCHIVED,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.activatedAssessmentArchived,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            activatedAssessmentAutomationArchivedSuccess(response.data)
          );
          onFinish();
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* deleteAssessmentAutomationArchived() {
  yield takeEvery(
    DELETE_ASSESSMENT_AUTOMATION_ARCHIVED,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.deleteAssessmentArchived,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteAssessmentAutomationArchivedSuccess(response.data));
          Notification(
            "success",
            "notification.assessmentAutomation.delete.success"
          );
          onFinish();
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

//ASSESSMENT DELETED
function* listAssessmentAutomationDeleted() {
  yield takeEvery(LIST_ASSESSMENT_AUTOMATION_DELETED, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.listAssessmentDeleted,
        payload
      );

      if (response.statusCode === 200) {
        yield put(listAssessmentAutomationDeletedSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* activatedAssessmentAutomationDeleted() {
  yield takeEvery(
    ACTIVATED_ASSESSMENT_AUTOMATION_DELETED,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.activatedAssessmentDeleted,
          payload
        );
        if (response.statusCode === 200) {
          yield put(activatedAssessmentAutomationDeletedSuccess(response.data));
          onFinish();
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* deleteAssessmentAutomationDeleted() {
  yield takeEvery(
    DELETE_ASSESSMENT_AUTOMATION_DELETED,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.deleteAssessmentDeleted,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteAssessmentAutomationDeletedSuccess(payload));
          Notification(
            "success",
            "notification.assessmentAutomation.delete.success"
          );
          onFinish();
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

//ASSESSMENT RISK
function* listAssessmentAutomationRisk() {
  yield takeEvery(LIST_ASSESSMENT_AUTOMATION_RISK, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.listAssessmentRisk,
        payload
      );

      if (response.statusCode === 200) {
        yield put(listAssessmentAutomationRiskSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* createAssessmentAutomationRisk() {
  yield takeEvery(
    CREATE_ASSESSMENT_AUTOMATION_RISK,
    function* ({ payload, onFinish, onFailed }) {
      console.log("pay", payload);
      try {
        const response = yield call(
          AssessmentAutomationServices.createAssessmentRisk,
          payload
        );
        // const inventory = yield call(
        //   DataMappingService.getDataInventory,
        //   dataInventoryId
        // );

        if (response.statusCode === 200 && response.data) {
          yield put(createAssessmentAutomationRiskSuccess(response.data));
          Notification(
            "success",
            "notification.assessmentAutomation.create.success"
          );
          onFinish();
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (error) {
        console.log(error);
        onFailed(error);
      }
    }
  );
}

function* updateAssessmentAutomationRisk() {
  yield takeEvery(
    UPDATE_ASSESSMENT_AUTOMATION_RISK,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.updateAssessmentRisk,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateAssessmentAutomationRiskSuccess(response));
          Notification(
            "success",
            "notification.assessmentAutomation.update.success"
          );
          onFinish(response.data);
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (err) {
        onFailed(err);
      }
    }
  );
}

function* deleteAssessmentAutomationRisk() {
  yield takeEvery(
    DELETE_ASSESSMENT_AUTOMATION_RISK,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.deleteAssessmentRisk,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteAssessmentAutomationRiskSuccess(payload));
          Notification(
            "success",
            "notification.assessmentAutomation.delete.success"
          );
          onFinish();
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

//ASSESSMENT TEMPLATE
function* listAssessmentAutomationTemplate() {
  yield takeEvery(LIST_ASSESSMENT_AUTOMATION_TEMPLATE, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.listTemplate,
        payload
      );
      if (response.statusCode === 200 && _.isArray(response.data)) {
        yield put(listAssessmentAutomationTemplateSuccess(response));
      } else {
        yield put(listAssessmentAutomationTemplateFailed(response));
      }
    } catch (error) {
      yield put(listAssessmentAutomationTemplateFailed(error));
    }
  });
}

function* getAssessmentAutomationTemplateType() {
  yield takeEvery(
    GET_ASSESSMENT_AUTOMATION_TEMPLATE_TYPE,
    function* ({ payload }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.listTemplateType,
          payload
        );
        if (response.statusCode === 200) {
          yield put(getAssessmentAutomationTemplateTypeSuccess(response?.data));
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* getAssessmentAutomationTemplateByType() {
  yield takeEvery(
    GET_ASSESSMENT_AUTOMATION_TEMPLATE_BY_TYPE,
    function* ({ payload }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.listTemplateByType,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            getAssessmentAutomationTemplateByTypeSuccess(response.data)
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* deleteAssessmentAutomationTemplate() {
  yield takeEvery(
    DELETE_ASSESSMENT_AUTOMATION_TEMPLATE,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.deleteAssessmentTemplate,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            deleteAssessmentAutomationTemplateSuccess(payload.ObjectUUID)
          );
          Notification(
            "success",
            "notification.assessmentAutomation.delete.success"
          );
          onFinish(response);
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* listAssessmentAutomationProcess() {
  yield takeEvery(LIST_ASSESSMENT_AUTOMATION_PROCESS, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.listAssessmentProcess,
        payload
      );

      if (response.statusCode === 200) {
        yield put(listAssessmentAutomationProcessSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* createAssessmentAutomationProcess() {
  yield takeEvery(
    CREATE_ASSESSMENT_AUTOMATION_PROCESS,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.createAssessmentProcess,
          payload
        );

        if (response.statusCode === 200 && response.data) {
          yield put(createAssessmentAutomationProcessSuccess(response.data));
          Notification(
            "success",
            "notification.assessmentAutomation.create.success"
          );
          onFinish();
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (error) {
        console.log(error);
        onFailed(error);
      }
    }
  );
}

function* getAssessmentAutomationProcess() {
  yield takeEvery(GET_ASSESSMENT_AUTOMATION_PROCESS, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.getAssessmentProcess,
        payload
      );
      if (response.statusCode === 200 && response.data) {
        yield put(getAssessmentAutomationProcessSuccess(response.data));
      } else {
        yield put(getAssessmentAutomationProcessFailed(response));
      }
    } catch (error) {
      yield put(getAssessmentAutomationProcessFailed(error));
    }
  });
}

function* updateAssessmentAutomationProcess() {
  yield takeEvery(
    UPDATE_ASSESSMENT_AUTOMATION_PROCESS,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.updateAssessmentProcess,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateAssessmentAutomationProcessSuccess(response.data));
          Notification(
            "success",
            "notification.assessmentAutomation.update.success"
          );
          onFinish(response.data);
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (err) {
        onFailed(err);
      }
    }
  );
}

function* deleteAssessmentAutomationProcess() {
  yield takeEvery(
    DELETE_ASSESSMENT_AUTOMATION_PROCESS,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.deleteAssessmentProcess,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteAssessmentAutomationProcessSuccess(payload));
          onFinish();
          Notification(
            "success",
            "notification.assessmentAutomation.delete.success"
          );
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

//ASSESSMENT RESULT
function* listAssessmentAutomationResult() {
  yield takeEvery(LIST_ASSESSMENT_AUTOMATION_RESULT, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.listAssessmentResult,
        payload
      );

      if (response.statusCode === 200) {
        yield put(listAssessmentAutomationResultSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* createAssessmentAutomationResult() {
  yield takeEvery(
    CREATE_ASSESSMENT_AUTOMATION_RESULT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.createAssessmentResult,
          payload
        );

        if (response.statusCode === 200 && response.data) {
          yield put(createAssessmentAutomationResultSuccess(response.data));
          onFinish();
          Notification(
            "success",
            "notification.assessmentAutomation.create.success"
          );
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (error) {
        console.log(error);
        onFailed(error);
      }
    }
  );
}

function* updateAssessmentAutomationResult() {
  yield takeEvery(
    UPDATE_ASSESSMENT_AUTOMATION_RESULT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.updateAssessmentResult,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateAssessmentAutomationResultSuccess(response));
          onFinish(response.data);
          Notification(
            "success",
            "notification.assessmentAutomation.update.success"
          );
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (err) {
        onFailed(err);
      }
    }
  );
}

function* deleteAssessmentAutomationResult() {
  yield takeEvery(
    DELETE_ASSESSMENT_AUTOMATION_RESULT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.deleteAssessmentResult,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteAssessmentAutomationResultSuccess(payload));
          onFinish();
          Notification(
            "success",
            "notification.assessmentAutomation.delete.success"
          );
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

//ASSESSMENT RULES
function* listAssessmentAutomationRules() {
  yield takeEvery(LIST_ASSESSMENT_AUTOMATION_RULES, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.listAssessmentRules,
        payload
      );

      if (response.statusCode === 200) {
        yield put(listAssessmentAutomationRulesSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* createAssessmentAutomationRules() {
  yield takeEvery(
    CREATE_ASSESSMENT_AUTOMATION_RULES,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.createAssessmentRules,
          payload
        );

        if (response.statusCode === 200 && response.data) {
          yield put(createAssessmentAutomationRulesSuccess(response.data));
          onFinish();
          Notification(
            "success",
            "notification.assessmentAutomation.create.success"
          );
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (error) {
        console.log(error);
        onFailed(error);
      }
    }
  );
}

function* updateAssessmentAutomationRules() {
  yield takeEvery(
    UPDATE_ASSESSMENT_AUTOMATION_RULES,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.updateAssessmentRules,
          payload
        );
        if (response.statusCode === 200) {
          yield put(updateAssessmentAutomationRulesSuccess(response));
          onFinish(response.data);
          Notification(
            "success",
            "notification.assessmentAutomation.update.success"
          );
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (err) {
        onFailed(err);
      }
    }
  );
}

function* deleteAssessmentAutomationRules() {
  yield takeEvery(
    DELETE_ASSESSMENT_AUTOMATION_RULES,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.deleteAssessmentRules,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteAssessmentAutomationRulesSuccess(payload));
          onFinish();
          Notification(
            "success",
            "notification.assessmentAutomation.delete.success"
          );
        } else {
          onFailed(response);
          Notification("success", "notification.internalServerError");
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

//ASSESSMENT SENT
function* listAssessmentAutomationNotSent() {
  yield takeEvery(LIST_ASSESSMENT_AUTOMATION_NOT_SENT, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.listAssessmentNotSent,
        payload
      );

      if (response.statusCode === 200) {
        yield put(listAssessmentAutomationNotSentSuccess(response));
      } else {
        yield put(listAssessmentAutomationNotSentFailed(response));
      }
    } catch (error) {
      yield put(listAssessmentAutomationNotSentFailed(error));
    }
  });
}

function* listAssessmentAutomationNotSentNextPage() {
  yield takeEvery(
    LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE,
    function* ({ payload }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.listAssessmentNotSentNextPage,
          payload.assessmentType,
          payload.next_page_token
        );

        if (response.statusCode === 200) {
          yield put(listAssessmentAutomationNotSentNextPageSuccess(response));
        } else {
          yield put(listAssessmentAutomationNotSentFailed(response));
        }
      } catch (error) {
        yield put(listAssessmentAutomationNotSentFailed(error));
      }
    }
  );
}
function* createAssessmentAutomationNotSent() {
  yield takeEvery(
    CREATE_ASSESSMENT_AUTOMATION_NOT_SENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.createAssessment,
          payload
        );
        if (response.statusCode === 200) {
          yield put(createAssessmentAutomationNotSentSuccess(response.data));
          Notification(
            "success",
            "notification.assessmentAutomation.create.success"
          );
          onFinish(response);
        } else if (response.errorCode === "CreateNotAllowed") {
          onFailed();
          Notification("error", "ไม่สามารถสร้างแบบประเมินชื่อซ้ำกันได้");
        } else {
          onFailed();
          yield put(createAssessmentAutomationNotSentFailed(response));
          Notification("error", "notification.internalServerError");
        }
      } catch (error) {
        onFailed(error);
        yield put(createAssessmentAutomationNotSentFailed(error));
      }
    }
  );
}

function* updateAssessmentAutomationNotSent() {
  yield takeEvery(
    UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT,
    function* ({ payload, onFinish }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.updateAssessment,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            updateAssessmentAutomationNotSentSuccess({
              ...payload,
              data: response.data,
            })
          );
          Notification(
            "success",
            "notification.assessmentAutomation.update.success"
          );
          onFinish();
        } else {
          yield put(updateAssessmentAutomationNotSentFailed(response));
          Notification("error", "notification.internalServerError");
        }
      } catch (error) {
        yield put(updateAssessmentAutomationNotSentFailed(error));
      }
    }
  );
}

function* deleteAssessmentAutomationNotSent() {
  yield takeEvery(
    DELETE_ASSESSMENT_AUTOMATION_NOT_SENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.deleteAssessment,
          payload
        );
        if (response.statusCode === 200) {
          yield put(
            deleteAssessmentAutomationNotSentSuccess(payload.assessmentId)
          );
          Notification(
            "success",
            "notification.assessmentAutomation.delete.success"
          );
          onFinish();
        } else {
          yield put(
            deleteAssessmentAutomationNotSentFailed(payload.assessmentId)
          );
          if (onFailed) {
            onFailed();
          }
          Notification("error", "notification.internalServerError");
        }
      } catch (error) {
        yield put(deleteAssessmentAutomationNotSentFailed(error));
      }
    }
  );
}

function* updateAssessmentAutomationNotSentToSent() {
  yield takeEvery(
    UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.updateAssessmentNotSentToSent,
          payload
        );

        if (response.statusCode === 200) {
          yield put(
            updateAssessmentAutomationNotSentToSentSuccess(response.data)
          );
          Notification(
            "success",
            "notification.assessmentAutomation.sent.success"
          );
          onFinish();
        } else {
          yield put(updateAssessmentAutomationNotSentToSentFailed(response));
          onFailed();
        }
      } catch (error) {
        yield put(updateAssessmentAutomationNotSentToSentFailed(error));
      }
    }
  );
}

function* listAssessmentAutomationSent() {
  yield takeEvery(LIST_ASSESSMENT_AUTOMATION_SENT, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.listAssessmentSent,
        payload
      );

      if (response.statusCode === 200) {
        yield put(listAssessmentAutomationSentSuccess(response));
      } else {
        yield put(listAssessmentAutomationSentFailed(response));
      }
    } catch (error) {
      yield put(listAssessmentAutomationSentFailed(error));
    }
  });
}

function* listAssessmentAutomationSentNextPage() {
  yield takeEvery(
    LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE,
    function* ({ payload }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.listAssessmentSentNextPage,
          payload.assessmentType,
          payload.next_page_token
        );

        if (response.statusCode === 200) {
          yield put(listAssessmentAutomationSentNextPageSuccess(response));
        } else {
          yield put(listAssessmentAutomationSentFailed(response));
        }
      } catch (error) {
        yield put(listAssessmentAutomationSentFailed(error));
      }
    }
  );
}
function* getAssessmentAutomationReport() {
  yield takeEvery(GET_ASSESSMENT_AUTOMATION_REPORT, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.getAssessment,
        payload
      );
      if (response.statusCode === 200) {
        yield put(getAssessmentAutomationReportSuccess(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* getAssessmentAutomationReportData() {
  yield takeEvery(
    GET_ASSESSMENT_AUTOMATION_REPORT_DATA,
    function* ({ payload }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.getAssessmentReport,
          payload
        );
        if (response.statusCode === 200) {
          yield put(getAssessmentAutomationReportDataSuccess(response.data));
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* listAssessmentAutomationSubmitReport() {
  yield takeEvery(
    LIST_ASSESSMENT_AUTOMATION_SUBMIT_REPORT,
    function* ({ assessmentType, assessmentId }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.listAssessmentSubmitReport,
          {
            assessmentType,
            assessmentId,
          }
        );
        yield put(listAssessmentAutomationSubmitReportSuccess(response.data));
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* getAssessmentAutomationSubmitReport() {
  yield takeEvery(
    GET_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_DATA,
    function* ({ payload }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.getAssessmentSubmitReport,
          payload
        );
        yield put(
          getAssessmentAutomationSubmitReportDataSuccess(response.data)
        );
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* listAssessmentAutomationTask() {
  yield takeEvery(
    LIST_ASSESSMENT_AUTOMATION_TASK,
    function* ({ assessmentType }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.listTask,
          assessmentType
        );
        yield put(listAssessmentAutomationTaskSuccess(response));
      } catch (error) {
        console.log(error);
      }
    }
  );
}
function* listAssessmentAutomationTaskNextPage() {
  yield takeEvery(
    LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE,
    function* ({ payload }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.listTaskNextPage,
          payload.assessmentType,
          payload.next_page_token
        );
        yield put(listAssessmentAutomationTaskNextPageSuccess(response));
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* listAssessmentTaskInAssessment() {
  yield takeEvery(
    LIST_ASSESSMENT_AUTOMATION_TASK_IN_ASSESSMENT,
    function* ({ payload }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.listTaskInAssessment,
          payload
        );
        yield put(listAssessmentAutomationInAssessmentSuccess(response.data));
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* createAssessmentAutomationTask() {
  yield takeEvery(
    CREATE_ASSESSMENT_AUTOMATION_TASK,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.createTask,
          payload
        );
        yield put(createAssessmentAutomationTaskSuccess(response.data));
        Notification("success", "สร้างงานเรียบร้อย");
        onFinish();
      } catch (error) {
        onFailed();
      }
    }
  );
}

function* updateAssessmentAutomationTask() {
  yield takeEvery(
    UPDATE_ASSESSMENT_AUTOMATION_TASK,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.updateTask,
          payload
        );
        yield put(updateAssessmentAutomationTaskSuccess(response.data));
        onFinish();
        Notification("success", "บันทึกการแก้ไขงานเรียบร้อยแล้ว");
      } catch (error) {
        Notification(
          "error",
          "ไม่สามารถบันทึกได้เนื่องจากงานนี้ไม่ได้อยู่ในความรับผิดชอบของท่าน"
        );
        onFailed(error);
      }
    }
  );
}

function* deleteAssessmentAutomationTask() {
  yield takeEvery(DELETE_ASSESSMENT_AUTOMATION_TASK, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.deleteTask,
        payload
      );
      yield put(deleteAssessmentAutomationTaskSuccess(response));
    } catch (error) {
      console.log(error);
    }
  });
}

function* getAssessmentInventory() {
  yield takeEvery(
    GET_ASSESSMENT_AUTOMATION_DATA_INVENTORY,
    function* ({ dataInventoryId, assessmentRisk }) {
      try {
        const assessmentRiskStore = yield select(
          selectAssessmentAutomationDataRisk
        );
        const personalData = _.filter(
          assessmentRisk?.personalData ?? [],
          (name) =>
            !assessmentRiskStore?.inventoryInformant?.includes(name) ?? false
        );
        // const risksOwner = _.filter(
        //   assessmentRisk?.risksOwner ?? [],
        //   (name) =>
        //     !assessmentRiskStore?.inventoryDataElements?.includes(name) ?? false
        // );
        const inventory = yield call(
          DataMappingService.getDataInventory,
          dataInventoryId
        );
        const devices = yield call(
          DataMappingService.listDataMappingInventoryITAsset,
          dataInventoryId
        );
        const listDataElements = yield call(
          DataMappingService.getDataInventoryDataElement,
          dataInventoryId
        );

        const inventoryInformant = inventory.data?.informant;
        const inventoryDataElements = _.map(
          listDataElements.data,
          ({ name }) => name
        );
        // console.log("device", devices?.includes(inventory.data.device));
        // if (devices && inventory) {
        const dataToset = devices.data?.filter((item) => {
          return inventory.data.device?.includes(item.ObjectUUID);
        });
        const devicename = dataToset.map((data) => data.name);
        // }

        const payload = {
          inventory: dataInventoryId,
          device: devicename,
          inventoryInformant,
          inventoryDataElements,
          personalData: [...inventoryInformant, ...personalData],
          risksOwner: [...listDataElements.data],
        };

        yield put(getAssessmentDataInventorySuccess(payload));
      } catch (error) {
        yield put(getAssessmentDataInventoryError());
      }
    }
  );
}

function* addDataMappingInventoryControl() {
  yield takeEvery(
    ADD_ASSESSMENT_CONTROL,
    function* ({ assessment_type, assessment_id, payload, onFinish }) {
      // yield put(setLoadingControl(true));
      try {
        const response = yield call(
          AssessmentAutomationServices.AddAssessmentControl,
          assessment_type,
          assessment_id,
          payload
        );
        if (response.statusCode === 200) {
          yield put(addAssessmentControlSuccess(response.data));
          Notification("success", "เพิ่มมาตรการความเสี่ยงสำเร็จแล้ว");
          onFinish();
        } else {
          yield put(addAssessmentControlFailed(response));
          Notification(
            "error",
            "เกิดข้อผิดพลาด ไม่สามารถเพิ่มมาตรการความเสี่ยงได้"
          );
        }
        // yield put(setLoadingControl(false));
      } catch (error) {
        console.log(error);
        yield put(addAssessmentControlFailed(error));
        // yield put(setLoadingControl(false));
        Notification(
          "error",
          "เกิดข้อผิดพลาด ไม่สามารถเพิ่มมาตรการความเสี่ยงได้"
        );
      }
    }
  );
}

function* getAssessmentControl() {
  yield takeEvery(
    GET_ASSESSMENT_CONTROL,
    function* ({ assessment_type, assessment_id, link_type }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.GetlistAssessmentControl,
          assessment_type,
          assessment_id,
          link_type
        );
        yield put(getAssessmentControlSuccess(response.data));
      } catch (error) {
        console.log(error);
      }
    }
  );
}

function* deleteAssessmentControl() {
  yield takeEvery(DELETE_ASSESSMENT_CONTROL, function* ({ payload, onFinish }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.deleteAssessmentControl,
        payload.assesment_type,
        payload.assessment_id,
        payload.link_type,
        payload.link_id
      );
      if (response.statusCode === 200) {
        yield put(deleteAssessmentControlSucces(payload));
        Notification("success", "ลบมาตรการความเสี่ยงสำเร็จแล้ว");
        onFinish();
      } else {
        Notification("error", "เกิดข้อผิดพลาด ไม่สามารถลบมาตรการความเสี่ยงได้");
      }
    } catch (error) {
      console.log(error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(listAssessmentAutomation),
    fork(createAssessmentAutomation),
    fork(updateAssessmentAutomation),
    fork(publishAssessmentAutomation),
    fork(deleteAssessmentAutomation),
    fork(getAssessmentAutomationData),
    fork(listAssessmentAutomationActive),
    fork(createAssessmentAutomationActive),
    fork(getAssessmentAutomationActiveByID),
    fork(updateAssessmentAutomationActive),
    fork(archivedAssessmentAutomationActive),
    fork(deleteAssessmentAutomationActive),
    fork(listAssessmentAutomationArchived),
    fork(activatedAssessmentAutomationArchived),
    fork(deleteAssessmentAutomationArchived),
    fork(activatedAssessmentAutomationDeleted),
    fork(deleteAssessmentAutomationDeleted),
    fork(listAssessmentAutomationDeleted),
    fork(listAssessmentAutomationRisk),
    fork(createAssessmentAutomationRisk),
    fork(updateAssessmentAutomationRisk),
    fork(deleteAssessmentAutomationRisk),
    fork(listAssessmentAutomationTemplate),
    fork(getAssessmentAutomationTemplateType),
    fork(getAssessmentAutomationTemplateByType),
    fork(deleteAssessmentAutomationTemplate),
    fork(listAssessmentAutomationProcess),
    fork(createAssessmentAutomationProcess),
    fork(getAssessmentAutomationProcess),
    fork(updateAssessmentAutomationProcess),
    fork(deleteAssessmentAutomationProcess),
    fork(listAssessmentAutomationResult),
    fork(createAssessmentAutomationResult),
    fork(updateAssessmentAutomationResult),
    fork(deleteAssessmentAutomationResult),
    fork(listAssessmentAutomationRules),
    fork(createAssessmentAutomationRules),
    fork(updateAssessmentAutomationRules),
    fork(deleteAssessmentAutomationRules),
    fork(listAssessmentAutomationNotSent),
    fork(createAssessmentAutomationNotSent),
    fork(updateAssessmentAutomationNotSent),
    fork(deleteAssessmentAutomationNotSent),
    fork(updateAssessmentAutomationNotSentToSent),
    fork(listAssessmentAutomationSent),
    fork(getAssessmentAutomationReport),
    fork(getAssessmentAutomationReportData),
    fork(listAssessmentAutomationSubmitReport),
    fork(getAssessmentAutomationSubmitReport),
    fork(listAssessmentAutomationTask),
    fork(listAssessmentTaskInAssessment),
    fork(createAssessmentAutomationTask),
    fork(updateAssessmentAutomationTask),
    fork(deleteAssessmentAutomationTask),
    fork(getAssessmentInventory),
    fork(addDataMappingInventoryControl),
    fork(getAssessmentControl),
    fork(deleteAssessmentControl),
    fork(listAssessmentAutomationSentNextPage),
    fork(listAssessmentAutomationNotSentNextPage),
    fork(listAssessmentAutomationTaskNextPage),
  ]);
}
