export const SIGNIN = "SIGNIN";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILED = "SIGNIN_FAILED";

export const AUTH_SHOW_LOADING = "AUTH_SHOW_LOADING";
export const AUTH_HIDE_LOADING = "AUTH_HIDE_LOADING";

export const TOKEN_REFRESHED_SUCCESS = "TOKEN_REFRESHED_SUCCESS";

export const CHANGE_AUTH_PASSWORD = "CHANGE_AUTH_PASSWORD";
export const CHANGE_AUTH_PASSWORD_SUCCESS = "CHANGE_AUTH_PASSWORD_SUCCESS";
