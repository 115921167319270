import fetch from "util/Api";
import { PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL as endpoint } from "config/EndpointConfig";

const CollectionPointService = {};

CollectionPointService.listCollectionPoint = async () =>
  await fetch({
    url: `${endpoint}/collection_points`,
    method: "get",
  });

CollectionPointService.getCollectionPoint = async (collectionPointId) =>
  await fetch({
    url: `${endpoint}/collection_points/${collectionPointId}`,
    method: "get",
  });

CollectionPointService.createCollectionPoint = async (data) =>
  await fetch({
    url: `${endpoint}/collection_points`,
    method: "post",
    data,
  });

CollectionPointService.updateCollectionPoint = async (
  collectionPointId,
  data
) =>
  await fetch({
    url: `${endpoint}/collection_points/${collectionPointId}`,
    method: "put",
    data,
  });

CollectionPointService.updateCollectionPointStatus = async (
  collectionPointId,
  status
) =>
  await fetch({
    url: `${endpoint}/collection_points/${collectionPointId}`,
    method: "post",
    data: { status },
  });

CollectionPointService.publishCollectionPoint = async (collectionPointId) =>
  await fetch({
    url: `${endpoint}/collection_points/${collectionPointId}/publish`,
    method: "post",
  });

CollectionPointService.listCollectionPointVersion = async (collectionPointId) =>
  await fetch({
    url: `${endpoint}/collection_points/${collectionPointId}/versions`,
    method: "get",
  });

CollectionPointService.getCollectionPointVersion = async (
  collectionPointId,
  versionId
) =>
  await fetch({
    url: `${endpoint}/collection_points/${collectionPointId}/versions/${versionId}`,
    method: "get",
  });

export default CollectionPointService;
