import {
  SET_TASK_MANAGEMENT_LIST,
  SET_TASK_MANAGEMENT_DETAIL,
  SET_TASK_ASSIGNMENT_LIST,
  SET_JOBS,
  SET_JOB_LOADING,
  SET_COMMENTS,
  SET_COMMENT_LOADING,
  SET_NEXT_PAGE_TOKEN_COMMENTS,
  SET_ACTIVITY,
  SET_ACTIVITY_LOADING,
  SET_NEXT_PAGE_TOKEN_ACTIVITY,
  SET_RESPONSE_PLAN,
  SET_RESPONSE_PLAN_DETAIL,
  SET_EDGE,
  SET_NODE,
  SET_WORKFLOW_MODULE,
} from "redux/constants/CyberKitSoar";

const initState = {
  taskManagement: {
    dataList: [],
    taskInfo: {
      detail: {},
      job: {
        dataList: [],
        loading: false,
      },
      comment: {
        loading: false,
        all: {
          items: [],
          next_page_token: null,
          totalPage: 0,
          currentPage: 0,
        },
      },
      activity: {
        items: [],
        loading: false,
        next_page_token: null,
        totalPage: 0,
        currentPage: 0,
      },
    },
  },
  taskAssignment: {
    dataList: [],
  },
  responsePlanning: {
    dataList: [],
    modules: [],
    workFlow: {
      detail: {},
      nodes: [],
      edges: [],
      modules: [],
    },
  },
};

const CyberKitSoar = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    // TASK MANAGEMENT
    case SET_TASK_MANAGEMENT_LIST:
      return {
        ...state,
        taskManagement: {
          ...state.taskManagement,
          dataList: payload,
        },
      };

    case SET_TASK_MANAGEMENT_DETAIL:
      return {
        ...state,
        taskManagement: {
          ...state.taskManagement,
          taskInfo: {
            ...state.taskManagement.taskInfo,
            detail: payload,
          },
        },
      };

    case SET_COMMENTS:
      return {
        ...state,
        taskManagement: {
          ...state.taskManagement,
          taskInfo: {
            ...state.taskManagement.taskInfo,
            comment: {
              ...state.taskManagement.taskInfo.comment,
              all: {
                ...state.taskManagement.taskInfo.comment.all,
                items: payload,
              },
            },
          },
        },
      };

    case SET_JOBS:
      return {
        ...state,
        taskManagement: {
          ...state.taskManagement,
          taskInfo: {
            ...state.taskManagement.taskInfo,
            job: {
              ...state.taskManagement.taskInfo.job,
              dataList: payload,
            },
          },
        },
      };

    case SET_JOB_LOADING:
      return {
        ...state,
        taskManagement: {
          ...state.taskManagement,
          taskInfo: {
            ...state.taskManagement.taskInfo,
            job: {
              ...state.taskManagement.taskInfo.job,
              loading: payload,
            },
          },
        },
      };

    case SET_COMMENT_LOADING:
      return {
        ...state,
        taskManagement: {
          ...state.taskManagement,
          taskInfo: {
            ...state.taskManagement.taskInfo,
            comment: {
              ...state.taskManagement.taskInfo.comment,
              loading: payload,
            },
          },
        },
      };

    case SET_NEXT_PAGE_TOKEN_COMMENTS:
      return {
        ...state,
        taskManagement: {
          ...state.taskManagement,
          taskInfo: {
            ...state.taskManagement.taskInfo,
            comment: {
              ...state.taskManagement.taskInfo.comment,
              all: {
                ...state.taskManagement.taskInfo.comment.all,
                next_page_token: payload.next,
                totalPage: payload.totalPage,
                currentPage: payload.currentPage,
              },
            },
          },
        },
      };

    case SET_ACTIVITY:
      return {
        ...state,
        taskManagement: {
          ...state.taskManagement,
          taskInfo: {
            ...state.taskManagement.taskInfo,
            activity: {
              ...state.taskManagement.taskInfo.activity,
              items: payload,
            },
          },
        },
      };

    case SET_ACTIVITY_LOADING:
      return {
        ...state,
        taskManagement: {
          ...state.taskManagement,
          taskInfo: {
            ...state.taskManagement.taskInfo,
            activity: {
              ...state.taskManagement.taskInfo.activity,
              loading: payload,
            },
          },
        },
      };

    case SET_NEXT_PAGE_TOKEN_ACTIVITY:
      return {
        ...state,
        taskManagement: {
          ...state.taskManagement,
          taskInfo: {
            ...state.taskManagement.taskInfo,
            activity: {
              ...state.taskManagement.taskInfo.activity,
              next_page_token: payload.next,
              totalPage: payload.totalPage,
              currentPage: payload.currentPage,
            },
          },
        },
      };

    // TASK ASSIGNMENT
    case SET_TASK_ASSIGNMENT_LIST:
      return {
        ...state,
        taskAssignment: {
          ...state.taskAssignment,
          dataList: payload,
        },
      };

    // RESPONSE PLAN
    case SET_RESPONSE_PLAN:
      return {
        ...state,
        responsePlanning: {
          ...state.responsePlanning,
          dataList: payload,
        },
      };

    case SET_RESPONSE_PLAN_DETAIL:
      return {
        ...state,
        responsePlanning: {
          ...state.responsePlanning,
          workFlow: {
            ...state.responsePlanning.workFlow,
            detail: payload,
          },
        },
      };

    case SET_NODE: {
      return {
        ...state,
        responsePlanning: {
          ...state.responsePlanning,
          workFlow: {
            ...state.responsePlanning.workFlow,
            nodes: payload,
          },
        },
      };
    }

    case SET_EDGE: {
      return {
        ...state,
        responsePlanning: {
          ...state.responsePlanning,
          workFlow: {
            ...state.responsePlanning.workFlow,
            edges: payload,
          },
        },
      };
    }

    case SET_WORKFLOW_MODULE: {
      return {
        ...state,
        responsePlanning: {
          ...state.responsePlanning,
          workFlow: {
            ...state.responsePlanning.workFlow,
            modules: payload,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default CyberKitSoar;
