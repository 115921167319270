export default class SourceModel {

    constructor(data) {
        this.id = data.id? data.id: 0;
        this.tenancy = data.tenancy? data.tenancy: "";
        this.description = data.description? data.description: "";
        this.name = data.name? data.name: "";
        this.tags = data.tags? data.tags: [];
        this.solution = data.solution? data.solution: "";
        this.configuration = data.configuration? data.configuration: {};
        this.identify = data.identify? data.identify: {};
        this.setting = data.setting? data.setting: {};
        this.enabled = data.enabled;
        this.status = data.status? data.status: "";
        this.create_by = data.create_by? data.create_by: "";
        this.create_at = data.create_at? data.create_at: "";
        this.update_by = data.update_by? data.update_by: "";
        this.update_at = data.update_at? data.update_at: "";
    }

    displayId = () => this.id
    displayName = () => this.name
    displayDescription = () => this.description
    displayTenancy = () => this.tenancy
    displaySolution = () => this.solution

    displayRetention = () => this.setting.retention

    displayHost = () => this.configuration.syslog.hostname
    displayPort = () => this.configuration.syslog.port
    displayTag = () => this.tags
}
