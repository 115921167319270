import {
  LIST_CONSENT_MANAGEMENT_COLLECTION_POINT,
  LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  GET_CONSENT_MANAGEMENT_COLLECTION_POINT,
  GET_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  TOGGLE_CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT,
  CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT,
  CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT_ERROR,
  UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT,
  UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS,
  UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_SUCCESS,
  PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT,
  PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT_ERROR,
  TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_SETTING,
  SET_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION,
  ADD_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_SECTION,
  UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_SECTION,
  UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_TEXT,
  UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_STYLE,
  TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION,
  DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION,
  TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
  DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
  TOGGLE_SETTING_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
  SETTING_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
  TOGGLE_MASK_IDENTIFY_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
  TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS,
  CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS,
  CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_SUCCESS,
  CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_ERROR,
  TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_PREVIEW,
  TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_GET_SCRIPT,
  LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION,
  LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_SUCCESS,
  LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_ERROR,
  GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION,
  GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_SUCCESS,
  GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_ERROR,
  TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION,
} from "../../constants/PrivacyKitConsentManagement";

export const listConsentManagementCollectionPoint = () => ({
  type: LIST_CONSENT_MANAGEMENT_COLLECTION_POINT,
});

export const listConsentManagementCollectionPointSuccess = (data) => ({
  type: LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  data,
});

export const getConsentManagementCollectionPoint = (collectionPointId) => ({
  type: GET_CONSENT_MANAGEMENT_COLLECTION_POINT,
  collectionPointId,
});

export const getConsentManagementCollectionPointSuccess = (data) => ({
  type: GET_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  data,
});

export const toggleCreateConsentManagementCollectionPoint = () => ({
  type: TOGGLE_CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT,
});

export const createConsentManagementCollectionPoint = (values) => ({
  type: CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT,
  values,
});

export const createConsentManagementCollectionPointSuccess = (data) => ({
  type: CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  data,
});

export const createConsentManagementCollectionPointError = () => ({
  type: CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT_ERROR,
});

export const updateConsentManagementCollectionPoint = (values) => ({
  type: UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT,
  values,
});

export const updateConsentManagementCollectionPointSuccess = (data) => ({
  type: UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  data,
});

export const updateConsentManagementCollectionPointStatus = () => ({
  type: UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS,
});

export const updateConsentManagementCollectionPointStatusSuccess = () => ({
  type: UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_SUCCESS,
});

export const publishConsentManagementCollectionPoint = () => ({
  type: PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT,
});

export const publishConsentManagementCollectionPointSuccess = (data) => ({
  type: PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS,
  data,
});

export const publishConsentManagementCollectionPointError = (data) => ({
  type: PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT_ERROR,
  data,
});

export const toggleConsentManagementCollectionPointSetting = (
  settingType,
  data
) => ({
  type: TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_SETTING,
  settingType,
  data,
});

export const setConsentManagementCollectionPointSection = (sectionId) => ({
  type: SET_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION,
  sectionId,
});

export const addConsentManagementCollectionPointFormSection = (sectionId) => ({
  type: ADD_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_SECTION,
  sectionId,
});

export const updateConsentManagementCollectionPointFormSection = (data) => ({
  type: UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_SECTION,
  data,
});

export const updateConsentManagementCollectionPointFormText = (formText) => ({
  type: UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_TEXT,
  formText,
});

export const updateConsentManagementCollectionPointFormStyle = (formStyle) => ({
  type: UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_STYLE,
  formStyle,
});

// TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION,
//   DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION,
//   TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
//   DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
//   TOGGLE_SETTING_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
//   SETTING_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,

export const toggleDeleteConsentManagementCollectionPointSection = (
  payload
) => ({
  type: TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION,
  payload,
});

export const deleteConsentManagementCollectionPointSection = (payload) => ({
  type: DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION,
  payload,
});

export const toggleDeleteConsentManagementCollectionPointSectionComponent = (
  payload
) => ({
  type: TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
  payload,
});

export const deleteConsentManagementCollectionPointSectionComponent = (
  payload
) => ({
  type: DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
  payload,
});

export const toggleSettingConsentManagementCollectionPointSectionComponent = (
  payload
) => ({
  type: TOGGLE_SETTING_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
  payload,
});

export const settingConsentManagementCollectionPointSectionComponent = (
  payload
) => ({
  type: SETTING_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
  payload,
});

export const toggleMaskIdentifyConsentManagementCollectionPointSectionComponent =
  (payload) => ({
    type: TOGGLE_MASK_IDENTIFY_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT,
    payload,
  });

export const toggleConsentManagementCollectionPointStatus = (payload) => ({
  type: TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS,
  payload,
});

export const changeConsentManagementCollectionPointStatus = () => ({
  type: CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS,
});

export const changeConsentManagementCollectionPointStatusSuccess = (
  payload
) => ({
  type: CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_SUCCESS,
  payload,
});

export const changeConsentManagementCollectionPointStatusError = (payload) => ({
  type: CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_ERROR,
  payload,
});

export const toggleConsentManagementCollectionPointPreview = (payload) => ({
  type: TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_PREVIEW,
  payload,
});

export const toggleConsentManagementCollectionPointGetScript = (payload) => ({
  type: TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_GET_SCRIPT,
  payload,
});

export const listConsentManagementCollectionPointVersion = () => ({
  type: LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION,
});

export const listConsentManagementCollectionPointVersionSuccess = (
  payload
) => ({
  type: LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_SUCCESS,
  payload,
});

export const listConsentManagementCollectionPointVersionError = () => ({
  type: LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_ERROR,
});

export const getConsentManagementCollectionPointVersion = (versionId) => ({
  type: GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION,
  versionId,
});

export const getConsentManagementCollectionPointVersionSuccess = (payload) => ({
  type: GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_SUCCESS,
  payload,
});

export const getConsentManagementCollectionPointVersionError = () => ({
  type: GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_ERROR,
});

export const toggleConsentManagementCollectionPointVersion = (payload) => ({
  type: TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION,
  payload,
});
