import fetch from "util/Api";
import { PRIVACYKIT_POLICY_AND_NOTICE_MANAGEMENT_BASE_URL as endpoint } from "config/EndpointConfig";

import { splitStringUUID } from "util/StringUtils";

const PolicyAndNoticeManagementService = {};

PolicyAndNoticeManagementService.getDashboard = async () =>
  await fetch({
    url: `${endpoint}/dashboard`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.listPolicy = async () =>
  await fetch({
    url: `${endpoint}/policy/lists`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.getPolicyTemplate = async () =>
  await fetch({
    method: "get",
    url: `${endpoint}/policy/template?type=mol`,
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.getPolicyTemplateData = async ({
  type,
  template,
}) =>
  await fetch({
    method: "get",
    url: `${endpoint}/policy/template/${type}/${template}`,
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.getPolicyTemplateBuilder = async ({
  template_data_id,
  template_id,
  ...data
}) =>
  await fetch({
    method: "post",
    url: `${endpoint}/policy/template/${template_data_id}/${template_id}/create_json`,
    data,
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.createPolicy = async (data) =>
  await fetch({
    url: `${endpoint}/policy`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.getPolicyData = async (ObjectType) =>
  await fetch({
    url: `${endpoint}/policy/${ObjectType}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.searchPolicyExiting = async ({
  name,
  policy_type,
}) =>
  await fetch({
    url: `${endpoint}/policy/result?search_query=${name}&policy_type=${policy_type}`,
    method: "get",
  });

PolicyAndNoticeManagementService.getPolicyVersion = async (ObjectType) =>
  await fetch({
    url: `${endpoint}/policy/${ObjectType}/versions`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.updatePolicy = async (data) => {
  const ObjectUUID = data.ObjectUUID.split("/");
  return await fetch({
    url: `${endpoint}/policy/${ObjectUUID[ObjectUUID.length - 1]}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);
};

PolicyAndNoticeManagementService.publishPolicy = async (data) => {
  const ObjectUUID = data.ObjectUUID.split("/");
  return await fetch({
    url: `${endpoint}/policy/${ObjectUUID[ObjectUUID.length - 1]}/publish`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);
};

PolicyAndNoticeManagementService.deletePolicy = async (data) => {
  const ObjectUUID = data.ObjectUUID.split("/");
  return await fetch({
    url: `${endpoint}/policy/${ObjectUUID[ObjectUUID.length - 1]}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);
};

PolicyAndNoticeManagementService.getPolicyPreviewWithData = async (
  policyData
) => {
  return await fetch({
    method: "post",
    url: `${endpoint}/policy/html`,
    data: policyData,
  })
    .then((response) => response)
    .catch((error) => error);
};

PolicyAndNoticeManagementService.listTask = async () =>
  await fetch({
    url: `${endpoint}/tasks`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.updateTask = async (data) =>
  await fetch({
    url: `${endpoint}/tasks/${splitStringUUID(data.ObjectUUID)}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.listAccessment = async () =>
  await fetch({
    url: `${endpoint}/accessment/lists`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.getAccessment = async (assessmentId) =>
  await fetch({
    url: `${endpoint}/accessment/${assessmentId}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.createAccessment = async (data) =>
  await fetch({
    url: `${endpoint}/accessment`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.updateAssessment = async (data) =>
  await fetch({
    url: `${endpoint}/accessment/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

PolicyAndNoticeManagementService.deleteAssessment = async (data) =>
  await fetch({
    url: `${endpoint}/accessment/${data.ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

export default PolicyAndNoticeManagementService;
