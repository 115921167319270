import moment from "moment";
import { changeFormatDatetime } from "util/DateUtils";

const STATUS_COLOUR_TAG = {
  opening: { color: "#6F42C1", label: "รอดำเนินการ" },
  close: { color: "#04D182", label: "เสร็จสิ้น" },
  success: { color: "#04D182", label: "เสร็จสิ้น" },
};

const SEVERITY_COLOUR_TAG = {
  low: { color: "#04D182", label: "ต่ำ" },
  medium: { color: "#EFC36D", label: "ปานกลาง" },
  high: { color: "#FA8C16", label: "สูง" },
  critical: { color: "#C74343", label: "รุนแรง" },
};

export default class TaskManagementModel {
  constructor(data) {
    this.id = data?.id;
    this.assigns = data?.assigns;
    this.object_type = data?.object_type;
    this.organization = data?.organization;
    this.condition_data = data?.condition_data;
    this.module = data?.module;
    this.request_type = data?.request_type;
    this.workflow_id = data?.workflow_id;
    this.workflow_name = data?.workflow_name;
    this.name = data?.name;
    this.description = data?.description;
    this.severity = data?.severity;
    this.tags = data?.tags;
    this.due_date = data?.due_date;
    this.data = data?.data;
    this.status = data.status ? data.status : "opening";
    this.create_at = data?.create_at;
    this.create_by = data?.create_by;
    this.update_at = data?.update_at;
    this.update_by = data?.update_by;
  }

  displayID = () => this.id;
  displayName = () => this.name;
  displayDescription = () => this.description;
  displayWorkflowName = () => this.workflow_name;
  displayNumberOfTasks = () => "0";
  displayResponsePlan = () => "0";
  displayResponseTime = () => moment();

  displayTags = () => this.tags || [];

  displayStatusColor = () =>
    STATUS_COLOUR_TAG[this.status.toLowerCase()]?.color || "#04D182";
  displayStatusLabel = () =>
    STATUS_COLOUR_TAG[this.status.toLowerCase()]?.label || "None";
  displaySeverityColor = () =>
    SEVERITY_COLOUR_TAG[this.severity]?.color || "#04D182";
  displaySeverityLabel = () =>
    SEVERITY_COLOUR_TAG[this.severity]?.label || "ไม่พบ";

  displayDateStart = () =>
    changeFormatDatetime(moment(this.due_date?.start), "DD/MM/YYYY h:mm A");
  displayDateEnd = () =>
    changeFormatDatetime(
      moment(this.due_date?.start).add(this.due_date?.day, "days"),
      "DD/MM/YYYY h:mm A"
    );

  displayCreateBy = () => this.create_by || "-";
  isClosed = () => this.status === "closed";

  displayCondition = () => {
    if (this.condition_data) {
      let keys = Object.keys(this.condition_data);
      keys = keys.filter((v) => v !== "group_by");
      const data = keys.map((v) => {
        return {
          title: v,
          content: this.condition_data[v].value,
        };
      });
      return data;
    } else return [];
  };
  displayGroupCondition = () => {
    if (this.condition_data) {
      let keys = Object.keys(this.condition_data);
      keys = keys.filter((v) => v === "group_by");
      const data = keys.map((v) => {
        const keyInKey = Object.keys(this.condition_data[v]).map(
          (i) => `${i} === ${this.condition_data[v][i]}`
        );
        return {
          title: v,
          content: keyInKey.join(" AND "),
        };
      });
      return data;
    } else return [];
  };
}
