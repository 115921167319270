import fetch from "util/Api";
import { PRIVACYKIT_DATA_MAPPING_BASE_URL as endpoint } from "config/EndpointConfig";
import { PRIVACYKIT_ASSESSMENT_AUTOMATION_BASE_URL as endpoint_assessment } from "config/EndpointConfig";

const DataMappingService = {};

DataMappingService.dataMappingDashboard = async () =>
  await fetch({
    url: `${endpoint}/inventory/dashboard`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listAssessmentActivated = async () =>
  await fetch({
    url: `${endpoint_assessment}/assessment/type/privacy?status=active`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.updateAssessmentActivatedToArchive = async ({
  assessment_type,
  ObjectUUID,
  data,
}) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/${assessment_type}/${ObjectUUID}/status`,
    method: "put",
    data: { status: "achieve", ...data },
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.updateAssessmentActivatedToDeleted = async ({
  assessment_type,
  ObjectUUID,
  data,
}) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/${assessment_type}/${ObjectUUID}/status`,
    method: "put",
    data: { status: "remove", ...data },
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listAssessmentArchive = async () =>
  await fetch({
    url: `${endpoint_assessment}/assessment/type/privacy?status=achieve`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.updateAssessmentArchiveToActivated = async ({
  assessment_type,
  ObjectUUID,
  data,
}) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/${assessment_type}/${ObjectUUID}/status`,
    method: "put",
    data: { status: "active", ...data },
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.updateAssessmentArchiveToDeleted = async ({
  assessment_type,
  ObjectUUID,
  data,
}) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/${assessment_type}/${ObjectUUID}/status`,
    method: "put",
    data: { status: "remove", ...data },
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listAssessmentDeleted = async () =>
  await fetch({
    url: `${endpoint_assessment}/assessment/type/privacy?status=remove`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.updateAssessmentDeletedToActivated = async ({
  assessment_type,
  ObjectUUID,
  data,
}) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/${assessment_type}/${ObjectUUID}/status`,
    method: "put",
    data: { status: "active", ...data },
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.createAssessment = async (data) =>
  await fetch({
    url: `${endpoint_assessment}/assessment`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.deleteAssessment = async ({ assessment_type, ObjectUUID }) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/${assessment_type}/${ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listRisk = async () =>
  await fetch({
    url: `${endpoint_assessment}/assessment/risk`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.createRisk = async (data) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/risk`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.getRisk = async ({ assessment_type, riskId }) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/risk/privacy/${riskId}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.updateRisk = async (data) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/risk/${data.assessment_type}/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.deleteRisk = async ({ assessment_type, ObjectUUID }) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/risk/${assessment_type}/${ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listInventoryAssets = async () =>
  await fetch({
    url: `${endpoint}/inventory/asset`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listInventoryProcessing = async () =>
  await fetch({
    url: `${endpoint}/inventory/processing`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listInventoryEntity = async () =>
  await fetch({
    url: `${endpoint}/inventory/entities`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listInventoryVendor = async () =>
  await fetch({
    url: `${endpoint}/inventory/vendors`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.createInventory = async (data) =>
  await fetch({
    url: `${endpoint}/inventory`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.createDataElements = async (data) =>
  await fetch({
    url: `${endpoint}/inventory/data_elements`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listDataElements = async () =>
  await fetch({
    url: `${endpoint}/inventory/data_elements`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.updateDataElements = async ({ data }) =>
  await fetch({
    url: `${endpoint}/inventory/data_elements/${data.elements_id}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.deleteDataElements = async (payload) =>
  await fetch({
    url: `${endpoint}/inventory/data_elements/${payload}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.getInventory = async ({ inventoryType, ObjectUUID }) =>
  await fetch({
    url: `${endpoint}/inventory/${inventoryType}/${ObjectUUID}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.updateInventory = async (data) =>
  await fetch({
    url: `${endpoint}/inventory/${data.inventory_type}/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.deleteInventory = async ({ inventory_type, ObjectUUID }) =>
  await fetch({
    url: `${endpoint}/inventory/${inventory_type}/${ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listSettingAutomationRule = async () =>
  await fetch({
    url: `${endpoint_assessment}/assessment/rule`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.createSettingAutomationRule = async (data) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/rule`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.getSettingAutomationRule = async ({
  assessment_type,
  ruleId,
}) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/rule/privacy/${ruleId}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.updateSettingAutomationRule = async (data) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/rule/${data.assessment_type}/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.deleteSettingAutomationRule = async ({
  assessment_type,
  ObjectUUID,
}) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/rule/${assessment_type}/${ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listSettingAssessmentTemplate = async () =>
  await fetch({
    url: `${endpoint_assessment}/assessment/type/:assessment_type`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listSettingAssessmentWorkflow = async () =>
  await fetch({
    url: `${endpoint_assessment}/assessment/stage`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.createSettingAssessmentWorkflow = async (data) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/stage`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.getSettingAssessmentWorkflow = async ({
  assessment_type,
  workflowId,
}) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/stage/internal/${workflowId}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.updateSettingAssessmentWorkflow = async (data) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/stage/${data.assessment_type}/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.deleteSettingAssessmentWorkflow = async ({
  assessment_type,
  ObjectUUID,
}) =>
  await fetch({
    url: `${endpoint_assessment}/assessment/stage/${assessment_type}/${ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listSettingInventoryManagement = async () =>
  await fetch({
    url: `${endpoint}/inventory/management`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.createSettingInventoryManagement = async (data) =>
  await fetch({
    url: `${endpoint}/inventory/management`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.deleteSettingInventoryManagement = async (data) =>
  await fetch({
    url: `${endpoint}/inventory/management/${data.attrbute_type}/${data.ObjectUUID}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listDataMappingInventory = async () =>
  await fetch({
    url: `${endpoint}/inventory`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.getDataMappingInventoryDetail = async ({
  inventory_type,
  ObjectUUID,
}) =>
  await fetch({
    url: `${endpoint}/inventory/${inventory_type}/${ObjectUUID}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.updateDataMappingInventory = async (data) =>
  await fetch({
    url: `${endpoint}/inventory/${data.inventory_type}/${data.ObjectUUID}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listDataMappingInventoryAssessment = async ({
  inventory_type,
  ObjectUUID,
}) =>
  await fetch({
    url: `${endpoint}/inventory/${inventory_type}/${ObjectUUID}/assessment`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.addDataMappingInventoryAssessment = async (data) =>
  await fetch({
    url: `${endpoint}/inventory/${data.inventory_type}/${data.ObjectUUID}/assessment`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.deleteDataMappingInventoryAssessment = async ({
  inventory_type,
  ObjectUUID,
  assessment_id,
}) =>
  await fetch({
    url: `${endpoint}/inventory/${inventory_type}/${ObjectUUID}/assessment/${assessment_id}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listDataMappingInventoryDataElement = async ({
  inventory_type,
  ObjectUUID,
}) =>
  await fetch({
    url: `${endpoint}/inventory/${inventory_type}/${ObjectUUID}/data_element`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.addDataMappingInventoryDataElement = async (
  inventory_type,
  ObjectUUID,
  data
) =>
  await fetch({
    url: `${endpoint}/inventory/${inventory_type}/${ObjectUUID}/data_element`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.deleteDataMappingInventoryDataElement = async (
  inventory_type,
  ObjectUUID,
  dataElement_id
) =>
  await fetch({
    url: `${endpoint}/inventory/${inventory_type}/${ObjectUUID}/data_element/${dataElement_id}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listDataMappingInventoryDataLifeCycle = async () =>
  await fetch({
    url: `${endpoint}/life_circle`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listDataMappingInventoryLocation = async () =>
  await fetch({
    url: `${endpoint}/map/country`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listDataMappingInventoryControl = async () =>
  await fetch({
    url: `${endpoint}/control_name`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listDataMappingInventoryControlParam = async (module) =>
  await fetch({
    url: `${endpoint}/control_name/?module=${module}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listDataMappingInventoryLinkControl = async (
  inventory_type,
  ObjectUUID
) =>
  await fetch({
    url: `${endpoint}/inventory/${inventory_type}/${ObjectUUID}/controls`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.addDataMappingInventoryControl = async (
  inventory_type,
  ObjectUUID,
  data
) =>
  await fetch({
    url: `${endpoint}/inventory/${inventory_type}/${ObjectUUID}/controls`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.deleteDataMappingInventoryControl = async (
  inventory_type,
  ObjectUUID,
  controls_id
) =>
  await fetch({
    url: `${endpoint}/inventory/${inventory_type}/${ObjectUUID}/controls/${controls_id}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listDataMappingInventoryDataInventoryRelated = async ({
  inventory_type,
  ObjectUUID,
  related_type,
}) =>
  await fetch({
    url: `${endpoint}/inventory/${inventory_type}/${ObjectUUID}/related?related_type=${related_type}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.addDataMappingInventoryDataInventoryRelated = async (
  inventory_type,
  ObjectUUID,
  data
) =>
  await fetch({
    url: `${endpoint}/inventory/${inventory_type}/${ObjectUUID}/related`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.deleteDataMappingInventoryDataInventoryRelated = async (
  inventory_type,
  ObjectUUID,
  related_type,
  related_id
) =>
  await fetch({
    url: `${endpoint}/inventory/${inventory_type}/${ObjectUUID}/related/${related_type}/${related_id}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listInventoryDataWorkflow = async () =>
  await fetch({
    url: `${endpoint}/inventory/workflow`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.createInventoryDataWorkflow = async (data) =>
  await fetch({
    url: `${endpoint}/inventory/workflow`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.deleteInventoryDataWorkflow = async (id) =>
  await fetch({
    url: `${endpoint}/inventory/workflow/${id}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listDataMappingInventoryITAsset = async () =>
  await fetch({
    url: `${endpoint}/inventory/it_asset`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.listDataMappingFlow = async (id) =>
  await fetch({
    url: `${endpoint}/inventory/workflow/${id}/flow`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataMappingService.getDataInventory = async (dataInventoryId) =>
  await fetch({
    url: `${endpoint}/inventory/asset/${dataInventoryId}`,
    method: "get",
  });

DataMappingService.getDataInventoryDataElement = async (dataInventoryId) =>
  await fetch({
    url: `${endpoint}/inventory/asset/${dataInventoryId}/data_element`,
    method: "get",
  });

export default DataMappingService;
