import fetch from "util/Api";
import { PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL as endpoint } from "config/EndpointConfig";

const CustomPreferenceService = {};

CustomPreferenceService.listCustomPreference = async () =>
  await fetch({
    url: `${endpoint}/custom_preferences`,
    method: "get",
  });

CustomPreferenceService.getCustomPreference = async (customPreferenceId) =>
  await fetch({
    url: `${endpoint}/custom_preferences/${customPreferenceId}`,
    method: "get",
  });

CustomPreferenceService.createCustomPreference = async (data) =>
  await fetch({
    url: `${endpoint}/custom_preferences`,
    method: "post",
    data,
  });

CustomPreferenceService.updateCustomPreference = async ({
  customPreferenceId,
  data,
}) =>
  await fetch({
    url: `${endpoint}/custom_preferences/${customPreferenceId}`,
    method: "put",
    data,
  });

CustomPreferenceService.deleteCustomPreference = async (customPreferenceId) =>
  await fetch({
    url: `${endpoint}/custom_preferences/${customPreferenceId}`,
    method: "delete",
  });

export default CustomPreferenceService;
