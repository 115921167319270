import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  LIST_DATA_MAPPING_DATA_WHERE_HOUSE,
  MANAGE_DATA_MAPPING_DATA_WHERE_HOUSE,
  CREATE_DATA_MAPPING_DATA_WHERE_HOUSE,
  UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE,
  DELETE_DATA_MAPPING_DATA_WHERE_HOUSE,
  LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP,
} from "redux/constants/IPrivacyKitDataMapping";
import {
  listDataMappingDataWarehouseSuccess,
  listDataMappingDataWarehouseFailed,
  createDataMappingDataWarehouseSuccess,
  createDataMappingDataWarehouseFailed,
  updateDataMappingDataWarehouseSuccess,
  updateDataMappingDataWarehouseFailed,
  deleteDataMappingDataWarehouseSuccess,
  deleteDataMappingDataWarehouseFailed,
  listDataMappingDataWarehouseGroupSuccess,
  listDataMappingDataWarehouseGroupFailed,
} from "redux/actions/IPrivacyKitDataMapping";
import {
  selectIPrivacyKitDataMappingDataWarehouseManageModal,
  selectIPrivacyKitDataMappingDataWarehouseDeleteModal,
} from "../../selectors/IPrivacyKitDataMapping";
import IDataMappingServices from "services/pdpakit/IDataMappingServices";
import Notification from "util/Notification";

export function* listDataMappingDataWhereHouse() {
  yield takeEvery(LIST_DATA_MAPPING_DATA_WHERE_HOUSE, function* () {
    try {
      const response = yield call(IDataMappingServices.listDataWareHouse);
      yield put(listDataMappingDataWarehouseSuccess(response.data));
    } catch (error) {
      yield put(listDataMappingDataWarehouseFailed(error));
    }
  });
}

export function* manageDataMappingDataWhereHouse() {
  yield takeEvery(MANAGE_DATA_MAPPING_DATA_WHERE_HOUSE, function* ({ values }) {
    const { data } = yield select(
      selectIPrivacyKitDataMappingDataWarehouseManageModal
    );
    if (data) {
      yield put({ type: UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE, values });
    } else {
      yield put({ type: CREATE_DATA_MAPPING_DATA_WHERE_HOUSE, values });
    }
  });
}

export function* createDataMappingDataWhereHouse() {
  yield takeEvery(CREATE_DATA_MAPPING_DATA_WHERE_HOUSE, function* ({ values }) {
    try {
      const response = yield call(
        IDataMappingServices.createDataWareHouse,
        values
      );
      yield put(createDataMappingDataWarehouseSuccess(response.data));
      Notification("success", "สร้างองค์ประกอบข้อมูลสำเร็จ");
    } catch (error) {
      yield put(createDataMappingDataWarehouseFailed(error));
      Notification("error", "สร้างองค์ประกอบข้อมูลไม่สำเร็จ");
    }
  });
}

export function* updateDataMappingDataWhereHouse() {
  yield takeEvery(UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE, function* ({ values }) {
    try {
      const { data } = yield select(
        selectIPrivacyKitDataMappingDataWarehouseManageModal
      );
      const response = yield call(IDataMappingServices.updateDataWareHouse, {
        ...data,
        ...values,
      });
      yield put(updateDataMappingDataWarehouseSuccess(response.data));
      Notification("success", "แก้ไของค์ประกอบข้อมูลสำเร็จ");
    } catch (error) {
      yield put(updateDataMappingDataWarehouseFailed(error));
      Notification("error", "แก้ไของค์ประกอบข้อมูลไม่สำเร็จ");
    }
  });
}

export function* deleteDataMappingDataWhereHouse() {
  yield takeEvery(DELETE_DATA_MAPPING_DATA_WHERE_HOUSE, function* () {
    try {
      const { data } = yield select(
        selectIPrivacyKitDataMappingDataWarehouseDeleteModal
      );
      const response = yield call(
        IDataMappingServices.deleteDataWareHouse,
        data.ObjectUUID
      );
      yield put(deleteDataMappingDataWarehouseSuccess(response.data));
      Notification("success", "ลบองค์ประกอบข้อมูลสำเร็จ");
    } catch (error) {
      yield put(deleteDataMappingDataWarehouseFailed(error));
      Notification("error", "ลบองค์ประกอบข้อมูลไม่สำเร็จ");
    }
  });
}

export function* listDataMappingDataWhereHouseGroup() {
  yield takeEvery(LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP, function* () {
    try {
      const response = yield call(IDataMappingServices.listDataWareHouseGroup);

      const allDataWareHouseGroup = [];
      response.data.forEach((dataWarehouseGroup) => {
        dataWarehouseGroup?.childs?.map((dataWarehouse) => {
          allDataWareHouseGroup.push(dataWarehouse);
        });
      });

      yield put(
        listDataMappingDataWarehouseGroupSuccess({
          allDataWareHouseGroup,
          listDataWareHouseGroup: response.data,
        })
      );
    } catch (error) {
      yield put(listDataMappingDataWarehouseGroupFailed(error));
    }
  });
}
