import {
  LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  TOGGLE_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  SET_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  CREATE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  UPDATE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  TOGGLE_DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_ERROR,
} from "../../constants/PrivacyKitConsentManagement";
import { replaceObject } from "util/arrayUtils";
import _ from "lodash";

const initialState = {
  loading: false,
  listCustomPreference: null,
  deleteCustomPreference: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
  manageCustomPreference: {
    visible: false,
    loading: false,
    customPreferenceId: null,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
};

const CustomPreferenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS: {
      return {
        ...state,
        listCustomPreference: action.data,
        loading: false,
      };
    }
    case GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE: {
      return {
        ...state,
        manageCustomPreference: {
          ...state.manageCustomPreference,
          loading: true,
        },
      };
    }
    case GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS: {
      return {
        ...state,
        manageCustomPreference: {
          ...state.manageCustomPreference,
          data: action.data,
          loading: false,
        },
      };
    }
    case TOGGLE_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE: {
      return {
        ...state,
        manageCustomPreference: {
          ...state.manageCustomPreference,
          data: null,
          customPreferenceId: action.customPreferenceId ?? null,
          visible: !state.manageCustomPreference.visible,
        },
      };
    }
    case SET_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE: {
      return {
        ...state,
        manageCustomPreference: {
          ...state.manageCustomPreference,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case CREATE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS: {
      return {
        ...state,
        manageCustomPreference: {
          ...initialState.manageCustomPreference,
        },
        listCustomPreference: [action.data, ...state.listCustomPreference],
      };
    }
    case UPDATE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS: {
      return {
        ...state,
        manageCustomPreference: {
          ...initialState.manageCustomPreference,
        },
        listCustomPreference: [
          ...replaceObject(
            state.listCustomPreference,
            action.customPreferenceId,
            action.data
          ),
        ],
      };
    }
    case TOGGLE_DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE: {
      return {
        ...state,
        deleteCustomPreference: {
          ...state.deleteCustomPreference,
          visible: !state.deleteCustomPreference.visible,
          data: action?.payload ?? null,
        },
      };
    }
    case DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE: {
      return {
        ...state,
        deleteCustomPreference: {
          ...state.deleteCustomPreference,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS: {
      return {
        ...state,
        listCustomPreference: [
          ..._.filter(
            state.listCustomPreference,
            ({ ObjectUUID }) => ObjectUUID !== action.customPreferenceId
          ),
        ],
        deleteCustomPreference: initialState.deleteCustomPreference,
      };
    }
    case DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_ERROR: {
      return {
        ...state,
        deleteCustomPreference: {
          ...state.deleteCustomPreference,
          okButtonProps: initialState.manageCustomPreference.okButtonProps,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default CustomPreferenceReducer;
