import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  GET_CONSENT_MANAGEMENT_DASHBOARD,
  GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE,
  GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT,
} from "../../constants/PrivacyKitConsentManagement";
import {
  getConsentManagementDashboardSuccess,
  getNextConsentManagementDashboardProcessingPurposeSuccess,
  getNextPageConsentManagementDashboardReceiptFailure,
  getNextPageConsentManagementDashboardReceiptSuccess,
} from "../../actions/PrivacyKitConsentManagement";
import {
  selectDashboard,
  selectDashboardProcessingPurpose,
} from "../../selectors/PrivacyKitConsentManagement";
import UniversalConsentAndPreferenceService from "services/pdpakit/UniversalConsentAndPreferenceService";
import _ from "lodash";
import Notification from "util/Notification";

const findDashboardChart = (dashboard, typeKey, valueKey) => {
  const dashboardData = _.find(dashboard, { type: typeKey });
  if (!dashboardData) {
    return [];
  }
  return Object.entries(dashboardData[valueKey || typeKey])
    .map((data) => {
      const [key, dashboardData] = data;
      let object = {};
      object["id"] = key;
      object["label"] = key;
      object["value"] = dashboardData.count || dashboardData;
      return object;
    })
    .filter((data) => data.value > 0)
    .filter((data, index) => index < 6);
};

const findDashboardCount = (dashboard, typeKey, valueKey) => {
  const dashboardData = _.find(dashboard, { type: typeKey });
  if (!dashboardData) {
    return 0;
  }
  return dashboardData[valueKey || typeKey] || 0;
};

export function* getDashboard() {
  yield takeEvery(
    GET_CONSENT_MANAGEMENT_DASHBOARD,
    function* ({ startDate, endDate }) {
      try {
        const [dashboard, receipt, processPurpose] = yield all([
          call(
            UniversalConsentAndPreferenceService.getConsentDashboard,
            startDate,
            endDate
          ),
          call(
            UniversalConsentAndPreferenceService.getConsentReceiptDashboard,
            null,
            startDate,
            endDate
          ),
          call(
            UniversalConsentAndPreferenceService.getConsentProcessingPurposeDashboard,
            1,
            startDate,
            endDate
          ),
        ]);

        const dataSubjectCount = findDashboardCount(
          dashboard.Items,
          "report_data_subject",
          "data_subject"
        );
        const receiptCount = findDashboardCount(
          dashboard.Items,
          "report_receipt_daily",
          "receipt_daily"
        );
        const rejectConsentCount = 0;
        const receiptConsentCount = findDashboardCount(
          dashboard.Items,
          "receipt_consent_count",
          "receipt"
        );

        const preferenceCenterChart = findDashboardChart(
          dashboard.Items,
          "preference_certers"
        );

        const processingPurposeChart = findDashboardChart(
          processPurpose.Items,
          "processing_purpose",
          "purpose"
        );

        const nextPageToken = receipt.body.next_page_token;

        yield put(
          getConsentManagementDashboardSuccess({
            receipt: {
              listReceipt: receipt.body.Items,
              nextPageToken,
            },
            processingPurpose: {
              currentPage: 1,
              prevPage: 1,
              totalPage: processPurpose.Items[0].total_page,
              dashboard: [processingPurposeChart],
            },
            dataSubjectCount,
            receiptCount,
            receiptConsentCount,
            rejectConsentCount,
            preferenceCenterChart,
          })
        );
        if (nextPageToken) {
          yield put({
            type: GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT,
            nextPageToken,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
}

export function* getNextPageDashboardReceipt() {
  yield takeEvery(
    GET_NEXT_PAGE_CONSENT_MANAGEMENT_DASHBOARD_RECEIPT,
    function* ({ nextPageToken }) {
      try {
        const dashboard = yield select(selectDashboard);
        const { filter } = dashboard;
        const receipt = yield call(
          UniversalConsentAndPreferenceService.getConsentReceiptDashboard,
          nextPageToken,
          filter.startDate,
          filter.endDate
        );
        yield put(
          getNextPageConsentManagementDashboardReceiptSuccess({
            listReceipt: dashboard.receipt.listReceipt.concat(
              receipt.body.Items
            ),
            nextPageToken: receipt.body.next_page_token,
          })
        );
      } catch (error) {
        console.log(error);
        yield put(getNextPageConsentManagementDashboardReceiptFailure(error));
      }
    }
  );
}

export function* getNextPageDashboardProcessingPurpose() {
  yield takeEvery(
    GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE,
    function* ({ currentPage }) {
      const { filter } = yield select(selectDashboard);
      let { prevPage, dashboard } = yield select(
        selectDashboardProcessingPurpose
      );
      try {
        const currentDashboard = dashboard[currentPage - 1];
        if (!currentDashboard) {
          const processPurpose = yield call(
            UniversalConsentAndPreferenceService.getConsentProcessingPurposeDashboard,
            currentPage,
            filter.startDate,
            filter.endDate
          );
          dashboard[currentPage - 1] = findDashboardChart(
            processPurpose.Items,
            "processing_purpose",
            "purpose"
          );
          yield put(
            getNextConsentManagementDashboardProcessingPurposeSuccess(dashboard)
          );
        }
      } catch (error) {
        yield put({
          GET_NEXT_CONSENT_MANAGEMENT_DASHBOARD_PROCESSING_PURPOSE,
          currentPage: prevPage,
        });
        Notification(
          "error",
          "ไม่สามารถดึงข้อมูลวัตถุประสงค์การประมวลผลวัตถุประสงค์การประมวลผลได้"
        );
      }
    }
  );
}
