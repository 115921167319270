export const LIST_CONSENT_MANAGEMENT_COLLECTION_POINT =
  "LIST_CONSENT_MANAGEMENT_COLLECTION_POINT";
export const LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS =
  "LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS";
export const GET_CONSENT_MANAGEMENT_COLLECTION_POINT =
  "GET_CONSENT_MANAGEMENT_COLLECTION_POINT";
export const GET_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS =
  "GET_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS";
export const TOGGLE_CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT =
  "TOGGLE_CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT";
export const CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT =
  "CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT";
export const CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS =
  "CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS";
export const CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT_ERROR =
  "CREATE_CONSENT_MANAGEMENT_COLLECTION_POINT_ERROR";
export const UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT =
  "UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT";
export const UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS =
  "UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS";
export const UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS =
  "UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS";
export const UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_SUCCESS =
  "UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_SUCCESS";
export const PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT =
  "PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT";
export const PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS =
  "PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT_SUCCESS";
export const PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT_ERROR =
  "PUBLISH_CONSENT_MANAGEMENT_COLLECTION_POINT_ERROR";

export const TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_SETTING =
  "TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_SETTING";
export const SET_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION =
  "SET_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION";
export const ADD_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_SECTION =
  "ADD_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_SECTION";
export const UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_SECTION =
  "UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_SECTION";
export const UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_TEXT =
  "UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_TEXT";
export const UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_STYLE =
  "UPDATE_CONSENT_MANAGEMENT_COLLECTION_POINT_FORM_STYLE";

export const TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION =
  "TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION";
export const DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION =
  "DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION";
export const TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT =
  "TOGGLE_DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT";
export const DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT =
  "DELETE_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT";
export const TOGGLE_SETTING_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT =
  "TOGGLE_SETTING_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT";
export const SETTING_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT =
  "SETTING_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT";
export const TOGGLE_MASK_IDENTIFY_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT =
  "TOGGLE_MASK_IDENTIFY_CONSENT_MANAGEMENT_COLLECTION_POINT_SECTION_COMPONENT";

export const TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS =
  "TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS";
export const CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS =
  "CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS";
export const CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_SUCCESS =
  "CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_SUCCESS";
export const CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_ERROR =
  "CHANGE_CONSENT_MANAGEMENT_COLLECTION_POINT_STATUS_ERROR";

export const TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_PREVIEW =
  "TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_PREVIEW";
export const TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_GET_SCRIPT =
  "TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_GET_SCRIPT";

export const LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION =
  "LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION";
export const LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_SUCCESS =
  "LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_SUCCESS";
export const LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_ERROR =
  "LIST_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_ERROR";
export const GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION =
  "GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION";
export const GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_SUCCESS =
  "GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_SUCCESS";
export const GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_ERROR =
  "GET_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION_ERROR";
export const TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION =
  "TOGGLE_CONSENT_MANAGEMENT_COLLECTION_POINT_VERSION";
