import antdTH from "antd/lib/locale-provider/th_TH";
import thMessages from "../locales/th-TH.json";

const ThLang = {
  messages: {
    ...thMessages,
  },
  antd: antdTH,
  locale: "th-TH",
};
export default ThLang;
