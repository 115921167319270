import { changeFormatDatetime } from "util/DateUtils";

const STATUS_COLOUR_TAG = {
  new: { color: "#545454", label: "รอดำเนินการ" },
  done: { color: "#04D182", label: "เสร็จสิ้น" },
  process: { color: "#EFC36D", label: "กำลังดำเนินการ" },
};

const SEVERITY_COLOUR_TAG = {
  low: { color: "#04D182", label: "ต่ำ" },
  medium: { color: "#EFC36D", label: "ปานกลาง" },
  high: { color: "#FA8C16", label: "สูง" },
  critical: { color: "#C74343", label: "รุนแรง" },
};

export default class EventModel {
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.description = data?.description;
    this.tags = data.tags ? data.tags : [];
    this.source = data.source ? data.source : "";
    this.severity = data?.severity;
    this.status = data?.status;
    this.create_at = data?.create_at;
    this.data = data?.data;
    this.end_date = data?.end_date;
    this.start_date = data?.start_date;
    this.workflow_name = data?.workflow_name;
  }

  displayID = () => this.id;
  displayName = () => this.name;
  displaySource = (sources) => {
    return (
      sources.find((item) => item.value === this.source)?.label ||
      "เงื่อนไขพื้นฐาน"
    );
  };

  isNewProcess = () => this.status.toLowerCase() === "new";

  displayStatusColor = () =>
    STATUS_COLOUR_TAG[this.status.toLowerCase()]?.color || "#04D182";
  displayStatusLabel = () =>
    STATUS_COLOUR_TAG[this.status.toLowerCase()]?.label || "ไม่พบ";
  displaySeverityColor = () =>
    SEVERITY_COLOUR_TAG[this.severity.toLowerCase()]?.color || "#04D182";
  displaySeverityLabel = () =>
    SEVERITY_COLOUR_TAG[this.severity.toLowerCase()]?.label || "ไม่พบ";

  displayCreateAt = () =>
    this.create_at !== "0001-01-01T00:00:00Z"
      ? changeFormatDatetime(this.create_at, "DD/MM/YYYY h:mm A")
      : "-";
}
