import dashboardReducer from "./dashboardReducer";
import requestReducer from "./requestReducer";
import webformReducer from "./webformReducer";
import workflowReducer from "./workflowReducer";
import taskReducer from "./taskReducer";

export default function rootReducers(state = {}, action) {
  return {
    dashboard: dashboardReducer(state.dashboard, action),
    request: requestReducer(state.request, action),
    webform: webformReducer(state.webform, action),
    workflow: workflowReducer(state.workflow, action),
    task: taskReducer(state.task, action),
  };
}
