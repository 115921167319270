import {
  RESET_ASSESSMENT_AUTOMATION_DATA,
  SELECT_ASSESSMENT_TYPE,
  LIST_ASSESSMENT_AUTOMATION_SUCCESS,
  CREATED_ASSESSMENT_AUTOMATION_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_SUCCESS,
  PUBLISH_ASSESSMENT_AUTOMATION_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_DATA,
  GET_ASSESSMENT_AUTOMATION_DATA_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
  ARCHIVED_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS,
  ACTIVATED_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_DELETED_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_DELETED_SUCCESS,
  ACTIVATED_ASSESSMENT_AUTOMATION_DELETED_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_TEMPLATE_FAILED,
  GET_ASSESSMENT_AUTOMATION_TEMPLATE_TYPE_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_TEMPLATE_BY_TYPE,
  GET_ASSESSMENT_AUTOMATION_TEMPLATE_BY_TYPE_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS,
  CLEAR_ASSESSMENT_AUTOMATION_PROCESS,
  LIST_ASSESSMENT_AUTOMATION_RESULT_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_RULES_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_RISK_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_RULES_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_RULES_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_RULES_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS,
  DELETE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_SENT_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_REPORT_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_REPORT_DATA_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_SUCCESS,
  GET_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_DATA,
  GET_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_DATA_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_TASK_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_TASK_IN_ASSESSMENT_SUCCESS,
  CREATE_ASSESSMENT_AUTOMATION_TASK_SUCCESS,
  UPDATE_ASSESSMENT_AUTOMATION_TASK_SUCCESS,
  CHANGE_ASSESSMENT_AUTOMATION_FORM_ASSESSMENT_FIELDS,
  UPDATE_ASSESSMENT_AUTOMATION_DATA_INVENTORY,
  ADD_ASSESSMENT_CONTROL_SUCCESS,
  GET_ASSESSMENT_CONTROL_SUCCESS,
  DELETE_ASSESSMENT_CONTROL_SUCCESS,
  LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE_SUCCESS,
} from "../constants/PrivacyKitAssessmentAutomation";
import _ from "lodash";

const initState = {
  assessmentReport: {
    radar: [],
    bar: [],
  },
};

const PrivacyKitAssessmentAutomation = (state = initState, action) => {
  switch (action.type) {
    case RESET_ASSESSMENT_AUTOMATION_DATA: {
      return {
        ...state,
        ...initState,
        assessmentData: undefined,
        taskInAssessment: undefined,
        listAssessmentControl: undefined,
        submitReport: undefined,
        listSubmitReport: undefined,
        listAssessmentTemplateType: undefined,
      };
    }
    case SELECT_ASSESSMENT_TYPE: {
      return {
        // ...state,
        assessmentType: action.payload,
      };
    }
    case CREATED_ASSESSMENT_AUTOMATION_SUCCESS: {
      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          data: [action.payload, ...state.listAssessment.data],
        },
      };
    }
    case UPDATE_ASSESSMENT_AUTOMATION_SUCCESS: {
      if (!state.listAssessment) {
        return {
          ...state,
          assessmentData: action.payload.data,
        };
      }
      const updateListAssessment = [...state.listAssessment.data];
      const updatedIndex = _.findIndex(updateListAssessment, {
        ObjectUUID: action.payload.assessmentId,
      });
      updateListAssessment[updatedIndex] = action.payload.data;
      return {
        ...state,
        assessmentData: action.payload.data,
        listAssessment: {
          ...state.listAssessment,
          data: updateListAssessment,
        },
      };
    }
    case PUBLISH_ASSESSMENT_AUTOMATION_SUCCESS: {
      const updateListAssessment = [...state.listAssessment.data];
      const updatedIndex = _.findIndex(updateListAssessment, {
        ObjectUUID: action.payload.ObjectUUID,
      });
      updateListAssessment[updatedIndex] = action.payload;

      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          data: updateListAssessment,
        },
        assessmentData: action.payload,
      };
    }
    case DELETE_ASSESSMENT_AUTOMATION_SUCCESS: {
      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          data: _.filter(
            state.listAssessment.data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload
          ),
        },
      };
    }
    case GET_ASSESSMENT_AUTOMATION_DATA: {
      return {
        ...state,
        assessmentId: action.payload.assessmentId,
        assessmentData: undefined,
        listAssessmentControl: undefined,
      };
    }
    case GET_ASSESSMENT_AUTOMATION_DATA_SUCCESS: {
      return {
        ...state,
        assessmentData: action.payload,
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_SUCCESS: {
      return {
        ...state,
        listAssessment: action.payload,
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS: {
      return {
        ...state,
        assessmentActive: undefined,
        listAssessmentActive: action.payload,
      };
    }
    case CREATE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS: {
      return {
        ...state,
        listAssessmentActive: {
          data: [action.payload, ...state.listAssessmentActive.data],
        },
      };
    }
    case GET_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS: {
      return {
        ...state,
        assessmentActive: action.payload,
        ...state,
        listAssessmentActive: { ...state.listAssessmentActive },
      };
    }
    case UPDATE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS: {
      const { data } = state.listAssessmentActive;
      const dataIndex = _.findIndex(
        data,
        ({ ObjectUUID }) => ObjectUUID === action.payload.ObjectUUID
      );
      data[dataIndex] = action.payload;
      return {
        ...state,
        listAssessmentActive: { ...state.listAssessmentActive, data },
        ...state,
        assessmentActive: { ...action.payload, ...state.assessmentActive },
      };
    }
    case ARCHIVED_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS: {
      const { data } = state.listAssessmentActive;
      return {
        ...state,
        listAssessmentActive: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
        listAssessmentArchived: state.listAssessmentArchived
          ? {
              ...state.listAssessmentArchived,
              data: [action.payload, ...state.listAssessmentArchived.data],
            }
          : undefined,
      };
    }
    case DELETE_ASSESSMENT_AUTOMATION_ACTIVE_SUCCESS: {
      const { data } = state.listAssessmentActive;
      return {
        ...state,
        listAssessmentActive: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
        listAssessmentDeleted: state.listAssessmentDeleted
          ? {
              ...state.listAssessmentDeleted,
              data: [action.payload, ...state.listAssessmentDeleted.data],
            }
          : undefined,
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS: {
      return {
        ...state,
        assessmentArchived: undefined,
        listAssessmentArchived: action.payload,
      };
    }
    case ACTIVATED_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS: {
      const { data } = state.listAssessmentArchived;
      return {
        ...state,
        listAssessmentArchived: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
        listAssessmentActive: state.listAssessmentActive
          ? {
              ...state.listAssessmentActive,
              data: [action.payload, ...state.listAssessmentActive.data],
            }
          : undefined,
      };
    }
    case DELETE_ASSESSMENT_AUTOMATION_ARCHIVED_SUCCESS: {
      const { data } = state.listAssessmentArchived;
      return {
        ...state,
        listAssessmentArchived: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
        listAssessmentDeleted: state.listAssessmentDeleted
          ? {
              ...state.listAssessmentDeleted,
              data: [action.payload, ...state.listAssessmentDeleted.data],
            }
          : undefined,
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_DELETED_SUCCESS: {
      return {
        ...state,
        assessmentDeleted: undefined,
        listAssessmentDeleted: action.payload,
      };
    }
    case ACTIVATED_ASSESSMENT_AUTOMATION_DELETED_SUCCESS: {
      const { data } = state.listAssessmentDeleted;
      return {
        ...state,
        listAssessmentDeleted: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
        listAssessmentActive: state.listAssessmentActive
          ? {
              ...state.listAssessmentActive,
              data: [action.payload, ...state.listAssessmentActive.data],
            }
          : undefined,
      };
    }
    case DELETE_ASSESSMENT_AUTOMATION_DELETED_SUCCESS: {
      const { data } = state.listAssessmentDeleted;
      return {
        ...state,
        listAssessmentDeleted: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_RISK_SUCCESS: {
      return {
        ...state,
        assessmentRisk: undefined,
        listAssessmentRisk: action.payload,
      };
    }
    case CREATE_ASSESSMENT_AUTOMATION_RISK_SUCCESS: {
      return {
        ...state,
        listAssessmentRisk: {
          data: [action.payload, ...state.listAssessmentRisk.data],
        },
      };
    }
    case UPDATE_ASSESSMENT_AUTOMATION_RISK_SUCCESS: {
      const { data } = state.listAssessmentRisk;
      return {
        ...state,
        listAssessmentRisk: {
          data: [
            action.payload,
            ...state.listAssessmentRisk.data,
            _.filter(
              data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
            ),
          ],
        },
        ...state,
        assessmentRisk: { ...action.payload, ...state.assessmentRisk },
      };
    }
    case DELETE_ASSESSMENT_AUTOMATION_RISK_SUCCESS: {
      const { data } = state.listAssessmentRisk;
      return {
        ...state,
        listAssessmentRisk: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS: {
      return {
        ...state,
        listAssessmentTemplate: action.payload,
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_TEMPLATE_FAILED: {
      return state;
    }
    case GET_ASSESSMENT_AUTOMATION_TEMPLATE_TYPE_SUCCESS: {
      return {
        ...state,
        listAssessmentTemplateType: action.payload,
      };
    }
    case GET_ASSESSMENT_AUTOMATION_TEMPLATE_BY_TYPE: {
      return {
        ...state,
        listAssessmentTemplate: undefined,
      };
    }
    case GET_ASSESSMENT_AUTOMATION_TEMPLATE_BY_TYPE_SUCCESS: {
      return {
        ...state,
        listAssessmentTemplate: action.payload,
      };
    }
    case CREATE_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS: {
      return {
        ...state,
        listAssessmentTemplate: {
          data: [action.payload, ...state.listAssessmentTemplate.data],
        },
      };
    }
    case DELETE_ASSESSMENT_AUTOMATION_TEMPLATE_SUCCESS: {
      return {
        ...state,
        listAssessmentTemplate: {
          data: [
            ..._.filter(
              state.listAssessmentTemplate.data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload
            ),
          ],
        },
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS: {
      return {
        ...state,
        assessmentProcess: undefined,
        listAssessmentProcess: action.payload,
      };
    }
    case CREATE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS: {
      return {
        ...state,
        listAssessmentProcess: {
          data: [action.payload, ...state.listAssessmentProcess.data],
        },
      };
    }
    case GET_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS: {
      return {
        ...state,
        assessmentProcess: action.payload,
      };
    }
    case UPDATE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS: {
      return {
        ...state,
        assessmentProcess: { ...state.assessmentProcess, ...action.payload },
      };
    }
    case DELETE_ASSESSMENT_AUTOMATION_PROCESS_SUCCESS: {
      const { data } = state.listAssessmentProcess;
      return {
        ...state,
        listAssessmentProcess: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    case CLEAR_ASSESSMENT_AUTOMATION_PROCESS: {
      state["assessmentProcess"] = undefined;
      return {
        ...state,
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_RESULT_SUCCESS: {
      return {
        ...state,
        assessmentResult: undefined,
        listAssessmentResult: action.payload,
      };
    }
    case CREATE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS: {
      return {
        ...state,
        listAssessmentResult: {
          data: [action.payload, ...state.listAssessmentResult.data],
        },
      };
    }
    case UPDATE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS: {
      const { data } = state.listAssessmentResult;
      return {
        ...state,
        listAssessmentResult: {
          data: [
            action.payload,
            ...state.listAssessmentResult.data,
            _.filter(
              data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
            ),
          ],
        },
        ...state,
        assessmentResult: { ...action.payload, ...state.assessmentResult },
      };
    }
    case DELETE_ASSESSMENT_AUTOMATION_RESULT_SUCCESS: {
      const { data } = state.listAssessmentResult;
      return {
        ...state,
        listAssessmentResult: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_RULES_SUCCESS: {
      return {
        ...state,
        assessmentRules: undefined,
        listAssessmentRules: action.payload,
      };
    }
    case CREATE_ASSESSMENT_AUTOMATION_RULES_SUCCESS: {
      return {
        ...state,
        listAssessmentRules: {
          data: [action.payload, ...state.listAssessmentRules.data],
        },
      };
    }
    case UPDATE_ASSESSMENT_AUTOMATION_RULES_SUCCESS: {
      const { data } = state.listAssessmentRules;
      return {
        ...state,
        listAssessmentRules: {
          data: [
            action.payload,
            ...state.listAssessmentRules.data,
            _.filter(
              data,
              ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
            ),
          ],
        },
        ...state,
        assessmentRules: { ...action.payload, ...state.assessmentRules },
      };
    }
    case DELETE_ASSESSMENT_AUTOMATION_RULES_SUCCESS: {
      const { data } = state.listAssessmentRules;
      return {
        ...state,
        listAssessmentRules: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS: {
      return {
        ...state,
        listAssessmentNotSent: action.payload,
      };
    }
    case CREATE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS: {
      if (!state.listAssessmentNotSent) {
        return state;
      }
      return {
        ...state,
        listAssessmentNotSent: {
          ...state.listAssessmentNotSent,
          data: [action.payload, ...state.listAssessmentNotSent.data],
        },
      };
    }
    case UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS: {
      if (!state.listAssessmentNotSent) {
        return {
          ...state,
          assessmentData: action.payload.data,
        };
      }
      const updateListAssessment = [...state.listAssessmentNotSent.data];
      const updatedIndex = _.findIndex(updateListAssessment, {
        ObjectUUID: action.payload.assessmentId,
      });
      updateListAssessment[updatedIndex] = action.payload.data;
      return {
        ...state,
        assessmentData: action.payload.data,
        listAssessmentNotSent: {
          ...state.listAssessmentNotSent,
          data: updateListAssessment,
        },
      };
    }
    case DELETE_ASSESSMENT_AUTOMATION_NOT_SENT_SUCCESS: {
      return {
        ...state,
        listAssessmentNotSent: {
          ...state.listAssessmentNotSent,
          data: _.filter(
            state.listAssessmentNotSent.data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload
          ),
        },
      };
    }
    case UPDATE_ASSESSMENT_AUTOMATION_NOT_SENT_TO_SENT_SUCCESS: {
      const { data } = state.listAssessmentNotSent;
      return {
        ...state,
        listAssessmentNotSent: {
          data: _.filter(
            data,
            ({ ObjectUUID }) => ObjectUUID !== action.payload.ObjectUUID
          ),
        },
        listAssessmentSent: state.listAssessmentSent
          ? {
              ...state.listAssessmentSent,
              data: [action.payload, ...state.listAssessmentSent.data],
            }
          : undefined,
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_SENT_SUCCESS: {
      return {
        ...state,
        listAssessmentSent: action.payload,
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_SENT_NEXT_PAGE_SUCCESS: {
      return {
        ...state,
        listAssessmentSent: {
          data: [...state.listAssessmentSent.data, ...action.payload.data],
          statusCode: action.payload.statusCode,
          next_page_token: action.payload.next_page_token,
        },
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_NOT_SENT_NEXT_PAGE_SUCCESS: {
      return {
        ...state,
        listAssessmentNotSent: {
          data: [...state.listAssessmentNotSent.data, ...action.payload.data],
          statusCode: action.payload.statusCode,
          next_page_token: action.payload.next_page_token,
        },
      };
    }
    case GET_ASSESSMENT_AUTOMATION_REPORT_SUCCESS: {
      return {
        ...state,
        assessmentData: action.payload,
      };
    }
    case GET_ASSESSMENT_AUTOMATION_REPORT_DATA_SUCCESS: {
      return {
        ...state,
        assessmentReport: action.payload,
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_SUCCESS: {
      return {
        ...state,
        listSubmitReport: action.payload,
      };
    }
    case GET_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_DATA: {
      return {
        ...state,
        submitReport: undefined,
      };
    }
    case GET_ASSESSMENT_AUTOMATION_SUBMIT_REPORT_DATA_SUCCESS: {
      return {
        ...state,
        submitReport: action.payload,
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_TASK_SUCCESS: {
      return {
        ...state,
        listTasks: action.payload,
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_TASK_NEXT_PAGE_SUCCESS: {
      return {
        ...state,
        listTasks: {
          data: [...state.listTasks.data, ...action.payload.data],
          statusCode: action.payload.statusCode,
          next_page_token: action.payload.next_page_token,
        },
      };
    }
    case LIST_ASSESSMENT_AUTOMATION_TASK_IN_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        taskInAssessment: action.payload,
      };
    }
    case CREATE_ASSESSMENT_AUTOMATION_TASK_SUCCESS: {
      return {
        ...state,
        taskInAssessment: [...state.taskInAssessment, action.payload],
      };
    }
    case UPDATE_ASSESSMENT_AUTOMATION_TASK_SUCCESS: {
      let taskInAssessment = state.taskInAssessment
        ? [...state.taskInAssessment]
        : undefined;
      let tasks = state.listTasks ? [...state.listTasks.data] : undefined;

      if (tasks) {
        const destinationIndex = _.findIndex(tasks, {
          ObjectUUID: action.payload.ObjectUUID,
        });
        tasks[destinationIndex] = { ...action.payload };
      }

      if (taskInAssessment) {
        const destinationIndex = _.findIndex(taskInAssessment, {
          ObjectUUID: action.payload.ObjectUUID,
        });
        taskInAssessment[destinationIndex] = { ...action.payload };
      }

      return {
        ...state,
        listTasks: state.listTasks
          ? { ...state.listTasks, data: [...tasks] }
          : undefined,
        taskInAssessment: state.taskInAssessment
          ? [...taskInAssessment]
          : undefined,
      };
    }
    case CHANGE_ASSESSMENT_AUTOMATION_FORM_ASSESSMENT_FIELDS: {
      let assessmentDataUpdate = { ...state.assessmentData };
      assessmentDataUpdate.form.assessmentFields = action.assessmentFields;
      return {
        ...state,
        assessmentData: assessmentDataUpdate,
      };
    }
    case UPDATE_ASSESSMENT_AUTOMATION_DATA_INVENTORY: {
      return {
        ...state,
        assessmentData: {
          ...state.assessmentData,
          assessment_risk: {
            ...state.assessmentData.assessment_risk,
            ...action.payload,
          },
        },
      };
    }
    case ADD_ASSESSMENT_CONTROL_SUCCESS: {
      return {
        ...state,
        listAssecssmentControl: {
          data: [...action.payload, ...state.listAssecssmentControl.data],
        },
      };
    }
    case GET_ASSESSMENT_CONTROL_SUCCESS: {
      return {
        ...state,
        listAssecssmentControl: {
          data: [...action.payload],
        },
      };
    }
    case DELETE_ASSESSMENT_CONTROL_SUCCESS: {
      const { data } = state.listAssecssmentControl;
      return {
        ...state,
        listAssecssmentControl: {
          data: _.filter(
            data,
            ({ link_id }) => link_id !== action.payload.link_id
          ),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default PrivacyKitAssessmentAutomation;
