import { saveToLS, getFromLS } from "util/LocalStorage";
import {
  // Agent
  SET_AGENT,

  // Scanner
  SET_SCANNER_DASHBOARD,
  SET_SCANNER_LIST,
  SET_SCANNER_TASK,
  SET_SCANNER_FOUND,
  SET_SCANNER_DETAIL,
} from "redux/constants/CyberKitVulnerabilityAssessment";

import { CyberKitVulnerabilityAssessmentDetailModel } from "models";

const CYBERKIT = "CYBERKIT";
const CYBERKIT_VULNERABILITY_ASSESSMENT = "CYBERKIT_VULNERABILITY_ASSESSMENT";

const initState = {
  agent: {
    dataList: [],
  },
  scanner: {
    dashboard: [],
    scanner_list: [],
    scanner_tasks: [],
    scanner_found: [],
    scanner_detail: new CyberKitVulnerabilityAssessmentDetailModel({}),
    meta: [],
  },
};

const CyberKitVulnerabilityAssessment = (
  state = getFromLS(CYBERKIT, CYBERKIT_VULNERABILITY_ASSESSMENT)
    ? getFromLS(CYBERKIT, CYBERKIT_VULNERABILITY_ASSESSMENT)
    : initState,
  action
) => {
  const { type, payload } = action;
  let changed = state;

  switch (type) {
    // Agent
    case SET_AGENT: {
      changed = {
        ...state,
        agent: {
          ...state.agent,
          dataList: payload,
        },
      };
      break;
    }

    // Scanner
    case SET_SCANNER_DASHBOARD: {
      changed = {
        ...state,
        scanner: {
          ...state.scanner,
          dashboard: payload,
        },
      };
      break;
    }

    case SET_SCANNER_LIST: {
      changed = {
        ...state,
        scanner: {
          ...state.scanner,
          scanner_list: payload,
        },
      };
      break;
    }

    case SET_SCANNER_TASK: {
      changed = {
        ...state,
        scanner: {
          ...state.scanner,
          scanner_tasks: payload,
        },
      };
      break;
    }

    case SET_SCANNER_FOUND: {
      changed = {
        ...state,
        scanner: {
          ...state.scanner,
          scanner_found: payload?.data,
          meta: payload?.meta,
        },
      };
      break;
    }

    case SET_SCANNER_DETAIL: {
      changed = {
        ...state,
        scanner: {
          ...state.scanner,
          scanner_detail: payload,
        },
      };
      break;
    }

    default:
      break;
  }

  if (localStorage.getItem("auth") !== null)
    saveToLS(CYBERKIT, CYBERKIT_VULNERABILITY_ASSESSMENT, changed);
  return changed;
};

export default CyberKitVulnerabilityAssessment;
