import {
  LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  SET_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  CREATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  UPDATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  TOGGLE_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  TOGGLE_DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE,
  DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS,
  DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_ERROR,
} from "../../constants/PrivacyKitConsentManagement";
import { replaceObject } from "util/arrayUtils";
import _ from "lodash";

const initialState = {
  loading: false,
  deleteProcessingPurpose: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
  manageProcessingPurpose: {
    visible: false,
    loading: false,
    data: null,
    processingPurposeId: null,
    okButtonProps: {
      loading: false,
    },
  },
  listProcessingPurpose: null,
};

const ProcessingPurposeReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE: {
      return {
        ...state,
        listProcessingPurpose: null,
        loading: true,
      };
    }
    case LIST_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS: {
      return {
        ...state,
        listProcessingPurpose: action.listProcessingPurpose,
        loading: false,
      };
    }
    case GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE: {
      return {
        ...state,
        manageProcessingPurpose: {
          ...state.manageProcessingPurpose,
          loading: true,
        },
      };
    }
    case GET_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS: {
      return {
        ...state,
        manageProcessingPurpose: {
          ...state.manageProcessingPurpose,
          data: action.data,
          loading: false,
        },
      };
    }
    case SET_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE: {
      return {
        ...state,
        manageProcessingPurpose: {
          ...state.manageProcessingPurpose,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case CREATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS: {
      return {
        ...state,
        manageProcessingPurpose: {
          ...initialState.manageProcessingPurpose,
        },
        listProcessingPurpose: [action.data, ...state.listProcessingPurpose],
      };
    }
    case UPDATE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS: {
      return {
        ...state,
        manageProcessingPurpose: {
          ...initialState.manageProcessingPurpose,
        },
        listProcessingPurpose: [
          ...replaceObject(
            state.listProcessingPurpose,
            action.processingPurposeId,
            action.data
          ),
        ],
      };
    }
    case TOGGLE_MANAGE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE: {
      return {
        ...state,
        manageProcessingPurpose: {
          ...state.manageProcessingPurpose,
          data: null,
          visible: !state.manageProcessingPurpose.visible,
          processingPurposeId: action.processingPurposeId,
        },
      };
    }
    case TOGGLE_DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE: {
      return {
        ...state,
        deleteProcessingPurpose: {
          ...state.deleteProcessingPurpose,
          visible: !state.deleteProcessingPurpose.visible,
          data: action?.payload ?? null,
        },
      };
    }
    case DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE: {
      return {
        ...state,
        deleteProcessingPurpose: {
          ...state.deleteProcessingPurpose,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_SUCCESS: {
      return {
        ...state,
        listProcessingPurpose: [
          ..._.filter(
            state.listProcessingPurpose,
            ({ ObjectUUID }) => ObjectUUID !== action.processingPurposeId
          ),
        ],
        deleteProcessingPurpose: initialState.deleteProcessingPurpose,
      };
    }
    case DELETE_CONSENT_MANAGEMENT_PROCESSING_PURPOSE_ERROR: {
      return {
        ...state,
        deleteProcessingPurpose: {
          ...state.deleteProcessingPurpose,
          okButtonProps: initialState.deleteProcessingPurpose.okButtonProps,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default ProcessingPurposeReducer;
