import { all, fork } from "redux-saga/effects";
import {
  listDataMappingDataWhereHouse,
  manageDataMappingDataWhereHouse,
  createDataMappingDataWhereHouse,
  updateDataMappingDataWhereHouse,
  deleteDataMappingDataWhereHouse,
  listDataMappingDataWhereHouseGroup,
} from "./dataWarehouse";
import {
  listDataMappingRelatedControlMeasuresSaga,
  listDataMappingRelatedControlMeasuresGroupSaga,
  manageDataMappingRelatedControlMeasuresSaga,
  createDataMappingRelatedControlMeasuresSaga,
  updateDataMappingRelatedControlMeasuresSaga,
  deleteDataMappingRelatedControlMeasuresSaga,
} from "./relatedControlMeasures";

export default function* rootSaga() {
  yield all([
    fork(listDataMappingDataWhereHouse),
    fork(manageDataMappingDataWhereHouse),
    fork(createDataMappingDataWhereHouse),
    fork(updateDataMappingDataWhereHouse),
    fork(deleteDataMappingDataWhereHouse),
    fork(listDataMappingDataWhereHouseGroup),
    fork(listDataMappingRelatedControlMeasuresSaga),
    fork(listDataMappingRelatedControlMeasuresGroupSaga),
    fork(manageDataMappingRelatedControlMeasuresSaga),
    fork(createDataMappingRelatedControlMeasuresSaga),
    fork(updateDataMappingRelatedControlMeasuresSaga),
    fork(deleteDataMappingRelatedControlMeasuresSaga),
  ]);
}
