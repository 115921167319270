import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_EVENT_BY_ID,
  GET_FEED,
  FOLLOW_EVENT,
  UNFOLLOW_EVENT,
} from "redux/constants/CyberKitThreatIntelligence";
import { setGlobalLoading } from "redux/actions/CyberKitTool";
import {
  setEventDetail,
  setFeed,
} from "redux/actions/CyberKitThreatIntelligence";
import ThreatIntelligenceServices from "services/cyberkit/ThreatIntelligenceServices";

import convertJsonParamsToString from "util/convertJsonParamsToString";
import Notification from "util/Notification";

export function* getEventDetail() {
  yield takeEvery(GET_EVENT_BY_ID, function* ({ payload }) {
    yield put(setGlobalLoading(true));
    let params = ``;
    const keyParamList = Object.keys(payload?.params || {});
    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);

    const payloadService = {
      params: params,
      path: payload.path,
    };

    const { status, data } = yield call(
      ThreatIntelligenceServices.getEventByID,
      payloadService
    );

    if (status === "OK") {
      yield put(
        setEventDetail({
          data: data,
        })
      );
      yield put(setGlobalLoading(false));
    } else {
      Notification("error", "Something went wrong");
      yield put(setGlobalLoading(false));
    }
  });
}

export function* getFeed() {
  yield takeEvery(GET_FEED, function* ({ payload }) {
    let params = ``;
    const keyParamList = Object.keys(payload?.params || {});
    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);

    const payloadService = {
      params: params,
    };

    const { status, data } = yield call(
      ThreatIntelligenceServices.getFeed,
      payloadService
    );

    if (status === "OK") {
      yield put(setFeed(data));
    }
  });
}

export function* followEvent() {
  yield takeEvery(FOLLOW_EVENT, function* ({ payload }) {
    yield put(setGlobalLoading(true));
    const { status } = yield call(
      ThreatIntelligenceServices.followEvent,
      payload
    );

    if (status === "OK") {
      yield put(setGlobalLoading(false));
      payload.action();
    } else {
      yield put(setGlobalLoading(false));
    }
  });
}

export function* unfollowEvent() {
  yield takeEvery(UNFOLLOW_EVENT, function* ({ payload }) {
    yield put(setGlobalLoading(true));
    let params = ``;
    const keyParamList = Object.keys(payload?.params || {});
    if (keyParamList.length > 0)
      params = convertJsonParamsToString(payload.params);

    const payloadService = {
      params: params,
      path: payload.path,
    };

    const { status } = yield call(
      ThreatIntelligenceServices.unfollowEvent,
      payloadService
    );

    if (status === "OK") {
      yield put(setGlobalLoading(false));
      payload.action();
    } else {
      yield put(setGlobalLoading(false));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getEventDetail),
    fork(getFeed),
    fork(followEvent),
    fork(unfollowEvent),
  ]);
}
