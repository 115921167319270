import {
  SET_LOADING,
  GET_PAGINATE,
  SET_METADATA,
  SET_METADATA_TOTAL_PAGE,
  SET_METADATA_CURRENT_PAGE,
  SET_METADATA_PAGE_SIZE,
  CLEAR_METADATA,
  GET_AGENT_LIST,
  SET_GLOBAL_LOADING,
} from "redux/constants/CyberKitTool";

// PAGINATE
export const setLoading = (status) => {
  return {
    type: SET_LOADING,
    payload: status,
  };
};

export const getPaginate = (payload) => {
  return {
    type: GET_PAGINATE,
    payload: payload,
  };
};

export const setMetadata = (payload) => {
  return {
    type: SET_METADATA,
    payload: payload,
  };
};

export const setTotalPage = (payload) => {
  return {
    type: SET_METADATA_TOTAL_PAGE,
    payload: payload,
  };
};

export const setCurrentPage = (payload) => {
  return {
    type: SET_METADATA_CURRENT_PAGE,
    payload: payload,
  };
};

export const setPageSize = (payload) => {
  return {
    type: SET_METADATA_PAGE_SIZE,
    payload: payload,
  };
};

export const clearMetadata = (payload) => {
  return {
    type: CLEAR_METADATA,
  };
};

// COMMON
export const getAgentList = (payload) => {
  return {
    type: GET_AGENT_LIST,
    payload: payload,
  };
};

export const setGlobalLoading = (payload) => {
  return {
    type: SET_GLOBAL_LOADING,
    payload: payload,
  };
};
