export const selectProcessingPurpose = (state) =>
  state.privacyKitConsentManagement.processingPurpose;

export const selectListProcessingPurpose = (state) =>
  state.privacyKitConsentManagement.processingPurpose.listProcessingPurpose;

export const selectManageProcessingPurpose = (state) =>
  state.privacyKitConsentManagement.processingPurpose.manageProcessingPurpose;

export const selectDeleteProcessingPurpose = (state) =>
  state.privacyKitConsentManagement.processingPurpose.deleteProcessingPurpose;
