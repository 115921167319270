import {
  LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS,
  LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE,
  LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP,
  LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP_SUCCESS,
  LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP_FAILURE,
  TOGGLE_MANAGE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  TOGGLE_DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  MANAGE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS,
  CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE,
  UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS,
  UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE,
  DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS,
  DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE,
} from "../../constants/IPrivacyKitDataMapping";

export const listDataMappingRelatedControlMeasures = () => ({
  type: LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES,
});

export const listDataMappingRelatedControlMeasuresSuccess = (data) => ({
  type: LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS,
  payload: data,
});

export const listDataMappingRelatedControlMeasuresFailure = (error) => ({
  type: LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE,
  payload: error,
});

export const listDataMappingRelatedControlMeasuresGroup = () => ({
  type: LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP,
});

export const listDataMappingRelatedControlMeasuresGroupSuccess = (data) => ({
  type: LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP_SUCCESS,
  payload: data,
});

export const listDataMappingRelatedControlMeasuresGroupFailure = (error) => ({
  type: LIST_DATA_MAPPING_RELATED_CONTROL_MEASURES_GROUP_FAILURE,
  error,
});

export const toggleManageDataMappingRelatedControlMeasures = (data) => ({
  type: TOGGLE_MANAGE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  data,
});

export const toggleDeleteDataMappingRelatedControlMeasures = (data) => ({
  type: TOGGLE_DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  data,
});

export const manageDataMappingRelatedControlMeasures = (values) => ({
  type: MANAGE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
  values,
});

export const createDataMappingRelatedControlMeasuresSuccess = (data) => ({
  type: CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS,
  payload: data,
});

export const createDataMappingRelatedControlMeasuresFailure = (error) => ({
  type: CREATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE,
  payload: error,
});

export const updateDataMappingRelatedControlMeasuresSuccess = (data) => ({
  type: UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS,
  payload: data,
});

export const updateDataMappingRelatedControlMeasuresFailure = (error) => ({
  type: UPDATE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE,
  payload: error,
});

export const deleteDataMappingRelatedControlMeasures = () => ({
  type: DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES,
});

export const deleteDataMappingRelatedControlMeasuresSuccess = (data) => ({
  type: DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES_SUCCESS,
  payload: data,
});

export const deleteDataMappingRelatedControlMeasuresFailure = (error) => ({
  type: DELETE_DATA_MAPPING_RELATED_CONTROL_MEASURES_FAILURE,
  payload: error,
});
