import { AbilityBuilder, Ability } from "@casl/ability";

const { can, rules } = new AbilityBuilder(Ability);

const updateAbility = (ability, permissions) => {
  const permissionUpdated = permissions.filter((key) => key.includes("apps."));
  permissionUpdated.forEach((key) => {
    const [action, ability] = key.split("|");
    can(action, ability);
  });

  can("put", "apps.cyberkit.log-management.source");
  can("post", "apps.cyberkit.log-management.source");
  can("delete", "apps.cyberkit.log-management.source");
  can("put", "apps.cyberkit.log-management.explorer");
  can("delete", "apps.cyberkit.log-management.explorer");

  can("put", "apps.cyberkit.siem.event");
  can("put", "apps.cyberkit.siem.security-rule");
  can("post", "apps.cyberkit.siem.security-rule");
  can("delete", "apps.cyberkit.siem.security-rule");
  can("put", "apps.cyberkit.siem.notification");
  can("post", "apps.cyberkit.siem.notification");
  can("delete", "apps.cyberkit.siem.notification");

  can("delete", "apps.cyberkit.soar.task-management");
  can("put", "apps.cyberkit.soar.response-planning");
  can("post", "apps.cyberkit.soar.response-planning");
  can("delete", "apps.cyberkit.soar.response-planning");

  can("put", "apps.cyberkit.it-asset.asset");
  can("post", "apps.cyberkit.it-asset.asset");
  can("delete", "apps.cyberkit.it-asset.asset");

  can("put", "apps.cyberkit.va.agent");
  can("post", "apps.cyberkit.va.agent");
  can("delete", "apps.cyberkit.va.agent");
  can("put", "apps.cyberkit.va.scanner");
  can("post", "apps.cyberkit.va.scanner");
  can("delete", "apps.cyberkit.va.scanner");
  //
  // can("post","apps.privacykit.privacy_assessment_automation.assessment.create");
  // can("put", "apps.privacykit.data_breach_notification.task.update_task");

  ability.update(rules);
};

export const ability = new Ability(rules);

export default updateAbility;
