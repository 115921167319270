export const LIST_USER_MANAGEMENT_ORGANIZATION =
  "LIST_USER_MANAGEMENT_ORGANIZATION";
export const LIST_USER_MANAGEMENT_ORGANIZATION_SUCCESS =
  "LIST_USER_MANAGEMENT_ORGANIZATION_SUCCESS";
export const LIST_USER_MANAGEMENT_ORGANIZATION_FAILED =
  "LIST_USER_MANAGEMENT_ORGANIZATION_FAILED";

export const LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL =
  "LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL";
export const LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL_SUCCESS =
  "LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL_SUCCESS";
export const LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL_FAILED =
  "LIST_USER_MANAGEMENT_ORGANIZATION_DETAIL_FAILED";

export const LIST_USER_MANAGEMENT_USER = "LIST_USER_MANAGEMENT_USER";
export const LIST_USER_MANAGEMENT_USER_SUCCESS =
  "LIST_USER_MANAGEMENT_USER_SUCCESS";
export const LIST_USER_MANAGEMENT_USER_FAILED =
  "LIST_USER_MANAGEMENT_USER_FAILED";

export const ADD_USER_MANAGEMENT_USER = "ADD_USER_MANAGEMENT_USER";
export const ADD_USER_MANAGEMENT_USER_SUCCESS =
  "ADD_USER_MANAGEMENT_USER_SUCCESS";
export const ADD_USER_MANAGEMENT_USER_FAILED =
  "ADD_USER_MANAGEMENT_USER_FAILED";

export const LIST_USER_MANAGEMENT_USER_DETAIL =
  "LIST_USER_MANAGEMENT_USER_DETAIL";
export const LIST_USER_MANAGEMENT_USER_DETAIL_SUCCESS =
  "LIST_USER_MANAGEMENT_USER_DETAIL_SUCCESS";
export const LIST_USER_MANAGEMENT_USER_DETAIL_FAILED =
  "LIST_USER_MANAGEMENT_USER_DETAIL_FAILED";

export const LIST_USER_MANAGEMENT_ROLES = "LIST_USER_MANAGEMENT_ROLES";
export const LIST_USER_MANAGEMENT_ROLES_SUCCESS =
  "LIST_USER_MANAGEMENT_ROLES_SUCCESS ";
export const LIST_USER_MANAGEMENT_ROLES_FAILED =
  "LIST_USER_MANAGEMENT_ROLES_FAILED";

export const LIST_USER_MANAGEMENT_ROLES_DETAIL =
  "LIST_USER_MANAGEMENT_ROLES_DETAIL";
export const LIST_USER_MANAGEMENT_ROLES_DETAIL_SUCCESS =
  "LIST_USER_MANAGEMENT_ROLES_DETAIL_SUCCESS ";
export const LIST_USER_MANAGEMENT_ROLES_DETAIL_FAILED =
  "LIST_USER_MANAGEMENT_ROLES_DETAIL_FAILED";

export const LIST_USER_MANAGEMENT_ROLES_PERMISSIONS =
  "LIST_USER_MANAGEMENT_ROLES_PERMISSIONS";
export const LIST_USER_MANAGEMENT_ROLES_PERMISSIONS_SUCCESS =
  "LIST_USER_MANAGEMENT_ROLES_PERMISSIONS_SUCCESS ";
export const LIST_USER_MANAGEMENT_ROLES_PERMISSIONS_FAILED =
  "LIST_USER_MANAGEMENT_ROLES_PERMISSIONS_FAILED";

export const LIST_USER_MANAGEMENT_USER_GROUP_DETAIL =
  "LIST_USER_MANAGEMENT_USER_DETAIL";
export const LIST_USER_MANAGEMENT_USER_GROUP_DETAIL_SUCCESS =
  "LIST_USER_MANAGEMENT_USER_GROUP_DETAIL_SUCCESS";
export const LIST_USER_MANAGEMENT_USER_GROUP_DETAIL_FAILED =
  "LIST_USER_MANAGEMENT_USER_GROUP_DETAIL_FAILED";

export const LIST_USER_MANAGEMENT_USER_GROUP =
  "LIST_USER_MANAGEMENT_USER_GROUP";
export const LIST_USER_MANAGEMENT_USER_GROUP_SUCCESS =
  "LIST_USER_MANAGEMENT_USER_GROUP_SUCCESS ";
export const LIST_USER_MANAGEMENT_USER_GROUP_FAILED =
  "LIST_USER_MANAGEMENT_USER_GROUP_FAILED";

export const LIST_USER_MANAGEMENT_USER_IN_GROUP =
  "LIST_USER_MANAGEMENT_USER_IN_GROUP";
export const LIST_USER_MANAGEMENT_USER_IN_GROUP_SUCCESS =
  "LIST_USER_MANAGEMENT_USER_IN_GROUP_SUCCESS";
export const LIST_USER_MANAGEMENT_USER_IN_GROUP_FAILED =
  "LIST_USER_MANAGEMENT_USER_IN_GROUP_FAILED";

export const LIST_USER_MANAGEMENT_ROLE_IN_GROUP =
  "LIST_USER_MANAGEMENT_ROLE_IN_GROUP";
export const LIST_USER_MANAGEMENT_ROLE_IN_GROUP_SUCCESS =
  "LIST_USER_MANAGEMENT_ROLE_IN_GROUP_SUCCESS";
export const LIST_USER_MANAGEMENT_ROLE_IN_GROUP_FAILED =
  "LIST_USER_MANAGEMENT_ROLE_IN_GROUP_FAILED";

export const LIST_TENANT_USER_MANAGEMENT_USER =
  "LIST_TENANT_USER_MANAGEMENT_USER";
export const LIST_TENANT_USER_MANAGEMENT_USER_SUCCESS =
  "LIST_TENANT_USER_MANAGEMENT_USER_SUCCESS";
export const LIST_TENANT_USER_MANAGEMENT_USER_FAILED =
  "LIST_TENANT_USER_MANAGEMENT_USER_FAILED";
