import axios from "axios";
import { ENTRY_ROUTE, TOKEN_PAYLOAD_KEY } from "config/AppConfig";
import { ORGANIZATION_API_PATH } from "config/AppConfig";
import { removeListLS } from "./LocalStorage";

const service = axios.create({
  timeout: 60000,
});

const auth = JSON.parse(localStorage.getItem("auth")) || {
  AuthenticationResult: {},
};

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const { IdToken } = auth.AuthenticationResult;
    const { OUToken } = auth;

    if (config.url.includes(ORGANIZATION_API_PATH) && OUToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = OUToken;
      return config;
    }

    if (IdToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = IdToken;
      return config;
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    Promise.reject(error).then();
  }
);

// API response interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) {
      if (
        error?.response?.data?.statusCode === 423 ||
        error?.response?.data?.Code === "NotAuthorizedException"
      ) {
        localStorage.removeItem("auth");
        window.location.reload();
      }
      return Promise.reject(error.response.data);
    } else {
      removeListLS(["auth", "CYBERKIT"]);
      window.location.assign(
        `${ENTRY_ROUTE}/?error=true&notification=token_expire`
      );
      return Promise.reject(error);
    }
  }
);

export default service;
