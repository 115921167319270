// EVENT
export const SET_EVENT_LIST = "SET_EVENT_LIST";
export const SET_EVENT_IN_EVENT = "SET_EVENT_IN_EVENT";
export const CREATE_EVENT = "CREATE_EVENT";

// DETECTION
export const SET_DETECTION_LIST = "SET_DETECTION_LIST";
export const CREATE_DETECTION = "CREATE_DETECTION";
export const UPDATE_DETECTION = "UPDATE_DETECTION";
export const DELETE_DETECTION = "DELETE_DETECTION";
export const CLONE_DETECTION = "CLONE_DETECTION";
export const ENABLE_DETECTION = "ENABLE_DETECTION";
export const DISABLE_DETECTION = "DISABLE_DETECTION";

// AUDIT
export const SET_AUDIT = "SET_AUDIT";

// NOTIFICATION
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
