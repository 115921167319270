import moment from "moment";

export const changeFormatDatetime = (datetime, format) =>
  moment(datetime).format(format);

export const getFormTo = (form, to) => {
  return moment(form).format(to);
};

export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const disabledFutureDate = (current) => {
  return current && current.valueOf() > Date.now();
};
