import fetch from "util/Api";
import { PRIVACYKIT_DATA_SUBJECT_RIGHT_MANAGEMENT_BASE_URL as endpoint } from "config/EndpointConfig";

const DataSubjectRightManagementService = {};

DataSubjectRightManagementService.listWorkflow = async () =>
  await fetch({
    url: `${endpoint}/workflow/default`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataSubjectRightManagementService.getWorkflowDefault = async () =>
  await fetch({
    url: `${endpoint}/workflow/create`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataSubjectRightManagementService.getWorkflow = async (workflowId) =>
  await fetch({
    url: `${endpoint}/workflow/${workflowId}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

DataSubjectRightManagementService.createWorkflow = async (data) =>
  await fetch({
    url: `${endpoint}/workflow`,
    method: "post",
    data: { data },
  });

DataSubjectRightManagementService.updateWorkflow = async (
  workflowId,
  userKey,
  data
) =>
  await fetch({
    url: `${endpoint}/workflow/${workflowId}?user_key=${userKey}`,
    method: "put",
    data: { data },
  });

DataSubjectRightManagementService.publishWorkflow = async (
  workflowId,
  userKey
) =>
  await fetch({
    url: `${endpoint}/workflow/${workflowId}/activate?user_key=${userKey}`,
    method: "post",
  });

DataSubjectRightManagementService.deleteWorkflow = async (workflowId) =>
  await fetch({
    url: `${endpoint}/workflow/${workflowId}`,
    method: "delete",
  });

DataSubjectRightManagementService.listTaskInWorkflow = async (workflowId) =>
  await fetch({
    url: `${endpoint}/workflow/${workflowId}/subtask`,
    method: "get",
  });

DataSubjectRightManagementService.createTaskInWorkflow = async (
  workflowId,
  data
) =>
  await fetch({
    url: `${endpoint}/workflow/${workflowId}/subtask`,
    method: "post",
    data: { data },
  });

DataSubjectRightManagementService.updateTaskInWorkflow = async (
  taskId,
  workflowId,
  data
) =>
  await fetch({
    url: `${endpoint}/subtask/${taskId}?workflow_id=${workflowId}`,
    method: "put",
    data: { data },
  });

DataSubjectRightManagementService.deleteTaskInWorkflow = async (
  taskId,
  workflowId,
  userKey
) =>
  await fetch({
    url: `${endpoint}/subtask/${taskId}?workflow_id=${workflowId}&user_key=${userKey}`,
    method: "delete",
  });

export default DataSubjectRightManagementService;
