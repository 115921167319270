import {
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE_SUCCESS,
  LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE_FAILURE,
  TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS,
  UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_FAILED,
  SEARCH_DATA_TASK,
  SEARCH_DATA_TASK_SUCCESS,
} from "../../constants/PrivacyKitDataSubjectRightManagementNew";
import { replaceObject } from "util/arrayUtils";

const initialState = {
  loading: false,
  listTasks: null,
  nextPageToken: null,
  manageTask: {
    visible: false,
    loading: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
};

const TaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE_SUCCESS: {
      return {
        ...state,
        listTasks: [...state.listTasks, ...action.payload.listTasks],
        nextPageToken: action.payload.nextPageToken,
      };
    }
    case LIST_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_NEXT_PAGE_FAILURE: {
      return {
        ...state,
        nextPageToken: null,
      };
    }
    case TOGGLE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK: {
      return {
        ...state,
        manageTask: {
          ...state.manageTask,
          visible: !state.manageTask.visible,
          data: action.payload ?? null,
        },
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK: {
      return {
        ...state,
        manageTask: {
          ...state.manageTask,
          okButtonProps: {
            loading: true,
          },
        },
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_SUCCESS: {
      return {
        ...state,
        manageTask: initialState.manageTask,
        listTasks: replaceObject(
          state.listTasks,
          action.payload.ObjectUUID,
          action.payload
        ),
      };
    }
    case UPDATE_DATA_SUBJECT_RIGHT_MANAGEMENT_TASK_FAILED: {
      return {
        ...state,
        manageTask: {
          ...state.manageTask,
          okButtonProps: {
            loading: false,
          },
        },
      };
    }
    case SEARCH_DATA_TASK: {
      return {
        ...state,
        loading: true,
        search: action.search,
      };
    }
    case SEARCH_DATA_TASK_SUCCESS: {
      return {
        ...state,
        listTasks: action.payload?.listTasks ?? null,
        nextPageToken: action.payload?.nextPageToken ?? null,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default TaskReducer;
