import fetch from "util/Api";
import { PRIVACYKIT_DATA_SUBJECT_RIGHT_MANAGEMENT_BASE_URL as endpoint } from "config/EndpointConfig";

const DataSubjectRightManagementService = {};

DataSubjectRightManagementService.listRequest = async () =>
  await fetch({
    url: `${endpoint}/request?search&filter=all`,
    method: "get",
  });

DataSubjectRightManagementService.listRequestNextPage = async (nextPageToken) =>
  await fetch({
    url: `${endpoint}/request?search&filter=all&next_page_token=${nextPageToken}`,
    method: "get",
  });

DataSubjectRightManagementService.getRequest = async (requestId, userKey) =>
  await fetch({
    url: `${endpoint}/request/${requestId}?user_key=${userKey}`,
    method: "get",
  });

DataSubjectRightManagementService.updateRequest = async (
  requestId,
  userKey,
  data
) =>
  await fetch({
    url: `${endpoint}/request/${requestId}?user_key=${userKey}`,
    method: "put",
    data: { data },
  });

DataSubjectRightManagementService.listRequestTask = async (
  requestId,
  userKey
) =>
  await fetch({
    url: `${endpoint}/request/${requestId}/subtask?user_key=${userKey}`,
    method: "get",
  });

DataSubjectRightManagementService.createRequestTask = async (
  requestId,
  userKey,
  data
) =>
  await fetch({
    url: `${endpoint}/request/${requestId}/subtask?user_key=${userKey}`,
    method: "post",
    data: { data },
  });

DataSubjectRightManagementService.searchRequest = async (search) =>
  await fetch({
    url: `${endpoint}/search?identifier=${search}`,
    method: "get",
  });

export default DataSubjectRightManagementService;
