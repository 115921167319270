import { changeFormatDatetime } from "util/DateUtils";
import { ACTIVITI } from "constants/AuditConstant";

const RESULT_TAG = {
  success: { color: "#04D182", label: "สำเร็จ" },
  fail: { color: "#C74343", label: "ไม่สำเร็จ" },
};

const TARGET = {
  securityrule: "เงื่อนไขการตรวจจับ",
  notification: "ตั้งค่าการแจ้งเตือน",
};

export default class AuditModel {
  constructor(data) {
    this.id = data.id ? data.id : 0;
    this.member_id = data.member_id ? data.member_id : "";
    this.target = data.target ? data.target : "";
    this.activity = data.activity ? data.activity : "";
    this.result = data.result ? data.result : "";
    this.timestamp = data.timestamp ? data.timestamp : "";
    this.actions = data.actions ? data.actions : "";
  }

  displayTarget = () =>
    TARGET[this.target.toLowerCase()] || this.target.toLowerCase();
  displayActivity = () =>
    ACTIVITI[this.activity.toLowerCase()] || this.activity.toLowerCase();
  displayResultColor = () =>
    RESULT_TAG[this.result.toLowerCase()]?.color || "#04D182";
  displayResultCLabel = () =>
    RESULT_TAG[this.result.toLowerCase()]?.label || "None";
  desplayTimestamp = () =>
    this.timestamp !== "0001-01-01T00:00:00Z"
      ? changeFormatDatetime(this.timestamp, "DD/MM/YYYY h:mm A")
      : "-";
}
