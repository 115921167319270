import {
  LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED_SUCCESS,
  LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED_FAILED,
  CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_SUCCESS,
  CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_FAILED,
  UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE_SUCCESS,
  UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE_FAILED,
  UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED_SUCCESS,
  UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED_FAILED,
  LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE_SUCCESS,
  LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE_FAILED,
  UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED_SUCCESS,
  UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED_FAILED,
  UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED_SUCCESS,
  UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED_FAILED,
  LIST_DATA_MAPPING_ASSESSMENT_DELETED_SUCCESS,
  LIST_DATA_MAPPING_ASSESSMENT_DELETED_FAILED,
  UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED_SUCCESS,
  UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED_FAILED,
  DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED_SUCCESS,
  DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED_FAILED,
  LIST_DATA_MAPPING_RISK_SUCCESS,
  LIST_DATA_MAPPING_RISK_FAILED,
  CREATE_DATA_MAPPING_RISK_SUCCESS,
  CREATE_DATA_MAPPING_RISK_FAILED,
  GET_DATA_MAPPING_RISK_SUCCESS,
  GET_DATA_MAPPING_RISK_FAILED,
  UPDATE_DATA_MAPPING_RISK_SUCCESS,
  UPDATE_DATA_MAPPING_RISK_FAILED,
  DELETE_DATA_MAPPING_RISK_SUCCESS,
  DELETE_DATA_MAPPING_RISK_FAILED,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_FAILED,
  CREATE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
  CREATE_DATA_MAPPING_INVENTORY_ASSETS_FAILED,
  GET_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
  GET_DATA_MAPPING_INVENTORY_ASSETS_FAILED,
  UPDATE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
  UPDATE_DATA_MAPPING_INVENTORY_ASSETS_FAILED,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_FAILED,
  LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED_FAILED,
  LIST_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_PROCESSING_FAILED,
  CREATE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
  CREATE_DATA_MAPPING_INVENTORY_PROCESSING_FAILED,
  GET_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
  GET_DATA_MAPPING_INVENTORY_PROCESSING_FAILED,
  UPDATE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
  UPDATE_DATA_MAPPING_INVENTORY_PROCESSING_FAILED,
  DELETE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_PROCESSING_FAILED,
  LIST_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_ENTITY_FAILED,
  CREATE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
  CREATE_DATA_MAPPING_INVENTORY_ENTITY_FAILED,
  GET_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
  GET_DATA_MAPPING_INVENTORY_ENTITY_FAILED,
  UPDATE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
  UPDATE_DATA_MAPPING_INVENTORY_ENTITY_FAILED,
  DELETE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_ENTITY_FAILED,
  LIST_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_VENDOR_FAILED,
  CREATE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
  CREATE_DATA_MAPPING_INVENTORY_VENDOR_FAILED,
  GET_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
  GET_DATA_MAPPING_INVENTORY_VENDOR_FAILED,
  UPDATE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
  UPDATE_DATA_MAPPING_INVENTORY_VENDOR_FAILED,
  DELETE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_VENDOR_FAILED,
  LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
  LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED,
  CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
  CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED,
  GET_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
  GET_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED,
  UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
  UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED,
  DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
  DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED,
  LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE_SUCCESS,
  LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE_FAILED,
  LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
  LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED,
  CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
  CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED,
  GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
  GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED,
  UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
  UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED,
  DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
  DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED,
  LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT_SUCCESS,
  LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT_FAILED,
  LIST_DATA_MAPPING_INVENTORY_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_FAILED,
  GET_DATA_MAPPING_INVENTORY_SUCCESS,
  GET_DATA_MAPPING_INVENTORY_FAILED,
  CREATE_DATA_MAPPING_MANAGEMENT_SUCCESS,
  CREATE_DATA_MAPPING_MANAGEMENT_FAILED,
  UPDATE_DATA_MAPPING_INVENTORY_SUCCESS,
  UPDATE_DATA_MAPPING_INVENTORY_FAILED,
  DELETE_DATA_MAPPING_MANAGEMENT_SUCCESS,
  DELETE_DATA_MAPPING_MANAGEMENT_FAILED,
  LIST_DATA_MAPPING_DASHBOARD_SUCCESS,
  LIST_DATA_MAPPING_DASHBOARD_FAILED,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_FAILED,
  ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS,
  ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_FAILED,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_FAILED,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_FAILED,
  ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS,
  ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_FAILED,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_FAILED,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE_FAILED,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_FAILED,
  ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS,
  ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_FAILED,
  LIST_DATA_MAPPING_INVENTORY_LOCATION_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_LOCATION_FAILED,
  LIST_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_CONTROL_FAILED,
  LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM_FAILED,
  LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL_FAILED,
  ADD_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS,
  ADD_DATA_MAPPING_INVENTORY_CONTROL_FAILED,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_FAILED,
  LOADING_DATA_MAPPING,
  LIST_DATA_MAPPING_DATA_WORKFLOW_SUCCESS,
  LIST_DATA_MAPPING_DATA_WORKFLOW_FAILED,
  CREATE_DATA_MAPPING_DATA_WORKFLOW_SUCCESS,
  CREATE_DATA_MAPPING_DATA_WORKFLOW_FAILED,
  DELETE_DATA_MAPPING_DATA_WORKFLOW_SUCCESS,
  DELETE_DATA_MAPPING_DATA_WORKFLOW_FAILED,
  DELETE_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_CONTROL_FAILED,
  RESET_DATA_MAPPING_ASSET,
  RESET_DATA_MAPPING_ENTITY,
  LIST_DATA_MAPPING_INVENTORY_IT_ASSET_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_IT_ASSET_FAILED,
  LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS,
  LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_FAILED,
  GET_DATA_MAPPING_FLOW_SUCCESS,
  GET_DATA_MAPPING_FLOW_FAILED,
  LOADING_CREATE_DATA_MAPPING,
  LOADING_DATA_ELEMENT,
  LOADING_ASSET,
  LOADING_CONTROL,
  LOADING_ENTITY,
  ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS,
  ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_FAILED,
  DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS,
  DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_FAILED,
  LOADING_PROCESSING,
  LOADING_WORKFLOW,
} from "redux/constants/PrivacyKitDataMapping";

export const dataMappingDashboardSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_DASHBOARD_SUCCESS,
    payload,
  };
};

export const dataMappingDashboardFailed = (payload) => {
  return {
    type: LIST_DATA_MAPPING_DASHBOARD_FAILED,
    payload,
  };
};

export const listDataMappingAssessmentActivatedSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED_SUCCESS,
    payload,
  };
};

export const listDataMappingAssessmentActivatedFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_ASSESSMENT_ACTIVATED_FAILED,
    payload: error,
  };
};

export const createDataMappingAssessmentActivatedSuccess = (payload) => {
  return {
    type: CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_SUCCESS,
    payload,
  };
};

export const createDataMappingAssessmentActivatedFailed = (error) => {
  return {
    type: CREATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_FAILED,
    payload: error,
  };
};

export const updateDataMappingAssessmentActivatedToArchiveSuccess = (
  payload
) => {
  return {
    type: UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE_SUCCESS,
    payload,
  };
};

export const updateDataMappingAssessmentActivatedToArchiveFailed = (error) => {
  return {
    type: UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_ARCHIVE_FAILED,
    payload: error,
  };
};

export const updateDataMappingAssessmentActivatedToDeletedSuccess = (
  payload
) => {
  return {
    type: UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED_SUCCESS,
    payload,
  };
};

export const updateDataMappingAssessmentActivatedToDeletedFailed = (error) => {
  return {
    type: UPDATE_DATA_MAPPING_ASSESSMENT_ACTIVATED_TO_DELETED_FAILED,
    payload: error,
  };
};

export const listDataMappingAssessmentArchiveSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE_SUCCESS,
    payload,
  };
};

export const listDataMappingAssessmentArchiveFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_ASSESSMENT_ARCHIVE_FAILED,
    payload: error,
  };
};

export const updateDataMappingAssessmentArchiveToActivatedSuccess = (
  payload
) => {
  return {
    type: UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED_SUCCESS,
    payload,
  };
};

export const updateDataMappingAssessmentArchiveToActivatedFailed = (error) => {
  return {
    type: UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_ACTIVATED_FAILED,
    payload: error,
  };
};

export const updateDataMappingAssessmentArchiveToDeletedSuccess = (payload) => {
  return {
    type: UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED_SUCCESS,
    payload,
  };
};

export const updateDataMappingAssessmentArchiveToDeletedFailed = (error) => {
  return {
    type: UPDATE_DATA_MAPPING_ASSESSMENT_ARCHIVE_TO_DELETED_FAILED,
    payload: error,
  };
};

export const listDataMappingAssessmentDeletedSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_ASSESSMENT_DELETED_SUCCESS,
    payload,
  };
};

export const listDataMappingAssessmentDeletedFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_ASSESSMENT_DELETED_FAILED,
    payload: error,
  };
};

export const updateDataMappingAssessmentDeletedToActivatedSuccess = (
  payload
) => {
  return {
    type: UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED_SUCCESS,
    payload,
  };
};

export const updateDataMappingAssessmentDeletedToActivatedFailed = (error) => {
  return {
    type: UPDATE_DATA_MAPPING_ASSESSMENT_DELETED_TO_ACTIVATED_FAILED,
    payload: error,
  };
};

export const deleteDataMappingAssessmentDeletedToDeletedSuccess = (payload) => {
  return {
    type: DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED_SUCCESS,
    payload,
  };
};

export const deleteDataMappingAssessmentDeletedToDeletedFailed = (error) => {
  return {
    type: DELETE_DATA_MAPPING_ASSESSMENT_DELETED_TO_DELETED_FAILED,
    payload: error,
  };
};

export const listDataMappingRiskSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_RISK_SUCCESS,
    payload,
  };
};

export const listDataMappingRiskFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_RISK_FAILED,
    payload: error,
  };
};

export const createDataMappingRiskSuccess = (payload) => {
  return {
    type: CREATE_DATA_MAPPING_RISK_SUCCESS,
    payload,
  };
};

export const createDataMappingRiskFailed = (error) => {
  return {
    type: CREATE_DATA_MAPPING_RISK_FAILED,
    payload: error,
  };
};

export const getDataMappingRiskSuccess = (payload) => {
  return {
    type: GET_DATA_MAPPING_RISK_SUCCESS,
    payload,
  };
};

export const getDataMappingRiskFailed = (error) => {
  return {
    type: GET_DATA_MAPPING_RISK_FAILED,
    payload: error,
  };
};

export const updateDataMappingRiskSuccess = (payload) => {
  return {
    type: UPDATE_DATA_MAPPING_RISK_SUCCESS,
    payload,
  };
};

export const updateDataMappingRiskFailed = (error) => {
  return {
    type: UPDATE_DATA_MAPPING_RISK_FAILED,
    payload: error,
  };
};

export const deleteDataMappingRiskSuccess = (payload) => {
  return {
    type: DELETE_DATA_MAPPING_RISK_SUCCESS,
    payload,
  };
};

export const deleteDataMappingRiskFailed = (error) => {
  return {
    type: DELETE_DATA_MAPPING_RISK_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryAssetsSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryAssetsFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ASSETS_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryAssetsAssessmentSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryAssetsAssessmentFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_FAILED,
    payload: error,
  };
};

export const addDataMappingInventoryAssetsAssessmentSuccess = (payload) => {
  return {
    type: ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS,
    payload,
  };
};

export const addDataMappingInventoryAssetsAssessmentFailed = (error) => {
  return {
    type: ADD_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_FAILED,
    payload: error,
  };
};

export const deleteDataMappingInventoryAssetsAssessmentSuccess = (payload) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_SUCCESS,
    payload,
  };
};

export const deleteDataMappingInventoryAssetsAssessmentFailed = (error) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_ASSETS_ASSESSMENT_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryAssetsDataElementSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryAssetsDataElementFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_FAILED,
    payload: error,
  };
};

export const addDataMappingInventoryAssetsDataElementSuccess = (payload) => {
  return {
    type: ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS,
    payload,
  };
};

export const addDataMappingInventoryAssetsDataElementFailed = (error) => {
  return {
    type: ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_FAILED,
    payload: error,
  };
};

export const deleteDataMappingInventoryAssetsDataElementSuccess = (payload) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_SUCCESS,
    payload,
  };
};

export const deleteDataMappingInventoryAssetsDataElementFailed = (error) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_ELEMENT_FAILED,
    payload: error,
  };
};

export const createDataMappingInventoryAssetsSuccess = (payload) => {
  return {
    type: CREATE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
    payload,
  };
};

export const createDataMappingInventoryAssetsFailed = (error) => {
  return {
    type: CREATE_DATA_MAPPING_INVENTORY_ASSETS_FAILED,
    payload: error,
  };
};

export const getDataMappingInventoryAssetsSuccess = (payload) => {
  return {
    type: GET_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
    payload,
  };
};

export const getDataMappingInventoryAssetsFailed = (error) => {
  return {
    type: GET_DATA_MAPPING_INVENTORY_ASSETS_FAILED,
    payload: error,
  };
};

export const updateDataMappingInventoryAssetsSuccess = (payload) => {
  return {
    type: UPDATE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
    payload,
  };
};

export const updateDataMappingInventoryAssetsFailed = (error) => {
  return {
    type: UPDATE_DATA_MAPPING_INVENTORY_ASSETS_FAILED,
    payload: error,
  };
};

export const deleteDataMappingInventoryAssetsSuccess = (payload) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_ASSETS_SUCCESS,
    payload,
  };
};
export const deleteDataMappingInventoryAssetsFailed = (error) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_ASSETS_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryProcessingDataInventoryRelatedSuccess = (
  payload
) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryProcessingDataInventoryRelatedFailed = (
  error
) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_PROCESSING_DATA_INVENTORY_RELATED_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryProcessingSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryProcessingFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_PROCESSING_FAILED,
    payload: error,
  };
};

export const createDataMappingInventoryProcessingSuccess = (payload) => {
  return {
    type: CREATE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
    payload,
  };
};

export const createDataMappingInventoryProcessingFailed = (error) => {
  return {
    type: CREATE_DATA_MAPPING_INVENTORY_PROCESSING_FAILED,
    payload: error,
  };
};

export const getDataMappingInventoryProcessingSuccess = (payload) => {
  return {
    type: GET_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
    payload,
  };
};

export const getDataMappingInventoryProcessingFailed = (error) => {
  return {
    type: GET_DATA_MAPPING_INVENTORY_PROCESSING_FAILED,
    payload: error,
  };
};

export const updateDataMappingInventoryProcessingSuccess = (payload) => {
  return {
    type: UPDATE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
    payload,
  };
};

export const updateDataMappingInventoryProcessingFailed = (error) => {
  return {
    type: UPDATE_DATA_MAPPING_INVENTORY_PROCESSING_FAILED,
    payload: error,
  };
};

export const deleteDataMappingInventoryProcessingSuccess = (payload) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_PROCESSING_SUCCESS,
    payload,
  };
};

export const deleteDataMappingInventoryProcessingFailed = (error) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_PROCESSING_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryEntitySuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryEntityFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ENTITY_FAILED,
    payload: error,
  };
};

export const createDataMappingInventoryEntitySuccess = (payload) => {
  return {
    type: CREATE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
    payload,
  };
};

export const createDataMappingInventoryEntityFailed = (error) => {
  return {
    type: CREATE_DATA_MAPPING_INVENTORY_ENTITY_FAILED,
    payload: error,
  };
};

export const getDataMappingInventoryEntitySuccess = (payload) => {
  return {
    type: GET_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
    payload,
  };
};

export const getDataMappingInventoryEntityFailed = (error) => {
  return {
    type: GET_DATA_MAPPING_INVENTORY_ENTITY_FAILED,
    payload: error,
  };
};

export const updateDataMappingInventoryEntitySuccess = (payload) => {
  return {
    type: UPDATE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
    payload,
  };
};

export const updateDataMappingInventoryEntityFailed = (error) => {
  return {
    type: UPDATE_DATA_MAPPING_INVENTORY_ENTITY_FAILED,
    payload: error,
  };
};

export const deleteDataMappingInventoryEntitySuccess = (payload) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_ENTITY_SUCCESS,
    payload,
  };
};

export const deleteDataMappingInventoryEntityFailed = (error) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_ENTITY_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryVendorSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryVendorFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_VENDOR_FAILED,
    payload: error,
  };
};

export const createDataMappingInventoryVendorSuccess = (payload) => {
  return {
    type: CREATE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
    payload,
  };
};

export const createDataMappingInventoryVendorFailed = (error) => {
  return {
    type: CREATE_DATA_MAPPING_INVENTORY_VENDOR_FAILED,
    payload: error,
  };
};

export const getDataMappingInventoryVendorSuccess = (payload) => {
  return {
    type: GET_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
    payload,
  };
};

export const getDataMappingInventoryVendorFailed = (error) => {
  return {
    type: GET_DATA_MAPPING_INVENTORY_VENDOR_FAILED,
    payload: error,
  };
};

export const updateDataMappingInventoryVendorSuccess = (payload) => {
  return {
    type: UPDATE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
    payload,
  };
};

export const updateDataMappingInventoryVendorFailed = (error) => {
  return {
    type: UPDATE_DATA_MAPPING_INVENTORY_VENDOR_FAILED,
    payload: error,
  };
};

export const deleteDataMappingInventoryVendorSuccess = (payload) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_VENDOR_SUCCESS,
    payload,
  };
};

export const deleteDataMappingInventoryVendorFailed = (error) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_VENDOR_FAILED,
    payload: error,
  };
};

export const listDataMappingSettingAutomationRuleSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
    payload,
  };
};

export const listDataMappingSettingAutomationRuleFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED,
    payload: error,
  };
};

export const createDataMappingSettingAutomationRuleSuccess = (payload) => {
  return {
    type: CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
    payload,
  };
};

export const createDataMappingSettingAutomationRuleFailed = (error) => {
  return {
    type: CREATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED,
    payload: error,
  };
};

export const getDataMappingSettingAutomationRuleSuccess = (payload) => {
  return {
    type: GET_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
    payload,
  };
};

export const getDataMappingSettingAutomationRuleFailed = (error) => {
  return {
    type: GET_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED,
    payload: error,
  };
};

export const updateDataMappingSettingAutomationRuleSuccess = (payload) => {
  return {
    type: UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
    payload,
  };
};

export const updateDataMappingSettingAutomationRuleFailed = (error) => {
  return {
    type: UPDATE_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED,
    payload: error,
  };
};

export const deleteDataMappingSettingAutomationRuleSuccess = (payload) => {
  return {
    type: DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE_SUCCESS,
    payload,
  };
};

export const deleteDataMappingSettingAutomationRuleFailed = (error) => {
  return {
    type: DELETE_DATA_MAPPING_SETTING_AUTOMATION_RULE_FAILED,
    payload: error,
  };
};

export const listDataMappingSettingAssessmentTemplateSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE_SUCCESS,
    payload,
  };
};

export const listDataMappingSettingAssessmentTemplateFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_SETTING_ASSESSMENT_TEMPLATE_FAILED,
    payload: error,
  };
};

export const listDataMappingSettingAssessmentWorkflowSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
    payload,
  };
};

export const listDataMappingSettingAssessmentWorkflowFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED,
    payload: error,
  };
};

export const createDataMappingSettingAssessmentWorkflowSuccess = (payload) => {
  return {
    type: CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
    payload,
  };
};

export const createDataMappingSettingAssessmentWorkflowFailed = (error) => {
  return {
    type: CREATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED,
    payload: error,
  };
};

export const getDataMappingSettingAssessmentWorkflowSuccess = (payload) => {
  return {
    type: GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
    payload,
  };
};

export const getDataMappingSettingAssessmentWorkflowFailed = (error) => {
  return {
    type: GET_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED,
    payload: error,
  };
};

export const updateDataMappingSettingAssessmentWorkflowSuccess = (payload) => {
  return {
    type: UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
    payload,
  };
};

export const updateDataMappingSettingAssessmentWorkflowFailed = (error) => {
  return {
    type: UPDATE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED,
    payload: error,
  };
};

export const deleteDataMappingSettingAssessmentWorkflowSuccess = (payload) => {
  return {
    type: DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_SUCCESS,
    payload,
  };
};

export const deleteDataMappingSettingAssessmentWorkflowFailed = (error) => {
  return {
    type: DELETE_DATA_MAPPING_SETTING_ASSESSMENT_WORKFLOW_FAILED,
    payload: error,
  };
};

export const listDataMappingSettingInventoryManagementSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT_SUCCESS,
    payload,
  };
};

export const listDataMappingSettingInventoryManagementFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_SETTING_INVENTORY_MANAGEMENT_FAILED,
    payload: error,
  };
};

export const listDataMappingInventorySuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_FAILED,
    payload: error,
  };
};

export const getDataMappingInventorySuccess = (payload) => {
  return {
    type: GET_DATA_MAPPING_INVENTORY_SUCCESS,
    payload,
  };
};

export const getDataMappingInventoryFailed = (error) => {
  return {
    type: GET_DATA_MAPPING_INVENTORY_FAILED,
    payload: error,
  };
};

export const updateDataMappingInventorySuccess = (payload) => {
  return {
    type: UPDATE_DATA_MAPPING_INVENTORY_SUCCESS,
    payload,
  };
};

export const updateDataMappingInventoryFailed = (error) => {
  return {
    type: UPDATE_DATA_MAPPING_INVENTORY_FAILED,
    payload: error,
  };
};

export const createDataMappingManagementSuccess = (payload) => {
  return {
    type: CREATE_DATA_MAPPING_MANAGEMENT_SUCCESS,
    payload,
  };
};

export const createDataMappingManagementFailed = (error) => {
  return {
    type: CREATE_DATA_MAPPING_MANAGEMENT_FAILED,
    payload: error,
  };
};

export const deleteDataMappingManagementSuccess = (payload) => {
  return {
    type: DELETE_DATA_MAPPING_MANAGEMENT_SUCCESS,
    payload,
  };
};

export const deleteDataMappingManagementFailed = (error) => {
  return {
    type: DELETE_DATA_MAPPING_MANAGEMENT_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryAssetsDataLifeCircleSuccess = (
  payload
) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryAssetsDataLifeCircleFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_LIFE_CYCLE_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryLocationSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_LOCATION_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryLocationFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_LOCATION_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryControlSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryControlFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_CONTROL_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryControlParamSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryControlParamFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_CONTROL_PARAM_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryLinkControlSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryLinkControlFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_LINK_CONTROL_FAILED,
    payload: error,
  };
};

export const addDataMappingInventoryControlSuccess = (payload) => {
  return {
    type: ADD_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS,
    payload,
  };
};

export const addDataMappingInventoryControlFailed = (error) => {
  return {
    type: ADD_DATA_MAPPING_INVENTORY_CONTROL_FAILED,
    payload: error,
  };
};

export const deleteDataMappingInventoryControlSuccess = (payload) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_CONTROL_SUCCESS,
    payload,
  };
};

export const deleteDataMappingInventoryControlFailed = (error) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_CONTROL_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryAssetsDataInventoryRelatedSuccess = (
  payload
) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryAssetsDataInventoryRelatedFailed = (
  error
) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_FAILED,
    payload: error,
  };
};

export const addDataMappingInventoryAssetsDataInventoryRelatedSuccess = (
  payload
) => {
  return {
    type: ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS,
    payload,
  };
};

export const addDataMappingInventoryAssetsDataInventoryRelatedFailed = (
  error
) => {
  return {
    type: ADD_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_FAILED,
    payload: error,
  };
};

export const deleteDataMappingInventoryAssetsInventoryRelatedSuccess = (
  payload
) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_SUCCESS,
    payload,
  };
};

export const deleteDataMappingInventoryAssetsInventoryRelatedFailed = (
  error
) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_ASSETS_DATA_INVENTORY_RELATED_FAILED,
    payload: error,
  };
};

export const setLoadingDataMapping = (isLoadingDataMapping) => {
  return {
    type: LOADING_DATA_MAPPING,
    payload: isLoadingDataMapping,
  };
};

export const setLoadingCreateDataMapping = (isLoadingCreateDataMapping) => {
  return {
    type: LOADING_CREATE_DATA_MAPPING,
    payload: isLoadingCreateDataMapping,
  };
};

export const setLoadingDataElement = (isLoadingDataElement) => {
  return {
    type: LOADING_DATA_ELEMENT,
    payload: isLoadingDataElement,
  };
};

export const setLoadingAsset = (isLoadingAsset) => {
  return {
    type: LOADING_ASSET,
    payload: isLoadingAsset,
  };
};

export const setLoadingEntity = (isLoadingEntity) => {
  return {
    type: LOADING_ENTITY,
    payload: isLoadingEntity,
  };
};

export const setLoadingControl = (isLoadingControl) => {
  return {
    type: LOADING_CONTROL,
    payload: isLoadingControl,
  };
};

export const setLoadingProcessing = (isLoadingProcessing) => {
  return {
    type: LOADING_PROCESSING,
    payload: isLoadingProcessing,
  };
};

export const setLoadingWorkflow = (isLoadingWorkflow) => {
  return {
    type: LOADING_WORKFLOW,
    payload: isLoadingWorkflow,
  };
};

export const listDataMappingDataWorkflowSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_DATA_WORKFLOW_SUCCESS,
    payload,
  };
};

export const listDataMappingDataWorkflowFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_DATA_WORKFLOW_FAILED,
    payload: error,
  };
};

export const createDataMappingDataWorkflowSuccess = (payload) => {
  return {
    type: CREATE_DATA_MAPPING_DATA_WORKFLOW_SUCCESS,
    payload,
  };
};

export const createDataMappingDataWorkflowFailed = (error) => {
  return {
    type: CREATE_DATA_MAPPING_DATA_WORKFLOW_FAILED,
    payload: error,
  };
};

export const deleteDataMappingDataWorkflowSuccess = (payload) => {
  return {
    type: DELETE_DATA_MAPPING_DATA_WORKFLOW_SUCCESS,
    payload,
  };
};

export const deleteDataMappingDataWorkflowFailed = (error) => {
  return {
    type: DELETE_DATA_MAPPING_DATA_WORKFLOW_FAILED,
    payload: error,
  };
};

export const resetDataMappingAsset = (payload) => {
  return {
    type: RESET_DATA_MAPPING_ASSET,
    payload,
  };
};

export const resetDataMappingEntity = (payload) => {
  return {
    type: RESET_DATA_MAPPING_ENTITY,
    payload,
  };
};

export const listDataMappingInventoryITAssetSuccess = (payload) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_IT_ASSET_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryITAssetFailed = (error) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_IT_ASSET_FAILED,
    payload: error,
  };
};

export const listDataMappingInventoryEntityDataInventoryRelatedSuccess = (
  payload
) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS,
    payload,
  };
};

export const listDataMappingInventoryEntityDataInventoryRelatedFailed = (
  error
) => {
  return {
    type: LIST_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_FAILED,
    payload: error,
  };
};

export const addDataMappingInventoryEntityDataInventoryRelatedSuccess = (
  payload
) => {
  return {
    type: ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS,
    payload,
  };
};

export const addDataMappingInventoryEntityDataInventoryRelatedFailed = (
  error
) => {
  return {
    type: ADD_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_FAILED,
    payload: error,
  };
};

export const deleteDataMappingInventoryEntityDataInventoryRelatedSuccess = (
  payload
) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_SUCCESS,
    payload,
  };
};

export const deleteDataMappingInventoryEntityDataInventoryRelatedFailed = (
  error
) => {
  return {
    type: DELETE_DATA_MAPPING_INVENTORY_ENTITY_DATA_INVENTORY_RELATED_FAILED,
    payload: error,
  };
};

export const getDataMappingFlowSuccess = (payload) => {
  return {
    type: GET_DATA_MAPPING_FLOW_SUCCESS,
    payload,
  };
};

export const getDataMappingFlowFailed = (error) => {
  return {
    type: GET_DATA_MAPPING_FLOW_FAILED,
    payload: error,
  };
};
