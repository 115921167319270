export const selectIPrivacyKitDataMapping = (state) =>
  state.iPrivacyKitDataMapping;

export const selectIPrivacyKitDataMappingDataWarehouse = (state) =>
  state.iPrivacyKitDataMapping.dataWarehouse;

export const selectIPrivacyKitDataMappingDataWarehouseManageModal = (state) =>
  state.iPrivacyKitDataMapping.dataWarehouse.manageModal;

export const selectIPrivacyKitDataMappingDataWarehouseDeleteModal = (state) =>
  state.iPrivacyKitDataMapping.dataWarehouse.deleteModal;

export const selectIPrivacyKitDataMappingRelatedControlMeasures = (state) =>
  state.iPrivacyKitDataMapping.relatedControlMeasures;

export const selectIPrivacyKitDataMappingRelatedControlMeasuresManageModal = (state) =>
  state.iPrivacyKitDataMapping.relatedControlMeasures.manageModal;

export const selectIPrivacyKitDataMappingRelatedControlMeasuresDeleteModal = (state) =>
  state.iPrivacyKitDataMapping.relatedControlMeasures.deleteModal;
