import { all } from "redux-saga/effects";
import Auth from "./Auth";
import UserManagement from "./UserManagement";

import PrivacyKitCookieConsentManagement from "./PrivacyKitCookieConsentManagement";
import PrivacyKitDataBreachNotification from "./PrivacyKitDataBreachNotification";
import PrivacyKitDataSubjectRightManagement from "./PrivacyKitDataSubjectRightManagement";
import PrivacyKitConsentManagement from "./PrivacyKitConsentManagement";
import PrivacyKitIncidentResponseManagement from "./PrivacyKitIncidentResponseManagement";
import PrivacyKitPrivacyAssessmentAutomation from "./PrivacyKitPrivacyAssessmentAutomation";
import PrivacyKitAssessmentAutomation from "./PrivacyKitAssessmentAutomation";
import PrivacyKitDataClassificationAndDiscovery from "./PrivacyKitDataClassificationAndDiscovery";
import PrivacyKitDataMapping from "./PrivacyKitDataMapping";
import PrivacyKitCompliantManagement from "./PrivacyKitCompliantManagement";
import PrivacyKitPolicyAndNoticeManagement from "./PrivacyKitPolicyAndNoticeManagement";
import CyberKitTool from "./CyberKitTool";
import CyberKitSiem from "./CyberKitSiem";
import CyberKitSoar from "./CyberKitSoar";
import CyberKitLogManagement from "./CyberKitLogManagement";
import CyberKitVulnerabilityAssessment from "./CyberKitVulnerabilityAssessment";
import CyberKitITAssetInventory from "./CyberKitAssetInventory";
import CyberKitThreatIntelligence from "./CyberKitThreatIntelligence";
import IPrivacyKitDataMapping from "./IPrivacyKitDataMapping";

export default function* rootSaga() {
  yield all([
    Auth(),
    UserManagement(),
    PrivacyKitCookieConsentManagement(),
    PrivacyKitDataBreachNotification(),
    PrivacyKitDataSubjectRightManagement(),
    PrivacyKitConsentManagement(),
    PrivacyKitIncidentResponseManagement(),
    PrivacyKitPrivacyAssessmentAutomation(),
    PrivacyKitAssessmentAutomation(),
    PrivacyKitDataClassificationAndDiscovery(),
    PrivacyKitDataMapping(),
    PrivacyKitCompliantManagement(),
    PrivacyKitPolicyAndNoticeManagement(),
    IPrivacyKitDataMapping(),
    CyberKitTool(),
    CyberKitLogManagement(),
    CyberKitSiem(),
    CyberKitSoar(),
    CyberKitVulnerabilityAssessment(),
    CyberKitITAssetInventory(),
    CyberKitThreatIntelligence(),
  ]);
}
