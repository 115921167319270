import {
  PrivacyKitPath,
  PrivacyKitDataClassificationAndDiscoveryPath,
} from "../AppModuleConfig";

const PrivacyKitDataClassificationAndDiscovery = [
  {
    key: `data-classification-and-discovering-dashboard`,
    path: `${PrivacyKitPath}${PrivacyKitDataClassificationAndDiscoveryPath}/dashboard`,
    title: "ค้นหาข้อมูล",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
];

export default PrivacyKitDataClassificationAndDiscovery;
