import {
  LIST_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  TOGGLE_CREATE_PRIVACY_ASSESSMENT_AUTOMATION_MODAL,
  TOGGLE_DELETE_PRIVACY_ASSESSMENT_AUTOMATION,
  GET_PRIVACY_ASSESSMENT_AUTOMATION,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  CREATE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  TOGGLE_PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS,
  DELETE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE,
  CLEAR_PRIVACY_ASSESSMENT_AUTOMATION,
} from "../../constants/PrivacyKitPrivacyAssessmentAutomation";
import { replaceObject } from "../../../util/arrayUtils";

const initialState = {
  list: null,
  data: null,
  nextPageToken: null,
  loading: false,
  okButtonProps: {
    loading: false,
  },
  publishModal: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
  createModal: {
    visible: false,
    okButtonProps: {
      loading: false,
    },
  },
  deleteModal: {
    visible: false,
    data: null,
    okButtonProps: {
      loading: false,
    },
  },
};

const AssessmentAutomation = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS:
      return {
        ...state,
        list: action.listAssessment,
        nextPageToken: action.nextPageToken,
      };
    case TOGGLE_CREATE_PRIVACY_ASSESSMENT_AUTOMATION_MODAL: {
      return {
        ...state,
        createModal: {
          ...initialState.createModal,
          visible: !state.createModal.visible,
        },
      };
    }
    case TOGGLE_DELETE_PRIVACY_ASSESSMENT_AUTOMATION: {
      return {
        ...state,
        deleteModal: {
          ...initialState.deleteModal,
          visible: !state.deleteModal.visible,
          data: action.data,
        },
      };
    }
    case GET_PRIVACY_ASSESSMENT_AUTOMATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case CREATE_PRIVACY_ASSESSMENT_AUTOMATION: {
      return {
        ...state,
        createModal: {
          ...state.createModal,
          okButtonProps: {
            ...state.createModal.okButtonProps,
            loading: true,
          },
        },
      };
    }
    case CREATE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS: {
      return {
        ...state,
        createModal: initialState.createModal,
        list: [action.data, ...state.list],
      };
    }
    case CREATE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE: {
      return {
        ...state,
        createModal: {
          ...state.createModal,
          okButtonProps: {
            ...state.createModal.okButtonProps,
            loading: false,
          },
        },
      };
    }
    case UPDATE_PRIVACY_ASSESSMENT_AUTOMATION: {
      return {
        ...state,
        okButtonProps: {
          ...state.okButtonProps,
          loading: true,
        },
      };
    }
    case UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS: {
      return {
        ...state,
        okButtonProps: {
          ...state.okButtonProps,
          loading: false,
        },
      };
    }
    case UPDATE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE: {
      return {
        ...state,
        okButtonProps: initialState.okButtonProps,
      };
    }
    case DELETE_PRIVACY_ASSESSMENT_AUTOMATION: {
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          okButtonProps: {
            ...state.deleteModal.okButtonProps,
            loading: true,
          },
        },
      };
    }
    case TOGGLE_PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION: {
      return {
        ...state,
        publishModal: {
          ...initialState.publishModal,
          visible: !state.publishModal.visible,
        },
      };
    }
    case PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION: {
      return {
        ...state,
        publishModal: {
          ...state.publishModal,
          okButtonProps: {
            ...state.publishModal.okButtonProps,
            loading: true,
          },
        },
      };
    }
    case PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS: {
      return {
        ...state,
        publishModal: initialState.publishModal,
        list: replaceObject(state.list, action.data.ObjectUUID, action.data),
      };
    }
    case PUBLISH_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE: {
      return {
        ...state,
        publishModal: {
          ...state.publishModal,
          okButtonProps: {
            ...state.publishModal.okButtonProps,
            loading: false,
          },
        },
      };
    }
    case DELETE_PRIVACY_ASSESSMENT_AUTOMATION_SUCCESS: {
      return {
        ...state,
        deleteModal: initialState.deleteModal,
        list: state.list.filter(
          (item) => item.ObjectUUID !== action.assessmentId
        ),
      };
    }
    case DELETE_PRIVACY_ASSESSMENT_AUTOMATION_FAILURE: {
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          okButtonProps: {
            ...state.deleteModal.okButtonProps,
            loading: false,
          },
        },
      };
    }
    case CLEAR_PRIVACY_ASSESSMENT_AUTOMATION: {
      return {
        ...state,
        data: null,
      };
    }
    default:
      return state;
  }
};

export default AssessmentAutomation;
