export const SET_ALL_EVENTS = "SET_ALL_EVENTS";
export const SET_FOLLOW_EVENTS = "SET_FOLLOW_EVENTS";
export const SET_PRIVATE_EVENTS = "SET_PRIVATE_EVENTS";
export const GET_EVENT_BY_ID = "GET_EVENT_BY_ID";
export const SET_EVENT_DETAIL = "SET_EVENT_DETAIL";
export const SET_ATTRIBUTES = "SET_ATTRIBUTES";

export const GET_FEED = "GET_FEED";
export const SET_FEED = "SET_FEED";
export const FOLLOW_EVENT = "FOLLOW_EVENT";
export const UNFOLLOW_EVENT = "UNFOLLOW_EVENT";
