import fetch from "util/Api";
import { CYBER_KIT_BASE_URL as endpoint } from "config/EndpointConfig";

const VulnerabilityAssessmentServices = {};

// Agent
VulnerabilityAssessmentServices.getAgentList = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/agents${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

VulnerabilityAssessmentServices.createAgent = async (payload) =>
  await fetch({
    method: "post",
    url: `${endpoint}/agents`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

VulnerabilityAssessmentServices.updateAgent = async (payload) =>
  await fetch({
    method: "put",
    url: `${endpoint}/agents/${payload.path.id}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

VulnerabilityAssessmentServices.deleteAgent = async (payload) =>
  await fetch({
    method: "delete",
    url: `${endpoint}/agents/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

// Scanner
VulnerabilityAssessmentServices.getScannerDashboard = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/scanner_data/summary${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

VulnerabilityAssessmentServices.getScannerList = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/scanners${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

VulnerabilityAssessmentServices.getScannerTasks = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/scanner_task/task/${payload.path.scanner_id}${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

VulnerabilityAssessmentServices.getScannerFound = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/scanner_data${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

VulnerabilityAssessmentServices.getScannerDetail = async (payload) =>
  await fetch({
    method: "get",
    url: `${endpoint}/scanner_data/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

VulnerabilityAssessmentServices.createScanner = async (payload) =>
  await fetch({
    method: "post",
    url: `${endpoint}/scanners`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

VulnerabilityAssessmentServices.updateScanner = async (payload) =>
  await fetch({
    method: "put",
    url: `${endpoint}/scanners/${payload.path.id}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

VulnerabilityAssessmentServices.deleteScanner = async (payload) =>
  await fetch({
    method: "delete",
    url: `${endpoint}/scanners/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

export default VulnerabilityAssessmentServices;
