export const LIST_CONSENT_MANAGEMENT = "LIST_CONSENT_MANAGEMENT";
export const LIST_CONSENT_MANAGEMENT_SUCCESS =
  "LIST_CONSENT_MANAGEMENT_SUCCESS";
export const LIST_CONSENT_MANAGEMENT_NEXT_PAGE =
  "LIST_CONSENT_MANAGEMENT_NEXT_PAGE";
export const LIST_CONSENT_MANAGEMENT_NEXT_PAGE_SUCCESS =
  "LIST_CONSENT_MANAGEMENT_NEXT_PAGE_SUCCESS";
export const LIST_CONSENT_MANAGEMENT_NEXT_PAGE_ERROR =
  "LIST_CONSENT_MANAGEMENT_NEXT_PAGE_ERROR";

export const GET_CONSENT_MANAGEMENT = "GET_CONSENT_MANAGEMENT";
export const GET_CONSENT_MANAGEMENT_SUCCESS = "GET_CONSENT_MANAGEMENT_SUCCESS";
export const SELECT_CONSENT_MANAGEMENT_FORM = "SELECT_CONSENT_MANAGEMENT_FORM";

export const TOGGLE_UNIVERSAL_CONSENT_MANAGEMENT_HISTORY =
  "TOGGLE_UNIVERSAL_CONSENT_MANAGEMENT_HISTORY";

export const ON_SEARCH_CONSENT_MANAGEMENT = "ON_SEARCH_CONSENT_MANAGEMENT";
export const ON_SEARCH_CONSENT_MANAGEMENT_SUCCESS =
  "ON_SEARCH_CONSENT_MANAGEMENT_SUCCESS";
