import { changeFormatDatetime } from "util/DateUtils";

const COLOUR_TAG = {
  public: { color: "#04D182", label: "พร้อมใช้งาน" },
};

export default class ResponsePlanModel {
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.description = data?.description;
    this.tags = data.tags ? data.tags : [];
    this.module = data?.module;
    this.version = data?.version;
    this.organization = data?.organization;
    this.object_type = data?.object_type;
    this.stages = data?.stages;
    this.status = data?.status;
    this.create_at = data?.create_at;
    this.create_by = data?.create_by;
    this.update_at = data?.update_at;
    this.update_by = data?.update_by;
  }

  displayID = () => this.id;
  displayStatusColor = () =>
    COLOUR_TAG[this.status.toLowerCase()]?.color || "#04D182";
  displayStatusLabel = () =>
    COLOUR_TAG[this.status.toLowerCase()]?.label || "None";
  displayCreatedAt = () =>
    this.create_at !== "0001-01-01T00:00:00Z"
      ? changeFormatDatetime(this.create_at, "DD/MM/YYYY h:mm A")
      : "-";
  displayUpdatedAt = () =>
    this.update_at !== "0001-01-01T00:00:00Z"
      ? changeFormatDatetime(this.update_at, "DD/MM/YYYY h:mm A")
      : "-";
}
