import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  LIST_COMPLIANT_ASSESSMENT,
  GET_COMPLIANT_ASSESSMENT,
  LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE,
  LIST_COMPLIANCE_PLAN,
  CREATE_COMPLIANCE_PLAN,
  DELETE_COMPLIANCE_PLAN,
  GET_DETAIL_COMPLIANCE_PLAN,
  UPDATE_COMPLIANCE_PLAN,
} from "../constants/PrivacyKitCompliantManagement";
import {
  listCompliantAssessmentSuccess,
  listCompliantAssessmentError,
  getCompliantAssessmentSuccess,
  getCompliantAssessmentError,
  listCompliantAssessmentTemplateTypeSuccess,
  listCompliantAssessmentTemplateTypeFailed,
  listCompliantPlanSuccess,
  createCompliantPlanSuccess,
  deleteCompliantPlanSuccess,
  getDetailCompliantPlanSuccess,
  updateCompliantPlanSuccess,
  updateCompliantPlanFailed,
} from "../actions/PrivacyKitCompliantManagement";
import AssessmentAutomationServices from "services/pdpakit/AssessmentAutomationServices";
import _ from "lodash";
import Notification from "util/Notification";

export function* listCompliantAssessment() {
  yield takeEvery(LIST_COMPLIANT_ASSESSMENT, function* () {
    try {
      const { statusCode, data } = yield call(
        AssessmentAutomationServices.listAssessmentByType,
        "compliant"
      );

      if (statusCode !== 200 || !_.isArray(data)) {
        yield put(listCompliantAssessmentError("Server Internal Error"));
      }

      if (statusCode === 200 || _.isArray(data)) {
        let assessment = _.filter(data, { assessment_type: "compliant" });
        yield put(
          listCompliantAssessmentSuccess({ statusCode, data: assessment })
        );
      }
    } catch (error) {
      yield put(listCompliantAssessmentError(error));
    }
  });
}

export function* getCompliantAssessment() {
  yield takeEvery(GET_COMPLIANT_ASSESSMENT, function* ({ payload }) {
    try {
      const { statusCode, data } = yield call(
        AssessmentAutomationServices.getAssessmentById,
        "compliant",
        payload
      );
      if (statusCode === 200 && _.isObject(data)) {
        yield put(getCompliantAssessmentSuccess(data));
      }
    } catch (error) {
      yield put(getCompliantAssessmentError(error));
    }
  });
}

function* getListCompliancePlan() {
  yield takeEvery(LIST_COMPLIANCE_PLAN, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.listCompliancePlan,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listCompliantPlanSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* listCompliantAssessmentTemplateType() {
  yield takeEvery(LIST_COMPLIANT_ASSESSMENT_TEMPLATE_TYPE, function* ({ payload }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.listAssessmentTemplateType,
        payload
      );
      if (response.statusCode === 200) {
        yield put(listCompliantAssessmentTemplateTypeSuccess(response));
      } else {
        yield put(listCompliantAssessmentTemplateTypeFailed(response));
      }
    } catch (error) {
      yield put(listCompliantAssessmentTemplateTypeFailed(error));
    }
  });
}

// function* getCompliantAssessmentTemplateData() {
//   yield takeEvery(
//     GET_COMPLIANT_ASSESSMENT_TEMPLATE_DATA,
//     function* ({ payload }) {
//       try {
//         // console.log(payload)
//         // const response = yield call(
//         //   AssessmentAutomationServices.listTemplateType,
//         //   payload.assessmentType
//         // );
//         // if (response.statusCode === 200) {
//         //   yield put(getCompliantAssessmentTemplateDataSuccess(response.Item));
//         // } else {
//         //   yield put(getCompliantAssessmentTemplateDataFailed(response));
//         // }
//       } catch (error) {
//         yield put(getCompliantAssessmentTemplateDataFailed(error));
//       }
//     }
//   );
// }

function* createCompliancePlan() {
  yield takeEvery(
    CREATE_COMPLIANCE_PLAN,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.createCompliancePlan,
          payload
        );

        if (response.statusCode === 200 && response.data) {
          yield put(createCompliantPlanSuccess(response.data));
          onFinish();
          Notification(
            "success",
            "notification.compliantManagement.create.success"
          );
        } else {
          onFailed(response);
          Notification("error", "notification.internalServerError.create");
        }
      } catch (error) {
        console.log(error);
        onFailed(error);
      }
    }
  );
}

function* deleteCompliancePlan() {
  yield takeEvery(
    DELETE_COMPLIANCE_PLAN,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.deleteCompliancePlan,
          payload
        );
        if (response.statusCode === 200) {
          yield put(deleteCompliantPlanSuccess(payload));
          onFinish();
          Notification(
            "success",
            "notification.complianceManagement.delete.success"
          );
        } else {
          onFailed(response);
          Notification("error", "notification.internalServerError");
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* getDetailCompliancePlan() {
  yield takeEvery(GET_DETAIL_COMPLIANCE_PLAN, function* ({ payload, id }) {
    try {
      const response = yield call(
        AssessmentAutomationServices.getDetailCompliancePlan,
        payload,
        id
      );
      if (response.statusCode === 200 && response.data) {
        yield put(getDetailCompliantPlanSuccess(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* updateCompliancePlan() {
  yield takeEvery(
    UPDATE_COMPLIANCE_PLAN,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          AssessmentAutomationServices.updateCompliancePlan,
          payload
        );
        if (response.statusCode === 200 && response.data) {
          yield put(updateCompliantPlanSuccess(response.data));
          onFinish();
          Notification(
            "success",
            "notification.complianceManagement.update.success"
          );
        } else {
          Notification("error", "notification.internalServerError");
        }
      } catch (error) {
        yield put(updateCompliantPlanFailed(error));
        console.log(error);
        onFailed(error);
      }
    }
  );
}
export default function* rootSaga() {
  yield all([
    fork(listCompliantAssessment),
    fork(getCompliantAssessment),
    fork(listCompliantAssessmentTemplateType),
    // fork(getCompliantAssessmentTemplateData),
    fork(getListCompliancePlan),
    fork(createCompliancePlan),
    fork(deleteCompliancePlan),
    fork(getDetailCompliancePlan),
    fork(updateCompliancePlan),
  ]);
}
