import React from "react";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import UserInfo from "components/UserInfo";
import { toggleCollapsedSideNav } from "redux/actions";
import HorizontalNav from "../HorizontalNav";
import { TAB_SIZE } from "constants/ThemeSetting";
import BrandLogo from "../BrandLogo";

const { Header } = Layout;

const BelowHeader = () => {
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  const dispatch = useDispatch();

  return (
    <div className="gx-header-horizontal gx-header-horizontal-dark gx-below-header-horizontal">
      <Header className="gx-header-horizontal-main">
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                }}
              />
            </div>
            <BrandLogo />
            <ul className="gx-header-notifications gx-ml-auto">
              <li className="gx-user-nav">
                <UserInfo />
              </li>
            </ul>
          </div>
        </div>
      </Header>

      {width >= TAB_SIZE && (
        <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve">
          <div className="gx-container">
            <HorizontalNav />
          </div>
        </div>
      )}
    </div>
  );
};

export default BelowHeader;
