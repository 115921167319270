export const CHART_DEFAULT_COLOR_SCHEME = [
  "#6F42C1",
  "#60C0E8",
  "#5FCE89",
  "#F6C75C",
  "#D9345C",
  "#49A11E",
  "#FD7E14",
  "#87C5E5",
  "#C74343"
];
