import AssessmentAutomation from "./assessmentAutomation";
import RiskAssessment from "./riskAssessment";
import DataMappingReducers from "./dataMapping";
import DashboardReducers from "./dashboad";

const initialState = {};

const PrivacyAssessmentAutomation = (state = initialState, action) => ({
  assessmentAutomation: AssessmentAutomation(
    state.assessmentAutomation,
    action
  ),
  riskAssessment: RiskAssessment(state.riskAssessment, action),
  dataMapping: DataMappingReducers(state.dataMapping, action),
  dashboard: DashboardReducers(state.dashboard, action),
});

export default PrivacyAssessmentAutomation;
