import {
  CHANGE_SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY,
  SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY_SUCCESS,
  SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY_ERROR,
  LIST_DATA_CLASSIFICATION_AND_DISCOVERY,
  LIST_DATA_CLASSIFICATION_AND_DISCOVERY_SUCCESS,
  LIST_DATA_CLASSIFICATION_AND_DISCOVERY_ERROR,
} from "redux/constants/PrivacyKitDataClassificationAndDiscovery";

const initialState = {
  search: null,
  loading: false,
  listData: null,
  listSearchData: null,
  nextPageToken: null,
};

const PrivacyKitDataClassificationAndDiscovery = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CHANGE_SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY: {
      return {
        ...state,
        loading: true,
        search: action.search,
      };
    }
    case SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY_SUCCESS: {
      return {
        ...state,
        loading: false,
        listSearchData: action.listSearchDataClassificationAndDiscovery,
      };
    }
    case SEARCH_DATA_CLASSIFICATION_AND_DISCOVERY_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case LIST_DATA_CLASSIFICATION_AND_DISCOVERY: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DATA_CLASSIFICATION_AND_DISCOVERY_SUCCESS: {
      return {
        ...state,
        loading: false,
        listData: action.payload,
      };
    }
    case LIST_DATA_CLASSIFICATION_AND_DISCOVERY_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default PrivacyKitDataClassificationAndDiscovery;
