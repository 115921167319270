import {
  TOGGLE_MANAGE_DATA_MAPPING_DATA_WAREHOUSE_MODAL,
  LIST_DATA_MAPPING_DATA_WHERE_HOUSE,
  LIST_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  LIST_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED,
  MANAGE_DATA_MAPPING_DATA_WHERE_HOUSE,
  CREATE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  CREATE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED,
  GET_DATA_MAPPING_DATA_WHERE_HOUSE,
  GET_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  GET_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED,
  UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED,
  TOGGLE_DELETE_DATA_MAPPING_DATA_WAREHOUSE_MODAL,
  DELETE_DATA_MAPPING_DATA_WHERE_HOUSE,
  DELETE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  DELETE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED,
  LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP,
  LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP_SUCCESS,
  LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP_FAILED,
} from "../../constants/IPrivacyKitDataMapping";

export const toggleManageDataMappingDataWarehouseModal = (data) => ({
  type: TOGGLE_MANAGE_DATA_MAPPING_DATA_WAREHOUSE_MODAL,
  payload: data,
});

export const listDataMappingDataWarehouse = () => ({
  type: LIST_DATA_MAPPING_DATA_WHERE_HOUSE,
});

export const listDataMappingDataWarehouseSuccess = (data) => ({
  type: LIST_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  payload: data,
});

export const listDataMappingDataWarehouseFailed = (error) => ({
  type: LIST_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED,
  error,
});

export const manageDataMappingDataWarehouse = (values) => ({
  type: MANAGE_DATA_MAPPING_DATA_WHERE_HOUSE,
  values,
});

export const createDataMappingDataWarehouseSuccess = (data) => ({
  type: CREATE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  payload: data,
});

export const createDataMappingDataWarehouseFailed = (error) => ({
  type: CREATE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED,
  error,
});

export const getDataMappingDataWarehouse = (dataWarehouseId) => ({
  type: GET_DATA_MAPPING_DATA_WHERE_HOUSE,
  dataWarehouseId,
});

export const getDataMappingDataWarehouseSuccess = (data) => ({
  type: GET_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  payload: data,
});

export const getDataMappingDataWarehouseFailed = (error) => ({
  type: GET_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED,
  error,
});

export const updateDataMappingDataWarehouseSuccess = (data) => ({
  type: UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  payload: data,
});

export const updateDataMappingDataWarehouseFailed = (error) => ({
  type: UPDATE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED,
  error,
});

export const toggleDeleteDataMappingDataWarehouseModal = (data) => ({
  type: TOGGLE_DELETE_DATA_MAPPING_DATA_WAREHOUSE_MODAL,
  payload: data,
});

export const deleteDataMappingDataWarehouse = (dataWarehouseId) => ({
  type: DELETE_DATA_MAPPING_DATA_WHERE_HOUSE,
  dataWarehouseId,
});

export const deleteDataMappingDataWarehouseSuccess = (data) => ({
  type: DELETE_DATA_MAPPING_DATA_WHERE_HOUSE_SUCCESS,
  payload: data,
});

export const deleteDataMappingDataWarehouseFailed = (error) => ({
  type: DELETE_DATA_MAPPING_DATA_WHERE_HOUSE_FAILED,
  error,
});

export const listDataMappingDataWarehouseGroup = () => ({
  type: LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP,
});

export const listDataMappingDataWarehouseGroupSuccess = (data) => ({
  type: LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP_SUCCESS,
  payload: data,
});

export const listDataMappingDataWarehouseGroupFailed = (error) => ({
  type: LIST_DATA_MAPPING_DATA_WHERE_HOUSE_GROUP_FAILED,
  error,
});
