import fetch from "util/Api";
import {
  CYBER_KIT_BASE_URL as cyberkitEndpoint,
  WORK_FLOW_BASE_URL as workflowEndpoint,
} from "config/EndpointConfig";

const SiemServices = {};

// EVENT
SiemServices.getEventList = async (payload) =>
  await fetch({
    method: "get",
    url: `${cyberkitEndpoint}/detection_rule/event${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.getEventData = async (payload) =>
  await fetch({
    method: "get",
    url: `${cyberkitEndpoint}/detection_rule/event/${payload.path.id}${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.updateEventStatus = async (payload) =>
  await fetch({
    method: "put",
    url: `${cyberkitEndpoint}/detection_rule/event/${payload.path.id}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.createEvent = async (payload) =>
  await fetch({
    method: "post",
    url: `${workflowEndpoint}/request`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

// DETECTION
SiemServices.getDetectionList = async (payload) =>
  await fetch({
    method: "get",
    url: `${cyberkitEndpoint}/security_rule${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.getSecurityRuleByID = async (payload) =>
  await fetch({
    method: "get",
    url: `${cyberkitEndpoint}/security_rule/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.createSecurityRule = async (payload) =>
  await fetch({
    method: "post",
    url: `${cyberkitEndpoint}/security_rule`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.updateSecurityRule = async (payload) =>
  await fetch({
    method: "put",
    url: `${cyberkitEndpoint}/security_rule/${payload.path.id}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.deleteSecurityRule = async (payload) =>
  await fetch({
    method: "delete",
    url: `${cyberkitEndpoint}/security_rule/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.cloneSecurityRule = async (payload) =>
  await fetch({
    method: "post",
    url: `${cyberkitEndpoint}/security_rule/clone`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.disableSecurityRule = async (payload) =>
  await fetch({
    method: "put",
    url: `${cyberkitEndpoint}/security_rule/disable/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.enableSecurityRule = async (payload) =>
  await fetch({
    method: "put",
    url: `${cyberkitEndpoint}/security_rule/enable/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

// Notification
SiemServices.getNotificationList = async (payload) =>
  await fetch({
    method: "get",
    url: `${cyberkitEndpoint}/notification${payload.params}`,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.getNotificationByID = async (payload) =>
  await fetch({
    method: "get",
    url: `${cyberkitEndpoint}/notification/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.createNotification = async (payload) =>
  await fetch({
    method: "post",
    url: `${cyberkitEndpoint}/notification`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.updateNotification = async (payload) =>
  await fetch({
    method: "put",
    url: `${cyberkitEndpoint}/notification/${payload.path.id}`,
    data: payload.data,
  })
    .then((response) => response)
    .catch((error) => error);

SiemServices.deleteNotification = async (payload) =>
  await fetch({
    method: "delete",
    url: `${cyberkitEndpoint}/notification/${payload.path.id}`,
  })
    .then((response) => response)
    .catch((error) => error);

export default SiemServices;
