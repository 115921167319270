import {
  GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD_SUCCESS,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD_FAILURE,
} from "../../constants/PrivacyKitPrivacyAssessmentAutomation";

export const getPrivacyAssessmentAutomationDashboard = () => ({
  type: GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD,
});

export const getPrivacyAssessmentAutomationDashboardSuccess = (payload) => ({
  type: GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD_SUCCESS,
  payload,
});

export const getPrivacyAssessmentAutomationDashboardFailure = () => ({
  type: GET_PRIVACY_ASSESSMENT_AUTOMATION_DASHBOARD_FAILURE,
});
