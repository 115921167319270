import fetch from "util/Api";
import { PRIVACYKIT_DATA_MAPPING_BASE_URL as endpoint } from "config/EndpointConfig";

const RelatedControlMeasures = {};

RelatedControlMeasures.listRelatedControlMeasures = async () =>
  await fetch({
    url: `${endpoint}/inventory/control`,
    method: "GET",
  });

RelatedControlMeasures.listRelatedControlMeasuresGroup = async () =>
  await fetch({
    url: `${endpoint}/control_name?new=1`,
    method: "GET",
  });

RelatedControlMeasures.createRelatedControlMeasures = async (data) =>
  await fetch({
    url: `${endpoint}/inventory/control`,
    method: "POST",
    data,
  });

RelatedControlMeasures.updateRelatedControlMeasures = async (data) =>
  await fetch({
    url: `${endpoint}/inventory/control/${data.ObjectUUID}`,
    method: "PUT",
    data,
  });

RelatedControlMeasures.deleteRelatedControlMeasures = async (data) =>
  await fetch({
    url: `${endpoint}/inventory/control/${data.ObjectUUID}`,
    method: "DELETE",
  });

export default RelatedControlMeasures;
