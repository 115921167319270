import fetch from "util/Api";
import { PRIVACYKIT_UNIVERSAL_CONSENT_AND_PREFERENCE_BASE_URL as endpoint } from "config/EndpointConfig";

const ProcessingPurposeService = {};

ProcessingPurposeService.listProcessingPurpose = async () =>
  await fetch({
    url: `${endpoint}/purposes`,
    method: "get",
  });

ProcessingPurposeService.getProcessingPurpose = async (processingPurposeId) =>
  await fetch({
    url: `${endpoint}/purposes/${processingPurposeId}`,
    method: "get",
  });

ProcessingPurposeService.createProcessingPurpose = async (data) =>
  await fetch({
    url: `${endpoint}/purposes`,
    method: "post",
    data,
  });

ProcessingPurposeService.updateProcessingPurpose = async (
  processingPurposeId,
  data
) =>
  await fetch({
    url: `${endpoint}/purposes/${processingPurposeId}`,
    method: "put",
    data,
  });

ProcessingPurposeService.deleteProcessingPurpose = async (
  processingPurposeId
) =>
  await fetch({
    url: `${endpoint}/purposes/${processingPurposeId}`,
    method: "delete",
  });

export default ProcessingPurposeService;
