import fetch from "util/Api";
import { PRIVACYKIT_ASSESSMENT_AUTOMATION_BASE_URL as endpoint } from "config/EndpointConfig";
import { PRIVACYKIT_DATA_MAPPING_BASE_URL } from "config/EndpointConfig";

const assessmentType = "privacy";

const PrivacyAssessmentAutomationService = {};

PrivacyAssessmentAutomationService.listAssessmentAutomation = async () =>
  await fetch({
    url: `${endpoint}/assessment/type/${assessmentType}`,
    method: "GET",
  });

PrivacyAssessmentAutomationService.getAssessmentAutomation = async (
  assessmentId
) =>
  await fetch({
    url: `${endpoint}/assessment/${assessmentType}/${assessmentId}`,
    method: "GET",
  });

PrivacyAssessmentAutomationService.createAssessmentAutomation = async (data) =>
  await fetch({
    url: `${endpoint}/assessment`,
    method: "POST",
    data,
  });

PrivacyAssessmentAutomationService.updateAssessmentAutomation = async (
  assessmentId,
  data
) =>
  await fetch({
    url: `${endpoint}/assessment/${assessmentType}/${assessmentId}`,
    method: "PUT",
    data,
  });

PrivacyAssessmentAutomationService.publishAssessmentAutomation = async (
  assessmentId
) =>
  await fetch({
    url: `${endpoint}/assessment/${assessmentType}/${assessmentId}/publish`,
    method: "post",
  });

PrivacyAssessmentAutomationService.deleteAssessmentAutomation = async (id) =>
  await fetch({
    url: `${endpoint}/assessment/${assessmentType}/${id}`,
    method: "DELETE",
  });

PrivacyAssessmentAutomationService.listAssessmentAutomationRisk = async (
  assessmentId
) =>
  await fetch({
    url: `${endpoint}/assessment/risk?assessment_type=${assessmentType}&assessment_id=${assessmentId}`,
    method: "GET",
  });

PrivacyAssessmentAutomationService.getAssessmentAutomationRisk = async (
  riskId
) =>
  await fetch({
    url: `${endpoint}/assessment/risk/privacy/${riskId}`,
    method: "GET",
  });

PrivacyAssessmentAutomationService.createAssessmentAutomationRisk = async (
  data
) =>
  await fetch({
    url: `${endpoint}/assessment/risk`,
    method: "POST",
    data,
  });

PrivacyAssessmentAutomationService.updateAssessmentAutomationRisk = async (
  riskId,
  data
) =>
  await fetch({
    url: `${endpoint}/assessment/risk/privacy/${riskId}`,
    method: "PUT",
    data,
  });

PrivacyAssessmentAutomationService.publishAssessmentAutomationRisk = async (
  riskId
) =>
  await fetch({
    url: `${endpoint}/assessment/risk/privacy/${riskId}/publish`,
    method: "post",
  });

PrivacyAssessmentAutomationService.deleteAssessmentAutomationRisk = async (
  riskId
) =>
  await fetch({
    url: `${endpoint}/assessment/risk/privacy/${riskId}`,
    method: "DELETE",
  });

PrivacyAssessmentAutomationService.getDataMappingInventoryPrivacyAssessmentAutomation =
  async (inventoryId) =>
    await fetch({
      url: `${PRIVACYKIT_DATA_MAPPING_BASE_URL}/inventory/asset/${inventoryId}/data_element`,
      method: "GET",
    });

PrivacyAssessmentAutomationService.getDashboard = async () =>
  await fetch({
    url: `${endpoint}/assessment/dashboard`,
    method: "GET",
  });

export default PrivacyAssessmentAutomationService;
