// Agent
export const CREATE_AGENT = "CREATE_AGENT";
export const UPDATE_AGENT = "UPDATE_AGENT";
export const DELETE_AGENT = "DELETE_AGENT";

export const SET_AGENT = "SET_AGENT";

// Scanner
export const GET_SCANNER_DASHBOARD = "GET_SCANNER_DASHBOARD";
export const SET_SCANNER_DASHBOARD = "SET_SCANNER_DASHBOARD";

export const CREATE_SCANNER = "CREATE_SCANNER";
export const UPDATE_SCANNER = "UPDATE_SCANNER";
export const DELETE_SCANNER = "DELETE_SCANNER";

export const GET_SCANNER_LIST = "GET_SCANNER_LIST";
export const GET_SCANNER_TASK = "GET_SCANNER_TASK";
export const GET_SCANNER_FOUND = "GET_SCANNER_FOUND";
export const GET_SCANNER_DETAIL = "GET_SCANNER_DETAIL";

export const SET_SCANNER_LIST = "SET_SCANNER_LIST";
export const SET_SCANNER_TASK = "SET_SCANNER_TASK";
export const SET_SCANNER_FOUND = "SET_SCANNER_FOUND";
export const SET_SCANNER_DETAIL = "SET_SCANNER_DETAIL";
