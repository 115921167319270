import {
  GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY_SUCCESS,
  GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY_FAILURE,
  CLEAR_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY,
} from "../../constants/PrivacyKitPrivacyAssessmentAutomation";

export const getPrivacyAssessmentAutomationDataMappingInventory = (
  inventoryId
) => ({
  type: GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY,
  inventoryId,
});

export const getPrivacyAssessmentAutomationDataMappingInventorySuccess = (
  data
) => ({
  type: GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY_SUCCESS,
  data,
});

export const getPrivacyAssessmentAutomationDataMappingInventoryFailure = (
  error
) => ({
  type: GET_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY_FAILURE,
  error,
});

export const clearPrivacyAssessmentAutomationDataMappingInventory = () => ({
  type: CLEAR_PRIVACY_ASSESSMENT_AUTOMATION_DATA_MAPPING_INVENTORY,
});
