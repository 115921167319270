import { changeFormatDatetime } from "util/DateUtils";
import { ACTIVITI } from "constants/AuditConstant";

const COLOUR_TAG = {
  Success: { color: "#04D182", label: "สำเร็จ" },
  Fail: { color: "#FA8C16", label: "ผิดพลาด" },
  Error: { color: "#F5222D", label: "ผิดพลาดจากระบบ" },
  Warning: { color: "##FFC542", label: "คำเตือน" },
};

const TARGET = {
  source: "รายการอุปกรณ์",
  search: "ค้นหา",
  loginformation: "ประวัติการใช้งาน",
  archive: "จัดการข้อมูล Log File",
};

export default class AuditModel {
  constructor(data) {
    this.activity = data.activity ? data.activity : "";
    this.member_id = data.member_id ? data.member_id : "";
    this.module = data.module ? data.module : "";
    this.result = data.result ? data.result : "";
    this.target = data.target ? data.target : "";
    this.tenancy = data.tenancy ? data.tenancy : "";
    this.timestamp = data.timestamp ? data.timestamp : "";
  }

  displayTarget = () =>
    TARGET[this.target.toLowerCase()] || this.target.toLowerCase();
  displayActivity = () =>
    ACTIVITI[this.activity.toLowerCase()] || this.activity.toLowerCase();
  displayStatusColor = () => {
    return COLOUR_TAG[this.result]?.color || "#04D182";
  };
  displayStatusLabel = () => COLOUR_TAG[this.result]?.label || "None";
  displayTimestamp = () =>
    changeFormatDatetime(this.timestamp, "DD/MM/YYYY h:mm A");
}
