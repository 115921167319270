import {
  LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  TOGGLE_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  SET_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  CREATE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  UPDATE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  TOGGLE_DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_ERROR,
} from "../../constants/PrivacyKitConsentManagement";

export const listConsentManagementCustomPreference = () => ({
  type: LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
});

export const listConsentManagementCustomPreferenceSuccess = (data) => ({
  type: LIST_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  data,
});

export const toggleManageConsentManagementCustomPreference = (
  customPreferenceId
) => ({
  type: TOGGLE_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  customPreferenceId,
});

export const setManageConsentManagementCustomPreference = ({
  customPreferenceId,
  values,
  data,
}) => ({
  type: SET_MANAGE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  customPreferenceId,
  values,
  data,
});

export const getConsentManagementCustomPreference = (customPreferenceId) => ({
  type: GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  customPreferenceId,
});

export const getConsentManagementCustomPreferenceSuccess = (data) => ({
  type: GET_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  data,
});

export const createConsentManagementCustomPreferenceSuccess = (data) => ({
  type: CREATE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  data,
});

export const updateConsentManagementCustomPreferenceSuccess = ({
  customPreferenceId,
  data,
}) => ({
  type: UPDATE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  customPreferenceId,
  data,
});

export const toggleDeleteConsentManagementCustomPreference = (payload) => ({
  type: TOGGLE_DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
  payload,
});

export const deleteConsentManagementCustomPreference = () => ({
  type: DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE,
});

export const deleteConsentManagementCustomPreferenceSuccess = (
  customPreferenceId
) => ({
  type: DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_SUCCESS,
  customPreferenceId,
});

export const deleteConsentManagementCustomPreferenceError = () => ({
  type: DELETE_CONSENT_MANAGEMENT_CUSTOM_PREFERENCE_ERROR,
});
