import {
  PrivacyKitPath,
  PrivacyKitUniversalConsentAndPreferenceManagementPath,
} from "../AppModuleConfig";

const PrivacyKitUniversalConsentAndPreferenceManagement = [
  {
    key: `universal-consent-and-preference-management-consent-management`,
    path: `${PrivacyKitPath}${PrivacyKitUniversalConsentAndPreferenceManagementPath}/consent-management`,
    title: "การให้ความยินยอม",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `universal-consent-and-preference-management-data-elements`,
    path: `${PrivacyKitPath}${PrivacyKitUniversalConsentAndPreferenceManagementPath}/data-elements`,
    title: "สร้างข้อมูลที่จัดเก็บ",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `universal-consent-and-preference-management-custom-preferences`,
    path: `${PrivacyKitPath}${PrivacyKitUniversalConsentAndPreferenceManagementPath}/custom-preferences`,
    title: "สร้างความยินยอม",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `universal-consent-and-preference-management-processing-purposes`,
    path: `${PrivacyKitPath}${PrivacyKitUniversalConsentAndPreferenceManagementPath}/processing-purposes`,
    title: "วัตถุประสงค์การประมวลผล",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `universal-consent-and-preference-management-collection-point`,
    path: `${PrivacyKitPath}${PrivacyKitUniversalConsentAndPreferenceManagementPath}/collection-point`,
    title: "ฟอร์มขอความยินยอม",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `universal-consent-and-preference-management-dashboard`,
    path: `${PrivacyKitPath}${PrivacyKitUniversalConsentAndPreferenceManagementPath}/dashboard`,
    title: "แดชบอร์ด",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
];

export default PrivacyKitUniversalConsentAndPreferenceManagement;
