import {
  PrivacyKitPath,
  PrivacyKitCookieConsentManagementPath,
} from "../AppModuleConfig";

const domainKey = sessionStorage.getItem("domainKey");

const PrivacyKitCookieConsentManagement = [
  {
    key: `privacykit-cookie-consent-management-website`,
    path: `${PrivacyKitPath}${PrivacyKitCookieConsentManagementPath}/manage-website`,
    urlParam: domainKey && `?domain=${domainKey}`,
    title: "เว็บไซต์",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `privacykit-cookie-consent-management-scan-result`,
    path: `${PrivacyKitPath}${PrivacyKitCookieConsentManagementPath}/scan-result`,
    urlParam: domainKey && `?domain=${domainKey}`,
    title: "รายงานการสแกน",
    breadcrumb: false,
    disabled: !domainKey,
    icon: null,
    submenu: [],
  },
  {
    key: `privacykit-cookie-consent-management-manage-cookie`,
    path: `${PrivacyKitPath}${PrivacyKitCookieConsentManagementPath}/manage-cookie`,
    urlParam: domainKey && `?domain=${domainKey}`,
    title: "คุกกี้",
    breadcrumb: false,
    disabled: !domainKey,
    icon: null,
    submenu: [],
  },
  {
    key: `privacykit-cookie-consent-management-cookie-banner`,
    path: `${PrivacyKitPath}${PrivacyKitCookieConsentManagementPath}/manage-banner`,
    urlParam: domainKey && `?domain=${domainKey}`,
    title: "แบนเนอร์คุกกี้",
    breadcrumb: false,
    disabled: !domainKey,
    icon: null,
    submenu: [],
  },
  {
    key: `privacykit-cookie-consent-management-dashboard`,
    path: `${PrivacyKitPath}${PrivacyKitCookieConsentManagementPath}/dashboard`,
    urlParam: domainKey && `?domain=${domainKey}`,
    title: "แดชบอร์ด",
    breadcrumb: false,
    disabled: !domainKey,
    icon: null,
    submenu: [],
  },
];

export default PrivacyKitCookieConsentManagement;
