import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}apps`}
        component={asyncComponent(() => import("./app-views"))}
      />
      <Route
        path={`${match.url}copyright`}
        component={asyncComponent(() => import("./copyright-views"))}
      />
      <Route
        path={`${match.url}errors`}
        component={asyncComponent(() => import("./error-views"))}
      />
      <Redirect to={`${match.url}errors`} />
    </Switch>
  </div>
);

export default App;
