import { OrganizationsManagementPath } from "../AppModuleConfig";

const UserManagement = [
  // {
  //   key: `privacykit-data-breach-notification-dashboard`,
  //   path: `${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}/dashboard`,
  //   title: "แดชบอร์ด",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
  {
    key: `management-user`,
    path: `${OrganizationsManagementPath}/user`,
    title: "ผู้ใช้",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  // {
  //   key: `management-usergroup`,
  //   path: `${OrganizationsManagementPath}/usergroup`,
  //   title: "กลุ่มผู้ใช้",
  //   breadcrumb: false,
  //   icon: null,
  //   submenu: [],
  // },
  {
    key: `management-roles`,
    path: `${OrganizationsManagementPath}/roles`,
    title: "บทบาท",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  {
    key: `management-organizations`,
    path: `${OrganizationsManagementPath}/organizations`,
    title: "องค์กร",
    breadcrumb: false,
    icon: null,
    submenu: [],
  },
  //   {
  //     key: `privacykit-data-breach-notification-response-plan`,
  //     path: `${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}/response-plan`,
  //     title: "แผนการตอบสนอง",
  //     breadcrumb: false,
  //     icon: null,
  //     submenu: [],
  //   },
  //   {
  //     key: `privacykit-data-breach-notification-webform`,
  //     path: `${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}/webform`,
  //     title: "รายการเว็บฟอร์ม",
  //     breadcrumb: false,
  //     icon: null,
  //     submenu: [],
  //   },
  //   {
  //     key: `privacykit-data-breach-notification-event-register`,
  //     path: `${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}/event-register`,
  //     title: "ทะเบียนเหตุการณ์",
  //     breadcrumb: false,
  //     icon: null,
  //     submenu: [],
  //   },
  //   {
  //     key: `privacykit-data-breach-notification-report`,
  //     path: `${PrivacyKitPath}${PrivacyKitDataBreachNotificationPath}/report`,
  //     title: "รายงาน สคส.",
  //     breadcrumb: false,
  //     icon: null,
  //     submenu: [],
  //   },
];

export default UserManagement;
